import { ReactNode } from 'react';
import { useLocalStorage } from '../../../shared/utils/useLocalStorage';
import { CookieConsent } from '../../login/partials/CookieConsent';
import classes from './Frame.module.scss';
import { NbHeader } from './Header';
import { NbMenu } from './Menu';

export interface NbFrameProps {
  children: ReactNode;
}

export const Frame = ({ children }: NbFrameProps) => {
  const { isMenuCollapsed, setIsMenuCollapsed } = useLocalStorage();

  const toggleMenuCollapse = () => {
    setIsMenuCollapsed(!isMenuCollapsed);
  };

  return (
    <div className={classes.frame}>
      <NbHeader isMenuCollapsed={isMenuCollapsed} />
      <NbMenu
        isMenuCollapsed={isMenuCollapsed}
        toggleMenuCollapse={toggleMenuCollapse}
      />
      <div className={`${classes.frameMain} ${isMenuCollapsed ? classes.frameMainIsCollapsed : ''}`}>
        <div className={classes.content}>{children}</div>
      </div>
      <CookieConsent />
    </div>
  );
};
