type LogLevel = 'trace' | 'debug' | 'info' | 'warn' | 'error';

let logCallback: undefined | ((message: string) => void);
export const setLogEventCb = (cb: (message: string) => void) => {
  logCallback = cb;
  return () => {
    logCallback = undefined;
  };
};
const logEvent = (level: LogLevel, message?: any, ...optionalParams: any[]) => {
  if (logCallback) {
    logCallback(
      `${level.toUpperCase()} | ${new Date().toISOString()} | ${message}${
        optionalParams.length > 0 ? ` | ${JSON.stringify(optionalParams).substring(0, 1021)}` : ''
      }}`,
    );
  }
};

export const createLogger = (name: string, level: LogLevel = 'debug') => {
  return {
    trace: (msg: string, ...args: Array<any>) => {
      if (level === 'trace') {
        console.log(`[${name}] ${msg}`, ...args);
      }
      logEvent(level, `[${name}] ${msg}`, ...args);
    },
    debug: (msg: string, ...args: Array<any>) => {
      if (['trace', 'debug'].includes(level)) {
        console.log(`[${name}] ${msg}`, ...args);
      }
      logEvent(level, `[${name}] ${msg}`, ...args);
    },
    info: (msg: string, ...args: Array<any>) => {
      if (['trace', 'debug', 'info'].includes(level)) {
        console.log(`[${name}] ${msg}`, ...args);
      }
      logEvent(level, `[${name}] ${msg}`, ...args);
    },
    warn: (msg: string, ...args: Array<any>) => {
      if (['trace', 'debug', 'info', 'warn'].includes(level)) {
        console.warn(`[${name}] ${msg}`, ...args);
      }
      logEvent(level, `[${name}] ${msg}`, ...args);
    },
    error: (msg: string, ...args: Array<any>) => {
      if (['trace', 'debug', 'info', 'warn', 'error'].includes(level)) {
        console.error(`[${name}] ${msg}`, ...args);
        logEvent(level, `[${name}] ${msg}`, ...args);
      }
    },
  };
};
