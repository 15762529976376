import { AxiosError } from 'axios';
import { closeSnackbar, enqueueSnackbar, SnackbarProvider as NSSnackbarProvider, VariantType } from 'notistack';
import { createContext, useCallback, useContext } from 'react';
import { NBSnackbar } from '../shared/components/snackbar/Snackbar';
import { useLocale } from './NbLocalizationContext';

interface SnackbarOptionsBase {
  title?: string;
  persist?: boolean;
}

interface SnackbarOptionsError extends SnackbarOptionsBase {
  error?: unknown;
}

export type CloseSnackbarFn = () => void;

export type SnackbarContextProps =
  | undefined
  | {
      openSnackbar: (message: string, options?: SnackbarOptionsBase, sev?: VariantType) => CloseSnackbarFn;
      showError: (msgOrErr: string | unknown, options?: SnackbarOptionsError) => CloseSnackbarFn;
      showWarning: (message: string, options?: SnackbarOptionsBase) => CloseSnackbarFn;
      showSuccess: (message: string, options?: SnackbarOptionsBase) => CloseSnackbarFn;
      showInfo: (message: string, options?: SnackbarOptionsBase) => CloseSnackbarFn;
    };

const SnackbarContext = createContext<SnackbarContextProps>(undefined);

declare module 'notistack' {
  interface VariantOverrides {
    success: {
      title?: string;
    };
    error: {
      title?: string;
    };
    info: {
      title?: string;
    };
    warning: {
      title?: string;
    };
  }
}

export const SnackbarProvider = ({ children }: { children: React.ReactNode }) => {
  const locale = useLocale();

  const openSnackbar = useCallback(
    (message: string, options?: SnackbarOptionsBase, sev: VariantType = 'success'): CloseSnackbarFn => {
      const snackBar = enqueueSnackbar({ message, variant: sev, title: options?.title, persist: options?.persist });
      return () => {
        closeSnackbar(snackBar);
      };
    },
    [],
  );

  const showError = (msgOrErr: string | unknown, options?: SnackbarOptionsError): CloseSnackbarFn => {
    const { error, persist } = options ?? {};
    const err = !!error ? error : (msgOrErr as unknown);
    const axiosError = err as AxiosError;
    const msg = typeof msgOrErr === 'string' ? `${msgOrErr}` : '';
    if (axiosError.isAxiosError && axiosError.response?.data) {
      const message = `${msg}: ${axiosError.response?.data}`;
      return openSnackbar(message, { title: locale.translation.general.toast.errorTitle, persist }, 'error');
    } else if (err && typeof (err as any).message === 'string') {
      const message = `${msg}: ${locale.translateWithParams(locale.translation.general.error.unhandledException, {
        message: (err as any).message,
      })}`;
      return openSnackbar(message, { title: locale.translation.general.toast.errorTitle, persist }, 'error');
    } else {
      return openSnackbar(
        msg || locale.translation.general.error.serverError,
        { title: locale.translation.general.toast.errorTitle, persist },
        'error',
      );
    }
  };

  const showWarning = (message: string, options?: SnackbarOptionsBase): CloseSnackbarFn => {
    return openSnackbar(
      message,
      { ...options, title: options?.title ?? locale.translation.general.toast.warningTitle },
      'warning',
    );
  };

  const showSuccess = (message: string, options?: SnackbarOptionsBase): CloseSnackbarFn => {
    return openSnackbar(
      message,
      { ...options, title: options?.title ?? locale.translation.general.toast.successTitle },
      'success',
    );
  };

  const showInfo = (message: string, options?: SnackbarOptionsBase): CloseSnackbarFn => {
    return openSnackbar(message, options, 'info');
  };

  return (
    <SnackbarContext.Provider value={{ openSnackbar, showError, showWarning, showSuccess, showInfo }}>
      <NSSnackbarProvider
        maxSnack={5}
        Components={{
          default: NBSnackbar,
          error: NBSnackbar,
          info: NBSnackbar,
          success: NBSnackbar,
          warning: NBSnackbar,
        }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        {children}
      </NSSnackbarProvider>
    </SnackbarContext.Provider>
  );
};

export const useSnackbar = () => {
  const context = useContext(SnackbarContext);
  if (context === undefined) {
    throw new Error(`useSnackbar must be used within an SnackProvider`);
  }
  return context;
};
