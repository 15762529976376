import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { routes } from '../data/routes';
import { AuthResource } from '../resources/auth.resource';
import { appAuthAtom, AuthState } from '../state/authStateAtom';
import { useResource } from './useResource';

export const useAuthEvents = () => {
  const authResource = useResource(AuthResource);
  const [, setAppAuth] = useRecoilState(appAuthAtom);
  const navigate = useNavigate();

  const login = useCallback(
    async (token: string): Promise<void> => {
      authResource.setToken(token);
      const user = await authResource.login();
      setAppAuth({
        isAuthenticated: true,
        authStatus: AuthState.Loading,
        user,
      });
    },
    [authResource, setAppAuth],
  );

  const logout = useCallback(async (): Promise<void> => {
    await authResource.logout();
    setAppAuth({ isAuthenticated: false, authStatus: AuthState.NotAuthenticated });
    navigate(routes.login);
  }, [authResource, setAppAuth, navigate]);

  return {
    login,
    logout,
  };
};
