import { LogType, OrderLogs } from '../../modules/trading/states/orderLogAtom';
import { ListedInstrument } from '../types/listedInstrument';
import { OrderType } from '../types/orderType';
import { mockedInstruments } from './mockedInstruments';

const mockOrderLogs = (instrument: ListedInstrument) => {
  return {
    event:
      Math.random() >= 0.8
        ? LogType.Create
        : Math.random() >= 0.6 && Math.random() < 0.8
        ? LogType.Edit
        : Math.random() >= 0.4 && Math.random() < 0.6
        ? LogType.Cancel
        : Math.random() >= 0.2 && Math.random() < 0.4
        ? LogType.Fulfillment
        : LogType.Rejection,
    tradeType: Math.random() >= 0.5 ? 'Buy' : 'Sell',
    instrument,
    orderId: `${instrument.ric}-${Math.ceil(Math.random() * 1000)}`,
    account: `Account ${Math.ceil(Math.random() * 1000)}`,
    date: new Date(),
    subElements: [
      {
        instrument,
        tradeType: Math.random() >= 0.5 ? 'Buy' : 'Sell',
        orderType: OrderType.Limit,
        price: Math.random() * 1000,
        quantity: Math.random() * 10,
        validity: Math.random() >= 0.5 ? 'Day' : 'Week',
        stopPrice: Math.random() * 1000,
      },
    ],
  };
};

export const mockedOrderLogs: OrderLogs = {
  orderLogs: [
    mockOrderLogs(mockedInstruments[0]),
    mockOrderLogs(mockedInstruments[1]),
    mockOrderLogs(mockedInstruments[2]),
    mockOrderLogs(mockedInstruments[3]),
  ],
};
