import { useEffect } from 'react';
import { useRecoilCallback } from 'recoil';
import { IndexRowInstrument, indexRowInstrumentAtom } from '../../../shared/state/indexRowStateAtom';
import { getRandomInteger } from '../../../shared/utils/mockUtils';

export const useIndexRowMocker = () => {
  const getRandomized = (instrument: IndexRowInstrument) => {
    return {
      ...instrument,
      values: [...instrument.values.slice(0, instrument.values.length - 1), { value: getRandomInteger(100, 200) }],
    };
  };

  const updateRecord = useRecoilCallback(
    ({ set }) =>
      () => {
        set(indexRowInstrumentAtom, (list: Array<IndexRowInstrument>) => {
          return list.map(getRandomized);
        });
      },
    [],
  );

  useEffect(() => {
    const intervalId = setInterval(updateRecord, 2500);

    return () => clearInterval(intervalId);
  }, [updateRecord]);
};
