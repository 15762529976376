import { InstrumentInstumentCategoryEnum } from '@eih-netbroker/api-instrument';
import classes from './InstrumentCategoryLogo.module.scss';

interface InstrumentCategoryLogoProps {
  instrumentCategory: InstrumentInstumentCategoryEnum;
  className?: string;
}

export const InstrumentCategoryLogo = ({ instrumentCategory, className }: InstrumentCategoryLogoProps) => {
  const getInstrumentCategoryClass = (instrumentCategory: InstrumentInstumentCategoryEnum) => {
    switch (instrumentCategory) {
      case InstrumentInstumentCategoryEnum.Certificate:
        return classes.logoBgIs0;
      case InstrumentInstumentCategoryEnum.CompensationNote:
        return classes.logoBgIs1;
      case InstrumentInstumentCategoryEnum.Currency:
        return classes.logoBgIs2;
      case InstrumentInstumentCategoryEnum.Future:
        return classes.logoBgIs3;
      case InstrumentInstumentCategoryEnum.InvestmentUnit:
        return classes.logoBgIs4;
      case InstrumentInstumentCategoryEnum.Option:
        return classes.logoBgIs5;
      case InstrumentInstumentCategoryEnum.Share:
        return classes.logoBgIs6;
      default:
        return '';
    }
  };

  const getInstrumentCategoryText = (instrumentCategory: InstrumentInstumentCategoryEnum) => {
    switch (instrumentCategory) {
      case InstrumentInstumentCategoryEnum.Certificate:
        return 'CRT';
      case InstrumentInstumentCategoryEnum.CompensationNote:
        return 2;
      case InstrumentInstumentCategoryEnum.Currency:
        return 3;
      case InstrumentInstumentCategoryEnum.Future:
        return 'FU';
      case InstrumentInstumentCategoryEnum.InvestmentUnit:
        return 5;
      case InstrumentInstumentCategoryEnum.Option:
        return 6;
      case InstrumentInstumentCategoryEnum.Share:
        return 7;
      default:
        return '';
    }
  };

  return (
    <div className={classes.logo}>
      <span className={`${classes.logoBg} ${getInstrumentCategoryClass(instrumentCategory)} ${className ?? ''}`}>
        {getInstrumentCategoryText(instrumentCategory)}
      </span>
    </div>
  );
};
