import { ListedInstrument } from './listedInstrument';

export const defaultGroupId = 0;

export interface WidgetGroup {
  id: number;
  color: WidgetGroupColor;
  instrument: ListedInstrument | null;

  // TODO declare other group related data (orderbook, etc)
}

export interface WidgetGroupUpdateDto {
  groupId: number;
  instrument: ListedInstrument | null;
}

export enum WidgetGroupColor {
  Default = 'Default',
  Group1 = 'Group1',
  Group2 = 'Group2',
  Group3 = 'Group3',
  Group4 = 'Group4',
  Group5 = 'Group5',
  Group6 = 'Group6',
  Group7 = 'Group7',
  Group8 = 'Group8',
  Group9 = 'Group9',
}

export const widgetGroupIds: { [key in WidgetGroupColor]: number } = {
  [WidgetGroupColor.Default]: defaultGroupId,
  [WidgetGroupColor.Group1]: 1,
  [WidgetGroupColor.Group2]: 2,
  [WidgetGroupColor.Group3]: 3,
  [WidgetGroupColor.Group4]: 4,
  [WidgetGroupColor.Group5]: 5,
  [WidgetGroupColor.Group6]: 6,
  [WidgetGroupColor.Group7]: 7,
  [WidgetGroupColor.Group8]: 8,
  [WidgetGroupColor.Group9]: 9,
};

export const widgetGroupIdsReverse = (id: number): WidgetGroupColor => {
  return (
    (Object.keys(widgetGroupIds).find(_ => widgetGroupIds[_ as WidgetGroupColor] === id) as WidgetGroupColor) ||
    WidgetGroupColor.Default
  );
};
