import { Button, Paper } from '@mui/material';
import { isValidElement, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useRecoilState } from 'recoil';
import { SSEState } from '../../../context/SSEConnectionContext';
import {
  mockedPortfolioComposition,
  mockedPortfolioCompositionMedium,
  mockedPortfolioCompositionSmall,
} from '../../../modules/portfolio/mock/mockedPortfolioComposition';
import { portfolioCompositionAtom } from '../../../modules/portfolio/states/portfolioCompositionAtom';
import { Keys } from '../../config/keys';
import { eStreamClient } from '../../services/sse/eStreamClient';
import { sseManager } from '../../services/sse/SSEManager';
import { useAuth } from '../../services/useAuth';
import { useFormatter } from '../../services/useFormatter';
import { SubscribeOptions } from '../../types/estream/eStreamSubscription';
import { InstrumentStore } from '../../types/estream/instrumentStore';
import { createLogger, setLogEventCb } from '../../utils/logger';
import { getRandomInteger } from '../../utils/mockUtils';
import { ObjectSet } from '../../utils/objectSet';
import { NbSelect } from '../select/Select';
import { NbTabs, TabItem } from '../tabs/Tabs';
import { NbTextField } from '../text-field/TextField';
import classes from './AppDebug.module.scss';

export type AppDebugProps = {
  visible: boolean;
  hideDebugger: () => void;
  sseState: SSEState;
  instrumentStore: InstrumentStore;
  subscriptionQueue: ObjectSet<SubscribeOptions>;
};

const tabs: Array<TabItem> = [
  {
    label: 'SSE',
    id: 'sse',
  },
  {
    label: 'Instrument store',
    id: 'instrument-store',
  },
  {
    label: 'EStream subscriptions',
    id: 'e-stream-subscriptions',
  },
  {
    label: 'Internal EStream subscriptions',
    id: 'internal-e-stream-subscriptions',
  },
  {
    label: 'Inventory data',
    id: 'inventory',
  },
  {
    label: 'Config',
    id: 'config',
  },
];

export const AppDebug = ({ hideDebugger, sseState, instrumentStore, subscriptionQueue, ...props }: AppDebugProps) => {
  const [selectedTab, setSelectedTab] = useState(0);
  const [logData, setLogData] = useState(`AppDebugger opened ${new Date().toISOString()}`);
  const [loggerWidth, setLoggerWidth] = useState(30);
  const [baseAppData, setBaseAppData] = useState<Array<ReactNode>>();
  const [instrumentStoreData, setInstrumentStoreData] = useState<Array<ReactNode>>();
  const [eStreamSubscriptionData, setEStreamSubscriptionData] = useState<Array<ReactNode>>();
  const [eInternalStreamSubscriptionData, setInternalEStreamSubscriptionData] = useState<Array<ReactNode>>();

  const [portfolioComposition, setPortfolioComposition] = useRecoilState(portfolioCompositionAtom);

  const { appAuth } = useAuth();

  const logger = useRef(createLogger(`AppDebug`, 'trace'));
  const logArea = useRef<HTMLTextAreaElement>(null);
  const formatter = useFormatter();

  const setWidth = (width: number) => {
    logger.current.info(`Set width ${width}`);
    setLoggerWidth(width);
  };

  const renderInteger = useCallback(
    (input: number | string) => <span style={{ color: 'blue' }}>{formatter.formatInteger(input)}</span>,
    [formatter],
  );
  const renderBoolean = useCallback(
    (input: boolean) => <span style={{ color: input ? 'green' : 'red' }}>{input ? `true` : `false`}</span>,
    [],
  );
  const renderString = useCallback((input: string) => <span style={{ color: 'red' }}>"{input}"</span>, []);

  const keyValue = useCallback(
    (key: string, value: any, level = 0): ReactNode => {
      let formattedVal: ReactNode;

      if (!isValidElement(value)) {
        if (value && Array.isArray(value)) {
          if (value.length === 0) {
            return keyValue(`${key}`, <span>[]</span>);
          }
          return [
            keyValue(`${key}`, <span>{`=>`}</span>, level),
            ...value.map((inp, ind) => keyValue(`[${ind}]`, inp, level + 1)),
          ];
        }
        if (value && typeof value === 'object') {
          return [
            keyValue(`${key}`, <span>{`=>`}</span>, level),
            ...Object.keys(value).map(k => {
              if (value.hasOwnProperty(k)) {
                return keyValue(`${k}`, value[k], level + 1);
              }
              return <></>;
            }),
          ];
        }
        if (value === null) {
          return keyValue(`${key}`, <span style={{ color: 'darkcyan' }}>null</span>, level);
        }
        if (value === undefined) {
          return keyValue(`${key}`, <span style={{ color: 'darkcyan' }}>undefined</span>, level);
        }

        switch (typeof value) {
          case 'number':
            formattedVal = renderInteger(value);
            break;
          case 'boolean':
            formattedVal = renderBoolean(value);
            break;
          case 'string':
            formattedVal = renderString(value);
            break;
          default:
            formattedVal = '-';
            break;
        }
      } else {
        formattedVal = value;
      }

      return [
        <div
          className={classes.debugItem}
          style={{ marginLeft: `${10 * level}px` }}
          key={`${key}-${level}`}
        >
          <div className={classes.debugItemKey}>{key}</div>
          <div className={classes.debugItemValue}>{formattedVal}</div>
        </div>,
      ];
    },
    [renderInteger, renderBoolean, renderString],
  );

  const refreshBaseAppData = () => {
    setBaseAppData([
      keyValue('authState', appAuth),
      keyValue('sseManager', {
        instanceId: sseManager.instanceId,
        isPimaryInstanceSelected: sseManager.isPrimaryInstanceSelected,
        primaryInstance: sseManager.primarySSEManagerInstanceId,
        primaryInstanceCandidates: sseManager.primaryInstanceCandidates,
      }),
      keyValue('SSEConnectionContext.sseState', sseState),
    ]);
  };

  const refreshInstrumentStore = () => {
    const subscribedInstruments = instrumentStore.DEBUG_store.keys();
    const debugData: Array<ReactNode> = [keyValue('instrumentStore.size', subscribedInstruments.length)];

    subscribedInstruments.forEach((instrumentId, ind) => {
      if (ind < 50) {
        debugData.push(keyValue(`instrumentStore.${instrumentId}`, instrumentStore.DEBUG_store.get(instrumentId)));
      }
    });
    if (subscribedInstruments.length >= 50) {
      debugData.push(
        keyValue(
          `instrumentStore.50-${subscribedInstruments.length}`,
          <span style={{ color: 'orange' }}>Too many result</span>,
        ),
      );
    }
    setInstrumentStoreData(debugData);
  };

  const refreshEStreamSubscriptions = () => {
    const subscriptions = subscriptionQueue.values();
    setEStreamSubscriptionData([keyValue('eStreamConnection.subscriptions', { subscriptions })]);
  };

  const refreshInternalEStreamSubscriptions = () => {
    const subscriptions = eStreamClient.subscriptions;
    setInternalEStreamSubscriptionData([keyValue('eStreamClient', { subscriptions })]);
  };

  const portfolioDebugData = useMemo(() => {
    return (
      <div>
        <div>
          <Button
            variant={'outlined'}
            onClick={() =>
              setPortfolioComposition({
                investmentLoan: 0,
                isLoading: false,
                portfolioItems: mockedPortfolioCompositionSmall,
              })
            }
          >
            Set Small Account
          </Button>
          <Button
            variant={'outlined'}
            onClick={() =>
              setPortfolioComposition({
                investmentLoan: 0,
                isLoading: false,
                portfolioItems: mockedPortfolioCompositionMedium,
              })
            }
          >
            Set Medium Account
          </Button>
          <Button
            variant={'outlined'}
            onClick={() =>
              setPortfolioComposition({
                investmentLoan: Math.random() > 0.1 ? -1 * getRandomInteger(1000000, 100000000) : undefined,
                isLoading: false,
                portfolioItems: mockedPortfolioComposition,
              })
            }
          >
            Set Large Account
          </Button>
        </div>
        <h5>Current inventory</h5>
        {keyValue('portfolioComposition', portfolioComposition)}
      </div>
    );
  }, [portfolioComposition, keyValue, setPortfolioComposition]);

  const [eStreamUrl, setEStreamUrl] = useState(localStorage.getItem(Keys.FocusTest.estream.streamUrl) || '');
  const [eStreamSubscription, setEStreamSubscriptionUrl] = useState(
    localStorage.getItem(Keys.FocusTest.estream.subscribeUrl) || '',
  );

  const configData = () => {
    return (
      <>
        <p>
          <NbTextField
            size={'small'}
            autoFocus
            value={eStreamUrl}
            onChange={e => {
              setEStreamUrl(e.target.value);
              localStorage.setItem(Keys.FocusTest.estream.streamUrl, e.target.value);
            }}
            label={`EStream URL`}
          />
        </p>
        <p>
          <NbTextField
            size={'small'}
            autoFocus
            value={eStreamSubscription}
            onChange={e => {
              setEStreamSubscriptionUrl(e.target.value);
              localStorage.setItem(Keys.FocusTest.estream.subscribeUrl, e.target.value);
            }}
            label={`EStream subscription URL`}
          />
        </p>
        <p>To changes take effect, please reload the page.</p>
      </>
    );
  };

  useEffect(() => {
    return setLogEventCb((data: string) => {
      setLogData(`${logData}\n${data}`);
      setTimeout(() => {
        if (logArea.current) {
          logArea.current.scrollTop = logArea.current.scrollHeight;
        }
      }, 100);
    });
  }, [setLogData, logData]);

  const [instrumentStoreInterval, setDataRefreshInterval] = useState<NodeJS.Timeout>();

  useEffect(() => {
    if (instrumentStoreInterval) {
      clearInterval(instrumentStoreInterval);
    }

    if (selectedTab === 0) {
      setDataRefreshInterval(setInterval(refreshBaseAppData, 500));
    } else if (selectedTab === 1) {
      setDataRefreshInterval(setInterval(refreshInstrumentStore, 500));
    } else if (selectedTab === 2) {
      setDataRefreshInterval(setInterval(refreshEStreamSubscriptions, 500));
    } else if (selectedTab === 3) {
      setDataRefreshInterval(setInterval(refreshInternalEStreamSubscriptions, 500));
    } else {
      setInstrumentStoreData(undefined);
    }
    // we don't want to listen to instrumentStoreInterval at this point
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTab]);

  if (!props.visible) {
    return <></>;
  }

  return (
    <>
      <div className={classes.container}>
        <div className={classes.infoBoxWrapper}>
          <div
            className={classes.loggerContainer}
            style={{ width: `${loggerWidth}%` }}
          >
            <div className={classes.loggerContainerHeader}>
              <NbSelect
                options={[
                  { id: '15', name: '15%' },
                  { id: '30', name: '30%' },
                  { id: '50', name: '50%' },
                ]}
                onChange={e => setWidth(parseInt(e.target.value))}
                size={'small'}
                value={loggerWidth.toString()}
              />
              <Button
                variant={'text'}
                onClick={() => setLogData('')}
              >
                Clear
              </Button>
            </div>
            <textarea
              value={logData}
              readOnly={true}
              ref={logArea}
            />
            <div>
              <Button onClick={hideDebugger}>Close</Button>
            </div>
          </div>

          <div className={classes.debugDataWrapper}>
            <NbTabs
              tabs={tabs}
              selected={selectedTab}
              onTabChange={e => setSelectedTab(e)}
            />
            {selectedTab === 0 && <Paper className={classes.debugData}>{baseAppData}</Paper>}
            {selectedTab === 1 && <Paper className={classes.debugData}>{instrumentStoreData}</Paper>}
            {selectedTab === 2 && <Paper className={classes.debugData}>{eStreamSubscriptionData}</Paper>}
            {selectedTab === 3 && <Paper className={classes.debugData}>{eInternalStreamSubscriptionData}</Paper>}
            {selectedTab === 4 && <Paper className={classes.debugData}>{portfolioDebugData}</Paper>}
            {selectedTab === 5 && <Paper className={classes.debugData}>{configData()}</Paper>}
          </div>
        </div>
      </div>
    </>
  );
};
