import { useState } from 'react';
import { Keys } from '../config/keys';

export const useLocalStorage = () => {
  const [menuState, setMenuState] = useState(localStorage.getItem(Keys.MenuState) === 'true');

  const setIsMenuCollapsed = (isCollapsed: boolean) => {
    setMenuState(isCollapsed);
    localStorage.setItem(Keys.MenuState, isCollapsed.toString());
  };

  return {
    isMenuCollapsed: menuState,
    setIsMenuCollapsed,
  };
};
