import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import RegiPhoto from '../../../../src/assets/images/erste_registration.png';
import ErsteLogo from '../../../../src/assets/svg/erste_logo_color.svg';
import Hungarian from '../../../../src/assets/svg/hungarian.svg';
import { useLocale } from '../../../context/NbLocalizationContext';
import { NbButton } from '../../../shared/components/button/Button';
import { NbSelect } from '../../../shared/components/select/Select';
import { routes } from '../../../shared/data/routes';
import { useAuth } from '../../../shared/services/useAuth';
import classes from './LoginView.module.scss';

export enum LanguageOptions {
  Hungarian = 'Magyar',
  English = 'English',
}

export const LoginView = () => {
  const { checkIfLoggedIn } = useAuth();
  const { login } = useAuth();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  // const [selectedLanguage] = useState<LanguageOptions>(LanguageOptions.Hungarian);
  const { translation } = useLocale();

  const registrationDetailsList = [
    translation.login.item1,
    translation.login.item2,
    translation.login.item3,
    translation.login.item4,
  ];

  const languageOptions = [
    {
      id: 0,
      name: LanguageOptions.Hungarian,
      logo: <Hungarian />,
    },
    {
      id: 1,
      name: LanguageOptions.English,
    },
  ];

  const loginClicked = async () => {
    try {
      setIsLoading(true);
      await login();
    } catch (err) {
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    (async () => {
      const isLoggedIn = await checkIfLoggedIn();
      if (isLoggedIn) {
        navigate(`${routes.home}`, { replace: true });
      }
    })();
  }, [navigate, checkIfLoggedIn]);

  return (
    <div className={classes.loginView}>
      <div className={classes.loginMobileHeader}>
        <img
          src={RegiPhoto}
          alt={translation.login.registration}
        />
      </div>

      <div className={classes.loginSection}>
        <div className={classes.loginHeader}>
          <div className={classes.logo}>
            <img
              src={ErsteLogo}
              alt={translation.login.loginTitle}
            />
          </div>
          <div className={classes.languageSelector}>
            <NbSelect
              size={'small'}
              defaultValue={languageOptions[0].id}
              options={languageOptions}
            />
          </div>
        </div>

        <div className={classes.loginContent}>
          <h2 className={classes.loginTitle}>{translation.login.loginTitle}</h2>

          <div className={classes.loginButton}>
            <NbButton
              onClick={loginClicked}
              isLoading={isLoading}
            >
              {translation.general.login}
            </NbButton>
          </div>

          <a
            className={classes.loginHelp}
            href="https://www.erstebank.hu"
          >
            {translation.login.help}
          </a>

          <p className={classes.loginRegister}>
            {translation.login.noAccountYet}
            <a href="https://www.erstebank.hu"> {translation.login.registration}</a>
          </p>
        </div>

        <div className={classes.loginFooter}>
          <div className={classes.loginFooterErste}>{translation.login.erste}</div>
          <div className={classes.loginFooterLinks}>
            <span className={classes.loginFooterLink}>
              <a href="https://www.erstebank.hu">{translation.login.contact}</a>
            </span>
            <span className={classes.loginFooterLink}>
              <a href="https://www.erstebank.hu">{translation.login.legalNotice}</a>
            </span>
            <span className={classes.loginFooterLink}>
              <a href="https://www.erstebank.hu">{translation.login.technicalInfo}</a>
            </span>
          </div>
        </div>
      </div>

      <div className={classes.registrationSection}>
        <h2 className={classes.registrationTitle}>{translation.login.registrationTitle}</h2>
        <p className={classes.registrationSubtitle}>{translation.login.registrationSubtitle}</p>
        <ul className={classes.registrationList}>
          {registrationDetailsList.map((item, index) => (
            <li
              key={index}
              className={classes.registrationListItem}
            >
              <span>
                <CheckCircleIcon />
              </span>
              {item}
            </li>
          ))}
        </ul>

        <div className={classes.registrationButtons}>
          <NbButton>{translation.login.registration}</NbButton>
          <NbButton color={'secondary'}>{translation.login.details}</NbButton>
        </div>

        <div className={classes.registrationPhoto}>
          <img
            src={RegiPhoto}
            alt={translation.login.registration}
          />
        </div>
      </div>
    </div>
  );
};
