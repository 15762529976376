import { useRef } from 'react';
import { ProductDetailsListRow } from './ProductDetailsListRow';
import classes from './ProductDetailsSection.module.scss';

export interface ProductDetailsSectionItem {
  label: string;
  value: number | string;
}

interface ProductDetailsSectionProps {
  title: string;
  list1: Array<ProductDetailsSectionItem>;
  list2: Array<ProductDetailsSectionItem>;
}

export const ProductDetailsSection = ({ list1, list2, title }: ProductDetailsSectionProps) => {
  const containerRef = useRef<HTMLUListElement>(null);

  return (
    <div>
      <div className={classes.header}>{title}</div>
      <div className={classes.content}>
        <ul
          className={classes.contentList}
          ref={containerRef}
        >
          {list1.map(item => (
            <li key={item.label}>
              <ProductDetailsListRow {...item} />
            </li>
          ))}
        </ul>
        <ul
          className={classes.contentList}
          ref={containerRef}
        >
          {list2.map(item => (
            <li key={item.label}>
              <ProductDetailsListRow {...item} />
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
