import { CheckCircle, Close, ErrorRounded, Info } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { closeSnackbar, CustomContentProps, SnackbarContent, VariantType } from 'notistack';
import { forwardRef, ReactNode, useCallback } from 'react';
import classes from './Snackbar.module.scss';

interface NBSnackbarProps extends CustomContentProps {
  title: string;
}

const snackbarClasses: { [key in VariantType]: string } = {
  default: classes.snackbarIsInfo,
  error: classes.snackbarIsError,
  info: classes.snackbarIsInfo,
  success: classes.snackbarIsSuccess,
  warning: classes.snackbarIsWarning,
};

const pinnedSnackbarClasses: { [key in VariantType]: string } = {
  default: classes.snackbarIsInfoIsPinned,
  error: classes.snackbarIsErrorIsPinned,
  info: classes.snackbarIsInfoIsPinned,
  success: classes.snackbarIsSuccessIsPinned,
  warning: classes.snackbarIsWarningIsPinned,
};

const icon: { [key in VariantType]: ReactNode } = {
  default: <Info />,
  error: <ErrorRounded />,
  info: <Info />,
  success: <CheckCircle />,
  warning: <CheckCircle />,
};

export const NBSnackbar = forwardRef<HTMLDivElement, NBSnackbarProps>(
  // eslint-disable-next-line
  ({ id, message, iconVariant, persist, anchorOrigin, hideIconVariant, autoHideDuration, title, ...props }, ref) => {
    const handleDismiss = useCallback(() => {
      closeSnackbar(id);
    }, [id]);

    return (
      <SnackbarContent
        ref={ref}
        {...props}
        className={`${classes.snackbar} ${snackbarClasses[props.variant]} ${
          persist && pinnedSnackbarClasses[props.variant]
        }`}
      >
        {icon[props.variant]}
        <div className={classes.content}>
          <div className={`${classes.snackbarTitle}`}>{title}</div>
          <div className={`${classes.snackbarMessage}`}>{message}</div>
        </div>

        <IconButton
          className={classes.snackbarClose}
          onClick={handleDismiss}
        >
          <Close className={classes.snackbarClose} />
        </IconButton>
      </SnackbarContent>
    );
  },
);
