import { useCallback } from 'react';
import { useRecoilValue } from 'recoil';
import { dictionariesAtom } from '../state/dictionariesAtom';

export const useDictionary = () => {
  const dictionaries = useRecoilValue(dictionariesAtom);

  const formatAccountCategoryType = useCallback(
    (id: string) => {
      return dictionaries.accountCategoryTypes.find(accountCategoryType => accountCategoryType.id === id)?.name || id;
    },
    [dictionaries],
  );

  const formatInstrumentCategoryType = useCallback(
    (id: string) => {
      return (
        dictionaries.instrumentCategoryTypes.find(instrumentCategoryType => instrumentCategoryType.id === id)?.name ||
        id
      );
    },
    [dictionaries],
  );

  return {
    formatAccountCategoryType,
    formatInstrumentCategoryType,
  };
};
