import { useState } from 'react';
import { useRecoilState } from 'recoil';
import { useSSEConnection } from '../../context/SSEConnectionContext';
import { AuthResource } from '../resources/auth.resource';
import { UserSettingsResource } from '../resources/userSettings.resource';
import { appAuthAtom, AuthState } from '../state/authStateAtom';
import { layoutStateAtom } from '../state/layoutStateAtom';
import { watchListGroupsAtom } from '../state/watchListStateAtom';
import { widgetStateAtom } from '../state/widgetStateAtom';
import { DefaultLayout } from '../types/layoutState';
import { createLogger } from '../utils/logger';
import { wait } from '../utils/mockUtils';
import { sseManager } from './sse/SSEManager';
import { useAuthEvents } from './useAuthEvents';
import { useResource } from './useResource';
import { useSession } from './useSession';

export const useAuth = () => {
  const logger = createLogger('useAuth');
  const authResource = useResource(AuthResource);
  const userSettingsResource = useResource(UserSettingsResource);
  const [appAuth, setAppAuth] = useRecoilState(appAuthAtom);
  const [, setWatchListGroups] = useRecoilState(watchListGroupsAtom);
  const [, setWidgetState] = useRecoilState(widgetStateAtom);
  const [, setLayoutState] = useRecoilState(layoutStateAtom);
  const { connectEStream, disconnectEStream } = useSSEConnection();
  const [initInProgress, setInitInProgress] = useState<boolean>(false);
  const authEvents = useAuthEvents();
  const { sessionInit } = useSession();

  const login = async (): Promise<void> => {
    try {
      logger.info(`Logging in`);
      await authEvents.login('xxx');
    } catch (err) {}
  };

  const logout = async () => {
    logger.info(`Logging out`);
    await authEvents.logout();
    disconnectEStream();
    sseManager.broadcastLogout();
  };

  /**
   * Initializes the application state:
   *  - loading user related data (watchlist, widget settings, etc)
   *  - creates eStream connection
   *  - sets the application state to authenticated
   */
  const initializeApp = async (): Promise<void> => {
    if (!appAuth.isAuthenticated) {
      throw new Error(`Can't initialize app, because user is not authenticated`);
    }
    if (initInProgress) {
      // init is already started
      return;
    }
    setInitInProgress(true);
    try {
      await connectEStream();
    } catch (err) {}
    // TODO load usersettings, watchlist, etc
    await wait(500);
    setWatchListGroups(await userSettingsResource.getWatchListGroups());
    const layoutConfig = await userSettingsResource.getLayoutConfig();
    setLayoutState(layoutConfig);
    setWidgetState(await userSettingsResource.getWidgetConfig(layoutConfig.selectedLayoutId ?? DefaultLayout.BET));

    setAppAuth({ ...appAuth, authStatus: AuthState.Authenticated });
    sessionInit();
    setInitInProgress(false);
  };

  const checkIfLoggedIn = async (): Promise<boolean> => {
    if (appAuth.authStatus !== AuthState.Unknown) {
      return appAuth.isAuthenticated;
    } else {
      setAppAuth({
        isAuthenticated: appAuth.isAuthenticated,
        authStatus: AuthState.Loading,
      });
    }

    try {
      const user = await authResource.getUser();
      setAppAuth({
        isAuthenticated: true,
        authStatus: AuthState.Loading,
        user,
      });

      return true;
    } catch (err) {
      setAppAuth({
        isAuthenticated: false,
        authStatus: AuthState.NotAuthenticated,
      });
    }
    return false;
  };

  return {
    login,
    logout,
    checkIfLoggedIn,
    initializeApp,
    appAuth,
  };
};
