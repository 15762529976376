import { Switch, SwitchProps } from '@mui/material';
import classes from './Switch.module.scss';

export interface NbSwitchProps extends SwitchProps {}

export const NbSwitch = ({ ...props }: NbSwitchProps) => {
  return (
    <Switch
      classes={{ root: classes.switch, switchBase: classes.switchBase, track: classes.switchTrack }}
      {...props}
    />
  );
};
