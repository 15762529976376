import { useCallback, useMemo } from 'react';
import { useLocale } from '../../context/NbLocalizationContext';
import { TriggerState } from '../../modules/trading/states/priceAlertsAtom';
import { NbSelectItemOption } from '../components/select/Select';
import { ExpiryType } from '../types/expiryType';
import { InventoryManagmentMethod } from '../types/inventoryManagmentMethod';
import { ListedInstrument } from '../types/listedInstrument';
import { OrderType } from '../types/orderType';
import { TradingSession } from '../types/tradingSession';

export const useOptions = () => {
  const { translation } = useLocale();
  const orderTypeOptions: NbSelectItemOption<OrderType>[] = useMemo(
    () =>
      Object.values(OrderType).map(value => ({
        id: value,
        name: translation.orderTypeOptions[value],
      })),
    [translation],
  );

  const inventoryManagmentMethodOptions: NbSelectItemOption<InventoryManagmentMethod>[] = useMemo(
    () =>
      Object.values(InventoryManagmentMethod).map(value => ({
        id: value,
        name: translation.inventoryManagmentMethodOptions[value],
      })),
    [translation],
  );

  const triggerStateOptions: NbSelectItemOption<TriggerState>[] = useMemo(
    () =>
      Object.values(TriggerState).map(value => ({
        id: value,
        name: translation.priceAlerts.modal[value],
      })),
    [translation],
  );

  const betTradingSessionOptions: NbSelectItemOption<TradingSession>[] = useMemo(
    () =>
      [TradingSession.Open, TradingSession.Auction, TradingSession.Closing, TradingSession.Opening].map(value => ({
        id: value,
        name: translation.tradingSessionOptions[value],
      })),
    [translation],
  );

  const xetradingSessionOptions: NbSelectItemOption<TradingSession>[] = useMemo(
    () =>
      [TradingSession.Open].map(value => ({
        id: value,
        name: translation.tradingSessionOptions[value],
      })),
    [translation],
  );

  const usaTradingSessionOptions: NbSelectItemOption<TradingSession>[] = useMemo(
    () =>
      [TradingSession.Open, TradingSession.PreMarket].map(value => ({
        id: value,
        name: translation.tradingSessionOptions[value],
      })),
    [translation],
  );

  const getTradingSessionOptions = useCallback(
    (instrument: ListedInstrument | null | undefined) => {
      if (!instrument) {
        return [];
      } else if (instrument.marketShortName === 'BET') {
        return betTradingSessionOptions;
      } else if (instrument.marketName === 'XETRA') {
        return xetradingSessionOptions;
      }

      return usaTradingSessionOptions;
    },
    [betTradingSessionOptions, xetradingSessionOptions, usaTradingSessionOptions],
  );

  const expiryTypeOptions: NbSelectItemOption<ExpiryType>[] = useMemo(
    () => Object.values(ExpiryType).map(value => ({ id: value, name: translation.expiryOptions[value] })),
    [translation],
  );

  const selectAllOption: NbSelectItemOption<string> = useMemo(
    () => ({ id: '', name: translation.general.emptySelectAll }),
    [translation],
  );

  return {
    orderTypeOptions,
    inventoryManagmentMethodOptions,
    getTradingSessionOptions,
    expiryTypeOptions,
    selectAllOption,
    betTradingSessionOptions,
    usaTradingSessionOptions,
    xetradingSessionOptions,
    triggerStateOptions,
  };
};
