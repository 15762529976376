import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { Identifier, XYCoord } from 'dnd-core';
import { ReactNode, useEffect, useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import classes from './DraggableItem.module.scss';

export type DraggableItemProps = {
  id: string;
  index: number;
  moveCard: (dragIndex: number, hoverIndex: number) => void;
  moveCardEnd: () => void;
  children: ReactNode;
  onDragging: () => void;
};

interface DragItem {
  index: number;
  id: string;
}

export const DraggableItem = ({ id, children, index, onDragging, moveCard, moveCardEnd }: DraggableItemProps) => {
  const previewRef = useRef<HTMLLIElement>(null);
  const ref = useRef<HTMLDivElement>(null);
  const [{ handlerId }, drop] = useDrop<DragItem, void, { handlerId: Identifier | null }>({
    accept: 'item',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    drop() {
      moveCardEnd();
    },
    hover(item: DragItem, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });

  const [{ opacity, isDragging }, drag, preview] = useDrag({
    type: 'item',
    item: () => {
      return { id, index };
    },
    collect: (monitor: any) => ({
      opacity: monitor.isDragging() ? 0.4 : 1,
      isDragging: monitor.isDragging(),
    }),
  });

  drag(drop(ref));
  preview(previewRef);

  useEffect(() => {
    if (isDragging) {
      onDragging();
    }
  }, [isDragging, onDragging]);

  return (
    <li
      ref={previewRef}
      className={classes.item}
      style={{ opacity }}
      data-handler-id={handlerId}
    >
      <div
        ref={ref}
        className={classes.itemDragIndicator}
      >
        <DragIndicatorIcon />
      </div>

      {children}
    </li>
  );
};
