import { useLocale } from '../../../context/NbLocalizationContext';
import { useSSEConnection } from '../../../context/SSEConnectionContext';
import { InstrumentSearchAutocomplete } from '../../../shared/components/instrument-search-autocomplete/InstrumentSearchAutocomplete';
import { ListedInstrument } from '../../../shared/types/listedInstrument';
import { WidgetElement } from '../../../shared/types/widgetElement';
import { ProductDetailsHeader } from '../partials/ProductDetailsHeader';
import { ProductDetailsSection } from '../partials/ProductDetailsSection';
import { useProductDetails } from '../services/useProductDetails';
import { useProductDetailsList } from '../services/useProductDetailsLists';
import { useWidgetGroup } from '../services/useWidgetGroup';
import { useWidgetInstrument } from '../services/useWidgetInstrument';
import classes from './ProductDetailsWidget.module.scss';

export const ProductDetailsWidget = (props: WidgetElement) => {
  const widgetGroup = useWidgetGroup(props.groupId);
  const { translation } = useLocale();
  const { instrument, updateInstrument } = useWidgetInstrument(widgetGroup);
  const { hasEStreamConnection } = useSSEConnection();
  const source = `product-details-${props.groupId}`;

  const { product } = useProductDetails(instrument, source);

  const {
    tradingSectionItems1,
    tradingSectionItems2,
    fundamentalSectionItems1,
    fundamentalSectionItems2,
    instrumentSectionItems1,
    instrumentSectionItems2,
  } = useProductDetailsList(instrument);

  const selectInstrument = (instrument: ListedInstrument | null) => {
    updateInstrument(instrument);
  };

  return (
    <div className={classes.container}>
      <InstrumentSearchAutocomplete
        className={classes.searchInput}
        isPopperWide
        onSelectInstrument={selectInstrument}
        selection={instrument}
      />
      {!instrument && translation.productDetails.selectInstrument}

      {instrument && product && (
        <>
          {hasEStreamConnection && (
            <ProductDetailsHeader
              className={classes.containerHeader}
              instrument={instrument}
              product={product}
            />
          )}
          <div className={classes.sectionsContainer}>
            <ProductDetailsSection
              title={translation.productDetails.tradingData}
              list1={tradingSectionItems1}
              list2={tradingSectionItems2}
            />
            <ProductDetailsSection
              title={translation.productDetails.fundamentalIndicators}
              list1={fundamentalSectionItems1}
              list2={fundamentalSectionItems2}
            />
            <ProductDetailsSection
              title={translation.productDetails.instrumentData}
              list1={instrumentSectionItems1}
              list2={instrumentSectionItems2}
            />
          </div>
        </>
      )}
    </div>
  );
};
