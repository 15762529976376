import ChangeHistoryIcon from '@mui/icons-material/ChangeHistory';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DownloadIcon from '@mui/icons-material/Download';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useState } from 'react';
import { ValidationError } from 'yup';
import { useDialog } from '../../../context/DialogContext';
import { useLocale } from '../../../context/NbLocalizationContext';
import { NbButton } from '../../../shared/components/button/Button';
import { NbIconButton } from '../../../shared/components/icon-button/IconButton';
import { NbNumericFormat } from '../../../shared/components/numeric-format/numericFormat';
import { NbTextSwitch } from '../../../shared/components/switch/TextSwitch';
import { NbTextField } from '../../../shared/components/text-field/TextField';
import textFieldClasses from '../../../shared/components/text-field/TextField.module.scss';
import { ExpiryType } from '../../../shared/types/expiryType';
import { InventoryManagmentMethod } from '../../../shared/types/inventoryManagmentMethod';
import { ListedInstrument } from '../../../shared/types/listedInstrument';
import { OrderType } from '../../../shared/types/orderType';
import { TradeType } from '../../../shared/types/tradeType';
import { SimpleTradeTicketWidgetElement, WidgetElement } from '../../../shared/types/widgetElement';
import { simpleTradeOrderSchema } from '../schemas/orderSchemas';
import { useWidget } from '../services/useWidget';
import { useWidgetGroup } from '../services/useWidgetGroup';
import { useWidgetInstrument } from '../services/useWidgetInstrument';
import { OrderModal } from './OrderModal';
import classes from './SimpleTradeTicketWidget.module.scss';
import { WidgetInstrumentSearchAndDisplay } from './WidgetInstrumentSearchAndDisplay';

export interface SimpleTradeTicketForm {
  instrument?: ListedInstrument | null;
  orderType?: OrderType;
  tradeType?: TradeType;
  quantity?: number;
  stopLimitPrice?: number;
}

export const SimpleTradeTicketWidget = (props: WidgetElement) => {
  const simpleTradeTicketWidgetElement = props as SimpleTradeTicketWidgetElement;
  const { payLoad: order = { orderType: OrderType.Market } } = simpleTradeTicketWidgetElement;
  const locale = useLocale();
  const widgetGroup = useWidgetGroup(props.groupId);
  const { instrument, updateInstrument } = useWidgetInstrument(widgetGroup);
  const { openDialog } = useDialog();
  const { updateWidget } = useWidget();
  const [errors, setErrors] = useState<Record<keyof SimpleTradeTicketForm, string> | undefined>(undefined);

  const handleChange = <T extends keyof SimpleTradeTicketForm>(key: T, value: SimpleTradeTicketForm[T]) => {
    setErrors(undefined);
    updateWidget({ ...simpleTradeTicketWidgetElement, payLoad: { ...order, [key]: value } });
  };

  const createOrder = (tradeType: TradeType) => {
    const simpleTradeOrder = {
      ...order,
      instrument: instrument,
      expiryType: ExpiryType.Day,
      inventoryManagmentMethod: InventoryManagmentMethod.Lifo,
      tradeType,
    };
    try {
      console.log(simpleTradeOrder.orderType);
      simpleTradeOrderSchema.validateSync(simpleTradeOrder, { abortEarly: false });

      openDialog((close: () => void) => (
        <OrderModal
          onCancel={close}
          order={{
            ...order,
            instrument: instrument,
            expiryType: ExpiryType.Day,
            inventoryManagmentMethod: InventoryManagmentMethod.Lifo,
            tradeType,
          }}
          defaultContent="confirm"
        />
      ));
    } catch (error) {
      debugger;
      const validationError = error as ValidationError;
      const validationErrors = validationError.inner.reduce((acc, error) => {
        acc[error.path as keyof SimpleTradeTicketForm] = error.message;
        return acc;
      }, {} as Record<keyof SimpleTradeTicketForm, string>);
      setErrors(validationErrors);
    }
  };

  return (
    <div className={classes.container}>
      <WidgetInstrumentSearchAndDisplay
        source={`simple-trade-ticket-widget-${props.groupId || 'default-group'}`}
        instrument={instrument}
        updateInstrument={updateInstrument}
      />
      <div className={classes.content}>
        <div className={classes.buttonRow}>
          <NbButton
            onClick={() => createOrder(TradeType.Sell)}
            className={classes.buttonRowSellButton}
            fullWidth
          >
            {`${locale.translation.simpleTradeTicket.sell}${order.orderType === OrderType.Market ? '\n654 334' : ''}`}
          </NbButton>
          <NbButton
            onClick={() => createOrder(TradeType.Buy)}
            className={classes.buttonRowBuyButton}
            fullWidth
          >
            {`${locale.translation.simpleTradeTicket.buy}${order.orderType === OrderType.Market ? '\n654 334' : ''}`}
          </NbButton>
        </div>

        <NbTextSwitch
          left={{
            name: locale.translation.simpleTradeTicket.marketPrice,
            value: OrderType.Market,
          }}
          right={{
            name: locale.translation.simpleTradeTicket.limitPrice,
            value: OrderType.Limit,
          }}
          className={classes.switch}
          onChange={value => handleChange('orderType', value)}
          selectedValue={order.orderType}
        />

        <div className={classes.row}>
          <div className={classes.rowColumn}>
            <span className={classes.label}>{locale.translation.simpleTradeTicket.quantity}</span>

            <NbNumericFormat
              value={order.quantity ?? ''}
              decimalScale={0}
              onValueChange={e => handleChange('quantity', e.floatValue)}
              className={classes.rowTextField}
              error={!!errors?.quantity}
              size="small"
              InputProps={{
                endAdornment: (
                  <span
                    className={`${textFieldClasses.nbTextFieldAdornment} ${textFieldClasses.nbTextFieldAdornmentIsEnd}`}
                  >
                    db
                  </span>
                ),
              }}
              customInput={NbTextField}
            />
          </div>
          <div className={classes.rowColumn}>
            <div className={classes.row}>
              <span className={classes.label}>{locale.translation.simpleTradeTicket.stock}</span>
              <InfoOutlinedIcon
                fontSize="inherit"
                className={classes.infoIcon}
              />
            </div>
            <NbButton
              color="emphasize"
              size="small"
              startIcon={<ContentCopyIcon />}
            >
              423 db
            </NbButton>
          </div>
        </div>

        <div className={`${classes.row} ${order.orderType === OrderType.Market ? classes.rowIsDisabled : ''}`}>
          <div className={classes.rowColumn}>
            <span className={classes.label}>{locale.translation.simpleTradeTicket.limitPrice}</span>
            <NbNumericFormat
              value={order.stopLimitPrice ?? ''}
              onValueChange={e => handleChange('stopLimitPrice', e.floatValue)}
              error={!!errors?.stopLimitPrice}
              className={classes.rowTextField}
              size="small"
              InputProps={{
                endAdornment: (
                  <span
                    className={`${textFieldClasses.nbTextFieldAdornment} ${textFieldClasses.nbTextFieldAdornmentIsEnd}`}
                  >
                    HUF
                  </span>
                ),
              }}
              customInput={NbTextField}
            />
          </div>
          <div className={classes.rowColumn}>
            <div className={classes.row}>
              <span className={classes.label}></span>
              <InfoOutlinedIcon
                fontSize="inherit"
                className={classes.infoIcon}
              />
            </div>
            <NbButton
              variant="contained"
              color="emphasize"
              size="small"
              startIcon={<ContentCopyIcon />}
            >
              {locale.translation.simpleTradeTicket.middlePrice}
            </NbButton>
          </div>
        </div>
      </div>
      <div className={classes.footer}>
        <div className={classes.footerTop}>
          <ChangeHistoryIcon fontSize="inherit" />
          <span>100 x 5 HUF = 500 HUF</span>
          <InfoOutlinedIcon
            fontSize="inherit"
            className={classes.infoIcon}
          />
        </div>
        <div className={classes.footerBottom}>
          <div className={classes.footerBottomRow}>
            <div className={classes.footerBottomColumn}>
              <div className={classes.footerBottomRow}>
                <span>{locale.translation.simpleTradeTicket.expectedCost}</span>
                <span>{locale.translation.simpleTradeTicket.totalValue}</span>
              </div>
              <div className={classes.footerBottomRow}>
                <span>3.500 HUF</span>
                <span>1.432.511 HUF</span>
              </div>
            </div>
            <NbIconButton
              style={{ height: 18, width: 18 }}
              color="inherit"
              className={classes.downloadIcon}
              icon={<DownloadIcon sx={{ height: 10, width: 10 }} />}
            ></NbIconButton>
          </div>
        </div>
      </div>
    </div>
  );
};
