import { atomFamily } from 'recoil';

export interface ProductDetailsBase {
  // Utolsó kötés
  lastPrice: number;
  // Nyító ár
  openPrice: number;
  // Napi minimum
  low: number;
  // Napi maximum
  high: number;
  // Átlagár
  averagePrice: number;
  // Forgalom (db)
  volumeQuantity: number;
  // Forgalom (érték)
  volumeValue: number;
  // 30 napos átlagforgalom(db)
  monthlyAverageVolume: number;
  // 52 heti minimum
  annualLow: number;
  // 52 heti maximum
  annualHigh: number;
  // Előző záró
  previousClose: number;
  // Napi változás
  dailyChange: number;
  // Napi változás (%)
  dailyChangePercent: number;
  // YTD árfolyam változás
  YTDChangePercent: number;
  // Piaci kapitalizáció
  marketCapitalization: number;
  // EPS (TTM)
  earningsPerShareTTM: number;
  // P/BV
  priceToBookValue: number;
  // P/E (Várakozás)
  priceToEarnings: number;
  // P/E (TTM)
  priceToEarningsTTM: number;
  // P/S (TTM)
  priceToSalesTTM: number;
  // Osztalék
  dividend: number;
  // Osztalékhozam
  dividendYield: number;
  // 5 éves osztalékhozam
  dividendYield5Years: number;
  // EBITDA
  EBITDA: number;
  // ROE
  ROE: number;
  // ROA
  ROA: number;
}

// export type ProductDetails = {
//   instrument: null
// } | (ProductDetailsBase & {
//   instrument: ListedInstrument;
// });

export const productDetailstStateAtom = atomFamily<ProductDetailsBase | undefined, string>({
  key: 'productDetails/instrumentum',
  default: undefined,
});
