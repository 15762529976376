import { Subscription } from '@eih-netbroker/api-partner-data';

function createSubscription(marketDataProvider: string, order: number, currency: string): Subscription {
  return {
    marketDataProvider,
    order,
    marketDepth: 5,
    realtime: marketDataProvider === 'bet',
    subscriptionPackageType: 'XXX',
    subscriptionPackagePriceId: 2241,
    subscriptionPackagePrice: 1420,
    subscriptionPackagePriceCcy: currency,
  };
}

export const mockedPartnerData: Array<Subscription> = [
  createSubscription('bet', 0, 'HUF'),
  createSubscription('xetraFr', 1, 'EUR'),
  createSubscription('xetraVie', 2, 'EUR'),
  createSubscription('otc', 3, 'EUR'),
  createSubscription('usa', 4, 'USD'),
  createSubscription('london', 5, 'GBP'),
];
