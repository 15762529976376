import { useLocale } from '../../../context/NbLocalizationContext';
import { Alert } from '../state/alertGroupsAtom';
import classes from './AlertCenterList.module.scss';
import { AlertCenterListRow } from './AlertCenterListRow';

export interface AlertCenterListProps {
  alerts: Alert[];
  onlyUnreads?: boolean;
}

export const AlertCenterList = ({ alerts, onlyUnreads }: AlertCenterListProps) => {
  const { translation } = useLocale();
  return (
    <ul className={`${classes.list} ${alerts.length === 0 ? classes.listIsEmpty : ''}`}>
      {alerts.length === 0 && !onlyUnreads && <span>{translation.alertCenter.noNewAlert}</span>}
      {alerts.length === 0 && onlyUnreads && <span>{translation.alertCenter.noUnreadAlert}</span>}
      {alerts.map(alert => (
        <AlertCenterListRow alert={alert} />
      ))}
    </ul>
  );
};
