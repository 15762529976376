import { date, number, object, string } from 'yup';
import { ExpiryType } from '../../../shared/types/expiryType';
import { OrderType } from '../../../shared/types/orderType';
import { TradeType } from '../../../shared/types/tradeType';

export const normalOrderSchema = object().shape(
  {
    instrument: object().required(),
    orderType: string().required(),
    tradeType: string().required(),
    quantity: number().required(),
    stopLimitPrice: number().when('orderType', orderType => {
      return orderType.includes(OrderType.StopLimit) || orderType.includes(OrderType.Limit)
        ? number().required()
        : number().notRequired();
    }),
    stopActivationPrice: number().when('orderType', orderType => {
      return orderType.includes(OrderType.StopLimit) || orderType.includes(OrderType.StopMarket)
        ? number().required('Aktiválási ár kitöltése kötelező')
        : number().notRequired();
    }),
    expiryType: string().required(),
    expiry: date().when('expiryType', expiryType => {
      return expiryType.includes(ExpiryType.Gtd) ? date().required() : date().notRequired();
    }),
    inventoryManagmentMethod: string().when('tradeType', tradeType => {
      return tradeType.includes(TradeType.Sell) ? string().required() : string().notRequired();
    }),
    tradingSession: string().required(),
  },
  [
    ['orderType', 'orderType'],
    ['expiryType', 'expiryType'],
    ['tradeType', 'tradeType'],
  ],
);

export const simpleTradeOrderSchema = object().shape(
  {
    instrument: object().required(),
    orderType: string().required(),
    tradeType: string().required(),
    quantity: number().required(),
    stopLimitPrice: number().when('orderType', orderType => {
      return orderType.includes(OrderType.Limit) ? number().required() : number().notRequired();
    }),
  },
  [['orderType', 'orderType']],
);
