/**
 * Creates an array of string or number which contains only unique items
 * @param list array of string or number
 * @result array of string or number
 * @example
 *
 * const uniques = uniqueArray([1,1,1,3,2,1]); // -> [1,3,2]
 */
export const uniqueArray = <T extends string | number>(list: Array<T>): Array<T> => {
  return [...Array.from(new Set(list))];
};

/**
 * Creates a unique array of items by a key
 * @param list array of objects
 * @param key key of the object
 */
export const uniqueArrayBy = <T, K extends keyof T>(list: Array<T>, key: K): Array<T[K]> => {
  return [...Array.from(new Set(list.map(item => item[key])))];
};

/**
 * Removes elements from an array
 * @param list array of string or number
 * @param excludedItem a single item OR Array of items to be excluded
 * @result array of string or number
 * @example
 *
 * const list = [1,2,3,4,5];
 *
 * const primeList = excludeFromArray(list, 4); // [1,2,3,5]
 *
 * const evenList = excludeFromArray(list, [2,4]); // -> [1,3,5]
 *
 */
export const excludeFromArray = <T extends string | number>(list: Array<T>, excludedItem: T | Array<T>): Array<T> => {
  if (Array.isArray(excludedItem)) {
    return list.filter(item => !excludedItem.includes(item));
  }
  return list.filter(item => item !== excludedItem);
};

export const findById = <T extends { id: string }>(list: Array<T>, id: string): T | undefined => {
  return list.find(item => item.id === id);
};

// TODO - remove this function and use lodash isArrayEqual
export const isArrayEqual = <T extends any>(array1: Array<T>, array2: Array<T>): boolean => {
  return JSON.stringify(array1) === JSON.stringify(array2);
};

/**
 * Groups an array of objects by a key
 * @param list array of objects
 * @param key key of the object
 */
export const groupBy = <T extends { [key: string]: any }>(list: Array<T>, key: string): { [key: string]: Array<T> } => {
  return list.reduce((acc, item) => {
    const group = item[key];
    if (!acc[group]) {
      acc[group] = [];
    }
    acc[group].push(item);
    return acc;
  }, {} as { [key: string]: Array<T> });
};

/**
 * Merges numeric values of an array of objects
 * @param list array of objects
 * @param excludeIncludeFields exclude or include fields from the merge
 */
export const mergeNumericValues = <T extends { [key: string]: any }>(
  list: Array<T>,
  excludeIncludeFields?: {
    exclude?: Array<keyof T>;
    include?: Array<keyof T>;
  },
): T => {
  const exclude = excludeIncludeFields?.exclude || [];
  const include = excludeIncludeFields?.include || [];

  return list.reduce((mergedItem, next) => {
    const raw: T = { ...mergedItem };
    Object.keys(next).forEach(field => {
      if (next.hasOwnProperty(field)) {
        if (typeof next[field] === 'number' && !Number.isNaN(next[field])) {
          if (exclude.includes(field)) {
            return;
          }
          if (include.includes(field) || include.length === 0) {
            (raw[field] as any) = (raw[field] || 0) + next[field];
          }
        } else {
          (raw[field] as any) = next[field];
        }
      }
    });
    return raw;
  }, {} as T);
};

/**
 * Groups an array of objects by a key and merges numeric values
 * @param list array of objects
 * @param groupByKey key of the object
 * @param excludeIncludeFields exclude or include fields from the merge
 */
export const groupByAndMergeNumericValues = <
  T extends {
    [key: string]: any;
  },
>(
  list: Array<T>,
  groupByKey: string,
  excludeIncludeFields: {
    exclude?: Array<string>;
    include?: Array<string>;
  },
): Array<T> => {
  const grouped = groupBy(list, groupByKey);
  return Object.keys(grouped).reduce((acc, group) => {
    const newItem = mergeNumericValues(grouped[group], excludeIncludeFields);
    acc.push(newItem);
    return acc;
  }, [] as Array<T>);
};
