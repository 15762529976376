export enum WidgetType {
  SimpleTradeTicket = 'SimpleTradeTicket', //Villamkotjegy
  TradeTicket = 'TradeTicket', //Teljes kotjegy
  OrderBook = 'OrderBook', //AjanlatiKonyv
  ProductDetails = 'ProductDetails', //TermekAdatlap
  WatchList = 'WatchList', //Figyelolista
  Chart = 'Chart',
  NetPositions = 'NetPositions', //NettoPoziciok
  OpenOrders = 'OpenOrders', //NyitottMegbizasok
  BETFillList = 'BETFillList', //BETKoteslista
  OpenPositions = 'OpenPositions', //Nyitott pozíciók
  Order = 'Order', //Megbizas
  OrderLog = 'OrderLog', //Megbizasi Napló
  PriceAlerts = 'PriceAlerts', //Arfolyam ertesito
}
