import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { useLocale } from '../../../context/NbLocalizationContext';
import { NbButton } from '../../../shared/components/button/Button';
import { useDictionary } from '../../../shared/services/useDictionary';
import { PortfolioCompositionView } from '../types/portfolioCompositionView';
import classes from './PortfolioCompositionHeader.module.scss';

export type PortfolioCompositionHeaderProps = {
  selectedAccount: string | null;
  selectedInstrumentClass: string | null;
  view: PortfolioCompositionView | null;
  resetAccounts: () => void;
  resetInstrumentClass: () => void;
  selectAccountsView: () => void;
  setView: (view: PortfolioCompositionView) => void;
};

export const PortfolioCompositionHeader = ({
  selectedAccount,
  selectedInstrumentClass,
  view,
  resetAccounts,
  resetInstrumentClass,
  selectAccountsView,
  setView,
}: PortfolioCompositionHeaderProps) => {
  const { translation } = useLocale();
  const { formatInstrumentCategoryType } = useDictionary();
  return (
    <div className={classes.header}>
      {selectedAccount === null && <h4>{translation.portfolioComposition.allAccounts}</h4>}
      {selectedAccount !== null && (
        <NbButton
          onClick={resetAccounts}
          color={'secondary'}
          variant={'text'}
        >
          <ChevronLeftIcon />
          {selectedAccount}
        </NbButton>
      )}
      <div className={classes.headerButtons}>
        {view !== PortfolioCompositionView.Instruments && (
          <>
            <NbButton
              color={view === PortfolioCompositionView.Accounts ? 'primary' : 'secondary'}
              className={view === PortfolioCompositionView.Accounts ? classes.headerButtonIsSelected : ''}
              onClick={() => selectAccountsView()}
            >
              {translation.portfolioComposition.accounts}
            </NbButton>
            <NbButton
              color={view === PortfolioCompositionView.InstrumentClass ? 'primary' : 'secondary'}
              className={view === PortfolioCompositionView.InstrumentClass ? classes.headerButtonIsSelected : ''}
              onClick={() => setView(PortfolioCompositionView.InstrumentClass)}
            >
              {translation.portfolioComposition.instrumentClass}
            </NbButton>
          </>
        )}
        {view === PortfolioCompositionView.Instruments && selectedInstrumentClass && (
          <NbButton
            color={'secondary'}
            onClick={() => resetInstrumentClass()}
          >
            {formatInstrumentCategoryType(selectedInstrumentClass) || '-'}
          </NbButton>
        )}
      </div>
    </div>
  );
};
