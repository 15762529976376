import { ArrowDropDown } from '@mui/icons-material';
import { DatePickerProps, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useLocale } from '../../../context/NbLocalizationContext';
import classes from './DatePicker.module.scss';

interface NbDatePickerProps<TDate> extends DatePickerProps<TDate> {
  size?: 'small' | 'normal';
}

export const NbDatePicker = <TDate extends any>({ size = 'small', ...props }: NbDatePickerProps<TDate>) => {
  const { translation } = useLocale();

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        className={`${classes.nbDatePicker} ${size === 'small' ? classes.nbDatePickerIsSmall : ''}`}
        slots={{
          openPickerIcon: ArrowDropDown,
        }}
        showDaysOutsideCurrentMonth
        format={translation.datePicker.defaultFormat}
        {...props}
        slotProps={{
          openPickerButton: {
            style: {
              marginRight: 0,
            },
          },
          ...props.slotProps,
        }}
      />
    </LocalizationProvider>
  );
};
