import { atom } from 'recoil';
import { Order } from '../../../shared/types/order';

export type Orders = {
  orders: Array<Order>;
  isLoading: boolean;
};

export const ordersAtom = atom<Orders>({
  key: 'trading/orders',
  default: {
    orders: [],
    isLoading: false,
  },
});
