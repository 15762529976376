import { Config } from '../../config/config';
import { Keys } from '../../config/keys';
import {
  InstrumentSubscriptionDataScope,
  InstrumentSubscriptionMap,
  InstrumentSubscriptionRequestItem,
  SubscribeOptions,
} from '../../types/estream/eStreamSubscription';
import { EStreamTradeDTO } from '../../types/estream/eStreamTrade';
import { ListedInstrumentLike } from '../../types/listedInstrument';
import { isArrayEqual, uniqueArray } from '../../utils/arrayUtils';
import { ObjectSet } from '../../utils/objectSet';
import { SSEClient } from './SSEClient';

const getInstrumentSubscriptionId = (item: ListedInstrumentLike): string => `${item.ric}_${item.mic}`;

const replaceSourceDataScope = (
  item: InstrumentSubscriptionMap,
  subscriptionSrc: string,
  changes: (existingDataScope: Array<InstrumentSubscriptionDataScope>) => Array<InstrumentSubscriptionDataScope>,
) => {
  return {
    ...item,
    source: { ...item.source, [subscriptionSrc]: changes(item.source[subscriptionSrc]) },
  };
};

type EStreamClientEvents = {
  onMessageCallback: (data: Array<EStreamTradeDTO>) => void;
};

export class EStreamClient extends SSEClient {
  private instrumentSubscription: ObjectSet<InstrumentSubscriptionMap> = new ObjectSet<
    InstrumentSubscriptionMap,
    string
  >(getInstrumentSubscriptionId);
  private _subscriptions?: Array<InstrumentSubscriptionRequestItem>;
  private eStreamClientsEvents?: EStreamClientEvents;

  static create(url: string) {
    return new EStreamClient(url, () => new EventSource(url));
  }

  static createForTest(url: string, clientConstructor: () => EventSource) {
    return new EStreamClient(url, clientConstructor);
  }

  constructor(readonly url: string, readonly clientConstructor: () => EventSource) {
    super(url, clientConstructor);
  }

  get subscriptions(): Array<InstrumentSubscriptionRequestItem> {
    return this._subscriptions || [];
  }

  addEventHandlers(eventHandlers: EStreamClientEvents) {
    this.eStreamClientsEvents = eventHandlers;
  }

  /**
   * Sends a subscribe request to eStream
   * @param subscriptionChanges if not defined, the previous subscription list will be used. Useful for reconnect purposes
   */
  async handleSubscriptions(subscriptionChanges?: ObjectSet<SubscribeOptions>): Promise<void> {
    if (subscriptionChanges) {
      this.updateSubscriptionMap(subscriptionChanges);
    }

    const newSubscriptions: Array<InstrumentSubscriptionRequestItem> = this.instrumentSubscription
      .values()
      .map(subscription => ({
        mic: subscription.mic,
        ric: subscription.ric,
        datascopes: uniqueArray(Object.values(subscription.source).flat()),
      }))
      .filter(subscription => subscription.datascopes.length > 0);

    // if subscriptions has not changed, no need to send another request
    if (!!subscriptionChanges && this._subscriptions && isArrayEqual(newSubscriptions, this._subscriptions)) {
      return;
    }
    this._subscriptions = newSubscriptions;

    return this.sendSubscriptionRequest();
  }

  private async sendSubscriptionRequest() {
    if (!this._subscriptions) {
      this.logger.error(`Subscription list is not defined`);
      return;
    }
    this.logger.debug(
      `Updating subscriptions to eStream: ${this._subscriptions.length} instrument (${this._subscriptions
        .map(_ => _.ric)
        .join(',')})`,
    );

    const url = localStorage.getItem(Keys.FocusTest.estream.subscribeUrl) || Config.Estream.subscriptionsUrl;
    const response = await fetch(url, {
      method: 'POST',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `token ${this.token}`,
        userid: this.userId,
        id: '',
        correlid: '',
        source: Config.App.API.source,
        'src-module': Config.App.API.module,
      },
      body: JSON.stringify(this._subscriptions),
    });

    return response.json();
  }

  protected override handleMessage(message: unknown) {
    if (this.eStreamClientsEvents) {
      this.eStreamClientsEvents.onMessageCallback(message as Array<EStreamTradeDTO>);
    }
  }

  private updateSubscriptionMap(subscriptionUpdates: ObjectSet<SubscribeOptions>) {
    this.instrumentSubscription.clear();

    // add subscriptions
    subscriptionUpdates.forEach(subscription => {
      const subscriptionId = getInstrumentSubscriptionId(subscription.instrument);

      const newSubscription: InstrumentSubscriptionMap = {
        ric: subscription.instrument.ric,
        mic: subscription.instrument.mic,
        source: {
          [subscription.source]: [subscription.dataScope],
        },
      };
      const subscriptionSrc = subscription.source;

      // when there is a subscription for the instrument we try to create/update the old subscriptions
      // by modifying data scopes or setting up data scope
      // if (subscription.type === 'subscribe') {
      this.instrumentSubscription.set(
        subscriptionId,
        updatedItem => {
          // if the source is already added we need to extend data scope
          if (updatedItem.source[subscriptionSrc]) {
            return replaceSourceDataScope(updatedItem, subscriptionSrc, existingDataScope =>
              uniqueArray([...existingDataScope, subscription.dataScope]),
            );
          } else {
            // if there is no source yet, create a datascope for that source
            return replaceSourceDataScope(updatedItem, subscriptionSrc, () => [subscription.dataScope]);
          }
        },
        newSubscription,
      );
    });
  }
}

export const eStreamClient = EStreamClient.create(
  localStorage.getItem(Keys.FocusTest.estream.streamUrl) || Config.Estream.streamUrl,
);
