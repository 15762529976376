import CloseIcon from '@mui/icons-material/Close';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import { useLocale } from '../../../context/NbLocalizationContext';
import { NbIconButton } from '../../../shared/components/icon-button/IconButton';
import { useFormatter } from '../../../shared/services/useFormatter';
import { Fiat } from '../../../shared/state/balanceAtom';
import classes from './CashPopoverContent.module.scss';

export interface CashPopoverContentProps {
  totalCash: string;
  fiats: Array<Fiat>;
  handleCashClose: () => void;
}

export const CashPopoverContent = ({ totalCash, fiats, handleCashClose }: CashPopoverContentProps) => {
  const { translation } = useLocale();
  const { formatInteger } = useFormatter();

  return (
    <div className={classes.cash}>
      <div className={classes.cashHeader}>
        <h4>{translation.header.allAccounts.toUpperCase()}</h4>
        <NbIconButton
          icon={<CloseIcon />}
          onClick={handleCashClose}
        />
      </div>
      <div className={classes.cashContent}>
        <div className={classes.cashRow}>
          <p>{translation.header.cash.title}</p>
          <p className={classes.cashButtonish}>
            <span>
              <CurrencyExchangeIcon />
            </span>
            {translation.header.cash.exchange}
          </p>
        </div>
        <div className={`${classes.cashRow} ${classes.cashRowIsBordered}`}>
          <p>{translation.header.cash.total}</p>
          <p>{totalCash}</p>
        </div>

        {fiats.map(fiat => (
          <div
            key={fiat.name}
            className={classes.cashFiat}
          >
            <div className={classes.cashRow}>
              <div className={classes.cashRowLeft}>
                <div className={classes.cashRowLeftFlag}>
                  <img
                    src={fiat.picture}
                    alt={fiat.name}
                  />
                </div>
                <div className={classes.cashRowLeftFiat}>
                  <p>{fiat.name}</p>
                  <p className={classes.cashRowLight}>{fiat.currency}</p>
                </div>
              </div>

              <div className={classes.cashRowRight}>
                <p>
                  {formatInteger(fiat.amount)} {fiat.currency}
                  <span className={classes.cashRowRightIcon}>
                    <CurrencyExchangeIcon />
                  </span>
                </p>
                {/*// TODO: This is a placeholder for now*/}
                <p className={classes.cashRowLight}>{formatInteger(fiat.amount * 6)} HUF</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
