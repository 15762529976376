/* tslint:disable */
/* eslint-disable */
/**
 * NetBroker partner adatok lekérdező API
 * NetBroker partner adatokat szolgáltató lekérdező szolgáltatások.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: zsolt.szabo@ersteinvestment.hu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { AxiosInstance, AxiosPromise, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
import type { Configuration } from './configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import type { RequestArgs } from './base';
import {
  assertParamExists,
  createRequestFunction,
  DUMMY_BASE_URL,
  serializeDataIfNeeded,
  setSearchParams,
  toPathString,
} from './common';
// @ts-ignore
import { BaseAPI, BASE_PATH } from './base';

/**
 * Consent típus
 * @export
 * @interface ClientSubscription
 */
export interface ClientSubscription {
  /**
   * Piaci adatszolgáltató azonosítója.
   * @type {string}
   * @memberof ClientSubscription
   */
  marketDataProvider: string;
  /**
   * Prioritás
   * @type {number}
   * @memberof ClientSubscription
   */
  priority: number;
  /**
   * Árszint
   * @type {number}
   * @memberof ClientSubscription
   */
  marketDepth: number;
  /**
   * Realtime-e?
   * @type {boolean}
   * @memberof ClientSubscription
   */
  realtime: boolean;
  /**
   * Előfizetesi csomag Típus.
   * @type {string}
   * @memberof ClientSubscription
   */
  subscriptionPackageType: string;
  /**
   * Dátumtól
   * @type {string}
   * @memberof ClientSubscription
   */
  fromDate: string;
  /**
   * Dátumig
   * @type {string}
   * @memberof ClientSubscription
   */
  toDate?: string;
  /**
   * Előfizetési csomagDíj azonosító.
   * @type {number}
   * @memberof ClientSubscription
   */
  subscriptionPackagePriceId: number;
  /**
   * Választható ügyfel által?
   * @type {boolean}
   * @memberof ClientSubscription
   */
  allowToEditByCustomer: boolean;
  /**
   * Előfizetesi csomagDíj.
   * @type {number}
   * @memberof ClientSubscription
   */
  subscriptionPackagePrice: number;
  /**
   * Előfizetesi csomagDíj devizája.
   * @type {string}
   * @memberof ClientSubscription
   */
  subscriptionPackagePriceCcy: string;
}
/**
 *
 * @export
 * @interface CreatePartnerSubscriptionRequestInner
 */
export interface CreatePartnerSubscriptionRequestInner {
  /**
   * Előfizetési csomagDíj azonosító.
   * @type {number}
   * @memberof CreatePartnerSubscriptionRequestInner
   */
  subscriptionPackagePriceId?: number;
  /**
   * Piaci adatszolgáltató azonosítója.
   * @type {string}
   * @memberof CreatePartnerSubscriptionRequestInner
   */
  marketDataProvider?: string;
}
/**
 *
 * @export
 * @interface GetPartnerSubscriptions400Response
 */
export interface GetPartnerSubscriptions400Response {
  /**
   * The error, or validation messages
   * @type {Array<object>}
   * @memberof GetPartnerSubscriptions400Response
   */
  errors?: Array<object>;
}
/**
 *
 * @export
 * @interface GetPartnerSubscriptionsForeStream200ResponseInner
 */
export interface GetPartnerSubscriptionsForeStream200ResponseInner {
  /**
   * Piaci instrumentum kód.
   * @type {string}
   * @memberof GetPartnerSubscriptionsForeStream200ResponseInner
   */
  micCode?: string;
  /**
   * Árszint
   * @type {number}
   * @memberof GetPartnerSubscriptionsForeStream200ResponseInner
   */
  marketDepth?: number;
}
/**
 * Consent típus
 * @export
 * @interface Subscription
 */
export interface Subscription {
  /**
   * Piaci adatszolgáltató azonosítója.
   * @type {string}
   * @memberof Subscription
   */
  marketDataProvider: string;
  /**
   * Soorend
   * @type {number}
   * @memberof Subscription
   */
  order: number;
  /**
   * Árszint
   * @type {number}
   * @memberof Subscription
   */
  marketDepth: number;
  /**
   * Realtime-e?
   * @type {boolean}
   * @memberof Subscription
   */
  realtime: boolean;
  /**
   * Előfizetesi csomag Típus.
   * @type {string}
   * @memberof Subscription
   */
  subscriptionPackageType: string;
  /**
   * Előfizetési csomagDíj azonosító.
   * @type {number}
   * @memberof Subscription
   */
  subscriptionPackagePriceId: number;
  /**
   * Előfizetesi csomagDíj.
   * @type {number}
   * @memberof Subscription
   */
  subscriptionPackagePrice: number;
  /**
   * Előfizetesi csomagDíj devizája.
   * @type {string}
   * @memberof Subscription
   */
  subscriptionPackagePriceCcy: string;
}

/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Ügyfél előfizetés létrehozása
     * @param {string} clientId Az ügyfének azonosítója.
     * @param {string} id All requests should include a unique identifier element. The identifier is used for auditing and control of double-submission for update operations. The Id must be prefixed by the application name (or module, if necessary) to be unique in the whole environment. The application name and the Id must be separated with colon (:). Synchronous response to a request must use the same id in the HTTP Header.
     * @param {string} correlid Flow (or session) identifier. The flow initiator generates the ID; any other party should copy the received ID into all subsequent messages/calls inside the flow. IDs should be unique for each flow.
     * @param {string} source This identifier is unique for the different modules of the source systemapplications. The name must be defined as an application module name in VEKER naming style. This field is used for logging and in the gatekeeper configuration too.
     * @param {string} srcModule This identifier is unique for the different consumer systemapplications. The name has to be defined in VEKER naming style as an application name. This field is used for logging, gathering information on service usage and in the gatekeeper configuration too.
     * @param {string} userid The identity ID describes the identity of the user that has initiated the service request. This may be, for example, the customer, or an internal user, or may identify a special systemapplication user for calls made by automated systemapplications. If the identification of the original user is not possible (e.g. batch jobs) or the original user is not part of the global user directory (e.g. a customer, who is using a frontend systemapplication), then a technical user name should be used. This is different from the application ID. The Identity ID is of the end user of the systemapplication while the application ID is the calling systemapplication.
     * @param {string} acceptLanguage A kliens által küldött, és elvárt nyelvi beállításokat tartalmazza. A Accept-language egy szabványos http header elem, melynek formátumát az IETF RFC5646 ajánlása definiálja.
     * @param {Array<CreatePartnerSubscriptionRequestInner>} [createPartnerSubscriptionRequestInner] Módosítja a felhasználó csoportok sorrendjét.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createPartnerSubscription: async (
      clientId: string,
      id: string,
      correlid: string,
      source: string,
      srcModule: string,
      userid: string,
      acceptLanguage: string,
      createPartnerSubscriptionRequestInner?: Array<CreatePartnerSubscriptionRequestInner>,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'clientId' is not null or undefined
      assertParamExists('createPartnerSubscription', 'clientId', clientId);
      // verify required parameter 'id' is not null or undefined
      assertParamExists('createPartnerSubscription', 'id', id);
      // verify required parameter 'correlid' is not null or undefined
      assertParamExists('createPartnerSubscription', 'correlid', correlid);
      // verify required parameter 'source' is not null or undefined
      assertParamExists('createPartnerSubscription', 'source', source);
      // verify required parameter 'srcModule' is not null or undefined
      assertParamExists('createPartnerSubscription', 'srcModule', srcModule);
      // verify required parameter 'userid' is not null or undefined
      assertParamExists('createPartnerSubscription', 'userid', userid);
      // verify required parameter 'acceptLanguage' is not null or undefined
      assertParamExists('createPartnerSubscription', 'acceptLanguage', acceptLanguage);
      const localVarPath = `/{clientId}/subscriptions`.replace(`{${'clientId'}}`, encodeURIComponent(String(clientId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (id != null) {
        localVarHeaderParameter['id'] = String(id);
      }

      if (correlid != null) {
        localVarHeaderParameter['correlid'] = String(correlid);
      }

      if (source != null) {
        localVarHeaderParameter['source'] = String(source);
      }

      if (srcModule != null) {
        localVarHeaderParameter['src-module'] = String(srcModule);
      }

      if (userid != null) {
        localVarHeaderParameter['userid'] = String(userid);
      }

      if (acceptLanguage != null) {
        localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        createPartnerSubscriptionRequestInner,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Ügyfélhez tartotó előfizetés lemondása
     * @param {string} clientId Az ügyfének azonosítója.
     * @param {number} subscriptionPackagePriceId Előfizetési csomagDíj azonosító.
     * @param {string} id All requests should include a unique identifier element. The identifier is used for auditing and control of double-submission for update operations. The Id must be prefixed by the application name (or module, if necessary) to be unique in the whole environment. The application name and the Id must be separated with colon (:). Synchronous response to a request must use the same id in the HTTP Header.
     * @param {string} correlid Flow (or session) identifier. The flow initiator generates the ID; any other party should copy the received ID into all subsequent messages/calls inside the flow. IDs should be unique for each flow.
     * @param {string} source This identifier is unique for the different modules of the source systemapplications. The name must be defined as an application module name in VEKER naming style. This field is used for logging and in the gatekeeper configuration too.
     * @param {string} srcModule This identifier is unique for the different consumer systemapplications. The name has to be defined in VEKER naming style as an application name. This field is used for logging, gathering information on service usage and in the gatekeeper configuration too.
     * @param {string} userid The identity ID describes the identity of the user that has initiated the service request. This may be, for example, the customer, or an internal user, or may identify a special systemapplication user for calls made by automated systemapplications. If the identification of the original user is not possible (e.g. batch jobs) or the original user is not part of the global user directory (e.g. a customer, who is using a frontend systemapplication), then a technical user name should be used. This is different from the application ID. The Identity ID is of the end user of the systemapplication while the application ID is the calling systemapplication.
     * @param {string} acceptLanguage A kliens által küldött, és elvárt nyelvi beállításokat tartalmazza. A Accept-language egy szabványos http header elem, melynek formátumát az IETF RFC5646 ajánlása definiálja.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deletePartnerSubscription: async (
      clientId: string,
      subscriptionPackagePriceId: number,
      id: string,
      correlid: string,
      source: string,
      srcModule: string,
      userid: string,
      acceptLanguage: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'clientId' is not null or undefined
      assertParamExists('deletePartnerSubscription', 'clientId', clientId);
      // verify required parameter 'subscriptionPackagePriceId' is not null or undefined
      assertParamExists('deletePartnerSubscription', 'subscriptionPackagePriceId', subscriptionPackagePriceId);
      // verify required parameter 'id' is not null or undefined
      assertParamExists('deletePartnerSubscription', 'id', id);
      // verify required parameter 'correlid' is not null or undefined
      assertParamExists('deletePartnerSubscription', 'correlid', correlid);
      // verify required parameter 'source' is not null or undefined
      assertParamExists('deletePartnerSubscription', 'source', source);
      // verify required parameter 'srcModule' is not null or undefined
      assertParamExists('deletePartnerSubscription', 'srcModule', srcModule);
      // verify required parameter 'userid' is not null or undefined
      assertParamExists('deletePartnerSubscription', 'userid', userid);
      // verify required parameter 'acceptLanguage' is not null or undefined
      assertParamExists('deletePartnerSubscription', 'acceptLanguage', acceptLanguage);
      const localVarPath = `/{clientId}/subscriptions/{subscriptionPackagePriceId}`
        .replace(`{${'clientId'}}`, encodeURIComponent(String(clientId)))
        .replace(`{${'subscriptionPackagePriceId'}}`, encodeURIComponent(String(subscriptionPackagePriceId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (id != null) {
        localVarHeaderParameter['id'] = String(id);
      }

      if (correlid != null) {
        localVarHeaderParameter['correlid'] = String(correlid);
      }

      if (source != null) {
        localVarHeaderParameter['source'] = String(source);
      }

      if (srcModule != null) {
        localVarHeaderParameter['src-module'] = String(srcModule);
      }

      if (userid != null) {
        localVarHeaderParameter['userid'] = String(userid);
      }

      if (acceptLanguage != null) {
        localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Visszadja az ügyfélhez számára elérhető előfizetések listáját
     * @param {string} clientId Az ügyfének azonosítója.
     * @param {string} id All requests should include a unique identifier element. The identifier is used for auditing and control of double-submission for update operations. The Id must be prefixed by the application name (or module, if necessary) to be unique in the whole environment. The application name and the Id must be separated with colon (:). Synchronous response to a request must use the same id in the HTTP Header.
     * @param {string} correlid Flow (or session) identifier. The flow initiator generates the ID; any other party should copy the received ID into all subsequent messages/calls inside the flow. IDs should be unique for each flow.
     * @param {string} source This identifier is unique for the different modules of the source systemapplications. The name must be defined as an application module name in VEKER naming style. This field is used for logging and in the gatekeeper configuration too.
     * @param {string} srcModule This identifier is unique for the different consumer systemapplications. The name has to be defined in VEKER naming style as an application name. This field is used for logging, gathering information on service usage and in the gatekeeper configuration too.
     * @param {string} userid The identity ID describes the identity of the user that has initiated the service request. This may be, for example, the customer, or an internal user, or may identify a special systemapplication user for calls made by automated systemapplications. If the identification of the original user is not possible (e.g. batch jobs) or the original user is not part of the global user directory (e.g. a customer, who is using a frontend systemapplication), then a technical user name should be used. This is different from the application ID. The Identity ID is of the end user of the systemapplication while the application ID is the calling systemapplication.
     * @param {string} acceptLanguage A kliens által küldött, és elvárt nyelvi beállításokat tartalmazza. A Accept-language egy szabványos http header elem, melynek formátumát az IETF RFC5646 ajánlása definiálja.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPartnerAvaiableSubscriptions: async (
      clientId: string,
      id: string,
      correlid: string,
      source: string,
      srcModule: string,
      userid: string,
      acceptLanguage: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'clientId' is not null or undefined
      assertParamExists('getPartnerAvaiableSubscriptions', 'clientId', clientId);
      // verify required parameter 'id' is not null or undefined
      assertParamExists('getPartnerAvaiableSubscriptions', 'id', id);
      // verify required parameter 'correlid' is not null or undefined
      assertParamExists('getPartnerAvaiableSubscriptions', 'correlid', correlid);
      // verify required parameter 'source' is not null or undefined
      assertParamExists('getPartnerAvaiableSubscriptions', 'source', source);
      // verify required parameter 'srcModule' is not null or undefined
      assertParamExists('getPartnerAvaiableSubscriptions', 'srcModule', srcModule);
      // verify required parameter 'userid' is not null or undefined
      assertParamExists('getPartnerAvaiableSubscriptions', 'userid', userid);
      // verify required parameter 'acceptLanguage' is not null or undefined
      assertParamExists('getPartnerAvaiableSubscriptions', 'acceptLanguage', acceptLanguage);
      const localVarPath = `/{clientId}/available-subscriptions`.replace(
        `{${'clientId'}}`,
        encodeURIComponent(String(clientId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (id != null) {
        localVarHeaderParameter['id'] = String(id);
      }

      if (correlid != null) {
        localVarHeaderParameter['correlid'] = String(correlid);
      }

      if (source != null) {
        localVarHeaderParameter['source'] = String(source);
      }

      if (srcModule != null) {
        localVarHeaderParameter['src-module'] = String(srcModule);
      }

      if (userid != null) {
        localVarHeaderParameter['userid'] = String(userid);
      }

      if (acceptLanguage != null) {
        localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Visszadja az ügyfélhez tartotó előfizetéseket
     * @param {string} clientId Az ügyfének azonosítója.
     * @param {string} id All requests should include a unique identifier element. The identifier is used for auditing and control of double-submission for update operations. The Id must be prefixed by the application name (or module, if necessary) to be unique in the whole environment. The application name and the Id must be separated with colon (:). Synchronous response to a request must use the same id in the HTTP Header.
     * @param {string} correlid Flow (or session) identifier. The flow initiator generates the ID; any other party should copy the received ID into all subsequent messages/calls inside the flow. IDs should be unique for each flow.
     * @param {string} source This identifier is unique for the different modules of the source systemapplications. The name must be defined as an application module name in VEKER naming style. This field is used for logging and in the gatekeeper configuration too.
     * @param {string} srcModule This identifier is unique for the different consumer systemapplications. The name has to be defined in VEKER naming style as an application name. This field is used for logging, gathering information on service usage and in the gatekeeper configuration too.
     * @param {string} userid The identity ID describes the identity of the user that has initiated the service request. This may be, for example, the customer, or an internal user, or may identify a special systemapplication user for calls made by automated systemapplications. If the identification of the original user is not possible (e.g. batch jobs) or the original user is not part of the global user directory (e.g. a customer, who is using a frontend systemapplication), then a technical user name should be used. This is different from the application ID. The Identity ID is of the end user of the systemapplication while the application ID is the calling systemapplication.
     * @param {string} acceptLanguage A kliens által küldött, és elvárt nyelvi beállításokat tartalmazza. A Accept-language egy szabványos http header elem, melynek formátumát az IETF RFC5646 ajánlása definiálja.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPartnerSubscriptions: async (
      clientId: string,
      id: string,
      correlid: string,
      source: string,
      srcModule: string,
      userid: string,
      acceptLanguage: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'clientId' is not null or undefined
      assertParamExists('getPartnerSubscriptions', 'clientId', clientId);
      // verify required parameter 'id' is not null or undefined
      assertParamExists('getPartnerSubscriptions', 'id', id);
      // verify required parameter 'correlid' is not null or undefined
      assertParamExists('getPartnerSubscriptions', 'correlid', correlid);
      // verify required parameter 'source' is not null or undefined
      assertParamExists('getPartnerSubscriptions', 'source', source);
      // verify required parameter 'srcModule' is not null or undefined
      assertParamExists('getPartnerSubscriptions', 'srcModule', srcModule);
      // verify required parameter 'userid' is not null or undefined
      assertParamExists('getPartnerSubscriptions', 'userid', userid);
      // verify required parameter 'acceptLanguage' is not null or undefined
      assertParamExists('getPartnerSubscriptions', 'acceptLanguage', acceptLanguage);
      const localVarPath = `/{clientId}/subscriptions`.replace(`{${'clientId'}}`, encodeURIComponent(String(clientId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (id != null) {
        localVarHeaderParameter['id'] = String(id);
      }

      if (correlid != null) {
        localVarHeaderParameter['correlid'] = String(correlid);
      }

      if (source != null) {
        localVarHeaderParameter['source'] = String(source);
      }

      if (srcModule != null) {
        localVarHeaderParameter['src-module'] = String(srcModule);
      }

      if (userid != null) {
        localVarHeaderParameter['userid'] = String(userid);
      }

      if (acceptLanguage != null) {
        localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Visszadja az ügyfélhez tartozó előfizetések eStream számára
     * @param {string} clientId Az ügyfének azonosítója.
     * @param {string} id All requests should include a unique identifier element. The identifier is used for auditing and control of double-submission for update operations. The Id must be prefixed by the application name (or module, if necessary) to be unique in the whole environment. The application name and the Id must be separated with colon (:). Synchronous response to a request must use the same id in the HTTP Header.
     * @param {string} correlid Flow (or session) identifier. The flow initiator generates the ID; any other party should copy the received ID into all subsequent messages/calls inside the flow. IDs should be unique for each flow.
     * @param {string} source This identifier is unique for the different modules of the source systemapplications. The name must be defined as an application module name in VEKER naming style. This field is used for logging and in the gatekeeper configuration too.
     * @param {string} srcModule This identifier is unique for the different consumer systemapplications. The name has to be defined in VEKER naming style as an application name. This field is used for logging, gathering information on service usage and in the gatekeeper configuration too.
     * @param {string} userid The identity ID describes the identity of the user that has initiated the service request. This may be, for example, the customer, or an internal user, or may identify a special systemapplication user for calls made by automated systemapplications. If the identification of the original user is not possible (e.g. batch jobs) or the original user is not part of the global user directory (e.g. a customer, who is using a frontend systemapplication), then a technical user name should be used. This is different from the application ID. The Identity ID is of the end user of the systemapplication while the application ID is the calling systemapplication.
     * @param {string} acceptLanguage A kliens által küldött, és elvárt nyelvi beállításokat tartalmazza. A Accept-language egy szabványos http header elem, melynek formátumát az IETF RFC5646 ajánlása definiálja.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPartnerSubscriptionsForeStream: async (
      clientId: string,
      id: string,
      correlid: string,
      source: string,
      srcModule: string,
      userid: string,
      acceptLanguage: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'clientId' is not null or undefined
      assertParamExists('getPartnerSubscriptionsForeStream', 'clientId', clientId);
      // verify required parameter 'id' is not null or undefined
      assertParamExists('getPartnerSubscriptionsForeStream', 'id', id);
      // verify required parameter 'correlid' is not null or undefined
      assertParamExists('getPartnerSubscriptionsForeStream', 'correlid', correlid);
      // verify required parameter 'source' is not null or undefined
      assertParamExists('getPartnerSubscriptionsForeStream', 'source', source);
      // verify required parameter 'srcModule' is not null or undefined
      assertParamExists('getPartnerSubscriptionsForeStream', 'srcModule', srcModule);
      // verify required parameter 'userid' is not null or undefined
      assertParamExists('getPartnerSubscriptionsForeStream', 'userid', userid);
      // verify required parameter 'acceptLanguage' is not null or undefined
      assertParamExists('getPartnerSubscriptionsForeStream', 'acceptLanguage', acceptLanguage);
      const localVarPath = `/{clientId}/estream-subscriptions`.replace(
        `{${'clientId'}}`,
        encodeURIComponent(String(clientId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (id != null) {
        localVarHeaderParameter['id'] = String(id);
      }

      if (correlid != null) {
        localVarHeaderParameter['correlid'] = String(correlid);
      }

      if (source != null) {
        localVarHeaderParameter['source'] = String(source);
      }

      if (srcModule != null) {
        localVarHeaderParameter['src-module'] = String(srcModule);
      }

      if (userid != null) {
        localVarHeaderParameter['userid'] = String(userid);
      }

      if (acceptLanguage != null) {
        localVarHeaderParameter['Accept-Language'] = String(acceptLanguage);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Ügyfél előfizetés létrehozása
     * @param {string} clientId Az ügyfének azonosítója.
     * @param {string} id All requests should include a unique identifier element. The identifier is used for auditing and control of double-submission for update operations. The Id must be prefixed by the application name (or module, if necessary) to be unique in the whole environment. The application name and the Id must be separated with colon (:). Synchronous response to a request must use the same id in the HTTP Header.
     * @param {string} correlid Flow (or session) identifier. The flow initiator generates the ID; any other party should copy the received ID into all subsequent messages/calls inside the flow. IDs should be unique for each flow.
     * @param {string} source This identifier is unique for the different modules of the source systemapplications. The name must be defined as an application module name in VEKER naming style. This field is used for logging and in the gatekeeper configuration too.
     * @param {string} srcModule This identifier is unique for the different consumer systemapplications. The name has to be defined in VEKER naming style as an application name. This field is used for logging, gathering information on service usage and in the gatekeeper configuration too.
     * @param {string} userid The identity ID describes the identity of the user that has initiated the service request. This may be, for example, the customer, or an internal user, or may identify a special systemapplication user for calls made by automated systemapplications. If the identification of the original user is not possible (e.g. batch jobs) or the original user is not part of the global user directory (e.g. a customer, who is using a frontend systemapplication), then a technical user name should be used. This is different from the application ID. The Identity ID is of the end user of the systemapplication while the application ID is the calling systemapplication.
     * @param {string} acceptLanguage A kliens által küldött, és elvárt nyelvi beállításokat tartalmazza. A Accept-language egy szabványos http header elem, melynek formátumát az IETF RFC5646 ajánlása definiálja.
     * @param {Array<CreatePartnerSubscriptionRequestInner>} [createPartnerSubscriptionRequestInner] Módosítja a felhasználó csoportok sorrendjét.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createPartnerSubscription(
      clientId: string,
      id: string,
      correlid: string,
      source: string,
      srcModule: string,
      userid: string,
      acceptLanguage: string,
      createPartnerSubscriptionRequestInner?: Array<CreatePartnerSubscriptionRequestInner>,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createPartnerSubscription(
        clientId,
        id,
        correlid,
        source,
        srcModule,
        userid,
        acceptLanguage,
        createPartnerSubscriptionRequestInner,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Ügyfélhez tartotó előfizetés lemondása
     * @param {string} clientId Az ügyfének azonosítója.
     * @param {number} subscriptionPackagePriceId Előfizetési csomagDíj azonosító.
     * @param {string} id All requests should include a unique identifier element. The identifier is used for auditing and control of double-submission for update operations. The Id must be prefixed by the application name (or module, if necessary) to be unique in the whole environment. The application name and the Id must be separated with colon (:). Synchronous response to a request must use the same id in the HTTP Header.
     * @param {string} correlid Flow (or session) identifier. The flow initiator generates the ID; any other party should copy the received ID into all subsequent messages/calls inside the flow. IDs should be unique for each flow.
     * @param {string} source This identifier is unique for the different modules of the source systemapplications. The name must be defined as an application module name in VEKER naming style. This field is used for logging and in the gatekeeper configuration too.
     * @param {string} srcModule This identifier is unique for the different consumer systemapplications. The name has to be defined in VEKER naming style as an application name. This field is used for logging, gathering information on service usage and in the gatekeeper configuration too.
     * @param {string} userid The identity ID describes the identity of the user that has initiated the service request. This may be, for example, the customer, or an internal user, or may identify a special systemapplication user for calls made by automated systemapplications. If the identification of the original user is not possible (e.g. batch jobs) or the original user is not part of the global user directory (e.g. a customer, who is using a frontend systemapplication), then a technical user name should be used. This is different from the application ID. The Identity ID is of the end user of the systemapplication while the application ID is the calling systemapplication.
     * @param {string} acceptLanguage A kliens által küldött, és elvárt nyelvi beállításokat tartalmazza. A Accept-language egy szabványos http header elem, melynek formátumát az IETF RFC5646 ajánlása definiálja.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deletePartnerSubscription(
      clientId: string,
      subscriptionPackagePriceId: number,
      id: string,
      correlid: string,
      source: string,
      srcModule: string,
      userid: string,
      acceptLanguage: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deletePartnerSubscription(
        clientId,
        subscriptionPackagePriceId,
        id,
        correlid,
        source,
        srcModule,
        userid,
        acceptLanguage,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Visszadja az ügyfélhez számára elérhető előfizetések listáját
     * @param {string} clientId Az ügyfének azonosítója.
     * @param {string} id All requests should include a unique identifier element. The identifier is used for auditing and control of double-submission for update operations. The Id must be prefixed by the application name (or module, if necessary) to be unique in the whole environment. The application name and the Id must be separated with colon (:). Synchronous response to a request must use the same id in the HTTP Header.
     * @param {string} correlid Flow (or session) identifier. The flow initiator generates the ID; any other party should copy the received ID into all subsequent messages/calls inside the flow. IDs should be unique for each flow.
     * @param {string} source This identifier is unique for the different modules of the source systemapplications. The name must be defined as an application module name in VEKER naming style. This field is used for logging and in the gatekeeper configuration too.
     * @param {string} srcModule This identifier is unique for the different consumer systemapplications. The name has to be defined in VEKER naming style as an application name. This field is used for logging, gathering information on service usage and in the gatekeeper configuration too.
     * @param {string} userid The identity ID describes the identity of the user that has initiated the service request. This may be, for example, the customer, or an internal user, or may identify a special systemapplication user for calls made by automated systemapplications. If the identification of the original user is not possible (e.g. batch jobs) or the original user is not part of the global user directory (e.g. a customer, who is using a frontend systemapplication), then a technical user name should be used. This is different from the application ID. The Identity ID is of the end user of the systemapplication while the application ID is the calling systemapplication.
     * @param {string} acceptLanguage A kliens által küldött, és elvárt nyelvi beállításokat tartalmazza. A Accept-language egy szabványos http header elem, melynek formátumát az IETF RFC5646 ajánlása definiálja.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getPartnerAvaiableSubscriptions(
      clientId: string,
      id: string,
      correlid: string,
      source: string,
      srcModule: string,
      userid: string,
      acceptLanguage: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Subscription>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getPartnerAvaiableSubscriptions(
        clientId,
        id,
        correlid,
        source,
        srcModule,
        userid,
        acceptLanguage,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Visszadja az ügyfélhez tartotó előfizetéseket
     * @param {string} clientId Az ügyfének azonosítója.
     * @param {string} id All requests should include a unique identifier element. The identifier is used for auditing and control of double-submission for update operations. The Id must be prefixed by the application name (or module, if necessary) to be unique in the whole environment. The application name and the Id must be separated with colon (:). Synchronous response to a request must use the same id in the HTTP Header.
     * @param {string} correlid Flow (or session) identifier. The flow initiator generates the ID; any other party should copy the received ID into all subsequent messages/calls inside the flow. IDs should be unique for each flow.
     * @param {string} source This identifier is unique for the different modules of the source systemapplications. The name must be defined as an application module name in VEKER naming style. This field is used for logging and in the gatekeeper configuration too.
     * @param {string} srcModule This identifier is unique for the different consumer systemapplications. The name has to be defined in VEKER naming style as an application name. This field is used for logging, gathering information on service usage and in the gatekeeper configuration too.
     * @param {string} userid The identity ID describes the identity of the user that has initiated the service request. This may be, for example, the customer, or an internal user, or may identify a special systemapplication user for calls made by automated systemapplications. If the identification of the original user is not possible (e.g. batch jobs) or the original user is not part of the global user directory (e.g. a customer, who is using a frontend systemapplication), then a technical user name should be used. This is different from the application ID. The Identity ID is of the end user of the systemapplication while the application ID is the calling systemapplication.
     * @param {string} acceptLanguage A kliens által küldött, és elvárt nyelvi beállításokat tartalmazza. A Accept-language egy szabványos http header elem, melynek formátumát az IETF RFC5646 ajánlása definiálja.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getPartnerSubscriptions(
      clientId: string,
      id: string,
      correlid: string,
      source: string,
      srcModule: string,
      userid: string,
      acceptLanguage: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ClientSubscription>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getPartnerSubscriptions(
        clientId,
        id,
        correlid,
        source,
        srcModule,
        userid,
        acceptLanguage,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Visszadja az ügyfélhez tartozó előfizetések eStream számára
     * @param {string} clientId Az ügyfének azonosítója.
     * @param {string} id All requests should include a unique identifier element. The identifier is used for auditing and control of double-submission for update operations. The Id must be prefixed by the application name (or module, if necessary) to be unique in the whole environment. The application name and the Id must be separated with colon (:). Synchronous response to a request must use the same id in the HTTP Header.
     * @param {string} correlid Flow (or session) identifier. The flow initiator generates the ID; any other party should copy the received ID into all subsequent messages/calls inside the flow. IDs should be unique for each flow.
     * @param {string} source This identifier is unique for the different modules of the source systemapplications. The name must be defined as an application module name in VEKER naming style. This field is used for logging and in the gatekeeper configuration too.
     * @param {string} srcModule This identifier is unique for the different consumer systemapplications. The name has to be defined in VEKER naming style as an application name. This field is used for logging, gathering information on service usage and in the gatekeeper configuration too.
     * @param {string} userid The identity ID describes the identity of the user that has initiated the service request. This may be, for example, the customer, or an internal user, or may identify a special systemapplication user for calls made by automated systemapplications. If the identification of the original user is not possible (e.g. batch jobs) or the original user is not part of the global user directory (e.g. a customer, who is using a frontend systemapplication), then a technical user name should be used. This is different from the application ID. The Identity ID is of the end user of the systemapplication while the application ID is the calling systemapplication.
     * @param {string} acceptLanguage A kliens által küldött, és elvárt nyelvi beállításokat tartalmazza. A Accept-language egy szabványos http header elem, melynek formátumát az IETF RFC5646 ajánlása definiálja.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getPartnerSubscriptionsForeStream(
      clientId: string,
      id: string,
      correlid: string,
      source: string,
      srcModule: string,
      userid: string,
      acceptLanguage: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (
        axios?: AxiosInstance,
        basePath?: string,
      ) => AxiosPromise<Array<GetPartnerSubscriptionsForeStream200ResponseInner>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getPartnerSubscriptionsForeStream(
        clientId,
        id,
        correlid,
        source,
        srcModule,
        userid,
        acceptLanguage,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = DefaultApiFp(configuration);
  return {
    /**
     *
     * @summary Ügyfél előfizetés létrehozása
     * @param {DefaultApiCreatePartnerSubscriptionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createPartnerSubscription(
      requestParameters: DefaultApiCreatePartnerSubscriptionRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<void> {
      return localVarFp
        .createPartnerSubscription(
          requestParameters.clientId,
          requestParameters.id,
          requestParameters.correlid,
          requestParameters.source,
          requestParameters.srcModule,
          requestParameters.userid,
          requestParameters.acceptLanguage,
          requestParameters.createPartnerSubscriptionRequestInner,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Ügyfélhez tartotó előfizetés lemondása
     * @param {DefaultApiDeletePartnerSubscriptionRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deletePartnerSubscription(
      requestParameters: DefaultApiDeletePartnerSubscriptionRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<void> {
      return localVarFp
        .deletePartnerSubscription(
          requestParameters.clientId,
          requestParameters.subscriptionPackagePriceId,
          requestParameters.id,
          requestParameters.correlid,
          requestParameters.source,
          requestParameters.srcModule,
          requestParameters.userid,
          requestParameters.acceptLanguage,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Visszadja az ügyfélhez számára elérhető előfizetések listáját
     * @param {DefaultApiGetPartnerAvaiableSubscriptionsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPartnerAvaiableSubscriptions(
      requestParameters: DefaultApiGetPartnerAvaiableSubscriptionsRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<Array<Subscription>> {
      return localVarFp
        .getPartnerAvaiableSubscriptions(
          requestParameters.clientId,
          requestParameters.id,
          requestParameters.correlid,
          requestParameters.source,
          requestParameters.srcModule,
          requestParameters.userid,
          requestParameters.acceptLanguage,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Visszadja az ügyfélhez tartotó előfizetéseket
     * @param {DefaultApiGetPartnerSubscriptionsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPartnerSubscriptions(
      requestParameters: DefaultApiGetPartnerSubscriptionsRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<Array<ClientSubscription>> {
      return localVarFp
        .getPartnerSubscriptions(
          requestParameters.clientId,
          requestParameters.id,
          requestParameters.correlid,
          requestParameters.source,
          requestParameters.srcModule,
          requestParameters.userid,
          requestParameters.acceptLanguage,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Visszadja az ügyfélhez tartozó előfizetések eStream számára
     * @param {DefaultApiGetPartnerSubscriptionsForeStreamRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPartnerSubscriptionsForeStream(
      requestParameters: DefaultApiGetPartnerSubscriptionsForeStreamRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<Array<GetPartnerSubscriptionsForeStream200ResponseInner>> {
      return localVarFp
        .getPartnerSubscriptionsForeStream(
          requestParameters.clientId,
          requestParameters.id,
          requestParameters.correlid,
          requestParameters.source,
          requestParameters.srcModule,
          requestParameters.userid,
          requestParameters.acceptLanguage,
          options,
        )
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for createPartnerSubscription operation in DefaultApi.
 * @export
 * @interface DefaultApiCreatePartnerSubscriptionRequest
 */
export interface DefaultApiCreatePartnerSubscriptionRequest {
  /**
   * Az ügyfének azonosítója.
   * @type {string}
   * @memberof DefaultApiCreatePartnerSubscription
   */
  readonly clientId: string;

  /**
   * All requests should include a unique identifier element. The identifier is used for auditing and control of double-submission for update operations. The Id must be prefixed by the application name (or module, if necessary) to be unique in the whole environment. The application name and the Id must be separated with colon (:). Synchronous response to a request must use the same id in the HTTP Header.
   * @type {string}
   * @memberof DefaultApiCreatePartnerSubscription
   */
  readonly id: string;

  /**
   * Flow (or session) identifier. The flow initiator generates the ID; any other party should copy the received ID into all subsequent messages/calls inside the flow. IDs should be unique for each flow.
   * @type {string}
   * @memberof DefaultApiCreatePartnerSubscription
   */
  readonly correlid: string;

  /**
   * This identifier is unique for the different modules of the source systemapplications. The name must be defined as an application module name in VEKER naming style. This field is used for logging and in the gatekeeper configuration too.
   * @type {string}
   * @memberof DefaultApiCreatePartnerSubscription
   */
  readonly source: string;

  /**
   * This identifier is unique for the different consumer systemapplications. The name has to be defined in VEKER naming style as an application name. This field is used for logging, gathering information on service usage and in the gatekeeper configuration too.
   * @type {string}
   * @memberof DefaultApiCreatePartnerSubscription
   */
  readonly srcModule: string;

  /**
   * The identity ID describes the identity of the user that has initiated the service request. This may be, for example, the customer, or an internal user, or may identify a special systemapplication user for calls made by automated systemapplications. If the identification of the original user is not possible (e.g. batch jobs) or the original user is not part of the global user directory (e.g. a customer, who is using a frontend systemapplication), then a technical user name should be used. This is different from the application ID. The Identity ID is of the end user of the systemapplication while the application ID is the calling systemapplication.
   * @type {string}
   * @memberof DefaultApiCreatePartnerSubscription
   */
  readonly userid: string;

  /**
   * A kliens által küldött, és elvárt nyelvi beállításokat tartalmazza. A Accept-language egy szabványos http header elem, melynek formátumát az IETF RFC5646 ajánlása definiálja.
   * @type {string}
   * @memberof DefaultApiCreatePartnerSubscription
   */
  readonly acceptLanguage: string;

  /**
   * Módosítja a felhasználó csoportok sorrendjét.
   * @type {Array<CreatePartnerSubscriptionRequestInner>}
   * @memberof DefaultApiCreatePartnerSubscription
   */
  readonly createPartnerSubscriptionRequestInner?: Array<CreatePartnerSubscriptionRequestInner>;
}

/**
 * Request parameters for deletePartnerSubscription operation in DefaultApi.
 * @export
 * @interface DefaultApiDeletePartnerSubscriptionRequest
 */
export interface DefaultApiDeletePartnerSubscriptionRequest {
  /**
   * Az ügyfének azonosítója.
   * @type {string}
   * @memberof DefaultApiDeletePartnerSubscription
   */
  readonly clientId: string;

  /**
   * Előfizetési csomagDíj azonosító.
   * @type {number}
   * @memberof DefaultApiDeletePartnerSubscription
   */
  readonly subscriptionPackagePriceId: number;

  /**
   * All requests should include a unique identifier element. The identifier is used for auditing and control of double-submission for update operations. The Id must be prefixed by the application name (or module, if necessary) to be unique in the whole environment. The application name and the Id must be separated with colon (:). Synchronous response to a request must use the same id in the HTTP Header.
   * @type {string}
   * @memberof DefaultApiDeletePartnerSubscription
   */
  readonly id: string;

  /**
   * Flow (or session) identifier. The flow initiator generates the ID; any other party should copy the received ID into all subsequent messages/calls inside the flow. IDs should be unique for each flow.
   * @type {string}
   * @memberof DefaultApiDeletePartnerSubscription
   */
  readonly correlid: string;

  /**
   * This identifier is unique for the different modules of the source systemapplications. The name must be defined as an application module name in VEKER naming style. This field is used for logging and in the gatekeeper configuration too.
   * @type {string}
   * @memberof DefaultApiDeletePartnerSubscription
   */
  readonly source: string;

  /**
   * This identifier is unique for the different consumer systemapplications. The name has to be defined in VEKER naming style as an application name. This field is used for logging, gathering information on service usage and in the gatekeeper configuration too.
   * @type {string}
   * @memberof DefaultApiDeletePartnerSubscription
   */
  readonly srcModule: string;

  /**
   * The identity ID describes the identity of the user that has initiated the service request. This may be, for example, the customer, or an internal user, or may identify a special systemapplication user for calls made by automated systemapplications. If the identification of the original user is not possible (e.g. batch jobs) or the original user is not part of the global user directory (e.g. a customer, who is using a frontend systemapplication), then a technical user name should be used. This is different from the application ID. The Identity ID is of the end user of the systemapplication while the application ID is the calling systemapplication.
   * @type {string}
   * @memberof DefaultApiDeletePartnerSubscription
   */
  readonly userid: string;

  /**
   * A kliens által küldött, és elvárt nyelvi beállításokat tartalmazza. A Accept-language egy szabványos http header elem, melynek formátumát az IETF RFC5646 ajánlása definiálja.
   * @type {string}
   * @memberof DefaultApiDeletePartnerSubscription
   */
  readonly acceptLanguage: string;
}

/**
 * Request parameters for getPartnerAvaiableSubscriptions operation in DefaultApi.
 * @export
 * @interface DefaultApiGetPartnerAvaiableSubscriptionsRequest
 */
export interface DefaultApiGetPartnerAvaiableSubscriptionsRequest {
  /**
   * Az ügyfének azonosítója.
   * @type {string}
   * @memberof DefaultApiGetPartnerAvaiableSubscriptions
   */
  readonly clientId: string;

  /**
   * All requests should include a unique identifier element. The identifier is used for auditing and control of double-submission for update operations. The Id must be prefixed by the application name (or module, if necessary) to be unique in the whole environment. The application name and the Id must be separated with colon (:). Synchronous response to a request must use the same id in the HTTP Header.
   * @type {string}
   * @memberof DefaultApiGetPartnerAvaiableSubscriptions
   */
  readonly id: string;

  /**
   * Flow (or session) identifier. The flow initiator generates the ID; any other party should copy the received ID into all subsequent messages/calls inside the flow. IDs should be unique for each flow.
   * @type {string}
   * @memberof DefaultApiGetPartnerAvaiableSubscriptions
   */
  readonly correlid: string;

  /**
   * This identifier is unique for the different modules of the source systemapplications. The name must be defined as an application module name in VEKER naming style. This field is used for logging and in the gatekeeper configuration too.
   * @type {string}
   * @memberof DefaultApiGetPartnerAvaiableSubscriptions
   */
  readonly source: string;

  /**
   * This identifier is unique for the different consumer systemapplications. The name has to be defined in VEKER naming style as an application name. This field is used for logging, gathering information on service usage and in the gatekeeper configuration too.
   * @type {string}
   * @memberof DefaultApiGetPartnerAvaiableSubscriptions
   */
  readonly srcModule: string;

  /**
   * The identity ID describes the identity of the user that has initiated the service request. This may be, for example, the customer, or an internal user, or may identify a special systemapplication user for calls made by automated systemapplications. If the identification of the original user is not possible (e.g. batch jobs) or the original user is not part of the global user directory (e.g. a customer, who is using a frontend systemapplication), then a technical user name should be used. This is different from the application ID. The Identity ID is of the end user of the systemapplication while the application ID is the calling systemapplication.
   * @type {string}
   * @memberof DefaultApiGetPartnerAvaiableSubscriptions
   */
  readonly userid: string;

  /**
   * A kliens által küldött, és elvárt nyelvi beállításokat tartalmazza. A Accept-language egy szabványos http header elem, melynek formátumát az IETF RFC5646 ajánlása definiálja.
   * @type {string}
   * @memberof DefaultApiGetPartnerAvaiableSubscriptions
   */
  readonly acceptLanguage: string;
}

/**
 * Request parameters for getPartnerSubscriptions operation in DefaultApi.
 * @export
 * @interface DefaultApiGetPartnerSubscriptionsRequest
 */
export interface DefaultApiGetPartnerSubscriptionsRequest {
  /**
   * Az ügyfének azonosítója.
   * @type {string}
   * @memberof DefaultApiGetPartnerSubscriptions
   */
  readonly clientId: string;

  /**
   * All requests should include a unique identifier element. The identifier is used for auditing and control of double-submission for update operations. The Id must be prefixed by the application name (or module, if necessary) to be unique in the whole environment. The application name and the Id must be separated with colon (:). Synchronous response to a request must use the same id in the HTTP Header.
   * @type {string}
   * @memberof DefaultApiGetPartnerSubscriptions
   */
  readonly id: string;

  /**
   * Flow (or session) identifier. The flow initiator generates the ID; any other party should copy the received ID into all subsequent messages/calls inside the flow. IDs should be unique for each flow.
   * @type {string}
   * @memberof DefaultApiGetPartnerSubscriptions
   */
  readonly correlid: string;

  /**
   * This identifier is unique for the different modules of the source systemapplications. The name must be defined as an application module name in VEKER naming style. This field is used for logging and in the gatekeeper configuration too.
   * @type {string}
   * @memberof DefaultApiGetPartnerSubscriptions
   */
  readonly source: string;

  /**
   * This identifier is unique for the different consumer systemapplications. The name has to be defined in VEKER naming style as an application name. This field is used for logging, gathering information on service usage and in the gatekeeper configuration too.
   * @type {string}
   * @memberof DefaultApiGetPartnerSubscriptions
   */
  readonly srcModule: string;

  /**
   * The identity ID describes the identity of the user that has initiated the service request. This may be, for example, the customer, or an internal user, or may identify a special systemapplication user for calls made by automated systemapplications. If the identification of the original user is not possible (e.g. batch jobs) or the original user is not part of the global user directory (e.g. a customer, who is using a frontend systemapplication), then a technical user name should be used. This is different from the application ID. The Identity ID is of the end user of the systemapplication while the application ID is the calling systemapplication.
   * @type {string}
   * @memberof DefaultApiGetPartnerSubscriptions
   */
  readonly userid: string;

  /**
   * A kliens által küldött, és elvárt nyelvi beállításokat tartalmazza. A Accept-language egy szabványos http header elem, melynek formátumát az IETF RFC5646 ajánlása definiálja.
   * @type {string}
   * @memberof DefaultApiGetPartnerSubscriptions
   */
  readonly acceptLanguage: string;
}

/**
 * Request parameters for getPartnerSubscriptionsForeStream operation in DefaultApi.
 * @export
 * @interface DefaultApiGetPartnerSubscriptionsForeStreamRequest
 */
export interface DefaultApiGetPartnerSubscriptionsForeStreamRequest {
  /**
   * Az ügyfének azonosítója.
   * @type {string}
   * @memberof DefaultApiGetPartnerSubscriptionsForeStream
   */
  readonly clientId: string;

  /**
   * All requests should include a unique identifier element. The identifier is used for auditing and control of double-submission for update operations. The Id must be prefixed by the application name (or module, if necessary) to be unique in the whole environment. The application name and the Id must be separated with colon (:). Synchronous response to a request must use the same id in the HTTP Header.
   * @type {string}
   * @memberof DefaultApiGetPartnerSubscriptionsForeStream
   */
  readonly id: string;

  /**
   * Flow (or session) identifier. The flow initiator generates the ID; any other party should copy the received ID into all subsequent messages/calls inside the flow. IDs should be unique for each flow.
   * @type {string}
   * @memberof DefaultApiGetPartnerSubscriptionsForeStream
   */
  readonly correlid: string;

  /**
   * This identifier is unique for the different modules of the source systemapplications. The name must be defined as an application module name in VEKER naming style. This field is used for logging and in the gatekeeper configuration too.
   * @type {string}
   * @memberof DefaultApiGetPartnerSubscriptionsForeStream
   */
  readonly source: string;

  /**
   * This identifier is unique for the different consumer systemapplications. The name has to be defined in VEKER naming style as an application name. This field is used for logging, gathering information on service usage and in the gatekeeper configuration too.
   * @type {string}
   * @memberof DefaultApiGetPartnerSubscriptionsForeStream
   */
  readonly srcModule: string;

  /**
   * The identity ID describes the identity of the user that has initiated the service request. This may be, for example, the customer, or an internal user, or may identify a special systemapplication user for calls made by automated systemapplications. If the identification of the original user is not possible (e.g. batch jobs) or the original user is not part of the global user directory (e.g. a customer, who is using a frontend systemapplication), then a technical user name should be used. This is different from the application ID. The Identity ID is of the end user of the systemapplication while the application ID is the calling systemapplication.
   * @type {string}
   * @memberof DefaultApiGetPartnerSubscriptionsForeStream
   */
  readonly userid: string;

  /**
   * A kliens által küldött, és elvárt nyelvi beállításokat tartalmazza. A Accept-language egy szabványos http header elem, melynek formátumát az IETF RFC5646 ajánlása definiálja.
   * @type {string}
   * @memberof DefaultApiGetPartnerSubscriptionsForeStream
   */
  readonly acceptLanguage: string;
}

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
  /**
   *
   * @summary Ügyfél előfizetés létrehozása
   * @param {DefaultApiCreatePartnerSubscriptionRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public createPartnerSubscription(
    requestParameters: DefaultApiCreatePartnerSubscriptionRequest,
    options?: AxiosRequestConfig,
  ) {
    return DefaultApiFp(this.configuration)
      .createPartnerSubscription(
        requestParameters.clientId,
        requestParameters.id,
        requestParameters.correlid,
        requestParameters.source,
        requestParameters.srcModule,
        requestParameters.userid,
        requestParameters.acceptLanguage,
        requestParameters.createPartnerSubscriptionRequestInner,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Ügyfélhez tartotó előfizetés lemondása
   * @param {DefaultApiDeletePartnerSubscriptionRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public deletePartnerSubscription(
    requestParameters: DefaultApiDeletePartnerSubscriptionRequest,
    options?: AxiosRequestConfig,
  ) {
    return DefaultApiFp(this.configuration)
      .deletePartnerSubscription(
        requestParameters.clientId,
        requestParameters.subscriptionPackagePriceId,
        requestParameters.id,
        requestParameters.correlid,
        requestParameters.source,
        requestParameters.srcModule,
        requestParameters.userid,
        requestParameters.acceptLanguage,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Visszadja az ügyfélhez számára elérhető előfizetések listáját
   * @param {DefaultApiGetPartnerAvaiableSubscriptionsRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public getPartnerAvaiableSubscriptions(
    requestParameters: DefaultApiGetPartnerAvaiableSubscriptionsRequest,
    options?: AxiosRequestConfig,
  ) {
    return DefaultApiFp(this.configuration)
      .getPartnerAvaiableSubscriptions(
        requestParameters.clientId,
        requestParameters.id,
        requestParameters.correlid,
        requestParameters.source,
        requestParameters.srcModule,
        requestParameters.userid,
        requestParameters.acceptLanguage,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Visszadja az ügyfélhez tartotó előfizetéseket
   * @param {DefaultApiGetPartnerSubscriptionsRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public getPartnerSubscriptions(
    requestParameters: DefaultApiGetPartnerSubscriptionsRequest,
    options?: AxiosRequestConfig,
  ) {
    return DefaultApiFp(this.configuration)
      .getPartnerSubscriptions(
        requestParameters.clientId,
        requestParameters.id,
        requestParameters.correlid,
        requestParameters.source,
        requestParameters.srcModule,
        requestParameters.userid,
        requestParameters.acceptLanguage,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Visszadja az ügyfélhez tartozó előfizetések eStream számára
   * @param {DefaultApiGetPartnerSubscriptionsForeStreamRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public getPartnerSubscriptionsForeStream(
    requestParameters: DefaultApiGetPartnerSubscriptionsForeStreamRequest,
    options?: AxiosRequestConfig,
  ) {
    return DefaultApiFp(this.configuration)
      .getPartnerSubscriptionsForeStream(
        requestParameters.clientId,
        requestParameters.id,
        requestParameters.correlid,
        requestParameters.source,
        requestParameters.srcModule,
        requestParameters.userid,
        requestParameters.acceptLanguage,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }
}
