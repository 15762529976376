import {
  Configuration,
  DefaultApi as SessionApi,
  DefaultApiSessionInitRequest as SessionApiSessionInitRequest,
} from '@eih-netbroker/api-session';
import { AxiosInstance } from 'axios';
import '../mock/resources/mockedSession.resource';
import { ResourceHeaders } from '../services/useResource';
import { BaseResource } from './base.resource';

export type SessionInitRequest = Omit<SessionApiSessionInitRequest, keyof ResourceHeaders>;

export class SessionResource extends BaseResource {
  // Name has to be overriden, since the build job renames it to 'r' and
  // every resource class will have the same name
  // @ts-ignore
  static readonly name = 'SessionResource';
  private sessionApi: SessionApi;

  constructor(axiosInstance: AxiosInstance, basePath: string) {
    super(axiosInstance, basePath);
    this.sessionApi = new SessionApi(new Configuration(), basePath, axiosInstance);
  }

  async sessionInit(request: SessionInitRequest, headers: ResourceHeaders) {
    return this.sessionApi.sessionInit({ ...request, ...headers });
  }

  async deleteSession(headers: ResourceHeaders) {
    return this.sessionApi.deleteSession(headers);
  }
}
