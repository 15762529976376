import DeleteIcon from '@mui/icons-material/Delete';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Popover } from '@mui/material';
import { MouseEvent, useCallback, useEffect, useState } from 'react';
import { ConfirmDialogResult, useDialog } from '../../../context/DialogContext';
import { useLocale } from '../../../context/NbLocalizationContext';
import { useSnackbar } from '../../../context/SnackbarContext';
import { DraggableList } from '../../../shared/components/draggable-list/DraggableList';
import { DraggableSwitchItem } from '../../../shared/components/draggable-list/DraggableSwitchItem';
import { NbIconButton } from '../../../shared/components/icon-button/IconButton';
import { OpenOrder, useOpenOrders } from '../../../shared/services/useOpenOrders';
import { WidgetElement } from '../../../shared/types/widgetElement';
import { allColumns, OpenOrdersColumn } from '../services/useOpenOrdersColumns';
import { useOrderManagment } from '../services/useOrderManagment';
import { OpenOrdersGrid } from './OpenOrdersGrid';
import classes from './OpenOrdersWidget.module.scss';
import { OrderModal } from './OrderModal';

type OpenOrdersColumnWithId = OpenOrdersColumn & {
  id: string;
  selected?: boolean;
  name: string;
};

export const OpenOrdersWidget = (_props: WidgetElement) => {
  const { translation } = useLocale();
  const { openDialog, confirm } = useDialog();
  const { showError, showSuccess } = useSnackbar();

  const { openOrders } = useOpenOrders();
  const { cancelAllOrders, cancelOrder, loadOrders, orders, mapOrderToOrderForm } = useOrderManagment();
  const [isLoading, setIsLoading] = useState(false);

  const [settingsPopover, setSettingsPopover] = useState<HTMLButtonElement | null>(null);
  const [openOrdersColumns, setOpenOrdersColumns] = useState<Array<OpenOrdersColumnWithId>>(
    allColumns.map(column => {
      return {
        id: column.field,
        field: column.field,
        name: translation.openOrders.grid[column.field] as string,
        selected: true,
      };
    }),
  );

  const toggleColumnVisibility = (column: OpenOrdersColumnWithId) => {
    const newColumnList = openOrdersColumns.map(col =>
      col.field !== column.field
        ? col
        : {
            ...col,
            selected: !col.selected,
          },
    );
    saveOpenOrdersColumns(newColumnList);
  };

  const saveOpenOrdersColumns = async (newColumns: Array<OpenOrdersColumnWithId>) => {
    setOpenOrdersColumns(newColumns);
  };

  const handleSettingsClick = (event: MouseEvent<HTMLButtonElement>) => {
    setSettingsPopover(event.currentTarget);
  };

  const handleSettingsClose = () => {
    setSettingsPopover(null);
  };

  const editOrder = useCallback(
    async (_event: MouseEvent<HTMLButtonElement>, row: OpenOrder) => {
      const order = orders.orders.find(order => order.id === row.id);
      if (order) {
        await openDialog<any>((close: (params?: any) => void) => (
          <OrderModal
            onCancel={() => close()}
            order={mapOrderToOrderForm(order)}
          />
        ));
      }
    },
    [openDialog, orders, mapOrderToOrderForm],
  );

  const onDeleteAll = async () => {
    const result = await confirm({
      title: translation.general.confirmation,
      content: translation.openOrders.confirmDeleteAll,
      confirm: translation.openOrders.approveAll,
      dismiss: translation.general.cancel,
      isDanger: true,
    });
    if (result === ConfirmDialogResult.Confirm) {
      setIsLoading(true);
      try {
        setSettingsPopover(null);
        await cancelAllOrders();
        showSuccess(translation.openOrders.successCancelAllOrders);
      } catch (err) {
        showError(err);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const onDeleteOrder = async (_: MouseEvent<HTMLButtonElement>, row: OpenOrder) => {
    const result = await confirm({
      title: translation.general.confirmation,
      content: translation.openOrders.confirmDelete,
      confirm: translation.openOrders.approve,
      dismiss: translation.general.cancel,
      isDanger: true,
    });

    if (result === ConfirmDialogResult.Confirm) {
      setIsLoading(true);
      try {
        setSettingsPopover(null);
        await cancelOrder(row.id);
        showSuccess(translation.openOrders.successCancelOrder);
      } catch (err) {
        showError(err);
      } finally {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    loadOrders();
  }, [loadOrders]);

  return (
    <div className={classes.openOrders}>
      <div className={classes.header}>
        <NbIconButton
          icon={<MoreVertIcon />}
          onClick={handleSettingsClick}
          className={classes.headerMore}
        />

        <Popover
          open={!!settingsPopover}
          anchorEl={settingsPopover}
          onClose={handleSettingsClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          classes={{ paper: classes.popover }}
        >
          <div className={classes.popoverButton}>
            <p>{translation.openOrders.delete}</p>
            <NbIconButton
              icon={<DeleteIcon />}
              onClick={onDeleteAll}
            />
          </div>
          <span className={classes.popoverTitle}>{translation.openOrders.setColumns}</span>
          <DraggableList
            list={openOrdersColumns}
            onListReordered={saveOpenOrdersColumns}
            renderItem={(column: OpenOrdersColumnWithId) => (
              <DraggableSwitchItem
                label={column.name}
                selected={!!column.selected}
                toggleColumnVisibility={() => toggleColumnVisibility(column)}
                disabled={column.field === 'instrument'}
              />
            )}
          />
        </Popover>
      </div>

      <OpenOrdersGrid
        loading={isLoading}
        openOrders={openOrders}
        onEditOrder={editOrder}
        onCancelOrder={onDeleteOrder}
      />
    </div>
  );
};
