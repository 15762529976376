import MockAdapter from 'axios-mock-adapter';
import { axiosInstance } from '../../services/useResource';
import { mockedInstruments } from '../mockedInstruments';

const mock = new MockAdapter(axiosInstance, { onNoMatch: 'passthrough', delayResponse: 1000 });

mock.onGet(new RegExp('https://api.eih-netbroker.com/listedInstruments/*')).reply(config => {
  const url = new URL(config.url!);
  const q = url.searchParams.get('q') ?? '';

  const filteredInstruments = mockedInstruments.filter(
    _ => _.ticker.toUpperCase().startsWith(q) || _.instrumentName.toUpperCase().startsWith(q) || _.isin === q,
  );

  return [200, filteredInstruments];
});

mock.onGet(new RegExp('https://api.eih-netbroker.com/instruments/batch-query')).reply(config => {
  const url = new URL(config.url!);
  const isinsString = url.searchParams.get('isins') ?? '';
  const isins = isinsString.split(',');

  const filteredInstruments = mockedInstruments.filter(_ => isins.includes(_.isin));

  return [200, filteredInstruments];
});
