import { ListedInstrumentInstumentCategoryEnum } from '@eih-netbroker/api-instrument';
import { ListedInstrument } from '../types/listedInstrument';
import { getRandomEnum } from '../utils/mockUtils';

function instrument(ticker: string, marketShortName: string, name: string, currency: string): ListedInstrument {
  return {
    ticker,
    instrumentName: name,
    currency,
    isin: `${ticker}123456789`,
    instumentCategory: getRandomEnum(ListedInstrumentInstumentCategoryEnum),
    mic: marketShortName,
    marketName: marketShortName,
    marketShortName,
    reutersRegionCode: '',
    ric: ticker,
  };
}

export const mockedInstrument = (ticker: string) => {
  return mockedInstruments.find(_ => _.ticker === ticker) || instrument(ticker, 'BET', ticker, 'HUF');
};

export const mockedInstruments: Array<ListedInstrument> = [
  instrument('TSLA', 'XNAS', 'Tesla Inc.', 'USD'),
  instrument('TOP', 'NasdaqCM', 'TOP Financial Group Limited (TOP)', 'USD'),
  instrument('TSM', 'NYSE', 'Taiwan Semiconductor Manufacturing Company Limited', 'USD'),
  instrument('IBM', 'NYSE', 'International Business Machines Corporation', 'USD'),
  instrument('LRCX', 'NYSE', 'Lam Research Corporation', 'USD'),
  instrument('LVS', 'NYSE', 'Las Vegas Sands Corp.', 'USD'),
  instrument('AA', 'NYSE', 'Alcoa Corporation', 'USD'),
  instrument('GERN', 'NYSE', 'Geron Corporation', 'USD'),
  instrument('NOKIA.HE', 'NYSE', 'Nokia Oyj', 'USD'),
  instrument('TSLL', 'NYSE', 'Direxion Daily TSLA Bull 1.5X Shares', 'USD'),
  instrument('EDU', 'NYSE', 'New Oriental Education & Technology Group Inc.', 'USD'),
  instrument('F', 'NYSE', 'Ford Motor Company', 'USD'),
  instrument('NIO', 'NYSE', 'NIO Inc.', 'USD'),
  instrument('FFIV', 'NYSE', 'F5, Inc.', 'USD'),
  instrument('VOW3.DE', 'XETRA', 'Volkswagen AG', 'EUR'),
  instrument('AMZN', 'NasdaqGS', 'Amazon.com, Inc.', 'USD'),
  instrument('GM', 'NYSE', 'General Motors Company', 'USD'),
  instrument('STLAM.MI', 'NYSE', 'Stellantis N.V.', 'EUR'),
  instrument('TL0.DE', 'XETRA', 'Tesla,', 'EUR'),
  instrument('CXAI', 'NasdaqCM', 'CXApp Inc.', 'USD'),
  instrument('TSLQ', 'NasdaqGM', 'AXS TSLA Bear Daily ETF', 'USD'),
  instrument('UIHC', 'NYSE', 'United Insurance Holdings Corp.', 'USD'),
  instrument('UNH', 'NasdaqCM', 'UnitedHealth Group Incorporated', 'USD'),
  instrument('FRC', 'NasdaqCM', 'First Republic Bank', 'USD'),
  instrument('BMW', 'XETRA', 'BMW AG.', 'EUR'),
  instrument('OTP', 'BET', 'OTP Bank Nyrt.', 'HUF'),
  instrument('4iG', 'BET', '4iG Nyrt.', 'HUF'),
  instrument('MOL', 'BET', 'MOL Nyrt.', 'HUF'),
  instrument('ZWACK', 'BET', 'Zwack Unicum Nyrt.', 'HUF'),
];
