import { atom } from 'recoil';
import { ListedInstrument } from '../../../shared/types/listedInstrument';

export type BETFillListItem = {
  id: string;
  instrument: ListedInstrument;
  price: number;
  quantity: number;
  time: Date;
};

export type BETFillList = {
  list: Array<BETFillListItem>;
};

export const betFillListAtom = atom<BETFillList>({
  key: 'trading/betFillList',
  default: undefined,
});
