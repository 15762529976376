import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useEffect, useState } from 'react';
import { useLocale } from '../../../context/NbLocalizationContext';
import { NbIconButton } from '../../../shared/components/icon-button/IconButton';
import classes from './Carousel.module.scss';

type CarouselItem = {
  title: string;
  link: string;
  backgroundColor?: string;
  backgroundPhoto?: string;
};

interface CarouselProps {
  isPromo: boolean;
  data: Array<CarouselItem>;
}

export const Carousel = ({ isPromo, data }: CarouselProps) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const { translation } = useLocale();

  const handleBack = () => {
    if (currentIndex - 1 <= 0) {
      setCurrentIndex(0);
    } else {
      setCurrentIndex(currentIndex - 1);
    }
  };

  const handleForward = () => {
    setCurrentIndex(prev => prev + 1);
    if (currentIndex + 1 >= data.length - 1) {
      setCurrentIndex(data.length - 1);
    }
  };

  useEffect(() => {
    const setTimeoutId = setTimeout(() => {
      if (currentIndex === data.length - 1) {
        setCurrentIndex(0);
      } else {
        setCurrentIndex(prev => prev + 1);
      }
    }, 6000);

    return () => clearTimeout(setTimeoutId);
  }, [currentIndex, data]);

  return (
    <div className={`${classes.carousel}`}>
      <div className={classes.carouselContainer}>
        <div
          className={classes.carouselWrapper}
          style={{ transform: `translateX(-${currentIndex * 100}%)` }}
        >
          {data.map((item, index) => (
            <div
              key={index}
              className={classes.carouselItem}
              style={{
                background: isPromo ? item.backgroundColor : '',
                backgroundImage: isPromo ? '' : `url(${item.backgroundPhoto})`,
                backgroundSize: isPromo ? '' : 'cover',
              }}
            >
              <h3 className={`${classes.carouselItemTitle} ${isPromo ? classes.carouselItemTitleIsCenter : ''}`}>
                {item.title}
              </h3>
              <div
                className={`${classes.carouselLinkContainer} ${isPromo ? classes.carouselLinkContainerIsCenter : ''}`}
              >
                <a
                  href={item.link}
                  className={`${classes.carouselLink} ${
                    isPromo ? classes.carouselLinkIsPromo : classes.carouselLinkIsTop
                  }`}
                >
                  {isPromo ? translation.dashboard.carousel.apply : translation.dashboard.carousel.details}
                </a>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className={classes.carouselFooter}>
        <NbIconButton
          icon={<ArrowBackIosIcon />}
          onClick={handleBack}
          className={`${currentIndex === 0 ? classes.carouselFooterArrowIsInactive : ''}`}
        />
        <div className={classes.carouselFooterDots}>
          {data.map((item, index) => (
            <span
              key={index}
              className={`${classes.carouselFooterDot} ${
                currentIndex !== index ? classes.carouselFooterDotIsInactive : ''
              }`}
            ></span>
          ))}
        </div>
        <NbIconButton
          icon={<ArrowForwardIosIcon />}
          onClick={handleForward}
          className={`${currentIndex === data.length - 1 ? classes.carouselFooterArrowIsInactive : ''}`}
        />
      </div>
    </div>
  );
};
