import { createLogger } from '../../utils/logger';

/**
 * Base class for creating SSE client
 */
export class SSEClient {
  protected logger = createLogger('SSEClient', 'info');
  protected client: EventSource | null = null;
  protected errorHandler?: () => void;
  protected connectFailedPromiseReject: ((err: unknown) => void) | null = null;
  protected token = '';
  protected userId = '';

  static create(url: string) {
    return new SSEClient(url, () => new EventSource(url));
  }

  static createForTest(url: string, clientConstructor: () => EventSource) {
    return new SSEClient(url, clientConstructor);
  }

  constructor(
    /** @deprecated */
    protected readonly url: string,
    protected readonly clientConstructor: () => EventSource,
  ) {}

  setToken(token: string) {
    this.token = token;
  }

  setUserId(userId: string) {
    this.userId = userId;
  }

  setErrorHandler(errorHandler: () => void) {
    this.errorHandler = errorHandler;
  }

  connect(): Promise<boolean | unknown> {
    return new Promise<boolean | unknown>((resolve, reject) => {
      try {
        // when event source URL is not reachable the client will call onerror in another scope,
        // so we need to memoize this reject function, so we can reject connect
        this.connectFailedPromiseReject = reject;

        this.client = this.clientConstructor();
        this.client.onmessage = this.onMessageReceived.bind(this);
        this.client.onerror = this.onError.bind(this);

        // onopen called when connection created
        this.client.onopen = () => {
          this.connectFailedPromiseReject = null;
          resolve(true);
        };
      } catch (err) {
        this.logger.error(`Failed to create eStream client`, err);
        reject(err);
      }
    });
  }

  disconnect() {
    if (this.client) {
      this.client.close();
    }
    this.client = null;
  }

  /* eslint-disable @typescript-eslint/no-unused-vars */
  protected handleMessage(message: unknown) {}

  private onMessageReceived(ev: MessageEvent) {
    if (ev.type === 'message') {
      try {
        const message = JSON.parse(ev.data as string);
        // this.logger.trace(`Message received from eStream`, message);
        this.handleMessage(message);
      } catch (err) {
        this.logger.error(`Invalid message`, ev.data);
      }
    } else {
      this.logger.error(`Unknown message type`, ev);
    }
  }

  protected onError(ev: Event) {
    // if this is set, that means the error happened during connect phase, so we need to reject
    // connect promise
    if (this.connectFailedPromiseReject) {
      this.connectFailedPromiseReject(ev);
      this.connectFailedPromiseReject = null;
    } else if (this.errorHandler) {
      this.logger.error(`Error from eStream`, ev);
      this.errorHandler();
    }
  }
}
