import { DndProvider } from 'react-dnd';
// @ts-ignore
import { HTML5Backend } from 'react-dnd-html5-backend';
import { BrowserRouter } from 'react-router-dom';
import { RecoilRoot } from 'recoil';
import { AuthProvider } from './AuthContext';
import { DialogProvider } from './DialogContext';
import { NbLocalizationProvider } from './NbLocalizationContext';
import { NbThemeProvider } from './NbThemeContext';
import { SnackbarProvider } from './SnackbarContext';
import { SSEConnectionProvider } from './SSEConnectionContext';

export type ContextProviderProps = { children: React.ReactNode };

export const ContextProvider = ({ children }: ContextProviderProps) => {
  return (
    <RecoilRoot>
      <NbLocalizationProvider>
        <NbThemeProvider>
          <SnackbarProvider>
            <DndProvider backend={HTML5Backend}>
              <BrowserRouter>
                <SSEConnectionProvider>
                  <DialogProvider>
                    <AuthProvider>{children}</AuthProvider>
                  </DialogProvider>
                </SSEConnectionProvider>
              </BrowserRouter>
            </DndProvider>
          </SnackbarProvider>
        </NbThemeProvider>
      </NbLocalizationProvider>
    </RecoilRoot>
  );
};
