import { GridColDef } from '@mui/x-data-grid-pro';
import { useMemo } from 'react';
import { NbDataGrid } from '../../../shared/components/data-grid/DataGrid';
import { useBETFillList } from '../services/useBETFillList';
import { allColumns, useBETFillListColumns } from '../services/useBETFillListColumns';
import { BETFillListItem } from '../states/betFillListAtom';
import classes from './BETFillListGrid.module.scss';

export const BETFillListGrid = () => {
  const { getBETFillListColumn } = useBETFillListColumns();
  const { betFillList } = useBETFillList();

  const columns: Array<GridColDef<BETFillListItem>> = useMemo(() => {
    return [...allColumns].map(column => getBETFillListColumn(column.field));
  }, [getBETFillListColumn]);

  return (
    <>
      <NbDataGrid
        getRowId={row => row.id}
        columns={columns}
        rows={betFillList?.list || []}
        getRowClassName={params =>
          params.row.id.includes('0') ? classes.gridRowIsHigh : params.row.id.includes('9') ? classes.gridRowIsLow : ''
        }
      />
    </>
  );
};
