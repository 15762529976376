import { useCallback, useEffect, useState } from 'react';
import { useDialog } from '../../../context/DialogContext';
import { useLocale } from '../../../context/NbLocalizationContext';
import { useSnackbar } from '../../../context/SnackbarContext';
import { NbButton } from '../../../shared/components/button/Button';
import { ListedInstrument } from '../../../shared/types/listedInstrument';
import { OrderWidgetElement, WidgetElement } from '../../../shared/types/widgetElement';
import { normalOrderSchema } from '../schemas/orderSchemas';
import { useWidget } from '../services/useWidget';
import { useWidgetGroup } from '../services/useWidgetGroup';
import { useWidgetInstrument } from '../services/useWidgetInstrument';
import { OrderForm, OrderFormData } from './OrderForm';
import { OrderModal } from './OrderModal';
import classes from './OrderWidget.module.scss';

export const OrderWidget = (props: WidgetElement) => {
  const orderWidgetElement = props as OrderWidgetElement;
  const { updateWidget } = useWidget();
  const { openDialog } = useDialog();
  const { translation } = useLocale();
  const widgetGroup = useWidgetGroup(props.groupId);
  const { instrument, updateInstrument } = useWidgetInstrument(widgetGroup);
  const { showError } = useSnackbar();
  const [isValid, setIsValid] = useState(false);

  useEffect(() => {
    try {
      normalOrderSchema.validateSync(orderWidgetElement.payLoad, { abortEarly: false });
      setIsValid(true);
    } catch (e) {
      setIsValid(false);
    }
  }, [orderWidgetElement.payLoad]);

  const handleOnSendClick = useCallback(() => {
    const order = orderWidgetElement.payLoad as OrderFormData;

    try {
      normalOrderSchema.validateSync(order, { abortEarly: false });

      openDialog<any>((close: (params?: any) => void) => (
        <OrderModal
          order={order}
          defaultContent="confirm"
          onCancel={() => close()}
        />
      ));
    } catch (e) {
      // TODO: validation error handling
      showError(e);
    }
  }, [openDialog, orderWidgetElement.payLoad, showError]);

  const onChange = (order: OrderFormData) => {
    updateWidget({ ...orderWidgetElement, payLoad: order });
  };

  const changeInstrument = (instrument: ListedInstrument | null) => {
    updateInstrument(instrument);
    updateWidget({ ...orderWidgetElement, payLoad: { ...orderWidgetElement.payLoad, instrument } });
  };

  return (
    <div className={classes.orderWidget}>
      <div className={classes.orderWidgetContent}>
        <OrderForm
          onInstrumentChange={changeInstrument}
          onChange={onChange}
          widgetGroupId={props.groupId || 'default'}
          order={{ ...orderWidgetElement.payLoad, instrument }}
        />
      </div>

      <div className={classes.orderWidgetFooter}>
        <NbButton
          disabled={!isValid}
          onClick={handleOnSendClick}
        >
          {translation.general.send}
        </NbButton>
      </div>
    </div>
  );
};
