import { TextField } from '@mui/material';
import { TextFieldProps } from '@mui/material/TextField/TextField';
import { useEffect, useRef } from 'react';
import classes from './TextField.module.scss';

export type NbTextFieldProps = TextFieldProps & {
  size?: 'small' | 'default' | 'large';
  autoFocus?: boolean;
};

export const NbTextField = ({ className, size, autoFocus, ...props }: NbTextFieldProps) => {
  const sizeClass = size === 'small' ? classes.nbTextFieldIsSmall : '';
  const inputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (autoFocus && inputRef.current) {
      inputRef.current.focus();
    }
  }, [inputRef, autoFocus]);

  return (
    <>
      <TextField
        className={`${classes.nbTextField} ${className ?? ''}`}
        classes={{
          root: `${classes.nbTextField} ${sizeClass}`,
        }}
        inputRef={inputRef}
        {...props}
      />
    </>
  );
};
