import { NbButton } from '../../../shared/components/button/Button';
import { useLayout } from '../services/useLayout';
import classes from './LayoutSelector.module.scss';

export const LayoutSelector = () => {
  const { selectLayout, layouts, selectedLayoutId } = useLayout();

  return (
    <>
      <div className={classes.layoutSelector}>
        {layouts.map(layout => (
          <NbButton
            onClick={() => selectLayout(layout.id)}
            key={layout.id}
            size="small"
            color={selectedLayoutId === layout.id ? 'primary' : 'secondary'}
          >
            {layout.name}
          </NbButton>
        ))}
      </div>
    </>
  );
};
