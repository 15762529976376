import AppRouting from './AppRouting';
import { ContextProvider } from './context';
import { ErrorBoundary } from './modules/core/pages/ErrorBoundary';
import { useHotjarScript } from './shared/services/useHotjarScript';

function App() {
  useHotjarScript();

  return (
    <ErrorBoundary>
      <ContextProvider>
        <AppRouting />
      </ContextProvider>
    </ErrorBoundary>
  );
}

export default App;
