import { FormControlLabel } from '@mui/material';
import { useTheme } from '../../../context/NbThemeContext';
import { NbSwitch } from '../../../shared/components/toggle-button/Switch';
import classes from './UserSettingsPage.module.scss';

export const UserSettingsPage = () => {
  const { setTheme, isDark } = useTheme();

  return (
    <div className={classes.userSettingsPage}>
      <FormControlLabel
        checked={isDark}
        onChange={(_, checked) => setTheme(checked)}
        label={'Sötét téma'}
        control={<NbSwitch />}
      />
    </div>
  );
};
