import { useCallback } from 'react';
import { useRecoilState } from 'recoil';
import { getRandomInteger, resolveLater } from '../../../shared/utils/mockUtils';
import { mockedPortfolioCompositionMedium } from '../mock/mockedPortfolioComposition';
import { portfolioCompositionAtom } from '../states/portfolioCompositionAtom';

export const usePortfolioComposition = () => {
  const [portfolioComposition, setPortfolioComposition] = useRecoilState(portfolioCompositionAtom);

  /**
   * Loads portfolio composition from the backend
   */
  const loadPortfolioComposition = useCallback(async (): Promise<void> => {
    setPortfolioComposition({
      isLoading: true,
    });
    const loadedPortfolioComposition = await resolveLater(mockedPortfolioCompositionMedium);
    setPortfolioComposition({
      isLoading: false,
      portfolioItems: loadedPortfolioComposition,
      investmentLoan: Math.random() > 0.1 ? -1 * getRandomInteger(1000000, 100000000) : undefined,
    });
  }, [setPortfolioComposition]);

  return {
    portfolioComposition,
    loadPortfolioComposition,
  };
};
