import { useEffect, useState } from 'react';
import { NbSelect } from '../../../shared/components/select/Select';
import { useOrderLogs } from '../../../shared/services/useOrderLogs';
import { WidgetElement } from '../../../shared/types/widgetElement';
import { OrderLogGrid } from './OrderLogGrid';
import classes from './OrderLogWidget.module.scss';

export const OrderLogWidget = (_: WidgetElement) => {
  const [isLoading, setIsLoading] = useState(false);
  const { loadOrderLogs, orderLogs } = useOrderLogs();

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        await loadOrderLogs();
      } catch (err) {
      } finally {
        setIsLoading(false);
      }
    })();
  }, [loadOrderLogs]);

  return (
    <div className={classes.orderLog}>
      <div className={classes.orderLogWrapper}>{!isLoading && <OrderLogGrid orderLogs={orderLogs} />}</div>
      <div className={classes.selects}>
        <NbSelect
          className={classes.select}
          size={'small'}
          // TODO: add options once FS is provided
          options={[
            { id: '1', name: 'osszes' },
            { id: '2', name: 'egy' },
          ]}
        />
        <NbSelect
          className={classes.select}
          size={'small'}
          // TODO: add options once FS is provided
          options={[
            { id: '1', name: 'elmult 7 nap' },
            { id: '2', name: 'elmult honap' },
          ]}
        />
      </div>
    </div>
  );
};
