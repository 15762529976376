import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { useDialog } from '../../../context/DialogContext';
import { useLocale } from '../../../context/NbLocalizationContext';
import { useSnackbar } from '../../../context/SnackbarContext';
import { allColumns } from '../../../shared/mock/mockedWatchList';
import {
  WatchListColumn,
  WatchListGroup,
  WatchListGroupId,
  watchListGroupSelector,
  WatchListInstrument,
} from '../../../shared/state/watchListStateAtom';
import { ListedInstrument } from '../../../shared/types/listedInstrument';
import { OrderType } from '../../../shared/types/orderType';
import { TradeType } from '../../../shared/types/tradeType';
import { OrderWidgetElement, SimpleTradeTicketWidgetElement } from '../../../shared/types/widgetElement';
import { WidgetType } from '../../../shared/types/widgetType';
import { OrderModal } from '../../trading/components/OrderModal';
import { PriceAlertEditModal } from '../../trading/components/PriceAlertEditModal';
import { useWidget } from '../../trading/services/useWidget';
import { useWatchList } from '../services/useWatchList';
import classes from './WatchList.module.scss';
import { WatchListEditor } from './WatchListEditor';
import { WatchListGrid } from './WatchListGrid';
import { WatchListHeader } from './WatchListHeader';

export type WatchListProps = {
  isWidgetMode: boolean;
  groupId?: number;
};

export const WatchList = ({ isWidgetMode, groupId }: WatchListProps) => {
  const { watchListGroups, addInstrumentToWatchList, registerEStreamWatchListUpdates } = useWatchList();
  const [selectedGroup, setSelectedGroup] = useState<string | undefined>(
    watchListGroups.length > 0 ? watchListGroups[0].id : undefined,
  );
  const [editedWatchListGroup, setEditedWatchListGroup] = useState<WatchListGroup>();
  const selectedWatchListGroup = useRecoilValue(watchListGroupSelector(selectedGroup));
  const [isLoading, setIsLoading] = useState(false);
  const { showError, showSuccess } = useSnackbar();
  const { createOrUpdateWatchListGroup, deleteWatchListInstrumentFromGroup, deleteAllWatchListInstrumentFromGroup } =
    useWatchList();
  const { translation, translateWithParams } = useLocale();
  const { updateWidget, searchWidgetsByTypeInGroup, updateWidgetGroupState } = useWidget();
  const { openDialog } = useDialog();
  const { confirm } = useDialog();

  const displayedWatchListGroup: WatchListGroup | undefined = editedWatchListGroup ?? selectedWatchListGroup;

  const selectGroup = (group: WatchListGroupId) => {
    setSelectedGroup(group.id);
  };

  const onDeleteWatchList = () => {
    if (watchListGroups.length > 0) {
      setSelectedGroup(watchListGroups[0].id);
    } else {
      setSelectedGroup('');
    }
  };

  const orderWatchListInstrument = (watchListInstrument: WatchListInstrument, tradeType?: TradeType) => {
    const orderForm = {
      instrument: watchListInstrument.instrument,
      tradeType: tradeType,
      orderType: tradeType ? OrderType.Limit : undefined,
      stopLimitPrice:
        tradeType === TradeType.Buy
          ? watchListInstrument.sellPrice
          : tradeType === TradeType.Sell
          ? watchListInstrument.buyPrice
          : undefined,
    };

    if (groupId) {
      updateWidgetGroupState(groupId, { instrument: watchListInstrument.instrument });
      const widgets = searchWidgetsByTypeInGroup(groupId, [WidgetType.Order, WidgetType.SimpleTradeTicket]);
      if (widgets.length > 0) {
        return widgets.forEach(orderWidget => {
          if (
            orderWidget &&
            (orderWidget.type === WidgetType.Order || orderWidget.type === WidgetType.SimpleTradeTicket)
          ) {
            const newWidget: OrderWidgetElement | SimpleTradeTicketWidgetElement = {
              ...orderWidget,
              payLoad: orderForm,
            };

            updateWidget(newWidget);
          }
        });
      }
    }

    openDialog<void>((close: () => void) => (
      <OrderModal
        order={orderForm}
        onCancel={close}
      />
    ));
  };

  const watchListGroupEdit = (watchListGroupId?: WatchListGroupId) => {
    if (!watchListGroupId) {
      setEditedWatchListGroup({
        id: '',
        name: '',
        items: [],
        columns: allColumns,
      });
    } else {
      const targetWatchListGroup = watchListGroups.find(group => group.id === watchListGroupId.id);
      if (!targetWatchListGroup) {
        throw new Error(`Not found watchlistgroup by id ${watchListGroupId.id}`);
      }
      setEditedWatchListGroup(targetWatchListGroup);
    }
  };

  const saveEditedWatchListGroup = async () => {
    try {
      if (!editedWatchListGroup) {
        console.error(`Edited watchlist group is invalid`);
        return;
      }
      setIsLoading(true);
      const newId = await createOrUpdateWatchListGroup(editedWatchListGroup);
      showSuccess(
        editedWatchListGroup.id
          ? translation.watchList.editor.listIsUpdated
          : translation.watchList.editor.listIsCreated,
      );
      closeEditor(newId);
    } catch (err) {
      showError(translation.watchList.editor.listIsAlreadyAdded);
    } finally {
      setIsLoading(false);
    }
  };

  const addInstrument = async (newInstrument: ListedInstrument) => {
    try {
      if (!selectedWatchListGroup) {
        console.error(`No selected watchlist group!`);
        return;
      }
      setIsLoading(true);
      const changedWatchListGroup = addInstrumentToWatchList(selectedWatchListGroup, newInstrument);
      await createOrUpdateWatchListGroup(changedWatchListGroup);
    } catch (err) {
      showError(translation.watchList.editor.instrumentCannotBeAdded);
    } finally {
      setIsLoading(false);
    }
  };

  const deleteWatchListInstrument = async (watchListInstrument: WatchListInstrument) => {
    await confirm({
      title: translation.general.confirmation,
      content: translateWithParams(translation.watchList.editor.confirmDeleteInstrument, {
        name: watchListInstrument.instrument.ticker,
      }),
      confirm: translation.general.confirm,
      dismiss: translation.general.cancel,
      onConfirm: async () => {
        if (editedWatchListGroup) {
          setEditedWatchListGroup({
            ...editedWatchListGroup,
            items: editedWatchListGroup.items.filter(
              item => item.instrument.ric !== watchListInstrument.instrument.ric,
            ),
          });
        } else if (selectedWatchListGroup) {
          await deleteWatchListInstrumentFromGroup(selectedWatchListGroup, watchListInstrument);
        }
      },
    });
  };

  const notifyWatchListInstrument = async (watchListInstrument: WatchListInstrument) => {
    await openDialog((close: () => void) => (
      <PriceAlertEditModal
        onCancel={() => close()}
        instrument={watchListInstrument.instrument}
      />
    ));
  };

  const clearAllInstrument = async () => {
    await confirm({
      title: translation.general.confirmation,
      content: translation.watchList.confirmClearList,
      confirm: translation.general.confirm,
      dismiss: translation.general.cancel,
      onConfirm: async () => {
        if (editedWatchListGroup) {
          setEditedWatchListGroup({ ...editedWatchListGroup, items: [] });
        } else if (selectedWatchListGroup) {
          await deleteAllWatchListInstrumentFromGroup(selectedWatchListGroup);
        }
      },
    });
  };

  const columnsUpdate = (columns: Array<WatchListColumn>) => {
    if (!editedWatchListGroup) {
      console.error(`Invalid edited watchlistgroup`);
      return;
    }
    setEditedWatchListGroup({ ...editedWatchListGroup, columns });
  };

  const closeEditor = (id?: string) => {
    setEditedWatchListGroup(undefined);
    if (id) {
      setSelectedGroup(id);
    }
  };

  useEffect(() => {
    const deregisterFn = registerEStreamWatchListUpdates(selectedGroup);

    return () => {
      deregisterFn();
    };
  }, [selectedGroup, registerEStreamWatchListUpdates]);

  return (
    <div className={classes.nbWatchList}>
      {!editedWatchListGroup && (
        <WatchListHeader
          selectedGroup={selectedGroup || ''}
          selectGroup={selectGroup}
          onDeleteWatchList={onDeleteWatchList}
          onGroupEdit={watchListGroupEdit}
          onAddInstrument={addInstrument}
          headerMode={isWidgetMode ? 'select' : 'tabs'}
        />
      )}

      <div className={classes.nbWatchListContent}>
        {editedWatchListGroup && (
          <WatchListEditor
            editedWatchListGroup={editedWatchListGroup}
            setEditedWatchListGroup={setEditedWatchListGroup}
            onSave={saveEditedWatchListGroup}
            closeEditor={closeEditor}
            isLoading={isLoading}
            onClearInstruments={clearAllInstrument}
            onColumnsUpdate={columnsUpdate}
          />
        )}
        {displayedWatchListGroup && (
          <WatchListGrid
            isEditMode={!!editedWatchListGroup}
            isWidgetMode={isWidgetMode}
            watchListGroup={displayedWatchListGroup}
            onDeleteWatchListInstrument={deleteWatchListInstrument}
            onOrderWatchListInstrument={orderWatchListInstrument}
            onNotifyWatchListInstrument={notifyWatchListInstrument}
          />
        )}
      </div>
    </div>
  );
};
