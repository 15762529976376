import { customAlphabet } from 'nanoid';
import { PortfolioCompositionItem } from '../types/portfolioCompositionItem';

const allowedCharacters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
export const htmlNanoId = customAlphabet(allowedCharacters, 10);

/**
 * Returns the weight of a portfolio item
 * @param portfolioCompositionItem Portfolio item
 * @private
 */
export const getPortfolioItemValue = (portfolioCompositionItem: PortfolioCompositionItem): number => {
  return portfolioCompositionItem.position * portfolioCompositionItem.initValue;
};
