import { DataGridProProps, GridColDef, GRID_DETAIL_PANEL_TOGGLE_COL_DEF } from '@mui/x-data-grid-pro';
import { useCallback, useMemo } from 'react';
import { useLocale } from '../../../context/NbLocalizationContext';
import { NbDataGrid } from '../../../shared/components/data-grid/DataGrid';
import { useFormatter } from '../../../shared/services/useFormatter';
import { allColumns, useOrderLogColumns } from '../services/useOrderLogColumns';
import { OrderLog, OrderLogs } from '../states/orderLogAtom';
import { CustomDetailPanelToggle } from './CustomDetailPanelToggle';
import classes from './OrderLogGrid.module.scss';

export type OrderLogGridProps = {
  orderLogs?: OrderLogs;
};

export const OrderLogGrid = ({ orderLogs }: OrderLogGridProps) => {
  const { getOrderLogColumn } = useOrderLogColumns();
  const { translation } = useLocale();
  const { formatNumberF2, formatInteger } = useFormatter();

  const columns: Array<GridColDef<OrderLog>> = useMemo(() => {
    const newColumns = [...allColumns].map(column => getOrderLogColumn(column.field));

    newColumns.unshift({
      ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
      renderCell: params => <CustomDetailPanelToggle {...params} />,
    });
    return newColumns;
  }, [getOrderLogColumn]);

  const getDetailPanelContent = useCallback<NonNullable<DataGridProProps['getDetailPanelContent']>>(
    ({ row }: { row: OrderLog }) => (
      <div className={classes.subContent}>
        <p className={classes.subContentTitle}>{translation.orderLog.parameters}</p>
        <div>
          <div className={classes.subContentRow}>
            <p className={classes.subContentRowTitle}>{translation.orderLog.grid.instrumentName}</p>
            <p>{row.subElements?.map(el => el.instrument.instrumentName)}</p>
          </div>
          <div className={classes.subContentRow}>
            <p className={classes.subContentRowTitle}>{translation.orderLog.grid.tradeTypeFull}</p>
            <p>{row.subElements?.map(el => el.tradeType)}</p>
          </div>
          <div className={classes.subContentRow}>
            <p className={classes.subContentRowTitle}>{translation.orderLog.grid.orderType}</p>
            <p>{row.subElements?.map(el => el.orderType)}</p>
          </div>
          <div className={classes.subContentRow}>
            <p className={classes.subContentRowTitle}>{translation.orderLog.grid.price}</p>
            <p>{row.subElements?.map(el => formatNumberF2(el.price))}</p>
          </div>
          <div className={classes.subContentRow}>
            <p className={classes.subContentRowTitle}>{translation.orderLog.grid.quantity}</p>
            <p>{row.subElements?.map(el => formatInteger(el.quantity))}</p>
          </div>
          <div className={classes.subContentRow}>
            <p className={classes.subContentRowTitle}>{translation.orderLog.grid.validity}</p>
            <p>{row.subElements?.map(el => el.validity)}</p>
          </div>
          <div className={classes.subContentRow}>
            <p className={classes.subContentRowTitle}>{translation.orderLog.grid.stopPrice}</p>
            <p>{row.subElements?.map(el => formatNumberF2(el.stopPrice))}</p>
          </div>
        </div>
      </div>
    ),
    [formatInteger, formatNumberF2, translation],
  );

  return (
    <>
      <NbDataGrid
        getRowId={row => row.orderId}
        getRowClassName={params =>
          params.indexRelativeToCurrentPage % 2 === 0 ? classes.gridRowIsEven : classes.gridRowIsOdd
        }
        columns={columns}
        rows={orderLogs?.orderLogs || []}
        getDetailPanelHeight={() => 'auto'} // Height based on the content.
        getDetailPanelContent={getDetailPanelContent}
      />
    </>
  );
};
