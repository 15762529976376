import { GridColDef } from '@mui/x-data-grid-pro';
import { MouseEvent, useCallback, useMemo } from 'react';
import { NbDataGrid } from '../../../shared/components/data-grid/DataGrid';
import { WatchListInstrument } from '../../../shared/state/watchListStateAtom';
import { ListedInstrument } from '../../../shared/types/listedInstrument';
import { allColumns, usePriceAlertsColumns } from '../services/usePriceAlertsColumns';
import { PriceAlert, PriceAlerts } from '../states/priceAlertsAtom';

export interface PriceAlertsGridProps {
  priceAlerts?: PriceAlerts;
  onEditAlert: (instrument: ListedInstrument) => void;
  onDeleteAlert: (event: MouseEvent<HTMLButtonElement>, row: PriceAlert) => void;
}

export const PriceAlertsGrid = ({ priceAlerts, onEditAlert, onDeleteAlert }: PriceAlertsGridProps) => {
  const { getPriceAlertsColumn, getPriceAlertsFlatIcons } = usePriceAlertsColumns();

  const editPriceAlert = useCallback(
    async (_event: MouseEvent<HTMLButtonElement>, row: WatchListInstrument) => {
      onEditAlert(row.instrument);
    },
    [onEditAlert],
  );

  const columns: Array<GridColDef<PriceAlert>> = useMemo(() => {
    return [...allColumns]
      .map(column => getPriceAlertsColumn(column.field))
      .concat(
        getPriceAlertsFlatIcons({
          onEditAlert: editPriceAlert,
          onDeleteAlert,
        }),
      ) as Array<GridColDef<PriceAlert>>;
  }, [getPriceAlertsColumn, getPriceAlertsFlatIcons, onDeleteAlert, editPriceAlert]);

  return (
    <NbDataGrid
      getRowId={row => row.instrument.ric}
      rows={priceAlerts?.priceAlerts || []}
      columns={columns}
      initialState={{ pinnedColumns: { right: ['actions'] } }}
    />
  );
};
