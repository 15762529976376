import { styled, Tooltip, tooltipClasses, TooltipProps } from '@mui/material';
import classes from './OrderBookTooltip.module.scss';

export const OrderBookTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip
    {...props}
    classes={{
      popper: className,
      arrow: props.placement === 'left' ? classes.tooltipArrowLeft : classes.tooltipArrowRight,
    }}
    arrow
  />
))(() => ({
  [`& .${tooltipClasses.tooltipPlacementLeft}`]: {
    backgroundColor: classes.colorBackgroundBuy,
    color: classes.colorText,
    fontWeight: classes.fontWeight,
    fontSize: classes.fontSize,
    lineHeight: classes.lineHeight,
  },
  [`& .${tooltipClasses.tooltipPlacementRight}`]: {
    backgroundColor: classes.colorBackgroundSell,
    color: classes.colorText,
    fontWeight: classes.fontWeight,
    fontSize: classes.fontSize,
    lineHeight: classes.lineHeight,
  },
}));
