import { betWidgetConfig } from '../config/betConfig';
import { generalWidgetConfig } from '../config/generalConfig';
import { layoutConfig } from '../config/layoutConfig';
import { playgroundConfig } from '../config/playgroundConfig';
import {
  mockedWatchListGroup1,
  mockedWatchListGroup2,
  mockedWatchListGroup3,
  mockedWatchListGroup4,
} from '../mock/mockedWatchList';
import { WatchListGroup } from '../state/watchListStateAtom';
import { DefaultLayout, LayoutState } from '../types/layoutState';
import { WidgetConfigState } from '../types/widgetConfigState';
import { wait } from '../utils/mockUtils';
import { BaseResource } from './base.resource';

export enum UserSetting {
  Widget = 'Widget',
  Layout = 'Layout',
  ChartLayout = 'ChartLayout',
  WatchList = 'WatchList',
}

export class UserSettingsResource extends BaseResource {
  // Name has to be overriden, since the build job renames it to 'r' and
  // every resource class will have the same name
  // @ts-ignore
  static readonly name = 'UserSettingsResource';

  getWidgetConfig(layoutId: number | DefaultLayout): WidgetConfigState {
    try {
      const storedConfig = window.localStorage.getItem(`nn-${UserSetting.Widget}/${layoutId}`);
      if (storedConfig?.trim().length) {
        return JSON.parse(storedConfig);
      }
      throw new Error('Invalid widget config');
    } catch (err) {
      if (layoutId === DefaultLayout.BET) {
        return betWidgetConfig;
      }

      if (layoutId === DefaultLayout.General) {
        return generalWidgetConfig;
      }

      if (layoutId === DefaultLayout.Playground) {
        return playgroundConfig;
      }

      return betWidgetConfig;
    }
  }

  updateWidgetConfig(widgetConfig: WidgetConfigState): void {
    window.localStorage.setItem(`nn-${UserSetting.Widget}/${widgetConfig.layout}`, JSON.stringify(widgetConfig));
  }

  getLayoutConfig(): LayoutState {
    try {
      const storedConfig = window.localStorage.getItem(`nn-${UserSetting.Layout}`);
      if (storedConfig?.trim().length) {
        return JSON.parse(storedConfig);
      }
      throw new Error('Invalid layout config');
    } catch (err) {
      return layoutConfig;
    }
  }

  getWatchListGroups(): Array<WatchListGroup> {
    try {
      const storedConfig = window.localStorage.getItem(`nn-${UserSetting.WatchList}`);
      if (storedConfig?.trim().length) {
        return JSON.parse(storedConfig);
      }
      throw new Error('Invalid chartlist config');
    } catch (err) {
      return [mockedWatchListGroup1, mockedWatchListGroup2, mockedWatchListGroup3, mockedWatchListGroup4];
    }
  }

  getChartDrawings(instrument: string): object[] {
    try {
      const chartSetting = window.localStorage.getItem(`nn-ChartDrawing/${instrument}`);
      if (chartSetting?.trim().length) {
        return JSON.parse(chartSetting);
      }

      throw new Error('Invalid chart drawings');
    } catch (err) {
      return [];
    }
  }

  getChartLayout(): object[] {
    try {
      const chartLayout = window.localStorage.getItem(`nn-${UserSetting.ChartLayout}`);
      if (chartLayout?.trim().length) {
        return JSON.parse(chartLayout);
      }
      throw new Error('Invalid chart layout');
    } catch (err) {
      return [];
    }
  }

  async updateUserSetting(setting: UserSetting, value: any): Promise<void> {
    await wait();
    window.localStorage.setItem(`nn-${setting}`, JSON.stringify(value));
  }

  async updateChartDrawings(instrument: string, value: any) {
    window.localStorage.setItem(`nn-ChartDrawing/${instrument}`, JSON.stringify(value));
  }
}
