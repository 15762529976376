import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Popover } from '@mui/material';
import { MouseEvent, useEffect, useMemo, useState } from 'react';
import { useDialog } from '../../../context/DialogContext';
import { useLocale } from '../../../context/NbLocalizationContext';
import { DraggableList } from '../../../shared/components/draggable-list/DraggableList';
import { DraggableSwitchItem } from '../../../shared/components/draggable-list/DraggableSwitchItem';
import { NbIconButton } from '../../../shared/components/icon-button/IconButton';
import { NbSelect } from '../../../shared/components/select/Select';
import { mockedInstruments } from '../../../shared/mock/mockedInstruments';
import { useOptions } from '../../../shared/services/useOptions';
import { usePriceAlerts } from '../../../shared/services/usePriceAlerts';
import { ListedInstrument } from '../../../shared/types/listedInstrument';
import { WidgetElement } from '../../../shared/types/widgetElement';
import { allColumns, PriceAlertsColumn } from '../services/usePriceAlertsColumns';
import { PriceAlertEditModal } from './PriceAlertEditModal';
import { PriceAlertsGrid } from './PriceAlertsGrid';
import classes from './PriceAlertsWidget.module.scss';

type PriceAlertsColumnWithId = PriceAlertsColumn & {
  id: string;
  selected?: boolean;
  name: string;
};

export const PriceAlertsWidget = (_: WidgetElement) => {
  const [isLoading, setIsLoading] = useState(false);
  const [settingsPopover, setSettingsPopover] = useState<HTMLButtonElement | null>(null);
  const [selectedInstrument, setSelectedInstrument] = useState<string>('');

  const { selectAllOption } = useOptions();
  const { loadPriceAlerts, priceAlerts } = usePriceAlerts();
  const { translation } = useLocale();
  const { confirm, openDialog } = useDialog();

  const [priceAlertsColumns, setPriceAlertsColumns] = useState<Array<PriceAlertsColumnWithId>>(
    allColumns.map(column => {
      return {
        id: column.field,
        field: column.field,
        name: translation.priceAlerts.grid[column.field],
        selected: true,
      };
    }),
  );

  const filteredPriceAlerts = useMemo(() => {
    if (!priceAlerts) {
      return undefined;
    }

    return {
      ...priceAlerts,
      priceAlerts: priceAlerts.priceAlerts.filter(
        alert => !selectedInstrument || alert.instrument.ric === selectedInstrument,
      ),
    };
  }, [priceAlerts, selectedInstrument]);

  const instruments = useMemo(
    () => [
      selectAllOption,
      ...mockedInstruments
        .filter(instrument => priceAlerts?.priceAlerts.some(alert => alert.instrument.ric === instrument.ric))
        .map(instrument => {
          return {
            id: instrument.ric,
            name: instrument.ticker,
          };
        }),
    ],
    [priceAlerts, selectAllOption],
  );

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        await loadPriceAlerts();
      } catch (err) {
      } finally {
        setIsLoading(false);
      }
    })();
  }, [loadPriceAlerts]);

  const editAlert = async (instrument: ListedInstrument) => {
    await openDialog((close: () => void) => (
      <PriceAlertEditModal
        onCancel={() => close()}
        instrument={instrument}
      />
    ));
  };

  const onDeleteAlert = async () => {
    await confirm({
      // TODO: change translation once confirm modal is available in figma
      title: translation.general.confirmation,
      content: 'Biztosan törölni szeretnéd az összes figyelmeztetést?',
      confirm: 'Törlés',
      dismiss: translation.general.cancel,
      isDanger: true,
    });
  };

  const toggleColumnVisibility = (column: PriceAlertsColumnWithId) => {
    const newColumnList = priceAlertsColumns.map(col =>
      col.field !== column.field
        ? col
        : {
            ...col,
            selected: !col.selected,
          },
    );
    savePriceAlertsColumns(newColumnList);
  };

  const savePriceAlertsColumns = async (newColumns: Array<PriceAlertsColumnWithId>) => {
    setPriceAlertsColumns(newColumns);
  };

  const handleSettingsClose = () => {
    setSettingsPopover(null);
  };

  const handleSettingsClick = (event: MouseEvent<HTMLButtonElement>) => {
    setSettingsPopover(event.currentTarget);
  };

  return (
    <div className={classes.alerts}>
      <div className={classes.header}>
        <NbSelect
          displayEmpty
          size={'small'}
          value={selectedInstrument}
          onChange={event => setSelectedInstrument(event.target.value)}
          options={instruments}
        />
      </div>

      <div className={classes.more}>
        <NbIconButton
          icon={<MoreVertIcon />}
          onClick={handleSettingsClick}
          className={classes.popoverButton}
        />

        <Popover
          open={!!settingsPopover}
          anchorEl={settingsPopover}
          onClose={handleSettingsClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          classes={{ paper: classes.popover }}
        >
          <span className={classes.popoverTitle}>{translation.openOrders.setColumns}</span>
          <DraggableList
            list={priceAlertsColumns}
            onListReordered={savePriceAlertsColumns}
            renderItem={(column: PriceAlertsColumnWithId) => (
              <DraggableSwitchItem
                label={column.name}
                selected={!!column.selected}
                toggleColumnVisibility={() => toggleColumnVisibility(column)}
                disabled={column.field === 'instrument'}
              />
            )}
          />
        </Popover>
      </div>

      {!isLoading && (
        <PriceAlertsGrid
          priceAlerts={filteredPriceAlerts}
          onEditAlert={editAlert}
          onDeleteAlert={onDeleteAlert}
        />
      )}
    </div>
  );
};
