import { useRecoilCallback, useRecoilState } from 'recoil';
import { useLocale } from '../../../context/NbLocalizationContext';
import { useSSEConnection } from '../../../context/SSEConnectionContext';
import { UserSetting, UserSettingsResource } from '../../../shared/resources/userSettings.resource';
import { useResource } from '../../../shared/services/useResource';
import {
  WatchListGroup,
  WatchListGroupId,
  watchListGroupsAtom,
  watchListGroupSelector,
  WatchListInstrument,
} from '../../../shared/state/watchListStateAtom';
import { InstrumentSubscriptionDataScope } from '../../../shared/types/estream/eStreamSubscription';
import { InstrumentStoreItem } from '../../../shared/types/estream/instrumentStore';
import { getInstrumentId, ListedInstrument } from '../../../shared/types/listedInstrument';
import { findById } from '../../../shared/utils/arrayUtils';
import { ObjectSet } from '../../../shared/utils/objectSet';

export const useWatchList = () => {
  const [watchListGroups, setWatchListGroups] = useRecoilState(watchListGroupsAtom);
  const userSettingsResource = useResource(UserSettingsResource);
  const { translation } = useLocale();
  const { subscribeToInstrumentChanges } = useSSEConnection();

  /**
   * Creates a new WatchList group
   * @param watchListGroup
   */
  const createOrUpdateWatchListGroup = async (watchListGroup: WatchListGroup): Promise<string> => {
    const isCreating = !watchListGroup.id;

    const updatedWatchListGroup = !isCreating ? watchListGroup : { ...watchListGroup, id: Date.now().toString() };

    const newWatchListGroups: Array<WatchListGroup> = watchListGroups.map(group =>
      group.id === updatedWatchListGroup.id ? updatedWatchListGroup : group,
    );
    if (isCreating) {
      newWatchListGroups.push(updatedWatchListGroup);
    }

    setWatchListGroups(newWatchListGroups);
    await userSettingsResource.updateUserSetting(UserSetting.WatchList, newWatchListGroups);
    return updatedWatchListGroup.id;
  };

  /**
   * Deletes a WatchList group
   * @param watchListGroupId
   */
  const deleteWatchListGroup = async (watchListGroupId: string): Promise<void> => {
    const newWatchListGroups: Array<WatchListGroup> = watchListGroups.filter(group => group.id !== watchListGroupId);
    // UI trick, first save the new list, and then update the local state, so during loading it will look better
    await userSettingsResource.updateUserSetting(UserSetting.WatchList, newWatchListGroups);
    setWatchListGroups(newWatchListGroups);
  };

  /**
   * Deletes ALL WatchListInstruments from a WatchList group
   * @param editedWatchListGroup
   */
  const deleteAllWatchListInstrumentFromGroup = async (editedWatchListGroup: WatchListGroup) => {
    const newWatchListGroups = watchListGroups.map(group =>
      group.id !== editedWatchListGroup.id ? group : { ...editedWatchListGroup, items: [] },
    );
    // UI trick, first save the new list, and then update the local state, so during loading it will look better
    await userSettingsResource.updateUserSetting(UserSetting.WatchList, newWatchListGroups);
    setWatchListGroups(newWatchListGroups);
  };

  /**
   * Deletes ONE WatchListInstrument from a WatchList group
   * @param editedWatchListGroup
   * @param watchListInstrument
   */
  const deleteWatchListInstrumentFromGroup = async (
    editedWatchListGroup: WatchListGroup,
    watchListInstrument: WatchListInstrument,
  ): Promise<void> => {
    const newWatchListGroup: WatchListGroup = {
      ...editedWatchListGroup,
      items: editedWatchListGroup.items.filter(item => item.instrument.ric !== watchListInstrument.instrument.ric),
    };
    const newWatchListGroups = watchListGroups.map(group =>
      group.id === editedWatchListGroup.id ? newWatchListGroup : group,
    );
    // if the group has an ID we need to persist
    if (editedWatchListGroup.id) {
      // UI trick, first save the new list, and then update the local state, so during loading it will look better
      await userSettingsResource.updateUserSetting(UserSetting.WatchList, newWatchListGroups);
    }
    setWatchListGroups(newWatchListGroups);
  };

  /**
   * Reorders the watchlist groups
   * @param newWatchListGroupIds
   */
  const reorderWatchListGroupsByWatchListGroupIds = async (newWatchListGroupIds: Array<WatchListGroupId>) => {
    const newWatchListGroups = newWatchListGroupIds.map(
      groupId => watchListGroups.find(group => group.id === groupId.id) as WatchListGroup,
    );
    setWatchListGroups(newWatchListGroups);
    await userSettingsResource.updateUserSetting(UserSetting.WatchList, newWatchListGroups);
  };

  /**
   * Adds a new instrument to a watchlist group
   * @param editedWatchListGroup
   * @param newInstrument
   */
  const addInstrumentToWatchList = (
    editedWatchListGroup: WatchListGroup,
    newInstrument: ListedInstrument,
  ): WatchListGroup | never => {
    if (editedWatchListGroup.items.some(item => item.instrument.ric === newInstrument.ric)) {
      throw new Error(translation.watchList.editor.itemIsCreated);
    }

    return {
      ...editedWatchListGroup,
      items: [...editedWatchListGroup.items, { instrument: newInstrument } as WatchListInstrument],
    };
  };

  /**
   * @private
   */
  const updateWatchListGroupByInstrumentStoreItem = (
    selectedGroup: string,
    oldWatchListGroups: Array<WatchListGroup>,
    instrumentStoreItems: ObjectSet<InstrumentStoreItem>,
  ) => {
    const updatedWatchListGroups: Array<WatchListGroup> = structuredClone(oldWatchListGroups);
    const modifiedWatchListGroup = findById(updatedWatchListGroups, selectedGroup);
    if (!modifiedWatchListGroup) {
      return updatedWatchListGroups;
    }
    modifiedWatchListGroup.items.forEach(watchListInstrument => {
      const instrumentStoreItem = instrumentStoreItems.get(getInstrumentId(watchListInstrument.instrument));
      if (instrumentStoreItem) {
        // update the watchlist instrument item
        watchListInstrument.closePrice = instrumentStoreItem.closePrice;
        watchListInstrument.lastPrice = instrumentStoreItem.lastPrice;
        if (instrumentStoreItem.book) {
          watchListInstrument.buyPrice = instrumentStoreItem.book.bids[0][0];
          watchListInstrument.sellPrice = instrumentStoreItem.book.asks[0][0];
        }
        if (instrumentStoreItem.details) {
          watchListInstrument.low = instrumentStoreItem.details.low;
          watchListInstrument.high = instrumentStoreItem.details.high;
          watchListInstrument.averagePrice = instrumentStoreItem.details.averagePrice;
          watchListInstrument.volumeQuantity = instrumentStoreItem.details.volumeQuantity;
          watchListInstrument.volumeValue = instrumentStoreItem.details.volumeValue;
          watchListInstrument.annualLow = instrumentStoreItem.details.annualLow;
          watchListInstrument.annualHigh = instrumentStoreItem.details.annualHigh;
        }
      }
    });
    return updatedWatchListGroups;
  };

  /**
   * Register all instruments from a watchlist group to update from EStream changes
   * @param selectedGroup the selected watchlist group
   * @returns a function to unregister the EStream listeners
   */
  const registerEStreamWatchListUpdates = useRecoilCallback(
    ({ set, snapshot }) =>
      (selectedGroup: string | undefined): (() => void) => {
        const watchListGroup = snapshot.getLoadable(watchListGroupSelector(selectedGroup)).getValue();
        if (!watchListGroup || !selectedGroup) {
          return () => {};
        }
        const instruments = watchListGroup.items.map(watchListInstrument => watchListInstrument.instrument);

        return subscribeToInstrumentChanges(
          { instruments, source: 'watchlist', dataScope: InstrumentSubscriptionDataScope.Trades },
          items => {
            set(watchListGroupsAtom, oldWatchListGroups =>
              updateWatchListGroupByInstrumentStoreItem(selectedGroup, oldWatchListGroups, items),
            );
          },
        );
      },
    [],
  );

  return {
    watchListGroups,
    createOrUpdateWatchListGroup,
    deleteAllWatchListInstrumentFromGroup,
    deleteWatchListInstrumentFromGroup,
    deleteWatchListGroup,
    reorderWatchListGroupsByWatchListGroupIds,
    addInstrumentToWatchList,
    registerEStreamWatchListUpdates,
  };
};
