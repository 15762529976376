import { useRecoilState } from 'recoil';
import { Account, accountsAtom, Client, selectedAccountAtom } from '../state/accountsAtom';

export const useAccounts = () => {
  const [{ clients }] = useRecoilState(accountsAtom);
  const [selectedAccount, setAccounts] = useRecoilState(selectedAccountAtom);

  const selectAccount = (client: Client, account: Account) => {
    setAccounts({
      account: account,
      client: client,
      isAllSelected: false,
    });
  };

  const selectAllAccounts = () => {
    setAccounts({
      isAllSelected: true,
    });
  };

  const account = selectedAccount.isAllSelected === false ? selectedAccount.account : undefined;

  const client = selectedAccount.isAllSelected === false ? selectedAccount.client : undefined;

  const accountName = client?.clientName ? `${client?.clientName ?? ''} ${account?.accountAlias ?? ''}` : undefined;

  return { clients, selectAccount, selectAllAccounts, selectedAccount, account, client, accountName };
};
