const env = (process.env || {}) as unknown as {
  NODE_ENV: string;
  REACT_APP_ESTREAM_STREAM_URL: string;
  REACT_APP_ESTREAM_SUBSCRIPTIONS_URL: string;
  REACT_APP_MUI_X_LICENSE_KEY: string;
};

export const Config = {
  App: {
    isProduction: env.NODE_ENV === 'production',
    API: {
      source: 'netbroker-web',
      module: 'netbroker-web',
      baseUrl: 'https://api.eih-netbroker.com',
    },
    MUILicenseKey: env.REACT_APP_MUI_X_LICENSE_KEY,
  },
  Estream: {
    // EStream event url
    streamUrl: env.REACT_APP_ESTREAM_STREAM_URL,
    // Subscriptions url
    subscriptionsUrl: env.REACT_APP_ESTREAM_SUBSCRIPTIONS_URL,
    // when EStream disconnects this timeout will be used to reconnect for the first n times
    FastReconnectTimeout: 5000,
    // when EStream is unreachable for first n times, this timeout will be used to reconnect
    GeneralReconnectTimeout: 30000,
    // EStream maximum reconnect attempts
    MaxReconnectAttempt: 10,
    // EStream discovery timeout - how long to wait for instances to be discovered
    DiscoveryTimeout: 1000,
  },
  Layout: {
    grid: {
      rowHeight: 36,
      toggleColumnWidth: 50,
    },
  },
  NetBroker: {
    baseCurrency: 'HUF',
  },
  PriceAlertCharacterCount: 150,
};
