import axios from 'axios';
import { useMemo } from 'react';
import { Config } from '../config/config';
import { BaseResource } from '../resources/base.resource';
import { useAccounts } from './useAccounts';

type ConstructorType<T extends BaseResource> = new (...args: any[]) => T;

export interface ResourceHeaders {
  readonly id: string;
  readonly correlid: string;
  readonly source: string;
  readonly srcModule: string;
  readonly userid: string;
  readonly accountId: string;
  readonly acceptLanguage: string;
}
const classes: { [key: string]: BaseResource } = {};

export const axiosInstance = axios.create({
  baseURL: Config.App.API.baseUrl,
  headers: {},
});

export const useResource = <T extends BaseResource>(resourceClass: ConstructorType<T>): T => {
  if (!classes[resourceClass.name]) {
    classes[resourceClass.name] = new resourceClass(axiosInstance, Config.App.API.baseUrl);
  }

  return classes[resourceClass.name] as T;
};

export const useResourceHeaders = (): ResourceHeaders => {
  const { account } = useAccounts();

  const headers = useMemo(() => {
    return {
      id: 'id',
      correlid: 'correlid',
      source: 'source',
      srcModule: 'srcModule',
      userid: 'userid',
      // TODO: headerWithAccountId???
      accountId: account?.accountNumber ?? '',
      acceptLanguage: 'hu_HU',
    };
  }, [account]);

  return headers;
};
