import widgetScreen from '../../../assets/widget_screens/widget_net-positions.png';
import { WidgetElement } from '../../../shared/types/widgetElement';

export const NetPositionsWidget = (props: WidgetElement) => {
  return (
    <>
      <img
        id={`netpositions-${props.id}`}
        src={widgetScreen}
        alt="net"
      />
    </>
  );
};
