import { AccountAccountCategoryEnum, AccountAccountProxyTypeEnum } from '@eih-netbroker/api-account';
import { atom } from 'recoil';

export const AccountCategory = AccountAccountCategoryEnum;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type AccountCategory = (typeof AccountCategory)[keyof typeof AccountCategory];

export const AccountProxyType = AccountAccountProxyTypeEnum;
// eslint-disable-next-line @typescript-eslint/no-redeclare
export type AccountProxyType = (typeof AccountProxyType)[keyof typeof AccountProxyType];

export type SelectedAccount =
  | {
      isAllSelected: true;
    }
  | {
      isAllSelected: false;
      account: Account;
      client: Client;
    };

export type Account = {
  accountNumber: string;
  accountCategory: AccountCategory;
  accountProxyType: AccountProxyType;
  accountAlias?: string;
};

export type Client = {
  clientId: string;
  accounts: Account[];
  clientName: string;
};

export type Accounts = {
  clients: Array<Client>;
};

export const accountsAtom = atom<Accounts>({
  key: 'core/accounts',
  default: {
    clients: [],
  },
});

export const selectedAccountAtom = atom<SelectedAccount>({
  key: 'core/selectedAccount',
  default: {
    isAllSelected: true,
  },
});
