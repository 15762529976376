import CloseIcon from '@mui/icons-material/Close';
import { useState } from 'react';
import { useLocale } from '../../../context/NbLocalizationContext';
import { NbButton } from '../../../shared/components/button/Button';
import { NbIconButton } from '../../../shared/components/icon-button/IconButton';
import { NbTextField } from '../../../shared/components/text-field/TextField';
import classes from './NoteModal.module.scss';

export interface NoteModalProps {
  onCancel: () => void;
}

export const NoteModal = ({ onCancel }: NoteModalProps) => {
  const { translation } = useLocale();
  const [note, setNote] = useState<string>('');

  return (
    <div className={classes.note}>
      <div className={classes.noteHeader}>
        <h4 className={classes.noteTitle}>{translation.noteModal.note}</h4>
        <NbIconButton
          onClick={onCancel}
          icon={<CloseIcon />}
        />
      </div>

      <div className={classes.noteContent}>
        <NbTextField
          placeholder={translation.noteModal.placeholder}
          value={note}
          onChange={e => setNote(e.target.value)}
          multiline
          rows={6}
        />
        {note.length > 0 && (
          <span className={classes.noteContentSave}>{translation.noteModal.lastSave}: 2012.01.07.</span>
        )}
      </div>

      <div className={classes.noteButtons}>
        <NbButton
          color={'secondary'}
          onClick={onCancel}
        >
          {translation.general.cancel}
        </NbButton>
        <NbButton>{translation.general.save}</NbButton>
      </div>
    </div>
  );
};
