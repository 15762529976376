import { Account } from '@eih-netbroker/api-account';
import { AccountCategory, AccountProxyType } from '../state/accountsAtom';

const mockAccounts = (
  accountNumber: string,
  clientId: string,
  accountCategory: AccountCategory,
  accountProxyType: AccountProxyType,
  clientName: string,
  accountAlias: string,
): Account => {
  return {
    accountNumber,
    clientId,
    accountCategory: accountCategory,
    accountProxyType,
    clientName,
    accountAlias,
  };
};

export const mockedAccounts: Array<Account> = [
  mockAccounts('1', '123456', AccountCategory.Normal, AccountProxyType.Main, 'Karen Basics', 'V5079058'),
  mockAccounts('2', '678912', AccountCategory.Tbsz2010, AccountProxyType.Sub, 'John Doe', 'V5079059'),
  mockAccounts('3', '432169', AccountCategory.Normal, AccountProxyType.Main, 'Jane Doe', 'V5079051'),
];
