import React, { createContext, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { eStreamClient } from '../shared/services/sse/eStreamClient';
import { sseManager } from '../shared/services/sse/SSEManager';
import { useEStreamConnection } from '../shared/services/sse/useEStreamConnection';
import { useAppDebugger } from '../shared/services/useAppDebugger';
import { useAuthEvents } from '../shared/services/useAuthEvents';
import { appAuthAtom } from '../shared/state/authStateAtom';
import { SubscribeList, SubscribeOptions } from '../shared/types/estream/eStreamSubscription';
import { EStreamTradeDTO } from '../shared/types/estream/eStreamTrade';
import { InstrumentStore, InstrumentStoreItem } from '../shared/types/estream/instrumentStore';
import { createLogger } from '../shared/utils/logger';
import { ObjectSet } from '../shared/utils/objectSet';

export enum ConnectionState {
  Connected = 'Connected',
  Connecting = 'Connecting',
  FailedToConnected = 'FailedToConnected',
  Disconnected = 'Disconnected',
}

type EStreamContextProps =
  | undefined
  | {
      connectEStream: () => Promise<void>;
      disconnectEStream: (disableReconnect?: boolean) => void;
      subscribeToInstrumentChanges: (
        options: Array<SubscribeOptions> | SubscribeList,
        onInstrumentChange: (items: ObjectSet<InstrumentStoreItem>) => void,
      ) => () => void;
      hasEStreamConnection: boolean;
      instrumentStore: InstrumentStore;
    };

export type SSEState = {
  /**
   * Marks if this instance is the primary instance, meaning this handles SSE connection
   */
  thisInstanceIsPrimary: boolean;
  /**
   * EStream connection can be created if SSE manager decides, that this instance is the primary AND user can be logged in
   */
  eStreamConnectionRequested: boolean;
  /**
   * Flag to indicate if this or another instance have an active EStream connection
   */
  hasEStreamConnection: boolean;
  /**
   * Is the login initiated from a broadcast message because user has logged in from another instance
   */
  loginInitiatedFromBroadcast: boolean;
  /**
   * When the primary browser instance is disconnected this will hold the destroyed instance previous subscriptions,
   * so we can re-register subscriptions in the new primary instance
   */
  previousPrimaryInstanceEStreamSubscriptions?: Array<SubscribeOptions>;
};

const SSEConnectionContext = createContext<EStreamContextProps>(undefined);

/**
 * Provider for handling SSE (e.g.: EStream) connections
 *
 * This provides the basic EStream functionalities:
 *  - connect/disconnect
 *  - subscribe/unsubscribe
 */
export const SSEConnectionProvider = ({
  children,
  offlineMode,
}: {
  children: React.ReactNode;
  offlineMode?: boolean;
}) => {
  const logger = useRef(createLogger('SSEConnectionContext'));
  const authState = useRecoilValue(appAuthAtom);
  const authEvents = useAuthEvents();

  const sseState = useRef<SSEState>({
    thisInstanceIsPrimary: false,
    eStreamConnectionRequested: false,
    hasEStreamConnection: false,
    loginInitiatedFromBroadcast: false,
    previousPrimaryInstanceEStreamSubscriptions: undefined,
  });

  const instrumentStore = useRef<InstrumentStore>(new InstrumentStore());

  const [hadEStreamConnection, setHadEStreamConnection] = useState(false);

  const {
    connectionState,
    Internal_setConnectionState,
    Internal_CreateEStreamClient,
    Internal_DisconnectEStream,
    Internal_AddEStreamSubscriptions,
    Internal_RemoveEStreamSubscriptions,
    Internal_RemoveEStreamSubscriptionsByInstanceId,
    showConnectionReconnected,
    showConnectionFailed,
    showConnectionInitFailed,
    Internal_SubscriptionQueue,
  } = useEStreamConnection({
    offlineMode,
    onConnectionStateChange: connectionState => {
      sseState.current.hasEStreamConnection = connectionState === ConnectionState.Connected;
      if (connectionState === ConnectionState.Connected) {
        setHadEStreamConnection(true);
      }
      if (connectionState !== ConnectionState.Connected) {
        sseState.current.eStreamConnectionRequested = false;
      }
    },
  });

  const appDebugger = useAppDebugger({
    sseState: sseState.current,
    instrumentStore: instrumentStore.current,
    subscriptionQueue: Internal_SubscriptionQueue,
  });

  /**
   * When SSEManager decides we need to be the primary instance, we try to connect to EStream if we need to
   * @private
   */
  const handleSSEEvents = () => {
    if (sseState.current.thisInstanceIsPrimary && sseState.current.eStreamConnectionRequested) {
      logger.current.info(`SSEManager decided we are the primary instance, trying to connect to EStream`);
      Internal_CreateEStreamClient({});
    }
  };

  /**
   * Handles the EStream message:
   *  - updates the local instrument store
   *  - if this is the primary instance, we broadcast the message to other instances
   * @param trades
   * @private
   */
  const onEStreamTradesReceived = (trades: Array<EStreamTradeDTO>) => {
    if (trades.length === 0) {
      return;
    }

    logger.current.trace(`Received ${trades.length} trades from EStream`);
    instrumentStore.current.setMany(trades);

    // if we are the primary instance we need other instances to know about the EStream messages
    if (sseState.current.thisInstanceIsPrimary) {
      sseManager.broadcastEStreamMessage(trades);
    }
  };

  /**
   * Called by SSEManager when this instance is selected as the primary one
   * @private
   */
  const onThisInstanceSelectedAsPrimary = (eStreamSubscriptions?: Array<SubscribeOptions>) => {
    logger.current.info(`This instance selected as primary`);
    sseState.current.thisInstanceIsPrimary = true;
    sseState.current.previousPrimaryInstanceEStreamSubscriptions = eStreamSubscriptions;
    handleSSEEvents();
  };

  /**
   * When we are NOT the primary instance displays the connection status message:
   *  - if we are disconnected, we show the connection failed message
   *  - if we are connected, we show the connection reconnected message
   * @param connectionState
   * @param isInitalValue true if this status updates comes from sse manager initialization
   */
  const onConnectionStateUpdate = (connectionState: ConnectionState, isInitalValue: boolean) => {
    logger.current.info(`onConnectionStateUpdate: ${connectionState} (isInitalValue: ${isInitalValue})`);
    if (isInitalValue) {
      Internal_setConnectionState(connectionState);
      sseState.current.hasEStreamConnection = connectionState === ConnectionState.Connected;
    } else {
      if (connectionState === ConnectionState.Disconnected || connectionState === ConnectionState.FailedToConnected) {
        if (sseState.current.hasEStreamConnection) {
          showConnectionFailed();
        } else {
          showConnectionInitFailed();
        }
        sseState.current.hasEStreamConnection = false;
      } else if (connectionState === ConnectionState.Connected) {
        showConnectionReconnected();
        sseState.current.hasEStreamConnection = true;
      }
    }

    if (connectionState === ConnectionState.Connected && sseState.current.previousPrimaryInstanceEStreamSubscriptions) {
      Internal_AddEStreamSubscriptions(sseState.current.previousPrimaryInstanceEStreamSubscriptions);
      sseState.current.previousPrimaryInstanceEStreamSubscriptions = undefined;
    }
  };

  /**
   * Called when another client logs in, and other browser instances needs to be logged in as well
   * @param token the SSO token
   */
  const onOtherInstanceLoggedIn = async (token: string) => {
    if (!authState.isAuthenticated) {
      sseState.current.loginInitiatedFromBroadcast = true;
      logger.current.info(`onOtherInstanceLoggedIn: ${new Array(token.length).fill('*').join('')}`);
      authEvents.login(token);
    }
  };

  /**
   * Called when another client logs out, and all other clients needs to be logged out as well
   */
  const onOtherInstanceLoggedOut = async () => {
    if (authState.isAuthenticated) {
      // User is signed off in another tab, se we can just refresh the page, theoretically user won't be logged in
      await authEvents.logout();
      eStreamClient.disconnect();
      sseState.current.thisInstanceIsPrimary = false;
      sseState.current.hasEStreamConnection = false;
      sseState.current.loginInitiatedFromBroadcast = false;
      sseState.current.previousPrimaryInstanceEStreamSubscriptions = undefined;
    }
  };

  const onOtherInstanceDestroyed = async (instanceId: number) => {
    if (sseState.current.thisInstanceIsPrimary) {
      Internal_RemoveEStreamSubscriptionsByInstanceId(instanceId);
    }
  };

  /**
   * When there is an EStream subscription change, we need to update the subscription on the primary instance
   * @param dir
   * @param options
   */
  const onEStreamSubscriptionChange = async (
    dir: 'subscribe' | 'unsubscribe',
    options: Array<SubscribeOptions>,
    instanceId: number,
  ) => {
    if (sseState.current.thisInstanceIsPrimary) {
      if (dir === 'subscribe') {
        Internal_AddEStreamSubscriptions(options, instanceId);
      } else {
        Internal_RemoveEStreamSubscriptions(options);
      }
    }
  };

  const setupSSEManagerEvents = () => {
    sseManager.updateEventHandlers({
      onThisInstanceSelectedAsPrimary,
      onConnectionStateUpdate,
      onEStreamTradesReceived,
      onOtherInstanceLoggedIn,
      onOtherInstanceLoggedOut,
      onOtherInstanceDestroyed,
      onEStreamSubscriptionChange,
    });
  };
  setupSSEManagerEvents();

  /**
   * - initializes the SSE Manager, which tries to decide which instance should be the primary one
   * - sets up events coming from SSE Manager
   * - handles the instance destroy event (aka browser window/tab has closed)
   * @private
   */
  const initSSE = () => {
    eStreamClient.addEventHandlers({ onMessageCallback: onEStreamTradesReceived });

    sseManager.init({
      onThisInstanceSelectedAsPrimary,
      onConnectionStateUpdate,
      onEStreamTradesReceived,
      onOtherInstanceLoggedIn,
      onOtherInstanceLoggedOut,
      onOtherInstanceDestroyed,
      onEStreamSubscriptionChange,
    });

    window.addEventListener('beforeunload', _event => {
      sseManager.destroy(Internal_SubscriptionQueue);
      // this is the place where we can show the "you are about to leave the page" message
      // event.preventDefault();
      // return event.returnValue = '';
    });
  };

  /**
   * Initiate the EStream connection
   */
  const connectEStream = async (): Promise<void> => {
    logger.current.info(`connectEStream initiated from login`);
    sseState.current.eStreamConnectionRequested = true;
    handleSSEEvents();
  };

  /**
   * Disconnects the eStream connection, and will not try to reconnect
   */
  const disconnect = () => {
    if (sseState.current.thisInstanceIsPrimary) {
      Internal_DisconnectEStream();
    }
  };

  /**
   * Subscribes to EStream data based on the options
   * @param pOptions instruments to subscribe to
   * @param onInstrumentChange callback when the instrument data changes
   * @returns a function which will unsubscribe
   */
  const subscribeToInstrumentChanges = (
    pOptions: Array<SubscribeOptions> | SubscribeList,
    onInstrumentChange: (items: ObjectSet<InstrumentStoreItem>) => void,
  ): (() => void) => {
    const options = Array.isArray(pOptions)
      ? pOptions
      : pOptions.instruments.map(instrument => ({
          instrument,
          source: pOptions.source,
          dataScope: pOptions.dataScope,
        }));

    const deregisterInstrumentStoreChanges = instrumentStore.current.registerAll(
      options.map(o => o.instrument),
      onInstrumentChange,
    );

    if (sseState.current.thisInstanceIsPrimary) {
      Internal_AddEStreamSubscriptions(options);
      return () => {
        Internal_RemoveEStreamSubscriptions(options);
        deregisterInstrumentStoreChanges();
      };
    } else {
      sseManager.broadcastSubscribeToInstruments(options);
      return () => {
        sseManager.broadcastUnsubscribeFromInstruments(options);
        deregisterInstrumentStoreChanges();
      };
    }
  };

  /**
   * Initializes the SSE event listeners, and sets up the SSE Manager
   */
  useEffect(() => {
    initSSE();
    // React wants us to include initSSE, but we don't want to wrap every function into a useCallback
    //
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * When the connection state changes, we need to broadcast it to other instances
   */
  useEffect(() => {
    // broadcast the connection state to other instances if we are logged in
    if (sseState.current.thisInstanceIsPrimary && sseManager.isPrimaryInstanceSelected && authState.isAuthenticated) {
      sseManager.broadcastConnectionState(connectionState);
    }
  }, [connectionState, authState.isAuthenticated]);

  /**
   * Broadcasts the login event to other instances if we are logged in and this instance initiated the login
   */
  useEffect(() => {
    if (authState.isAuthenticated && !sseState.current.loginInitiatedFromBroadcast) {
      // TODO add current live token
      sseManager.broadcastLogin('xxx');
      sseState.current.loginInitiatedFromBroadcast = true;
    }
    if (!authState.isAuthenticated) {
      sseState.current.loginInitiatedFromBroadcast = false;
    }
  }, [authState.isAuthenticated]);

  // to prevent constant re-rendering we need to wrap these into useMemo...
  const values = useMemo(
    () => ({
      connectEStream,
      disconnectEStream: disconnect,
      subscribeToInstrumentChanges,
      hadEStreamConnection,
      hasEStreamConnection: connectionState === ConnectionState.Connected,
      instrumentStore: instrumentStore.current,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [connectionState, hadEStreamConnection],
  );

  return (
    <SSEConnectionContext.Provider value={values}>
      {children}
      {appDebugger}
    </SSEConnectionContext.Provider>
  );
};

export const useSSEConnection = () => {
  const context = useContext(SSEConnectionContext);
  if (context === undefined) {
    throw new Error(`useSSEConnection must be used within an SSEConnectionProvider`);
  }
  return context;
};
