import { useRecoilState } from 'recoil';
import { useLocale } from '../../../context/NbLocalizationContext';
import { useFormatter } from '../../../shared/services/useFormatter';
import { ProductDetailsBase, productDetailstStateAtom } from '../../../shared/state/productDetailsStateAtom';
import { getInstrumentId, ListedInstrument } from '../../../shared/types/listedInstrument';
import { ProductDetailsSectionItem } from '../partials/ProductDetailsSection';

export const tradingFields1: Array<keyof ProductDetailsBase> = [
  'openPrice',
  'low',
  'high',
  'averagePrice',
  'lastPrice',
  'previousClose',
  'dailyChange',
];

export const tradingFields2: Array<keyof ProductDetailsBase> = [
  'dailyChangePercent',
  'YTDChangePercent',
  'annualLow',
  'annualHigh',
  'volumeQuantity',
  'volumeValue',
  'monthlyAverageVolume',
];

export const fundamentalFields1: Array<keyof ProductDetailsBase> = [
  'marketCapitalization',
  'earningsPerShareTTM',
  'priceToBookValue',
  'priceToEarnings',
  'priceToEarningsTTM',
  'priceToSalesTTM',
];

export const fundamentalFields2: Array<keyof ProductDetailsBase> = [
  'dividend',
  'dividendYield',
  'dividendYield5Years',
  'EBITDA',
  'ROE',
  'ROA',
];

export const instrumentsFields: Array<keyof ListedInstrument> = [
  'isin',
  'instumentCategory',
  'marketShortName',
  'currency',
];

export const useProductDetailsList = (instrument: ListedInstrument | null) => {
  const [product] = useRecoilState(productDetailstStateAtom(instrument ? getInstrumentId(instrument) : ''));
  const { translation } = useLocale();
  const { formatNumber } = useFormatter();

  const unknownValue = '';

  const tradingSectionItems1: Array<ProductDetailsSectionItem> = tradingFields1.map(f => ({
    label: translation.productDetails.productDetailsFields[f],
    value: product ? formatNumber(product[f]) : unknownValue,
  }));

  const tradingSectionItems2: Array<ProductDetailsSectionItem> = tradingFields2.map(f => ({
    label: translation.productDetails.productDetailsFields[f],
    value: product ? formatNumber(product[f]) : unknownValue,
  }));

  const fundamentalSectionItems1: Array<ProductDetailsSectionItem> = fundamentalFields1.map(f => ({
    label: translation.productDetails.productDetailsFields[f],
    value: product ? formatNumber(product[f]) : unknownValue,
  }));

  const fundamentalSectionItems2: Array<ProductDetailsSectionItem> = fundamentalFields2.map(f => ({
    label: translation.productDetails.productDetailsFields[f],
    value: product ? formatNumber(product[f]) : unknownValue,
  }));

  const instrumentSectionItems1: Array<ProductDetailsSectionItem> = [
    {
      label: translation.productDetails.instrumentFields.isin,
      value: instrument ? instrument.isin : unknownValue,
    },
    {
      label: translation.productDetails.instrumentFields.instrumentCategory,
      value: instrument ? instrument.instumentCategory : unknownValue,
    },
  ];

  const instrumentSectionItems2: Array<ProductDetailsSectionItem> = [
    {
      label: translation.productDetails.instrumentFields.marketCode,
      value: instrument ? instrument.marketShortName : unknownValue,
    },
    {
      label: translation.productDetails.instrumentFields.currencyCode,
      value: instrument ? instrument.currency : unknownValue,
    },
  ];

  return {
    tradingSectionItems1,
    tradingSectionItems2,
    fundamentalSectionItems1,
    fundamentalSectionItems2,
    instrumentSectionItems1,
    instrumentSectionItems2,
  };
};
