import { InputProps } from '@mui/material';
import { NumericFormat, NumericFormatProps } from 'react-number-format';
import { useLocale } from '../../../context/NbLocalizationContext';

export function NbNumericFormat<BaseType = InputProps>(props: NumericFormatProps<BaseType>) {
  const { translation } = useLocale();
  return (
    <NumericFormat
      decimalSeparator={translation.numericFormat.decimalSeparator}
      thousandSeparator={translation.numericFormat.thousandSeparator}
      decimalScale={2}
      allowNegative={false}
      {...props}
    />
  );
}
