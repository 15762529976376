import { GridRowClassNameParams, GridValidRowModel } from '@mui/x-data-grid';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { DataGridProProps } from '@mui/x-data-grid-pro/models/dataGridProProps';
import { useLocale } from '../../../context/NbLocalizationContext';
import { useTheme } from '../../../context/NbThemeContext';
import { Config } from '../../config/config';
import classes from './DataGrid.module.scss';

export type NbDataGridProps<R extends GridValidRowModel = any> = DataGridProProps<R> & {
  /**
   * Set true to alternate row color (default: true)
   */
  alternateRowColor?: boolean;
};

const sxDark = {
  border: 'none',
  '& > .MuiDataGrid-main': {
    '& > .MuiDataGrid-columnHeaders': {
      borderBottom: 'none',
    },

    '& div div div div >.MuiDataGrid-cell': {
      borderBottom: 'none',
    },
  },
};

export const NbDataGrid = <R extends GridValidRowModel = any>({
  rowHeight,
  columnHeaderHeight,
  getRowClassName,
  alternateRowColor,
  ...props
}: NbDataGridProps<R>) => {
  const { translation } = useLocale();
  const { rem, isDark } = useTheme();

  const nbGetRowClassName = (params: GridRowClassNameParams<R>): string => {
    const alternateRowColorStyle =
      alternateRowColor !== false
        ? params.indexRelativeToCurrentPage % 2 === 0
          ? classes.dataGridRowIsEven
          : classes.dataGridRowIsOdd
        : classes.dataGridRowIsEven;
    return alternateRowColorStyle + (getRowClassName ? ` ${getRowClassName(params)}` : '');
  };

  return (
    <DataGridPro
      {...props}
      rowHeight={rowHeight || rem(Config.Layout.grid.rowHeight)}
      getRowClassName={nbGetRowClassName}
      columnHeaderHeight={columnHeaderHeight === undefined ? rem(Config.Layout.grid.rowHeight) : columnHeaderHeight}
      autoHeight={props.autoHeight === undefined ? true : props.autoHeight}
      rowSelection={false}
      className={classes.dataGrid}
      sx={{
        ...(isDark ? sxDark : {}),

        '& .MuiDataGrid-cell:focus-within': {
          outline: 'none',
        },
        '& .MuiDataGrid-cell': {
          fontSize: rem(12),
        },
        '& .MuiDataGrid-columnHeaderTitleContainer': {
          fontSize: rem(12),
        },
        ...props.sx,
      }}
      hideFooter
      localeText={{
        noRowsLabel: translation.general.grid.noRowsLabel,
        footerTotalRows: translation.general.grid.footerTotalRows,
        footerRowSelected: count =>
          count >= 1 ? `${count.toLocaleString()} ${translation.general.grid.footerRowSelectedSingular}` : '',
      }}
    />
  );
};
