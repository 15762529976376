import { ProductDetailsBase } from '../state/productDetailsStateAtom';

export const mockedProductDetails: ProductDetailsBase = {
  openPrice: 10780,
  low: 10790,
  high: 10799,
  averagePrice: 10795,
  lastPrice: 10798,
  previousClose: 10780,
  dailyChange: 5.44,
  dailyChangePercent: 10.5,
  YTDChangePercent: 3.4,
  annualLow: 10700,
  annualHigh: 10900,
  volumeQuantity: 1123,
  volumeValue: 3400000,
  monthlyAverageVolume: 23454,
  marketCapitalization: 10780,
  earningsPerShareTTM: 10780,
  priceToBookValue: 10780,
  priceToEarnings: 10780,
  priceToEarningsTTM: 10780,
  priceToSalesTTM: 10780,
  dividend: 10780,
  dividendYield: 10780,
  dividendYield5Years: 10780,
  EBITDA: 10780,
  ROE: 10780,
  ROA: 10780,
};
