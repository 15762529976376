import DeleteIcon from '@mui/icons-material/Delete';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Popover } from '@mui/material';
import { MouseEvent, useCallback, useEffect, useState } from 'react';
import { useDialog } from '../../../context/DialogContext';
import { useLocale } from '../../../context/NbLocalizationContext';
import { DraggableList } from '../../../shared/components/draggable-list/DraggableList';
import { DraggableSwitchItem } from '../../../shared/components/draggable-list/DraggableSwitchItem';
import { NbIconButton } from '../../../shared/components/icon-button/IconButton';
import { NbLoader } from '../../../shared/components/loader/Loader';
import { useOpenPositions } from '../../../shared/services/useOpenPositions';
import { WidgetElement } from '../../../shared/types/widgetElement';
import { allColumns, OpenPositionsColumn } from '../services/useOpenPositionsColumns';
import { OpenPositionItemGroup } from '../states/openPositionsAtom';
import { NoteModal } from './NoteModal';
import { OpenPositionsDeleteAllModal } from './OpenPositionsDeleteAllModal';
import { OpenPositionsGrid } from './OpenPositionsGrid';
import classes from './OpenPositionsWidget.module.scss';
import { OrderModal } from './OrderModal';

type OpenPositionsColumnWithId = OpenPositionsColumn & {
  id: string;
  selected?: boolean;
  name: string;
};

export const OpenPositionsWidget = (_: WidgetElement) => {
  const { translation } = useLocale();
  const { openDialog } = useDialog();
  const [isLoading, setIsLoading] = useState(false);
  const { loadOpenPositions, openPositions } = useOpenPositions();
  const [settingsPopover, setSettingsPopover] = useState<HTMLButtonElement | null>(null);
  const [openPositionsColumns, setOpenPositionsColumns] = useState<Array<OpenPositionsColumnWithId>>(
    allColumns.map(column => {
      return {
        id: column.field,
        field: column.field,
        name: (translation.openPositions.grid as any)[column.field] as string,
        selected: true,
      };
    }),
  );

  const toggleColumnVisibility = (column: OpenPositionsColumnWithId) => {
    const newColumnList = openPositionsColumns.map(col =>
      col.field !== column.field
        ? col
        : {
            ...col,
            selected: !col.selected,
          },
    );
    saveOpenPositionsColumns(newColumnList);
  };

  const saveOpenPositionsColumns = async (newColumns: Array<OpenPositionsColumnWithId>) => {
    setOpenPositionsColumns(newColumns);
    // onColumnsUpdate(
    //   newColumns.filter(column => column.selected).map(column => ({ field: column.field } as OpenPositionsColumn)),
    // );
  };

  const handleSettingsClick = (event: MouseEvent<HTMLButtonElement>) => {
    setSettingsPopover(event.currentTarget);
  };

  const handleSettingsClose = () => {
    setSettingsPopover(null);
  };

  const addNote = useCallback(
    async (_event: MouseEvent<HTMLButtonElement>, _row: OpenPositionItemGroup) => {
      await openDialog<any>((close: (params?: any) => void) => <NoteModal onCancel={() => close()} />);
    },
    [openDialog],
  );

  const changePosition = useCallback(async () => {
    await openDialog<any>((close: (params?: any) => void) => <OrderModal onCancel={() => close()} />);
  }, [openDialog]);

  const onDeleteAll = async () => {
    await openDialog<any>((close: (params?: any) => void) => <OpenPositionsDeleteAllModal onCancel={() => close()} />);
  };

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        await loadOpenPositions();
      } catch (err) {
      } finally {
        setIsLoading(false);
      }
    })();
  }, [loadOpenPositions]);

  return (
    <div className={classes.nbOpenPositions}>
      <div className={classes.header}>
        <NbIconButton
          icon={<MoreVertIcon />}
          onClick={handleSettingsClick}
          className={classes.headerMore}
        />

        <Popover
          open={!!settingsPopover}
          anchorEl={settingsPopover}
          onClose={handleSettingsClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          classes={{ paper: classes.popover }}
        >
          <div className={classes.popoverButton}>
            <p>{translation.openPositions.closeAll}</p>
            <NbIconButton
              icon={<DeleteIcon />}
              onClick={onDeleteAll}
            />
          </div>
          <span className={classes.popoverTitle}>{translation.openPositions.setColumns}</span>
          <DraggableList
            list={openPositionsColumns}
            onListReordered={saveOpenPositionsColumns}
            renderItem={(column: OpenPositionsColumnWithId) => (
              <DraggableSwitchItem
                label={column.name}
                selected={!!column.selected}
                toggleColumnVisibility={() => toggleColumnVisibility(column)}
                disabled={column.field === 'instrument'}
              />
            )}
          />
        </Popover>
      </div>

      {isLoading && <NbLoader />}
      {!isLoading && (
        <OpenPositionsGrid
          openPositions={openPositions}
          onAddNote={addNote}
          onChangePosition={changePosition}
        />
      )}
    </div>
  );
};
