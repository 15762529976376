import { createContext, ReactNode, useContext, useState } from 'react';
import { hu_HU } from '../shared/data/translation';

export type Locale = 'en_US' | 'hu_HU';

export type Translation = typeof hu_HU;

export type LocalizationContextProps =
  | undefined
  | {
      language: Locale;
      setLocale: (language: Locale) => void;
      translation: Translation;
      translateWithParams: (key: string, params: Record<string, string>) => string;
    };

export const NbLocaleContext = createContext<LocalizationContextProps>(undefined);

export const NbLocalizationProvider = ({ children }: { children: ReactNode }) => {
  const [locale, setLocale] = useState<Locale>('hu_HU');
  const [translation, setTranslation] = useState(hu_HU);

  const updateLanguage = (value: Locale) => {
    if (value === 'hu_HU') {
      setTranslation(hu_HU);
    } else {
      throw new Error(`Missing localization for locale ${value}`);
    }
    setLocale(value);
  };

  const translateWithParams = (value: string, params: Record<string, string>) => {
    return Object.keys(params).reduce((txt, paramKey) => {
      return txt.replaceAll(`$${paramKey}$`, params[paramKey]);
    }, value);
  };

  return (
    <NbLocaleContext.Provider
      value={{
        language: locale,
        setLocale: updateLanguage,
        translation,
        translateWithParams,
      }}
    >
      {children}
    </NbLocaleContext.Provider>
  );
};

export const useLocale = () => {
  const context = useContext(NbLocaleContext);
  if (context === undefined) {
    throw new Error(`useLocale must be used within a NbLocalizationProvider`);
  }
  return context;
};
