import { atom } from 'recoil';
import { mockedIndexRowInstruments } from '../mock/mockedStock';
import { ListedInstrument } from '../types/listedInstrument';

export type IndexRowInstrument = {
  instrument: ListedInstrument;
  closePrice: number;
  values: {
    value: number;
  }[];
};

export const indexRowInstrumentAtom = atom<Array<IndexRowInstrument>>({
  key: 'indexRow/instruments',
  default: mockedIndexRowInstruments,
});
