/* tslint:disable */
/* eslint-disable */
/**
 * NetBroker instrumentum lekérdező API
 * NetBroker instrumentum lekérdező szolgáltatások.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: zsolt.szabo@ersteinvestment.hu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { AxiosInstance, AxiosPromise, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
import type { Configuration } from './configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import type { RequestArgs } from './base';
import { assertParamExists, createRequestFunction, DUMMY_BASE_URL, setSearchParams, toPathString } from './common';
// @ts-ignore
import { BaseAPI, BASE_PATH, COLLECTION_FORMATS } from './base';

/**
 * Instrumentum objektum.
 * @export
 * @interface Instrument
 */
export interface Instrument {
  /**
   * Instrumentum neve.
   * @type {string}
   * @memberof Instrument
   */
  instrumentName: string;
  /**
   * Instrumentum ISIN kódja.
   * @type {string}
   * @memberof Instrument
   */
  isin: string;
  /**
   * Instrumentum típusa neve.
   * @type {string}
   * @memberof Instrument
   */
  instumentCategory: InstrumentInstumentCategoryEnum;
}

export const InstrumentInstumentCategoryEnum = {
  InvestmentUnit: 'investmentUnit',
  CompensationNote: 'compensationNote',
  Share: 'share',
  Certificate: 'certificate',
  Option: 'option',
  Currency: 'currency',
  Future: 'future',
} as const;

export type InstrumentInstumentCategoryEnum =
  (typeof InstrumentInstumentCategoryEnum)[keyof typeof InstrumentInstumentCategoryEnum];

/**
 * Az listázott instrumentum Erste (ill, szabályozó) specifikus kiegészítő adatokkal bővített leírója.
 * @export
 * @interface InstrumentDetails
 */
export interface InstrumentDetails {
  /**
   * Shortolható-e az instrumentum?
   * @type {boolean}
   * @memberof InstrumentDetails
   */
  canBeSorted?: boolean;
  /**
   * NYESZ-re vehető-e az instrumentum?
   * @type {boolean}
   * @memberof InstrumentDetails
   */
  allowToNYESZ?: boolean;
  /**
   * Az instrumentum fedezeti értéke (0-1) között százalékosan.
   * @type {number}
   * @memberof InstrumentDetails
   */
  collateralValue?: number;
  /**
   * Instrumentum neve.
   * @type {string}
   * @memberof InstrumentDetails
   */
  instrumentName: string;
  /**
   * Instrumentum ISIN kódja.
   * @type {string}
   * @memberof InstrumentDetails
   */
  isin: string;
  /**
   * Instrumentum típusa neve.
   * @type {string}
   * @memberof InstrumentDetails
   */
  instumentCategory: InstrumentDetailsInstumentCategoryEnum;
  /**
   * Piac MIC kódja.
   * @type {string}
   * @memberof InstrumentDetails
   */
  mic: string;
  /**
   * Piac neve.
   * @type {string}
   * @memberof InstrumentDetails
   */
  marketName: string;
  /**
   * Piac rövid neve.
   * @type {string}
   * @memberof InstrumentDetails
   */
  marketShortName: string;
  /**
   * Piac régió kód (ISO 3166 szerint).
   * @type {any}
   * @memberof InstrumentDetails
   */
  reutersRegionCode: any;
}

export const InstrumentDetailsInstumentCategoryEnum = {
  InvestmentUnit: 'investmentUnit',
  CompensationNote: 'compensationNote',
  Share: 'share',
  Certificate: 'certificate',
  Option: 'option',
  Currency: 'currency',
  Future: 'future',
} as const;

export type InstrumentDetailsInstumentCategoryEnum =
  (typeof InstrumentDetailsInstumentCategoryEnum)[keyof typeof InstrumentDetailsInstumentCategoryEnum];

/**
 * Listázott instrumentum, azaz egy Instrumentum piacfüggő adatokkal bővített leírója.
 * @export
 * @interface ListedInstrument
 */
export interface ListedInstrument {
  /**
   * Symbol of the listed instrument on the given exchange
   * @type {string}
   * @memberof ListedInstrument
   */
  ticker: string;
  /**
   * RIC (Refinitiv) code of the listed instrument
   * @type {string}
   * @memberof ListedInstrument
   */
  ric: string;
  /**
   * ISO 4217 code of the currency
   * @type {string}
   * @memberof ListedInstrument
   */
  currency: string;
  /**
   * Instrumentum neve.
   * @type {string}
   * @memberof ListedInstrument
   */
  instrumentName: string;
  /**
   * Instrumentum ISIN kódja.
   * @type {string}
   * @memberof ListedInstrument
   */
  isin: string;
  /**
   * Instrumentum típusa neve.
   * @type {string}
   * @memberof ListedInstrument
   */
  instumentCategory: ListedInstrumentInstumentCategoryEnum;
  /**
   * Piac MIC kódja.
   * @type {string}
   * @memberof ListedInstrument
   */
  mic: string;
  /**
   * Piac neve.
   * @type {string}
   * @memberof ListedInstrument
   */
  marketName: string;
  /**
   * Piac rövid neve.
   * @type {string}
   * @memberof ListedInstrument
   */
  marketShortName: string;
  /**
   * Piac régió kód (ISO 3166 szerint).
   * @type {any}
   * @memberof ListedInstrument
   */
  reutersRegionCode: any;
}

export const ListedInstrumentInstumentCategoryEnum = {
  InvestmentUnit: 'investmentUnit',
  CompensationNote: 'compensationNote',
  Share: 'share',
  Certificate: 'certificate',
  Option: 'option',
  Currency: 'currency',
  Future: 'future',
} as const;

export type ListedInstrumentInstumentCategoryEnum =
  (typeof ListedInstrumentInstumentCategoryEnum)[keyof typeof ListedInstrumentInstumentCategoryEnum];

/**
 * Piacot leíró objektum.
 * @export
 * @interface Market
 */
export interface Market {
  /**
   * Piac MIC kódja.
   * @type {string}
   * @memberof Market
   */
  mic: string;
  /**
   * Piac neve.
   * @type {string}
   * @memberof Market
   */
  marketName: string;
  /**
   * Piac rövid neve.
   * @type {string}
   * @memberof Market
   */
  marketShortName: string;
  /**
   * Piac régió kód (ISO 3166 szerint).
   * @type {any}
   * @memberof Market
   */
  reutersRegionCode: any;
}
/**
 * Árlépésköz leíró adatai.
 * @export
 * @interface TickSize
 */
export interface TickSize {
  /**
   * Alsó limit ár.
   * @type {number}
   * @memberof TickSize
   */
  lowerLimitPrice: number;
  /**
   * Felső limit ár.
   * @type {any}
   * @memberof TickSize
   */
  upperLimitPrice: any;
  /**
   * Árlépésköz.
   * @type {any}
   * @memberof TickSize
   */
  tickSizeValue: any;
}

/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary A megadott listán szereplő RIC lista alapján visszaadja az instrumentum részletes adatait.
     * @param {string} id All requests should include a unique identifier element. The identifier is used for auditing and control of double-submission for update operations. The Id must be prefixed by the application name (or module, if necessary) to be unique in the whole environment. The application name and the Id must be separated with colon (:). Synchronous response to a request must use the same id in the HTTP Header.
     * @param {string} correlid Flow (or session) identifier. The flow initiator generates the ID; any other party should copy the received ID into all subsequent messages/calls inside the flow. IDs should be unique for each flow.
     * @param {string} source This identifier is unique for the different modules of the source systemapplications. The name must be defined as an application module name in VEKER naming style. This field is used for logging and in the gatekeeper configuration too.
     * @param {string} srcModule This identifier is unique for the different consumer systemapplications. The name has to be defined in VEKER naming style as an application name. This field is used for logging, gathering information on service usage and in the gatekeeper configuration too.
     * @param {string} userid The identity ID describes the identity of the user that has initiated the service request. This may be, for example, the customer, or an internal user, or may identify a special systemapplication user for calls made by automated systemapplications. If the identification of the original user is not possible (e.g. batch jobs) or the original user is not part of the global user directory (e.g. a customer, who is using a frontend systemapplication), then a technical user name should be used. This is different from the application ID. The Identity ID is of the end user of the systemapplication while the application ID is the calling systemapplication.
     * @param {Array<string>} [rics] RIC kód lista
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    batchInstrumentDetailsQuery: async (
      id: string,
      correlid: string,
      source: string,
      srcModule: string,
      userid: string,
      rics?: Array<string>,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('batchInstrumentDetailsQuery', 'id', id);
      // verify required parameter 'correlid' is not null or undefined
      assertParamExists('batchInstrumentDetailsQuery', 'correlid', correlid);
      // verify required parameter 'source' is not null or undefined
      assertParamExists('batchInstrumentDetailsQuery', 'source', source);
      // verify required parameter 'srcModule' is not null or undefined
      assertParamExists('batchInstrumentDetailsQuery', 'srcModule', srcModule);
      // verify required parameter 'userid' is not null or undefined
      assertParamExists('batchInstrumentDetailsQuery', 'userid', userid);
      const localVarPath = `/instruments/batch-details-query`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (rics) {
        localVarQueryParameter['rics'] = rics.join(COLLECTION_FORMATS.csv);
      }

      if (id != null) {
        localVarHeaderParameter['id'] = String(id);
      }

      if (correlid != null) {
        localVarHeaderParameter['correlid'] = String(correlid);
      }

      if (source != null) {
        localVarHeaderParameter['source'] = String(source);
      }

      if (srcModule != null) {
        localVarHeaderParameter['src-module'] = String(srcModule);
      }

      if (userid != null) {
        localVarHeaderParameter['userid'] = String(userid);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary A megadott string paraméter alapján az instrumentum teljes név, ISIN mezőiben keres.
     * @param {string} id All requests should include a unique identifier element. The identifier is used for auditing and control of double-submission for update operations. The Id must be prefixed by the application name (or module, if necessary) to be unique in the whole environment. The application name and the Id must be separated with colon (:). Synchronous response to a request must use the same id in the HTTP Header.
     * @param {string} correlid Flow (or session) identifier. The flow initiator generates the ID; any other party should copy the received ID into all subsequent messages/calls inside the flow. IDs should be unique for each flow.
     * @param {string} source This identifier is unique for the different modules of the source systemapplications. The name must be defined as an application module name in VEKER naming style. This field is used for logging and in the gatekeeper configuration too.
     * @param {string} srcModule This identifier is unique for the different consumer systemapplications. The name has to be defined in VEKER naming style as an application name. This field is used for logging, gathering information on service usage and in the gatekeeper configuration too.
     * @param {string} userid The identity ID describes the identity of the user that has initiated the service request. This may be, for example, the customer, or an internal user, or may identify a special systemapplication user for calls made by automated systemapplications. If the identification of the original user is not possible (e.g. batch jobs) or the original user is not part of the global user directory (e.g. a customer, who is using a frontend systemapplication), then a technical user name should be used. This is different from the application ID. The Identity ID is of the end user of the systemapplication while the application ID is the calling systemapplication.
     * @param {Array<string>} isins ISIN kód lista
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    batchInstrumentQuery: async (
      id: string,
      correlid: string,
      source: string,
      srcModule: string,
      userid: string,
      isins: Array<string>,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('batchInstrumentQuery', 'id', id);
      // verify required parameter 'correlid' is not null or undefined
      assertParamExists('batchInstrumentQuery', 'correlid', correlid);
      // verify required parameter 'source' is not null or undefined
      assertParamExists('batchInstrumentQuery', 'source', source);
      // verify required parameter 'srcModule' is not null or undefined
      assertParamExists('batchInstrumentQuery', 'srcModule', srcModule);
      // verify required parameter 'userid' is not null or undefined
      assertParamExists('batchInstrumentQuery', 'userid', userid);
      // verify required parameter 'isins' is not null or undefined
      assertParamExists('batchInstrumentQuery', 'isins', isins);
      const localVarPath = `/instruments/batch-query`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (isins) {
        localVarQueryParameter['isins'] = isins.join(COLLECTION_FORMATS.csv);
      }

      if (id != null) {
        localVarHeaderParameter['id'] = String(id);
      }

      if (correlid != null) {
        localVarHeaderParameter['correlid'] = String(correlid);
      }

      if (source != null) {
        localVarHeaderParameter['source'] = String(source);
      }

      if (srcModule != null) {
        localVarHeaderParameter['src-module'] = String(srcModule);
      }

      if (userid != null) {
        localVarHeaderParameter['userid'] = String(userid);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Instrumentum részletes adatainak lekérdezése, az URL-ben kapott RIC kód alapján InstrumentDetail entitást ad vissza.
     * @param {string} id All requests should include a unique identifier element. The identifier is used for auditing and control of double-submission for update operations. The Id must be prefixed by the application name (or module, if necessary) to be unique in the whole environment. The application name and the Id must be separated with colon (:). Synchronous response to a request must use the same id in the HTTP Header.
     * @param {string} correlid Flow (or session) identifier. The flow initiator generates the ID; any other party should copy the received ID into all subsequent messages/calls inside the flow. IDs should be unique for each flow.
     * @param {string} source This identifier is unique for the different modules of the source systemapplications. The name must be defined as an application module name in VEKER naming style. This field is used for logging and in the gatekeeper configuration too.
     * @param {string} srcModule This identifier is unique for the different consumer systemapplications. The name has to be defined in VEKER naming style as an application name. This field is used for logging, gathering information on service usage and in the gatekeeper configuration too.
     * @param {string} userid The identity ID describes the identity of the user that has initiated the service request. This may be, for example, the customer, or an internal user, or may identify a special systemapplication user for calls made by automated systemapplications. If the identification of the original user is not possible (e.g. batch jobs) or the original user is not part of the global user directory (e.g. a customer, who is using a frontend systemapplication), then a technical user name should be used. This is different from the application ID. The Identity ID is of the end user of the systemapplication while the application ID is the calling systemapplication.
     * @param {string} ric IInstrumentum RIC kódja.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getInstrumentDetails: async (
      id: string,
      correlid: string,
      source: string,
      srcModule: string,
      userid: string,
      ric: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('getInstrumentDetails', 'id', id);
      // verify required parameter 'correlid' is not null or undefined
      assertParamExists('getInstrumentDetails', 'correlid', correlid);
      // verify required parameter 'source' is not null or undefined
      assertParamExists('getInstrumentDetails', 'source', source);
      // verify required parameter 'srcModule' is not null or undefined
      assertParamExists('getInstrumentDetails', 'srcModule', srcModule);
      // verify required parameter 'userid' is not null or undefined
      assertParamExists('getInstrumentDetails', 'userid', userid);
      // verify required parameter 'ric' is not null or undefined
      assertParamExists('getInstrumentDetails', 'ric', ric);
      const localVarPath = `/instruments/{ric}/details`.replace(`{${'ric'}}`, encodeURIComponent(String(ric)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (id != null) {
        localVarHeaderParameter['id'] = String(id);
      }

      if (correlid != null) {
        localVarHeaderParameter['correlid'] = String(correlid);
      }

      if (source != null) {
        localVarHeaderParameter['source'] = String(source);
      }

      if (srcModule != null) {
        localVarHeaderParameter['src-module'] = String(srcModule);
      }

      if (userid != null) {
        localVarHeaderParameter['userid'] = String(userid);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Árlépésköz adatok lekérdezése, az URL-ben kapott RIC kód alapján TickSize listát ad vissza.
     * @param {string} id All requests should include a unique identifier element. The identifier is used for auditing and control of double-submission for update operations. The Id must be prefixed by the application name (or module, if necessary) to be unique in the whole environment. The application name and the Id must be separated with colon (:). Synchronous response to a request must use the same id in the HTTP Header.
     * @param {string} correlid Flow (or session) identifier. The flow initiator generates the ID; any other party should copy the received ID into all subsequent messages/calls inside the flow. IDs should be unique for each flow.
     * @param {string} source This identifier is unique for the different modules of the source systemapplications. The name must be defined as an application module name in VEKER naming style. This field is used for logging and in the gatekeeper configuration too.
     * @param {string} srcModule This identifier is unique for the different consumer systemapplications. The name has to be defined in VEKER naming style as an application name. This field is used for logging, gathering information on service usage and in the gatekeeper configuration too.
     * @param {string} userid The identity ID describes the identity of the user that has initiated the service request. This may be, for example, the customer, or an internal user, or may identify a special systemapplication user for calls made by automated systemapplications. If the identification of the original user is not possible (e.g. batch jobs) or the original user is not part of the global user directory (e.g. a customer, who is using a frontend systemapplication), then a technical user name should be used. This is different from the application ID. The Identity ID is of the end user of the systemapplication while the application ID is the calling systemapplication.
     * @param {string} ric IInstrumentum RIC kódja.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getInstrumentTickSize: async (
      id: string,
      correlid: string,
      source: string,
      srcModule: string,
      userid: string,
      ric: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('getInstrumentTickSize', 'id', id);
      // verify required parameter 'correlid' is not null or undefined
      assertParamExists('getInstrumentTickSize', 'correlid', correlid);
      // verify required parameter 'source' is not null or undefined
      assertParamExists('getInstrumentTickSize', 'source', source);
      // verify required parameter 'srcModule' is not null or undefined
      assertParamExists('getInstrumentTickSize', 'srcModule', srcModule);
      // verify required parameter 'userid' is not null or undefined
      assertParamExists('getInstrumentTickSize', 'userid', userid);
      // verify required parameter 'ric' is not null or undefined
      assertParamExists('getInstrumentTickSize', 'ric', ric);
      const localVarPath = `/instruments/{ric}/ticksize`.replace(`{${'ric'}}`, encodeURIComponent(String(ric)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (id != null) {
        localVarHeaderParameter['id'] = String(id);
      }

      if (correlid != null) {
        localVarHeaderParameter['correlid'] = String(correlid);
      }

      if (source != null) {
        localVarHeaderParameter['source'] = String(source);
      }

      if (srcModule != null) {
        localVarHeaderParameter['src-module'] = String(srcModule);
      }

      if (userid != null) {
        localVarHeaderParameter['userid'] = String(userid);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Instrumentum adatainak lekérdezése, az URL-ben kapott ISIN kód alapján egy Instrument entitást ad vissza.
     * @param {string} id All requests should include a unique identifier element. The identifier is used for auditing and control of double-submission for update operations. The Id must be prefixed by the application name (or module, if necessary) to be unique in the whole environment. The application name and the Id must be separated with colon (:). Synchronous response to a request must use the same id in the HTTP Header.
     * @param {string} correlid Flow (or session) identifier. The flow initiator generates the ID; any other party should copy the received ID into all subsequent messages/calls inside the flow. IDs should be unique for each flow.
     * @param {string} source This identifier is unique for the different modules of the source systemapplications. The name must be defined as an application module name in VEKER naming style. This field is used for logging and in the gatekeeper configuration too.
     * @param {string} srcModule This identifier is unique for the different consumer systemapplications. The name has to be defined in VEKER naming style as an application name. This field is used for logging, gathering information on service usage and in the gatekeeper configuration too.
     * @param {string} userid The identity ID describes the identity of the user that has initiated the service request. This may be, for example, the customer, or an internal user, or may identify a special systemapplication user for calls made by automated systemapplications. If the identification of the original user is not possible (e.g. batch jobs) or the original user is not part of the global user directory (e.g. a customer, who is using a frontend systemapplication), then a technical user name should be used. This is different from the application ID. The Identity ID is of the end user of the systemapplication while the application ID is the calling systemapplication.
     * @param {string} isin Instrumentum ISIN kódja.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getInstrumentumByISIN: async (
      id: string,
      correlid: string,
      source: string,
      srcModule: string,
      userid: string,
      isin: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('getInstrumentumByISIN', 'id', id);
      // verify required parameter 'correlid' is not null or undefined
      assertParamExists('getInstrumentumByISIN', 'correlid', correlid);
      // verify required parameter 'source' is not null or undefined
      assertParamExists('getInstrumentumByISIN', 'source', source);
      // verify required parameter 'srcModule' is not null or undefined
      assertParamExists('getInstrumentumByISIN', 'srcModule', srcModule);
      // verify required parameter 'userid' is not null or undefined
      assertParamExists('getInstrumentumByISIN', 'userid', userid);
      // verify required parameter 'isin' is not null or undefined
      assertParamExists('getInstrumentumByISIN', 'isin', isin);
      const localVarPath = `/instruments/{isin}`.replace(`{${'isin'}}`, encodeURIComponent(String(isin)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (id != null) {
        localVarHeaderParameter['id'] = String(id);
      }

      if (correlid != null) {
        localVarHeaderParameter['correlid'] = String(correlid);
      }

      if (source != null) {
        localVarHeaderParameter['source'] = String(source);
      }

      if (srcModule != null) {
        localVarHeaderParameter['src-module'] = String(srcModule);
      }

      if (userid != null) {
        localVarHeaderParameter['userid'] = String(userid);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Instrumentum piacfüggő adatainak lekérdezése, az URL-ben kapott ISIN kód alapján ListedInstrument listát ad vissza.
     * @param {string} id All requests should include a unique identifier element. The identifier is used for auditing and control of double-submission for update operations. The Id must be prefixed by the application name (or module, if necessary) to be unique in the whole environment. The application name and the Id must be separated with colon (:). Synchronous response to a request must use the same id in the HTTP Header.
     * @param {string} correlid Flow (or session) identifier. The flow initiator generates the ID; any other party should copy the received ID into all subsequent messages/calls inside the flow. IDs should be unique for each flow.
     * @param {string} source This identifier is unique for the different modules of the source systemapplications. The name must be defined as an application module name in VEKER naming style. This field is used for logging and in the gatekeeper configuration too.
     * @param {string} srcModule This identifier is unique for the different consumer systemapplications. The name has to be defined in VEKER naming style as an application name. This field is used for logging, gathering information on service usage and in the gatekeeper configuration too.
     * @param {string} userid The identity ID describes the identity of the user that has initiated the service request. This may be, for example, the customer, or an internal user, or may identify a special systemapplication user for calls made by automated systemapplications. If the identification of the original user is not possible (e.g. batch jobs) or the original user is not part of the global user directory (e.g. a customer, who is using a frontend systemapplication), then a technical user name should be used. This is different from the application ID. The Identity ID is of the end user of the systemapplication while the application ID is the calling systemapplication.
     * @param {string} isin Instrumentum ISIN kódja.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    instrumentsIsinListingsGet: async (
      id: string,
      correlid: string,
      source: string,
      srcModule: string,
      userid: string,
      isin: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('instrumentsIsinListingsGet', 'id', id);
      // verify required parameter 'correlid' is not null or undefined
      assertParamExists('instrumentsIsinListingsGet', 'correlid', correlid);
      // verify required parameter 'source' is not null or undefined
      assertParamExists('instrumentsIsinListingsGet', 'source', source);
      // verify required parameter 'srcModule' is not null or undefined
      assertParamExists('instrumentsIsinListingsGet', 'srcModule', srcModule);
      // verify required parameter 'userid' is not null or undefined
      assertParamExists('instrumentsIsinListingsGet', 'userid', userid);
      // verify required parameter 'isin' is not null or undefined
      assertParamExists('instrumentsIsinListingsGet', 'isin', isin);
      const localVarPath = `/instruments/{isin}/listings`.replace(`{${'isin'}}`, encodeURIComponent(String(isin)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (id != null) {
        localVarHeaderParameter['id'] = String(id);
      }

      if (correlid != null) {
        localVarHeaderParameter['correlid'] = String(correlid);
      }

      if (source != null) {
        localVarHeaderParameter['source'] = String(source);
      }

      if (srcModule != null) {
        localVarHeaderParameter['src-module'] = String(srcModule);
      }

      if (userid != null) {
        localVarHeaderParameter['userid'] = String(userid);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary A megadott string paraméter alapján az instrumentum teljes név, ISIN mezőiben keres.
     * @param {string} id All requests should include a unique identifier element. The identifier is used for auditing and control of double-submission for update operations. The Id must be prefixed by the application name (or module, if necessary) to be unique in the whole environment. The application name and the Id must be separated with colon (:). Synchronous response to a request must use the same id in the HTTP Header.
     * @param {string} correlid Flow (or session) identifier. The flow initiator generates the ID; any other party should copy the received ID into all subsequent messages/calls inside the flow. IDs should be unique for each flow.
     * @param {string} source This identifier is unique for the different modules of the source systemapplications. The name must be defined as an application module name in VEKER naming style. This field is used for logging and in the gatekeeper configuration too.
     * @param {string} srcModule This identifier is unique for the different consumer systemapplications. The name has to be defined in VEKER naming style as an application name. This field is used for logging, gathering information on service usage and in the gatekeeper configuration too.
     * @param {string} userid The identity ID describes the identity of the user that has initiated the service request. This may be, for example, the customer, or an internal user, or may identify a special systemapplication user for calls made by automated systemapplications. If the identification of the original user is not possible (e.g. batch jobs) or the original user is not part of the global user directory (e.g. a customer, who is using a frontend systemapplication), then a technical user name should be used. This is different from the application ID. The Identity ID is of the end user of the systemapplication while the application ID is the calling systemapplication.
     * @param {string} q Az instrumentum neve, vagy ISIN kódjának szótöredéke.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchInstrument: async (
      id: string,
      correlid: string,
      source: string,
      srcModule: string,
      userid: string,
      q: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('searchInstrument', 'id', id);
      // verify required parameter 'correlid' is not null or undefined
      assertParamExists('searchInstrument', 'correlid', correlid);
      // verify required parameter 'source' is not null or undefined
      assertParamExists('searchInstrument', 'source', source);
      // verify required parameter 'srcModule' is not null or undefined
      assertParamExists('searchInstrument', 'srcModule', srcModule);
      // verify required parameter 'userid' is not null or undefined
      assertParamExists('searchInstrument', 'userid', userid);
      // verify required parameter 'q' is not null or undefined
      assertParamExists('searchInstrument', 'q', q);
      const localVarPath = `/instruments`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (q !== undefined) {
        localVarQueryParameter['q'] = q;
      }

      if (id != null) {
        localVarHeaderParameter['id'] = String(id);
      }

      if (correlid != null) {
        localVarHeaderParameter['correlid'] = String(correlid);
      }

      if (source != null) {
        localVarHeaderParameter['source'] = String(source);
      }

      if (srcModule != null) {
        localVarHeaderParameter['src-module'] = String(srcModule);
      }

      if (userid != null) {
        localVarHeaderParameter['userid'] = String(userid);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary A megadott string paraméter alapján az instrumentum ticker, teljes név, ISIN mezőiben keres.
     * @param {string} id All requests should include a unique identifier element. The identifier is used for auditing and control of double-submission for update operations. The Id must be prefixed by the application name (or module, if necessary) to be unique in the whole environment. The application name and the Id must be separated with colon (:). Synchronous response to a request must use the same id in the HTTP Header.
     * @param {string} correlid Flow (or session) identifier. The flow initiator generates the ID; any other party should copy the received ID into all subsequent messages/calls inside the flow. IDs should be unique for each flow.
     * @param {string} source This identifier is unique for the different modules of the source systemapplications. The name must be defined as an application module name in VEKER naming style. This field is used for logging and in the gatekeeper configuration too.
     * @param {string} srcModule This identifier is unique for the different consumer systemapplications. The name has to be defined in VEKER naming style as an application name. This field is used for logging, gathering information on service usage and in the gatekeeper configuration too.
     * @param {string} userid The identity ID describes the identity of the user that has initiated the service request. This may be, for example, the customer, or an internal user, or may identify a special systemapplication user for calls made by automated systemapplications. If the identification of the original user is not possible (e.g. batch jobs) or the original user is not part of the global user directory (e.g. a customer, who is using a frontend systemapplication), then a technical user name should be used. This is different from the application ID. The Identity ID is of the end user of the systemapplication while the application ID is the calling systemapplication.
     * @param {string} q A listázott instrumentum neve, vagy ISIN kódjának szótöredéke.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchListedInstrument: async (
      id: string,
      correlid: string,
      source: string,
      srcModule: string,
      userid: string,
      q: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('searchListedInstrument', 'id', id);
      // verify required parameter 'correlid' is not null or undefined
      assertParamExists('searchListedInstrument', 'correlid', correlid);
      // verify required parameter 'source' is not null or undefined
      assertParamExists('searchListedInstrument', 'source', source);
      // verify required parameter 'srcModule' is not null or undefined
      assertParamExists('searchListedInstrument', 'srcModule', srcModule);
      // verify required parameter 'userid' is not null or undefined
      assertParamExists('searchListedInstrument', 'userid', userid);
      // verify required parameter 'q' is not null or undefined
      assertParamExists('searchListedInstrument', 'q', q);
      const localVarPath = `/listedInstruments`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (q !== undefined) {
        localVarQueryParameter['q'] = q;
      }

      if (id != null) {
        localVarHeaderParameter['id'] = String(id);
      }

      if (correlid != null) {
        localVarHeaderParameter['correlid'] = String(correlid);
      }

      if (source != null) {
        localVarHeaderParameter['source'] = String(source);
      }

      if (srcModule != null) {
        localVarHeaderParameter['src-module'] = String(srcModule);
      }

      if (userid != null) {
        localVarHeaderParameter['userid'] = String(userid);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary A megadott listán szereplő RIC lista alapján visszaadja az instrumentum részletes adatait.
     * @param {string} id All requests should include a unique identifier element. The identifier is used for auditing and control of double-submission for update operations. The Id must be prefixed by the application name (or module, if necessary) to be unique in the whole environment. The application name and the Id must be separated with colon (:). Synchronous response to a request must use the same id in the HTTP Header.
     * @param {string} correlid Flow (or session) identifier. The flow initiator generates the ID; any other party should copy the received ID into all subsequent messages/calls inside the flow. IDs should be unique for each flow.
     * @param {string} source This identifier is unique for the different modules of the source systemapplications. The name must be defined as an application module name in VEKER naming style. This field is used for logging and in the gatekeeper configuration too.
     * @param {string} srcModule This identifier is unique for the different consumer systemapplications. The name has to be defined in VEKER naming style as an application name. This field is used for logging, gathering information on service usage and in the gatekeeper configuration too.
     * @param {string} userid The identity ID describes the identity of the user that has initiated the service request. This may be, for example, the customer, or an internal user, or may identify a special systemapplication user for calls made by automated systemapplications. If the identification of the original user is not possible (e.g. batch jobs) or the original user is not part of the global user directory (e.g. a customer, who is using a frontend systemapplication), then a technical user name should be used. This is different from the application ID. The Identity ID is of the end user of the systemapplication while the application ID is the calling systemapplication.
     * @param {Array<string>} [rics] RIC kód lista
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async batchInstrumentDetailsQuery(
      id: string,
      correlid: string,
      source: string,
      srcModule: string,
      userid: string,
      rics?: Array<string>,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<InstrumentDetails>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.batchInstrumentDetailsQuery(
        id,
        correlid,
        source,
        srcModule,
        userid,
        rics,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary A megadott string paraméter alapján az instrumentum teljes név, ISIN mezőiben keres.
     * @param {string} id All requests should include a unique identifier element. The identifier is used for auditing and control of double-submission for update operations. The Id must be prefixed by the application name (or module, if necessary) to be unique in the whole environment. The application name and the Id must be separated with colon (:). Synchronous response to a request must use the same id in the HTTP Header.
     * @param {string} correlid Flow (or session) identifier. The flow initiator generates the ID; any other party should copy the received ID into all subsequent messages/calls inside the flow. IDs should be unique for each flow.
     * @param {string} source This identifier is unique for the different modules of the source systemapplications. The name must be defined as an application module name in VEKER naming style. This field is used for logging and in the gatekeeper configuration too.
     * @param {string} srcModule This identifier is unique for the different consumer systemapplications. The name has to be defined in VEKER naming style as an application name. This field is used for logging, gathering information on service usage and in the gatekeeper configuration too.
     * @param {string} userid The identity ID describes the identity of the user that has initiated the service request. This may be, for example, the customer, or an internal user, or may identify a special systemapplication user for calls made by automated systemapplications. If the identification of the original user is not possible (e.g. batch jobs) or the original user is not part of the global user directory (e.g. a customer, who is using a frontend systemapplication), then a technical user name should be used. This is different from the application ID. The Identity ID is of the end user of the systemapplication while the application ID is the calling systemapplication.
     * @param {Array<string>} isins ISIN kód lista
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async batchInstrumentQuery(
      id: string,
      correlid: string,
      source: string,
      srcModule: string,
      userid: string,
      isins: Array<string>,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ListedInstrument>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.batchInstrumentQuery(
        id,
        correlid,
        source,
        srcModule,
        userid,
        isins,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Instrumentum részletes adatainak lekérdezése, az URL-ben kapott RIC kód alapján InstrumentDetail entitást ad vissza.
     * @param {string} id All requests should include a unique identifier element. The identifier is used for auditing and control of double-submission for update operations. The Id must be prefixed by the application name (or module, if necessary) to be unique in the whole environment. The application name and the Id must be separated with colon (:). Synchronous response to a request must use the same id in the HTTP Header.
     * @param {string} correlid Flow (or session) identifier. The flow initiator generates the ID; any other party should copy the received ID into all subsequent messages/calls inside the flow. IDs should be unique for each flow.
     * @param {string} source This identifier is unique for the different modules of the source systemapplications. The name must be defined as an application module name in VEKER naming style. This field is used for logging and in the gatekeeper configuration too.
     * @param {string} srcModule This identifier is unique for the different consumer systemapplications. The name has to be defined in VEKER naming style as an application name. This field is used for logging, gathering information on service usage and in the gatekeeper configuration too.
     * @param {string} userid The identity ID describes the identity of the user that has initiated the service request. This may be, for example, the customer, or an internal user, or may identify a special systemapplication user for calls made by automated systemapplications. If the identification of the original user is not possible (e.g. batch jobs) or the original user is not part of the global user directory (e.g. a customer, who is using a frontend systemapplication), then a technical user name should be used. This is different from the application ID. The Identity ID is of the end user of the systemapplication while the application ID is the calling systemapplication.
     * @param {string} ric IInstrumentum RIC kódja.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getInstrumentDetails(
      id: string,
      correlid: string,
      source: string,
      srcModule: string,
      userid: string,
      ric: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InstrumentDetails>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getInstrumentDetails(
        id,
        correlid,
        source,
        srcModule,
        userid,
        ric,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Árlépésköz adatok lekérdezése, az URL-ben kapott RIC kód alapján TickSize listát ad vissza.
     * @param {string} id All requests should include a unique identifier element. The identifier is used for auditing and control of double-submission for update operations. The Id must be prefixed by the application name (or module, if necessary) to be unique in the whole environment. The application name and the Id must be separated with colon (:). Synchronous response to a request must use the same id in the HTTP Header.
     * @param {string} correlid Flow (or session) identifier. The flow initiator generates the ID; any other party should copy the received ID into all subsequent messages/calls inside the flow. IDs should be unique for each flow.
     * @param {string} source This identifier is unique for the different modules of the source systemapplications. The name must be defined as an application module name in VEKER naming style. This field is used for logging and in the gatekeeper configuration too.
     * @param {string} srcModule This identifier is unique for the different consumer systemapplications. The name has to be defined in VEKER naming style as an application name. This field is used for logging, gathering information on service usage and in the gatekeeper configuration too.
     * @param {string} userid The identity ID describes the identity of the user that has initiated the service request. This may be, for example, the customer, or an internal user, or may identify a special systemapplication user for calls made by automated systemapplications. If the identification of the original user is not possible (e.g. batch jobs) or the original user is not part of the global user directory (e.g. a customer, who is using a frontend systemapplication), then a technical user name should be used. This is different from the application ID. The Identity ID is of the end user of the systemapplication while the application ID is the calling systemapplication.
     * @param {string} ric IInstrumentum RIC kódja.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getInstrumentTickSize(
      id: string,
      correlid: string,
      source: string,
      srcModule: string,
      userid: string,
      ric: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TickSize>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getInstrumentTickSize(
        id,
        correlid,
        source,
        srcModule,
        userid,
        ric,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Instrumentum adatainak lekérdezése, az URL-ben kapott ISIN kód alapján egy Instrument entitást ad vissza.
     * @param {string} id All requests should include a unique identifier element. The identifier is used for auditing and control of double-submission for update operations. The Id must be prefixed by the application name (or module, if necessary) to be unique in the whole environment. The application name and the Id must be separated with colon (:). Synchronous response to a request must use the same id in the HTTP Header.
     * @param {string} correlid Flow (or session) identifier. The flow initiator generates the ID; any other party should copy the received ID into all subsequent messages/calls inside the flow. IDs should be unique for each flow.
     * @param {string} source This identifier is unique for the different modules of the source systemapplications. The name must be defined as an application module name in VEKER naming style. This field is used for logging and in the gatekeeper configuration too.
     * @param {string} srcModule This identifier is unique for the different consumer systemapplications. The name has to be defined in VEKER naming style as an application name. This field is used for logging, gathering information on service usage and in the gatekeeper configuration too.
     * @param {string} userid The identity ID describes the identity of the user that has initiated the service request. This may be, for example, the customer, or an internal user, or may identify a special systemapplication user for calls made by automated systemapplications. If the identification of the original user is not possible (e.g. batch jobs) or the original user is not part of the global user directory (e.g. a customer, who is using a frontend systemapplication), then a technical user name should be used. This is different from the application ID. The Identity ID is of the end user of the systemapplication while the application ID is the calling systemapplication.
     * @param {string} isin Instrumentum ISIN kódja.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getInstrumentumByISIN(
      id: string,
      correlid: string,
      source: string,
      srcModule: string,
      userid: string,
      isin: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Instrument>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getInstrumentumByISIN(
        id,
        correlid,
        source,
        srcModule,
        userid,
        isin,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Instrumentum piacfüggő adatainak lekérdezése, az URL-ben kapott ISIN kód alapján ListedInstrument listát ad vissza.
     * @param {string} id All requests should include a unique identifier element. The identifier is used for auditing and control of double-submission for update operations. The Id must be prefixed by the application name (or module, if necessary) to be unique in the whole environment. The application name and the Id must be separated with colon (:). Synchronous response to a request must use the same id in the HTTP Header.
     * @param {string} correlid Flow (or session) identifier. The flow initiator generates the ID; any other party should copy the received ID into all subsequent messages/calls inside the flow. IDs should be unique for each flow.
     * @param {string} source This identifier is unique for the different modules of the source systemapplications. The name must be defined as an application module name in VEKER naming style. This field is used for logging and in the gatekeeper configuration too.
     * @param {string} srcModule This identifier is unique for the different consumer systemapplications. The name has to be defined in VEKER naming style as an application name. This field is used for logging, gathering information on service usage and in the gatekeeper configuration too.
     * @param {string} userid The identity ID describes the identity of the user that has initiated the service request. This may be, for example, the customer, or an internal user, or may identify a special systemapplication user for calls made by automated systemapplications. If the identification of the original user is not possible (e.g. batch jobs) or the original user is not part of the global user directory (e.g. a customer, who is using a frontend systemapplication), then a technical user name should be used. This is different from the application ID. The Identity ID is of the end user of the systemapplication while the application ID is the calling systemapplication.
     * @param {string} isin Instrumentum ISIN kódja.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async instrumentsIsinListingsGet(
      id: string,
      correlid: string,
      source: string,
      srcModule: string,
      userid: string,
      isin: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ListedInstrument>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.instrumentsIsinListingsGet(
        id,
        correlid,
        source,
        srcModule,
        userid,
        isin,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary A megadott string paraméter alapján az instrumentum teljes név, ISIN mezőiben keres.
     * @param {string} id All requests should include a unique identifier element. The identifier is used for auditing and control of double-submission for update operations. The Id must be prefixed by the application name (or module, if necessary) to be unique in the whole environment. The application name and the Id must be separated with colon (:). Synchronous response to a request must use the same id in the HTTP Header.
     * @param {string} correlid Flow (or session) identifier. The flow initiator generates the ID; any other party should copy the received ID into all subsequent messages/calls inside the flow. IDs should be unique for each flow.
     * @param {string} source This identifier is unique for the different modules of the source systemapplications. The name must be defined as an application module name in VEKER naming style. This field is used for logging and in the gatekeeper configuration too.
     * @param {string} srcModule This identifier is unique for the different consumer systemapplications. The name has to be defined in VEKER naming style as an application name. This field is used for logging, gathering information on service usage and in the gatekeeper configuration too.
     * @param {string} userid The identity ID describes the identity of the user that has initiated the service request. This may be, for example, the customer, or an internal user, or may identify a special systemapplication user for calls made by automated systemapplications. If the identification of the original user is not possible (e.g. batch jobs) or the original user is not part of the global user directory (e.g. a customer, who is using a frontend systemapplication), then a technical user name should be used. This is different from the application ID. The Identity ID is of the end user of the systemapplication while the application ID is the calling systemapplication.
     * @param {string} q Az instrumentum neve, vagy ISIN kódjának szótöredéke.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async searchInstrument(
      id: string,
      correlid: string,
      source: string,
      srcModule: string,
      userid: string,
      q: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Instrument>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.searchInstrument(
        id,
        correlid,
        source,
        srcModule,
        userid,
        q,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary A megadott string paraméter alapján az instrumentum ticker, teljes név, ISIN mezőiben keres.
     * @param {string} id All requests should include a unique identifier element. The identifier is used for auditing and control of double-submission for update operations. The Id must be prefixed by the application name (or module, if necessary) to be unique in the whole environment. The application name and the Id must be separated with colon (:). Synchronous response to a request must use the same id in the HTTP Header.
     * @param {string} correlid Flow (or session) identifier. The flow initiator generates the ID; any other party should copy the received ID into all subsequent messages/calls inside the flow. IDs should be unique for each flow.
     * @param {string} source This identifier is unique for the different modules of the source systemapplications. The name must be defined as an application module name in VEKER naming style. This field is used for logging and in the gatekeeper configuration too.
     * @param {string} srcModule This identifier is unique for the different consumer systemapplications. The name has to be defined in VEKER naming style as an application name. This field is used for logging, gathering information on service usage and in the gatekeeper configuration too.
     * @param {string} userid The identity ID describes the identity of the user that has initiated the service request. This may be, for example, the customer, or an internal user, or may identify a special systemapplication user for calls made by automated systemapplications. If the identification of the original user is not possible (e.g. batch jobs) or the original user is not part of the global user directory (e.g. a customer, who is using a frontend systemapplication), then a technical user name should be used. This is different from the application ID. The Identity ID is of the end user of the systemapplication while the application ID is the calling systemapplication.
     * @param {string} q A listázott instrumentum neve, vagy ISIN kódjának szótöredéke.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async searchListedInstrument(
      id: string,
      correlid: string,
      source: string,
      srcModule: string,
      userid: string,
      q: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ListedInstrument>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.searchListedInstrument(
        id,
        correlid,
        source,
        srcModule,
        userid,
        q,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = DefaultApiFp(configuration);
  return {
    /**
     *
     * @summary A megadott listán szereplő RIC lista alapján visszaadja az instrumentum részletes adatait.
     * @param {DefaultApiBatchInstrumentDetailsQueryRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    batchInstrumentDetailsQuery(
      requestParameters: DefaultApiBatchInstrumentDetailsQueryRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<Array<InstrumentDetails>> {
      return localVarFp
        .batchInstrumentDetailsQuery(
          requestParameters.id,
          requestParameters.correlid,
          requestParameters.source,
          requestParameters.srcModule,
          requestParameters.userid,
          requestParameters.rics,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary A megadott string paraméter alapján az instrumentum teljes név, ISIN mezőiben keres.
     * @param {DefaultApiBatchInstrumentQueryRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    batchInstrumentQuery(
      requestParameters: DefaultApiBatchInstrumentQueryRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<Array<ListedInstrument>> {
      return localVarFp
        .batchInstrumentQuery(
          requestParameters.id,
          requestParameters.correlid,
          requestParameters.source,
          requestParameters.srcModule,
          requestParameters.userid,
          requestParameters.isins,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Instrumentum részletes adatainak lekérdezése, az URL-ben kapott RIC kód alapján InstrumentDetail entitást ad vissza.
     * @param {DefaultApiGetInstrumentDetailsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getInstrumentDetails(
      requestParameters: DefaultApiGetInstrumentDetailsRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<InstrumentDetails> {
      return localVarFp
        .getInstrumentDetails(
          requestParameters.id,
          requestParameters.correlid,
          requestParameters.source,
          requestParameters.srcModule,
          requestParameters.userid,
          requestParameters.ric,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Árlépésköz adatok lekérdezése, az URL-ben kapott RIC kód alapján TickSize listát ad vissza.
     * @param {DefaultApiGetInstrumentTickSizeRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getInstrumentTickSize(
      requestParameters: DefaultApiGetInstrumentTickSizeRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<Array<TickSize>> {
      return localVarFp
        .getInstrumentTickSize(
          requestParameters.id,
          requestParameters.correlid,
          requestParameters.source,
          requestParameters.srcModule,
          requestParameters.userid,
          requestParameters.ric,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Instrumentum adatainak lekérdezése, az URL-ben kapott ISIN kód alapján egy Instrument entitást ad vissza.
     * @param {DefaultApiGetInstrumentumByISINRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getInstrumentumByISIN(
      requestParameters: DefaultApiGetInstrumentumByISINRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<Instrument> {
      return localVarFp
        .getInstrumentumByISIN(
          requestParameters.id,
          requestParameters.correlid,
          requestParameters.source,
          requestParameters.srcModule,
          requestParameters.userid,
          requestParameters.isin,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Instrumentum piacfüggő adatainak lekérdezése, az URL-ben kapott ISIN kód alapján ListedInstrument listát ad vissza.
     * @param {DefaultApiInstrumentsIsinListingsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    instrumentsIsinListingsGet(
      requestParameters: DefaultApiInstrumentsIsinListingsGetRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<Array<ListedInstrument>> {
      return localVarFp
        .instrumentsIsinListingsGet(
          requestParameters.id,
          requestParameters.correlid,
          requestParameters.source,
          requestParameters.srcModule,
          requestParameters.userid,
          requestParameters.isin,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary A megadott string paraméter alapján az instrumentum teljes név, ISIN mezőiben keres.
     * @param {DefaultApiSearchInstrumentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchInstrument(
      requestParameters: DefaultApiSearchInstrumentRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<Array<Instrument>> {
      return localVarFp
        .searchInstrument(
          requestParameters.id,
          requestParameters.correlid,
          requestParameters.source,
          requestParameters.srcModule,
          requestParameters.userid,
          requestParameters.q,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary A megadott string paraméter alapján az instrumentum ticker, teljes név, ISIN mezőiben keres.
     * @param {DefaultApiSearchListedInstrumentRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    searchListedInstrument(
      requestParameters: DefaultApiSearchListedInstrumentRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<Array<ListedInstrument>> {
      return localVarFp
        .searchListedInstrument(
          requestParameters.id,
          requestParameters.correlid,
          requestParameters.source,
          requestParameters.srcModule,
          requestParameters.userid,
          requestParameters.q,
          options,
        )
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for batchInstrumentDetailsQuery operation in DefaultApi.
 * @export
 * @interface DefaultApiBatchInstrumentDetailsQueryRequest
 */
export interface DefaultApiBatchInstrumentDetailsQueryRequest {
  /**
   * All requests should include a unique identifier element. The identifier is used for auditing and control of double-submission for update operations. The Id must be prefixed by the application name (or module, if necessary) to be unique in the whole environment. The application name and the Id must be separated with colon (:). Synchronous response to a request must use the same id in the HTTP Header.
   * @type {string}
   * @memberof DefaultApiBatchInstrumentDetailsQuery
   */
  readonly id: string;

  /**
   * Flow (or session) identifier. The flow initiator generates the ID; any other party should copy the received ID into all subsequent messages/calls inside the flow. IDs should be unique for each flow.
   * @type {string}
   * @memberof DefaultApiBatchInstrumentDetailsQuery
   */
  readonly correlid: string;

  /**
   * This identifier is unique for the different modules of the source systemapplications. The name must be defined as an application module name in VEKER naming style. This field is used for logging and in the gatekeeper configuration too.
   * @type {string}
   * @memberof DefaultApiBatchInstrumentDetailsQuery
   */
  readonly source: string;

  /**
   * This identifier is unique for the different consumer systemapplications. The name has to be defined in VEKER naming style as an application name. This field is used for logging, gathering information on service usage and in the gatekeeper configuration too.
   * @type {string}
   * @memberof DefaultApiBatchInstrumentDetailsQuery
   */
  readonly srcModule: string;

  /**
   * The identity ID describes the identity of the user that has initiated the service request. This may be, for example, the customer, or an internal user, or may identify a special systemapplication user for calls made by automated systemapplications. If the identification of the original user is not possible (e.g. batch jobs) or the original user is not part of the global user directory (e.g. a customer, who is using a frontend systemapplication), then a technical user name should be used. This is different from the application ID. The Identity ID is of the end user of the systemapplication while the application ID is the calling systemapplication.
   * @type {string}
   * @memberof DefaultApiBatchInstrumentDetailsQuery
   */
  readonly userid: string;

  /**
   * RIC kód lista
   * @type {Array<string>}
   * @memberof DefaultApiBatchInstrumentDetailsQuery
   */
  readonly rics?: Array<string>;
}

/**
 * Request parameters for batchInstrumentQuery operation in DefaultApi.
 * @export
 * @interface DefaultApiBatchInstrumentQueryRequest
 */
export interface DefaultApiBatchInstrumentQueryRequest {
  /**
   * All requests should include a unique identifier element. The identifier is used for auditing and control of double-submission for update operations. The Id must be prefixed by the application name (or module, if necessary) to be unique in the whole environment. The application name and the Id must be separated with colon (:). Synchronous response to a request must use the same id in the HTTP Header.
   * @type {string}
   * @memberof DefaultApiBatchInstrumentQuery
   */
  readonly id: string;

  /**
   * Flow (or session) identifier. The flow initiator generates the ID; any other party should copy the received ID into all subsequent messages/calls inside the flow. IDs should be unique for each flow.
   * @type {string}
   * @memberof DefaultApiBatchInstrumentQuery
   */
  readonly correlid: string;

  /**
   * This identifier is unique for the different modules of the source systemapplications. The name must be defined as an application module name in VEKER naming style. This field is used for logging and in the gatekeeper configuration too.
   * @type {string}
   * @memberof DefaultApiBatchInstrumentQuery
   */
  readonly source: string;

  /**
   * This identifier is unique for the different consumer systemapplications. The name has to be defined in VEKER naming style as an application name. This field is used for logging, gathering information on service usage and in the gatekeeper configuration too.
   * @type {string}
   * @memberof DefaultApiBatchInstrumentQuery
   */
  readonly srcModule: string;

  /**
   * The identity ID describes the identity of the user that has initiated the service request. This may be, for example, the customer, or an internal user, or may identify a special systemapplication user for calls made by automated systemapplications. If the identification of the original user is not possible (e.g. batch jobs) or the original user is not part of the global user directory (e.g. a customer, who is using a frontend systemapplication), then a technical user name should be used. This is different from the application ID. The Identity ID is of the end user of the systemapplication while the application ID is the calling systemapplication.
   * @type {string}
   * @memberof DefaultApiBatchInstrumentQuery
   */
  readonly userid: string;

  /**
   * ISIN kód lista
   * @type {Array<string>}
   * @memberof DefaultApiBatchInstrumentQuery
   */
  readonly isins: Array<string>;
}

/**
 * Request parameters for getInstrumentDetails operation in DefaultApi.
 * @export
 * @interface DefaultApiGetInstrumentDetailsRequest
 */
export interface DefaultApiGetInstrumentDetailsRequest {
  /**
   * All requests should include a unique identifier element. The identifier is used for auditing and control of double-submission for update operations. The Id must be prefixed by the application name (or module, if necessary) to be unique in the whole environment. The application name and the Id must be separated with colon (:). Synchronous response to a request must use the same id in the HTTP Header.
   * @type {string}
   * @memberof DefaultApiGetInstrumentDetails
   */
  readonly id: string;

  /**
   * Flow (or session) identifier. The flow initiator generates the ID; any other party should copy the received ID into all subsequent messages/calls inside the flow. IDs should be unique for each flow.
   * @type {string}
   * @memberof DefaultApiGetInstrumentDetails
   */
  readonly correlid: string;

  /**
   * This identifier is unique for the different modules of the source systemapplications. The name must be defined as an application module name in VEKER naming style. This field is used for logging and in the gatekeeper configuration too.
   * @type {string}
   * @memberof DefaultApiGetInstrumentDetails
   */
  readonly source: string;

  /**
   * This identifier is unique for the different consumer systemapplications. The name has to be defined in VEKER naming style as an application name. This field is used for logging, gathering information on service usage and in the gatekeeper configuration too.
   * @type {string}
   * @memberof DefaultApiGetInstrumentDetails
   */
  readonly srcModule: string;

  /**
   * The identity ID describes the identity of the user that has initiated the service request. This may be, for example, the customer, or an internal user, or may identify a special systemapplication user for calls made by automated systemapplications. If the identification of the original user is not possible (e.g. batch jobs) or the original user is not part of the global user directory (e.g. a customer, who is using a frontend systemapplication), then a technical user name should be used. This is different from the application ID. The Identity ID is of the end user of the systemapplication while the application ID is the calling systemapplication.
   * @type {string}
   * @memberof DefaultApiGetInstrumentDetails
   */
  readonly userid: string;

  /**
   * IInstrumentum RIC kódja.
   * @type {string}
   * @memberof DefaultApiGetInstrumentDetails
   */
  readonly ric: string;
}

/**
 * Request parameters for getInstrumentTickSize operation in DefaultApi.
 * @export
 * @interface DefaultApiGetInstrumentTickSizeRequest
 */
export interface DefaultApiGetInstrumentTickSizeRequest {
  /**
   * All requests should include a unique identifier element. The identifier is used for auditing and control of double-submission for update operations. The Id must be prefixed by the application name (or module, if necessary) to be unique in the whole environment. The application name and the Id must be separated with colon (:). Synchronous response to a request must use the same id in the HTTP Header.
   * @type {string}
   * @memberof DefaultApiGetInstrumentTickSize
   */
  readonly id: string;

  /**
   * Flow (or session) identifier. The flow initiator generates the ID; any other party should copy the received ID into all subsequent messages/calls inside the flow. IDs should be unique for each flow.
   * @type {string}
   * @memberof DefaultApiGetInstrumentTickSize
   */
  readonly correlid: string;

  /**
   * This identifier is unique for the different modules of the source systemapplications. The name must be defined as an application module name in VEKER naming style. This field is used for logging and in the gatekeeper configuration too.
   * @type {string}
   * @memberof DefaultApiGetInstrumentTickSize
   */
  readonly source: string;

  /**
   * This identifier is unique for the different consumer systemapplications. The name has to be defined in VEKER naming style as an application name. This field is used for logging, gathering information on service usage and in the gatekeeper configuration too.
   * @type {string}
   * @memberof DefaultApiGetInstrumentTickSize
   */
  readonly srcModule: string;

  /**
   * The identity ID describes the identity of the user that has initiated the service request. This may be, for example, the customer, or an internal user, or may identify a special systemapplication user for calls made by automated systemapplications. If the identification of the original user is not possible (e.g. batch jobs) or the original user is not part of the global user directory (e.g. a customer, who is using a frontend systemapplication), then a technical user name should be used. This is different from the application ID. The Identity ID is of the end user of the systemapplication while the application ID is the calling systemapplication.
   * @type {string}
   * @memberof DefaultApiGetInstrumentTickSize
   */
  readonly userid: string;

  /**
   * IInstrumentum RIC kódja.
   * @type {string}
   * @memberof DefaultApiGetInstrumentTickSize
   */
  readonly ric: string;
}

/**
 * Request parameters for getInstrumentumByISIN operation in DefaultApi.
 * @export
 * @interface DefaultApiGetInstrumentumByISINRequest
 */
export interface DefaultApiGetInstrumentumByISINRequest {
  /**
   * All requests should include a unique identifier element. The identifier is used for auditing and control of double-submission for update operations. The Id must be prefixed by the application name (or module, if necessary) to be unique in the whole environment. The application name and the Id must be separated with colon (:). Synchronous response to a request must use the same id in the HTTP Header.
   * @type {string}
   * @memberof DefaultApiGetInstrumentumByISIN
   */
  readonly id: string;

  /**
   * Flow (or session) identifier. The flow initiator generates the ID; any other party should copy the received ID into all subsequent messages/calls inside the flow. IDs should be unique for each flow.
   * @type {string}
   * @memberof DefaultApiGetInstrumentumByISIN
   */
  readonly correlid: string;

  /**
   * This identifier is unique for the different modules of the source systemapplications. The name must be defined as an application module name in VEKER naming style. This field is used for logging and in the gatekeeper configuration too.
   * @type {string}
   * @memberof DefaultApiGetInstrumentumByISIN
   */
  readonly source: string;

  /**
   * This identifier is unique for the different consumer systemapplications. The name has to be defined in VEKER naming style as an application name. This field is used for logging, gathering information on service usage and in the gatekeeper configuration too.
   * @type {string}
   * @memberof DefaultApiGetInstrumentumByISIN
   */
  readonly srcModule: string;

  /**
   * The identity ID describes the identity of the user that has initiated the service request. This may be, for example, the customer, or an internal user, or may identify a special systemapplication user for calls made by automated systemapplications. If the identification of the original user is not possible (e.g. batch jobs) or the original user is not part of the global user directory (e.g. a customer, who is using a frontend systemapplication), then a technical user name should be used. This is different from the application ID. The Identity ID is of the end user of the systemapplication while the application ID is the calling systemapplication.
   * @type {string}
   * @memberof DefaultApiGetInstrumentumByISIN
   */
  readonly userid: string;

  /**
   * Instrumentum ISIN kódja.
   * @type {string}
   * @memberof DefaultApiGetInstrumentumByISIN
   */
  readonly isin: string;
}

/**
 * Request parameters for instrumentsIsinListingsGet operation in DefaultApi.
 * @export
 * @interface DefaultApiInstrumentsIsinListingsGetRequest
 */
export interface DefaultApiInstrumentsIsinListingsGetRequest {
  /**
   * All requests should include a unique identifier element. The identifier is used for auditing and control of double-submission for update operations. The Id must be prefixed by the application name (or module, if necessary) to be unique in the whole environment. The application name and the Id must be separated with colon (:). Synchronous response to a request must use the same id in the HTTP Header.
   * @type {string}
   * @memberof DefaultApiInstrumentsIsinListingsGet
   */
  readonly id: string;

  /**
   * Flow (or session) identifier. The flow initiator generates the ID; any other party should copy the received ID into all subsequent messages/calls inside the flow. IDs should be unique for each flow.
   * @type {string}
   * @memberof DefaultApiInstrumentsIsinListingsGet
   */
  readonly correlid: string;

  /**
   * This identifier is unique for the different modules of the source systemapplications. The name must be defined as an application module name in VEKER naming style. This field is used for logging and in the gatekeeper configuration too.
   * @type {string}
   * @memberof DefaultApiInstrumentsIsinListingsGet
   */
  readonly source: string;

  /**
   * This identifier is unique for the different consumer systemapplications. The name has to be defined in VEKER naming style as an application name. This field is used for logging, gathering information on service usage and in the gatekeeper configuration too.
   * @type {string}
   * @memberof DefaultApiInstrumentsIsinListingsGet
   */
  readonly srcModule: string;

  /**
   * The identity ID describes the identity of the user that has initiated the service request. This may be, for example, the customer, or an internal user, or may identify a special systemapplication user for calls made by automated systemapplications. If the identification of the original user is not possible (e.g. batch jobs) or the original user is not part of the global user directory (e.g. a customer, who is using a frontend systemapplication), then a technical user name should be used. This is different from the application ID. The Identity ID is of the end user of the systemapplication while the application ID is the calling systemapplication.
   * @type {string}
   * @memberof DefaultApiInstrumentsIsinListingsGet
   */
  readonly userid: string;

  /**
   * Instrumentum ISIN kódja.
   * @type {string}
   * @memberof DefaultApiInstrumentsIsinListingsGet
   */
  readonly isin: string;
}

/**
 * Request parameters for searchInstrument operation in DefaultApi.
 * @export
 * @interface DefaultApiSearchInstrumentRequest
 */
export interface DefaultApiSearchInstrumentRequest {
  /**
   * All requests should include a unique identifier element. The identifier is used for auditing and control of double-submission for update operations. The Id must be prefixed by the application name (or module, if necessary) to be unique in the whole environment. The application name and the Id must be separated with colon (:). Synchronous response to a request must use the same id in the HTTP Header.
   * @type {string}
   * @memberof DefaultApiSearchInstrument
   */
  readonly id: string;

  /**
   * Flow (or session) identifier. The flow initiator generates the ID; any other party should copy the received ID into all subsequent messages/calls inside the flow. IDs should be unique for each flow.
   * @type {string}
   * @memberof DefaultApiSearchInstrument
   */
  readonly correlid: string;

  /**
   * This identifier is unique for the different modules of the source systemapplications. The name must be defined as an application module name in VEKER naming style. This field is used for logging and in the gatekeeper configuration too.
   * @type {string}
   * @memberof DefaultApiSearchInstrument
   */
  readonly source: string;

  /**
   * This identifier is unique for the different consumer systemapplications. The name has to be defined in VEKER naming style as an application name. This field is used for logging, gathering information on service usage and in the gatekeeper configuration too.
   * @type {string}
   * @memberof DefaultApiSearchInstrument
   */
  readonly srcModule: string;

  /**
   * The identity ID describes the identity of the user that has initiated the service request. This may be, for example, the customer, or an internal user, or may identify a special systemapplication user for calls made by automated systemapplications. If the identification of the original user is not possible (e.g. batch jobs) or the original user is not part of the global user directory (e.g. a customer, who is using a frontend systemapplication), then a technical user name should be used. This is different from the application ID. The Identity ID is of the end user of the systemapplication while the application ID is the calling systemapplication.
   * @type {string}
   * @memberof DefaultApiSearchInstrument
   */
  readonly userid: string;

  /**
   * Az instrumentum neve, vagy ISIN kódjának szótöredéke.
   * @type {string}
   * @memberof DefaultApiSearchInstrument
   */
  readonly q: string;
}

/**
 * Request parameters for searchListedInstrument operation in DefaultApi.
 * @export
 * @interface DefaultApiSearchListedInstrumentRequest
 */
export interface DefaultApiSearchListedInstrumentRequest {
  /**
   * All requests should include a unique identifier element. The identifier is used for auditing and control of double-submission for update operations. The Id must be prefixed by the application name (or module, if necessary) to be unique in the whole environment. The application name and the Id must be separated with colon (:). Synchronous response to a request must use the same id in the HTTP Header.
   * @type {string}
   * @memberof DefaultApiSearchListedInstrument
   */
  readonly id: string;

  /**
   * Flow (or session) identifier. The flow initiator generates the ID; any other party should copy the received ID into all subsequent messages/calls inside the flow. IDs should be unique for each flow.
   * @type {string}
   * @memberof DefaultApiSearchListedInstrument
   */
  readonly correlid: string;

  /**
   * This identifier is unique for the different modules of the source systemapplications. The name must be defined as an application module name in VEKER naming style. This field is used for logging and in the gatekeeper configuration too.
   * @type {string}
   * @memberof DefaultApiSearchListedInstrument
   */
  readonly source: string;

  /**
   * This identifier is unique for the different consumer systemapplications. The name has to be defined in VEKER naming style as an application name. This field is used for logging, gathering information on service usage and in the gatekeeper configuration too.
   * @type {string}
   * @memberof DefaultApiSearchListedInstrument
   */
  readonly srcModule: string;

  /**
   * The identity ID describes the identity of the user that has initiated the service request. This may be, for example, the customer, or an internal user, or may identify a special systemapplication user for calls made by automated systemapplications. If the identification of the original user is not possible (e.g. batch jobs) or the original user is not part of the global user directory (e.g. a customer, who is using a frontend systemapplication), then a technical user name should be used. This is different from the application ID. The Identity ID is of the end user of the systemapplication while the application ID is the calling systemapplication.
   * @type {string}
   * @memberof DefaultApiSearchListedInstrument
   */
  readonly userid: string;

  /**
   * A listázott instrumentum neve, vagy ISIN kódjának szótöredéke.
   * @type {string}
   * @memberof DefaultApiSearchListedInstrument
   */
  readonly q: string;
}

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
  /**
   *
   * @summary A megadott listán szereplő RIC lista alapján visszaadja az instrumentum részletes adatait.
   * @param {DefaultApiBatchInstrumentDetailsQueryRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public batchInstrumentDetailsQuery(
    requestParameters: DefaultApiBatchInstrumentDetailsQueryRequest,
    options?: AxiosRequestConfig,
  ) {
    return DefaultApiFp(this.configuration)
      .batchInstrumentDetailsQuery(
        requestParameters.id,
        requestParameters.correlid,
        requestParameters.source,
        requestParameters.srcModule,
        requestParameters.userid,
        requestParameters.rics,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary A megadott string paraméter alapján az instrumentum teljes név, ISIN mezőiben keres.
   * @param {DefaultApiBatchInstrumentQueryRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public batchInstrumentQuery(requestParameters: DefaultApiBatchInstrumentQueryRequest, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .batchInstrumentQuery(
        requestParameters.id,
        requestParameters.correlid,
        requestParameters.source,
        requestParameters.srcModule,
        requestParameters.userid,
        requestParameters.isins,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Instrumentum részletes adatainak lekérdezése, az URL-ben kapott RIC kód alapján InstrumentDetail entitást ad vissza.
   * @param {DefaultApiGetInstrumentDetailsRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public getInstrumentDetails(requestParameters: DefaultApiGetInstrumentDetailsRequest, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .getInstrumentDetails(
        requestParameters.id,
        requestParameters.correlid,
        requestParameters.source,
        requestParameters.srcModule,
        requestParameters.userid,
        requestParameters.ric,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Árlépésköz adatok lekérdezése, az URL-ben kapott RIC kód alapján TickSize listát ad vissza.
   * @param {DefaultApiGetInstrumentTickSizeRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public getInstrumentTickSize(
    requestParameters: DefaultApiGetInstrumentTickSizeRequest,
    options?: AxiosRequestConfig,
  ) {
    return DefaultApiFp(this.configuration)
      .getInstrumentTickSize(
        requestParameters.id,
        requestParameters.correlid,
        requestParameters.source,
        requestParameters.srcModule,
        requestParameters.userid,
        requestParameters.ric,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Instrumentum adatainak lekérdezése, az URL-ben kapott ISIN kód alapján egy Instrument entitást ad vissza.
   * @param {DefaultApiGetInstrumentumByISINRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public getInstrumentumByISIN(
    requestParameters: DefaultApiGetInstrumentumByISINRequest,
    options?: AxiosRequestConfig,
  ) {
    return DefaultApiFp(this.configuration)
      .getInstrumentumByISIN(
        requestParameters.id,
        requestParameters.correlid,
        requestParameters.source,
        requestParameters.srcModule,
        requestParameters.userid,
        requestParameters.isin,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Instrumentum piacfüggő adatainak lekérdezése, az URL-ben kapott ISIN kód alapján ListedInstrument listát ad vissza.
   * @param {DefaultApiInstrumentsIsinListingsGetRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public instrumentsIsinListingsGet(
    requestParameters: DefaultApiInstrumentsIsinListingsGetRequest,
    options?: AxiosRequestConfig,
  ) {
    return DefaultApiFp(this.configuration)
      .instrumentsIsinListingsGet(
        requestParameters.id,
        requestParameters.correlid,
        requestParameters.source,
        requestParameters.srcModule,
        requestParameters.userid,
        requestParameters.isin,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary A megadott string paraméter alapján az instrumentum teljes név, ISIN mezőiben keres.
   * @param {DefaultApiSearchInstrumentRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public searchInstrument(requestParameters: DefaultApiSearchInstrumentRequest, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .searchInstrument(
        requestParameters.id,
        requestParameters.correlid,
        requestParameters.source,
        requestParameters.srcModule,
        requestParameters.userid,
        requestParameters.q,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary A megadott string paraméter alapján az instrumentum ticker, teljes név, ISIN mezőiben keres.
   * @param {DefaultApiSearchListedInstrumentRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public searchListedInstrument(
    requestParameters: DefaultApiSearchListedInstrumentRequest,
    options?: AxiosRequestConfig,
  ) {
    return DefaultApiFp(this.configuration)
      .searchListedInstrument(
        requestParameters.id,
        requestParameters.correlid,
        requestParameters.source,
        requestParameters.srcModule,
        requestParameters.userid,
        requestParameters.q,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }
}
