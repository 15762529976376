import { CookieConsent } from '../partials/CookieConsent';
import { LoginView } from '../partials/LoginView';

export const LoginPage = () => {
  return (
    <>
      <LoginView />
      <CookieConsent />
    </>
  );
};
