import { Autocomplete, AutocompleteRenderInputParams, TextField } from '@mui/material';
import { AutocompleteProps } from '@mui/material/Autocomplete/Autocomplete';
import { TextFieldProps } from '@mui/material/TextField/TextField';
import { SyntheticEvent, useState } from 'react';
import styles from './Autocomplete.module.scss';

type MaterialAutocompletePropsWithoutRenderInput<T> = Omit<AutocompleteProps<T, false, false, false>, 'renderInput'>;

export interface NbAutocompleteProps<T> extends MaterialAutocompletePropsWithoutRenderInput<T> {
  id?: string;
  label?: string;
  className?: string;
  inputProps?: TextFieldProps;
}

export const NbAutocomplete = <T,>({
  id,
  label,
  className,
  size,
  classes,
  inputProps,
  ...props
}: NbAutocompleteProps<T>) => {
  const [selection, setSelection] = useState<T | null>(null);
  const sizeClass = size === 'small' ? styles.nbAutocompleteIsSmall : '';
  const { InputProps: InputInputProps, ...textFieldInputProps } = inputProps || {};
  const paramClasses = classes || {};
  const updateSelection = (_: SyntheticEvent, val: T | null) => {
    setSelection(val);
  };

  return (
    <>
      <Autocomplete
        id={id}
        className={`${className ?? ''}`}
        classes={{
          ...paramClasses,
          root: `${styles.nbAutocomplete} ${sizeClass}`,
        }}
        value={selection}
        onChange={updateSelection}
        renderInput={({ InputProps, ...renderInputParams }: AutocompleteRenderInputParams) => (
          <TextField
            InputProps={{
              ...InputProps,
              ...InputInputProps,
            }}
            {...renderInputParams}
            {...textFieldInputProps}
            label={label}
          />
        )}
        {...props}
      />
    </>
  );
};
