import BackspaceIcon from '@mui/icons-material/Backspace';
import ViewColumnIcon from '@mui/icons-material/ViewColumn';
import { Popover } from '@mui/material';
import { MouseEvent, useState } from 'react';
import { useLocale } from '../../../context/NbLocalizationContext';
import { useSnackbar } from '../../../context/SnackbarContext';
import { NbButton } from '../../../shared/components/button/Button';
import { DraggableList } from '../../../shared/components/draggable-list/DraggableList';
import { DraggableSwitchItem } from '../../../shared/components/draggable-list/DraggableSwitchItem';
import { NbIconButton } from '../../../shared/components/icon-button/IconButton';
import { InstrumentSearchAutocomplete } from '../../../shared/components/instrument-search-autocomplete/InstrumentSearchAutocomplete';
import { NbTextField } from '../../../shared/components/text-field/TextField';
import { allColumns } from '../../../shared/mock/mockedWatchList';
import { WatchListColumn, WatchListGroup } from '../../../shared/state/watchListStateAtom';
import { ListedInstrument } from '../../../shared/types/listedInstrument';
import { useWatchList } from '../services/useWatchList';
import classes from './WatchListEditor.module.scss';

export interface WatchListEditorProps {
  closeEditor: (selectedWatchListId?: string) => void;
  editedWatchListGroup: WatchListGroup;
  setEditedWatchListGroup: (value: WatchListGroup) => void;
  onSave: () => Promise<void>;
  isLoading: boolean;
  onClearInstruments: () => void;
  onColumnsUpdate: (columns: Array<WatchListColumn>) => void;
}

type WatchListColumnWithId = WatchListColumn & {
  id: string;
  selected: boolean;
  name: string;
};

export const WatchListEditor = ({
  closeEditor,
  editedWatchListGroup,
  setEditedWatchListGroup,
  onSave,
  isLoading,
  onClearInstruments,
  onColumnsUpdate,
}: WatchListEditorProps) => {
  const { translation } = useLocale();
  const [selectedInstrument] = useState<ListedInstrument | null>(null);
  const [columnsPopover, setColumnsPopover] = useState<HTMLButtonElement | null>(null);
  const [watchListGroupColumns, setWatchListGroupColumns] = useState<Array<WatchListColumnWithId>>(
    allColumns.map(column => {
      return {
        id: column.field,
        field: column.field,
        name: translation.watchListGrid.grid[column.field] as string,
        selected: editedWatchListGroup.columns.map(c => c.field).includes(column.field),
      };
    }),
  );

  const { showError } = useSnackbar();
  const { addInstrumentToWatchList } = useWatchList();

  const createWatchList = async () => {
    await onSave();
  };

  const updateWatchListName = (newName: string) => {
    setEditedWatchListGroup({ ...editedWatchListGroup, name: newName });
  };

  const openColumnsPopover = (event: MouseEvent<HTMLButtonElement>) => {
    setColumnsPopover(event.currentTarget);
  };

  const handleColumnsPopoverClose = () => {
    setColumnsPopover(null);
  };

  const toggleColumnVisibility = (column: WatchListColumnWithId) => {
    const newColumnList = watchListGroupColumns.map(col =>
      col.field !== column.field
        ? col
        : {
            ...col,
            selected: !col.selected,
          },
    );
    saveWatchListGroupColumns(newColumnList);
  };

  const saveWatchListGroupColumns = async (newColumns: Array<WatchListColumnWithId>) => {
    setWatchListGroupColumns(newColumns);
    onColumnsUpdate(
      newColumns.filter(column => column.selected).map(column => ({ field: column.field } as WatchListColumn)),
    );
  };

  const addInstrument = (newInstrument: ListedInstrument | null) => {
    if (newInstrument) {
      try {
        const updatedWatchListGroup = addInstrumentToWatchList(editedWatchListGroup, newInstrument);
        setEditedWatchListGroup(updatedWatchListGroup);
      } catch (err) {
        showError((err as Error).message);
      }
    }
  };

  return (
    <div className={classes.edit}>
      <div className={classes.editHeader}>
        <div className={classes.editHeaderInputs}>
          <NbTextField
            size={'small'}
            className={classes.textField}
            placeholder={translation.watchList.newListPlaceholder}
            value={editedWatchListGroup.name}
            onChange={e => updateWatchListName(e.target.value)}
            disabled={isLoading}
            autoFocus
          />
          <InstrumentSearchAutocomplete
            size={'small'}
            inputProps={{ placeholder: translation.watchList.searchNewInstrumentPlaceholder }}
            disabled={isLoading}
            className={classes.autocomplete}
            selection={selectedInstrument}
            onSelectInstrument={addInstrument}
            clearSelectionAfterSelect={true}
            isPopperWide
          />
          <NbIconButton
            icon={<ViewColumnIcon />}
            onClick={openColumnsPopover}
          />
          <NbIconButton
            icon={<BackspaceIcon />}
            onClick={onClearInstruments}
          />

          <Popover
            open={!!columnsPopover}
            anchorEl={columnsPopover}
            onClose={handleColumnsPopoverClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            classes={{ paper: classes.popover }}
          >
            <div className={classes.column}>
              <DraggableList
                list={watchListGroupColumns}
                onListReordered={saveWatchListGroupColumns}
                renderItem={(column: WatchListColumnWithId) => (
                  <DraggableSwitchItem
                    label={column.name}
                    selected={column.selected}
                    toggleColumnVisibility={() => toggleColumnVisibility(column)}
                  />
                )}
              />
            </div>
          </Popover>
        </div>
        <div className={classes.editHeaderButtons}>
          <NbButton
            size={'small'}
            color={'secondary'}
            onClick={() => closeEditor()}
            disabled={isLoading}
          >
            {translation.general.cancel}
          </NbButton>
          <NbButton
            size={'small'}
            color={'primary'}
            onClick={createWatchList}
            isLoading={isLoading}
          >
            {translation.general.save}
          </NbButton>
        </div>
      </div>
    </div>
  );
};
