import { nanoid } from 'nanoid';
import { ConnectionState } from '../../../context/SSEConnectionContext';
import { Config } from '../../config/config';
import { Keys } from '../../config/keys';
import { SubscribeOptions } from '../../types/estream/eStreamSubscription';
import { EStreamTradeDTO } from '../../types/estream/eStreamTrade';
import { createLogger } from '../../utils/logger';
import { ObjectSet } from '../../utils/objectSet';

//
// PRIVATE TYPES
//

type SSEManagerEvents = {
  onThisInstanceSelectedAsPrimary: (eStreamSubscriptions?: Array<SubscribeOptions>) => void;
  onConnectionStateUpdate: (connectionState: ConnectionState, isInitalValue: boolean) => void;
  onEStreamTradesReceived: (trades: Array<EStreamTradeDTO>) => void;
  onOtherInstanceLoggedIn: (token: string) => void;
  onOtherInstanceLoggedOut: () => void;
  onOtherInstanceDestroyed: (instanceId: number) => void;
  onEStreamSubscriptionChange: (
    dir: 'subscribe' | 'unsubscribe',
    options: Array<SubscribeOptions>,
    instanceId: number,
  ) => void;
};

enum SSEManagerEvent {
  InstanceInit = 'init',
  InstanceDestroy = 'destroy',
  InstanceAnswer = 'answer',
  InstanceIgnore = 'ignore',
  Login = 'Login',
  Logout = 'Logout',
  EStreamConnectionStateUpdate = 'EStreamConnectionStateUpdate',
  EStreamTrades = 'EStreamTrades',
  EStreamSubscriptionSubscribe = 'EStreamSubscriptionSubscribe',
  EStreamSubscriptionUnsubscribe = 'EStreamSubscriptionUnsubscribe',
}

type SSEManagerBasePayload = {
  eventType: SSEManagerEvent;
};
type SSEMInitPayload = {
  eventType: SSEManagerEvent.InstanceInit;
  myInstanceId: number;
};
type SSEMDestroyPayload = {
  eventType: SSEManagerEvent.InstanceDestroy;
  myInstanceId: number;
  eStreamSubscriptions: Array<SubscribeOptions>;
};
type SSEMBroadcastPrimaryPayload = SSEManagerBasePayload & {
  eventType: SSEManagerEvent.InstanceAnswer;
  primarySSEManagerId: number;
  eStreamConnectionState?: ConnectionState;
};
type SSEMBroadcastPrimaryIgnoreYourselfPayload = SSEManagerBasePayload & {
  eventType: SSEManagerEvent.InstanceIgnore;
  instanceId: number;
};
type SSEMEStreamConnectionStateUpdatePayload = SSEManagerBasePayload & {
  eventType: SSEManagerEvent.EStreamConnectionStateUpdate;
  connectionState: ConnectionState;
};
type SSEMEStreamTradesPayload = SSEManagerBasePayload & {
  eventType: SSEManagerEvent.EStreamTrades;
  trades: Array<EStreamTradeDTO>;
};
type SSEMLoginPayload = SSEManagerBasePayload & {
  eventType: SSEManagerEvent.Login;
  token: string;
};
type SSEMEStreamSubscriptionChangePayload = SSEManagerBasePayload & {
  eventType: SSEManagerEvent.EStreamSubscriptionSubscribe | SSEManagerEvent.EStreamSubscriptionUnsubscribe;
  options: Array<SubscribeOptions>;
};

type BroadcastMessage<T extends SSEManagerBasePayload> = {
  source: 'netbroker';
  nbInstance: number;
  messageId: string;
  responseId?: string;
  payload: T;
};

class SSEManager {
  readonly instanceId = Math.floor(Math.random() * 9999999) + 1;

  private readonly logger = createLogger('SSEManager', 'info');
  private readonly channel = new BroadcastChannel('net-broker-sse-manager');

  private primaryInstanceSelected = false;
  private isInitialized = false;
  private isDestroyed = false;

  /**
   * Id of the primary NetBroker instance
   * @private
   */
  private _primarySSEManagerInstanceId?: number;

  /**
   * When primary client is destroyed, we need to find a new one, and this array holds the candidates instance ids
   * @private
   */
  private _primaryInstanceCandidates: Array<number> = [];

  /**
   * Timeout for the primary client discovery.
   * Used when the primary client is destroyed, and we need to find a new one
   * @private
   */
  private primaryClientDiscoveryTimeout?: NodeJS.Timeout;

  /**
   * init function returns with a promise, and because resolving/rejecting happens after broadcast messages
   * we need to have a reference for the resolve/reject function, with a timeout promise to cancel
   * @private
   */
  private initPromiseConfig: {
    timeoutPromise: NodeJS.Timeout;
    resolve: () => void;
  } | null = null;

  /**
   * Will be resolved when a primary instance is selected
   * @private
   */
  private choosePrimaryInstancePromise: Promise<void> | null = null;

  /**
   * SSE Manager event listeners
   * @private
   */
  private sseManagerEvents?: SSEManagerEvents;

  /**
   * If this client is trying to be the primary instance, but others said the candidates are already registered, so we need to ignore ourselves
   * @private
   */
  private ignoreMyselfAsPrimaryInstance = false;

  /**
   * When a primary instance destroyed we need to resubscribe to the eStream subscriptions, and this array holds the previous subscriptions
   * @private
   */
  private pendingEStreamSubscriptionsFromPreviousPrimaryInstance?: Array<SubscribeOptions> = undefined;

  /**
   * Holds the current eStream connection state
   * @private
   */
  private eStreamConnectionState?: ConnectionState;

  get isPrimaryInstanceSelected() {
    return this.primaryInstanceSelected;
  }

  get primarySSEManagerInstanceId() {
    return this._primarySSEManagerInstanceId;
  }

  get primaryInstanceCandidates() {
    return this._primaryInstanceCandidates;
  }

  updateEventHandlers(eventHandlers: SSEManagerEvents) {
    this.sseManagerEvents = eventHandlers;
  }

  /**
   * Creates the broadcast channel
   */
  async init(eventHandlers: SSEManagerEvents) {
    if (this.isInitialized) {
      return this.choosePrimaryInstancePromise!;
    }
    this.sseManagerEvents = eventHandlers;

    this.isInitialized = true;
    this._primarySSEManagerInstanceId = undefined;
    this._primaryInstanceCandidates = [];
    this.primaryClientDiscoveryTimeout = undefined;
    this.channel.onmessage = this.onMessage.bind(this);

    this.logger.info(`My instance id is ${this.instanceId}`);
    this.broadcast<SSEMInitPayload>({
      eventType: SSEManagerEvent.InstanceInit,
      myInstanceId: this.instanceId,
    });
    this.choosePrimaryInstancePromise = new Promise(resolve => {
      this.initPromiseConfig = {
        resolve,
        timeoutPromise: setTimeout(() => {
          // when we are still looking for the primary client, do not reject the init promise
          if (this.primaryClientDiscoveryTimeout) {
            return;
          }
          if (this.ignoreMyselfAsPrimaryInstance) {
            this.logger.info(`Others said that I should not be the primary instance`);
            this.ignoreMyselfAsPrimaryInstance = false;
            return;
          }
          this.logger.warn(`SSEManager init timed out after ${Config.Estream.DiscoveryTimeout}ms`);
          this.setPrimaryInstance(this.instanceId);
          this.initPromiseConfig?.resolve();
          this.initPromiseConfig = null;
        }, Config.Estream.DiscoveryTimeout),
      };
    });

    return this.choosePrimaryInstancePromise;
  }

  /**
   * Destroys the broadcast channel, should be called when the app is closed
   */
  destroy(eStreamSubscriptions: ObjectSet<SubscribeOptions>) {
    if (!this.isInitialized) {
      // SSEManager is not yet initialized
      return;
    }
    eStreamSubscriptions.deleteByCondition(subscription =>
      subscription.source.startsWith(`${Keys.EStreamSubscriptionSourcePrefix}${this.instanceId}/`),
    );

    // send a broadcast message, that this instance is destroyed
    this.broadcast<SSEMDestroyPayload>({
      eventType: SSEManagerEvent.InstanceDestroy,
      myInstanceId: this.instanceId,
      eStreamSubscriptions: eStreamSubscriptions.values(),
    });
    this.channel.close();
    this.isDestroyed = true;
  }

  private broadcast<T extends SSEManagerBasePayload>(
    payload: T,
    options?: { messageId?: string; responseId?: string },
  ) {
    const wrapper: BroadcastMessage<T> = {
      source: 'netbroker',
      nbInstance: this.instanceId,
      messageId: options?.messageId || nanoid(),
      responseId: options?.responseId,
      payload,
    };
    if (!this.isDestroyed) {
      this.channel.postMessage(wrapper);
    } else {
      this.logger.error(`Tried to broadcast, but channel is already destroyed`, wrapper);
    }
  }

  private onMessage(event: MessageEvent<any>) {
    const data = event.data as BroadcastMessage<SSEManagerBasePayload>;
    if (event.origin !== window.location.origin || data.nbInstance === this.instanceId) {
      return;
    }
    this.logger.trace(`Received`, data);

    switch (data.payload.eventType) {
      // when other client is asking who is the primary instance
      case SSEManagerEvent.InstanceInit:
        // if we already have a primary instance, let them know there is a primary instance
        if (this._primarySSEManagerInstanceId) {
          this.logger.debug(
            `We already have a primary instance, let other knows: ${this._primarySSEManagerInstanceId}`,
          );
          this.broadcast<SSEMBroadcastPrimaryPayload>({
            eventType: SSEManagerEvent.InstanceAnswer,
            primarySSEManagerId: this._primarySSEManagerInstanceId,
            eStreamConnectionState: this.eStreamConnectionState,
          });
        } else {
          const primaryInstanceCandidate = (data.payload as SSEMInitPayload).myInstanceId;
          this.logger.debug(
            `There is no primary instance, let's register this as a primary instance candidate ${primaryInstanceCandidate}`,
          );
          // register this as a primary instance candidate
          this._primaryInstanceCandidates.push(primaryInstanceCandidate);

          // if we haven't started the primary discovery process, let's start it
          if (!this.primaryClientDiscoveryTimeout) {
            this.logger.trace(`Initiate primary client discovery timeout`);
            // Determine the primary instance
            this.primaryClientDiscoveryTimeout = setTimeout(() => {
              this._primaryInstanceCandidates.push(this.instanceId);
              this.logger.debug(
                `Initiate primary client discovery timeout finished, choosing from ${this._primaryInstanceCandidates.length} candidates`,
              );
              const candidatePrimaryInstance = this._primaryInstanceCandidates.sort((a, b) => a - b)[0];
              this.broadcast<SSEMBroadcastPrimaryPayload>({
                eventType: SSEManagerEvent.InstanceAnswer,
                primarySSEManagerId: candidatePrimaryInstance,
                eStreamConnectionState: this.eStreamConnectionState,
              });
              this.setPrimaryInstance(candidatePrimaryInstance, true);
            }, Config.Estream.DiscoveryTimeout);
          } else {
            // we ignore this instance as primary, since we already started the primary discovery process
            this.broadcast<SSEMBroadcastPrimaryIgnoreYourselfPayload>({
              eventType: SSEManagerEvent.InstanceIgnore,
              instanceId: (data.payload as SSEMInitPayload).myInstanceId,
            });
            this.logger.warn(
              `Primary client discovery timeout already initiated, ignoring instance ${
                (data.payload as SSEMInitPayload).myInstanceId
              }`,
            );
          }
        }
        break;
      case SSEManagerEvent.InstanceIgnore:
        const instanceId = (data.payload as SSEMBroadcastPrimaryIgnoreYourselfPayload).instanceId;
        if (instanceId === this.instanceId) {
          // an instance said to ignore it as a primary instance
          this.ignoreMyselfAsPrimaryInstance = true;
        }
        break;
      case SSEManagerEvent.InstanceAnswer:
        const payload = data.payload as SSEMBroadcastPrimaryPayload;
        // an instance said there is a primary instance
        const primarySSEManagerInstance = payload.primarySSEManagerId;
        this.setPrimaryInstance(primarySSEManagerInstance);
        if (payload.eStreamConnectionState) {
          this.eStreamConnectionState = payload.eStreamConnectionState;
          this.sseManagerEvents?.onConnectionStateUpdate(this.eStreamConnectionState, true);
        }
        if (this.initPromiseConfig) {
          clearTimeout(this.initPromiseConfig.timeoutPromise);
          this.initPromiseConfig.resolve();
        }
        break;
      case SSEManagerEvent.InstanceDestroy:
        if (this._primarySSEManagerInstanceId === (data.payload as SSEMDestroyPayload).myInstanceId) {
          this.logger.info(`Primary instance destroyed, restarting primary discovery process`);
          this.pendingEStreamSubscriptionsFromPreviousPrimaryInstance = (
            data.payload as SSEMDestroyPayload
          ).eStreamSubscriptions;
          this.isInitialized = false;
          this.primaryInstanceSelected = false;
          this.init(this.sseManagerEvents!);
        } else {
          this.sseManagerEvents?.onOtherInstanceDestroyed((data.payload as SSEMDestroyPayload).myInstanceId);
        }
        break;
      case SSEManagerEvent.EStreamConnectionStateUpdate:
        const connectionState = (data.payload as SSEMEStreamConnectionStateUpdatePayload).connectionState;
        this.sseManagerEvents?.onConnectionStateUpdate?.(connectionState, false);
        break;
      case SSEManagerEvent.EStreamTrades:
        const trades = (data.payload as SSEMEStreamTradesPayload).trades;
        this.sseManagerEvents?.onEStreamTradesReceived?.(trades);
        break;
      case SSEManagerEvent.Login:
        const token = (data.payload as SSEMLoginPayload).token;
        this.sseManagerEvents?.onOtherInstanceLoggedIn(token);
        break;
      case SSEManagerEvent.Logout:
        this.sseManagerEvents?.onOtherInstanceLoggedOut();
        break;
      case SSEManagerEvent.EStreamSubscriptionSubscribe:
        this.sseManagerEvents?.onEStreamSubscriptionChange?.(
          'subscribe',
          (data.payload as SSEMEStreamSubscriptionChangePayload).options,
          data.nbInstance,
        );
        break;
      case SSEManagerEvent.EStreamSubscriptionUnsubscribe:
        this.sseManagerEvents?.onEStreamSubscriptionChange?.(
          'unsubscribe',
          (data.payload as SSEMEStreamSubscriptionChangePayload).options,
          data.nbInstance,
        );
        break;
    }
  }

  private setPrimaryInstance(instanceId: number, isByConsensus = false) {
    this.logger.info(
      `Primary instance ${isByConsensus ? 'agreed' : 'selected'}: ${instanceId}${
        instanceId === this.instanceId ? ' (me)' : ''
      }`,
    );
    this._primarySSEManagerInstanceId = instanceId;
    this._primaryInstanceCandidates = [];
    this.primaryClientDiscoveryTimeout = undefined;
    if (this.instanceId === instanceId) {
      this.sseManagerEvents?.onThisInstanceSelectedAsPrimary(
        this.pendingEStreamSubscriptionsFromPreviousPrimaryInstance,
      );
      this.pendingEStreamSubscriptionsFromPreviousPrimaryInstance = undefined;
    }
    this.primaryInstanceSelected = true;

    // TODO start a timer to check if the primary instance is still alive
  }

  broadcastEStreamMessage(trades: Array<EStreamTradeDTO>) {
    this.broadcast<SSEMEStreamTradesPayload>({
      eventType: SSEManagerEvent.EStreamTrades,
      trades,
    });
  }

  broadcastConnectionState(connectionState: ConnectionState) {
    this.broadcast<SSEMEStreamConnectionStateUpdatePayload>({
      eventType: SSEManagerEvent.EStreamConnectionStateUpdate,
      connectionState,
    });
  }

  broadcastLogin(token: string) {
    this.broadcast<SSEMLoginPayload>({
      eventType: SSEManagerEvent.Login,
      token,
    });
  }

  broadcastLogout() {
    this.broadcast<SSEManagerBasePayload>({
      eventType: SSEManagerEvent.Logout,
    });
  }

  broadcastSubscribeToInstruments(options: Array<SubscribeOptions>) {
    this.broadcast<SSEMEStreamSubscriptionChangePayload>({
      eventType: SSEManagerEvent.EStreamSubscriptionSubscribe,
      options,
    });
  }

  broadcastUnsubscribeFromInstruments(options: Array<SubscribeOptions>) {
    this.broadcast<SSEMEStreamSubscriptionChangePayload>({
      eventType: SSEManagerEvent.EStreamSubscriptionUnsubscribe,
      options,
    });
  }

  setEStreamConnectionState(state: ConnectionState) {
    this.eStreamConnectionState = state;
    this.broadcastConnectionState(state);
  }
}

export const sseManager = new SSEManager();
