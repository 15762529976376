import { ButtonBase } from '@mui/material';
import classes from './TextSwitch.module.scss';

export interface NbTextSwitchProps<T> {
  className?: string;
  selectedValue: T;
  left: {
    name: string;
    value: T;
  };
  right: {
    name: string;
    value: T;
  };
  onChange: (value: T) => void;
}

export function NbTextSwitch<T>({ className, selectedValue, left, right, onChange }: NbTextSwitchProps<T>) {
  return (
    <div
      className={`${classes.textSwitch} 
      ${className ? ` ${className}` : ``}
      ${selectedValue === left.value ? classes.textSwitchIsLeftActive : classes.textSwitchIsRightActive}`}
    >
      <ButtonBase
        onClick={() => onChange(left.value)}
        disableRipple
      >
        <div className={`${classes.Text} ${selectedValue === left.value && classes.TextIsActive}`}>Piaci ár</div>
      </ButtonBase>
      <ButtonBase
        onClick={() => onChange(right.value)}
        disableRipple
      >
        <div className={`${classes.Text} ${selectedValue === right.value && classes.TextIsActive}`}>Limit ár</div>
      </ButtonBase>
    </div>
  );
}
