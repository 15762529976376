import { mockedInstrument } from '../mock/mockedInstruments';
import { IndexRowInstrument } from '../state/indexRowStateAtom';
import { getRandomInteger } from '../utils/mockUtils';

export const mockedValues = () =>
  Array.from({ length: 50 }, () => getRandomInteger(100, 200)).map(value => ({
    value,
  }));

export const mockedIndexRowInstruments: Array<IndexRowInstrument> = [
  {
    closePrice: 120,
    instrument: mockedInstrument('OTP'),
    values: mockedValues(),
  },
  {
    closePrice: 120,
    instrument: mockedInstrument('TSLA'),
    values: mockedValues(),
  },
  {
    closePrice: 120,
    instrument: mockedInstrument('TOP'),
    values: mockedValues(),
  },
  {
    closePrice: 120,
    instrument: mockedInstrument('TSM'),
    values: mockedValues(),
  },
  {
    closePrice: 120,
    instrument: mockedInstrument('IBM'),
    values: mockedValues(),
  },

  {
    closePrice: 120,
    instrument: mockedInstrument('AMZN'),
    values: mockedValues(),
  },
  {
    closePrice: 120,
    instrument: mockedInstrument('LRCX'),
    values: mockedValues(),
  },
  {
    closePrice: 120,
    instrument: mockedInstrument('LVS'),
    values: mockedValues(),
  },
  {
    closePrice: 120,
    instrument: mockedInstrument('AA'),
    values: mockedValues(),
  },
  {
    closePrice: 120,
    instrument: mockedInstrument('EDU'),
    values: mockedValues(),
  },
];
