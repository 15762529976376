import { WatchListColumn, WatchListGroup, WatchListGroupId, WatchListInstrument } from '../state/watchListStateAtom';
import { WithoutId } from '../types/withoutId';
import { mockedInstrument } from './mockedInstruments';

export const mockedWatchListGroupId: Array<WatchListGroupId> = [
  {
    id: '1',
    name: 'Hazai részvény',
  },
  {
    id: '2',
    name: 'Külföldi részvény',
  },
  {
    id: '3',
    name: 'ETF',
  },
  {
    id: '4',
    name: 'Certifikát',
  },
];

const watchListInstrumentFields: Array<keyof WithoutId<WatchListInstrument>> = [
  'instrument',
  'lastPrice',
  'change',
  'changePercent',
  'buyPrice',
  'sellPrice',
  'low',
  'high',
  'averagePrice',
  'volumeQuantity',
  'volumeValue',
  'annualLow',
  'annualHigh',
];
export const allColumns: Array<WatchListColumn> = watchListInstrumentFields.map(field => ({ field }));

export const mockedWatchListGroup1: WatchListGroup = {
  id: '1',
  name: 'Hazai részvény',
  columns: allColumns,
  items: [
    {
      id: '1',
      instrument: mockedInstrument('OTP'),
      // lastPrice: 1.7,
      //
      // closePrice: 4,
      //
      // buyPrice: 1.69,
      // sellPrice: 1.68,
      // low: 10,
      // high: 10.69,
      // averagePrice: 10,
      // volumeQuantity: 1.69,
      // volumeValue: 1.69,
      // annualLow: 1.69,
      // annualHigh: 1.69,
    },
    {
      id: '2',
      instrument: mockedInstrument('MOL'),
    },
    {
      id: '3',
      instrument: mockedInstrument('MTELEKOM'),
    },
    {
      id: '4',
      instrument: mockedInstrument('RICHTER'),
    },
    {
      id: '5',
      instrument: mockedInstrument('4IG'),
    },
    {
      id: '6',
      instrument: mockedInstrument('OPUS'),
    },
    {
      id: '7',
      instrument: mockedInstrument('PANNERGY'),
    },
    {
      id: '8',
      instrument: mockedInstrument('WABERERS'),
    },
    {
      id: '9',
      instrument: mockedInstrument('MASTERPLAST'),
    },
    {
      id: '10',
      instrument: mockedInstrument('AUTOWALLIS'),
    },
  ],
};
export const mockedWatchListGroup2: WatchListGroup = {
  id: '2',
  name: 'Külföldi részvény',
  columns: allColumns,
  items: [
    {
      id: '1',
      instrument: mockedInstrument('TSLA'),
    },
    {
      id: '2',
      instrument: mockedInstrument('GOOG'),
    },
    {
      id: '3',
      instrument: mockedInstrument('WMT'),
    },
    {
      id: '4',
      instrument: mockedInstrument('AAPL'),
    },
    {
      id: '5',
      instrument: mockedInstrument('AMZN'),
    },
    {
      id: '6',
      instrument: mockedInstrument('META'),
    },
    {
      id: '7',
      instrument: mockedInstrument('MSFT'),
    },
    {
      id: '8',
      instrument: mockedInstrument('XOM'),
    },
    {
      id: '9',
      instrument: mockedInstrument('JPM'),
    },
    {
      id: '10',
      instrument: mockedInstrument('LLY'),
    },
  ],
};
export const mockedWatchListGroup3: WatchListGroup = {
  id: '3',
  name: 'ETF',
  columns: allColumns,
  items: [
    {
      id: '1',
      instrument: mockedInstrument('SXR8'),
    },
    {
      id: '2',
      instrument: mockedInstrument('SWDA'),
    },
    {
      id: '3',
      instrument: mockedInstrument('EMIM'),
    },
    {
      id: '4',
      instrument: mockedInstrument('IUSZ'),
    },
    {
      id: '5',
      instrument: mockedInstrument('SXRV'),
    },
    {
      id: '6',
      instrument: mockedInstrument('VWCE'),
    },
    {
      id: '7',
      instrument: mockedInstrument('EXW1'),
    },
    {
      id: '8',
      instrument: mockedInstrument('EXS1'),
    },
    {
      id: '9',
      instrument: mockedInstrument('EUNK'),
    },
    {
      id: '10',
      instrument: mockedInstrument('XD9U'),
    },
  ],
};
export const mockedWatchListGroup4: WatchListGroup = {
  id: '4',
  name: 'Certifikát',
  columns: allColumns,
  items: [
    {
      id: '1',
      instrument: mockedInstrument('EBOTPTL08'),
    },
    {
      id: '2',
      instrument: mockedInstrument('EBMOLTL15'),
    },
    {
      id: '3',
      instrument: mockedInstrument('EBBUXTL42'),
    },
    {
      id: '4',
      instrument: mockedInstrument('EBEURHUFTL54'),
    },
    {
      id: '5',
      instrument: mockedInstrument('EBWTIOIL04'),
    },
    {
      id: '6',
      instrument: mockedInstrument('EBGOLD03'),
    },
    {
      id: '7',
      instrument: mockedInstrument('EBSILVER03'),
    },
    {
      id: '8',
      instrument: mockedInstrument('EBSPTL45'),
    },
    {
      id: '9',
      instrument: mockedInstrument('EBBMWTL06'),
    },
    {
      id: '10',
      instrument: mockedInstrument('EBHENHUBTL81'),
    },
  ],
};
