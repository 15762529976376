import widgetScreen from '../../../assets/widget_screens/widget_trade-ticket.png';
import { WidgetElement } from '../../../shared/types/widgetElement';

export const TradeTicketWidget = (_: WidgetElement) => {
  return (
    <>
      <img
        src={widgetScreen}
        alt="simple trade"
      />
    </>
  );
};
