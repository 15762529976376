import { useLocale } from '../../../context/NbLocalizationContext';
import { NbButton } from '../../../shared/components/button/Button';
import { useAccounts } from '../../../shared/services/useAccounts';
import { Account, Client } from '../../../shared/state/accountsAtom';
import classes from './AccountsPopoverContent.module.scss';

export interface AccountsPopoverContentProps {
  clients: Array<Client>;
  onClose: () => void;
}

export const AccountsPopoverContent = ({ clients, onClose }: AccountsPopoverContentProps) => {
  const { translation } = useLocale();
  const { selectAccount, selectedAccount, selectAllAccounts } = useAccounts();

  const selectAccountCategory = (client: Client, account: Account) => {
    selectAccount(client, account);
    onClose();
  };

  const selectAll = () => {
    selectAllAccounts();
    onClose();
  };

  return (
    <>
      <div className={classes.accountsHeader}>
        <NbButton
          color={selectedAccount.isAllSelected ? 'primary' : 'secondary'}
          className={classes.accountsHeaderAllButton}
          onClick={selectAll}
        >
          {translation.header.allAccounts}
        </NbButton>
      </div>
      <div className={classes.accounts}>
        {clients.map(client => (
          <div
            key={client.clientId}
            className={classes.accountWrapper}
          >
            <div className={classes.account}>
              <p className={classes.accountName}>{client.clientName}</p>
              <p className={classes.accountId}>{client.clientId}</p>
              <div className={classes.accountButtons}>
                {client.accounts.map(account => (
                  <NbButton
                    key={account.accountCategory}
                    color={
                      !selectedAccount.isAllSelected &&
                      selectedAccount.account.accountCategory === account.accountCategory &&
                      selectedAccount.client.clientId === client.clientId
                        ? 'primary'
                        : 'secondary'
                    }
                    onClick={() => selectAccountCategory(client, account)}
                  >
                    {account.accountCategory}
                  </NbButton>
                ))}
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};
