import { SessionInit200Response } from '@eih-netbroker/api-session';
import MockAdapter from 'axios-mock-adapter';
import { axiosInstance } from '../../services/useResource';
import { mockedAccounts } from '../mockedAccounts';
import {
  mockedAccountCategoryTypes,
  mockedAccountProxyTypes,
  mockedInstrumentCategoryTypes,
  mockedMarketDataProviders,
  mockedSubscriptionPackegesProxyTypes,
} from '../mockedDictionaries';

var mock = new MockAdapter(axiosInstance, { onNoMatch: 'passthrough', delayResponse: 1000 });

mock.onPost(new RegExp('https://api.eih-netbroker.com/sessions')).reply(_ => {
  const response: SessionInit200Response = {
    dictionaries: {
      accountCategoryTypes: mockedAccountCategoryTypes,
      accountProxyTypes: mockedAccountProxyTypes,
      instrumentCategoryTypes: mockedInstrumentCategoryTypes,
      marketDataProviders: mockedMarketDataProviders,
      subscriptionPackegesProxyTypes: mockedSubscriptionPackegesProxyTypes,
    },
    accounts: mockedAccounts,
    consents: [],
  };
  return [200, response];
});
