import { useState } from 'react';
import { InstrumentSearchAutocomplete } from '../../../shared/components/instrument-search-autocomplete/InstrumentSearchAutocomplete';
import { WidgetElement } from '../../../shared/types/widgetElement';
import { useWidgetGroup } from '../services/useWidgetGroup';
import { useWidgetInstrument } from '../services/useWidgetInstrument';
import { BETFillListGrid } from './BETFillListGrid';
import classes from './BETFillListWidget.module.scss';

export const BetFillListWidget = (props: WidgetElement) => {
  const widgetGroup = useWidgetGroup(props.groupId);
  const { instrument, updateInstrument } = useWidgetInstrument(widgetGroup);
  const [isLoading] = useState(false);

  return (
    <div className={classes.fillList}>
      <div className={classes.fillListHeader}>
        <InstrumentSearchAutocomplete
          isPopperWide
          onSelectInstrument={updateInstrument}
          selection={instrument}
        />
      </div>
      {!isLoading && <BETFillListGrid />}
    </div>
  );
};
