import { GridRenderCellParams } from '@mui/x-data-grid';
import { GridColDef } from '@mui/x-data-grid-pro';
import { ReactComponent as BuyIcon } from '../../../assets/svg/buy.svg';
import { ReactComponent as SellIcon } from '../../../assets/svg/sell.svg';
import { useLocale } from '../../../context/NbLocalizationContext';
import { useFormatter } from '../../../shared/services/useFormatter';
import { TradeType } from '../../../shared/types/tradeType';
import { WithoutId } from '../../../shared/types/withoutId';
import { LogType, OrderLog } from '../states/orderLogAtom';

export const EventType = (event: LogType) => {
  const { translation } = useLocale();

  const translations = {
    [LogType.Create]: translation.eventType.create,
    [LogType.Edit]: translation.eventType.edit,
    [LogType.Cancel]: translation.eventType.cancel,
    [LogType.Fulfillment]: translation.eventType.fulfillment,
    [LogType.Rejection]: translation.eventType.rejection,
  };

  return translations[event];
};

export const orderLogItemFields: Array<keyof WithoutId<OrderLog>> = [
  'event',
  'tradeType',
  'instrument',
  'orderId',
  'account',
  'date',
];

export type OrderLogColumn = {
  field: keyof WithoutId<OrderLog>;
};

export const allColumns: Array<OrderLogColumn> = orderLogItemFields.map(field => ({ field }));

export const useOrderLogColumns = () => {
  const { translation } = useLocale();
  const { formatDateAndTime } = useFormatter();

  const getOrderLogColumn = (column: keyof WithoutId<OrderLog>): GridColDef<OrderLog> => {
    const baseColDef: GridColDef<OrderLog> = {
      field: column,
      headerName: translation.orderLog.grid[column],
      sortable: false,
      disableColumnMenu: true,
    };

    switch (column) {
      case 'event':
        return {
          ...baseColDef,
          width: 95,
          align: 'left',
          renderCell: (params: GridRenderCellParams<OrderLog>) => {
            return <span>{EventType(params.row.event)}</span>;
          },
        };
      case 'tradeType':
        return {
          ...baseColDef,
          width: 65,
          align: 'center',
          renderCell: (params: GridRenderCellParams<OrderLog>) => {
            return (
              <span>
                {params.row.tradeType === TradeType.Buy ? (
                  <BuyIcon />
                ) : params.row.tradeType === TradeType.Sell ? (
                  <SellIcon />
                ) : (
                  ''
                )}
              </span>
            );
          },
        };
      case 'instrument':
        return {
          ...baseColDef,
          width: 100,
          align: 'left',
          renderCell: (params: GridRenderCellParams<OrderLog>) => {
            return <span>{params.row.instrument.ticker}</span>;
          },
        };
      case 'orderId':
        return {
          ...baseColDef,
          width: 100,
          align: 'left',
          renderCell: (params: GridRenderCellParams<OrderLog>) => {
            return <span>{params.row.orderId}</span>;
          },
        };
      case 'account':
        return {
          ...baseColDef,
          width: 100,
          align: 'center',
          renderCell: (params: GridRenderCellParams<OrderLog>) => {
            return <span>{params.row.account}</span>;
          },
        };
      case 'date':
        return {
          ...baseColDef,
          width: 150,
          align: 'center',
          renderCell: (params: GridRenderCellParams<OrderLog>) => {
            return <span>{formatDateAndTime(params.row.date)}</span>;
          },
        };

      default:
        throw new Error(`Invalid column ${column}`);
    }
  };

  return { getOrderLogColumn };
};
