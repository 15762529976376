import { useCallback } from 'react';
import { useRecoilState } from 'recoil';
import { orderLogsAtom } from '../../modules/trading/states/orderLogAtom';
import { mockedOrderLogs } from '../mock/mockedOrderLogs';
import { resolveLater } from '../utils/mockUtils';

export const useOrderLogs = () => {
  const [orderLogs, setOrderLogs] = useRecoilState(orderLogsAtom);

  const loadOrderLogs = useCallback(async (): Promise<void> => {
    const loadedOrderLogs = await resolveLater(mockedOrderLogs);
    setOrderLogs(loadedOrderLogs);
  }, [setOrderLogs]);

  return { orderLogs, loadOrderLogs };
};
