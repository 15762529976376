const translation = {
  app: {
    lang: 'hu-HU',
    formatDate: 'yyyy-MM-dd',
    formatTime: 'HH:mm:ss',
    formatDateAndTime: 'yyyy.MM.dd HH:mm:ss',
    formatDateTime: 'yyyy-MM-dd HH:mm:ss',
    consent: {
      header: 'Ez a weboldal sütiket használ',
      textHTML: `<p>A süti egyedi azonosítót ad a munkamenethez. Ez lehetővé teszi a weboldal számára, hogy statisztikai célokra adatokat gyűjtsön a látogatók viselkedéséről. _Meghatározza, hogy a felhasználó navigációját regisztrálni kell-e egy bizonyos statisztikában. _Regisztrálja a látogatók weboldal-viselkedésének adatait. Ezt a belső elemzéshez és a weboldal optimalizálásához használjuk._ Az aktuális webhely SEO rangsorát észleli._</p>
<p>A Hotjar nevű külső eszközzel azt vizsgáljuk anonim módon, hogy a felhasználók mennyire képesek valóban jól használni a weboldalt. Segítségével úgy tudjuk fejleszteni tartalmainkat, hogy azokkal könnyebben és akár érthetőbben tudjanak a látogatók kapcsolatba kerülni szolgáltatásainkkal.</p>
<p>A HotJar cookie-t helyez el a böngészőben. Ezt a sütit arra használják, hogy megszámolják, hányszor látogattak meg egy webhelyet különböző látogatók - ez úgy történik, hogy a látogatónak azonosítót rendelünk, így a látogató nem duplikálódik.</p>
<p>Ez a szolgáltatás egy harmadik fél statisztikájának és elemzésének része.</p>`,
      necessary: 'Elengedhetetlen',
      statistics: 'Statisztikai',
      allowAll: 'Minden süti engedélyezése',
      allowSelected: 'Kiválasztottak engedélyezése',
      reject: 'Elutasítom',
      details: 'részletek',
    },
    estream: {
      unavailable: 'Az árfolyam szolgáltatás jelenleg nem elérhető',
      connectionInitFailed: `A$market$ piachoz kapcsolódó árfolyam szolgáltatás jelenleg nem elérhető! A rendszerben a legutóbbi elérhető árak kerülnek megjelenítésre!`,
      connectionDisconnected: `A$market$ piachoz kapcsolódó árfolyam szolgáltatás jelenleg nem elérhető! A rendszerben a legutóbbi elérhető árak kerülnek megjelenítésre!`,
      connectionReconnected: `A$market$ piachoz kapcsolódó árfolyam szolgáltatás ismét elérhető!`,
    },
  },
  general: {
    login: 'Belépés',
    logout: 'Kilépés',
    cancel: 'Mégsem',
    save: 'Mentés',
    confirm: `Megerősít`,
    confirmation: `Megerősítés`,
    send: `Beküldés`,
    error: {
      unhandledException: 'Váratlan hiba történt ($message$)',
      serverError: 'Ismeretlen hiba történt',
    },
    toast: {
      warningTitle: 'Figyelmeztetés',
      errorTitle: 'Hiba',
      successTitle: 'Siker',
    },
    grid: {
      noRowsLabel: 'Nincs adat',
      footerTotalRows: 'Összes sor:',
      footerRowSelectedSingular: 'kiválasztott sor',
    },
    emptySelectAll: 'Összes',
  },
  login: {
    loginTitle: `NetBroker Belépés`,
    user: `Felhasználónév`,
    account: `Számlaszám`,
    password: `Jelszó`,
    help: `Segítség a belépéshez`,
    noAccountYet: `Nincs még számlád?`,
    erste: `Erste Befektetési Zrt.`,
    contact: `Kapcsolat`,
    legalNotice: `Jogi nyilatkozat`,
    technicalInfo: `Technikai információk`,
    registration: `Regisztráció`,
    details: `Részletek`,
    registrationTitle: `NetBroker`,
    registrationSubtitle: `Real-time online kereskedés a nap 24 órájában`,
    item1: `Azonnali elérés hazai és külföldi részvényekhez`,
    item2: `Számlaműveletek, számlainformációk`,
    item3: `Real-time árfolyamok`,
    item4: `Broker életérzés testközelben`,
  },
  header: {
    allAccounts: 'Minden számla',
    invoiceValue: 'Számlaérték',
    floatingResult: 'Lebegő eredmény',
    cashBalance: 'Készpénz egyenleg',
    cash: {
      title: 'Készpénz',
      exchange: `Devizaváltás`,
      total: `Összesen`,
    },
    exchange: {
      title: 'Árfolyam szolgáltatás',
      realTime: 'Valósidejű',
      noAccess: 'Nincs kapcsolat',
      delay: `$min$ perc késleltetés`,
      error: `A szolgáltatás információ nem elérhető`,
    },
  },
  search: {},
  instrument: {
    search: {
      title: `Keresés`,
      instruments: `Termékek`,
      noResult: `Nincs találat`,
    },
  },
  menu: {
    dashboard: 'Kezdőlap',
    trading: 'Kereskedés',
    stockTrading: 'Tőzsdei kereskedés',
    watchList: 'Figyelőlista',
    chart: 'Chart',
    portfolio: 'Portfólió',
    settings: 'Beállítások',
    userSettings: `Felhasználói beállítások`,
    notificationsSettings: `Értesítési beállítások`,
    dataServiceManagement: `Adatszolgáltatás kezelése`,
    logout: `Kilépés`,
  },
  widget: {
    header: {
      SimpleTradeTicket: 'Villám kötjegy',
      TradeTicket: 'Teljes kötjegy',
      OrderBook: 'Ajánlati könyv',
      ProductDetails: 'Termék adatlap',
      WatchList: 'Figyelőlista',
      Chart: 'Grafikon',
      NetPositions: 'Nettó pozíciók',
      OpenOrders: 'Nyitott megbízások',
      BETFillList: 'BÉT kötéslista',
      OpenPositions: 'Nyitott pozíciók',
      Order: 'Megbízás',
      PriceAlerts: 'Árfolyamértesítők',
      OrderLog: `Megbízási napló`,
    },
  },
  watchList: {
    addNewList: `Új lista létrehozása`,
    addNewInstrument: `Instrumentum hozzáadása`,
    newListPlaceholder: `Új lista címe`,
    searchNewInstrumentPlaceholder: `Új termék keresése`,
    editLists: `Listák szerkesztése`,
    confirmDelete: `Biztosan törlöd a listát?`,
    confirmClearList: `Biztosan kiüríted a listát?`,
    editor: {
      listIsAlreadyAdded: `Már létezik ilyen nevű lista`,
      listIsCreated: `Új figyelőlista sikeresen hozzáadva!`,
      listIsUpdated: `Figyelőlista frissítve!`,
      itemIsCreated: `Ez az instrumentum már hozzá van adva!`,
      instrumentCannotBeAdded: `Nem sikerült hozzáadni a terméket a figyelőlistához`,
      confirmDeleteInstrument: `Biztosan törlöd a $name$ terméket a figyelőlistáról?`,
      instrumentCannotBeDeleted: `Nem sikerült törölni a terméket a figyelőlistáról`,
    },
    watchListGroupsReorderFailed: `Nem sikerült elmenteni az új sorrendet`,
    watchListGroupWarning: `A listák száma elérte a maximális 10 darabot.`,
    watchlistInstrumentWarning: `A lista elemszáma elérte a maximális 100 darabot.`,
  },
  watchListTab: {
    domesticStock: `Hazai részvény`,
    internationalStock: `Külföldi részvény`,
    eft: `ETF`,
    certificate: `Certifikát`,
  },
  watchListGrid: {
    instrumentDefault: `Instrumentum adatlap`,
    change: `Vált.`,
    description: `Description`,
    add: `Hozzáadás`,
    notification: `Értesítő beállítása`,
    chart: `Chart megnyitása`,
    delete: `Törlés`,
    more: `Több`,
    grid: {
      instrument: `Termék`,
      closePrice: `Záró ár`,
      lastPrice: `Ut. ár`,
      change: `Vált.`,
      changePercent: `Vált. %`,
      buyPrice: `V. ár`,
      sellPrice: `E. ár`,
      low: `Napi min.`,
      high: `Napi max.`,
      averagePrice: `Napi átl.`,
      volumeQuantity: `Forg. (db)`,
      volumeValue: `Forg. (érték)`,
      annualLow: `52 heti min.`,
      annualHigh: `52 heti max.`,
    },
    context: {
      details: `Instrumentum adatlap`,
      order: `Megbízás`,
      notification: `Értesítő beállítása`,
      chart: `Chart megnyitása`,
      deleteRow: `Sor törlése`,
    },
  },
  simpleTradeTicket: {
    sell: 'Eladás',
    buy: 'Vétel',
    marketPrice: 'Piaci ár',
    limitPrice: 'Limit ár',
    quantity: 'Mennyiség',
    stock: 'Készlet',
    middlePrice: 'Közép ár',
    expectedCost: 'Várható költség',
    totalValue: 'Összérték',
  },
  orderbook: {
    quantity: 'Mennyiség',
    sell: 'Eladás',
    buy: 'Vétel',
    grid: {
      saleQuantity: 'Mennyiség',
      salePrice: 'Eladás',
      purchasePrice: 'Vétel',
      purchaseQuantity: 'Mennyiség',
    },
  },
  productDetails: {
    tradingData: 'Kereskedési adatok',
    fundamentalIndicators: 'Fundamentális mutatók',
    selectInstrument: `Nincs kiválasztva termék`,
    averagePriceInfo: 'VWAP, forgalommal súlyozott átlagár',
    productDetailsFields: {
      high: 'Napi maximum',
      min: 'Min.',
      max: 'Max.',
      openPrice: 'Nyitó ár',
      low: 'Napi minimum',
      averagePrice: 'Átlagár',
      lastPrice: 'Utolsó kötés',
      previousClose: 'Előző záró',
      dailyChange: 'Napi változás (HUF/EUR/USD)',
      dailyChangePercent: 'Napi változás (%)',
      YTDChangePercent: 'YTD árfolyam változás (%)',
      annualLow: '52 heti minimum',
      annualHigh: '52 heti maximum',
      volumeQuantity: 'Forgalom (db)',
      volumeValue: 'Forgalom (HUF)',
      monthlyAverageVolume: '30 napos átlagforgalom',
      marketCapitalization: 'Piaci kapitalizáció',
      earningsPerShareTTM: 'EPS (TTM)',
      priceToBookValue: 'P/BV',
      priceToEarnings: 'P/E (Várakozás)',
      priceToEarningsTTM: 'P/E (TTM)',
      priceToSalesTTM: 'P/S (TTM)',
      dividend: 'Osztalék',
      dividendYield: 'Osztalékhozam',
      dividendYield5Years: '5 éves osztalékhozam',
      EBITDA: 'EBITDA',
      ROE: 'ROE',
      ROA: 'ROA',
    },
    instrumentFields: {
      isin: 'ISIN',
      instrumentCategory: 'Termék kategória',
      marketCode: 'Piac',
      currencyCode: 'Devizanem',
    },
    high: 'Napi maximum',
    highShort: 'Napi max.',
    instrumentData: 'Általános adatok',
    min: 'Min.',
    max: 'Max.',
    openPrice: 'Nyitó ár',
    low: 'Napi minimum',
    lowShort: 'Napi min.',
    averagePrice: 'Átlagár',
    lastPrice: 'Utolsó kötés',
    previousClose: 'Előző záró',
    dailyChange: 'Napi változás (HUF/EUR/USD)',
    dailyChangePercent: 'Napi változás (%)',
    YTDChangePercent: 'YTD árfolyam változás (%)',
    annualLow: '52 heti minimum',
    annualHigh: '52 heti maximum',
    volumeQuantity: 'Forgalom (db)',
    volumeValue: 'Forgalom (HUF)',
    monthlyAverageVolume: '30 napos átlagforgalom',
    marketCapitalization: 'Piaci kapitalizáció',
    earningsPerShareTTM: 'EPS (TTM)',
    priceToBookValue: 'P/BV',
    priceToEarnings: 'P/E (Várakozás)',
    priceToEarningsTTM: 'P/E (TTM)',
    priceToSalesTTM: 'P/S (TTM)',
    dividend: 'Osztalék',
    dividendYield: 'Osztalékhozam',
    dividendYield5Years: '5 éves osztalékhozam',
    EBITDA: 'EBITDA',
    ROE: 'ROE',
    ROA: 'ROA',
    isin: 'ISIN',
    instrumentCategory: 'Termék kategória',
    marketCode: 'Piac',
    currencyCode: 'Devizanem',
  },
  openPositions: {
    openPositions: `Nyitott pozíciók`,
    all: `Összes`,
    grid: {
      instrument: `Termék`,
      quantity: `Menny.`,
      averagePrice: `Beker. átlagár`,
      costBasis: `Bekerülési érték`,
      marketValue: `Piaci érték`,
      dailyPnL: `Napi P&L`,
      unrealizedPnL: `Össz P&L`,
      unrealizedPnLPerc: `Össz P&L %`,
    },
    confirmationSubtitle: `Biztosan zárja az összes nyitott pozícióját?`,
    confirmationText: `Zárni fogja az összes nyitott pozícióját, beleértve az enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur.`,
    moreInfo: ` További információ`,
    sureText: `Írja be az alábbi mezőbe a BIZTOS szót a megerősítéshez`,
    surePlaceholder: `BIZTOS?`,
    sure: 'biztos',
    closeAll: `Összes zárása`,
    notes: `Megjegyzés`,
    order: `Megbízás`,
    setColumns: `Oszlopok beállítása`,
  },
  orderModal: {
    order: `Megbízás`,
    transactionType: `Ügylet típusa`,
    orderType: `Megbízás típusa`,
    quantity: `Mennyiség`,
    limit: `Limitár`,
    stopPrice: `Aktiválási ár`,
    timeInForce: `Érvényesség`,
    stockValuation: `Készletértékelés`,
    tradingRestrictions: `Kereskedési szakasz`,
    exchange: `Devizaváltás`,
    sell: 'Eladás',
    buy: 'Vétel',
    max: `Max`,
    createOrderSuccess: 'Megbízás sikeresen leadva',
  },
  noteModal: {
    note: `Jegyzet`,
    lastSave: `Utolsó mentés`,
    placeholder: `Jegyzet írása`,
  },
  datePicker: {
    defaultFormat: 'yyyy.MM.dd',
  },
  orderConfirm: {
    rows: {
      accountName: 'Terhelendő számla',
      instrumentName: 'Termék neve',
      isin: 'ISIN',
      currencyCode: 'Devizanem',
      orderType: 'Megbízás típusa',
      orderTypeValues: {
        limit: 'Limit',
        stopLimit: 'Stop limit',
        market: 'Piaci',
        stopMarket: 'Stop piaci',
        oco: 'OCO',
      },
      tradeType: 'Ügylet típusa',
      tradeTypeValues: {
        buy: 'Vétel',
        sell: 'Eladás',
      },
      quantity: 'Mennyiség',
      exchangeRate: 'Árfolyam',
      stopActivationPrice: 'Aktiválási ár',
      expiryType: 'Érvényesség',
      expiryTypeValues: {
        day: 'Nap',
        gtd: 'Getting Things Done',
        gtc: 'Viszavonásig',
      },
      tradingSession: 'Kereskedési szakasz',
      tradingSessionValues: {
        intraDay: 'Nyító szakasz',
      },
      price: 'Megbízás értéke',
      dividend: 'Osztalék',
      transactionSum: 'Tranzakció összértéke',
    },
    preliminaryExpenses: 'Előzetes költségkimutatás',
  },
  numericFormat: {
    thousandSeparator: ' ',
    decimalSeparator: ',',
  },
  BETFillList: {
    grid: {
      instrument: `Termék`,
      price: `Árfolyam`,
      quantity: `Mennyiség`,
      time: `Idő`,
    },
  },
  openOrders: {
    grid: {
      instrument: `Termék`,
      tradeType: `Ügylet típus`,
      quantity: `Menny.`,
      price: `Ár`,
      stopPrice: `Aktiválási ár`,
      orderType: `Megbízás típusa`,
      expiryType: `Érvényesség`,
      state: `Státusz`,
    },
    delete: `Összes visszavonása`,
    confirmDelete: `Biztosan visszavonja ezt a nyitott megbízását?`,
    confirmDeleteAll: `Biztosan visszavonja az összes nyitott megbízását?`,
    approve: `Visszavonás`,
    approveAll: `Összes visszavonása`,
    setColumns: `Oszlopok beállítása`,
    notes: `Megjegyzés`,
    order: `Megbízás`,
    successCancelAllOrders: `Összes nyitott megbízás visszavonva`,
    successCancelOrder: `Megbízás visszavonva`,
  },
  tradeTypeOptions: {
    buy: 'Vétel',
    sell: 'Eladás',
  },
  orderTypeOptions: {
    limit: `Limit`,
    stopLimit: `Stop limit`,
    market: `Piaci`,
    stopMarket: `Stop piaci`,
    oco: `OCO`,
  },
  inventoryManagmentMethodOptions: {
    FIFO: `FIFO`,
    LIFO: `LIFO`,
    HIFO: `HIFO`,
    LOFO: `LOFO`,
  },
  tradingSessionOptions: {
    preMarket: `Elő piac`,
    afterMarket: `Utó piac`,
    opening: `Nyitó szakasz`,
    open: `Nyitó szakasz`,
    closing: `Záró szakasz`,
    auction: `Aukció`,
  },
  expiryOptions: {
    day: `Nap`,
    gtd: 'Dátumig',
    gtc: 'Viszavonásig',
  },
  orderStateOptions: {
    'pre-submitted': 'Előkészített',
    submitted: 'Beküldött',
    active: 'Aktív',
    revoked: 'Visszavont',
    rejected: 'Visszautasított',
    partfilled: 'Részteljesített',
    filled: 'Teljesített',
  },
  alertCenter: {
    onlyUnreads: `Csak olvasatlanok`,
    categories: {
      event: 'Események',
      promotion: 'Promóciók',
      technical: 'Technikai',
    },
    markAllAsRead: 'Összes megjelölése olvasottként',
    notificationsSettings: 'Értesítések beállításai',
    noNewAlert: 'Az elmúlt 7 napban nem érkezett új értesítés',
    noUnreadAlert: 'Nincs olvasatlan üzenet',
  },
  priceAlerts: {
    grid: {
      instrument: `Termék`,
      trigger: `Aktiválás feltétele`,
      price: `Árfolyamszint`,
      sentiment: `Szentiment`,
    },
    editAlert: `Riasztás módosítása`,
    deleteAlert: `Riasztás törlése`,
    modal: {
      title: `Árfolyamértesítő`,
      stopPriceCondition: `Aktiválás feltétele`,
      exchangeRate: `Árfolyamszint`,
      sentiment: `Szentiment`,
      note: `Megjegyzés`,
      character: `karakter`,
      exchangeRateManagement: `Árfolyamértesítések kezelése`,
      info: `Az AAPL árfolyama kisebb vagy egyenlő mint 160, akkor aktiválásra kerül az árfolyamértesítő`,
      LessThanEqual: `≤ Kisebb vagy egyenlő`,
      GreaterThanEqual: `≥ Nagyobb vagy egyenlő`,
    },
  },
  orderLog: {
    orderLog: `Megbízási napló`,
    grid: {
      event: `Esemény`,
      tradeType: `Ügylet típ.`,
      instrument: `Instrumentum`,
      orderId: `Azonosító`,
      account: `Számla`,
      date: `Idő`,
      subElements: `Alelemek`,
      instrumentName: `Instrumentum neve`,
      tradeTypeFull: `Ügylet típusa`,
      orderType: `Megbízás típusa`,
      price: `Ár`,
      quantity: `Mennyiség`,
      validity: `Érvényesség`,
      stopPrice: `Aktiválási ár`,
    },
    parameters: `Megbízás paraméterei`,
    rejectReason: `Visszautasítás oka`,
  },
  eventType: {
    create: `Létrehozás`,
    edit: `Módosítás`,
    cancel: `Visszavonás`,
    fulfillment: `Teljesülés`,
    rejection: `Visszautasítás`,
  },
  portfolio: {
    portfolio: `Portfólió`,
  },
  portfolioComposition: {
    title: `Portfólió összetétel`,
    allAccounts: `Minden számla`,
    accounts: `Számlák`,
    instrumentClass: `Eszközosztály`,
    investmentLoan: `Befektetési hitel`,
    grid: {
      instrument: `Ticker`,
      name: `Név`,
      quantity: `Mennyiség`,
      investmentAmount: `Befektetés összege`,
      pNl: `Nominális P&L`,
      pNlPercentage: `P&L %`,
      valueInHuf: `Készlet érték (HUF)`,
      value: `Készlet érték (deviza)`,
      weight: `Súly`,
    },
    gridAction: `Megbízás`,
    inventoryCannotBeTraded: `Offline csatornán kereskedett termék`,
  },
  dashboard: {
    marketHint: {
      title: `Erste Market Tipp`,
      buttonText: `Felfedés`,
      actionButtonText: `Megkötöm`,
      relatedAnalysis: `Kapcsolódó elemzés`,
      openExchangeRate: `Nyitási árfolyam`,
      openDate: `Nyitás dátuma`,
      goalExchangeRate: `Célárfolyam`,
      stopExchangeRate: `Stop árfolyam`,
    },
    carousel: {
      apply: `Jelentkezés`,
      details: `Részletek`,
    },
  },
};

export default translation;
