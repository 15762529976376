import { User } from '../types/User';
import { wait } from '../utils/mockUtils';
import { BaseResource } from './base.resource';

export class AuthResource extends BaseResource {
  // Name has to be overriden, since the build job renames it to 'r' and
  // every resource class will have the same name
  // @ts-ignore
  static readonly name = 'AuthResource';

  async login(): Promise<User> {
    const user: User = { name: new Date().toISOString() };
    localStorage.setItem(`nn-user`, JSON.stringify(user));
    await wait();
    return user;
  }

  async getUser(): Promise<User | never> {
    await wait();
    const rawUser = localStorage.getItem(`nn-user`);
    if (!rawUser || rawUser.trim().length === 0) {
      throw new Error('User is not logged in');
    }

    return JSON.parse(rawUser) as User;
  }

  /**
   * Removes the nn-user from local storage
   */
  async logout(): Promise<void> {
    localStorage.removeItem(`nn-user`);
  }
}
