/**
 * Set which uses an id function to determine if an item is unique.
 */
export class ObjectSet<T extends {}, K extends string | number = string> {
  protected idSet: Set<K> = new Set<K>();
  protected items = new Array<T>();

  constructor(private readonly idFunction: (item: T) => K, initialItems?: Array<T>) {
    if (initialItems) {
      initialItems.forEach(this.add.bind(this));
    }
  }

  add(item: T): boolean {
    if (!this.has(item)) {
      this.idSet.add(this.idFunction(item));
      this.items.push(item);
      return true;
    } else {
      return false;
    }
  }

  addAndClone(item: T | T[]): ObjectSet<T, K> {
    if (Array.isArray(item)) {
      const hasAnyNewItem = item.every(i => this.add(i));
      if (hasAnyNewItem) {
        return new ObjectSet<T, K>(this.idFunction, this.items);
      }
      return this;
    } else {
      if (this.add(item)) {
        return new ObjectSet<T, K>(this.idFunction, this.items);
      }
      return this;
    }
  }

  overwrite(item: T): void {
    if (this.has(item)) {
      this.delete(item);
    }
    this.add(item);
  }

  overWriteAndClone(item: T | T[]): ObjectSet<T, K> {
    if (Array.isArray(item)) {
      item.forEach(i => this.overwrite(i));
    } else {
      this.overwrite(item);
    }
    return new ObjectSet<T, K>(this.idFunction, this.items);
  }

  /**
   * Updates or creates an item in the set.
   * @param idOrItem The id of the item to update or the item itself
   * @param update How to update the item
   * @param create How to create the item if it doesn't exist
   * @deprecated use setX instead
   * @example
   *
   * const objectSet = new ObjectSet<{id: number, name: string}, number>(i => i.id);
   *
   * if (isMale) {
   *   objectSet.set(324, {sex: 'Male'}, {id: 324, name: 'John', sex: 'Male' });
   * }
   *
   * // or
   * objectSet.set(31, updatedItem => ({...updatedItem, sex: 'Male'}), {id: 34, sex: 'Male'});
   *
   */
  set(idOrItem: number | string | T, update: Partial<T> | ((updatedItem: T) => T), create: T | (() => T)) {
    const itemId = typeof idOrItem === 'string' || typeof idOrItem === 'number' ? idOrItem : this.idFunction(idOrItem);
    const existingItemInd = this.items.findIndex(i => this.idFunction(i) === itemId);
    if (existingItemInd > -1) {
      const newItem: T =
        typeof update === 'function'
          ? update(this.items[existingItemInd])
          : { ...this.items[existingItemInd], ...update };
      if (this.idFunction(newItem) !== itemId) {
        throw new Error(`Item id cannot be replaced during a set!`);
      }
      this.items.splice(existingItemInd, 1, newItem);
      return true;
    } else {
      const newItem: T = typeof create === 'function' ? (create as () => T)() : create;
      this.add(newItem);
    }
  }

  /**
   * Updates or creates an item in the set.
   * @param idOrItem The id of the item to update or the item itself
   * @param create How to create the item if it doesn't exist
   * @param update How to update the item or the partial that should be merged
   * @example
   *
   * const objectSet = new ObjectSet<{id: number, name: string}, number>(i => i.id);
   *
   * if (isMale) {
   *   objectSet.set(324, {id: 324}, {sex: 'Male'});
   * }
   *
   * // or
   * objectSet.set(31, {id: 34}, updatedItem => ({...updatedItem, sex: 'Male'}));
   */
  setX(idOrItem: number | string | T, create: T | (() => T), update: Partial<T> | ((updatedItem: T) => T)) {
    const itemId: K =
      typeof idOrItem === 'string' || typeof idOrItem === 'number' ? (idOrItem as K) : this.idFunction(idOrItem);
    if (!this.idSet.has(itemId)) {
      const newItem: T = typeof create === 'function' ? (create as () => T)() : create;
      this.add(newItem);
    }

    const existingItemInd = this.items.findIndex(i => this.idFunction(i) === itemId);
    if (existingItemInd > -1) {
      const newItem: T =
        typeof update === 'function'
          ? update(this.items[existingItemInd])
          : { ...this.items[existingItemInd], ...update };
      if (this.idFunction(newItem) !== itemId) {
        throw new Error(`Item id cannot be replaced during a set!`);
      }
      this.items.splice(existingItemInd, 1, newItem);
      return true;
    } else {
      throw new Error('Created item may not be eligible for update!');
    }
  }

  /**
   * Returns true if the item is in the set.
   * @param item The item to check
   */
  has(item: T): boolean {
    return this.hasById(this.idFunction(item));
  }

  /**
   * Returns the item from the set.
   * @param idOrItem The id or the item to get
   */
  get(idOrItem: string | number | T): undefined | T {
    const itemId: K =
      typeof idOrItem === 'string' || typeof idOrItem === 'number' ? (idOrItem as K) : this.idFunction(idOrItem);
    return this.items.find(i => this.idFunction(i) === itemId);
  }

  /**
   * Returns true if the item is in the set.
   * @param itemId The id of the item to check
   */
  hasById(itemId: K): boolean {
    return this.idSet.has(itemId);
  }

  /**
   * Removes an item from the set.
   * @param item The item to remove
   * @returns True if the item was removed
   */
  delete(item: T): boolean {
    const itemId = this.idFunction(item);
    const ind = this.items.findIndex(i => this.idFunction(i) === itemId);
    if (ind > -1) {
      this.items.splice(ind, 1);
      this.idSet.delete(itemId);
      return true;
    }
    return false;
  }

  /**
   * Removes items from the set based on a condition.
   * @param condition The condition to check
   * @example
   *
   * objectSet.deleteByCondition(i => i.age < 18);
   */
  deleteByCondition(condition: (item: T) => boolean): ObjectSet<T, K> {
    const itemsToDelete = this.items.filter(condition);
    itemsToDelete.forEach(this.delete.bind(this));
    return new ObjectSet<T, K>(this.idFunction, this.items);
  }

  /**
   * Removes every item from the set
   */
  clear(): void {
    this.idSet.clear();
    this.items.splice(0);
  }

  /**
   * Returns the values of the set
   */
  values(): Array<T> {
    return [...this.items];
  }

  /**
   * Iterates over the items in the set
   * @param callback The callback to call for each item
   */
  forEach(callback: (item: T, id: K) => void): void {
    this.items.forEach(item => {
      callback(item, this.idFunction(item));
    });
  }

  /**
   * Returns a subset of the set based on the specified ids
   * @param ids The ids to include in the subset
   * @example
   * const objectSet = new ObjectSet<{id: number, name: string}, number>(i => i.id, [{id: 1, name: 'John'}, {id: 2, name: 'Jane'}, {id: 3, name: 'Jack'}]);
   *
   * objectSet.subset([1, 3]); // -> objectSet with values [{id: 1, name: 'John'}, {id: 3, name: 'Jack'}]
   */
  subset(ids: Array<K>): ObjectSet<T, K> {
    return new ObjectSet(
      this.idFunction,
      this.items.filter(i => ids.includes(this.idFunction(i))),
    );
  }

  keys() {
    return Array.from(this.idSet);
  }
}
