import { InstrumentInstumentCategoryEnum } from '@eih-netbroker/api-instrument';
import { useEffect, useMemo, useState } from 'react';
import { useLocale } from '../../../context/NbLocalizationContext';
import { NbLoader } from '../../../shared/components/loader/Loader';
import { useDictionary } from '../../../shared/services/useDictionary';
import { PortfolioChart } from '../components/PortfolioChart';
import { PortfolioChartLegend } from '../components/PortfolioChartLegend';
import { PortfolioCompositionGrid } from '../components/PortfolioCompositionGrid';
import { PortfolioCompositionHeader } from '../components/PortfolioCompositionHeader';
import { usePortfolioComposition } from '../services/usePortfolioComposition';
import { usePortfolioCompositionChart } from '../services/usePortfolioCompositionChart';
import { usePortfolioCompositionGrid } from '../services/usePortfolioCompositionGrid';
import { PortfolioCompositionDisplayItem, PortfolioCompositionGridItem } from '../types/portfolioCompositionItem';
import { PortfolioCompositionView } from '../types/portfolioCompositionView';
import classes from './PortfolioComposition.module.scss';

const assetColors: Record<InstrumentInstumentCategoryEnum, string> = {
  [InstrumentInstumentCategoryEnum.Certificate]: classes.certificateColor,
  [InstrumentInstumentCategoryEnum.CompensationNote]: classes.compensationNoteColor,
  [InstrumentInstumentCategoryEnum.Share]: classes.shareColor,
  [InstrumentInstumentCategoryEnum.InvestmentUnit]: classes.investmentUnitColor,
  [InstrumentInstumentCategoryEnum.Option]: classes.optionColor,
  [InstrumentInstumentCategoryEnum.Currency]: classes.currencyColor,
  [InstrumentInstumentCategoryEnum.Future]: classes.futureColor,
};
const unknownColor = classes.unknownColor;
const ersteColor = classes.ersteColor;

export type PortfolioCompositionProps = {
  homePage?: boolean;
};

export const PortfolioComposition = ({ homePage }: PortfolioCompositionProps) => {
  const { translation } = useLocale();
  const { loadPortfolioComposition, portfolioComposition } = usePortfolioComposition();
  const {
    getAccountsForPieChart,
    getInstrumentClassForPieChart,
    getInstrumentsForPieChart,
    highlightPieItem,
    resetPieHighlight,
  } = usePortfolioCompositionChart();
  const { getInstrumentsListByAccountForGrid, getInstrumentListForGrid } = usePortfolioCompositionGrid();
  const { formatInstrumentCategoryType } = useDictionary();
  const [view, setView] = useState<PortfolioCompositionView | null>(null);
  const [selectedAccount, setSelectedAccount] = useState<string | null>(null);
  const [selectedInstrumentClass, setSelectedInstrumentClass] = useState<string | null>(null);
  const [displayDataPie, setDisplayDataPie] = useState<Array<PortfolioCompositionDisplayItem>>([]);
  const [displayDataGrid, setDisplayDataGrid] = useState<Array<PortfolioCompositionGridItem>>([]);
  const [activeSelectionLabel, setActiveSelectionLabel] = useState<string>('');

  const onPortfolioItemHoverActive = (item: PortfolioCompositionDisplayItem) => {
    if (view === PortfolioCompositionView.InstrumentClass || view === PortfolioCompositionView.Instruments) {
      setActiveSelectionLabel(formatInstrumentCategoryType(item.name));
    }
    highlightPieItem(displayDataPie, item.id);
  };

  const onPortfolioItemHoverDeactivated = () => {
    setActiveSelectionLabel('');
    resetPieHighlight(displayDataPie);
  };

  const onPortfolioItemSelected = (item: PortfolioCompositionDisplayItem) => {
    if (view === PortfolioCompositionView.Accounts) {
      setSelectedAccount(item.payloadId);
      setView(PortfolioCompositionView.InstrumentClass);
    }
    if (view === PortfolioCompositionView.InstrumentClass && !homePage) {
      setSelectedInstrumentClass(item.payloadId);
      setView(PortfolioCompositionView.Instruments);
    }
  };

  const selectAccountsView = () => {
    setView(PortfolioCompositionView.Accounts);
    setSelectedAccount(null);
  };

  const selectedFilter = useMemo(() => {
    switch (view) {
      case PortfolioCompositionView.Accounts:
        return translation.portfolioComposition.allAccounts;
      case PortfolioCompositionView.InstrumentClass:
        return translation.portfolioComposition.instrumentClass;
      case PortfolioCompositionView.Instruments:
        return selectedInstrumentClass ? formatInstrumentCategoryType(selectedInstrumentClass) : '';
      default:
        return '';
    }
  }, [view, translation, selectedInstrumentClass, formatInstrumentCategoryType]);

  const reloadView = async () => {
    if (!portfolioComposition?.portfolioItems) {
      await loadPortfolioComposition();
    }
    setView(PortfolioCompositionView.Accounts);
  };

  const resetAccounts = () => {
    setSelectedAccount(null);
    setView(PortfolioCompositionView.Accounts);
  };

  const resetInstrumentClass = () => {
    setSelectedInstrumentClass(null);
    setView(PortfolioCompositionView.InstrumentClass);
  };

  const updateDisplayData = () => {
    if (view === PortfolioCompositionView.Accounts) {
      setDisplayDataPie(getAccountsForPieChart(selectedAccount));
    }
    if (view === PortfolioCompositionView.InstrumentClass) {
      setDisplayDataPie(getInstrumentClassForPieChart(selectedAccount));
      setDisplayDataGrid(getInstrumentsListByAccountForGrid(selectedAccount));
    }
    if (view === PortfolioCompositionView.Instruments) {
      if (selectedInstrumentClass) {
        setDisplayDataPie(getInstrumentsForPieChart(selectedAccount, selectedInstrumentClass));
        setDisplayDataGrid(getInstrumentListForGrid(selectedAccount, selectedInstrumentClass));
      }
    }
  };

  useEffect(() => {
    updateDisplayData();
    // updateDisplayData shouldn't be wrapped in useCallback, since it triggers a rerender, making it a render loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAccount, view, selectedInstrumentClass]);

  useEffect(() => {
    reloadView();
    // reloadview shouldn't be wrapped in useCallback, since it triggers a rerender, making it a render loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={`${classes.composition} ${homePage ? classes.compositionIsHomePage : ''}`}>
      <div className={classes.compositionMainHeader}>{translation.portfolioComposition.title}</div>
      {portfolioComposition?.isLoading && (
        <NbLoader
          horizontalCenter={true}
          verticalCenter={true}
          className={classes.compositionLoader}
        />
      )}

      {!portfolioComposition?.isLoading && displayDataPie && (
        <>
          <PortfolioCompositionHeader
            selectedAccount={selectedAccount}
            selectedInstrumentClass={selectedInstrumentClass}
            view={view}
            resetAccounts={resetAccounts}
            resetInstrumentClass={resetInstrumentClass}
            selectAccountsView={selectAccountsView}
            setView={setView}
          />

          <div className={classes.compositionContent}>
            <PortfolioChart
              pieChartElements={displayDataPie}
              onItemHoverActive={onPortfolioItemHoverActive}
              onItemHoverDeactivated={onPortfolioItemHoverDeactivated}
              onItemSelected={onPortfolioItemSelected}
              selectedFilter={selectedFilter}
              activeSelectionLabel={activeSelectionLabel}
              view={view}
              assetColors={assetColors}
              unknownColor={unknownColor}
              ersteColor={ersteColor}
            />

            {view !== PortfolioCompositionView.Instruments && (
              <PortfolioChartLegend
                pieChartElements={displayDataPie}
                assetColors={assetColors}
                view={view}
                unknownColor={unknownColor}
                ersteColor={ersteColor}
                onItemHoverActive={onPortfolioItemHoverActive}
                onItemHoverDeactivated={onPortfolioItemHoverDeactivated}
                onItemSelected={onPortfolioItemSelected}
                investmentLoan={
                  selectedAccount === null && view === PortfolioCompositionView.InstrumentClass
                    ? portfolioComposition?.investmentLoan
                    : undefined
                }
              />
            )}
          </div>
        </>
      )}

      {!portfolioComposition?.isLoading &&
        !homePage &&
        (view === PortfolioCompositionView.InstrumentClass || view === PortfolioCompositionView.Instruments) && (
          <PortfolioCompositionGrid
            assetColors={assetColors}
            rows={displayDataGrid}
            onAction={() => {}}
            onPortfolioItemSelected={item => {
              console.log('onPortfolioItemSelected', item);
              setSelectedInstrumentClass('');
            }}
            enableDetailPanel={view === PortfolioCompositionView.InstrumentClass && selectedAccount === null}
          />
        )}
    </div>
  );
};
