import { InstrumentInstumentCategoryEnum } from '@eih-netbroker/api-instrument';
import { createRef } from 'react';
import { Cell, Pie, PieChart } from 'recharts';
import { useTheme } from '../../../context/NbThemeContext';
import { usePortfolioCompositionChart } from '../services/usePortfolioCompositionChart';
import { PortfolioCompositionDisplayItem } from '../types/portfolioCompositionItem';
import { PortfolioCompositionView } from '../types/portfolioCompositionView';
import { createPortfolioPieLabelElement } from './createPortfolioPieLabelElement';
import classes from './PortfolioChart.module.scss';

export type PortfolioChartProps = {
  pieChartElements: Array<PortfolioCompositionDisplayItem>;
  onItemHoverActive: (item: PortfolioCompositionDisplayItem) => void;
  onItemHoverDeactivated: () => void;
  onItemSelected: (item: PortfolioCompositionDisplayItem) => void;
  unknownColor: string;
  ersteColor: string;
  assetColors: Record<InstrumentInstumentCategoryEnum, string>;
  view: PortfolioCompositionView | null;
  selectedFilter: string;
  activeSelectionLabel: string | null;
};

export const PortfolioChart = ({
  pieChartElements,
  onItemHoverActive,
  onItemHoverDeactivated,
  onItemSelected,
  assetColors,
  ersteColor,
  unknownColor,
  selectedFilter,
  activeSelectionLabel,
  view,
}: PortfolioChartProps) => {
  const { getPortfolioItemColor } = usePortfolioCompositionChart();
  const { rem } = useTheme();
  const chartWrapper = createRef<HTMLDivElement>();

  const chartSize = rem(300);
  const innerRadius = chartSize / 2 / 2;
  const outerRadius = chartSize / 2.5;

  const onAnimationStart = () => {
    chartWrapper.current?.classList.remove(`${classes.chartIsAnimated}`);
  };

  const onAnimationEnd = () => {
    chartWrapper.current?.classList.add(`${classes.chartIsAnimated}`);
  };

  const getColor = (payloadId: string) => {
    return getPortfolioItemColor({ view, assetColors, unknownColor, ersteColor, payloadId });
  };

  return (
    <>
      <div
        className={`${classes.chart} ${view === PortfolioCompositionView.Instruments ? classes.chartIsFullWidth : ''}`}
        ref={chartWrapper}
      >
        <PieChart
          width={chartSize}
          height={chartSize}
        >
          <Pie
            data={pieChartElements}
            dataKey="value"
            nameKey="name"
            cx="50%"
            cy="50%"
            innerRadius={innerRadius}
            outerRadius={outerRadius}
            fill="#8884d8"
            label={createPortfolioPieLabelElement(rem)}
            labelLine={false}
            onMouseOver={onItemHoverActive}
            onMouseLeave={onItemHoverDeactivated}
            onAnimationStart={onAnimationStart}
            onAnimationEnd={onAnimationEnd}
            onClick={onItemSelected}
          >
            {pieChartElements.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={getColor(entry.payloadId)}
              />
            ))}
          </Pie>
        </PieChart>

        <div
          className={classes.chartInnerText}
          style={{ width: `${outerRadius}px` }}
          title={`${selectedFilter} (${pieChartElements.length})`}
        >
          {selectedFilter && !activeSelectionLabel && (
            <>
              {selectedFilter} <br />({pieChartElements.length})
            </>
          )}
          {activeSelectionLabel && activeSelectionLabel}
        </div>
      </div>
    </>
  );
};
