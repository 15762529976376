export const Keys = {
  CookieConsent: 'nn-cookie-consent',
  DebuggerVisible: 'nn-debugger-visible',
  EStreamSubscriptionSourcePrefix: 'NN-',
  Markets: {
    /** @deprecated */
    BET: 'BET',
    /** @deprecated */
    XETRA: 'XETRA',
    /** @deprecated */
    NASDAQ: 'NASDAQ',

    Icons: {
      HU: 'hu',
      DE: 'de',
      US: 'us',
      GB: 'gb',
      EU: 'eu',
    },
  },
  MenuState: 'nn-menu-state',
  /** @deprecated */
  FocusTest: {
    estream: {
      streamUrl: 'nn-estream-url',
      subscribeUrl: 'nn-estream-subscribe-url',
    },
  },
  Theme: {
    key: 'nn-theme',
    dark: 'darkTheme',
    light: 'lightTheme',
  },
};
