import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import type { FC } from 'react';
import { NbIconButton } from '../../../shared/components/icon-button/IconButton';
import { WatchListGroupId } from '../../../shared/state/watchListStateAtom';
import classes from './WatchListDraggableSettingsItem.module.scss';

export interface WatchListDraggableSettingsItemProps {
  watchListGroupId: WatchListGroupId;
  onEditWatchList: (group: WatchListGroupId) => void;
  onDeleteWatchList: (id: string) => void;
}

export const WatchListDraggableSettingsItem: FC<WatchListDraggableSettingsItemProps> = ({
  watchListGroupId,
  onEditWatchList,
  onDeleteWatchList,
}) => {
  return (
    <div className={classes.item}>
      <div className={classes.itemName}>{watchListGroupId.name}</div>

      <span>
        <NbIconButton
          icon={<EditIcon />}
          onClick={() => onEditWatchList(watchListGroupId)}
        />
        <NbIconButton
          icon={<DeleteIcon />}
          onClick={() => onDeleteWatchList(watchListGroupId.id)}
        />
      </span>
    </div>
  );
};
