import { Tab, Tabs, TabsProps } from '@mui/material';
import { SyntheticEvent, useEffect, useState } from 'react';
import classes from './Tabs.module.scss';

export interface TabItem {
  label: string;
  id: string;
}

export interface NbTabsProps extends TabsProps {
  tabs: Array<TabItem>;
  onTabChange?: (index: number) => void;
  selected: number;
}

export const NbTabs = ({ tabs, onTabChange, selected, ...others }: NbTabsProps) => {
  const [selectedTab, setSelectedTab] = useState<number>(selected);

  useEffect(() => {
    setSelectedTab(selected);
  }, [selected]);

  const handleChange = (_: SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
    if (onTabChange) {
      onTabChange(newValue);
    }
  };

  return (
    <Tabs
      className={classes.tabs}
      classes={{ root: classes.root }}
      // classes={{selected: classes.selectedT}}
      value={selectedTab}
      onChange={handleChange}
      aria-label="tabs"
      variant="scrollable"
      scrollButtons="auto"
      {...others}
    >
      {tabs.map(tab => (
        <Tab
          key={tab.id}
          label={tab.label}
        />
      ))}
    </Tabs>
  );
};
