import { MarketDataProvider } from '@eih-netbroker/api-dictionary';
import { Subscription } from '@eih-netbroker/api-partner-data';
import { ReactNode, useCallback } from 'react';
import { useRecoilValue } from 'recoil';
import { ReactComponent as FlagDe } from '../../../assets/svg/market-flags/flag_de.svg';
import { ReactComponent as FlagEu } from '../../../assets/svg/market-flags/flag_eu.svg';
import { ReactComponent as FlagGb } from '../../../assets/svg/market-flags/flag_gb.svg';
import { ReactComponent as FlagHu } from '../../../assets/svg/market-flags/flag_hu.svg';
import { ReactComponent as FlagUnknown } from '../../../assets/svg/market-flags/flag_unknown.svg';
import { ReactComponent as FlagUs } from '../../../assets/svg/market-flags/flag_us.svg';
import { Keys } from '../../../shared/config/keys';
import { PartnerDataResource } from '../../../shared/resources/partnerData.resource';
import { useResource, useResourceHeaders } from '../../../shared/services/useResource';
import { dictionariesAtom } from '../../../shared/state/dictionariesAtom';

export type SubscriptionWithMarketDataInfo = {
  subscription: Subscription;
  marketDataProvider?: MarketDataProvider;
};

const MarketIcons: Record<string, ReactNode> = {
  [Keys.Markets.Icons.HU]: <FlagHu />,
  [Keys.Markets.Icons.DE]: <FlagDe />,
  [Keys.Markets.Icons.US]: <FlagUs />,
  [Keys.Markets.Icons.GB]: <FlagGb />,
  [Keys.Markets.Icons.EU]: <FlagEu />,
};

export const usePartnerSubscriptions = () => {
  const dictionaries = useRecoilValue(dictionariesAtom);
  const partnerDataResource = useResource(PartnerDataResource);
  const resourceHeaders = useResourceHeaders();

  const getPartnerAvailableSubscriptions = useCallback(async (): Promise<Array<SubscriptionWithMarketDataInfo>> => {
    const { data } = await partnerDataResource.getPartnerAvailableSubscriptions({ clientId: '1235' }, resourceHeaders);

    return data.map((subscription: Subscription) => {
      const marketDataProvider = dictionaries.marketDataProviders.find(
        mdp => mdp.id === subscription.marketDataProvider,
      );
      return {
        subscription,
        marketDataProvider,
      };
    });
  }, [dictionaries, partnerDataResource, resourceHeaders]);

  const getMarketIcon = (subscription: SubscriptionWithMarketDataInfo) => {
    if (!subscription.marketDataProvider?.icon || !MarketIcons[subscription.marketDataProvider.icon]) {
      return <FlagUnknown />;
    }
    return MarketIcons[subscription.marketDataProvider.icon];
  };

  return {
    getMarketIcon,
    getPartnerAvailableSubscriptions,
  };
};
