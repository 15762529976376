import { InstrumentInstumentCategoryEnum } from '@eih-netbroker/api-instrument';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { GridActionsCellItem, GridRowParams } from '@mui/x-data-grid';
import { GridColDef } from '@mui/x-data-grid-pro';
import { MouseEvent } from 'react';
import { useLocale } from '../../../context/NbLocalizationContext';
import { useTheme } from '../../../context/NbThemeContext';
import { NbTooltip } from '../../../shared/components/tooltip/Tooltip';
import { useFormatter } from '../../../shared/services/useFormatter';
import gridClasses from '../components/PortfolioCompositionGrid.module.scss';
import { PortfolioCompositionGridItem } from '../types/portfolioCompositionItem';

export type PortfolioCompositionGridItemVisibleFields = Omit<
  PortfolioCompositionGridItem,
  'id' | 'subElements' | 'accountNumber' | 'instrumentCategory'
>;

export const portfolioCompositionItemFields: Array<keyof PortfolioCompositionGridItemVisibleFields> = [
  'instrument',
  'name',
  'quantity',
  'investmentAmount',
  'pNl',
  'pNlPercentage',
  'valueInHuf',
  'value',
  'weight',
];

export type PortfolioCompositionColumn = {
  field: keyof PortfolioCompositionGridItemVisibleFields;
};

export interface PortfolioCompositionGridActions {
  // TODO: rename once we have the real action
  onAction: (event: MouseEvent<HTMLButtonElement>, row: any) => void;
}

export const allColumns: Array<PortfolioCompositionColumn> = portfolioCompositionItemFields.map(field => ({ field }));

export const usePortfolioCompositionColumns = () => {
  const { rem } = useTheme();
  const { translation } = useLocale();
  const { formatNumberF2, formatInteger } = useFormatter();

  const getPortfolioCompositionColumn = (
    column: keyof PortfolioCompositionGridItemVisibleFields,
    getInstrumentColor?: (item: PortfolioCompositionGridItem) => string,
    showAccountNumber?: boolean,
  ): GridColDef<PortfolioCompositionGridItem> => {
    const baseColDef: GridColDef<PortfolioCompositionGridItem> = {
      field: column,
      headerName: translation.portfolioComposition.grid[column],
      sortable: false,
      disableColumnMenu: true,
    };

    switch (column) {
      case 'instrument':
        return {
          ...baseColDef,
          width: rem(85),
          align: 'left',
          renderCell: params => {
            return (
              <span className={gridClasses.groupTickerRowContainer}>
                {getInstrumentColor && (
                  <span
                    style={{ backgroundColor: getInstrumentColor(params.row) }}
                    className={gridClasses.groupTickerRowItem}
                  ></span>
                )}
                <span className={gridClasses.groupTickerInstrument}>{params.row.instrument}</span>
                {showAccountNumber && (
                  <span className={gridClasses.groupTickerAccountNumber}>{params.row.accountNumber}</span>
                )}
              </span>
            );
          },
        };
      case 'name':
        return {
          ...baseColDef,
          width: rem(170),
          align: 'left',
        };
      case 'quantity':
        return {
          ...baseColDef,
          width: rem(80),
          align: 'right',
          renderCell: params => {
            return <span>{formatInteger(params.row.quantity)}</span>;
          },
        };
      case 'value':
        return {
          ...baseColDef,
          width: rem(80),
          align: 'right',
          renderCell: params => {
            return <span>{formatNumberF2(params.row.value)}</span>;
          },
        };
      case 'valueInHuf':
        return {
          ...baseColDef,
          width: rem(110),
          align: 'right',
          renderCell: params => {
            return <span>{formatNumberF2(params.row.valueInHuf)}</span>;
          },
        };
      case 'investmentAmount':
        return {
          ...baseColDef,
          width: rem(110),
          align: 'right',
          renderCell: params => {
            return <span>{formatNumberF2(params.row.investmentAmount)}</span>;
          },
        };
      case 'pNl':
        return {
          ...baseColDef,
          width: rem(110),
          align: 'right',
          renderCell: params => {
            return <span>{formatNumberF2(params.row.pNl)}</span>;
          },
        };
      case 'pNlPercentage':
        return {
          ...baseColDef,
          width: rem(110),
          align: 'right',
          renderCell: params => {
            return <span>{formatNumberF2(params.row.pNlPercentage)}</span>;
          },
        };
      case 'weight':
        return {
          ...baseColDef,
          width: rem(60),
          align: 'right',
          renderCell: params => {
            return <span>{formatNumberF2(params.row.weight)}</span>;
          },
        };
      default:
        return baseColDef;
    }
  };

  const getPortfolioCompositionFlatIcons = (
    actions: PortfolioCompositionGridActions,
  ): GridColDef<PortfolioCompositionGridItem> => {
    return {
      field: 'actions',
      type: 'actions',
      width: rem(50),
      getActions: (params: GridRowParams<PortfolioCompositionGridItem>) => {
        if (params.row.subElements && params.row.subElements.length > 0) {
          return [];
        }
        if (
          Object.values(InstrumentInstumentCategoryEnum).includes(
            params.row.instrumentCategory as InstrumentInstumentCategoryEnum,
          )
        ) {
          return [
            <GridActionsCellItem
              className={gridClasses.actionsEdit}
              icon={<CompareArrowsIcon />}
              label={translation.portfolioComposition.gridAction}
              onClick={e => actions.onAction(e, params.row)}
            />,
          ];
        }
        return [
          <NbTooltip title={translation.portfolioComposition.inventoryCannotBeTraded}>
            <GridActionsCellItem
              className={gridClasses.actionsEdit}
              icon={<InfoOutlinedIcon />}
              label={''}
            />
          </NbTooltip>,
        ];
      },
    };
  };

  return {
    getPortfolioCompositionColumn,
    getPortfolioCompositionFlatIcons,
  };
};
