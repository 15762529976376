export const toPrecision = (num: number, precision = 12): number => {
  return parseFloat(num.toPrecision(precision));
};

/**
 * Calculates the percentage of a number
 * @param of Number to get percentage of
 * @param by Percentage to get
 * @example
 * getPercent(1024, 10) // -> 102.4
 */
export const getPercent = (of: number, by: number): number => {
  return parseFloat(toPrecision(toPrecision(of) * toPrecision(by / 100)).toPrecision(2));
};

/**
 * Returns the percentage of a number
 * @param num1
 * @param num2
 * @example
 * getPercentOf(500, 1000) // -> 50
 */
export const getPercentOf = (num1: number, num2: number): number => {
  return parseFloat(toPrecision((toPrecision(num1) / toPrecision(num2)) * 100).toPrecision(2));
};

/**
 * Returns the sum of a field in a list of objects
 * @param list List of objects
 * @param field which field to sum
 * @example
 * sumOfFieldInList([{price: 1300}, {price: 221}], 'price') // -> 1521
 */
export const sumOfFieldInList = <T>(list: Array<T>, field: keyof T): number => {
  return list.reduce((previousValue, currentValue) => previousValue + (currentValue[field] as number), 0);
};

/**
 * Returns the changes of a last price and close price with the percentage
 * @param lastPrice Last price
 * @param closePrice Close price
 */
export const getChangeWithPercent = (
  lastPrice: number,
  closePrice: number,
): { change: number; changePercent: number } => {
  const change = lastPrice - closePrice;
  const changePercent = change / closePrice;
  return { change, changePercent };
};

export const shadeColor = (color: string, percent: number) => {
  let R = Number(parseInt(color.substring(1, 3), 16));
  let G = Number(parseInt(color.substring(3, 5), 16));
  let B = Number(parseInt(color.substring(5, 7), 16));

  R = (R * (100 + percent)) / 100;
  G = (G * (100 + percent)) / 100;
  B = (B * (100 + percent)) / 100;

  R = R < 255 ? R : 255;
  G = G < 255 ? G : 255;
  B = B < 255 ? B : 255;

  R = Math.round(R);
  G = Math.round(G);
  B = Math.round(B);

  const RR = R.toString(16).length === 1 ? '0' + R.toString(16) : R.toString(16);
  const GG = G.toString(16).length === 1 ? '0' + G.toString(16) : G.toString(16);
  const BB = B.toString(16).length === 1 ? '0' + B.toString(16) : B.toString(16);

  return '#' + RR + GG + BB;
};
