import { ReactNode } from 'react';
import classes from './NotificationCounter.module.scss';

export interface NbNotificationCounterProps {
  children: ReactNode;
}

export const NbNotificationCounter = ({ children }: NbNotificationCounterProps) => {
  return <span className={classes.counter}>{children}</span>;
};
