import { useMemo, useRef, useState } from 'react';

class Debounced<T> {
  private previousAction?: { id: number; action: () => Promise<T> };

  constructor(
    private readonly setState: (value: T) => void,
    private readonly setIsLoading: (value: boolean) => void,
    private readonly timeout = 300,
  ) {}

  debounce(action: () => Promise<T>) {
    const id = Math.random();
    const newAction = { id, action };
    if (this.previousAction) {
      this.previousAction = undefined;
    }
    this.previousAction = newAction;

    setTimeout(() => {
      // if there is no newer action added for execution, we can call the action
      if (this.previousAction?.id === id) {
        this.setIsLoading(true);
        newAction
          .action()
          .then((res: T) => this.handleActionResult(id, res))
          .finally(() => this.setIsLoading(false));
      }
    }, this.timeout);
  }

  private handleActionResult(id: number, res: T) {
    if (this.previousAction?.id !== id) {
      // this action has been cancelled, no need to set state
      return;
    }
    this.setState(res);
  }
}

export const useDebounce = <T>(setState: (val: T) => void) => {
  const [isLoading, setIsLoading] = useState(false);
  const debouncer = useRef(new Debounced(setState, setIsLoading, 300));
  return {
    debounce: useMemo(() => debouncer.current.debounce.bind(debouncer.current), [debouncer]),
    isLoading,
  };
};
