import { GridColDef } from '@mui/x-data-grid';
import { useLocale } from '../../../context/NbLocalizationContext';
import { useFormatter } from '../../../shared/services/useFormatter';
import { WithoutId } from '../../../shared/types/withoutId';
import { BETFillListItem } from '../states/betFillListAtom';

export const betFillListFields: Array<keyof WithoutId<BETFillListItem>> = ['instrument', 'price', 'quantity', 'time'];

export type BETFillListColumn = {
  field: keyof WithoutId<BETFillListItem>;
};

export const allColumns: Array<BETFillListColumn> = betFillListFields.map(field => ({ field }));

export const useBETFillListColumns = () => {
  const { formatNumberF2, formatTime, formatInteger } = useFormatter();
  const { translation } = useLocale();

  const getBETFillListColumn = (column: keyof WithoutId<BETFillListItem>): GridColDef<BETFillListItem> => {
    const baseColDef: GridColDef<BETFillListItem> = {
      field: column,
      headerName: translation.BETFillList.grid[column],
      sortable: false,
      disableColumnMenu: true,
    };

    switch (column) {
      case 'instrument':
        return {
          ...baseColDef,
          width: 60,
          align: 'left',
          renderCell: params => {
            return <span>{params.row.instrument.ticker}</span>;
          },
        };
      case 'price':
        return {
          ...baseColDef,
          width: 65,
          align: 'right',
          renderCell: params => {
            return <span>{formatNumberF2(params.row.price)}</span>;
          },
        };
      case 'quantity':
        return {
          ...baseColDef,
          width: 65,
          align: 'right',
          renderCell: params => {
            return <span>{formatInteger(params.row.quantity)}</span>;
          },
        };
      case 'time':
        return {
          ...baseColDef,
          width: 70,
          align: 'right',
          renderCell: params => {
            return <span>{formatTime(params.row.time)}</span>;
          },
        };

      default:
        throw new Error(`Invalid column ${column}`);
    }
  };

  return { getBETFillListColumn };
};
