import BarChartIcon from '@mui/icons-material/BarChart';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import NotificationsIcon from '@mui/icons-material/Notifications';
import PaymentsIcon from '@mui/icons-material/Payments';
import SearchIcon from '@mui/icons-material/Search';
import { Button, Popover } from '@mui/material';
import { MouseEvent, useState } from 'react';
import { ReactComponent as ErsteLogo } from '../../../assets/svg/erste_logo.svg';
import { ReactComponent as ErsteIcon } from '../../../assets/svg/erste_logo_icon.svg';
import FlagEu from '../../../assets/svg/market-flags/flag_eu.svg';
import FlagHun from '../../../assets/svg/market-flags/flag_hu.svg';
import { useDialog } from '../../../context/DialogContext';
import { useLocale } from '../../../context/NbLocalizationContext';
import { useTheme } from '../../../context/NbThemeContext';
import { useSSEConnection } from '../../../context/SSEConnectionContext';
import { NbButton } from '../../../shared/components/button/Button';
import { NbIconButton } from '../../../shared/components/icon-button/IconButton';
import { InstrumentSearchModal } from '../../../shared/components/instrument-search-modal/InstrumentSearchModal';
import { useAccounts } from '../../../shared/services/useAccounts';
import { ListedInstrument } from '../../../shared/types/listedInstrument';
import { AlertCenterModal } from '../../alert/component/AlertCenterModal';
import { AccountsPopoverContent } from '../components/AccountsPopoverContent';
import { CashPopoverContent } from '../components/CashPopoverContent';
import { ExchangePopoverContent } from '../components/ExchangePopoverContent';
import { NbNotificationCounter } from '../components/NotificationCounter';
import classes from './Header.module.scss';

export interface NbHeaderProps {
  isMenuCollapsed: boolean;
}

export const NbHeader = ({ isMenuCollapsed }: NbHeaderProps) => {
  const { translation } = useLocale();
  const { openDialog } = useDialog();
  const { clients } = useAccounts();
  const { selectedAccount } = useAccounts();
  const [accountsPopover, setAccountsPopover] = useState<HTMLButtonElement | null>(null);
  const [exchangePopover, setExchangePopover] = useState<HTMLButtonElement | null>(null);
  const [cashPopover, setCashPopover] = useState<HTMLDivElement | null>(null);
  const { hasEStreamConnection } = useSSEConnection();
  const { setTheme, isDark } = useTheme();

  const MOCK_DATA = {
    invoiceValueHuf: '123 456 678 HUF',
    floatingValueHuf: '+12 113 374 HUF',
    cashBalanceHuf: '23 656 267 HUF',
    totalCash: '123 456 678 HUF',
    fiats: [
      {
        picture: FlagHun,
        name: 'Forint',
        currency: 'HUF',
        amount: 450000,
        // inHuf: '450000 HUF',
      },
      {
        picture: FlagEu,
        name: 'Euro',
        currency: 'EUR',
        amount: 200,
        // inHuf: '123 456 HUF',
      },
    ],
  };

  const onSearchInstrument = async () => {
    await openDialog<ListedInstrument>((close: (params?: ListedInstrument) => void) => (
      <InstrumentSearchModal
        onSelect={instrument => close(instrument)}
        onCancel={() => close()}
      />
    ));
  };

  const showAlertModal = async () => {
    await openDialog((close: () => void) => <AlertCenterModal onCancel={close} />);
  };

  const handleAccountsClose = () => {
    setAccountsPopover(null);
  };

  const handleAccountsClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAccountsPopover(event.currentTarget);
  };

  const handleCashClose = () => {
    setCashPopover(null);
  };

  const handleCashClick = (event: MouseEvent<HTMLDivElement>) => {
    setCashPopover(event.currentTarget);
  };

  const handleExchangeClose = () => {
    setExchangePopover(null);
  };

  const handleExchangeClick = (event: MouseEvent<HTMLButtonElement>) => {
    setExchangePopover(event.currentTarget);
  };

  return (
    <div className={`${classes.header} ${isMenuCollapsed ? classes.headerIsCollapsedMenu : ''}`}>
      <div className={classes.logo}>
        <ErsteLogo />
        <ErsteIcon />
      </div>

      <div className={classes.main}>
        <NbButton
          color={'secondary'}
          variant={'outlined'}
          onClick={handleAccountsClick}
          className={classes.headerButtonAllAccounts}
        >
          <PaymentsIcon />
          {selectedAccount.isAllSelected && translation.header.allAccounts}
          {!selectedAccount.isAllSelected && (
            <div className={classes.headerButtonAllAccountsContent}>
              <span>{selectedAccount.client.clientName}</span>
              <span>{selectedAccount.account.accountCategory}</span>
            </div>
          )}
          <ExpandMoreIcon className={classes.headerButtonAllAccountsIcon} />
        </NbButton>
        <Popover
          open={!!accountsPopover}
          anchorEl={accountsPopover}
          onClose={handleAccountsClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          classes={{ paper: classes.popover }}
        >
          <AccountsPopoverContent
            clients={clients}
            onClose={handleAccountsClose}
          />
        </Popover>

        <div className={classes.mainItem}>
          <p>{MOCK_DATA.invoiceValueHuf}</p>
          <p>{translation.header.invoiceValue}</p>
        </div>
        <div className={classes.mainItem}>
          <p>{MOCK_DATA.floatingValueHuf}</p>
          <p>{translation.header.floatingResult}</p>
        </div>

        <div
          className={`${classes.mainItem} ${classes.mainItemIsButton}`}
          onClick={handleCashClick}
        >
          <p>{MOCK_DATA.cashBalanceHuf}</p>
          <p>{translation.header.cashBalance}</p>
        </div>
        <Popover
          open={!!cashPopover}
          anchorEl={cashPopover}
          onClose={handleCashClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          classes={{ paper: classes.popover }}
        >
          <CashPopoverContent
            totalCash={MOCK_DATA.totalCash}
            fiats={MOCK_DATA.fiats}
            handleCashClose={handleCashClose}
          />
        </Popover>
      </div>

      <div className={classes.actions}>
        <Button
          onClick={() => {
            setTheme(!isDark);
          }}
        ></Button>
        <NbIconButton
          icon={<SearchIcon />}
          onClick={onSearchInstrument}
        />

        <div className={classes.actionWithNoti}>
          <NbIconButton
            icon={<NotificationsIcon />}
            onClick={showAlertModal}
          />
          <NbNotificationCounter>4</NbNotificationCounter>
        </div>

        <div className={classes.actionWithNoti}>
          <NbIconButton
            icon={<BarChartIcon />}
            onClick={handleExchangeClick}
          />
          {!hasEStreamConnection && <NbNotificationCounter>!</NbNotificationCounter>}
          <Popover
            open={!!exchangePopover}
            anchorEl={exchangePopover}
            onClose={handleExchangeClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            classes={{ paper: classes.popover }}
          >
            <ExchangePopoverContent onHandleExchangeClose={handleExchangeClose} />
          </Popover>
        </div>
      </div>
    </div>
  );
};
