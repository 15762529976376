import { OrderDirectionEnum } from '@eih-netbroker/api-order';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import InfoIcon from '@mui/icons-material/Info';
import { useState } from 'react';
import { useDialog } from '../../../context/DialogContext';
import { useLocale } from '../../../context/NbLocalizationContext';
import { NbButton } from '../../../shared/components/button/Button';
import { useFormatter } from '../../../shared/services/useFormatter';
import { OrderModal } from '../../trading/components/OrderModal';
import { MarketHintItem } from '../pages/HomePage';
import classes from './MarketHint.module.scss';

export enum MarketHintColor {
  Dark = 'Dark',
  Light = 'Light',
}

export interface MarketHintProps {
  color: MarketHintColor;
  data: MarketHintItem;
}

export const MarketHint = ({ color, data }: MarketHintProps) => {
  const { translation } = useLocale();
  const { openDialog } = useDialog();
  const { formatPercentage, formatInteger } = useFormatter();
  const [isRevealed, setIsRevealed] = useState<boolean>(false);

  const revealCard = () => {
    setIsRevealed(!isRevealed);
  };

  const handleOrder = async () => {
    await openDialog((close: () => void) => <OrderModal onCancel={close} />);
  };

  return (
    <>
      {!isRevealed && (
        <div className={`${classes.hint} ${color === MarketHintColor.Dark ? classes.hintDark : classes.hintLight}`}>
          <div>
            <h4>{translation.dashboard.marketHint.title}</h4>
          </div>
          <div>
            <NbButton
              size={'large'}
              color={'primary'}
              onClick={revealCard}
            >
              {translation.dashboard.marketHint.buttonText}
            </NbButton>
          </div>
        </div>
      )}

      {isRevealed && (
        <div className={classes.reveal}>
          <div className={classes.revealHeader}>
            <p
              className={`${classes.revealTitle} ${
                data.directionId === OrderDirectionEnum.Buy ? classes.isUp : classes.isDown
              }`}
            >
              {data.name}
            </p>
            {data.directionId === OrderDirectionEnum.Buy ? (
              <ArrowUpwardIcon
                className={`${classes.revealArrowIcon} ${
                  data.directionId === OrderDirectionEnum.Buy ? classes.isUp : classes.isDown
                }`}
              />
            ) : (
              <ArrowDownwardIcon
                className={`${classes.revealArrowIcon} ${
                  data.directionId === OrderDirectionEnum.Buy
                    ? classes.revealArrowIconIsUp
                    : classes.revealArrowIconIsDown
                }`}
              />
            )}
            <div
              className={`${classes.revealHeaderChange} ${
                data.directionId === OrderDirectionEnum.Buy
                  ? classes.revealHeaderChangeIsUp
                  : classes.revealHeaderChangeIsDown
              }`}
            >
              <span className={`${data.directionId === OrderDirectionEnum.Buy ? classes.isUp : classes.isDown}`}>
                {formatInteger(data.openPrice - data.takeProfit)}
              </span>
              <span className={`${data.directionId === OrderDirectionEnum.Buy ? classes.isUp : classes.isDown}`}>
                {formatPercentage(data.openPrice / data.takeProfit)}
              </span>
            </div>
            <InfoIcon className={classes.revealInfoIcon} />
            <div className={classes.revealButton}>
              <NbButton
                size={'small'}
                color={'primary'}
                onClick={handleOrder}
              >
                {translation.dashboard.marketHint.actionButtonText}
              </NbButton>
            </div>
          </div>

          <div className={classes.revealContent}>
            <div className={classes.revealRow}>
              <div className={classes.revealItem}>
                <p className={classes.revealItemTitle}>{translation.dashboard.marketHint.openExchangeRate}:</p>
                <p className={classes.revealItemPrice}>{data.openPrice}</p>
              </div>

              <div className={classes.revealItem}>
                <p className={classes.revealItemTitle}>{translation.dashboard.marketHint.goalExchangeRate}:</p>
                <p className={classes.revealItemPrice}>{data.takeProfit}</p>
              </div>
            </div>

            <div className={classes.revealRow}>
              <div className={classes.revealItem}>
                <p className={classes.revealItemTitle}>{translation.dashboard.marketHint.openDate}:</p>
                <p className={classes.revealItemPrice}>{data.openDate}</p>
              </div>

              <div className={classes.revealItem}>
                <p className={classes.revealItemTitle}>{translation.dashboard.marketHint.stopExchangeRate}:</p>
                <p className={classes.revealItemPrice}>{data.stopLoss}</p>
              </div>
            </div>
          </div>

          <div className={classes.revealLink}>
            <a
              href={data.relatedContent.url}
              target={'_blank'}
              rel="noreferrer noopener"
            >
              {translation.dashboard.marketHint.relatedAnalysis}
            </a>
          </div>
        </div>
      )}
    </>
  );
};
