import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { useRef, useState } from 'react';
import { useRecoilState } from 'recoil';
import { NbIconButton } from '../../../shared/components/icon-button/IconButton';
import { indexRowInstrumentAtom } from '../../../shared/state/indexRowStateAtom';
import { IndexRowElement } from '../partials/IndexRowElement';
import { useIndexRowMocker } from '../services/useIndexRowMocker';
import classes from './IndexRow.module.scss';

export const IndexRow = () => {
  useIndexRowMocker();

  const [indexRowInstruments] = useRecoilState(indexRowInstrumentAtom);
  const [leftArrowDisabled, setLeftArrowDisabled] = useState(true);
  const [rightArrowDisabled, setRightArrowDisabled] = useState(false);
  const divRef = useRef<HTMLDivElement | null>(null);

  const scrollRight = () => {
    divRef.current?.scrollBy({ left: divRef.current.offsetWidth, behavior: 'smooth' });
  };

  const scrollLeft = () => {
    divRef.current?.scrollBy({ left: -divRef.current.offsetWidth, behavior: 'smooth' });
  };

  const onScroll: React.UIEventHandler<HTMLDivElement> = e => {
    const node = e.target as HTMLDivElement;
    const isRightDisabled = Math.abs(node.scrollWidth - node.clientWidth - node.scrollLeft) <= 1;
    setRightArrowDisabled(isRightDisabled);
    const isLeftDisabled = node.scrollLeft < 1;
    setLeftArrowDisabled(isLeftDisabled);
  };

  return (
    <div className={classes.indexRow}>
      <div
        onScroll={onScroll}
        ref={divRef}
        className={classes.indexRowCharts}
      >
        {indexRowInstruments.map(instrument => (
          <IndexRowElement
            key={instrument.instrument.ric}
            instrument={instrument}
          />
        ))}
      </div>
      <div className={classes.indexRowArrows}>
        <NbIconButton
          icon={<KeyboardArrowLeftIcon />}
          disabled={leftArrowDisabled}
          onClick={() => scrollLeft()}
        ></NbIconButton>
        <NbIconButton
          icon={<KeyboardArrowRightIcon />}
          disabled={rightArrowDisabled}
          onClick={() => scrollRight()}
        ></NbIconButton>
      </div>
    </div>
  );
};
