import { NbSwitch } from '../toggle-button/Switch';
import classes from './DraggableSwitchItem.module.scss';

export type DraggableSwitchItemProps = {
  label: string;
  selected: boolean;
  toggleColumnVisibility: () => void;
  disabled?: boolean;
};

export const DraggableSwitchItem = ({
  label,
  selected,
  toggleColumnVisibility,
  disabled,
}: DraggableSwitchItemProps) => {
  return (
    <>
      <div className={classes.item}>
        <span className={classes.itemName}>{label}</span>
        <span className={classes.itemSwitch}>
          <NbSwitch
            size={'small'}
            checked={selected}
            disabled={disabled}
            onChange={toggleColumnVisibility}
            color={'secondary'}
          />
        </span>
      </div>
    </>
  );
};
