import { useCallback, useEffect } from 'react';
import { Config } from '../../../shared/config/config';
import { Keys } from '../../../shared/config/keys';
import { useCookie } from '../../../shared/utils/useCookie';

const loadHotJar = () => {
  if (Config.App.isProduction) {
    const head = document.querySelector('head');
    const script = document.createElement('script');

    script.innerHTML = `(function(h,o,t,j,a,r){
        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
        h._hjSettings={hjid:3246489,hjsv:6};
        a=o.getElementsByTagName('head')[0];
        r=o.createElement('script');r.async=1;
        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
        a.appendChild(r);
      })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`;

    head?.appendChild(script);
  }
};

let hotjarLoaded = false;

export enum ConsentStatus {
  Consented = 'consented',
  Rejected = 'rejected',
}

export const useConsent = () => {
  const { getCookie, setCookie } = useCookie();

  const getCookieConsent = useCallback((): ConsentStatus | undefined => {
    try {
      const res = getCookie(Keys.CookieConsent);
      return res as ConsentStatus;
    } catch (err) {}
    return undefined;
  }, [getCookie]);

  const acceptCookieConsent = () => {
    try {
      setCookie(Keys.CookieConsent, ConsentStatus.Consented, 365);
    } catch (err) {
      console.warn(`Failed to set consent cookie`, err);
    }
    loadHotJar();
  };

  const rejectCookieConsent = () => {
    try {
      setCookie(Keys.CookieConsent, ConsentStatus.Rejected, 365);
    } catch (err) {
      console.warn(`Failed to set consent cookie`, err);
    }
  };

  useEffect(() => {
    if (getCookieConsent() === ConsentStatus.Consented && !hotjarLoaded) {
      loadHotJar();
      hotjarLoaded = true;
    }
  }, [getCookieConsent]);

  return {
    getCookieConsent,
    acceptCookieConsent,
    rejectCookieConsent,
  };
};
