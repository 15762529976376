import { Alert, AlertGroup } from '../../modules/alert/state/alertGroupsAtom';
import { AlertCategory } from '../../modules/alert/types/alertCategory';
import { AlertType } from '../../modules/alert/types/alertType';

const alertList: Array<Alert> = [
  {
    id: 1,
    title: 'Teljesült megbízás: NVDA Limit VÉTEL 100 db @ 180 USD Számla: TBSZ 2023',
    read: false,
    type: AlertType.Success,
    message: 'Visszautasítás oka: Hibás árlépésköz',
    date: new Date(),
  },
  {
    id: 2,
    title: 'Teljesült megbízás: NVDA Limit VÉTEL 100 db @ 180 USD Számla: TBSZ 2023',
    read: false,
    type: AlertType.Warning,
    date: new Date(),
  },
  {
    id: 3,
    title: 'Teljesült megbízás: NVDA Limit VÉTEL 100 db @ 180 USD Számla: TBSZ 2023',
    read: true,
    type: AlertType.Info,
    date: new Date(),
  },
  {
    id: 4,
    title: 'Teljesült megbízás: NVDA Limit VÉTEL 100 db @ 180 USD Számla: TBSZ 2023',
    read: true,
    type: AlertType.Error,
    date: new Date(),
  },
  {
    id: 5,
    title: 'Teljesült megbízás: NVDA Limit VÉTEL 100 db @ 180 USD Számla: TBSZ 2023',
    read: false,
    type: AlertType.Info,
    date: new Date(),
  },
  {
    id: 6,
    title: 'Teljesült megbízás: NVDA Limit VÉTEL 100 db @ 180 USD Számla: TBSZ 2023',
    read: true,
    type: AlertType.Info,
    date: new Date(),
  },
  {
    id: 7,
    title: 'Teljesült megbízás: NVDA Limit VÉTEL 100 db @ 180 USD Számla: TBSZ 2023',
    read: true,
    type: AlertType.Info,
    date: new Date(),
  },
  {
    id: 8,
    title: 'Teljesült megbízás: NVDA Limit VÉTEL 100 db @ 180 USD Számla: TBSZ 2023',
    read: true,
    type: AlertType.Info,
    date: new Date(),
  },
  {
    id: 9,
    title: 'Teljesült megbízás: NVDA Limit VÉTEL 100 db @ 180 USD Számla: TBSZ 2023',
    read: false,
    type: AlertType.Info,
    message: 'Visszautasítás oka: Hibás árlépésköz',
    date: new Date(),
  },
  {
    id: 10,
    title: 'Teljesült megbízás: NVDA Limit VÉTEL 100 db @ 180 USD Számla: TBSZ 2023',
    read: true,
    type: AlertType.Info,
    date: new Date(),
  },
  {
    id: 11,
    title: 'Teljesült megbízás: NVDA Limit VÉTEL 100 db @ 180 USD Számla: TBSZ 2023',
    read: true,
    type: AlertType.Info,
    date: new Date(),
  },
  {
    id: 12,
    title: 'Teljesült megbízás: NVDA Limit VÉTEL 100 db @ 180 USD Számla: TBSZ 2023',
    read: true,
    type: AlertType.Info,
    message: 'Visszautasítás oka: Hibás árlépésköz',
    date: new Date(),
  },
  {
    id: 13,
    title: 'Teljesült megbízás: NVDA Limit VÉTEL 100 db @ 180 USD Számla: TBSZ 2023',
    read: true,
    type: AlertType.Info,
    date: new Date(),
  },
  {
    id: 14,
    title: 'Teljesült megbízás: NVDA Limit VÉTEL 100 db @ 180 USD Számla: TBSZ 2023',
    read: true,
    type: AlertType.Info,
    date: new Date(),
  },
];

export const mockedAlertGroups: Array<AlertGroup> = [
  {
    category: AlertCategory.Event,
    alerts: alertList,
  },
  {
    category: AlertCategory.Promotion,
    alerts: alertList,
  },
  {
    category: AlertCategory.Technical,
    alerts: [],
  },
];
