import { useRecoilValue } from 'recoil';
import { widgetStateAtom } from '../../../shared/state/widgetStateAtom';
import { LayoutSelector } from '../partials/LayoutSelector';
import { WidgetView } from '../partials/WidgetView';
import classes from './TradingPage.module.scss';

export const TradingPage = () => {
  const widgetConfigState = useRecoilValue(widgetStateAtom);

  return (
    <div className={classes.tradingPage}>
      <LayoutSelector />
      <WidgetView widgetConfigState={widgetConfigState} />
    </div>
  );
};
