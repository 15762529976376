import { atom, selector, selectorFamily } from 'recoil';
import { ListedInstrument } from '../types/listedInstrument';
import { WithoutId } from '../types/withoutId';

export type WatchListInstrument = {
  /** Not displayed */
  id: string;
  instrument: ListedInstrument;
  lastPrice?: number;
  /** Not displayed */
  closePrice?: number;
  change?: number;
  changePercent?: number;
  buyPrice?: number;
  sellPrice?: number;
  low?: number;
  high?: number;
  averagePrice?: number;
  volumeQuantity?: number;
  volumeValue?: number;
  annualLow?: number;
  annualHigh?: number;
};

export type WatchListColumn = {
  field: keyof WithoutId<WatchListInstrument>;
};

export type WatchListGroupId = {
  id: string;
  name: string;
};

export type WatchListGroup = WatchListGroupId & {
  columns: Array<WatchListColumn>;
  items: Array<WatchListInstrument>;
};

export type WatchListOfUser = {
  userId: string;
  groups: Array<WatchListGroup>;
};

export const watchListGroupsAtom = atom<Array<WatchListGroup>>({
  key: 'watchList/groups',
  default: [],
});

export const watchListGroupIdsSelector = selector<Array<WatchListGroupId>>({
  key: 'watchList/groupIds',
  get: ({ get }) => {
    const watchListItems = get(watchListGroupsAtom);
    return watchListItems.reduce((acc, currentValue) => {
      if (!acc.some(watchListGroup => watchListGroup.id === currentValue.id)) {
        acc.push({
          id: currentValue.id,
          name: currentValue.name,
        });
      }
      return acc;
    }, [] as Array<WatchListGroupId>);
  },
});

export const watchListGroupSelector = selectorFamily<WatchListGroup | undefined, string | undefined>({
  key: 'watchList/group',
  get:
    (groupId: string | undefined) =>
    ({ get }) => {
      if (!groupId) {
        return undefined;
      }
      const watchListItems = get(watchListGroupsAtom);
      return watchListItems.find(watchListItem => watchListItem.id === groupId);
    },
});
