import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { useEffect, useRef, useState } from 'react';
import { useLocale } from '../../../context/NbLocalizationContext';
import { useSSEConnection } from '../../../context/SSEConnectionContext';
import { InstrumentSearchAutocomplete } from '../../../shared/components/instrument-search-autocomplete/InstrumentSearchAutocomplete';
import { NbLoader } from '../../../shared/components/loader/Loader';
import { NbTooltip } from '../../../shared/components/tooltip/Tooltip';
import { useFormatter } from '../../../shared/services/useFormatter';
import { InstrumentSubscriptionDataScope } from '../../../shared/types/estream/eStreamSubscription';
import { InstrumentStoreItem } from '../../../shared/types/estream/instrumentStore';
import { ListedInstrument } from '../../../shared/types/listedInstrument';
import classes from './WidgetInstrumentSearchAndDisplay.module.scss';

export type WidgetInstrumentSearchAndDisplayProps = {
  source: string;
  instrument: ListedInstrument | null;
  updateInstrument: (instrument: ListedInstrument | null) => void;
  showPriceChanges?: boolean;
};

export const WidgetInstrumentSearchAndDisplay = ({
  source,
  instrument,
  updateInstrument,
  showPriceChanges,
}: WidgetInstrumentSearchAndDisplayProps) => {
  const [instrumentData, setInstrumentData] = useState<InstrumentStoreItem | null>(null);
  const previousInstrumentChangesListener = useRef<() => void>();
  const { subscribeToInstrumentChanges, hasEStreamConnection } = useSSEConnection();
  const { formatCurrency, formatNumberF2, formatPercentage } = useFormatter();
  const { translation } = useLocale();

  const formattedPrice =
    instrument && instrumentData?.lastPrice ? formatCurrency(instrumentData.lastPrice, instrument) : undefined;
  const priceChange =
    instrument && instrumentData?.lastPrice && instrumentData?.closePrice
      ? instrumentData.lastPrice - instrumentData.closePrice
      : undefined;
  const priceChangePerc =
    instrument && instrumentData?.lastPrice && instrumentData?.closePrice && priceChange
      ? priceChange / instrumentData.closePrice
      : undefined;

  useEffect(() => {
    if (previousInstrumentChangesListener.current) {
      previousInstrumentChangesListener.current();
      previousInstrumentChangesListener.current = undefined;
    }
    if (instrument) {
      previousInstrumentChangesListener.current = subscribeToInstrumentChanges(
        [
          {
            instrument,
            source,
            dataScope: InstrumentSubscriptionDataScope.Trades,
          },
        ],
        items => {
          const changedItem = items.get(instrument);
          if (changedItem) {
            setInstrumentData(changedItem);
          }
        },
      );
    }
    return () => {
      if (previousInstrumentChangesListener.current) {
        previousInstrumentChangesListener.current();
        previousInstrumentChangesListener.current = undefined;
      }
    };
  }, [instrument, source, subscribeToInstrumentChanges]);

  return (
    <>
      <div className={classes.header}>
        <InstrumentSearchAutocomplete
          isPopperWide
          onSelectInstrument={updateInstrument}
          selection={instrument}
          className={classes.headerInstrumentSearch}
        />
        {instrument && !hasEStreamConnection && (
          <NbTooltip
            title={translation.app.estream.unavailable}
            placement={'bottom'}
          >
            <span className={classes.errorIcon}>
              <ErrorOutlineIcon />
            </span>
          </NbTooltip>
        )}
        {hasEStreamConnection && (
          <>
            {formattedPrice && priceChange && priceChangePerc && (
              <>
                <span className={classes.headerPriceText}>
                  {formatCurrency(instrumentData!.lastPrice!, instrument!)}
                </span>
                {showPriceChanges !== false && (
                  <div
                    className={`${classes.headerChangeText} ${
                      priceChange < 0 ? classes.headerChangeTextIsNegative : classes.headerChangeTextIsPositive
                    }`}
                  >
                    {priceChange < 0 ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />}
                    <span>
                      {formatNumberF2(priceChange)} ({formatPercentage(priceChangePerc)})
                    </span>
                  </div>
                )}
              </>
            )}
            {instrument && (!formattedPrice || !priceChange || !priceChangePerc) && <NbLoader />}
          </>
        )}
      </div>
    </>
  );
};
