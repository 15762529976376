import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import DescriptionIcon from '@mui/icons-material/Description';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import SsidChartIcon from '@mui/icons-material/SsidChart';
import { useLocale } from '../../../context/NbLocalizationContext';
import { WatchListInstrument } from '../../../shared/state/watchListStateAtom';
import { TradeType } from '../../../shared/types/tradeType';
import classes from './WatchListContextMenu.module.scss';

interface WatchListContextMenuProps {
  instrument: any;
  onDeleteWatchlistInstrument: (watchListInstrument: WatchListInstrument) => void;
  onOrderWatchListInstrument: (watchListInstrument: WatchListInstrument, tradeType?: TradeType) => void;
  onNotifyWatchListInstrument: (watchListInstrument: WatchListInstrument) => void;
}

export const WatchListContextMenu = ({
  instrument,
  onDeleteWatchlistInstrument,
  onOrderWatchListInstrument,
  onNotifyWatchListInstrument,
}: WatchListContextMenuProps) => {
  const { translation } = useLocale();

  const orderWatchlistInstrument = () => {
    onOrderWatchListInstrument(instrument);
  };

  const deleteWatchlistInstrument = () => {
    onDeleteWatchlistInstrument(instrument);
  };

  const notifyWatchlistInstrument = () => {
    onNotifyWatchListInstrument(instrument);
  };

  return (
    <ul className={classes.menu}>
      <li className={classes.menuRow}>
        <DescriptionIcon /> {translation.watchListGrid.context.details}
      </li>
      <li
        className={classes.menuRow}
        onClick={orderWatchlistInstrument}
      >
        <AddIcon /> {translation.watchListGrid.context.order}
      </li>
      <li
        className={classes.menuRow}
        onClick={notifyWatchlistInstrument}
      >
        <NotificationsNoneIcon /> {translation.watchListGrid.context.notification}
      </li>
      <li className={classes.menuRow}>
        <SsidChartIcon /> {translation.watchListGrid.context.chart}
      </li>
      <li
        className={classes.menuRow}
        onClick={deleteWatchlistInstrument}
      >
        <DeleteIcon /> {translation.watchListGrid.context.deleteRow}
      </li>
    </ul>
  );
};
