import { atom } from 'recoil';
import { PortfolioCompositionItem } from '../types/portfolioCompositionItem';

export type PortfolioComposition = {
  isLoading?: boolean;
  portfolioItems?: Array<PortfolioCompositionItem>;
  investmentLoan?: number;
};

export const portfolioCompositionAtom = atom<PortfolioComposition | undefined>({
  key: 'portfolio/composition',
  default: undefined,
});
