import { FC } from 'react';
import { WidgetElement } from '../../../shared/types/widgetElement';
import { WatchList } from '../../watchlist/partials/WatchList';

export const WatchListWidget: FC<WidgetElement> = (widgetElement: WidgetElement) => {
  const { groupId } = widgetElement;
  return (
    <WatchList
      isWidgetMode={true}
      groupId={groupId}
    />
  );
};
