import { GridColDef } from '@mui/x-data-grid-pro';
import { MouseEvent, useMemo } from 'react';
import { NbDataGrid } from '../../../shared/components/data-grid/DataGrid';
import { OpenOrder, OpenOrders } from '../../../shared/services/useOpenOrders';
import { allColumns, useOpenOrdersColumns } from '../services/useOpenOrdersColumns';
import classes from './OpenPositionsGrid.module.scss';

export interface OpenOrdersGridProps {
  openOrders?: OpenOrders;
  onEditOrder: (event: MouseEvent<HTMLButtonElement>, row: OpenOrder) => void;
  onCancelOrder: (event: MouseEvent<HTMLButtonElement>, row: OpenOrder) => void;
  loading?: boolean;
}

export const OpenOrdersGrid = ({ openOrders, onEditOrder, onCancelOrder, loading }: OpenOrdersGridProps) => {
  const { getOpenOrdersColumn, getOpenOrderFlatActions } = useOpenOrdersColumns();

  const columns: Array<GridColDef<OpenOrder>> = useMemo(() => {
    return [...allColumns]
      .map(column => getOpenOrdersColumn(column.field))
      .concat(getOpenOrderFlatActions({ onEdit: onEditOrder, onCancelOrder: onCancelOrder }));
  }, [getOpenOrdersColumn, getOpenOrderFlatActions, onEditOrder, onCancelOrder]);

  return (
    <>
      <NbDataGrid
        getRowId={row => row.id}
        getRowClassName={params =>
          params.indexRelativeToCurrentPage % 2 === 0 ? classes.nbOpenPositionsGridRowIsEven : ''
        }
        loading={openOrders?.isLoading || loading}
        rows={openOrders?.openOrders || []}
        columns={columns}
        initialState={{ pinnedColumns: { right: ['actions'] } }}
      />
    </>
  );
};
