import { CircularProgress } from '@mui/material';
import classes from './Loader.module.scss';

export type NbLoaderProps = {
  verticalCenter?: boolean;
  horizontalCenter?: boolean;
  className?: string;
};

export const NbLoader = ({ horizontalCenter, verticalCenter, className }: NbLoaderProps) => {
  const loader = <CircularProgress size={classes.defaultSize} />;
  if (!horizontalCenter && !verticalCenter && !className) {
    return loader;
  }
  return (
    <div
      className={`${classes.loader} ${className ? className : ''} ${
        horizontalCenter ? classes.loaderIsHorizontallyCenter : ''
      } ${verticalCenter ? classes.loaderIsVerticallyCenter : ''}`}
    >
      {loader}
    </div>
  );
};
