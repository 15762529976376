import { useRecoilState } from 'recoil';
import { UserSetting, UserSettingsResource } from '../../../shared/resources/userSettings.resource';
import { useResource } from '../../../shared/services/useResource';
import { layoutStateAtom } from '../../../shared/state/layoutStateAtom';
import { widgetStateAtom } from '../../../shared/state/widgetStateAtom';
import { DefaultLayout, Layout } from '../../../shared/types/layoutState';

export const useLayout = () => {
  const [layoutState, setLayouts] = useRecoilState(layoutStateAtom);
  const [, setWidgetState] = useRecoilState(widgetStateAtom);
  const userSettingsResource = useResource(UserSettingsResource);

  // TODO: error handling
  const selectLayout = (layoutId: number | DefaultLayout) => {
    const newLayoutState = { ...layoutState, selectedLayoutId: layoutId };
    userSettingsResource.updateUserSetting(UserSetting.Layout, newLayoutState);
    setLayouts(newLayoutState);

    const widgetConfig = userSettingsResource.getWidgetConfig(layoutId);
    setWidgetState(widgetConfig);
  };

  const addLayout = (layout: Layout) => {
    const newLayoutState = { ...layoutState, layouts: [...layoutState.layouts, { ...layout }] };
    setLayouts(newLayoutState);
    userSettingsResource.updateUserSetting(UserSetting.Layout, newLayoutState);
  };

  const deleteLayout = (layoutId: number) => {
    const newLayoutState = { ...layoutState, layouts: layoutState.layouts.filter(layout => layout.id !== layoutId) };
    setLayouts(newLayoutState);
    userSettingsResource.updateUserSetting(UserSetting.Layout, newLayoutState);
  };

  return {
    selectLayout,
    addLayout,
    deleteLayout,
    layouts: layoutState.layouts,
    selectedLayoutId: layoutState.selectedLayoutId,
  };
};
