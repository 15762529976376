import CloseIcon from '@mui/icons-material/Close';
import { useCallback, useEffect, useState } from 'react';
import { useLocale } from '../../../context/NbLocalizationContext';
import { useSnackbar } from '../../../context/SnackbarContext';
import { NbButton } from '../../../shared/components/button/Button';
import { NbIconButton } from '../../../shared/components/icon-button/IconButton';
import { normalOrderSchema } from '../schemas/orderSchemas';
import { useOrderManagment } from '../services/useOrderManagment';
import { OrderConfirm } from './OrderConfirm';
import { OrderForm, OrderFormData } from './OrderForm';
import classes from './OrderModal.module.scss';

export interface OrderModalProps {
  onCancel: () => void;
  defaultContent?: 'form' | 'confirm';
  order?: OrderFormData;
}

export const OrderModal = ({ onCancel, defaultContent = 'form', ...props }: OrderModalProps) => {
  const { translation } = useLocale();
  const [activeContent, setActiveContent] = useState<'form' | 'confirm'>(defaultContent);

  const [orderState, setOrderState] = useState(props.order ?? {});
  const { createOrder, costCalculation, mapOrderFormToOrderRequest, modifyOrder } = useOrderManagment();
  const [isValid, setIsValid] = useState(false);
  const [expectedCost, setExpectedCost] = useState<number>();
  const { showError, showSuccess } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);

  const onSendHandle = () => {
    try {
      normalOrderSchema.validateSync(orderState, { abortEarly: false });
      setActiveContent('confirm');
    } catch (e) {
      // TODO: validation error handling
      showError(e);
    }
  };

  const handleOnCancel = () => {
    if (activeContent === 'confirm' && defaultContent === 'form') {
      setActiveContent('form');
    } else {
      onCancel();
    }
  };

  const calculateCost = async () => {
    try {
      setIsLoading(true);
      const constCalculationResponse = await costCalculation(mapOrderFormToOrderRequest(orderState));
      setExpectedCost(constCalculationResponse.expectedCost);
    } catch (error) {
      showError(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (activeContent === 'confirm') {
      calculateCost();
    }
    // calculateCost shouldn't be wrapped in useCallback, since it triggers a rerender, making it a render loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeContent]);

  useEffect(() => {
    try {
      normalOrderSchema.validateSync(orderState, { abortEarly: false });
      setIsValid(true);
    } catch (e) {
      setIsValid(false);
    }
  }, [orderState]);

  const onConfirmHandle = useCallback(async () => {
    try {
      setIsLoading(true);
      const orderRequest = mapOrderFormToOrderRequest(orderState);
      if (orderState.id) {
        await modifyOrder(orderState.id, orderRequest);
      } else {
        await createOrder(orderRequest);
      }

      showSuccess(translation.orderModal.createOrderSuccess);
      onCancel();
    } catch (error) {
      showError(error);
    } finally {
      setIsLoading(false);
    }
  }, [createOrder, onCancel, orderState, translation, mapOrderFormToOrderRequest, modifyOrder, showError, showSuccess]);

  return (
    <div className={classes.order}>
      <div className={classes.orderHeader}>
        <h4 className={classes.orderTitle}>{translation.orderModal.order}</h4>
        <NbIconButton
          onClick={onCancel}
          icon={<CloseIcon />}
        />
      </div>

      {/* TODO: animation transition */}
      {activeContent === 'form' && (
        <OrderForm
          order={orderState}
          onChange={setOrderState}
          onInstrumentChange={instrument => setOrderState({ ...orderState, instrument })}
        />
      )}
      {activeContent === 'confirm' && (
        <OrderConfirm
          orderForm={orderState}
          expectedCost={expectedCost}
        />
      )}

      <div className={classes.orderButtons}>
        <NbButton
          color={'secondary'}
          onClick={handleOnCancel}
        >
          {translation.general.cancel}
        </NbButton>
        {activeContent === 'form' && (
          <NbButton
            disabled={!isValid}
            onClick={onSendHandle}
          >
            {translation.general.send}
          </NbButton>
        )}
        {activeContent === 'confirm' && (
          <NbButton
            isLoading={isLoading}
            onClick={onConfirmHandle}
          >
            {translation.general.confirm}
          </NbButton>
        )}
      </div>
    </div>
  );
};
