import { Area, AreaChart } from 'recharts';

type ChartItem = {
  value: number;
};

interface SmallChartProps {
  data: Array<ChartItem>;
}

export const IndexRowChart = ({ data }: SmallChartProps) => {
  return (
    <AreaChart
      width={120}
      height={50}
      data={data}
      margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
    >
      <defs>
        <linearGradient
          id="colorValue"
          x1="0"
          y1="0"
          x2="0"
          y2="1"
        >
          <stop
            offset={'0%'}
            stopColor="#F5F8FA"
          />
          <stop
            offset="33%"
            stopColor="#007AFF54"
          />
          <stop
            offset="100%"
            stopColor="transparent"
          />
        </linearGradient>
      </defs>
      <Area
        min={0}
        max={250}
        accumulate="sum"
        type="monotone"
        dataKey="value"
        stroke="#21416C"
        fillOpacity={1}
        fill="url(#colorValue)"
      />
    </AreaChart>
  );
};
