import { format } from 'date-fns';
import { useLocale } from '../../context/NbLocalizationContext';
import { Config } from '../config/config';
import { ListedInstrument } from '../types/listedInstrument';

export const useFormatter = () => {
  const locale = useLocale();
  const formatNumber = (
    value: number | string,
    { maximumFractionDigits = 5, ...options }: Intl.NumberFormatOptions = {},
  ): string => {
    const num = Number(value);
    if (value === '' || isNaN(num)) {
      return '-';
    }
    return new Intl.NumberFormat(locale.translation.app.lang, { maximumFractionDigits, ...options }).format(num);
  };

  const formatNumberF2 = (value: number | string): string => {
    return formatNumber(value, { maximumFractionDigits: 2, minimumFractionDigits: 2 });
  };

  const formatInteger = (value: number | string): string => {
    return formatNumber(value, { maximumFractionDigits: 0, minimumFractionDigits: 0 });
  };

  /**
   * Formats a currency value
   * @param value the value to format
   * @param instrumentOrCurrency this can be either a ListedInstrument or a currency string (e.g. 'EUR') or when null specified the base currency is used
   * @param withoutFractions if true, no fractions are displayed
   */
  const formatCurrency = (
    value: number | string,
    instrumentOrCurrency: ListedInstrument | string | null,
    withoutFractions = false,
  ): string => {
    const num = Number(value);
    if (value === '' || isNaN(num)) {
      return '-';
    }

    const fractionDigits = withoutFractions ? 0 : 2;
    const formattedNumber = new Intl.NumberFormat(locale.translation.app.lang, {
      maximumFractionDigits: fractionDigits,
      minimumFractionDigits: fractionDigits,
    }).format(num);

    const currency =
      typeof instrumentOrCurrency === 'object' && instrumentOrCurrency !== null
        ? instrumentOrCurrency.currency
        : instrumentOrCurrency || Config.NetBroker.baseCurrency;

    return `${formattedNumber} ${currency}`;
  };

  const formatPercentage = (
    value: number | string,
    { maximumFractionDigits = 2, ...options }: Intl.NumberFormatOptions = {},
  ) => {
    const num = Number(value);
    if (isNaN(num)) {
      return '-';
    }
    return new Intl.NumberFormat(locale.translation.app.lang, {
      ...options,
      style: 'percent',
      maximumFractionDigits,
    }).format(num);
  };

  const formatDate = (date: Date) => {
    return format(date, locale.translation.app.formatDate);
  };

  const formatTime = (date: Date) => {
    return format(date, locale.translation.app.formatTime);
  };

  const formatDateTime = (date: Date) => {
    return format(date, locale.translation.app.formatDateTime);
  };

  const formatDateAndTime = (date: Date) => {
    return format(date, locale.translation.app.formatDateAndTime);
  };

  return {
    formatNumber,
    formatNumberF2,
    formatInteger,
    formatCurrency,
    formatPercentage,
    formatDate,
    formatTime,
    formatDateTime,
    formatDateAndTime,
  };
};
