import { WidgetGroup, widgetGroupIdsReverse } from '../types/widgetGroup';

export const initialGroups: Record<number, WidgetGroup> = {};

for (let i = 0; i < 10; i++) {
  initialGroups[i] = {
    id: i,
    color: widgetGroupIdsReverse(i),
    instrument: null,
  };
}
