import { InfoOutlined } from '@mui/icons-material';
import { NbTooltip } from '../../../shared/components/tooltip/Tooltip';
import classes from './ProductDetailsListRow.module.scss';

interface ProductDetailsListRowProps {
  label: string;
  value: string | number;
  info?: string;
  isNumber?: boolean;
}

export const ProductDetailsListRow = ({ label, value, isNumber, info }: ProductDetailsListRowProps) => {
  return (
    <div className={classes.row}>
      <span className={classes.rowLabel}>
        {label}
        {info && (
          <NbTooltip
            title={info}
            placement="top"
          >
            <InfoOutlined />
          </NbTooltip>
        )}
      </span>

      <span className={`${classes.rowValue} ${isNumber ? classes.rowValueIsNumber : ''}`}>{value}</span>
    </div>
  );
};
