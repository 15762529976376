import { InstrumentInstumentCategoryEnum } from '@eih-netbroker/api-instrument';
import { useCallback, useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { useDictionary } from '../../../shared/services/useDictionary';
import { groupByAndMergeNumericValues, uniqueArrayBy } from '../../../shared/utils/arrayUtils';
import { shadeColor } from '../../../shared/utils/numberUtils';
import { portfolioCompositionAtom } from '../states/portfolioCompositionAtom';
import { PortfolioCompositionDisplayItem, PortfolioCompositionItem } from '../types/portfolioCompositionItem';
import { PortfolioCompositionView } from '../types/portfolioCompositionView';
import { getPortfolioItemValue, htmlNanoId } from './portfolioUtils';

export const usePortfolioCompositionChart = () => {
  const portfolioComposition = useRecoilValue(portfolioCompositionAtom);

  const portfolioItems = useMemo(() => portfolioComposition?.portfolioItems || [], [portfolioComposition]);
  const { formatAccountCategoryType, formatInstrumentCategoryType } = useDictionary();

  /**
   * Returns the weight of an account
   * @private
   */
  const getAccountWeight = useCallback(
    (portfolioItems: Array<PortfolioCompositionItem>, accountNumber: string): number => {
      return portfolioItems
        .filter(_ => _.accountNumber === accountNumber)
        .reduce((sum, item) => sum + getPortfolioItemValue(item), 0);
    },
    [],
  );

  /**
   * Returns the unique account numbers with their weights
   * sorted by weight descending
   * @private
   */
  const uniqueAccountNumbers: Array<{ accountNumber: string; weight: number }> = useMemo(() => {
    const accountNumbers = uniqueArrayBy(portfolioItems, 'accountNumber');

    const res = accountNumbers.reduce((res, accountNumber) => {
      res.push({
        accountNumber,
        weight: getAccountWeight(portfolioItems, accountNumber),
      });
      return res;
    }, [] as Array<{ accountNumber: string; weight: number }>);

    res.sort((a, b) => b.weight - a.weight);

    return res;
  }, [portfolioItems, getAccountWeight]);

  /**
   * Returns portfolio items grouped by accounts for the pie chart
   * @param accountNumberFilter - optional account number to filter by
   */
  const getAccountsForPieChart = (accountNumberFilter: string | null): Array<PortfolioCompositionDisplayItem> => {
    const accountNumbers = accountNumberFilter ? [accountNumberFilter] : uniqueAccountNumbers.map(_ => _.accountNumber);
    if (accountNumbers.length === 0) {
      return [];
    }

    return accountNumbers.map(accountNumber => {
      const name = `${formatAccountCategoryType(
        portfolioItems.find(item => item.accountNumber === accountNumber)!.accountCategory,
      )} (${accountNumber})`;
      const value = portfolioItems.reduce((sum, portfolioCompositionItem) => {
        if (portfolioCompositionItem.accountNumber === accountNumber) {
          return sum + getPortfolioItemValue(portfolioCompositionItem);
        }
        return sum;
      }, 0);
      return {
        id: htmlNanoId(),
        payloadId: accountNumber,
        name,
        value,
      };
    });
  };

  /**
   * Returns portfolio items grouped by instrument class for the pie chart
   */
  const getInstrumentClassForPieChart = (
    accountNumberFilter: string | null,
  ): Array<PortfolioCompositionDisplayItem> => {
    const filteredPortfolioItems = accountNumberFilter
      ? portfolioItems.filter(_ => _.accountNumber === accountNumberFilter)
      : portfolioItems;
    const instrumentCategories = uniqueArrayBy(
      accountNumberFilter ? filteredPortfolioItems : portfolioItems,
      'instrumentCategory',
    );
    return instrumentCategories.map(instrumentCategory => {
      const value = filteredPortfolioItems.reduce((sum, portfolioCompositionItem) => {
        if (portfolioCompositionItem.instrumentCategory === instrumentCategory) {
          return sum + getPortfolioItemValue(portfolioCompositionItem);
        }
        return sum;
      }, 0);
      return {
        id: htmlNanoId(),
        payloadId: instrumentCategory,
        name: formatInstrumentCategoryType(instrumentCategory),
        value,
      };
    });
  };

  const getInstrumentsForPieChart = (
    accountNumberFilter: string | null,
    instrumentCategory: string,
  ): Array<PortfolioCompositionDisplayItem> => {
    const filteredPortfolioItems = (
      accountNumberFilter ? portfolioItems.filter(_ => _.accountNumber === accountNumberFilter) : portfolioItems
    ).filter(_ => _.instrumentCategory === instrumentCategory);

    const uniqueInstruments = groupByAndMergeNumericValues(filteredPortfolioItems, 'instrumentName', {});
    return uniqueInstruments.map(portfolioCompositionItem => ({
      id: htmlNanoId(),
      payloadId: portfolioCompositionItem.instrumentName,
      name: portfolioCompositionItem.instrumentName,
      value: getPortfolioItemValue(portfolioCompositionItem),
    }));
  };

  /**
   * Calculates the color of a portfolio item
   */
  const getPortfolioItemColor = (props: {
    view: PortfolioCompositionView | null;
    assetColors: Record<InstrumentInstumentCategoryEnum, string>;
    unknownColor: string;
    ersteColor: string;
    payloadId: string;
  }) => {
    if (props.view === PortfolioCompositionView.InstrumentClass) {
      const instrumentCategory = portfolioItems.find(
        item => item.instrumentCategory === props.payloadId,
      )?.instrumentCategory;

      return (
        (instrumentCategory ? props.assetColors[instrumentCategory as InstrumentInstumentCategoryEnum] : '') ||
        props.unknownColor
      );
    } else if (props.view === PortfolioCompositionView.Accounts) {
      const ind = uniqueAccountNumbers.findIndex(item => item.accountNumber === props.payloadId);
      const step = 100 / uniqueAccountNumbers.length;
      return shadeColor(props.ersteColor, step * ind);
    } else {
      return props.unknownColor;
    }
  };

  const highlightPieItem = (displayData: Array<PortfolioCompositionDisplayItem>, renderId: string) => {
    const otherIds = displayData
      .filter(item => item.id !== renderId)
      .map(_ => [`#${_.id}`, `#legend_${_.id}`])
      .flat()
      .join(',');
    if (otherIds === '') {
      return;
    }

    // highlight the selected pie and legend item
    document.querySelectorAll(otherIds).forEach(item => ((item as unknown as SVGElement).style.opacity = '0.5'));
  };

  const resetPieHighlight = (displayData: Array<PortfolioCompositionDisplayItem>) => {
    const allIds = displayData
      .map(_ => [`#${_.id}`, `#legend_${_.id}`])
      .flat()
      .join(',');
    if (allIds === '') {
      return;
    }
    document.querySelectorAll(allIds).forEach(item => {
      (item as unknown as SVGElement).style.opacity = '1';
    });
  };

  return {
    getAccountsForPieChart,
    getInstrumentClassForPieChart,
    getInstrumentsForPieChart,
    getPortfolioItemColor,
    highlightPieItem,
    resetPieHighlight,
  };
};
