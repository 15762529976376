import { Instrument as IInstrument, ListedInstrument as IListedInstrument } from '@eih-netbroker/api-instrument';

export type Instrument = IInstrument;

export type ListedInstrument = IListedInstrument;

export type ListedInstrumentLike = { ric: string; mic: string };

/**
 * Function to generate deterministic unique ID for an instrument like
 * @param item
 */
export const getInstrumentId = (item: ListedInstrumentLike) => `${item.ric}.${item.mic}`;
