import CloseIcon from '@mui/icons-material/Close';
import { useState } from 'react';
import { useLocale } from '../../../context/NbLocalizationContext';
import { NbButton } from '../../../shared/components/button/Button';
import { NbIconButton } from '../../../shared/components/icon-button/IconButton';
import { NbTextField } from '../../../shared/components/text-field/TextField';
import classes from './OpenPositionsDeleteAllModal.module.scss';

export interface OpenPositionsDeleteAllModalProps {
  onCancel: () => void;
}

export const OpenPositionsDeleteAllModal = ({ onCancel }: OpenPositionsDeleteAllModalProps) => {
  const { translation } = useLocale();
  const [inputText, setInputText] = useState('');

  return (
    <div>
      <div>
        <h4>{translation.general.confirmation}</h4>
        <NbIconButton
          icon={<CloseIcon />}
          onClick={onCancel}
        />
      </div>
      <p>{translation.openPositions.confirmationSubtitle}</p>

      <div className={classes.deleteText}>
        {translation.openPositions.confirmationText}
        <span>
          <a href="https://www.erstebank.hu">{translation.openPositions.moreInfo}</a>
        </span>
      </div>
      <p className={classes.deleteSure}>{translation.openPositions.sureText}</p>

      <NbTextField
        size={'small'}
        placeholder={translation.openPositions.surePlaceholder}
        value={inputText}
        onChange={e => setInputText(e.target.value)}
      />

      <div>
        <NbButton
          color={'secondary'}
          onClick={onCancel}
        >
          {translation.general.cancel}
        </NbButton>
        <NbButton disabled={inputText.trim() !== translation.openPositions.sure.toUpperCase()}>
          {translation.openPositions.closeAll}
        </NbButton>
      </div>
    </div>
  );
};
