import { nanoid } from 'nanoid';
import { DefaultLayout } from '../types/layoutState';
import { WidgetConfigState } from '../types/widgetConfigState';
import { WidgetType } from '../types/widgetType';
import { initialGroups } from './initialGroups';

export const betWidgetConfig: WidgetConfigState = {
  layout: DefaultLayout.BET,
  containers: [
    {
      width: 1.5,
      rows: [
        {
          height: 1,
          columns: {
            id: nanoid(),
            elements: [
              { id: nanoid(), type: WidgetType.Chart, groupId: 0 },
              { id: nanoid(), type: WidgetType.WatchList, groupId: 0 },
              { id: nanoid(), type: WidgetType.ProductDetails, groupId: 0 },
            ],
          },
        },
        {
          height: 1,
          columns: {
            id: nanoid(),
            elements: [
              { id: nanoid(), type: WidgetType.OpenPositions, groupId: 0 },
              { id: nanoid(), type: WidgetType.OpenOrders, groupId: 0 },
            ],
          },
        },
      ],
    },
    {
      width: 1,
      rows: [
        {
          height: 1,
          columns: {
            id: nanoid(),
            elements: [
              { id: nanoid(), type: WidgetType.SimpleTradeTicket, groupId: 0 },
              { id: nanoid(), type: WidgetType.OrderBook, groupId: 0 },
            ],
          },
        },
        {
          height: 1,
          columns: {
            id: nanoid(),
            elements: [
              { id: nanoid(), type: WidgetType.SimpleTradeTicket, groupId: 0 },
              { id: nanoid(), type: WidgetType.OrderBook, groupId: 0 },
            ],
          },
        },
      ],
    },
    {
      width: 1,
      rows: [
        {
          height: 1,
          columns: {
            id: nanoid(),
            elements: [
              { id: nanoid(), type: WidgetType.SimpleTradeTicket, groupId: 0 },
              { id: nanoid(), type: WidgetType.OrderBook, groupId: 0 },
            ],
          },
        },
        {
          height: 1,
          columns: {
            id: nanoid(),
            elements: [
              { id: nanoid(), type: WidgetType.SimpleTradeTicket, groupId: 0 },
              { id: nanoid(), type: WidgetType.OrderBook, groupId: 0 },
            ],
          },
        },
      ],
    },
    {
      width: 0.8,
      rows: [
        {
          height: 1,
          columns: {
            id: nanoid(),
            elements: [{ id: nanoid(), type: WidgetType.BETFillList, groupId: 0 }],
          },
        },
      ],
    },
  ],
  groups: initialGroups,
};
