import { atom } from 'recoil';
import { NbDictionaries } from '../types/dictionaries';

export const initialDictionaries: NbDictionaries = {
  accountCategoryTypes: [],
  accountProxyTypes: [],
  instrumentCategoryTypes: [],
  marketDataProviders: [],
  subscriptionPackegesProxyTypes: [],
};

export const dictionariesAtom = atom<NbDictionaries>({
  key: 'core/dictionaries',
  default: initialDictionaries,
});
