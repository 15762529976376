import {
  Configuration,
  DefaultApi as OrderManagmentApi,
  DefaultApiGetOrdersRequest as OrderManagmentApiGetOrderRequest,
  DefaultApiModifyOrderRequest as OrderManagmentApiModifyOrderRequest,
  OrderRequest,
} from '@eih-netbroker/api-order';
import { AxiosInstance } from 'axios';
import '../mock/resources/mockedOrderManagment.resource';
import { ResourceHeaders } from '../services/useResource';
import { BaseResource } from './base.resource';

export type GetOrderRequestParams = Omit<OrderManagmentApiGetOrderRequest, keyof ResourceHeaders>;

export type ModifyOrderRequest = Omit<OrderManagmentApiModifyOrderRequest, keyof ResourceHeaders>;

export class OrderManagmentResource extends BaseResource {
  // Name has to be overriden, since the build job renames it to 'r' and
  // every resource class will have the same name
  // @ts-ignore
  static readonly name = 'OrderManagmentResource';
  private orderManagmentApi: OrderManagmentApi;

  constructor(axiosInstance: AxiosInstance, basePath: string) {
    super(axiosInstance, basePath);
    this.orderManagmentApi = new OrderManagmentApi(new Configuration(), basePath, axiosInstance);
  }

  async createOrder(request: OrderRequest, headers: ResourceHeaders) {
    return this.orderManagmentApi.createOrder({ orderRequest: request, ...headers });
  }

  async getOrders(params: GetOrderRequestParams, headers: ResourceHeaders) {
    return this.orderManagmentApi.getOrders({ ...params, ...headers });
  }

  async costCalculation(request: OrderRequest, headers: ResourceHeaders) {
    return this.orderManagmentApi.costCalculation({ orderRequest: request, ...headers });
  }

  async modifyOrder(request: ModifyOrderRequest, headers: ResourceHeaders) {
    return this.orderManagmentApi.modifyOrder({ ...request, ...headers });
  }

  async cancelOrder(orderId: string, headers: ResourceHeaders) {
    await this.orderManagmentApi.cancelOrder({ orderId, ...headers });
  }

  async cancelAllOrders(headers: ResourceHeaders) {
    await this.orderManagmentApi.cancelAllOrders({ ...headers });
  }
}
