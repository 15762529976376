import { ListedInstrument } from '@eih-netbroker/api-instrument';
import { useCallback } from 'react';
import { InstrumentResource } from '../resources/instrument.resource';
import { useResource, useResourceHeaders } from './useResource';

export const useInstrumentSearch = () => {
  const instrumentResource = useResource(InstrumentResource);
  const headers = useResourceHeaders();

  const searchInstrument = useCallback(
    async (query: string): Promise<Array<ListedInstrument>> => {
      if (query.trim() === '') {
        return [];
      }
      const q = query.toUpperCase();

      const response = await instrumentResource.searchListedInstrument(q, headers);

      return response.data;
    },
    [instrumentResource, headers],
  );

  const batchInstrumentSearch = useCallback(
    async (isins: Array<string>): Promise<Array<ListedInstrument>> => {
      const response = await instrumentResource.batchInstrumentQuery({ isins }, headers);

      return response.data;
    },
    [instrumentResource, headers],
  );

  return {
    searchInstrument,
    batchInstrumentSearch,
  };
};
