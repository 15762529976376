import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useState } from 'react';
import { useLocale } from '../../../context/NbLocalizationContext';
import { NbButton } from '../../../shared/components/button/Button';
import { NbIconButton } from '../../../shared/components/icon-button/IconButton';
import { NbSwitch } from '../../../shared/components/toggle-button/Switch';
import { ConsentStatus, useConsent } from '../services/useConsent';
import classes from './CookieConsent.module.scss';

export const CookieConsent = () => {
  const { getCookieConsent, acceptCookieConsent, rejectCookieConsent } = useConsent();
  const [consentStatus, setConsentStatus] = useState(getCookieConsent());
  const [isShowDetails, setIsShowDetails] = useState<boolean>(false);
  const { translation } = useLocale();

  const acceptCookies = () => {
    setConsentStatus(ConsentStatus.Consented);
    acceptCookieConsent();
  };

  const rejectCookies = () => {
    setConsentStatus(ConsentStatus.Rejected);
    rejectCookieConsent();
  };

  const onShowDetails = () => {
    setIsShowDetails(!isShowDetails);
  };

  if (consentStatus) {
    return <></>;
  }

  return (
    <div className={classes.consentWrapper}>
      <div className={classes.consentHeader}>
        <h2>{translation.app.consent.header}</h2>
        <NbIconButton
          icon={<CloseIcon />}
          onClick={rejectCookies}
        />
      </div>
      <div className={`${classes.consentText} ${!isShowDetails ? classes.consentTextIsTeaser : ''}`}>
        <div dangerouslySetInnerHTML={{ __html: translation.app.consent.textHTML }}></div>
        {!isShowDetails && <div className={classes.consentTextBlur}></div>}
      </div>
      <div className={classes.consentDetails}>
        <NbButton
          color={'secondary'}
          variant={'text'}
          onClick={onShowDetails}
        >
          {translation.app.consent.details}
          <span>{isShowDetails ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}</span>
        </NbButton>
      </div>

      <div className={classes.radios}>
        <div className={classes.radiosItem}>
          <p>{translation.app.consent.necessary}</p>
          <div className={classes.radiosSwitch}>
            <NbSwitch
              defaultChecked
              disabled
            />
          </div>
        </div>
        <div className={classes.radiosItem}>
          <p>{translation.app.consent.statistics}</p>
          <div className={classes.radiosSwitch}>
            <NbSwitch />
          </div>
        </div>
      </div>

      <div className={classes.actions}>
        <NbButton
          variant={'outlined'}
          color={'secondary'}
          onClick={rejectCookies}
        >
          {translation.app.consent.reject}
        </NbButton>
        <NbButton
          color={'secondary'}
          variant={'outlined'}
          size={'small'}
          onClick={acceptCookies}
        >
          {translation.app.consent.allowSelected}
        </NbButton>
        <NbButton
          className={classes.actionsIsAccept}
          onClick={acceptCookies}
        >
          {translation.app.consent.allowAll}
        </NbButton>
      </div>
    </div>
  );
};
