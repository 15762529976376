import { PortfolioComposition } from '../../portfolio/partials/PortfolioComposition';
import { WatchList } from '../../watchlist/partials/WatchList';
import { Carousel } from '../components/Carousel';
import { IndexRow } from '../components/IndexRow';
import { MarketHint, MarketHintColor } from '../components/MarketHint';
import classes from './HomePage.module.scss';

export type MarketHintItem = {
  id: number;
  hash: string;
  name: string;
  ric: string;
  openPrice: number;
  takeProfit: number;
  openDate: string;
  stopLoss: number;
  directionId: string;
  relatedContent: { id: number; url: string };
};

export const HomePage = () => {
  const MOCK_DATA: Array<MarketHintItem> = [
    {
      id: 272,
      hash: '633316ddc81e0',
      name: 'Arch Capital Group Ltd.',
      ric: 'ACGL',
      openPrice: 71.68,
      openDate: '2023-06-29',
      takeProfit: 79.9,
      stopLoss: 68.9,
      directionId: 'buy',
      relatedContent: {
        id: 118701,
        url: 'https://www.erstemarket.hu/tartalom/156122/arch-capital-van-akinek-nem-fajnak-a-magas-kamatok-20230629',
      },
    },
    {
      id: 273,
      hash: '633316ddc81f5',
      name: 'Microchip Technology Incorporated',
      ric: 'MCHP',
      openPrice: 86.46,
      openDate: '2021-06-09',
      takeProfit: 119.0,
      stopLoss: 68.9,
      directionId: 'buy',
      relatedContent: {
        id: 118702,
        url: 'https://www.erstemarket.hu/tartalom/154032/ujra-megvesszuk-a-microchip-technology-t-20230614',
      },
    },
  ];

  const MOCK_CAROUSEL_PROMO = [
    {
      title: `Hozamtermelés nehéz piaci körülmények között`,
      link: `https://google.com`,
      backgroundColor: 'green',
    },
    {
      title: `Mindent a jövő befektetéseiről - Erste Future webinárium`,
      link: `https://google.com`,
      backgroundColor: 'blue',
    },
    {
      title: `Magyarország a recesszió küszöbén`,
      link: `https://google.com`,
      backgroundColor: 'orange',
    },
  ];

  const MOCK_CAROUSEL_TOP = [
    {
      title: `Nyugtat a svájci jegybank`,
      link: `https://google.com`,
      backgroundPhoto:
        'https://images.pexels.com/photos/8577758/pexels-photo-8577758.jpeg?auto=compress&cs=tinysrgb&w=1600',
    },
    {
      title: `Tovább hűtené a várakozásokat az EKB`,
      link: `https://google.com`,
      backgroundPhoto:
        'https://images.pexels.com/photos/1179973/pexels-photo-1179973.jpeg?auto=compress&cs=tinysrgb&w=1600',
    },
    {
      title: `A Nissan részvényei emelkednek a Renault szövetség átalakítása után`,
      link: `https://google.com`,
      backgroundPhoto:
        'https://images.pexels.com/photos/775203/pexels-photo-775203.jpeg?auto=compress&cs=tinysrgb&w=1600',
    },
  ];

  return (
    <div className={classes.homePage}>
      <div className={classes.container}>
        <div className={classes.indexRow}>
          <IndexRow />
        </div>
        <div className={classes.portfolioContainer}>
          <PortfolioComposition homePage={true} />
        </div>
        <div className={classes.watchlistContainer}>
          <WatchList isWidgetMode={true} />
        </div>

        <div className={classes.marketCarouselContainer}>
          <Carousel
            isPromo={true}
            data={MOCK_CAROUSEL_PROMO}
          />
          <Carousel
            isPromo={false}
            data={MOCK_CAROUSEL_TOP}
          />
        </div>

        <div className={classes.marketHintContainer}>
          <MarketHint
            color={MarketHintColor.Dark}
            data={MOCK_DATA[0]}
          />
          <MarketHint
            color={MarketHintColor.Light}
            data={MOCK_DATA[1]}
          />
        </div>
      </div>
    </div>
  );
};
