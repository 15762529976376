import { OrderRequest, OrderRequestStateEnum } from '@eih-netbroker/api-order';
import { useCallback } from 'react';
import { useRecoilState } from 'recoil';
import { InstrumentResource } from '../../../shared/resources/instrument.resource';
import { OrderManagmentResource } from '../../../shared/resources/orderManagment.resource';
import { useResource, useResourceHeaders } from '../../../shared/services/useResource';
import { Order } from '../../../shared/types/order';
import { OrderFormData } from '../components/OrderForm';
import { Orders, ordersAtom } from '../states/ordersAtom';

export const useOrderManagment = () => {
  const [orders, setOrders] = useRecoilState(ordersAtom);

  const orderManagmentResource = useResource(OrderManagmentResource);
  const instrumentResource = useResource(InstrumentResource);
  const resourceHeaders = useResourceHeaders();

  const loadOrders = useCallback(
    async (clear: boolean = true) => {
      setOrders(curr => ({ isLoading: true, orders: clear ? [] : curr.orders }));

      try {
        const response = await orderManagmentResource.getOrders({}, resourceHeaders);
        const instruments = await instrumentResource.batchInstrumentQuery(
          { isins: response.data.map(order => order.isin!) },
          resourceHeaders,
        );

        const orders: Orders = {
          orders: response.data.map<Order>(order => ({
            ...order,
            instrument: instruments.data.find(instrument => instrument.isin === order.isin),
          })),
          isLoading: false,
        };
        setOrders(orders);
      } catch (error) {
        setOrders({ isLoading: false, orders: [] });
        throw error;
      }
    },
    [orderManagmentResource, resourceHeaders, setOrders, instrumentResource],
  );

  const createOrder = useCallback(
    async (order: OrderRequest) => {
      await orderManagmentResource.createOrder(order, resourceHeaders);
      loadOrders(false);
    },
    [orderManagmentResource, resourceHeaders, loadOrders],
  );

  const modifyOrder = useCallback(
    async (orderId: string, order: OrderRequest) => {
      await orderManagmentResource.modifyOrder(
        {
          orderId,
          orderRequest: order,
        },
        resourceHeaders,
      );
      loadOrders(false);
    },
    [orderManagmentResource, resourceHeaders, loadOrders],
  );

  const costCalculation = useCallback(
    async (order: OrderRequest) => {
      const response = await orderManagmentResource.costCalculation(order, resourceHeaders);
      return response.data;
    },
    [orderManagmentResource, resourceHeaders],
  );

  const mapOrderFormToOrderRequest = useCallback(
    (order: OrderFormData): OrderRequest => ({
      isin: order.instrument?.isin,
      quantity: order.quantity!,
      stopLimitPrice: order.stopLimitPrice,
      stopActivationPrice: order.stopActivationPrice,
      expiryType: order.expiryType!,
      expiry: order.expiry?.toString(),
      direction: order.tradeType!,
      orderType: order.orderType!,
      tradingSession: order.tradingSession!,
      mic: order.instrument!.mic,
      price: order.stopLimitPrice!,
      inventoryManagementMethod: order.inventoryManagmentMethod,
      // TODO: update api and remove next line
      state: OrderRequestStateEnum.Active,
    }),
    [],
  );

  const mapOrderToOrderForm = useCallback(
    (order: Order): OrderFormData => ({
      id: order.id,
      instrument: order.instrument,
      orderType: order.orderType,
      tradeType: order.direction,
      quantity: order.quantity,
      stopLimitPrice: order.stopLimitPrice,
      stopActivationPrice: order.stopActivationPrice,
      expiryType: order.expiryType,
      expiry: order.expiry ? new Date(order.expiry) : undefined,
      inventoryManagmentMethod: order.inventoryManagementMethod,
      tradingSession: order.tradingSession,
    }),
    [],
  );

  const cancelAllOrders = useCallback(async () => {
    await orderManagmentResource.cancelAllOrders(resourceHeaders);
    loadOrders(false);
  }, [orderManagmentResource, resourceHeaders, loadOrders]);

  const cancelOrder = useCallback(
    async (orderId: string) => {
      await orderManagmentResource.cancelOrder(orderId, resourceHeaders);
      loadOrders(false);
    },
    [orderManagmentResource, resourceHeaders, loadOrders],
  );

  return {
    createOrder,
    costCalculation,
    mapOrderFormToOrderRequest,
    mapOrderToOrderForm,
    cancelAllOrders,
    cancelOrder,
    modifyOrder,
    loadOrders,
    orders,
  };
};
