import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { useMemo } from 'react';
import { useFormatter } from '../../../shared/services/useFormatter';
import { IndexRowInstrument } from '../../../shared/state/indexRowStateAtom';
import { IndexRowChart } from './IndexRowChart';
import classes from './IndexRowElement.module.scss';

interface IndexRowElementProps {
  instrument: IndexRowInstrument;
}

export const IndexRowElement = ({ instrument }: IndexRowElementProps) => {
  const { values, closePrice } = instrument;
  const { formatNumber } = useFormatter();

  const currentChange = useMemo(() => {
    return values[values.length - 1].value - closePrice;
  }, [values, closePrice]);

  return (
    <div className={classes.indexRowElement}>
      <div className={classes.indexRowElementContent}>
        <span className={classes.indexRowElementInstrumentName}>{instrument.instrument.ticker}</span>
        <span className={classes.indexRowElementValue}>
          {formatNumber(values[values.length - 1].value, { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
        </span>
        <div
          className={`${classes.indexRowElementValueChange} ${
            currentChange >= 0
              ? classes.indexRowElementValueChangeIsPositive
              : classes.indexRowElementValueChangeIsNegative
          }`}
        >
          {currentChange >= 0 ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}

          <span>
            {formatNumber(Math.abs(currentChange), {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            })}
          </span>
        </div>
      </div>
      <IndexRowChart data={values} />
    </div>
  );
};
