import { nanoid } from 'nanoid';
import { useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { useSSEConnection } from '../../../context/SSEConnectionContext';
import { mockedInstrument } from '../../../shared/mock/mockedInstruments';
import { ListedInstrument } from '../../../shared/types/listedInstrument';
import { betFillListAtom, BETFillListItem } from '../states/betFillListAtom';

export const useBETFillList = () => {
  const [betFillList, setBetFillList] = useRecoilState(betFillListAtom);
  const { instrumentStore } = useSSEConnection();

  const initBETFillList = () => {
    instrumentStore.registerAll(
      [
        mockedInstrument('OTP'),
        mockedInstrument('MOL'),
        mockedInstrument('MTELEKOM'),
        mockedInstrument('RICHTER'),
        mockedInstrument('4IG'),
        mockedInstrument('OPUS'),
        mockedInstrument('MASTERPLAST'),
      ],
      instrumentStoreItems => {
        setBetFillList(_prevBetFillList => {
          const updatedList: Array<BETFillListItem> = [
            ...instrumentStoreItems.values().map(instrumentStoreItem => ({
              id: nanoid(),
              instrument: instrumentStoreItem as unknown as ListedInstrument,
              price: instrumentStoreItem.lastPrice!,
              time: instrumentStoreItem.lastTradeTime!,
              quantity: instrumentStoreItem.lastTradeQty!,
            })),
            ...(_prevBetFillList?.list || []),
          ];

          return { list: updatedList.slice(0, 25) };
        });
      },
    );
  };

  useEffect(() => {
    initBETFillList();
    // temporary data, so we can ignore react...
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    betFillList,
  };
};
