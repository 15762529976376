import { IconButton, IconButtonProps } from '@mui/material';
import { ReactElement, ReactNode } from 'react';

export interface NbIconButtonProps extends IconButtonProps {
  icon: ReactNode;
  iconLabel?: string;
}

export const NbIconButton = ({
  color = `primary`,
  size = `medium`,
  icon,
  iconLabel = `icon`,
  ...props
}: NbIconButtonProps): ReactElement => {
  return (
    <IconButton
      aria-label={iconLabel}
      color={color}
      size={size}
      {...props}
    >
      {icon}
    </IconButton>
  );
};
