import { GridActionsCellItem, GridAddIcon, GridColDef, GridRenderCellParams, GridRowParams } from '@mui/x-data-grid';
import { useLocale } from '../../../context/NbLocalizationContext';
import { useFormatter } from '../../../shared/services/useFormatter';
import { TradeType } from '../../../shared/types/tradeType';
import { WithoutId } from '../../../shared/types/withoutId';
import classes from '../../trading/components/OrderBookWidget.module.scss';
import { OrderBookTooltip } from '../partials/OrderBookTooltip';

export type OrderBookInstrument = {
  id: string;
  saleQuantity: number;
  salePrice: number;
  purchaseQuantity: number;
  purchasePrice: number;
};

export const orderBookInstrumentFields: Array<keyof WithoutId<OrderBookInstrument>> = [
  'purchaseQuantity',
  'purchasePrice',
  'salePrice',
  'saleQuantity',
];

export type OrderBookColumn = {
  field: keyof WithoutId<OrderBookInstrument>;
};

export const allColumns: Array<OrderBookColumn> = orderBookInstrumentFields.map(field => ({ field }));

export type OrderBook = {
  columns: Array<OrderBookColumn>;
  items: Array<OrderBookInstrument>;
};

export interface OrderBookGridColumnActions {
  onPriceClick: (id: string, tradeType: TradeType) => void;
  onQuantityClick: (id: string, tradeType: TradeType) => void;
}

export const useOrderBookColumns = () => {
  const { formatNumber, formatNumberF2 } = useFormatter();
  const { translation } = useLocale();

  const getOrderBookColumn = (
    column: keyof WithoutId<OrderBookInstrument>,
    hoveredRow: string | null,
    actions: OrderBookGridColumnActions,
  ): GridColDef<OrderBookInstrument> => {
    const baseColDef: GridColDef<OrderBookInstrument> = {
      field: column,
      headerName: translation.orderbook.grid[column],
      sortable: false,
      disableColumnMenu: true,
    };

    switch (column) {
      case 'saleQuantity':
        return {
          ...baseColDef,
          width: 75,
          renderCell: (params: GridRenderCellParams<OrderBookInstrument>) => {
            return (
              <span
                onClick={() => actions.onQuantityClick(params.row.id, TradeType.Sell)}
                className={`${classes.nbOrderBookGridCellBox} ${
                  hoveredRow === params.row.id ? classes.nbOrderBookGridCellBoxIsHover : ''
                } ${classes.nbOrderBookGridCellBoxIsSell}`}
              >
                {formatNumber(params.row.saleQuantity)}
              </span>
            );
          },
        };
      case 'salePrice':
        return {
          ...baseColDef,
          width: 75,
          renderCell: (params: GridRenderCellParams<OrderBookInstrument>) => {
            return (
              <span
                onClick={() => actions.onPriceClick(params.row.id, TradeType.Sell)}
                className={`${classes.nbOrderBookGridCellBox} ${
                  hoveredRow === params.row.id ? classes.nbOrderBookGridCellBoxIsHover : ''
                } ${classes.nbOrderBookGridCellBoxIsSell}`}
              >
                {formatNumberF2(params.row.salePrice)}
              </span>
            );
          },
        };
      case 'purchasePrice':
        return {
          ...baseColDef,
          width: 75,
          renderCell: (params: GridRenderCellParams<OrderBookInstrument>) => {
            return (
              <span
                onClick={() => actions.onPriceClick(params.row.id, TradeType.Buy)}
                className={`${classes.nbOrderBookGridCellBox} ${
                  hoveredRow === params.row.id ? classes.nbOrderBookGridCellBoxIsHover : ''
                } ${classes.nbOrderBookGridCellBoxIsBuy}`}
              >
                {formatNumberF2(params.row.purchasePrice)}
              </span>
            );
          },
        };
      case 'purchaseQuantity':
        return {
          ...baseColDef,
          width: 75,
          renderCell: (params: GridRenderCellParams<OrderBookInstrument>) => {
            return (
              <span
                onClick={() => actions.onQuantityClick(params.row.id, TradeType.Buy)}
                className={`${classes.nbOrderBookGridCellBox} ${
                  hoveredRow === params.row.id ? classes.nbOrderBookGridCellBoxIsHover : ''
                } ${classes.nbOrderBookGridCellBoxIsBuy}`}
              >
                {formatNumber(params.row.purchaseQuantity)}
              </span>
            );
          },
        };
      default:
        throw new Error(`Invalid column ${column}`);
    }
  };

  const getSellAction = (hoveredRow: string | null, onClick: (id: string) => void): GridColDef<OrderBookInstrument> => {
    return {
      field: 'rightActions',
      type: 'actions',
      minWidth: 36,
      width: 36,
      getActions: (params: GridRowParams<OrderBookInstrument>) => {
        return hoveredRow === params.id
          ? [
              <OrderBookTooltip
                title={`${translation.orderbook.sell} @ ${formatNumberF2(params.row.purchasePrice)}`}
                arrow
                placement="right"
              >
                <GridActionsCellItem
                  onClick={() => onClick(params.row.id)}
                  className={`${classes.actionIcon} ${classes.actionIconIsSellPrice}`}
                  icon={<GridAddIcon />}
                  label={'Add'}
                />
              </OrderBookTooltip>,
            ]
          : [];
      },
    };
  };

  const getBuyAction = (hoveredRow: string | null, onClick: (id: string) => void) => {
    return {
      field: 'leftActions',
      type: 'actions',
      minWidth: 36,
      width: 36,
      getActions: (params: GridRowParams<OrderBookInstrument>) => {
        return hoveredRow === params.id
          ? [
              <OrderBookTooltip
                title={`${translation.orderbook.buy} @ ${formatNumberF2(params.row.purchasePrice)}`}
                arrow
                placement="left"
              >
                <GridActionsCellItem
                  onClick={() => onClick(params.row.id)}
                  className={`${classes.actionIcon} ${classes.actionIconIsBuyPrice}`}
                  icon={<GridAddIcon />}
                  label={'Add'}
                />
              </OrderBookTooltip>,
            ]
          : [];
      },
    };
  };

  return { getOrderBookColumn, getSellAction, getBuyAction };
};
