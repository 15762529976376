import {
  CostCalculationResponse,
  Order,
  OrderCreatedResponse,
  OrderRequest,
  OrderStateEnum,
} from '@eih-netbroker/api-order';
import MockAdapter from 'axios-mock-adapter';
import { nanoid } from 'nanoid';
import { axiosInstance } from '../../services/useResource';
import { ExpiryType } from '../../types/expiryType';
import { InventoryManagmentMethod } from '../../types/inventoryManagmentMethod';
import { OrderType } from '../../types/orderType';
import { TradeType } from '../../types/tradeType';
import { TradingSession } from '../../types/tradingSession';
import { mockedInstrument } from '../mockedInstruments';

const mock = new MockAdapter(axiosInstance, { onNoMatch: 'passthrough', delayResponse: 1000 });

let orders: Order[] = [
  {
    id: nanoid(),
    mic: mockedInstrument('TSLA').mic,
    isin: mockedInstrument('TSLA').isin,
    quantity: 100,
    price: 100,
    orderType: OrderType.Limit,
    direction: TradeType.Buy,
    accountId: '1',
    expiryType: ExpiryType.Day,
    expiry: new Date().toString(),
    state: OrderStateEnum.Active,
    stopLimitPrice: 100,
    tradingSession: TradingSession.Open,
  },
  {
    id: nanoid(),
    mic: mockedInstrument('OTP').mic,
    isin: mockedInstrument('OTP').isin,
    quantity: 100,
    price: 100,
    orderType: OrderType.StopLimit,
    direction: TradeType.Sell,
    accountId: '1',
    expiryType: ExpiryType.Day,
    expiry: new Date().toString(),
    state: OrderStateEnum.Active,
    inventoryManagementMethod: InventoryManagmentMethod.Lifo,
    stopActivationPrice: 100,
    stopLimitPrice: 100,
    tradingSession: TradingSession.Closing,
  },
];

mock.onGet(new RegExp('https://api.eih-netbroker.com/accounts/[0-9]*/orders')).reply(config => {
  const urlParts = config.url?.split('/');
  const accountId = urlParts?.[urlParts.length - 2];

  return [200, orders.filter(order => order.accountId === accountId)];
});

mock.onPost(new RegExp('https://api.eih-netbroker.com/accounts/[0-9]*/orders')).reply(config => {
  const data = JSON.parse(config.data) as OrderRequest;
  const urlParts = config.url?.split('/');
  const accountId = urlParts?.[urlParts.length - 2];
  const orderId = nanoid();

  if (!accountId) {
    return [400, 'Invalid account id'];
  }

  if (data.mic !== 'BET') {
    return [400, 'Invalid mic'];
  }

  orders.push({ ...data, accountId: accountId, id: orderId });
  const response: OrderCreatedResponse = {
    id: orderId,
    confirmationNeeded: false,
    confirmed: true,
  };

  return [200, response];
});

mock.onPost(new RegExp('https://api.eih-netbroker.com/accounts/[0-9]*/cost-calculation')).reply(() => {
  const response: CostCalculationResponse = {
    currency: 'USD',
    expectedCost: 300000,
  };
  return [200, response];
});

mock.onDelete(new RegExp('https://api.eih-netbroker.com/accounts/[0-9]*/orders/[0-9]*')).reply(config => {
  const urlParts = config.url?.split('/');
  const orderId = urlParts?.[urlParts.length - 1];
  orders = orders.filter(order => order.id !== orderId);
  return [200];
});

mock.onPost(new RegExp('https://api.eih-netbroker.com/accounts/[0-9]*/cancel-all-orders')).reply(config => {
  const urlParts = config.url?.split('/');
  const accountId = urlParts?.[urlParts.length - 2];
  orders = orders.filter(order => order.accountId !== accountId);
  return [200];
});

mock.onPut(new RegExp('https://api.eih-netbroker.com/accounts/[0-9]*/orders/[0-9]*')).reply(config => {
  const urlParts = config.url?.split('/');
  const accountId = urlParts?.[urlParts.length - 3];
  const orderId = urlParts?.[urlParts.length - 1];
  const data = JSON.parse(config.data) as OrderRequest;

  const newOrder = { ...data, accountId: accountId!, id: orderId! };

  orders = orders.map(order => {
    if (order.id === orderId) {
      return { ...newOrder };
    }
    return order;
  });
  return [200, newOrder];
});
