import { InstrumentInstumentCategoryEnum } from '@eih-netbroker/api-instrument';
import translation from '../../../shared/data/translation/hu_HU';
import { useFormatter } from '../../../shared/services/useFormatter';
import { getPercentOf, sumOfFieldInList } from '../../../shared/utils/numberUtils';
import { usePortfolioCompositionChart } from '../services/usePortfolioCompositionChart';
import { PortfolioCompositionDisplayItem } from '../types/portfolioCompositionItem';
import { PortfolioCompositionView } from '../types/portfolioCompositionView';
import classes from './PortfolioChartLegend.module.scss';

export type PortfolioChartLegendProps = {
  pieChartElements: Array<PortfolioCompositionDisplayItem>;
  view: PortfolioCompositionView | null;
  assetColors: Record<InstrumentInstumentCategoryEnum, string>;
  unknownColor: string;
  ersteColor: string;
  onItemHoverActive: (item: PortfolioCompositionDisplayItem) => void;
  onItemHoverDeactivated: () => void;
  onItemSelected: (item: PortfolioCompositionDisplayItem) => void;
  investmentLoan?: number;
};

export const PortfolioChartLegend = ({
  pieChartElements,
  view,
  assetColors,
  unknownColor,
  ersteColor,
  onItemHoverActive,
  onItemHoverDeactivated,
  onItemSelected,
  investmentLoan,
}: PortfolioChartLegendProps) => {
  const { getPortfolioItemColor } = usePortfolioCompositionChart();
  const { formatInteger, formatCurrency } = useFormatter();
  const sumValue = sumOfFieldInList(pieChartElements, 'value');

  const getColor = (payloadId: string) => {
    return getPortfolioItemColor({ view, assetColors, unknownColor, ersteColor, payloadId });
  };

  return (
    <div className={classes.legends}>
      {pieChartElements.map(item => (
        <div
          key={item.id}
          id={`legend_${item.id}`}
          className={classes.legend}
          onMouseOver={() => onItemHoverActive(item)}
          onMouseLeave={onItemHoverDeactivated}
          onClick={() => onItemSelected(item)}
        >
          <div
            className={classes.legendColor}
            style={{ backgroundColor: `${getColor(item.payloadId)}` }}
          />
          <div
            className={classes.legendName}
            title={`${item.name} (${formatInteger(getPercentOf(item.value, sumValue))} %)`}
          >
            {item.name} ({formatInteger(getPercentOf(item.value, sumValue))} %)
          </div>
          <div
            className={classes.legendValue}
            title={formatCurrency(item.value, null, true)}
          >
            {formatCurrency(item.value, null, true)}
          </div>
        </div>
      ))}
      {!!investmentLoan && (
        <div className={`${classes.legend} ${classes.legendIsLoan}`}>
          <div className={classes.legendName}>{translation.portfolioComposition.investmentLoan}</div>
          <div
            className={classes.legendValue}
            title={formatCurrency(investmentLoan, null, true)}
          >
            {formatCurrency(investmentLoan, null, true)}
          </div>
        </div>
      )}
    </div>
  );
};
