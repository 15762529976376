import { FormControl, InputLabel, MenuItem, Select, SelectProps } from '@mui/material';
import { ReactNode } from 'react';
import classes from './Select.module.scss';

export interface NbSelectItemOption<T> {
  id: T;
  name: string;
  logo?: ReactNode;
}

export interface NbSelectProps<T> extends SelectProps<T> {
  options: Array<NbSelectItemOption<T>>;
}

export const NbSelect = <T extends string | number>({ options, value, defaultValue, ...props }: NbSelectProps<T>) => {
  return (
    <FormControl
      className={classes.nbSelect}
      classes={{ root: classes.root }}
    >
      <InputLabel>{props.label}</InputLabel>
      <Select
        value={value}
        defaultValue={defaultValue}
        {...props}
        classes={{ select: classes.nbSelect, nativeInput: classes.nbInput }}
      >
        {options.map((option, index) => (
          <MenuItem
            key={index}
            value={option.id}
          >
            {option.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
