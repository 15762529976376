import { useCallback } from 'react';
import { useRecoilState } from 'recoil';
import { priceAlertsAtom } from '../../modules/trading/states/priceAlertsAtom';
import { mockedPriceAlerts } from '../mock/mockedPriceAlerts';
import { resolveLater } from '../utils/mockUtils';

export const usePriceAlerts = () => {
  const [priceAlerts, setPriceAlerts] = useRecoilState(priceAlertsAtom);

  const loadPriceAlerts = useCallback(async (): Promise<void> => {
    const loadedPriceAlerts = await resolveLater(mockedPriceAlerts);
    setPriceAlerts(loadedPriceAlerts);
  }, [setPriceAlerts]);

  return { priceAlerts, loadPriceAlerts };
};
