import CloseIcon from '@mui/icons-material/Close';
import EventNoteIcon from '@mui/icons-material/EventNote';
import { GridActionsCellItem, GridColDef, GridRowParams } from '@mui/x-data-grid';
import { MouseEvent } from 'react';
import { useLocale } from '../../../context/NbLocalizationContext';
import { useFormatter } from '../../../shared/services/useFormatter';
import { WithoutId } from '../../../shared/types/withoutId';
import gridClasses from '../components/OpenPositionsWidget.module.scss';
import { OpenPositionItem, OpenPositionItemGroup } from '../states/openPositionsAtom';

/**
 * Fields for the grouping of the open position item
 */
export const openPositionsItemGroupFields: Array<keyof WithoutId<OpenPositionItem>> = [
  'instrument',
  'quantity',
  'averagePrice',
  'costBasis',
  'marketValue',
  'dailyPnL',
  'unrealizedPnL',
  'unrealizedPnLPerc',
];

/**
 * Fields for the sub items of the instrument
 */
export const openPositionsSubItemFields: Array<keyof WithoutId<OpenPositionItem>> = [
  'quantity',
  'averagePrice',
  'costBasis',
  'marketValue',
  'dailyPnL',
  'unrealizedPnL',
  'unrealizedPnLPerc',
];

export type OpenPositionsColumn = {
  field: keyof WithoutId<OpenPositionItem>;
};

export interface OpenPositionsGridActions {
  onAddNote: (event: MouseEvent<HTMLButtonElement>, row: OpenPositionItemGroup) => void;
  onChangePosition: (event: MouseEvent<HTMLButtonElement>, row: OpenPositionItemGroup) => void;
}

export const allColumns: Array<OpenPositionsColumn> = openPositionsItemGroupFields.map(field => ({ field }));
export const allSubColumns: Array<OpenPositionsColumn> = openPositionsSubItemFields.map(field => ({ field }));

export const useOpenPositionsColumns = () => {
  const { formatPercentage, formatNumberF2 } = useFormatter();
  const { translation } = useLocale();

  const getOpenPositionsColumn = (column: keyof WithoutId<OpenPositionItem>): GridColDef<OpenPositionItem> => {
    const baseColDef: GridColDef<OpenPositionItem> = {
      field: column,
      headerName: translation.openPositions.grid[column],
      sortable: false,
      disableColumnMenu: true,
    };

    switch (column) {
      case 'instrument':
        return {
          ...baseColDef,
          width: 75,
          align: 'left',
          renderCell: params => {
            return <span>{(params.row as OpenPositionItemGroup).instrument.ticker}</span>;
          },
        };
      case 'quantity':
        return {
          ...baseColDef,
          width: 75,
          align: 'right',
          renderCell: params => {
            return <span>{Math.round(params.row.quantity)}</span>;
          },
        };
      case 'averagePrice':
        return {
          ...baseColDef,
          width: 75,
          align: 'right',
          renderCell: params => {
            return <span>{formatNumberF2(params.row.averagePrice)}</span>;
          },
        };
      case 'costBasis':
        return {
          ...baseColDef,
          width: 75,
          align: 'right',
          renderCell: params => {
            return <span>{formatNumberF2(params.row.costBasis)}</span>;
          },
        };
      case 'marketValue':
        return {
          ...baseColDef,
          width: 75,
          align: 'right',
          renderCell: params => {
            return <span>{formatNumberF2(params.row.marketValue)}</span>;
          },
        };
      case 'dailyPnL':
        return {
          ...baseColDef,
          width: 75,
          align: 'right',
          renderCell: params => {
            return <span className={gridClasses.green}>{formatNumberF2(params.row.dailyPnL)}</span>;
          },
        };
      case 'unrealizedPnL':
        return {
          ...baseColDef,
          width: 75,
          align: 'right',
          renderCell: params => {
            return <span className={gridClasses.green}>{formatNumberF2(params.row.unrealizedPnL)}</span>;
          },
        };
      case 'unrealizedPnLPerc':
        return {
          ...baseColDef,
          width: 75,
          align: 'right',
          renderCell: params => {
            return <span className={gridClasses.green}>{formatPercentage(params.row.unrealizedPnLPerc)}</span>;
          },
        };

      default:
        throw new Error(`Invalid column ${column}`);
    }
  };

  const getOpenPositionFlatActions = (actions: OpenPositionsGridActions): GridColDef<OpenPositionItemGroup> => {
    return {
      field: 'actions',
      type: 'actions',
      width: 70,
      getActions: (params: GridRowParams<OpenPositionItemGroup>) => [
        <GridActionsCellItem
          className={gridClasses.actionsNotes}
          icon={<EventNoteIcon />}
          label={translation.openPositions.notes}
          onClick={e => actions.onAddNote(e, params.row)}
        />,
        <GridActionsCellItem
          className={gridClasses.actionsOrder}
          icon={<CloseIcon />}
          label={translation.openPositions.order}
          onClick={e => actions.onChangePosition(e, params.row)}
        />,
      ],
    };
  };

  return { getOpenPositionsColumn, getOpenPositionFlatActions };
};
