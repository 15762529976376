import { useEffect, useRef, useState } from 'react';
import { useLocale } from '../../../context/NbLocalizationContext';
import { CloseSnackbarFn, useSnackbar } from '../../../context/SnackbarContext';
import { ConnectionState } from '../../../context/SSEConnectionContext';
import { Config } from '../../config/config';
import { Keys } from '../../config/keys';
import { SubscribeOptions } from '../../types/estream/eStreamSubscription';
import { getInstrumentId } from '../../types/listedInstrument';
import { createLogger } from '../../utils/logger';
import { ObjectSet } from '../../utils/objectSet';
import { eStreamClient } from './eStreamClient';
import { sseManager } from './SSEManager';

export type UseEStreamConnectionProps = {
  offlineMode?: boolean;
  onConnectionStateChange?: (connectionState: ConnectionState) => void;
};

export const getSubscribeOptionId = (item: SubscribeOptions): string =>
  `${item.source}_${item.instrument.ric}.${item.instrument.mic}_${item.source}_${item.dataScope}`;

export const useEStreamConnection = ({ offlineMode, onConnectionStateChange }: UseEStreamConnectionProps) => {
  const logger = useRef(createLogger('useEStreamConnection'));

  const reconnectLaterTimeout = useRef<NodeJS.Timeout>();
  const reconnectCounter = useRef<number>(0);
  const connectionErrorSnackbar = useRef<CloseSnackbarFn | null>(null);
  const connectionSuccessSnackbar = useRef<CloseSnackbarFn | null>(null);

  /** INTERNAL connection state */
  const [connectionState, setConnectionState] = useState<ConnectionState>(ConnectionState.Disconnected);
  const [subscriptionQueue, setSubscriptionQueue] = useState<ObjectSet<SubscribeOptions>>(
    new ObjectSet(getSubscribeOptionId),
  );

  const { showError, showSuccess } = useSnackbar();
  const { translation, translateWithParams } = useLocale();

  const getFormattedMarket = (market: string) => {
    if (market.length > 0) {
      return ` ${market}`;
    }
    return '';
  };

  /**
   * @private
   */
  const updateConnectionState = (state: ConnectionState) => {
    setConnectionState(state);
    sseManager.setEStreamConnectionState(state);
  };

  /**
   * Shows an error message, that the EStream connection init failed
   */
  const showConnectionInitFailed = (market = '') => {
    hideSuccessSnackbar();
    connectionErrorSnackbar.current = showError(
      translateWithParams(translation.app.estream.connectionInitFailed, { market: getFormattedMarket(market) }),
      { persist: true },
    );
  };

  /**
   * Shows an error message, that the EStream has been disconnected
   */
  const showConnectionFailed = (market = '') => {
    hideErrorSnackbar();
    connectionErrorSnackbar.current = showError(
      translateWithParams(translation.app.estream.connectionDisconnected, { market: getFormattedMarket(market) }),
      { persist: true },
    );
  };

  /**
   * Shows a success message, that the EStream has been reconnected
   */
  const showConnectionReconnected = (market = '') => {
    hideErrorSnackbar();
    hideSuccessSnackbar();
    connectionSuccessSnackbar.current = showSuccess(
      translateWithParams(translation.app.estream.connectionReconnected, { market: getFormattedMarket(market) }),
    );
  };

  /**
   * Connects to eStream using the eStreamClient. If connection cannot be created tries to reconnect later.
   * @param isSilent if set to true, there will be no error message displayed when connection fails
   * @throws error when connection failed
   */
  const createEStreamClient = async ({ isSilent }: { isSilent?: boolean }): Promise<void> => {
    if (offlineMode) {
      return;
    }
    if (connectionState === ConnectionState.Connecting) {
      logger.current.trace(`EStream connection is already in progress`);
      return;
    }
    try {
      updateConnectionState(ConnectionState.Connecting);
      await eStreamClient.connect();
      updateConnectionState(ConnectionState.Connected);
      onConnectionStateChange?.(ConnectionState.Connected);
    } catch (err) {
      updateConnectionState(ConnectionState.FailedToConnected);
      onConnectionStateChange?.(ConnectionState.FailedToConnected);
      startReconnectLater();

      if (!isSilent) {
        showConnectionInitFailed();
      }

      throw err;
    }
  };

  /**
   * Handles if the eStream disconnects. If there was a connection before, it tries to reconnect later
   */
  const onEStreamDisconnect = () => {
    if (connectionState === ConnectionState.Connected && !reconnectLaterTimeout.current) {
      showConnectionFailed();
      startReconnectLater();
    }
    setConnectionState(ConnectionState.FailedToConnected);
    onConnectionStateChange?.(ConnectionState.FailedToConnected);
    eStreamClient.disconnect();
  };

  /**
   * Creates a timeout to reconnect later
   * @private
   */
  const startReconnectLater = () => {
    reconnectLaterTimeout.current = setTimeout(
      reConnectLater,
      reconnectCounter.current > 3 ? Config.Estream.GeneralReconnectTimeout : Config.Estream.FastReconnectTimeout,
    );
  };

  /**
   * Hides the connection success snackbar if there is any
   * @private
   */
  const hideSuccessSnackbar = () => {
    if (connectionSuccessSnackbar.current) {
      connectionSuccessSnackbar.current();
      connectionSuccessSnackbar.current = null;
    }
  };

  /**
   * Hides the connection error snackbar if there is any
   * @private
   */
  const hideErrorSnackbar = () => {
    if (connectionErrorSnackbar.current) {
      connectionErrorSnackbar.current();
      connectionErrorSnackbar.current = null;
    }
  };

  /**
   * If we haven't given up on EStream, tries to connect
   * @private
   */
  const reConnectLater = async () => {
    reconnectCounter.current += 1;
    logger.current.debug(`EStream reconnecting (${reconnectCounter.current}/${Config.Estream.MaxReconnectAttempt})`);
    if (reconnectLaterTimeout) {
      clearTimeout(reconnectLaterTimeout.current);
      reconnectLaterTimeout.current = undefined;
    }

    // try only for max reconnect attempt
    if (reconnectCounter.current > Config.Estream.MaxReconnectAttempt) {
      logger.current.debug(
        `EStream seems to be unreachable, giving up auto-reconnect after ${reconnectCounter.current} times`,
      );
      return;
    }

    try {
      await createEStreamClient({ isSilent: true });
      reconnectCounter.current = 0;
      showConnectionReconnected();
      try {
        await eStreamClient.handleSubscriptions();
      } catch (err) {
        logger.current.error(`Failed to resend subscriptions after reconnect`, err);
      }
    } catch (err) {}
  };

  /**
   * Disconnects the EStream client, and updates the connection state
   */
  const disconnectEStream = () => {
    setConnectionState(ConnectionState.Disconnected);
    onConnectionStateChange?.(ConnectionState.Disconnected);
    eStreamClient.disconnect();
  };

  /**
   * Subscribes to EStream data based on the options
   * @param options list of options to subscribe to
   * @param instanceId by default this is the current browser instance id, however you can add subscription from another instance
   */
  const addEStreamSubscriptions = (options: Array<SubscribeOptions>, instanceId?: number) => {
    const subOptions: Array<SubscribeOptions> = options.map(o => ({
      ...o,
      source: `${Keys.EStreamSubscriptionSourcePrefix}${instanceId || sseManager.instanceId}/${o.source}`,
    }));
    setSubscriptionQueue(prevSubscriptionQueue => prevSubscriptionQueue.overWriteAndClone(subOptions));
  };

  /**
   * Unsubscribes from EStream data based on the options
   * @param options
   */
  const removeEStreamSubscriptions = (options: Array<SubscribeOptions>) => {
    setSubscriptionQueue(prevSubscriptionQueue => {
      return prevSubscriptionQueue.deleteByCondition(item =>
        options.some(
          option =>
            `${Keys.EStreamSubscriptionSourcePrefix}${sseManager.instanceId}/${option.source}` === item.source &&
            getInstrumentId(option.instrument) === getInstrumentId(item.instrument),
        ),
      );
    });
  };

  const removeEStreamSubscriptionsByInstanceId = (instanceId: number) => {
    setSubscriptionQueue(prevSubscriptionQueue =>
      prevSubscriptionQueue.deleteByCondition(item =>
        item.source.startsWith(`${Keys.EStreamSubscriptionSourcePrefix}${instanceId}/`),
      ),
    );
  };

  useEffect(() => {
    // since eStreamClient lives outside react we need to update the disconnect function reference
    eStreamClient.setErrorHandler(onEStreamDisconnect);

    // when connection is ready send the subscription queue
    if (connectionState === ConnectionState.Connected) {
      eStreamClient.handleSubscriptions(subscriptionQueue);
    }
    // React wants us to include onEStreamDisconnect, but we don't. Because that's changing on connection/subscription
    // changes, and this effect runs on connection/subscription changes, so doesn't make any sense...
    // We would also need to wrap that into a useCallback, with dependencies, and that's total overkill
    //
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connectionState, subscriptionQueue]);

  return {
    connectionState,
    Internal_setConnectionState: setConnectionState,
    Internal_CreateEStreamClient: createEStreamClient,
    Internal_DisconnectEStream: disconnectEStream,
    Internal_AddEStreamSubscriptions: addEStreamSubscriptions,
    Internal_RemoveEStreamSubscriptions: removeEStreamSubscriptions,
    Internal_RemoveEStreamSubscriptionsByInstanceId: removeEStreamSubscriptionsByInstanceId,
    Internal_SubscriptionQueue: subscriptionQueue,
    showConnectionInitFailed,
    showConnectionFailed,
    showConnectionReconnected,
  };
};
