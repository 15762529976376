import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { groupBy, groupByAndMergeNumericValues, mergeNumericValues } from '../../../shared/utils/arrayUtils';
import { portfolioCompositionAtom } from '../states/portfolioCompositionAtom';
import { PortfolioCompositionGridItem, PortfolioCompositionItem } from '../types/portfolioCompositionItem';
import { getPortfolioItemValue, htmlNanoId } from './portfolioUtils';

export const usePortfolioCompositionGrid = () => {
  const portfolioComposition = useRecoilValue(portfolioCompositionAtom);

  const portfolioItems = useMemo(() => portfolioComposition?.portfolioItems || [], [portfolioComposition]);

  /** @private */
  const createGridItem = (
    portfolioCompositionItem: PortfolioCompositionItem,
    accumulatedValues?: { position: number; initValue: number },
  ): PortfolioCompositionGridItem => {
    return {
      id: htmlNanoId(),
      instrument: portfolioCompositionItem.instrumentName,
      instrumentCategory: portfolioCompositionItem.instrumentCategory,
      accountNumber: portfolioCompositionItem.accountNumber,
      name: portfolioCompositionItem.instrumentName,
      quantity: accumulatedValues?.position || portfolioCompositionItem.position,
      value: accumulatedValues?.initValue || portfolioCompositionItem.initValue,
      valueInHuf: 0,
      investmentAmount: 0,
      pNl: 0,
      pNlPercentage: 0,
      weight: 0,
    };
  };

  /**
   * Returns portfolio items filtered by account number and instrument class
   */
  const getInstrumentListForGrid = (
    accountNumberFilter: string | null,
    instrumentCategory: string,
  ): Array<PortfolioCompositionGridItem> => {
    const filteredPortfolioItems = (
      accountNumberFilter ? portfolioItems.filter(_ => _.accountNumber === accountNumberFilter) : portfolioItems
    ).filter(_ => _.instrumentCategory === instrumentCategory);

    const uniqueInstruments = groupByAndMergeNumericValues(filteredPortfolioItems, 'instrumentName', {});
    return uniqueInstruments.map(_ => createGridItem(_));
  };

  const getInstrumentsListByAccountForGrid = (
    accountNumberFilter: string | null,
  ): Array<PortfolioCompositionGridItem> => {
    const filteredPortfolioItems = accountNumberFilter
      ? portfolioItems.filter(_ => _.accountNumber === accountNumberFilter)
      : portfolioItems;
    const portfolioCompItemsGroupedByInstrument: Record<string, Array<PortfolioCompositionItem>> = groupBy(
      filteredPortfolioItems,
      'instrumentName',
    );

    return Object.keys(portfolioCompItemsGroupedByInstrument).reduce((res, instrument) => {
      const portfolioItemsOfInstrument = groupByAndMergeNumericValues(
        portfolioCompItemsGroupedByInstrument[instrument],
        'accountNumber',
        {},
      );

      if (portfolioItemsOfInstrument.length === 1) {
        res.push(
          createGridItem(portfolioItemsOfInstrument[0], {
            position: portfolioItemsOfInstrument[0].position,
            initValue: getPortfolioItemValue(portfolioItemsOfInstrument[0]),
          }),
        );
      } else if (portfolioItemsOfInstrument.length > 1) {
        const accumulatedValues = mergeNumericValues(portfolioItemsOfInstrument, {
          include: ['position', 'initValue'],
        });
        const createdItem = createGridItem(portfolioItemsOfInstrument[0], accumulatedValues);
        createdItem.subElements = portfolioItemsOfInstrument.map(_ => createGridItem(_));
        res.push(createdItem);
      }
      return res;
    }, [] as Array<PortfolioCompositionGridItem>);
  };

  return {
    getInstrumentListForGrid,
    getInstrumentsListByAccountForGrid,
  };
};
