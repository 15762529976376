import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MenuIcon from '@mui/icons-material/Menu';
import SegmentIcon from '@mui/icons-material/Segment';
import { Accordion, AccordionSummary } from '@mui/material';
import { ReactElement, useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { ReactComponent as DashboardLogo } from '../../../assets/svg/menu_dashboard.svg';
import { ReactComponent as LogoutLogo } from '../../../assets/svg/menu_logout.svg';
import { ReactComponent as PortfolioLogo } from '../../../assets/svg/menu_portfolio.svg';
import { ReactComponent as SettingsLogo } from '../../../assets/svg/menu_settings.svg';
import { ReactComponent as TradingLogo } from '../../../assets/svg/menu_trading.svg';
import { useLocale } from '../../../context/NbLocalizationContext';
import { NbButton } from '../../../shared/components/button/Button';
import { NbIconButton } from '../../../shared/components/icon-button/IconButton';
import { routes } from '../../../shared/data/routes';
import { useAuth } from '../../../shared/services/useAuth';
import classes from './Menu.module.scss';

interface NbMenuItemProps {
  text: string;
  link?: string;
  icon?: ReactElement;
  submenuList?: Array<NbSubmenuItemsProps>;
}

interface NbSubmenuItemsProps {
  text: string;
  link: string;
}

export interface NbMenuItemsProps {
  overriddenMenuPoints?: NbMenuItemProps[];
  toggleMenuCollapse: () => void;
  isMenuCollapsed: boolean;
}

export const NbMenu = ({ overriddenMenuPoints, toggleMenuCollapse, isMenuCollapsed }: NbMenuItemsProps) => {
  const { translation } = useLocale();
  const { logout } = useAuth();
  const navigate = useNavigate();
  const defaultMenuPoints: Array<NbMenuItemProps> = [
    {
      text: translation.menu.dashboard,
      link: routes.home,
      icon: <DashboardLogo />,
    },
    {
      text: translation.menu.trading,
      icon: <TradingLogo />,
      submenuList: [
        {
          text: translation.menu.stockTrading,
          link: routes.trading,
        },
        {
          text: translation.menu.watchList,
          link: routes.watchlist,
        },
        {
          text: translation.menu.chart,
          link: routes.chart,
        },
      ],
    },
    {
      text: translation.menu.portfolio,
      link: routes.portfolio,
      icon: <PortfolioLogo />,
    },
    {
      text: translation.menu.settings,
      icon: <SettingsLogo />,
      submenuList: [
        {
          text: translation.menu.userSettings,
          link: routes.userSettings,
        },
        {
          text: translation.menu.notificationsSettings,
          link: routes.notificationsSettings,
        },
        {
          text: translation.menu.dataServiceManagement,
          link: routes.dataServiceManagement,
        },
      ],
    },
  ];

  const [isCollapsedMenuEnabled, setIsCollapsedMenuEnabled] = useState(true);

  const [activeMenu, setActiveMenu] = useState<NbMenuItemProps>();
  const location = useLocation();

  const hideSubmenu = () => {
    setIsCollapsedMenuEnabled(false);
  };

  const menuPoints = overriddenMenuPoints ? overriddenMenuPoints : defaultMenuPoints;

  const getMenuItemClass = (link: string): string => {
    const currentPath = location.pathname;
    return currentPath.startsWith(link) ? classes.submenuItemIsActive : '';
  };

  const getSubMenu = (item: NbMenuItemProps) => (
    <AccordionSummary>
      <ul className={classes.submenu}>
        {item.submenuList &&
          item.submenuList.map((submenu, index) => (
            <Link
              to={submenu.link}
              key={index}
              onClick={hideSubmenu}
            >
              <li
                className={`${classes.submenuItem} ${getMenuItemClass(submenu.link)} ${
                  isMenuCollapsed ? classes.submenuItemIsCollapsed : ''
                }`}
              >
                {submenu.text}
              </li>
            </Link>
          ))}
      </ul>
    </AccordionSummary>
  );

  const navigateSubmenu = (item: NbMenuItemProps) => {
    if (!item.submenuList) {
      return;
    }
    navigate(item.submenuList[0].link);
  };

  useEffect(() => {
    const currentPath = location.pathname;
    const menu = menuPoints.find(
      m => m.link?.startsWith(currentPath) || (m.submenuList || []).find(sm => sm.link.startsWith(currentPath)),
    );

    if (menu) {
      setActiveMenu(menu);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  useEffect(() => {
    if (!isCollapsedMenuEnabled) {
      setIsCollapsedMenuEnabled(true);
    }
  }, [isCollapsedMenuEnabled]);

  return (
    <div className={`${classes.menu}  ${isMenuCollapsed ? classes.menuIsCollapsed : ''}`}>
      <div className={`${classes.toggleButton} ${isMenuCollapsed ? classes.toggleButtonIsCollapsed : ''}`}>
        <NbIconButton
          icon={isMenuCollapsed ? <SegmentIcon /> : <MenuIcon />}
          onClick={toggleMenuCollapse}
        />
      </div>

      {menuPoints.map((item, index) => (
        <div
          key={index}
          className={classes.menuElement}
        >
          <Accordion>
            <AccordionSummary
              expandIcon={item.submenuList ? <ExpandMoreIcon /> : ''}
              className={`${classes.menuItem} ${activeMenu?.text === item.text ? classes.menuItemIsActive : ''}`}
            >
              {item.link ? (
                <Link to={item.link}>
                  <span
                    className={`${classes.menuItemIcon} ${
                      activeMenu?.text === item.text ? classes.menuItemIconIsActive : ''
                    }`}
                  >
                    {item.icon}
                  </span>
                  {!isMenuCollapsed && <span className={classes.menuItemText}>{item.text}</span>}
                </Link>
              ) : (
                <>
                  <span
                    onClick={() => navigateSubmenu(item)}
                    className={`${classes.menuItemIcon} ${
                      activeMenu?.text === item.text ? classes.menuItemIconIsActive : ''
                    }`}
                  >
                    {item.icon}
                  </span>
                  {!isMenuCollapsed && (
                    <span
                      className={classes.menuItemText}
                      onClick={() => navigateSubmenu(item)}
                    >
                      {item.text}
                    </span>
                  )}
                </>
              )}
              {item.submenuList && isCollapsedMenuEnabled && (
                <div className={classes.collapsedMenuItem}>
                  <div className={classes.collapsedMenuItemHeader}>{item.text}</div>
                  {getSubMenu(item)}
                </div>
              )}
            </AccordionSummary>
            {!isMenuCollapsed && item.submenuList && getSubMenu(item)}
          </Accordion>
        </div>
      ))}

      <div className={`${classes.logout} ${isMenuCollapsed ? classes.logoutIsCollapsed : ''}`}>
        <NbButton
          onClick={logout}
          variant={'text'}
          className={classes.logoutButton}
        >
          <span className={classes.logoutIcon}>{<LogoutLogo />}</span>
          {!isMenuCollapsed && <span className={classes.menuItemText}>{translation.menu.logout}</span>}
        </NbButton>
      </div>
    </div>
  );
};
