import { forwardRef, ReactElement } from 'react';

import 'chartiq/css/chartiq.css'; // Chart UI
import 'chartiq/css/normalize.css';
import 'chartiq/css/page-defaults.css';
import 'chartiq/css/stx-chart.css'; // Chart API

// @ts-ignore
// @ts-ignore
import 'chartiq/examples/feeds/symbolLookupChartIQ';
// @ts-ignore
import 'chartiq/js/addOns';
import 'chartiq/js/components';
import 'chartiq/js/componentUI';
// @ts-ignore
import 'chartiq/plugins/signaliq/signaliqDialog';

import 'chartiq/plugins/tfc/tfc-demo';
import 'chartiq/plugins/tfc/tfc-loader.js';

interface ChartProps {
  template: ReactElement;
}

export const Chart = forwardRef(({ template }: ChartProps, ref) => <cq-context ref={ref}>{template}</cq-context>);
