import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import DescriptionIcon from '@mui/icons-material/Description';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import SsidChartIcon from '@mui/icons-material/SsidChart';
import { GridActionsCellItem, GridColDef, GridRowParams } from '@mui/x-data-grid';
import { GridRenderCellParams } from '@mui/x-data-grid/models/params/gridCellParams';
import { MouseEvent } from 'react';
import { Translation, useLocale } from '../../../context/NbLocalizationContext';
import { useTheme } from '../../../context/NbThemeContext';
import { NbTooltip } from '../../../shared/components/tooltip/Tooltip';
import { useFormatter } from '../../../shared/services/useFormatter';
import { WatchListInstrument } from '../../../shared/state/watchListStateAtom';
import { TradeType } from '../../../shared/types/tradeType';
import { WithoutId } from '../../../shared/types/withoutId';
import { getChangeWithPercent } from '../../../shared/utils/numberUtils';
import { default as gridClasses } from '../partials/WatchListGrid.module.scss';

export interface WatchListGridActions {
  onDelete: (event: MouseEvent<HTMLButtonElement>, row: WatchListInstrument) => void;
  onOrder: (event: MouseEvent<HTMLButtonElement>, row: WatchListInstrument) => void;
  onMore: (event: MouseEvent<HTMLButtonElement>, row: WatchListInstrument) => void;
  onNotify: (event: MouseEvent<HTMLButtonElement>, row: WatchListInstrument) => void;
}

export interface WatchListGridColumnsActions {
  onOrder: (
    event: MouseEvent<HTMLButtonElement | HTMLSpanElement>,
    row: WatchListInstrument,
    tradeType: TradeType,
  ) => void;
}

export const useWatchListColumns = () => {
  const { translation } = useLocale();
  const { formatPercentage, formatNumberF2, formatInteger } = useFormatter();
  const unknownValue = <span>-</span>;
  const { rem } = useTheme();

  const getWatchListColumn = (
    column: keyof WithoutId<WatchListInstrument>,
    actions: WatchListGridColumnsActions,
  ): GridColDef<WatchListInstrument> => {
    const baseColDef: GridColDef<WatchListInstrument> = {
      field: column,
      headerName: translation.watchListGrid.grid[column],
      sortable: false,
      disableColumnMenu: true,
    };

    switch (column) {
      case 'instrument':
        return {
          ...baseColDef,
          width: rem(75),
          renderCell: (params: GridRenderCellParams<WatchListInstrument>) => (
            <NbTooltip
              title={
                <span>
                  {params.row.instrument.ticker
                    ? params.row.instrument.ticker
                    : translation.watchListGrid.instrumentDefault}
                </span>
              }
            >
              <span>{params.row.instrument.ticker}</span>
            </NbTooltip>
          ),
        };
      case 'lastPrice':
        return {
          ...baseColDef,
          width: rem(75),
          renderCell: (params: GridRenderCellParams<WatchListInstrument>) => {
            if (params.row.closePrice === undefined || params.row.lastPrice === undefined) {
              return unknownValue;
            }
            const customClass =
              params.row.lastPrice > params.row.closePrice
                ? gridClasses.nbWatchListGridDotIsGreen
                : params.row.lastPrice === params.row.closePrice
                ? gridClasses.nbWatchListGridDotIsOrange
                : gridClasses.nbWatchListGridDotIsRed;
            return (
              <span>
                <span className={`${gridClasses.nbWatchListGridDot} ${customClass}`}></span>
                {formatNumberF2(params.row.lastPrice)}
              </span>
            );
          },
        };
      case 'change':
        return {
          ...baseColDef,
          width: rem(75),
          align: 'right',
          renderCell: (params: GridRenderCellParams<WatchListInstrument>) => {
            if (params.row.lastPrice === undefined || params.row.closePrice === undefined) {
              return unknownValue;
            }
            const { change } = getChangeWithPercent(params.row.lastPrice, params.row.closePrice);
            return (
              <span
                className={
                  change < 0 ? gridClasses.nbWatchListGridCellIsLower : gridClasses.nbWatchListGridCellIsHigher
                }
              >
                {formatNumberF2(change)}
              </span>
            );
          },
        };
      case 'changePercent':
        return {
          ...baseColDef,
          width: rem(75),
          align: 'right',
          renderCell: (params: GridRenderCellParams<WatchListInstrument>) => {
            if (params.row.lastPrice === undefined || params.row.closePrice === undefined) {
              return unknownValue;
            }
            const { changePercent } = getChangeWithPercent(params.row.lastPrice, params.row.closePrice);
            return (
              <span
                className={
                  changePercent < 0 ? gridClasses.nbWatchListGridCellIsLower : gridClasses.nbWatchListGridCellIsHigher
                }
              >
                {formatPercentage(changePercent)}
              </span>
            );
          },
        };
      case 'buyPrice':
        return {
          ...baseColDef,
          width: rem(75),
          align: 'center',
          renderCell: (params: GridRenderCellParams<WatchListInstrument>) => {
            if (params.row.buyPrice === undefined) {
              return unknownValue;
            }

            return (
              <span
                onClick={e => actions.onOrder(e, params.row, TradeType.Sell)}
                className={`${gridClasses.nbWatchListGridCellBox} ${gridClasses.nbWatchListGridCellBoxIsBuyPrice}`}
              >
                {formatNumberF2(params.row.buyPrice)}
              </span>
            );
          },
        };
      case 'sellPrice':
        return {
          ...baseColDef,
          width: rem(75),
          align: 'center',
          renderCell: (params: GridRenderCellParams<WatchListInstrument>) => {
            if (params.row.sellPrice === undefined) {
              return unknownValue;
            }
            return (
              <span
                onClick={e => actions.onOrder(e, params.row, TradeType.Buy)}
                className={`${gridClasses.nbWatchListGridCellBox} ${gridClasses.nbWatchListGridCellBoxIsSellPrice}`}
              >
                {formatNumberF2(params.row.sellPrice)}
              </span>
            );
          },
        };
      case 'low':
        return {
          ...baseColDef,
          width: rem(75),
          valueFormatter: ({ value }) => formatNumberF2(value),
        };
      case 'high':
        return {
          ...baseColDef,
          width: rem(75),
          align: 'right',
          valueFormatter: ({ value }) => formatNumberF2(value),
        };
      case 'averagePrice':
        return {
          ...baseColDef,
          width: rem(75),
          align: 'right',
          valueFormatter: ({ value }) => formatNumberF2(value),
        };
      case 'volumeQuantity':
        return {
          ...baseColDef,
          width: rem(75),
          align: 'right',
          valueFormatter: ({ value }) => formatInteger(value),
        };
      case 'volumeValue':
        return {
          ...baseColDef,
          width: rem(75),
          align: 'right',
          valueFormatter: ({ value }) => formatInteger(value),
        };
      case 'annualLow':
        return {
          ...baseColDef,
          width: rem(75),
          align: 'right',
          valueFormatter: ({ value }) => formatNumberF2(value),
        };
      case 'annualHigh':
        return {
          ...baseColDef,
          width: rem(75),
          align: 'right',
          valueFormatter: ({ value }) => formatNumberF2(value),
        };
      default:
        throw new Error(`Invalid column ${column}`);
    }
  };

  const getWatchListFlatActions = (
    translation: Translation,
    isEditMode: boolean,
    isWidgetMode: boolean,
    actions: WatchListGridActions,
  ): GridColDef<WatchListInstrument> => {
    return {
      field: 'actions',
      type: 'actions',
      width: isEditMode || isWidgetMode ? rem(40) : rem(200),
      getActions: (params: GridRowParams<WatchListInstrument>) => [
        ...(isEditMode && !isWidgetMode
          ? [
              <GridActionsCellItem
                className={gridClasses.actionIcon}
                icon={<DeleteIcon />}
                label={translation.watchListGrid.delete}
                onClick={e => actions.onDelete(e, params.row)}
              />,
            ]
          : !isEditMode && !isWidgetMode
          ? [
              <GridActionsCellItem
                className={gridClasses.actionIcon}
                icon={<DescriptionIcon />}
                label={translation.watchListGrid.description}
              />,
              <GridActionsCellItem
                className={gridClasses.actionIcon}
                icon={<AddIcon />}
                onClick={e => actions.onOrder(e, params.row)}
                label={translation.watchListGrid.add}
              />,
              <GridActionsCellItem
                className={gridClasses.actionIcon}
                icon={<NotificationsNoneIcon />}
                onClick={e => actions.onNotify(e, params.row)}
                label={translation.watchListGrid.notification}
              />,
              <GridActionsCellItem
                className={gridClasses.actionIcon}
                icon={<SsidChartIcon />}
                label={translation.watchListGrid.chart}
              />,
              <GridActionsCellItem
                className={gridClasses.actionIcon}
                icon={<DeleteIcon />}
                label={translation.watchListGrid.delete}
                onClick={e => actions.onDelete(e, params.row)}
              />,
            ]
          : [
              <GridActionsCellItem
                className={gridClasses.actionIcon}
                icon={<MoreVertIcon />}
                label={translation.watchListGrid.more}
                onClick={e => actions.onMore(e, params.row)}
              />,
            ]),
      ],
    };
  };

  return {
    getWatchListColumn,
    getWatchListFlatActions,
  };
};
