export type CustomPieChartProps = {
  cx: number;
  cy: number;
  midAngle: number;
  innerRadius: number;
  outerRadius: number;
  percent: number;
  index: number;
};

const radian = Math.PI / 180;

// This is not a React component!
export const createPortfolioPieLabelElement =
  (rem: (px: number) => number) =>
  ({ cx, cy, innerRadius, outerRadius, midAngle, percent }: CustomPieChartProps) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.3;
    const x = cx + radius * Math.cos(-midAngle * radian);
    const y = cy + radius * Math.sin(-midAngle * radian);

    if (percent < 0.05) {
      return null;
    }

    return (
      <text
        x={x}
        y={y}
        fill="white"
        textAnchor={x > cx ? 'start' : 'end'}
        dominantBaseline="central"
        style={{ fontSize: rem(12) }}
        onMouseOver={e => console.log('onMouseOver', e)}
        pointerEvents={'none'}
      >
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };
