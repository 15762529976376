import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import SettingsIcon from '@mui/icons-material/Settings';
import { Popover } from '@mui/material';
import { useState } from 'react';
import { useRecoilValue } from 'recoil';
import { useLocale } from '../../../context/NbLocalizationContext';
import { NbButton } from '../../../shared/components/button/Button';
import { NbFormControlLabel } from '../../../shared/components/form-control-label/FormControlLabel';
import { NbIconButton } from '../../../shared/components/icon-button/IconButton';
import { NbTabs, TabItem } from '../../../shared/components/tabs/Tabs';
import { NbSwitch } from '../../../shared/components/toggle-button/Switch';
import { alertGroupsAtom } from '../state/alertGroupsAtom';
import { AlertCategory } from '../types/alertCategory';
import classes from './AlertCenterHeader.module.scss';

interface AlertCenterHeaderProps {
  onCancel: () => void;
  selectGroup: (group: AlertCategory) => void;
  selectedGroup: AlertCategory;
  onlyUnreads: boolean;
  onSwitchChange: (checked: boolean) => void;
}

export const AlertCenterHeader = ({ selectedGroup, onCancel, selectGroup, onSwitchChange }: AlertCenterHeaderProps) => {
  const alertGroups = useRecoilValue(alertGroupsAtom);
  const [actionsPopover, setActionsPopover] = useState<HTMLButtonElement | null>(null);
  const { translation } = useLocale();

  const handleActionsClose = () => {
    setActionsPopover(null);
  };

  const handleMoreClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setActionsPopover(event.currentTarget);
  };

  const tabItems = alertGroups.map<TabItem>(group => ({
    id: group.category,
    label: `${translation.alertCenter.categories[group.category]} (${group.alerts.reduce(
      (prev, cur) => (cur.read ? prev : prev + 1),
      0,
    )})`,
  }));

  return (
    <div className={classes.header}>
      <div className={classes.headerTabs}>
        <NbTabs
          tabs={tabItems}
          selected={tabItems.findIndex(group => group.id === selectedGroup)}
          onTabChange={(index: number) => selectGroup(alertGroups[index].category)}
        />
      </div>
      <div className={classes.headerActions}>
        <NbFormControlLabel
          control={
            <NbSwitch
              color="secondary"
              size="small"
              onChange={(_, checked) => onSwitchChange(checked)}
            />
          }
          label={translation.alertCenter.onlyUnreads}
        />

        <NbIconButton
          size={'small'}
          icon={<MoreVertIcon />}
          onClick={handleMoreClick}
        />
        <Popover
          open={!!actionsPopover}
          anchorEl={actionsPopover}
          onClose={handleActionsClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          classes={{ paper: classes.popover }}
        >
          <div className={classes.actions}>
            <NbButton
              color={'primary'}
              variant={'text'}
              size={'small'}
              startIcon={<DoneIcon />}
            >
              {translation.alertCenter.markAllAsRead}
            </NbButton>
            <NbButton
              color={'primary'}
              variant={'text'}
              size={'small'}
              startIcon={<SettingsIcon />}
            >
              {translation.alertCenter.notificationsSettings}
            </NbButton>
          </div>
        </Popover>
        <NbIconButton
          size={'small'}
          icon={<CloseIcon />}
          onClick={onCancel}
        />
      </div>
    </div>
  );
};
