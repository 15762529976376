import { FormControlLabel, FormControlLabelProps } from '@mui/material';
import classes from './FormControlLabel.module.scss';

export const NbFormControlLabel = (props: FormControlLabelProps) => {
  return (
    <FormControlLabel
      className={classes.formControlLabel}
      {...props}
    />
  );
};
