import { useRecoilState } from 'recoil';
import { UserSettingsResource } from '../../../shared/resources/userSettings.resource';
import { useResource } from '../../../shared/services/useResource';
import { widgetStateAtom } from '../../../shared/state/widgetStateAtom';
import { WidgetColumn } from '../../../shared/types/widgetColumn';
import { WidgetConfigState } from '../../../shared/types/widgetConfigState';
import { WidgetElement } from '../../../shared/types/widgetElement';
import {
  defaultGroupId,
  WidgetGroup,
  WidgetGroupColor,
  widgetGroupIdsReverse,
  WidgetGroupUpdateDto,
} from '../../../shared/types/widgetGroup';
import { WidgetType } from '../../../shared/types/widgetType';

/* eslint-disable @typescript-eslint/no-unused-vars */

export const useWidget = () => {
  const [widgetState, setWidgetState] = useRecoilState(widgetStateAtom);
  const userSettingsResource = useResource(UserSettingsResource);

  const addWidget = async (type: WidgetType) => {
    // try {
    //   const newWidgetState: WidgetConfigState = {
    //     groups: widgetState.groups,
    //     containers: [
    //       ...widgetState.containers,
    //       {
    //         id: Date.now(),
    //         elements: [
    //           {
    //             id: Date.now(),
    //             type,
    //             groupId: undefined,
    //           },
    //         ],
    //       },
    //     ],
    //   };
    //   setWidgetState({ ...newWidgetState });
    //   await userSettingsResource.updateUserSetting(UserSetting.Widget, newWidgetState);
    // } catch (err) {
    //   console.error(`Failed to add widget`, err);
    // }
  };

  const removePayloadFromWidgets = (widgetConfig: WidgetConfigState) => {
    const newWidgetConfig: WidgetConfigState = {
      ...widgetConfig,
      containers: removePayloadFromWidgetColumns(widgetConfig.containers),
    };
    return newWidgetConfig;
  };

  const removePayloadFromWidgetColumns = (columns: WidgetColumn[]): WidgetColumn[] => {
    return columns.map(column => {
      const mappedRows = column.rows.map(row => {
        if (Array.isArray(row.columns)) {
          return {
            ...row,
            columns: removePayloadFromWidgetColumns(row.columns),
          };
        }

        return {
          ...row,
          columns: {
            ...row.columns,
            elements: row.columns.elements.map(element => {
              return {
                ...element,
                payLoad: undefined,
              };
            }),
          },
        };
      });

      return {
        rows: mappedRows,
        width: column.width,
      };
    });
  };

  const selectWidgetInContainer = async (widgetId: string, containerId: string) => {
    try {
      const newWidgetState: WidgetConfigState = {
        ...widgetState,
        containers: selectWidgetInContainerColumns(widgetState.containers, widgetId, containerId),
      };
      setWidgetState(newWidgetState);
      await userSettingsResource.updateWidgetConfig(removePayloadFromWidgets(newWidgetState));
    } catch (err) {
      console.error(`Failed to update selected widget`, err);
    }
  };

  const updateWidget = (element: WidgetElement, select: boolean = false) => {
    setWidgetState(state => ({ ...state, containers: updateWidgetColumns(state.containers, element, select) }));
  };

  const updateWidgetColumns = (
    columns: WidgetColumn[],
    element: WidgetElement,
    select: boolean = false,
  ): WidgetColumn[] => {
    return columns.map(column => {
      const mappedRows = column.rows.map(row => {
        if (Array.isArray(row.columns)) {
          return {
            ...row,
            columns: updateWidgetColumns(row.columns, element, select),
          };
        }

        const isTheWidgetContainer = row.columns.elements.some(e => e.id === element.id);
        if (isTheWidgetContainer) {
          return {
            ...row,
            columns: {
              ...row.columns,
              elements: row.columns.elements.map(e => {
                if (e.id === element.id) {
                  return { ...element };
                }
                return e;
              }),
              selectedWidgetId: select ? element.id : row.columns.selectedWidgetId,
            },
          };
        }

        return row;
      });

      return {
        rows: mappedRows,
        width: column.width,
      };
    });
  };

  const searchWidgetsByTypeInGroup = (
    widgetGroup: number = 0,
    widgetType: WidgetType | WidgetType[],
  ): WidgetElement[] => {
    return searchWidgetsByTypeInGroupColumn(widgetState.containers, widgetGroup, widgetType);
  };

  const searchWidgetsByTypeInGroupColumn = (
    columns: WidgetColumn[],
    widgetGroup: number = 0,
    widgetType: WidgetType | WidgetType[],
  ): WidgetElement[] => {
    const results: WidgetElement[] = [];
    for (const column of columns) {
      for (const row of column.rows) {
        if (Array.isArray(row.columns)) {
          results.push(...searchWidgetsByTypeInGroupColumn(row.columns, widgetGroup, widgetType));
        } else if (row.columns.elements.some(element => element.groupId === widgetGroup)) {
          row.columns.elements.forEach(element => {
            if (element.type === widgetType || (Array.isArray(widgetType) && widgetType.includes(element.type))) {
              results.push(element);
            }
          });
        }
      }
    }

    return results;
  };

  const searchWidgetByTypeInGroup = (widgetGroup: number = 0, widgetType: WidgetType): WidgetElement | undefined => {
    return searchWidgetByTypeInGroupColumn(widgetState.containers, widgetGroup, widgetType);
  };

  const searchWidgetByTypeInGroupColumn = (
    columns: WidgetColumn[],
    widgetGroup: number,
    widgetType: WidgetType,
  ): WidgetElement | undefined => {
    for (const column of columns) {
      for (const row of column.rows) {
        if (Array.isArray(row.columns)) {
          return searchWidgetByTypeInGroupColumn(row.columns, widgetGroup, widgetType);
        }

        if (row.columns.elements.some(element => element.groupId === widgetGroup)) {
          const element = row.columns.elements.find(element => element.type === widgetType);
          if (element) {
            return element;
          }
        }
      }
    }
  };

  const selectWidgetInContainerColumns = (
    columns: WidgetColumn[],
    widgetId: string,
    containerId: string,
  ): WidgetColumn[] => {
    return columns.map(column => {
      const mappedRows = column.rows.map(row => {
        if (Array.isArray(row.columns)) {
          return {
            ...row,
            columns: selectWidgetInContainerColumns(row.columns, widgetId, containerId),
          };
        }

        if (row.columns.id === containerId) {
          return {
            ...row,
            columns: {
              ...row.columns,
              selectedWidgetId: widgetId,
            },
          };
        }

        return row;
      });

      return {
        rows: mappedRows,
        width: column.width,
      };
    });
  };

  const updateWidgetGroup = async (containerId: string, groupId: number) => {
    try {
      const newWidgetState: WidgetConfigState = {
        ...widgetState,
        containers: updateWidgetGroupColumns(widgetState.containers, containerId, groupId),
      };
      if (!newWidgetState.groups[groupId]) {
        newWidgetState.groups[groupId] = {
          id: groupId,
          color: widgetGroupIdsReverse(groupId),
          instrument: null,
        };
      }
      setWidgetState(newWidgetState);
      await userSettingsResource.updateWidgetConfig(removePayloadFromWidgets(newWidgetState));
    } catch (err) {
      console.error(`Failed to update widget group`, err);
    }
  };

  const updateWidgetGroupColumns = (
    columns: Array<WidgetColumn>,
    containerId: string,
    groupId: number,
  ): WidgetColumn[] => {
    return columns.map(column => {
      const mappedRows = column.rows.map(row => {
        if (Array.isArray(row.columns)) {
          return {
            ...row,
            columns: updateWidgetGroupColumns(row.columns, containerId, groupId),
          };
        }

        if (row.columns.id === containerId) {
          return {
            ...row,
            columns: {
              ...row.columns,
              elements: row.columns.elements.map(element => ({ ...element, groupId: groupId })),
            },
          };
        }

        return row;
      });

      return {
        rows: mappedRows,
        width: column.width,
      };
    });
  };

  const updateWidgetGroupState = async (groupId: number = defaultGroupId, rest: Partial<WidgetGroupUpdateDto>) => {
    try {
      const newWidgetState: WidgetConfigState = {
        ...widgetState,
        groups: Object.keys(widgetState.groups).reduce((previousValue, currentValue) => {
          const pGroupId = Number(currentValue);
          if (pGroupId === 0 && !widgetState.groups[groupId]) {
            previousValue[groupId] = {
              id: groupId,
              color: WidgetGroupColor.Group1,
              instrument: null,
            };
          }
          previousValue[pGroupId] = {
            ...widgetState.groups[pGroupId],
            ...(pGroupId === groupId ? rest : {}),
          };
          return previousValue;
        }, {} as Record<number, WidgetGroup>),
      };
      setWidgetState(newWidgetState);
      await userSettingsResource.updateWidgetConfig(removePayloadFromWidgets(newWidgetState));
    } catch (err) {
      console.error(`Failed to update widget group state`, err);
    }
  };

  return {
    addWidget,
    selectWidgetInContainer,
    searchWidgetTypeInGroup: searchWidgetByTypeInGroup,
    searchWidgetsByTypeInGroup,
    updateWidget,
    updateWidgetGroup,
    updateWidgetGroupState,
  };
};
