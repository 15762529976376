import { createTheme, ThemeProvider } from '@mui/material';
import { createContext, ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Keys } from '../shared/config/keys';
import styles from './NbThemeContext.module.scss';

declare module '@mui/material/styles' {
  interface Palette {
    emphasize: Palette['primary'];
  }

  interface PaletteOptions {
    emphasize: PaletteOptions['primary'];
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    emphasize: true;
  }
}

const safeColor = (input: string | undefined, fallback: string) =>
  !input || !input.startsWith('#') || !input.startsWith('rgb') || !input.startsWith('rgb') ? fallback : input;

// use https://bareynol.github.io/mui-theme-creator/#Buttons
const lightTheme = createTheme({
  palette: {
    text: {
      primary: safeColor(styles.colorTextPrimary, '#303030'),
    },
    primary: {
      main: safeColor(styles.colorPrimary, '#2870ED'),
    },
    secondary: {
      main: safeColor(styles.colorSecondary, '#ffffff'),
    },
    error: {
      main: safeColor(styles.colorError, '#ef0039'),
    },
    warning: {
      main: safeColor(styles.colorWarning, '#ff6130'),
    },
    info: {
      main: safeColor(styles.colorInfo, '#a3b5c9'),
    },
    success: {
      main: safeColor(styles.colorSuccess, '#0cb43f'),
    },
    emphasize: {
      main: '#ffffff',
      contrastText: safeColor(styles.colorPrimary, '#2870ed'),
      dark: '#ffffff',
    },
  },
});

const darkTheme = createTheme({
  palette: {
    text: {
      primary: safeColor(styles.colorTextPrimaryDark, '#ffffff'),
    },
    primary: {
      main: safeColor(styles.colorPrimaryDark, '#2870ed'),
    },
    secondary: {
      main: safeColor(styles.colorSecondaryDark, '#ffffff'),
    },
    error: {
      main: safeColor(styles.colorErrorDark, '#ef0039'),
    },
    warning: {
      main: safeColor(styles.colorWarningDark, '#ff6130'),
    },
    info: {
      main: safeColor(styles.colorInfoDark, '#a3b5c9'),
    },
    success: {
      main: safeColor(styles.colorSuccessDark, '#0cb43f'),
    },
    emphasize: {
      main: '#ffffff',
      contrastText: safeColor(styles.colorPrimaryDark, '#2870ed'),
      dark: '#ffffff',
    },
  },
});

type NbThemeContextProps =
  | undefined
  | {
      rem: (fullHdSizeInPx: number) => number;
      isDark: boolean;
      setTheme: (isDark: boolean) => void;
    };

const NbThemeContext = createContext<NbThemeContextProps>(undefined);

const fontSizes = {
  tiny: Number(styles.fontSizeTiny.replace('px', '')),
  small: Number(styles.fontSizeSmall.replace('px', '')),
  medium: Number(styles.fontSizeMedium.replace('px', '')),
  normal: Number(styles.fontSizeNormal.replace('px', '')),
};

const resolutions = {
  fulldHd: Number(styles.resolutionFullHd.replace('px', '')),
  highRes: Number(styles.resolutionHighRes.replace('px', '')),
  midRes: Number(styles.resolutionMidRes.replace('px', '')),
  desktop: Number(styles.resolutionDesktop.replace('px', '')),
};

/**
 * Returns base font size based on screen width
 *
 * @param width width of the screen
 */
const getBaseFontSize = (width: number) => {
  if (width >= resolutions.fulldHd) {
    return fontSizes.normal;
  } else if (width >= resolutions.highRes) {
    return fontSizes.normal;
  } else if (width >= resolutions.midRes) {
    return fontSizes.medium;
  } else if (width >= resolutions.desktop) {
    return fontSizes.small;
  } else {
    return fontSizes.tiny;
  }
};

const isDynamicSizingEnabled = document.cookie.includes(`dynamicSizingEnabled=true`);

export const NbThemeProvider = ({ children }: { children: ReactNode }) => {
  const [baseFontSize, setBaseFontSize] = useState<number>(getBaseFontSize(window.innerWidth));
  const [isDark, setIsDark] = useState<boolean>(window.localStorage.getItem(Keys.Theme.key) === Keys.Theme.dark);

  const onWindowResize = () => {
    const width = window.innerWidth;
    setBaseFontSize(getBaseFontSize(width));
  };

  const rem = useCallback(
    (fullHdSizeInPx: number): number => {
      if (!isDynamicSizingEnabled) {
        return fullHdSizeInPx;
      }

      return (fullHdSizeInPx * baseFontSize) / 16;
    },
    [baseFontSize],
  );

  const setTheme = useCallback((isDark: boolean) => {
    if (isDark) {
      window.localStorage.setItem(Keys.Theme.key, 'dark');
      document.documentElement.className = Keys.Theme.dark;
      document.body.classList.add('ciq-night');
      setIsDark(true);
    } else {
      window.localStorage.setItem(Keys.Theme.key, 'light');
      document.documentElement.className = Keys.Theme.light;
      document.body.classList.remove('ciq-night');
      setIsDark(false);
    }
  }, []);

  const theme = useMemo(() => (isDark ? darkTheme : lightTheme), [isDark]);

  useEffect(() => {
    if (isDynamicSizingEnabled) {
      window.addEventListener('resize', onWindowResize);
      document.querySelector(`html`)!.classList.add(`dynamicSizingEnabled`);
    }

    const theme = window.localStorage.getItem(Keys.Theme.key);
    setTheme(theme === 'dark');
    if (theme === 'dark') {
      document.body.classList.add('ciq-night');
    } else {
      document.body.classList.remove('ciq-night');
    }

    return () => {
      window.removeEventListener('resize', onWindowResize);
    };
  }, [setTheme]);

  return (
    <NbThemeContext.Provider value={{ rem, isDark, setTheme }}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </NbThemeContext.Provider>
  );
};

export const useTheme = () => {
  const context = useContext(NbThemeContext);
  if (context === undefined) {
    throw new Error(`useTheme must be used within a NbThemeProvider`);
  }
  return context;
};
