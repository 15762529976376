import { useMemo, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { AlertCenterHeader } from '../partials/AlertCenterHeader';
import { AlertCenterList } from '../partials/AlertCenterList';
import { AlertCategory, alertGroupsAtom } from '../state/alertGroupsAtom';
import classes from './AlertCenterModal.module.scss';

export interface AlertCenterModalProps {
  onCancel: () => void;
}

export const AlertCenterModal = ({ onCancel }: AlertCenterModalProps) => {
  const [selectedGroup, setSelectedGroup] = useState<AlertCategory>(AlertCategory.Event);
  const [onlyUnreads, setOnlyUnreads] = useState<boolean>(false);
  const alertGroups = useRecoilValue(alertGroupsAtom);

  const alerts = useMemo(() => {
    const alertGroup = alertGroups.find(group => group.category === selectedGroup)?.alerts ?? [];

    if (onlyUnreads) {
      return alertGroup.filter(alert => !alert.read);
    }

    return alertGroup;
  }, [alertGroups, onlyUnreads, selectedGroup]);

  return (
    <div className={classes.modal}>
      <AlertCenterHeader
        onlyUnreads={onlyUnreads}
        onSwitchChange={setOnlyUnreads}
        selectedGroup={selectedGroup}
        selectGroup={setSelectedGroup}
        onCancel={onCancel}
      />
      <AlertCenterList
        onlyUnreads={onlyUnreads}
        alerts={alerts}
      />
    </div>
  );
};
