import { styled, Tooltip, tooltipClasses, TooltipProps } from '@mui/material';
import classes from './Tooltip.module.scss';

export const NbTooltip = styled(({ className, children, ...props }: TooltipProps) => (
  <Tooltip
    {...props}
    classes={{ popper: className, arrow: classes.tooltipArrow }}
    arrow
  >
    {children}
  </Tooltip>
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: classes.colorBackground,
    color: classes.colorText,
    fontWeight: classes.fontWeight,
    fontSize: classes.fontSize,
    lineHeight: classes.lineHeight,
  },
}));
