import { MarketDataProvider } from '@eih-netbroker/api-dictionary';
import { Keys } from '../config/keys';
import { TypeCategoryItem } from '../types/typeCategoryItem';

export const mockedAccountCategoryTypes: TypeCategoryItem[] = [
  {
    id: 'normal',
    name: 'Normál',
  },
  {
    id: 'nyesz',
    name: 'Nyesz',
  },
  {
    id: 'investmentCard',
    name: 'Befektetési kártya',
  },
  {
    id: 'deposit',
    name: 'Letéti',
  },
  {
    id: 'locked',
    name: 'Zárolt',
  },
  {
    id: 'tbsz2010',
    name: 'TBSZ 2010',
  },
  {
    id: 'tbsz2011',
    name: 'TBSZ 2011',
  },
  {
    id: 'tbsz2012',
    name: 'TBSZ 2012',
  },
  {
    id: 'tbsz2013',
    name: 'TBSZ 2013',
  },
  {
    id: 'tbsz2014',
    name: 'TBSZ 2014',
  },
  {
    id: 'tbsz2015',
    name: 'TBSZ 2015',
  },
  {
    id: 'tbsz2015_2010',
    name: 'TBSZ 2015(2010)',
  },
  {
    id: 'tbsz2016',
    name: 'TBSZ 2016',
  },
  {
    id: 'tbsz2016_2011',
    name: 'TBSZ 2016(2011)',
  },
  {
    id: 'tbsz2017',
    name: 'TBSZ 2017',
  },
  {
    id: 'tbsz2017_2012',
    name: 'TBSZ 2017(2012)',
  },
  {
    id: 'tbsz2018',
    name: 'TBSZ 2018',
  },
  {
    id: 'tbsz2018_2013',
    name: 'TBSZ 2018(2013)',
  },
  {
    id: 'tbsz2019',
    name: 'TBSZ 2019',
  },
  {
    id: 'tbsz2019_2014',
    name: 'TBSZ 2019(2014)',
  },
  {
    id: 'tbsz2020',
    name: 'TBSZ 2020',
  },
  {
    id: 'tbsz2020_2015',
    name: 'TBSZ 2020(2015)',
  },
  {
    id: 'tbsz2020_2010',
    name: 'TBSZ 2020(2010)',
  },
  {
    id: 'tbsz2021',
    name: 'TBSZ 2021',
  },
  {
    id: 'tbsz2021_2016',
    name: 'TBSZ 2021(2016)',
  },
  {
    id: 'tbsz2021_2011',
    name: 'TBSZ 2021(2011)',
  },
  {
    id: 'tbsz2022',
    name: 'TBSZ 2022',
  },
  {
    id: 'tbsz2022_2017',
    name: 'TBSZ 2022(2017)',
  },
  {
    id: 'tbsz2022_2012',
    name: 'TBSZ 2022(2012)',
  },
  {
    id: 'tbsz2023',
    name: 'TBSZ 2023',
  },
];

export const mockedInstrumentCategoryTypes: TypeCategoryItem[] = [
  {
    id: 'investmentUnit',
    name: 'Befektetési Jegy',
  },
  {
    id: 'compensationNote',
    name: 'Kárpótlási jegy',
  },
  {
    id: 'share',
    name: 'Részvény',
  },
  {
    id: 'option',
    name: 'Opciós alaptermék',
  },
  {
    id: 'certificate',
    name: 'Certificate',
  },
  {
    id: 'currency',
    name: 'Deviza',
  },
  {
    id: 'future',
    name: 'Tőzsdei határidős termék',
  },
];

export const mockedMarketDataProviders: MarketDataProvider[] = [
  {
    id: 'bet',
    name: 'BÉT',
    icon: Keys.Markets.Icons.HU,
  },
  {
    id: 'xetraFr',
    name: 'XETRA-Frankfurt',
    icon: Keys.Markets.Icons.DE,
  },
  {
    id: 'xetraVie',
    name: 'XETRA-Bécs',
    icon: Keys.Markets.Icons.DE,
  },
  {
    id: 'otc',
    name: 'OTC',
  },
  {
    id: 'usa',
    name: 'USA',
    icon: Keys.Markets.Icons.US,
  },
  {
    id: 'london',
    name: 'London',
    icon: Keys.Markets.Icons.GB,
  },
];

export const mockedAccountProxyTypes: TypeCategoryItem[] = [
  {
    id: 'main',
    name: 'Főszámla',
  },
  {
    id: 'sub',
    name: 'Alszámla',
  },
  {
    id: 'trustee',
    name: 'Meghatalmazotti',
  },
];

export const mockedSubscriptionPackegesProxyTypes: TypeCategoryItem[] = [
  {
    id: 'delayed',
    name: 'Késleltetett',
  },
  {
    id: 'rt5',
    name: 'RealTime 5',
  },
  {
    id: 'rt10',
    name: 'RealTime 10',
  },
  {
    id: 'rt20',
    name: 'RealTime 20',
  },
  {
    id: 'rt',
    name: 'RealTime',
  },
  {
    id: 'rtDiscount',
    name: 'RealTime kedvezményes',
  },
  {
    id: 'rt5Discount',
    name: 'RealTime 5 kedvezményes',
  },
  {
    id: 'rt10Discount',
    name: 'RealTime 10 kedvezményes',
  },
  {
    id: 'otcRt',
    name: 'OTC RealTime',
  },
];
