import { atom } from 'recoil';
import { ListedInstrument } from '../../../shared/types/listedInstrument';

export enum TriggerState {
  LessThanEqual = 'LessThanEqual',
  GreaterThanEqual = 'GreaterThanEqual',
}

export enum SentimentType {
  Bullish = 'Bullish',
  Bearish = 'Bearish',
  Neutral = 'Neutral',
}

export type PriceAlert = {
  instrument: ListedInstrument;
  trigger: TriggerState;
  price: number;
  sentiment: SentimentType;
};

export type PriceAlerts = {
  priceAlerts: Array<PriceAlert>;
};

export const priceAlertsAtom = atom<PriceAlerts>({
  key: 'trading/priceAlerts',
  default: undefined,
});
