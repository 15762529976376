import { useCallback } from 'react';
import { useRecoilState } from 'recoil';
import { openPositionsAtom } from '../../modules/trading/states/openPositionsAtom';
import { mockedOpenPositions } from '../mock/mockedOpenPositions';
import { resolveLater } from '../utils/mockUtils';

export const useOpenPositions = () => {
  const [openPositions, setOpenPositions] = useRecoilState(openPositionsAtom);

  const loadOpenPositions = useCallback(async (): Promise<void> => {
    const loadedOpenPositions = await resolveLater(mockedOpenPositions);
    setOpenPositions(loadedOpenPositions);
  }, [setOpenPositions]);

  return {
    openPositions,
    loadOpenPositions,
  };
};
