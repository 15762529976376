import {
  Configuration,
  DefaultApi as InstrtumentApi,
  DefaultApiBatchInstrumentQueryRequest as InstrumentApiBatchInstrumentQueryRequest,
} from '@eih-netbroker/api-instrument';
import { AxiosInstance } from 'axios';
import '../mock/resources/mockedInstrument.resource';
import { ResourceHeaders } from '../services/useResource';
import { BaseResource } from './base.resource';

export type BatchInstrumentQueryRequest = Omit<InstrumentApiBatchInstrumentQueryRequest, keyof ResourceHeaders>;

export class InstrumentResource extends BaseResource {
  // Name has to be overriden, since the build job renames it to 'r' and
  // every resource class will have the same name
  // @ts-ignore
  static readonly name = 'InstrumentResource';
  private instrumentApi: InstrtumentApi;

  constructor(axiosInstance: AxiosInstance, basePath: string) {
    super(axiosInstance, basePath);
    this.instrumentApi = new InstrtumentApi(new Configuration(), basePath, axiosInstance);
  }

  async searchListedInstrument(query: string, headers: ResourceHeaders) {
    return this.instrumentApi.searchListedInstrument({ q: query, ...headers });
  }

  async batchInstrumentQuery(request: BatchInstrumentQueryRequest, headers: ResourceHeaders) {
    return this.instrumentApi.batchInstrumentQuery({ ...request, ...headers });
  }
}
