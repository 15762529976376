import { ListedInstrument } from '../listedInstrument';

export enum InstrumentSubscriptionDataScope {
  Trades = 't',
  MarketData = 'md',
  MarketDataExtra = 'mde',
  Book5 = 'b5',
  Book10 = 'b10',
  Book20 = 'b20',
  OHLCV = 'ohlcv',
  Fundamentals = 'fun',
  Financials = 'fin',
}

export type InstrumentSubscriptionMap = {
  ric: string;
  mic: string;
  source: Record<string, Array<InstrumentSubscriptionDataScope>>;
};

export type InstrumentSubscriptionRequestItem = {
  ric: string;
  mic: string;
  datascopes: Array<InstrumentSubscriptionDataScope>;
};

export type SubscribeOptions = {
  instrument: ListedInstrument;
  source: string;
  dataScope: InstrumentSubscriptionDataScope;
};

export type SubscribeList = {
  instruments: Array<ListedInstrument>;
  source: string;
  dataScope: InstrumentSubscriptionDataScope;
};
