import { InstrumentInstumentCategoryEnum } from '@eih-netbroker/api-instrument';
import { getRandomInteger } from '../../../shared/utils/mockUtils';
import { PortfolioCompositionItem } from '../types/portfolioCompositionItem';

const createPortfolioCompositionItem = (
  accountNumber: string,
  accountCategory: string,
  instrumentName: string,
  instrumentCategory: InstrumentInstumentCategoryEnum,
): PortfolioCompositionItem => ({
  accountNumber,
  clientId: '5079058',
  accountCategory,
  instrumentCategory,
  instrumentName,
  isin: 'HU0000403597',
  ric: null,
  ticker: instrumentName,
  marketName: 'OTC',
  marketMICCode: 'XXXX',
  inventoryDate: '2018-09-03',
  position: getRandomInteger(1, 100),
  decimalPlace: null,
  costBasis: 0,
  initCcy: 'HUF',
  initValue: getRandomInteger(1, 5000),
  initPriceValue: 10.028,
  marketPrice: 0,
  marketPriceCcy: 'HUF',
  marketPriceDate: '2023-02-21',
  exchangeRate: 1,
  profitLossPerPostion: -1.0028,
  profitLossPerPostionHuf: -1.0028,
  grossProfitLoss: -10.028,
  netProfitLoss: -10.028,
  grossProfitLossHuf: -10.028,
  netProfitLossHuf: -10.028,
});

export const mockedPortfolioCompositionSmall: Array<PortfolioCompositionItem> = [
  createPortfolioCompositionItem('5079058_1', 'normal', 'MOL', InstrumentInstumentCategoryEnum.Share),
  createPortfolioCompositionItem('5079058_1', 'normal', 'MOL', InstrumentInstumentCategoryEnum.Share),
];
export const mockedPortfolioCompositionMedium: Array<PortfolioCompositionItem> = [
  createPortfolioCompositionItem('5079058_1', 'tbsz2021', 'SPY', InstrumentInstumentCategoryEnum.Future),
  createPortfolioCompositionItem('5079058_2', 'tbsz2022', 'SPY', InstrumentInstumentCategoryEnum.Future),
  createPortfolioCompositionItem('5079058_2', 'tbsz2022', 'MOL', InstrumentInstumentCategoryEnum.Share),
  createPortfolioCompositionItem('5079058_3', 'normal', 'TSLA', InstrumentInstumentCategoryEnum.Share),
];

export const mockedPortfolioComposition: Array<PortfolioCompositionItem> = [
  {
    accountNumber: '5079058_1',
    clientId: '5079058',
    accountCategory: 'normal',
    instrumentCategory: InstrumentInstumentCategoryEnum.InvestmentUnit,
    instrumentName: '2020/H4 KÉTÉVES MAGYAR ÁLLAMPAPÍR',
    isin: 'HU0000403597',
    ric: null,
    ticker: '2020/H4',
    marketName: 'OTC',
    marketMICCode: 'XXXX',
    inventoryDate: '2018-09-03',
    position: 10,
    decimalPlace: null,
    costBasis: 0,
    initCcy: 'HUF',
    initValue: 1.0028,
    initPriceValue: 10.028,
    marketPrice: 0,
    marketPriceCcy: 'HUF',
    marketPriceDate: '2023-02-21',
    exchangeRate: 1,
    profitLossPerPostion: -1.0028,
    profitLossPerPostionHuf: -1.0028,
    grossProfitLoss: -10.028,
    netProfitLoss: -10.028,
    grossProfitLossHuf: -10.028,
    netProfitLossHuf: -10.028,
  },
  {
    accountNumber: '5079058_14',
    clientId: '5079058',
    accountCategory: 'tbsz2016_2011',
    instrumentCategory: InstrumentInstumentCategoryEnum.InvestmentUnit,
    instrumentName: '2020/H4 KÉTÉVES MAGYAR ÁLLAMPAPÍR',
    isin: 'HU0000403597',
    ric: null,
    ticker: '2020/H4',
    marketName: 'OTC',
    marketMICCode: 'XXXX',
    inventoryDate: '2018-09-13',
    position: 100,
    decimalPlace: null,
    costBasis: 0,
    initCcy: 'HUF',
    initValue: 1.0036,
    initPriceValue: 100.36,
    marketPrice: 0,
    marketPriceCcy: 'HUF',
    marketPriceDate: '2023-02-21',
    exchangeRate: 1,
    profitLossPerPostion: -1.0036,
    profitLossPerPostionHuf: -1.0036,
    grossProfitLoss: -100.36,
    netProfitLoss: -100.36,
    grossProfitLossHuf: -100.36,
    netProfitLossHuf: -100.36,
  },
  {
    accountNumber: '5079058_14',
    clientId: '5079058',
    accountCategory: 'tbsz2016_2011',
    instrumentCategory: InstrumentInstumentCategoryEnum.InvestmentUnit,
    instrumentName: '2020/H4 KÉTÉVES MAGYAR ÁLLAMPAPÍR',
    isin: 'HU0000403597',
    marketName: 'OTC',
    marketMICCode: 'XXXX',
    inventoryDate: '2018-09-13',
    position: 100,
    decimalPlace: null,
    costBasis: 0,
    initCcy: 'HUF',
    initValue: 1.0036,
    initPriceValue: 100.36,
    marketPrice: 0,
    marketPriceCcy: 'HUF',
    marketPriceDate: '2023-02-21',
    exchangeRate: 1,
    profitLossPerPostion: -1.0036,
    profitLossPerPostionHuf: -1.0036,
    grossProfitLoss: -100.36,
    netProfitLoss: -100.36,
    grossProfitLossHuf: -100.36,
    netProfitLossHuf: -100.36,
  },
  {
    accountNumber: '5079058_1',
    clientId: '5079058',
    accountCategory: 'normal',
    instrumentCategory: InstrumentInstumentCategoryEnum.InvestmentUnit,
    instrumentName: '2021/H1 KÉTÉVES MAGYAR ÁLLAMPAPÍR',
    isin: 'HU0000403654',
    ric: null,
    ticker: '2021/H1',
    marketName: 'OTC',
    marketMICCode: 'XXXX',
    inventoryDate: '2019-01-29',
    position: 10,
    decimalPlace: null,
    costBasis: 0,
    initCcy: 'HUF',
    initValue: 1.0023,
    initPriceValue: 10.023,
    marketPrice: 10.324,
    marketPriceCcy: 'HUF',
    marketPriceDate: '2023-02-21',
    exchangeRate: 1,
    profitLossPerPostion: 0.0301,
    profitLossPerPostionHuf: 0.0301,
    grossProfitLoss: 0.301,
    netProfitLoss: 0.301,
    grossProfitLossHuf: 0.301,
    netProfitLossHuf: 0.301,
  },
  {
    accountNumber: '5079058_1',
    clientId: '5079058',
    accountCategory: 'normal',
    instrumentCategory: InstrumentInstumentCategoryEnum.InvestmentUnit,
    instrumentName: '2021/H1 KÉTÉVES MAGYAR ÁLLAMPAPÍR',
    isin: 'HU0000403654',
    ric: null,
    ticker: '2021/H1',
    marketName: 'OTC',
    marketMICCode: 'XXXX',
    inventoryDate: '2019-01-29',
    position: 10,
    decimalPlace: null,
    costBasis: 0,
    initCcy: 'HUF',
    initValue: 1.0023,
    initPriceValue: 10.023,
    marketPrice: 10.324,
    marketPriceCcy: 'HUF',
    marketPriceDate: '2023-02-21',
    exchangeRate: 1,
    profitLossPerPostion: 0.0301,
    profitLossPerPostionHuf: 0.0301,
    grossProfitLoss: 0.301,
    netProfitLoss: 0.301,
    grossProfitLossHuf: 0.301,
    netProfitLossHuf: 0.301,
  },
  {
    accountNumber: '5079058_2',
    clientId: '5079058',
    accountCategory: 'nyesz',
    instrumentCategory: InstrumentInstumentCategoryEnum.InvestmentUnit,
    instrumentName: '2021/H2 KÉTÉVES MAGYAR ÁLLAMPAPÍR',
    isin: 'HU0000403712',
    ric: null,
    ticker: '2021/H1',
    marketName: 'OTC',
    marketMICCode: 'XXXX',
    inventoryDate: '2019-04-08',
    position: 8,
    decimalPlace: null,
    costBasis: 0,
    initCcy: 'HUF',
    initValue: 1.0005,
    initPriceValue: 8.004,
    marketPrice: 8,
    marketPriceCcy: 'HUF',
    marketPriceDate: '2023-02-21',
    exchangeRate: 1,
    profitLossPerPostion: -0.0005,
    profitLossPerPostionHuf: -0.0005,
    grossProfitLoss: -0.004,
    netProfitLoss: -0.004,
    grossProfitLossHuf: -0.004,
    netProfitLossHuf: -0.004,
  },
  {
    accountNumber: '5079058_1',
    clientId: '5079058',
    accountCategory: 'normal',
    instrumentCategory: InstrumentInstumentCategoryEnum.InvestmentUnit,
    instrumentName: '2024/P BÓNUSZ MAGYAR ÁLLAMPAPÍR',
    isin: 'HU0000403514',
    ric: null,
    ticker: '2024/P',
    marketName: 'OTC',
    marketMICCode: 'XXXX',
    inventoryDate: '2019-04-08',
    position: 10,
    decimalPlace: null,
    costBasis: 0,
    initCcy: 'HUF',
    initValue: 1.0336,
    initPriceValue: 10.336,
    marketPrice: 11,
    marketPriceCcy: 'HUF',
    marketPriceDate: '0001-01-01',
    exchangeRate: 1,
    profitLossPerPostion: 0.0664,
    profitLossPerPostionHuf: 0.0664,
    grossProfitLoss: 0.664,
    netProfitLoss: 0.664,
    grossProfitLossHuf: 0.664,
    netProfitLossHuf: 0.664,
  },
  {
    accountNumber: '5079058_2',
    clientId: '5079058',
    accountCategory: 'nyesz',
    instrumentCategory: InstrumentInstumentCategoryEnum.InvestmentUnit,
    instrumentName: '2024/P BÓNUSZ MAGYAR ÁLLAMPAPÍR',
    isin: 'HU0000403514',
    ric: null,
    ticker: '2024/P',
    marketName: 'OTC',
    marketMICCode: 'XXXX',
    inventoryDate: '2019-04-09',
    position: 10,
    decimalPlace: null,
    costBasis: 0,
    initCcy: 'HUF',
    initValue: 1.0337,
    initPriceValue: 10.337,
    marketPrice: 11,
    marketPriceCcy: 'HUF',
    marketPriceDate: '0001-01-01',
    exchangeRate: 1,
    profitLossPerPostion: 0.0663,
    profitLossPerPostionHuf: 0.0663,
    grossProfitLoss: 0.663,
    netProfitLoss: 0.663,
    grossProfitLossHuf: 0.663,
    netProfitLossHuf: 0.663,
  },
  {
    accountNumber: '5079058_1',
    clientId: '5079058',
    accountCategory: 'normal',
    instrumentCategory: 'share',
    instrumentName: 'Delta Technologies',
    isin: 'HU0000151956',
    ric: 'DELTA.BU',
    ticker: 'DELTA',
    marketName: 'XETRA - BÉT',
    marketMICCode: 'XBU2',
    inventoryDate: '2019-01-22',
    position: 10,
    decimalPlace: null,
    costBasis: 1,
    initCcy: 'HUF',
    initValue: 104,
    initPriceValue: 1040,
    marketPrice: 1090,
    marketPriceCcy: 'HUF',
    marketPriceDate: '2023-06-12',
    exchangeRate: 1,
    profitLossPerPostion: 5,
    profitLossPerPostionHuf: 5,
    grossProfitLoss: 50,
    netProfitLoss: 49,
    grossProfitLossHuf: 50,
    netProfitLossHuf: 49,
  },
  {
    accountNumber: '5079058_1',
    clientId: '5079058',
    accountCategory: 'normal',
    instrumentCategory: 'investmentUnit',
    instrumentName: 'ERSTE BÁZIS BEFEKTETÉSI ALAP',
    isin: 'HU0000702006',
    ric: null,
    ticker: null,
    marketName: 'OTC',
    marketMICCode: 'XXXX',
    inventoryDate: '2018-06-29',
    position: 37,
    decimalPlace: null,
    costBasis: 0,
    initCcy: 'HUF',
    initValue: 2.240673,
    initPriceValue: 82.904901,
    marketPrice: 41.5658,
    marketPriceCcy: 'HUF',
    marketPriceDate: '2023-06-13',
    exchangeRate: 1,
    profitLossPerPostion: -1.117273,
    profitLossPerPostionHuf: -1.117273,
    grossProfitLoss: -41.339101,
    netProfitLoss: -41.339101,
    grossProfitLossHuf: -41.339101,
    netProfitLossHuf: -41.339101,
  },
  {
    accountNumber: '5079058_1',
    clientId: '5079058',
    accountCategory: 'normal',
    instrumentCategory: 'investmentUnit',
    instrumentName: 'ERSTE BÁZIS BEFEKTETÉSI ALAP',
    isin: 'HU0000702006',
    ric: null,
    ticker: null,
    marketName: 'OTC',
    marketMICCode: 'XXXX',
    inventoryDate: '2018-11-26',
    position: 1,
    decimalPlace: null,
    costBasis: 0,
    initCcy: 'HUF',
    initValue: 2.242489,
    initPriceValue: 2.242489,
    marketPrice: 1.1234,
    marketPriceCcy: 'HUF',
    marketPriceDate: '2023-06-13',
    exchangeRate: 1,
    profitLossPerPostion: -1.119089,
    profitLossPerPostionHuf: -1.119089,
    grossProfitLoss: -1.119089,
    netProfitLoss: -1.119089,
    grossProfitLossHuf: -1.119089,
    netProfitLossHuf: -1.119089,
  },
  {
    accountNumber: '5079058_1',
    clientId: '5079058',
    accountCategory: 'normal',
    instrumentCategory: 'investmentUnit',
    instrumentName: 'ERSTE BÁZIS BEFEKTETÉSI ALAP',
    isin: 'HU0000702006',
    ric: null,
    ticker: null,
    marketName: 'OTC',
    marketMICCode: 'XXXX',
    inventoryDate: '2019-02-05',
    position: 7,
    decimalPlace: null,
    costBasis: 0,
    initCcy: 'HUF',
    initValue: 2.242049,
    initPriceValue: 15.694343,
    marketPrice: 7.8638,
    marketPriceCcy: 'HUF',
    marketPriceDate: '2023-06-13',
    exchangeRate: 1,
    profitLossPerPostion: -1.118649,
    profitLossPerPostionHuf: -1.118649,
    grossProfitLoss: -7.830543,
    netProfitLoss: -7.830543,
    grossProfitLossHuf: -7.830543,
    netProfitLossHuf: -7.830543,
  },
  {
    accountNumber: '5079058_1',
    clientId: '5079058',
    accountCategory: 'normal',
    instrumentCategory: 'investmentUnit',
    instrumentName: 'ERSTE BÁZIS BEFEKTETÉSI ALAP',
    isin: 'HU0000702006',
    ric: null,
    ticker: null,
    marketName: 'OTC',
    marketMICCode: 'XXXX',
    inventoryDate: '2019-05-27',
    position: 4,
    decimalPlace: null,
    costBasis: 0,
    initCcy: 'HUF',
    initValue: 2.242347,
    initPriceValue: 8.969388,
    marketPrice: 4.4936,
    marketPriceCcy: 'HUF',
    marketPriceDate: '2023-06-13',
    exchangeRate: 1,
    profitLossPerPostion: -1.118947,
    profitLossPerPostionHuf: -1.118947,
    grossProfitLoss: -4.475788,
    netProfitLoss: -4.475788,
    grossProfitLossHuf: -4.475788,
    netProfitLossHuf: -4.475788,
  },
  {
    accountNumber: '5079058_1',
    clientId: '5079058',
    accountCategory: 'normal',
    instrumentCategory: 'investmentUnit',
    instrumentName: 'ERSTE BÁZIS BEFEKTETÉSI ALAP',
    isin: 'HU0000702006',
    ric: null,
    ticker: null,
    marketName: 'OTC',
    marketMICCode: 'XXXX',
    inventoryDate: '2020-09-28',
    position: 435,
    decimalPlace: null,
    costBasis: 0,
    initCcy: 'HUF',
    initValue: 2.244883,
    initPriceValue: 976.524105,
    marketPrice: 488.679,
    marketPriceCcy: 'HUF',
    marketPriceDate: '2023-06-13',
    exchangeRate: 1,
    profitLossPerPostion: -1.121483,
    profitLossPerPostionHuf: -1.121483,
    grossProfitLoss: -487.845105,
    netProfitLoss: -487.845105,
    grossProfitLossHuf: -487.845105,
    netProfitLossHuf: -487.845105,
  },
  {
    accountNumber: '5079058_1',
    clientId: '5079058',
    accountCategory: 'normal',
    instrumentCategory: 'investmentUnit',
    instrumentName: 'ERSTE BÁZIS BEFEKTETÉSI ALAP',
    isin: 'HU0000702006',
    ric: null,
    ticker: null,
    marketName: 'OTC',
    marketMICCode: 'XXXX',
    inventoryDate: '2020-10-02',
    position: 445,
    decimalPlace: null,
    costBasis: 0,
    initCcy: 'HUF',
    initValue: 2.244757,
    initPriceValue: 998.916865,
    marketPrice: 499.913,
    marketPriceCcy: 'HUF',
    marketPriceDate: '2023-06-13',
    exchangeRate: 1,
    profitLossPerPostion: -1.121357,
    profitLossPerPostionHuf: -1.121357,
    grossProfitLoss: -499.003865,
    netProfitLoss: -499.003865,
    grossProfitLossHuf: -499.003865,
    netProfitLossHuf: -499.003865,
  },
  {
    accountNumber: '5079058_1',
    clientId: '5079058',
    accountCategory: 'normal',
    instrumentCategory: 'investmentUnit',
    instrumentName: 'ERSTE BÁZIS BEFEKTETÉSI ALAP',
    isin: 'HU0000702006',
    ric: null,
    ticker: null,
    marketName: 'OTC',
    marketMICCode: 'XXXX',
    inventoryDate: '2020-11-04',
    position: 4,
    decimalPlace: null,
    costBasis: 0,
    initCcy: 'HUF',
    initValue: 2.246297,
    initPriceValue: 8.985188,
    marketPrice: 4.4936,
    marketPriceCcy: 'HUF',
    marketPriceDate: '2023-06-13',
    exchangeRate: 1,
    profitLossPerPostion: -1.122897,
    profitLossPerPostionHuf: -1.122897,
    grossProfitLoss: -4.491588,
    netProfitLoss: -4.491588,
    grossProfitLossHuf: -4.491588,
    netProfitLossHuf: -4.491588,
  },
  {
    accountNumber: '5079058_1',
    clientId: '5079058',
    accountCategory: 'normal',
    instrumentCategory: 'investmentUnit',
    instrumentName: 'ERSTE BÁZIS BEFEKTETÉSI ALAP',
    isin: 'HU0000702006',
    ric: null,
    ticker: null,
    marketName: 'OTC',
    marketMICCode: 'XXXX',
    inventoryDate: '2020-11-04',
    position: 4,
    decimalPlace: null,
    costBasis: 0,
    initCcy: 'HUF',
    initValue: 2.246297,
    initPriceValue: 8.985188,
    marketPrice: 4.4936,
    marketPriceCcy: 'HUF',
    marketPriceDate: '2023-06-13',
    exchangeRate: 1,
    profitLossPerPostion: -1.122897,
    profitLossPerPostionHuf: -1.122897,
    grossProfitLoss: -4.491588,
    netProfitLoss: -4.491588,
    grossProfitLossHuf: -4.491588,
    netProfitLossHuf: -4.491588,
  },
  {
    accountNumber: '5079058_1',
    clientId: '5079058',
    accountCategory: 'normal',
    instrumentCategory: 'investmentUnit',
    instrumentName: 'ERSTE BÁZIS BEFEKTETÉSI ALAP',
    isin: 'HU0000702006',
    ric: null,
    ticker: null,
    marketName: 'OTC',
    marketMICCode: 'XXXX',
    inventoryDate: '2020-12-04',
    position: 44,
    decimalPlace: null,
    costBasis: 0,
    initCcy: 'HUF',
    initValue: 2.248088,
    initPriceValue: 98.915872,
    marketPrice: 49.4296,
    marketPriceCcy: 'HUF',
    marketPriceDate: '2023-06-13',
    exchangeRate: 1,
    profitLossPerPostion: -1.124688,
    profitLossPerPostionHuf: -1.124688,
    grossProfitLoss: -49.486272,
    netProfitLoss: -49.486272,
    grossProfitLossHuf: -49.486272,
    netProfitLossHuf: -49.486272,
  },
  {
    accountNumber: '5079058_1',
    clientId: '5079058',
    accountCategory: 'normal',
    instrumentCategory: 'investmentUnit',
    instrumentName: 'ERSTE BÁZIS BEFEKTETÉSI ALAP',
    isin: 'HU0000702006',
    ric: null,
    ticker: null,
    marketName: 'OTC',
    marketMICCode: 'XXXX',
    inventoryDate: '2020-12-04',
    position: 3,
    decimalPlace: null,
    costBasis: 0,
    initCcy: 'HUF',
    initValue: 2.248088,
    initPriceValue: 6.744264,
    marketPrice: 3.3702,
    marketPriceCcy: 'HUF',
    marketPriceDate: '2023-06-13',
    exchangeRate: 1,
    profitLossPerPostion: -1.124688,
    profitLossPerPostionHuf: -1.124688,
    grossProfitLoss: -3.374064,
    netProfitLoss: -3.374064,
    grossProfitLossHuf: -3.374064,
    netProfitLossHuf: -3.374064,
  },
  {
    accountNumber: '5079058_1',
    clientId: '5079058',
    accountCategory: 'normal',
    instrumentCategory: 'investmentUnit',
    instrumentName: 'ERSTE BÁZIS BEFEKTETÉSI ALAP',
    isin: 'HU0000702006',
    ric: null,
    ticker: null,
    marketName: 'OTC',
    marketMICCode: 'XXXX',
    inventoryDate: '2020-12-18',
    position: 44,
    decimalPlace: null,
    costBasis: 0,
    initCcy: 'HUF',
    initValue: 2.24823,
    initPriceValue: 98.92212,
    marketPrice: 49.4296,
    marketPriceCcy: 'HUF',
    marketPriceDate: '2023-06-13',
    exchangeRate: 1,
    profitLossPerPostion: -1.12483,
    profitLossPerPostionHuf: -1.12483,
    grossProfitLoss: -49.49252,
    netProfitLoss: -49.49252,
    grossProfitLossHuf: -49.49252,
    netProfitLossHuf: -49.49252,
  },
  {
    accountNumber: '5079058_1',
    clientId: '5079058',
    accountCategory: 'normal',
    instrumentCategory: 'investmentUnit',
    instrumentName: 'ERSTE BÁZIS BEFEKTETÉSI ALAP',
    isin: 'HU0000702006',
    ric: null,
    ticker: null,
    marketName: 'OTC',
    marketMICCode: 'XXXX',
    inventoryDate: '2018-02-19',
    position: 4,
    decimalPlace: null,
    costBasis: 0,
    initCcy: 'HUF',
    initValue: 2.245312,
    initPriceValue: 8.981248,
    marketPrice: 4.4936,
    marketPriceCcy: 'HUF',
    marketPriceDate: '2023-06-13',
    exchangeRate: 1,
    profitLossPerPostion: -1.121912,
    profitLossPerPostionHuf: -1.121912,
    grossProfitLoss: -4.487648,
    netProfitLoss: -4.487648,
    grossProfitLossHuf: -4.487648,
    netProfitLossHuf: -4.487648,
  },
  {
    accountNumber: '5079058_1',
    clientId: '5079058',
    accountCategory: 'normal',
    instrumentCategory: 'investmentUnit',
    instrumentName: 'ERSTE BÁZIS BEFEKTETÉSI ALAP',
    isin: 'HU0000702006',
    ric: null,
    ticker: null,
    marketName: 'OTC',
    marketMICCode: 'XXXX',
    inventoryDate: '2018-06-29',
    position: 42,
    decimalPlace: null,
    costBasis: 0,
    initCcy: 'HUF',
    initValue: 2.241946,
    initPriceValue: 94.161732,
    marketPrice: 47.1828,
    marketPriceCcy: 'HUF',
    marketPriceDate: '2023-06-13',
    exchangeRate: 1,
    profitLossPerPostion: -1.118546,
    profitLossPerPostionHuf: -1.118546,
    grossProfitLoss: -46.978932,
    netProfitLoss: -46.978932,
    grossProfitLossHuf: -46.978932,
    netProfitLossHuf: -46.978932,
  },
  {
    accountNumber: '5079058_14',
    clientId: '5079058',
    accountCategory: 'tbsz2016_2011',
    instrumentCategory: 'investmentUnit',
    instrumentName: 'ERSTE BÁZIS BEFEKTETÉSI ALAP',
    isin: 'HU0000702006',
    ric: null,
    ticker: null,
    marketName: 'OTC',
    marketMICCode: 'XXXX',
    inventoryDate: '2011-01-03',
    position: 41,
    decimalPlace: null,
    costBasis: 0,
    initCcy: 'HUF',
    initValue: 2.256811,
    initPriceValue: 92.529251,
    marketPrice: 46.0594,
    marketPriceCcy: 'HUF',
    marketPriceDate: '2023-06-13',
    exchangeRate: 1,
    profitLossPerPostion: -1.133411,
    profitLossPerPostionHuf: -1.133411,
    grossProfitLoss: -46.469851,
    netProfitLoss: -46.469851,
    grossProfitLossHuf: -46.469851,
    netProfitLossHuf: -46.469851,
  },
  {
    accountNumber: '5079058_1',
    clientId: '5079058',
    accountCategory: 'normal',
    instrumentCategory: 'investmentUnit',
    instrumentName: 'ERSTE DOLLÁR BÁZIS BEFEKTETÉSI ALAP',
    isin: 'HU0000705991',
    ric: null,
    ticker: null,
    marketName: 'OTC',
    marketMICCode: 'XXXX',
    inventoryDate: '2020-12-04',
    position: 1,
    decimalPlace: null,
    costBasis: 0,
    initCcy: 'USD',
    initValue: 1.1621,
    initPriceValue: 1.1621,
    marketPrice: 1.1234,
    marketPriceCcy: 'USD',
    marketPriceDate: '2023-06-13',
    exchangeRate: 296.5,
    profitLossPerPostion: -0.0387,
    profitLossPerPostionHuf: -11.47455,
    grossProfitLoss: -0.0387,
    netProfitLoss: -0.0387,
    grossProfitLossHuf: -11.47455,
    netProfitLossHuf: -11.47455,
  },
  {
    accountNumber: '5079058_1',
    clientId: '5079058',
    accountCategory: 'normal',
    instrumentCategory: 'investmentUnit',
    instrumentName: 'ERSTE DUETT ALAPOK ALAPJA',
    isin: 'HU0000703830',
    ric: null,
    ticker: null,
    marketName: 'OTC',
    marketMICCode: 'XXXX',
    inventoryDate: '2020-08-27',
    position: 1330,
    decimalPlace: null,
    costBasis: 0,
    initCcy: 'HUF',
    initValue: 1.8773,
    initPriceValue: 2496.809,
    marketPrice: 1494.122,
    marketPriceCcy: 'HUF',
    marketPriceDate: '2023-06-13',
    exchangeRate: 1,
    profitLossPerPostion: -0.7539,
    profitLossPerPostionHuf: -0.7539,
    grossProfitLoss: -1002.687,
    netProfitLoss: -1002.687,
    grossProfitLossHuf: -1002.687,
    netProfitLossHuf: -1002.687,
  },
  {
    accountNumber: '5079058_1',
    clientId: '5079058',
    accountCategory: 'normal',
    instrumentCategory: 'investmentUnit',
    instrumentName: 'ERSTE DUETT ALAPOK ALAPJA',
    isin: 'HU0000703830',
    ric: null,
    ticker: null,
    marketName: 'OTC',
    marketMICCode: 'XXXX',
    inventoryDate: '2020-09-21',
    position: 1594,
    decimalPlace: null,
    costBasis: 0,
    initCcy: 'HUF',
    initValue: 1.8801,
    initPriceValue: 2996.8794,
    marketPrice: 1790.6996,
    marketPriceCcy: 'HUF',
    marketPriceDate: '2023-06-13',
    exchangeRate: 1,
    profitLossPerPostion: -0.7567,
    profitLossPerPostionHuf: -0.7567,
    grossProfitLoss: -1206.1798,
    netProfitLoss: -1206.1798,
    grossProfitLossHuf: -1206.1798,
    netProfitLossHuf: -1206.1798,
  },
  {
    accountNumber: '5079058_1',
    clientId: '5079058',
    accountCategory: 'normal',
    instrumentCategory: 'investmentUnit',
    instrumentName: 'ERSTE DUETT ALAPOK ALAPJA',
    isin: 'HU0000703830',
    ric: null,
    ticker: null,
    marketName: 'OTC',
    marketMICCode: 'XXXX',
    inventoryDate: '2020-09-29',
    position: 1329,
    decimalPlace: null,
    costBasis: 0,
    initCcy: 'HUF',
    initValue: 1.8809,
    initPriceValue: 2499.7161,
    marketPrice: 1492.9986,
    marketPriceCcy: 'HUF',
    marketPriceDate: '2023-06-13',
    exchangeRate: 1,
    profitLossPerPostion: -0.7575,
    profitLossPerPostionHuf: -0.7575,
    grossProfitLoss: -1006.7175,
    netProfitLoss: -1006.7175,
    grossProfitLossHuf: -1006.7175,
    netProfitLossHuf: -1006.7175,
  },
  {
    accountNumber: '5079058_1',
    clientId: '5079058',
    accountCategory: 'normal',
    instrumentCategory: 'investmentUnit',
    instrumentName: 'ERSTE DUETT ALAPOK ALAPJA',
    isin: 'HU0000703830',
    ric: null,
    ticker: null,
    marketName: 'OTC',
    marketMICCode: 'XXXX',
    inventoryDate: '2020-10-27',
    position: 1325,
    decimalPlace: null,
    costBasis: 0,
    initCcy: 'HUF',
    initValue: 1.8848,
    initPriceValue: 2497.36,
    marketPrice: 1488.505,
    marketPriceCcy: 'HUF',
    marketPriceDate: '2023-06-13',
    exchangeRate: 1,
    profitLossPerPostion: -0.7614,
    profitLossPerPostionHuf: -0.7614,
    grossProfitLoss: -1008.855,
    netProfitLoss: -1008.855,
    grossProfitLossHuf: -1008.855,
    netProfitLossHuf: -1008.855,
  },
  {
    accountNumber: '5079058_1',
    clientId: '5079058',
    accountCategory: 'normal',
    instrumentCategory: 'investmentUnit',
    instrumentName: 'ERSTE DUETT ALAPOK ALAPJA',
    isin: 'HU0000703830',
    marketName: 'OTC',
    marketMICCode: 'XXXX',
    inventoryDate: '2020-11-27',
    position: 1324,
    decimalPlace: null,
    costBasis: 0,
    initCcy: 'HUF',
    initValue: 1.8882,
    initPriceValue: 2499.9768,
    marketPrice: 1487.3816,
    marketPriceCcy: 'HUF',
    marketPriceDate: '2023-06-13',
    exchangeRate: 1,
    profitLossPerPostion: -0.7648,
    profitLossPerPostionHuf: -0.7648,
    grossProfitLoss: -1012.5952,
    netProfitLoss: -1012.5952,
    grossProfitLossHuf: -1012.5952,
    netProfitLossHuf: -1012.5952,
  },
  {
    accountNumber: '5079058_1',
    clientId: '5079058',
    accountCategory: 'normal',
    instrumentCategory: 'investmentUnit',
    instrumentName: 'ERSTE DUETT ALAPOK ALAPJA',
    isin: 'HU0000703830',
    ric: null,
    ticker: null,
    marketName: 'OTC',
    marketMICCode: 'XXXX',
    inventoryDate: '2020-12-07',
    position: 104,
    decimalPlace: null,
    costBasis: 0,
    initCcy: 'HUF',
    initValue: 1.889,
    initPriceValue: 196.456,
    marketPrice: 116.8336,
    marketPriceCcy: 'HUF',
    marketPriceDate: '2023-06-13',
    exchangeRate: 1,
    profitLossPerPostion: -0.7656,
    profitLossPerPostionHuf: -0.7656,
    grossProfitLoss: -79.6224,
    netProfitLoss: -79.6224,
    grossProfitLossHuf: -79.6224,
    netProfitLossHuf: -79.6224,
  },
  {
    accountNumber: '5079058_1',
    clientId: '5079058',
    accountCategory: 'normal',
    instrumentCategory: 'investmentUnit',
    instrumentName: 'ERSTE DUETT ALAPOK ALAPJA',
    isin: 'HU0000703830',
    ric: null,
    ticker: null,
    marketName: 'OTC',
    marketMICCode: 'XXXX',
    inventoryDate: '2020-12-21',
    position: 1058,
    decimalPlace: null,
    costBasis: 0,
    initCcy: 'HUF',
    initValue: 1.8901,
    initPriceValue: 1999.7258,
    marketPrice: 1188.5572,
    marketPriceCcy: 'HUF',
    marketPriceDate: '2023-06-13',
    exchangeRate: 1,
    profitLossPerPostion: -0.7667,
    profitLossPerPostionHuf: -0.7667,
    grossProfitLoss: -811.1686,
    netProfitLoss: -811.1686,
    grossProfitLossHuf: -811.1686,
    netProfitLossHuf: -811.1686,
  },
  {
    accountNumber: '5079058_1',
    clientId: '5079058',
    accountCategory: 'normal',
    instrumentCategory: 'investmentUnit',
    instrumentName: 'ERSTE DUETT ALAPOK ALAPJA',
    isin: 'HU0000703830',
    ric: null,
    ticker: null,
    marketName: 'OTC',
    marketMICCode: 'XXXX',
    inventoryDate: '2020-12-21',
    position: 793,
    decimalPlace: null,
    costBasis: 0,
    initCcy: 'HUF',
    initValue: 1.8901,
    initPriceValue: 1498.8493,
    marketPrice: 890.8562,
    marketPriceCcy: 'HUF',
    marketPriceDate: '2023-06-13',
    exchangeRate: 1,
    profitLossPerPostion: -0.7667,
    profitLossPerPostionHuf: -0.7667,
    grossProfitLoss: -607.9931,
    netProfitLoss: -607.9931,
    grossProfitLossHuf: -607.9931,
    netProfitLossHuf: -607.9931,
  },
  {
    accountNumber: '5079058_2',
    clientId: '5079058',
    accountCategory: 'nyesz',
    instrumentCategory: 'investmentUnit',
    instrumentName: 'ERSTE DUETT ALAPOK ALAPJA',
    isin: 'HU0000703830',
    ric: null,
    ticker: null,
    marketName: 'OTC',
    marketMICCode: 'XXXX',
    inventoryDate: '2020-09-08',
    position: 531,
    decimalPlace: null,
    costBasis: 0,
    initCcy: 'HUF',
    initValue: 1.8789,
    initPriceValue: 997.6959,
    marketPrice: 596.5254,
    marketPriceCcy: 'HUF',
    marketPriceDate: '2023-06-13',
    exchangeRate: 1,
    profitLossPerPostion: -0.7555,
    profitLossPerPostionHuf: -0.7555,
    grossProfitLoss: -401.1705,
    netProfitLoss: -401.1705,
    grossProfitLossHuf: -401.1705,
    netProfitLossHuf: -401.1705,
  },
  {
    accountNumber: '5079058_2',
    clientId: '5079058',
    accountCategory: 'nyesz',
    instrumentCategory: 'investmentUnit',
    instrumentName: 'ERSTE DUETT ALAPOK ALAPJA',
    isin: 'HU0000703830',
    ric: null,
    ticker: null,
    marketName: 'OTC',
    marketMICCode: 'XXXX',
    inventoryDate: '2020-10-08',
    position: 530,
    decimalPlace: null,
    costBasis: 0,
    initCcy: 'HUF',
    initValue: 1.8822,
    initPriceValue: 997.566,
    marketPrice: 595.402,
    marketPriceCcy: 'HUF',
    marketPriceDate: '2023-06-13',
    exchangeRate: 1,
    profitLossPerPostion: -0.7588,
    profitLossPerPostionHuf: -0.7588,
    grossProfitLoss: -402.164,
    netProfitLoss: -402.164,
    grossProfitLossHuf: -402.164,
    netProfitLossHuf: -402.164,
  },
  {
    accountNumber: '5079058_2',
    clientId: '5079058',
    accountCategory: 'nyesz',
    instrumentCategory: 'investmentUnit',
    instrumentName: 'ERSTE DUETT ALAPOK ALAPJA',
    isin: 'HU0000703830',
    ric: null,
    ticker: null,
    marketName: 'OTC',
    marketMICCode: 'XXXX',
    inventoryDate: '2020-11-05',
    position: 4,
    decimalPlace: null,
    costBasis: 0,
    initCcy: 'HUF',
    initValue: 1.8857,
    initPriceValue: 7.5428,
    marketPrice: 4.4936,
    marketPriceCcy: 'HUF',
    marketPriceDate: '2023-06-13',
    exchangeRate: 1,
    profitLossPerPostion: -0.7623,
    profitLossPerPostionHuf: -0.7623,
    grossProfitLoss: -3.0492,
    netProfitLoss: -3.0492,
    grossProfitLossHuf: -3.0492,
    netProfitLossHuf: -3.0492,
  },
  {
    accountNumber: '5079058_2',
    clientId: '5079058',
    accountCategory: 'nyesz',
    instrumentCategory: 'investmentUnit',
    instrumentName: 'ERSTE DUETT ALAPOK ALAPJA',
    isin: 'HU0000703830',
    ric: null,
    ticker: null,
    marketName: 'OTC',
    marketMICCode: 'XXXX',
    inventoryDate: '2020-11-05',
    position: 4,
    decimalPlace: null,
    costBasis: 0,
    initCcy: 'HUF',
    initValue: 1.8857,
    initPriceValue: 7.5428,
    marketPrice: 4.4936,
    marketPriceCcy: 'HUF',
    marketPriceDate: '2023-06-13',
    exchangeRate: 1,
    profitLossPerPostion: -0.7623,
    profitLossPerPostionHuf: -0.7623,
    grossProfitLoss: -3.0492,
    netProfitLoss: -3.0492,
    grossProfitLossHuf: -3.0492,
    netProfitLossHuf: -3.0492,
  },
  {
    accountNumber: '5079058_2',
    clientId: '5079058',
    accountCategory: 'nyesz',
    instrumentCategory: 'investmentUnit',
    instrumentName: 'ERSTE DUETT ALAPOK ALAPJA',
    isin: 'HU0000703830',
    ric: null,
    ticker: null,
    marketName: 'OTC',
    marketMICCode: 'XXXX',
    inventoryDate: '2020-11-10',
    position: 529,
    decimalPlace: null,
    costBasis: 0,
    initCcy: 'HUF',
    initValue: 1.8862,
    initPriceValue: 997.7998,
    marketPrice: 594.2786,
    marketPriceCcy: 'HUF',
    marketPriceDate: '2023-06-13',
    exchangeRate: 1,
    profitLossPerPostion: -0.7628,
    profitLossPerPostionHuf: -0.7628,
    grossProfitLoss: -403.5212,
    netProfitLoss: -403.5212,
    grossProfitLossHuf: -403.5212,
    netProfitLossHuf: -403.5212,
  },
  {
    accountNumber: '5079058_2',
    clientId: '5079058',
    accountCategory: 'nyesz',
    instrumentCategory: 'investmentUnit',
    instrumentName: 'ERSTE DUETT ALAPOK ALAPJA',
    isin: 'HU0000703830',
    ric: null,
    ticker: null,
    marketName: 'OTC',
    marketMICCode: 'XXXX',
    inventoryDate: '2020-12-08',
    position: 528,
    decimalPlace: null,
    costBasis: 0,
    initCcy: 'HUF',
    initValue: 1.8893,
    initPriceValue: 997.5504,
    marketPrice: 593.1552,
    marketPriceCcy: 'HUF',
    marketPriceDate: '2023-06-13',
    exchangeRate: 1,
    profitLossPerPostion: -0.7659,
    profitLossPerPostionHuf: -0.7659,
    grossProfitLoss: -404.3952,
    netProfitLoss: -404.3952,
    grossProfitLossHuf: -404.3952,
    netProfitLossHuf: -404.3952,
  },
  {
    accountNumber: '5079058_1',
    clientId: '5079058',
    accountCategory: 'normal',
    instrumentCategory: 'investmentUnit',
    instrumentName: 'ERSTE EURO BÁZIS BEFEKTETÉSI ALAP',
    isin: 'HU0000706007',
    ric: null,
    ticker: null,
    marketName: 'OTC',
    marketMICCode: 'XXXX',
    inventoryDate: '2020-10-09',
    position: 1,
    decimalPlace: null,
    costBasis: 0,
    initCcy: 'EUR',
    initValue: 1.1022,
    initPriceValue: 1.1022,
    marketPrice: 1.1234,
    marketPriceCcy: 'EUR',
    marketPriceDate: '2023-06-13',
    exchangeRate: 360.6,
    profitLossPerPostion: 0.0212,
    profitLossPerPostionHuf: 7.64472,
    grossProfitLoss: 0.0212,
    netProfitLoss: 0.0212,
    grossProfitLossHuf: 7.64472,
    netProfitLossHuf: 7.64472,
  },
  {
    accountNumber: '5079058_1',
    clientId: '5079058',
    accountCategory: 'normal',
    instrumentCategory: 'investmentUnit',
    instrumentName: 'ERSTE INGATLAN ALAP',
    isin: 'HU0000703160',
    ric: null,
    ticker: null,
    marketName: 'OTC',
    marketMICCode: 'XXXX',
    inventoryDate: '2019-05-08',
    position: 41132,
    decimalPlace: null,
    costBasis: 0,
    initCcy: 'HUF',
    initValue: 2.4311,
    initPriceValue: 99996.0052,
    marketPrice: 46207.6888,
    marketPriceCcy: 'HUF',
    marketPriceDate: '2023-06-13',
    exchangeRate: 1,
    profitLossPerPostion: -1.3077,
    profitLossPerPostionHuf: -1.3077,
    grossProfitLoss: -53788.3164,
    netProfitLoss: -53788.3164,
    grossProfitLossHuf: -53788.3164,
    netProfitLossHuf: -53788.3164,
  },
  {
    accountNumber: '5079058_1',
    clientId: '5079058',
    accountCategory: 'normal',
    instrumentCategory: 'investmentUnit',
    instrumentName: 'ERSTE MULTI ASSET GROWTH ALAPOK ALAPJA',
    isin: 'HU0000708656',
    ric: null,
    ticker: null,
    marketName: 'OTC',
    marketMICCode: 'XXXX',
    inventoryDate: '2020-12-21',
    position: 711,
    decimalPlace: null,
    costBasis: 0,
    initCcy: 'HUF',
    initValue: 2.1072,
    initPriceValue: 1498.2192,
    marketPrice: 798.7374,
    marketPriceCcy: 'HUF',
    marketPriceDate: '2023-06-13',
    exchangeRate: 1,
    profitLossPerPostion: -0.9838,
    profitLossPerPostionHuf: -0.9838,
    grossProfitLoss: -699.4818,
    netProfitLoss: -699.4818,
    grossProfitLossHuf: -699.4818,
    netProfitLossHuf: -699.4818,
  },
  {
    accountNumber: '5079058_1',
    clientId: '5079058',
    accountCategory: 'normal',
    instrumentCategory: 'investmentUnit',
    instrumentName: 'ERSTE STOCK BIOTEC HUF R01',
    isin: 'AT0000A18XC5',
    ric: null,
    ticker: null,
    marketName: 'OTC',
    marketMICCode: 'XXXX',
    inventoryDate: '2020-12-07',
    position: 0.004,
    decimalPlace: null,
    costBasis: 0,
    initCcy: 'HUF',
    initValue: 188366.91,
    initPriceValue: 753.46764,
    marketPrice: 0.0044936,
    marketPriceCcy: 'HUF',
    marketPriceDate: '2023-06-13',
    exchangeRate: 1,
    profitLossPerPostion: -188365.7866,
    profitLossPerPostionHuf: -188365.7866,
    grossProfitLoss: -753.4631464,
    netProfitLoss: -753.4631464,
    grossProfitLossHuf: -753.4631464,
    netProfitLossHuf: -753.4631464,
  },
  {
    accountNumber: '5079058_2',
    clientId: '5079058',
    accountCategory: 'nyesz',
    instrumentCategory: 'investmentUnit',
    instrumentName: 'ERSTE STOCK BIOTEC HUF R01',
    isin: 'AT0000A18XC5',
    ric: null,
    ticker: null,
    marketName: 'OTC',
    marketMICCode: 'XXXX',
    inventoryDate: '2019-10-10',
    position: 0.033,
    decimalPlace: null,
    costBasis: 0,
    initCcy: 'HUF',
    initValue: 148603.36,
    initPriceValue: 4903.91088,
    marketPrice: 0.0370722,
    marketPriceCcy: 'HUF',
    marketPriceDate: '2023-06-13',
    exchangeRate: 1,
    profitLossPerPostion: -148602.2366,
    profitLossPerPostionHuf: -148602.2366,
    grossProfitLoss: -4903.8738078,
    netProfitLoss: -4903.8738078,
    grossProfitLossHuf: -4903.8738078,
    netProfitLossHuf: -4903.8738078,
  },
  {
    accountNumber: '5079058_2',
    clientId: '5079058',
    accountCategory: 'nyesz',
    instrumentCategory: 'investmentUnit',
    instrumentName: 'ERSTE STOCK BIOTEC HUF R01',
    isin: 'AT0000A18XC5',
    ric: null,
    ticker: null,
    marketName: 'OTC',
    marketMICCode: 'XXXX',
    inventoryDate: '2019-11-11',
    position: 0.014,
    decimalPlace: null,
    costBasis: 0,
    initCcy: 'HUF',
    initValue: 167009.86,
    initPriceValue: 2338.13804,
    marketPrice: 0.0157276,
    marketPriceCcy: 'HUF',
    marketPriceDate: '2023-06-13',
    exchangeRate: 1,
    profitLossPerPostion: -167008.7366,
    profitLossPerPostionHuf: -167008.7366,
    grossProfitLoss: -2338.1223124,
    netProfitLoss: -2338.1223124,
    grossProfitLossHuf: -2338.1223124,
    netProfitLossHuf: -2338.1223124,
  },
  {
    accountNumber: '5079058_2',
    clientId: '5079058',
    accountCategory: 'nyesz',
    instrumentCategory: 'investmentUnit',
    instrumentName: 'ERSTE STOCK BIOTEC HUF R01',
    isin: 'AT0000A18XC5',
    ric: null,
    ticker: null,
    marketName: 'OTC',
    marketMICCode: 'XXXX',
    inventoryDate: '2019-12-10',
    position: 0.013,
    decimalPlace: null,
    costBasis: 0,
    initCcy: 'HUF',
    initValue: 177902.65,
    initPriceValue: 2312.73445,
    marketPrice: 0.0146042,
    marketPriceCcy: 'HUF',
    marketPriceDate: '2023-06-13',
    exchangeRate: 1,
    profitLossPerPostion: -177901.5266,
    profitLossPerPostionHuf: -177901.5266,
    grossProfitLoss: -2312.7198458,
    netProfitLoss: -2312.7198458,
    grossProfitLossHuf: -2312.7198458,
    netProfitLossHuf: -2312.7198458,
  },
  {
    accountNumber: '5079058_2',
    clientId: '5079058',
    accountCategory: 'nyesz',
    instrumentCategory: 'investmentUnit',
    instrumentName: 'ERSTE STOCK BIOTEC HUF R01',
    isin: 'AT0000A18XC5',
    ric: null,
    ticker: null,
    marketName: 'OTC',
    marketMICCode: 'XXXX',
    inventoryDate: '2020-12-21',
    position: 0.002,
    decimalPlace: null,
    costBasis: 0,
    initCcy: 'HUF',
    initValue: 195330.01,
    initPriceValue: 390.66002,
    marketPrice: 0.0022468,
    marketPriceCcy: 'HUF',
    marketPriceDate: '2023-06-13',
    exchangeRate: 1,
    profitLossPerPostion: -195328.8866,
    profitLossPerPostionHuf: -195328.8866,
    grossProfitLoss: -390.6577732,
    netProfitLoss: -390.6577732,
    grossProfitLossHuf: -390.6577732,
    netProfitLossHuf: -390.6577732,
  },
  {
    accountNumber: '5079058_2',
    clientId: '5079058',
    accountCategory: 'nyesz',
    instrumentCategory: 'investmentUnit',
    instrumentName: 'ERSTE STOCK BIOTEC HUF R01',
    isin: 'AT0000A18XC5',
    ric: null,
    ticker: null,
    marketName: 'OTC',
    marketMICCode: 'XXXX',
    inventoryDate: '2021-01-08',
    position: 0.002,
    decimalPlace: null,
    costBasis: 0,
    initCcy: 'HUF',
    initValue: 198369.79,
    initPriceValue: 396.73958,
    marketPrice: 0.0022468,
    marketPriceCcy: 'HUF',
    marketPriceDate: '2023-06-13',
    exchangeRate: 1,
    profitLossPerPostion: -198368.6666,
    profitLossPerPostionHuf: -198368.6666,
    grossProfitLoss: -396.7373332,
    netProfitLoss: -396.7373332,
    grossProfitLossHuf: -396.7373332,
    netProfitLossHuf: -396.7373332,
  },
  {
    accountNumber: '5079058_14',
    clientId: '5079058',
    accountCategory: 'tbsz2016_2011',
    instrumentCategory: 'investmentUnit',
    instrumentName: 'ERSTE STOCK BIOTEC HUF R01',
    isin: 'AT0000A18XC5',
    ric: null,
    ticker: null,
    marketName: 'OTC',
    marketMICCode: 'XXXX',
    inventoryDate: '2020-12-07',
    position: 0.005,
    decimalPlace: null,
    costBasis: 0,
    initCcy: 'HUF',
    initValue: 188366.91,
    initPriceValue: 941.83455,
    marketPrice: 0.005617,
    marketPriceCcy: 'HUF',
    marketPriceDate: '2023-06-13',
    exchangeRate: 1,
    profitLossPerPostion: -188365.7866,
    profitLossPerPostionHuf: -188365.7866,
    grossProfitLoss: -941.828933,
    netProfitLoss: -941.828933,
    grossProfitLossHuf: -941.828933,
    netProfitLossHuf: -941.828933,
  },
  {
    accountNumber: '5079058_21',
    clientId: '5079058',
    accountCategory: 'tbsz2020',
    instrumentCategory: 'investmentUnit',
    instrumentName: 'ERSTE STOCK BIOTEC HUF R01',
    isin: 'AT0000A18XC5',
    ric: null,
    ticker: null,
    marketName: 'OTC',
    marketMICCode: 'XXXX',
    inventoryDate: '2020-12-07',
    position: 0.001,
    decimalPlace: null,
    costBasis: 0,
    initCcy: 'HUF',
    initValue: 188366.91,
    initPriceValue: 188.36691,
    marketPrice: 0.0011234,
    marketPriceCcy: 'HUF',
    marketPriceDate: '2023-06-13',
    exchangeRate: 1,
    profitLossPerPostion: -188365.7866,
    profitLossPerPostionHuf: -188365.7866,
    grossProfitLoss: -188.3657866,
    netProfitLoss: -188.3657866,
    grossProfitLossHuf: -188.3657866,
    netProfitLossHuf: -188.3657866,
  },
  {
    accountNumber: '5079058_1',
    clientId: '5079058',
    accountCategory: 'normal',
    instrumentCategory: 'investmentUnit',
    instrumentName: 'ERSTE STOCK GLOBAL HUF R01',
    isin: 'AT0000627484',
    ric: null,
    ticker: null,
    marketName: 'OTC',
    marketMICCode: 'XXXX',
    inventoryDate: '2020-08-27',
    position: 0.049,
    decimalPlace: null,
    costBasis: 0,
    initCcy: 'HUF',
    initValue: 49663.22,
    initPriceValue: 2433.49778,
    marketPrice: 3017.26908,
    marketPriceCcy: 'HUF',
    marketPriceDate: '2023-06-13',
    exchangeRate: 1,
    profitLossPerPostion: 11913.7,
    profitLossPerPostionHuf: 11913.7,
    grossProfitLoss: 583.7713,
    netProfitLoss: 583.7713,
    grossProfitLossHuf: 583.7713,
    netProfitLossHuf: 583.7713,
  },
  {
    accountNumber: '5079058_1',
    clientId: '5079058',
    accountCategory: 'normal',
    instrumentCategory: 'investmentUnit',
    instrumentName: 'ERSTE STOCK GLOBAL HUF R01',
    isin: 'AT0000627484',
    ric: null,
    ticker: null,
    marketName: 'OTC',
    marketMICCode: 'XXXX',
    inventoryDate: '2020-09-29',
    position: 0.048,
    decimalPlace: null,
    costBasis: 0,
    initCcy: 'HUF',
    initValue: 50943.04,
    initPriceValue: 2445.26592,
    marketPrice: 2955.69216,
    marketPriceCcy: 'HUF',
    marketPriceDate: '2023-06-13',
    exchangeRate: 1,
    profitLossPerPostion: 10633.88,
    profitLossPerPostionHuf: 10633.88,
    grossProfitLoss: 510.42624,
    netProfitLoss: 510.42624,
    grossProfitLossHuf: 510.42624,
    netProfitLossHuf: 510.42624,
  },
  {
    accountNumber: '5079058_1',
    clientId: '5079058',
    accountCategory: 'normal',
    instrumentCategory: 'investmentUnit',
    instrumentName: 'ERSTE STOCK GLOBAL HUF R01',
    isin: 'AT0000627484',
    ric: null,
    ticker: null,
    marketName: 'OTC',
    marketMICCode: 'XXXX',
    inventoryDate: '2020-10-28',
    position: 0.048,
    decimalPlace: null,
    costBasis: 0,
    initCcy: 'HUF',
    initValue: 51016.66,
    initPriceValue: 2448.79968,
    marketPrice: 2955.69216,
    marketPriceCcy: 'HUF',
    marketPriceDate: '2023-06-13',
    exchangeRate: 1,
    profitLossPerPostion: 10560.26,
    profitLossPerPostionHuf: 10560.26,
    grossProfitLoss: 506.89248,
    netProfitLoss: 506.89248,
    grossProfitLossHuf: 506.89248,
    netProfitLossHuf: 506.89248,
  },
  {
    accountNumber: '5079058_1',
    clientId: '5079058',
    accountCategory: 'normal',
    instrumentCategory: 'investmentUnit',
    instrumentName: 'ERSTE STOCK GLOBAL HUF R01',
    isin: 'AT0000627484',
    ric: null,
    ticker: null,
    marketName: 'OTC',
    marketMICCode: 'XXXX',
    inventoryDate: '2020-11-27',
    position: 0.047,
    decimalPlace: null,
    costBasis: 0,
    initCcy: 'HUF',
    initValue: 53062.57,
    initPriceValue: 2493.94079,
    marketPrice: 2894.11524,
    marketPriceCcy: 'HUF',
    marketPriceDate: '2023-06-13',
    exchangeRate: 1,
    profitLossPerPostion: 8514.35,
    profitLossPerPostionHuf: 8514.35,
    grossProfitLoss: 400.17445,
    netProfitLoss: 400.17445,
    grossProfitLossHuf: 400.17445,
    netProfitLossHuf: 400.17445,
  },
  {
    accountNumber: '5079058_1',
    clientId: '5079058',
    accountCategory: 'normal',
    instrumentCategory: 'investmentUnit',
    instrumentName: 'ERSTE STOCK GLOBAL HUF R01',
    isin: 'AT0000627484',
    ric: null,
    ticker: null,
    marketName: 'OTC',
    marketMICCode: 'XXXX',
    inventoryDate: '2020-12-21',
    position: 0.056,
    decimalPlace: null,
    costBasis: 0,
    initCcy: 'HUF',
    initValue: 52447.73,
    initPriceValue: 2937.07288,
    marketPrice: 3448.30752,
    marketPriceCcy: 'HUF',
    marketPriceDate: '2023-06-13',
    exchangeRate: 1,
    profitLossPerPostion: 9129.19,
    profitLossPerPostionHuf: 9129.19,
    grossProfitLoss: 511.23464,
    netProfitLoss: 511.23464,
    grossProfitLossHuf: 511.23464,
    netProfitLossHuf: 511.23464,
  },
  {
    accountNumber: '5079058_1',
    clientId: '5079058',
    accountCategory: 'normal',
    instrumentCategory: 'investmentUnit',
    instrumentName: 'ERSTE STOCK TECHNO HUF R01',
    isin: 'AT0000A09GU1',
    ric: null,
    ticker: null,
    marketName: 'OTC',
    marketMICCode: 'XXXX',
    inventoryDate: '2020-12-21',
    position: 0.008,
    decimalPlace: null,
    costBasis: 0,
    initCcy: 'HUF',
    initValue: 44323.29,
    initPriceValue: 354.58632,
    marketPrice: 380.22704,
    marketPriceCcy: 'HUF',
    marketPriceDate: '2023-06-13',
    exchangeRate: 1,
    profitLossPerPostion: 3205.09,
    profitLossPerPostionHuf: 3205.09,
    grossProfitLoss: 25.64072,
    netProfitLoss: 25.64072,
    grossProfitLossHuf: 25.64072,
    netProfitLossHuf: 25.64072,
  },
  {
    accountNumber: '5079058_2',
    clientId: '5079058',
    accountCategory: 'nyesz',
    instrumentCategory: 'investmentUnit',
    instrumentName: 'ERSTE STOCK TECHNO HUF R01',
    isin: 'AT0000A09GU1',
    ric: null,
    ticker: null,
    marketName: 'OTC',
    marketMICCode: 'XXXX',
    inventoryDate: '2019-11-11',
    position: 0.082,
    decimalPlace: null,
    costBasis: 0,
    initCcy: 'HUF',
    initValue: 30309.32,
    initPriceValue: 2485.36424,
    marketPrice: 3897.32716,
    marketPriceCcy: 'HUF',
    marketPriceDate: '2023-06-13',
    exchangeRate: 1,
    profitLossPerPostion: 17219.06,
    profitLossPerPostionHuf: 17219.06,
    grossProfitLoss: 1411.96292,
    netProfitLoss: 1411.96292,
    grossProfitLossHuf: 1411.96292,
    netProfitLossHuf: 1411.96292,
  },
  {
    accountNumber: '5079058_2',
    clientId: '5079058',
    accountCategory: 'nyesz',
    instrumentCategory: 'investmentUnit',
    instrumentName: 'ERSTE STOCK TECHNO HUF R01',
    isin: 'AT0000A09GU1',
    ric: null,
    ticker: null,
    marketName: 'OTC',
    marketMICCode: 'XXXX',
    inventoryDate: '2019-12-10',
    position: 0.081,
    decimalPlace: null,
    costBasis: 0,
    initCcy: 'HUF',
    initValue: 30533.4,
    initPriceValue: 2473.2054,
    marketPrice: 3849.79878,
    marketPriceCcy: 'HUF',
    marketPriceDate: '2023-06-13',
    exchangeRate: 1,
    profitLossPerPostion: 16994.98,
    profitLossPerPostionHuf: 16994.98,
    grossProfitLoss: 1376.59338,
    netProfitLoss: 1376.59338,
    grossProfitLossHuf: 1376.59338,
    netProfitLossHuf: 1376.59338,
  },
  {
    accountNumber: '5079058_2',
    clientId: '5079058',
    accountCategory: 'nyesz',
    instrumentCategory: 'investmentUnit',
    instrumentName: 'ERSTE STOCK TECHNO HUF R01',
    isin: 'AT0000A09GU1',
    ric: null,
    ticker: null,
    marketName: 'OTC',
    marketMICCode: 'XXXX',
    inventoryDate: '2020-11-03',
    position: 0.1,
    decimalPlace: null,
    costBasis: 0,
    initCcy: 'HUF',
    initValue: 41078.64,
    initPriceValue: 4107.864,
    marketPrice: 4752.838,
    marketPriceCcy: 'HUF',
    marketPriceDate: '2023-06-13',
    exchangeRate: 1,
    profitLossPerPostion: 6449.74,
    profitLossPerPostionHuf: 6449.74,
    grossProfitLoss: 644.974,
    netProfitLoss: 644.974,
    grossProfitLossHuf: 644.974,
    netProfitLossHuf: 644.974,
  },
  {
    accountNumber: '5079058_2',
    clientId: '5079058',
    accountCategory: 'nyesz',
    instrumentCategory: 'investmentUnit',
    instrumentName: 'ERSTE STOCK TECHNO HUF R01',
    isin: 'AT0000A09GU1',
    ric: null,
    ticker: null,
    marketName: 'OTC',
    marketMICCode: 'XXXX',
    inventoryDate: '2020-12-21',
    position: 0.017,
    decimalPlace: null,
    costBasis: 0,
    initCcy: 'HUF',
    initValue: 44323.29,
    initPriceValue: 753.49593,
    marketPrice: 807.98246,
    marketPriceCcy: 'HUF',
    marketPriceDate: '2023-06-13',
    exchangeRate: 1,
    profitLossPerPostion: 3205.09,
    profitLossPerPostionHuf: 3205.09,
    grossProfitLoss: 54.48653,
    netProfitLoss: 54.48653,
    grossProfitLossHuf: 54.48653,
    netProfitLossHuf: 54.48653,
  },
  {
    accountNumber: '5079058_2',
    clientId: '5079058',
    accountCategory: 'nyesz',
    instrumentCategory: 'investmentUnit',
    instrumentName: 'ERSTE STOCK TECHNO HUF R01',
    isin: 'AT0000A09GU1',
    ric: null,
    ticker: null,
    marketName: 'OTC',
    marketMICCode: 'XXXX',
    inventoryDate: '2021-01-08',
    position: 0.016,
    decimalPlace: null,
    costBasis: 0,
    initCcy: 'HUF',
    initValue: 45144.19,
    initPriceValue: 722.30704,
    marketPrice: 760.45408,
    marketPriceCcy: 'HUF',
    marketPriceDate: '2023-06-13',
    exchangeRate: 1,
    profitLossPerPostion: 2384.19,
    profitLossPerPostionHuf: 2384.19,
    grossProfitLoss: 38.14704,
    netProfitLoss: 38.14704,
    grossProfitLossHuf: 38.14704,
    netProfitLossHuf: 38.14704,
  },
  {
    accountNumber: '5079058_1',
    clientId: '5079058',
    accountCategory: 'normal',
    instrumentCategory: 'share',
    instrumentName: 'MAGYAR TELEKOM',
    isin: 'HU0000073507',
    ric: 'MTEL.BU',
    ticker: 'MTELEKOM',
    marketName: 'XETRA - BÉT',
    marketMICCode: 'XBU2',
    inventoryDate: '2018-04-24',
    position: 6,
    decimalPlace: null,
    costBasis: 3,
    initCcy: 'HUF',
    initValue: 457,
    initPriceValue: 2742,
    marketPrice: 2448,
    marketPriceCcy: 'HUF',
    marketPriceDate: '2023-06-12',
    exchangeRate: 1,
    profitLossPerPostion: -49,
    profitLossPerPostionHuf: -49,
    grossProfitLoss: -294,
    netProfitLoss: -297,
    grossProfitLossHuf: -294,
    netProfitLossHuf: -297,
  },
  {
    accountNumber: '5079058_1',
    clientId: '5079058',
    accountCategory: 'normal',
    instrumentCategory: InstrumentInstumentCategoryEnum.InvestmentUnit,
    instrumentName: 'N240826 Magyar Állampapír Plusz',
    isin: 'HU0000403878',
    ric: null,
    ticker: null,
    marketName: 'OTC',
    marketMICCode: 'XXXX',
    inventoryDate: '2019-08-26',
    position: 3400,
    decimalPlace: null,
    costBasis: 0,
    initCcy: 'HUF',
    initValue: 1,
    initPriceValue: 3400,
    marketPrice: 0,
    marketPriceCcy: 'HUF',
    marketPriceDate: '0001-01-01',
    exchangeRate: 1,
    profitLossPerPostion: -1,
    profitLossPerPostionHuf: -1,
    grossProfitLoss: -3400,
    netProfitLoss: -3400,
    grossProfitLossHuf: -3400,
    netProfitLossHuf: -3400,
  },
  {
    accountNumber: '5079058_1',
    clientId: '5079058',
    accountCategory: 'normal',
    instrumentCategory: InstrumentInstumentCategoryEnum.InvestmentUnit,
    instrumentName: 'N250921 Magyar Állampapír Plusz',
    isin: 'HU0000404579',
    ric: null,
    ticker: null,
    marketName: 'OTC',
    marketMICCode: 'XXXX',
    inventoryDate: '2020-09-21',
    position: 10,
    decimalPlace: null,
    costBasis: 0,
    initCcy: 'HUF',
    initValue: 1,
    initPriceValue: 10,
    marketPrice: 0,
    marketPriceCcy: 'HUF',
    marketPriceDate: '0001-01-01',
    exchangeRate: 1,
    profitLossPerPostion: -1,
    profitLossPerPostionHuf: -1,
    grossProfitLoss: -10,
    netProfitLoss: -10,
    grossProfitLossHuf: -10,
    netProfitLossHuf: -10,
  },
  {
    accountNumber: '5079058_1',
    clientId: '5079058',
    accountCategory: 'normal',
    instrumentCategory: InstrumentInstumentCategoryEnum.InvestmentUnit,
    instrumentName: 'N250921 Magyar Állampapír Plusz',
    isin: 'HU0000404579',
    ric: null,
    ticker: null,
    marketName: 'OTC',
    marketMICCode: 'XXXX',
    inventoryDate: '2020-09-21',
    position: 100,
    decimalPlace: null,
    costBasis: 0,
    initCcy: 'HUF',
    initValue: 1,
    initPriceValue: 100,
    marketPrice: 0,
    marketPriceCcy: 'HUF',
    marketPriceDate: '0001-01-01',
    exchangeRate: 1,
    profitLossPerPostion: -1,
    profitLossPerPostionHuf: -1,
    grossProfitLoss: -100,
    netProfitLoss: -100,
    grossProfitLossHuf: -100,
    netProfitLossHuf: -100,
  },
  {
    accountNumber: '5079058_1',
    clientId: '5079058',
    accountCategory: 'normal',
    instrumentCategory: InstrumentInstumentCategoryEnum.InvestmentUnit,
    instrumentName: 'N251012 Magyar Állampapír Plusz',
    isin: 'HU0000404637',
    ric: null,
    ticker: null,
    marketName: 'OTC',
    marketMICCode: 'XXXX',
    inventoryDate: '2020-10-12',
    position: 10,
    decimalPlace: null,
    costBasis: 0,
    initCcy: 'HUF',
    initValue: 1,
    initPriceValue: 10,
    marketPrice: 0,
    marketPriceCcy: 'HUF',
    marketPriceDate: '0001-01-01',
    exchangeRate: 1,
    profitLossPerPostion: -1,
    profitLossPerPostionHuf: -1,
    grossProfitLoss: -10,
    netProfitLoss: -10,
    grossProfitLossHuf: -10,
    netProfitLossHuf: -10,
  },
  {
    accountNumber: '5079058_2',
    clientId: '5079058',
    accountCategory: 'nyesz',
    instrumentCategory: 'investmentUnit',
    instrumentName: 'NN (L) Smart Connectivity - X Cap HUF',
    isin: 'LU0509951199',
    ric: null,
    ticker: null,
    marketName: 'OTC',
    marketMICCode: 'XXXX',
    inventoryDate: '2020-12-21',
    position: 0.001,
    decimalPlace: null,
    costBasis: 0,
    initCcy: 'HUF',
    initValue: 226616.04,
    initPriceValue: 226.61604,
    marketPrice: 0.0011234,
    marketPriceCcy: 'HUF',
    marketPriceDate: '2023-06-13',
    exchangeRate: 1,
    profitLossPerPostion: -226614.9166,
    profitLossPerPostionHuf: -226614.9166,
    grossProfitLoss: -226.6149166,
    netProfitLoss: -226.6149166,
    grossProfitLossHuf: -226.6149166,
    netProfitLossHuf: -226.6149166,
  },
  {
    accountNumber: '5079058_2',
    clientId: '5079058',
    accountCategory: 'nyesz',
    instrumentCategory: 'investmentUnit',
    instrumentName: 'NN (L) Smart Connectivity - X Cap HUF',
    isin: 'LU0509951199',
    ric: null,
    ticker: null,
    marketName: 'OTC',
    marketMICCode: 'XXXX',
    inventoryDate: '2021-01-07',
    position: 0.002,
    decimalPlace: null,
    costBasis: 0,
    initCcy: 'HUF',
    initValue: 224618.2,
    initPriceValue: 449.2364,
    marketPrice: 0.0022468,
    marketPriceCcy: 'HUF',
    marketPriceDate: '2023-06-13',
    exchangeRate: 1,
    profitLossPerPostion: -224617.0766,
    profitLossPerPostionHuf: -224617.0766,
    grossProfitLoss: -449.2341532,
    netProfitLoss: -449.2341532,
    grossProfitLossHuf: -449.2341532,
    netProfitLossHuf: -449.2341532,
  },
  {
    accountNumber: '5444533_1',
    clientId: '5444533',
    accountCategory: 'normal',
    instrumentCategory: 'investmentUnit',
    instrumentName: 'ERSTE MULTI ASSET GROWTH ALAPOK ALAPJA',
    isin: 'HU0000708656',
    ric: null,
    ticker: null,
    marketName: 'OTC',
    marketMICCode: 'XXXX',
    inventoryDate: '2017-06-21',
    position: 6,
    decimalPlace: null,
    costBasis: 0,
    initCcy: 'HUF',
    initValue: 1.6351,
    initPriceValue: 9.8106,
    marketPrice: 6.7404,
    marketPriceCcy: 'HUF',
    marketPriceDate: '2023-06-13',
    exchangeRate: 1,
    profitLossPerPostion: -0.5117,
    profitLossPerPostionHuf: -0.5117,
    grossProfitLoss: -3.0702,
    netProfitLoss: -3.0702,
    grossProfitLossHuf: -3.0702,
    netProfitLossHuf: -3.0702,
  },
  {
    accountNumber: '7000002_1',
    clientId: '7000002',
    accountCategory: 'normal',
    instrumentCategory: 'investmentUnit',
    instrumentName: 'ERSTE BÁZIS BEFEKTETÉSI ALAP',
    isin: 'HU0000702006',
    ric: null,
    ticker: null,
    marketName: 'OTC',
    marketMICCode: 'XXXX',
    inventoryDate: '2016-11-29',
    position: 44,
    decimalPlace: null,
    costBasis: 0,
    initCcy: 'HUF',
    initValue: 2.253206,
    initPriceValue: 99.141064,
    marketPrice: 49.4296,
    marketPriceCcy: 'HUF',
    marketPriceDate: '2023-06-13',
    exchangeRate: 1,
    profitLossPerPostion: -1.129806,
    profitLossPerPostionHuf: -1.129806,
    grossProfitLoss: -49.711464,
    netProfitLoss: -49.711464,
    grossProfitLossHuf: -49.711464,
    netProfitLossHuf: -49.711464,
  },
  {
    accountNumber: '7000002_1',
    clientId: '7000002',
    accountCategory: 'normal',
    instrumentCategory: 'investmentUnit',
    instrumentName: 'ERSTE BÁZIS BEFEKTETÉSI ALAP',
    isin: 'HU0000702006',
    ric: null,
    ticker: null,
    marketName: 'OTC',
    marketMICCode: 'XXXX',
    inventoryDate: '2017-11-22',
    position: 44,
    decimalPlace: null,
    costBasis: 0,
    initCcy: 'HUF',
    initValue: 2.24672,
    initPriceValue: 98.85568,
    marketPrice: 49.4296,
    marketPriceCcy: 'HUF',
    marketPriceDate: '2023-06-13',
    exchangeRate: 1,
    profitLossPerPostion: -1.12332,
    profitLossPerPostionHuf: -1.12332,
    grossProfitLoss: -49.42608,
    netProfitLoss: -49.42608,
    grossProfitLossHuf: -49.42608,
    netProfitLossHuf: -49.42608,
  },
  {
    accountNumber: '7000002_1',
    clientId: '7000002',
    accountCategory: 'normal',
    instrumentCategory: 'investmentUnit',
    instrumentName: 'ERSTE BÁZIS BEFEKTETÉSI ALAP',
    isin: 'HU0000702006',
    ric: null,
    ticker: null,
    marketName: 'OTC',
    marketMICCode: 'XXXX',
    inventoryDate: '2017-12-21',
    position: 1,
    decimalPlace: null,
    costBasis: 0,
    initCcy: 'HUF',
    initValue: 2.245591,
    initPriceValue: 2.245591,
    marketPrice: 1.1234,
    marketPriceCcy: 'HUF',
    marketPriceDate: '2023-06-13',
    exchangeRate: 1,
    profitLossPerPostion: -1.122191,
    profitLossPerPostionHuf: -1.122191,
    grossProfitLoss: -1.122191,
    netProfitLoss: -1.122191,
    grossProfitLossHuf: -1.122191,
    netProfitLossHuf: -1.122191,
  },
  {
    accountNumber: '7000002_1',
    clientId: '7000002',
    accountCategory: 'normal',
    instrumentCategory: 'investmentUnit',
    instrumentName: 'ERSTE BÁZIS BEFEKTETÉSI ALAP',
    isin: 'HU0000702006',
    ric: null,
    ticker: null,
    marketName: 'OTC',
    marketMICCode: 'XXXX',
    inventoryDate: '2010-08-18',
    position: 1,
    decimalPlace: null,
    costBasis: 0,
    initCcy: 'HUF',
    initValue: 1.886763,
    initPriceValue: 1.886763,
    marketPrice: 1.1234,
    marketPriceCcy: 'HUF',
    marketPriceDate: '2023-06-13',
    exchangeRate: 1,
    profitLossPerPostion: -0.763363,
    profitLossPerPostionHuf: -0.763363,
    grossProfitLoss: -0.763363,
    netProfitLoss: -0.763363,
    grossProfitLossHuf: -0.763363,
    netProfitLossHuf: -0.763363,
  },
  {
    accountNumber: '7000002_1',
    clientId: '7000002',
    accountCategory: 'normal',
    instrumentCategory: 'investmentUnit',
    instrumentName: 'ERSTE BÁZIS BEFEKTETÉSI ALAP',
    isin: 'HU0000702006',
    ric: null,
    ticker: null,
    marketName: 'OTC',
    marketMICCode: 'XXXX',
    inventoryDate: '2011-01-10',
    position: 44,
    decimalPlace: null,
    costBasis: 0,
    initCcy: 'HUF',
    initValue: 1.919094,
    initPriceValue: 84.440136,
    marketPrice: 49.4296,
    marketPriceCcy: 'HUF',
    marketPriceDate: '2023-06-13',
    exchangeRate: 1,
    profitLossPerPostion: -0.795694,
    profitLossPerPostionHuf: -0.795694,
    grossProfitLoss: -35.010536,
    netProfitLoss: -35.010536,
    grossProfitLossHuf: -35.010536,
    netProfitLossHuf: -35.010536,
  },
  {
    accountNumber: '7000002_1',
    clientId: '7000002',
    accountCategory: 'normal',
    instrumentCategory: 'investmentUnit',
    instrumentName: 'ERSTE BÁZIS BEFEKTETÉSI ALAP',
    isin: 'HU0000702006',
    ric: null,
    ticker: null,
    marketName: 'OTC',
    marketMICCode: 'XXXX',
    inventoryDate: '2016-10-17',
    position: 44,
    decimalPlace: null,
    costBasis: 0,
    initCcy: 'HUF',
    initValue: 2.25551,
    initPriceValue: 99.24244,
    marketPrice: 49.4296,
    marketPriceCcy: 'HUF',
    marketPriceDate: '2023-06-13',
    exchangeRate: 1,
    profitLossPerPostion: -1.13211,
    profitLossPerPostionHuf: -1.13211,
    grossProfitLoss: -49.81284,
    netProfitLoss: -49.81284,
    grossProfitLossHuf: -49.81284,
    netProfitLossHuf: -49.81284,
  },
  {
    accountNumber: '7000002_1',
    clientId: '7000002',
    accountCategory: 'normal',
    instrumentCategory: 'share',
    instrumentName: 'MAGYAR TELEKOM',
    isin: 'HU0000073507',
    ric: 'MTEL.BU',
    ticker: 'MTELEKOM',
    marketName: 'XETRA - BÉT',
    marketMICCode: 'XBU2',
    inventoryDate: '2013-11-26',
    position: 1,
    decimalPlace: null,
    costBasis: 0,
    initCcy: 'HUF',
    initValue: 274,
    initPriceValue: 274,
    marketPrice: 408,
    marketPriceCcy: 'HUF',
    marketPriceDate: '2023-06-12',
    exchangeRate: 1,
    profitLossPerPostion: 134,
    profitLossPerPostionHuf: 134,
    grossProfitLoss: 134,
    netProfitLoss: 134,
    grossProfitLossHuf: 134,
    netProfitLossHuf: 134,
  },
  {
    accountNumber: '8000003_1',
    clientId: '8000003',
    accountCategory: 'normal',
    instrumentCategory: 'investmentUnit',
    instrumentName: 'AEGON BELFÖLDI KÖTVÉNY ALAP',
    isin: 'HU0000702493',
    ric: null,
    ticker: null,
    marketName: 'OTC',
    marketMICCode: 'XXXX',
    inventoryDate: '2006-11-28',
    position: 41,
    decimalPlace: null,
    costBasis: 0,
    initCcy: 'HUF',
    initValue: 2.433222,
    initPriceValue: 99.762102,
    marketPrice: 46.0594,
    marketPriceCcy: 'HUF',
    marketPriceDate: '2023-06-13',
    exchangeRate: 1,
    profitLossPerPostion: -1.309822,
    profitLossPerPostionHuf: -1.309822,
    grossProfitLoss: -53.702702,
    netProfitLoss: -53.702702,
    grossProfitLossHuf: -53.702702,
    netProfitLossHuf: -53.702702,
  },
  {
    accountNumber: '8000003_1',
    clientId: '8000003',
    accountCategory: 'normal',
    instrumentCategory: 'investmentUnit',
    instrumentName: 'Aegon Feltörekvő Piaci ESG Részvény Befektetési Alap',
    isin: 'HU0000705272',
    ric: null,
    ticker: null,
    marketName: 'OTC',
    marketMICCode: 'XXXX',
    inventoryDate: '2007-09-13',
    position: 181,
    decimalPlace: null,
    costBasis: 1,
    initCcy: 'HUF',
    initValue: 1.097098,
    initPriceValue: 198.574738,
    marketPrice: 203.3354,
    marketPriceCcy: 'HUF',
    marketPriceDate: '2023-06-13',
    exchangeRate: 1,
    profitLossPerPostion: 0.026302,
    profitLossPerPostionHuf: 0.026302,
    grossProfitLoss: 4.760662,
    netProfitLoss: 3.760662,
    grossProfitLossHuf: 4.760662,
    netProfitLossHuf: 3.760662,
  },
  {
    accountNumber: '8000003_1',
    clientId: '8000003',
    accountCategory: 'normal',
    instrumentCategory: 'investmentUnit',
    instrumentName: 'BUDAPEST EURÓ RÖVID KÖTVÉNY ALAP',
    isin: 'HU0000701560',
    ric: null,
    ticker: null,
    marketName: 'OTC',
    marketMICCode: 'XXXX',
    inventoryDate: '2006-08-28',
    position: 7500000,
    decimalPlace: null,
    costBasis: 0,
    initCcy: 'HUF',
    initValue: 1.2565,
    initPriceValue: 94.2375,
    marketPrice: 84.255,
    marketPriceCcy: 'HUF',
    marketPriceDate: '2023-06-13',
    exchangeRate: 1,
    profitLossPerPostion: -0.1331,
    profitLossPerPostionHuf: -0.1331,
    grossProfitLoss: -9.9825,
    netProfitLoss: -9.9825,
    grossProfitLossHuf: -9.9825,
    netProfitLossHuf: -9.9825,
  },
  {
    accountNumber: '8000003_1',
    clientId: '8000003',
    accountCategory: 'normal',
    instrumentCategory: 'investmentUnit',
    instrumentName: 'CIB INDEXKÖVETŐ RÉSZVÉNY ALAP',
    isin: 'HU0000703350',
    ric: null,
    ticker: null,
    marketName: 'OTC',
    marketMICCode: 'XXXX',
    inventoryDate: '2006-09-29',
    position: 57,
    decimalPlace: null,
    costBasis: 0,
    initCcy: 'HUF',
    initValue: 1.7725,
    initPriceValue: 101.0325,
    marketPrice: 64.0338,
    marketPriceCcy: 'HUF',
    marketPriceDate: '2023-06-13',
    exchangeRate: 1,
    profitLossPerPostion: -0.6491,
    profitLossPerPostionHuf: -0.6491,
    grossProfitLoss: -36.9987,
    netProfitLoss: -36.9987,
    grossProfitLossHuf: -36.9987,
    netProfitLossHuf: -36.9987,
  },
  {
    accountNumber: '8000003_1',
    clientId: '8000003',
    accountCategory: 'normal',
    instrumentCategory: 'investmentUnit',
    instrumentName: 'CIB KÖZÉP-EURÓPAI RÉSZVÉNY ALAP',
    isin: 'HU0000702600',
    ric: null,
    ticker: null,
    marketName: 'OTC',
    marketMICCode: 'XXXX',
    inventoryDate: '2006-11-16',
    position: 45,
    decimalPlace: null,
    costBasis: 0,
    initCcy: 'HUF',
    initValue: 2.2382,
    initPriceValue: 100.719,
    marketPrice: 50.553,
    marketPriceCcy: 'HUF',
    marketPriceDate: '2023-06-13',
    exchangeRate: 1,
    profitLossPerPostion: -1.1148,
    profitLossPerPostionHuf: -1.1148,
    grossProfitLoss: -50.166,
    netProfitLoss: -50.166,
    grossProfitLossHuf: -50.166,
    netProfitLossHuf: -50.166,
  },
  {
    accountNumber: '8000003_1',
    clientId: '8000003',
    accountCategory: 'normal',
    instrumentCategory: 'investmentUnit',
    instrumentName: 'ERSTE BÁZIS BEFEKTETÉSI ALAP',
    isin: 'HU0000702006',
    ric: null,
    ticker: null,
    marketName: 'OTC',
    marketMICCode: 'XXXX',
    inventoryDate: '2010-10-01',
    position: 27,
    decimalPlace: null,
    costBasis: 0,
    initCcy: 'HUF',
    initValue: 1.896355,
    initPriceValue: 51.201585,
    marketPrice: 30.3318,
    marketPriceCcy: 'HUF',
    marketPriceDate: '2023-06-13',
    exchangeRate: 1,
    profitLossPerPostion: -0.772955,
    profitLossPerPostionHuf: -0.772955,
    grossProfitLoss: -20.869785,
    netProfitLoss: -20.869785,
    grossProfitLossHuf: -20.869785,
    netProfitLossHuf: -20.869785,
  },
  {
    accountNumber: '8000003_1',
    clientId: '8000003',
    accountCategory: 'normal',
    instrumentCategory: 'investmentUnit',
    instrumentName: 'ERSTE STOCK HUNGARY INDEXKÖVETŐ RÉSZVÉNY ALAP',
    isin: 'HU0000704200',
    ric: null,
    ticker: null,
    marketName: 'OTC',
    marketMICCode: 'XXXX',
    inventoryDate: '2006-11-27',
    position: 100,
    decimalPlace: null,
    costBasis: 0,
    initCcy: 'HUF',
    initValue: 1.0024,
    initPriceValue: 100.24,
    marketPrice: 112.34,
    marketPriceCcy: 'HUF',
    marketPriceDate: '2023-06-13',
    exchangeRate: 1,
    profitLossPerPostion: 0.121,
    profitLossPerPostionHuf: 0.121,
    grossProfitLoss: 12.1,
    netProfitLoss: 12.1,
    grossProfitLossHuf: 12.1,
    netProfitLossHuf: 12.1,
  },
  {
    accountNumber: '5387503_1',
    clientId: '5387503',
    accountCategory: 'normal',
    instrumentCategory: 'share',
    instrumentName: 'BANCO SANTANDER ORD',
    isin: 'ES0113900J37',
    ric: 'SAN.F',
    ticker: 'BSD2',
    marketName: 'XETRA -FRANKFURT',
    marketMICCode: 'XETR',
    inventoryDate: '2018-03-13',
    position: 1,
    decimalPlace: null,
    costBasis: 29.56,
    initCcy: 'EUR',
    initValue: 5.531,
    initPriceValue: 4867.28,
    marketPrice: 2506.68,
    marketPriceCcy: 'EUR',
    marketPriceDate: '2021-01-12',
    exchangeRate: 360.6,
    profitLossPerPostion: -2.6825,
    profitLossPerPostionHuf: -967.3095,
    grossProfitLoss: -2360.6,
    netProfitLoss: -2390.16,
    grossProfitLossHuf: -851232.36,
    netProfitLossHuf: -861891.696,
  },
  {
    accountNumber: '5387503_1',
    clientId: '5387503',
    accountCategory: 'normal',
    instrumentCategory: 'share',
    instrumentName: 'BANCO SANTANDER ORD',
    isin: 'ES0113900J37',
    ric: 'SAN.F',
    ticker: 'BSD2',
    marketName: 'XETRA -FRANKFURT',
    marketMICCode: 'XETR',
    inventoryDate: '2018-03-13',
    position: 20,
    decimalPlace: null,
    costBasis: 66.36,
    initCcy: 'EUR',
    initValue: 5.53,
    initPriceValue: 11060,
    marketPrice: 5697,
    marketPriceCcy: 'EUR',
    marketPriceDate: '2021-01-12',
    exchangeRate: 360.6,
    profitLossPerPostion: -2.6815,
    profitLossPerPostionHuf: -966.9489,
    grossProfitLoss: -5363,
    netProfitLoss: -5429.36,
    grossProfitLossHuf: -1933897.8,
    netProfitLossHuf: -1957827.216,
  },
  {
    accountNumber: '5387503_1',
    clientId: '5387503',
    accountCategory: 'normal',
    instrumentCategory: 'share',
    instrumentName: 'BANCO SANTANDER ORD',
    isin: 'ES0113900J37',
    ric: 'SAN.F',
    ticker: 'BSD2',
    marketName: 'OTC',
    marketMICCode: 'XXXX',
    inventoryDate: '2020-12-15',
    position: 12,
    decimalPlace: null,
    costBasis: 0,
    initCcy: 'EUR',
    initValue: 0,
    initPriceValue: 0,
    marketPrice: 356.0625,
    marketPriceCcy: 'EUR',
    marketPriceDate: '2021-01-12',
    exchangeRate: 360.6,
    profitLossPerPostion: 2.8485,
    profitLossPerPostionHuf: 1027.1691,
    grossProfitLoss: 356.0625,
    netProfitLoss: 356.0625,
    grossProfitLossHuf: 128396.1375,
    netProfitLossHuf: 128396.1375,
  },
  {
    accountNumber: '5387503_1',
    clientId: '5387503',
    accountCategory: 'normal',
    instrumentCategory: 'investmentUnit',
    instrumentName: 'ERSTE MEGTAKARÍTÁSI ALAPOK ALAPJA',
    isin: 'HU0000704507',
    ric: null,
    ticker: null,
    marketName: 'OTC',
    marketMICCode: 'XXXX',
    inventoryDate: '2017-11-17',
    position: 413,
    decimalPlace: null,
    costBasis: 0,
    initCcy: 'HUF',
    initValue: 1.4448,
    initPriceValue: 597998.3856,
    marketPrice: 464971.8898,
    marketPriceCcy: 'HUF',
    marketPriceDate: '2023-06-13',
    exchangeRate: 1,
    profitLossPerPostion: -0.3214,
    profitLossPerPostionHuf: -0.3214,
    grossProfitLoss: -133026.4958,
    netProfitLoss: -133026.4958,
    grossProfitLossHuf: -133026.4958,
    netProfitLossHuf: -133026.4958,
  },
  {
    accountNumber: '5387503_1',
    clientId: '5387503',
    accountCategory: 'normal',
    instrumentCategory: 'share',
    instrumentName: 'ING Group NV Aktie',
    isin: 'NL0011821202',
    ric: 'INN1.DE',
    ticker: 'INGA',
    marketName: 'XETRA -FRANKFURT',
    marketMICCode: 'XETR',
    inventoryDate: '2018-02-23',
    position: 2000,
    decimalPlace: null,
    costBasis: 176.832,
    initCcy: 'EUR',
    initValue: 14.736,
    initPriceValue: 29472,
    marketPrice: 16082,
    marketPriceCcy: 'EUR',
    marketPriceDate: '2021-01-12',
    exchangeRate: 360.6,
    profitLossPerPostion: -6.695,
    profitLossPerPostionHuf: -2414.217,
    grossProfitLoss: -13390,
    netProfitLoss: -13566.832,
    grossProfitLossHuf: -4828434,
    netProfitLossHuf: -4892199.6192,
  },
  {
    accountNumber: '5387503_1',
    clientId: '5387503',
    accountCategory: 'normal',
    instrumentCategory: 'share',
    instrumentName: 'MOL',
    isin: 'HU0000153937',
    ric: 'MOLB.BU',
    ticker: 'MOL',
    marketName: 'XETRA - BÉT',
    marketMICCode: 'XBU2',
    inventoryDate: '2019-03-26',
    position: 5,
    decimalPlace: null,
    costBasis: 83,
    initCcy: 'HUF',
    initValue: 3308,
    initPriceValue: 16540,
    marketPrice: 12650,
    marketPriceCcy: 'HUF',
    marketPriceDate: '2023-06-12',
    exchangeRate: 1,
    profitLossPerPostion: -778,
    profitLossPerPostionHuf: -778,
    grossProfitLoss: -3890,
    netProfitLoss: -3973,
    grossProfitLossHuf: -3890,
    netProfitLossHuf: -3973,
  },
  {
    accountNumber: '5387503_1',
    clientId: '5387503',
    accountCategory: 'normal',
    instrumentCategory: 'share',
    instrumentName: 'MOL',
    isin: 'HU0000153937',
    ric: 'MOLB.BU',
    ticker: 'MOL',
    marketName: 'XETRA - BÉT',
    marketMICCode: 'XBU2',
    inventoryDate: '2019-03-26',
    position: 375,
    decimalPlace: null,
    costBasis: 6202,
    initCcy: 'HUF',
    initValue: 3308,
    initPriceValue: 1240500,
    marketPrice: 948750,
    marketPriceCcy: 'HUF',
    marketPriceDate: '2023-06-12',
    exchangeRate: 1,
    profitLossPerPostion: -778,
    profitLossPerPostionHuf: -778,
    grossProfitLoss: -291750,
    netProfitLoss: -297952,
    grossProfitLossHuf: -291750,
    netProfitLossHuf: -297952,
  },
  {
    accountNumber: '5387503_1',
    clientId: '5387503',
    accountCategory: 'normal',
    instrumentCategory: 'share',
    instrumentName: 'MOL',
    isin: 'HU0000153937',
    ric: 'MOLB.BU',
    ticker: 'MOL',
    marketName: 'XETRA - BÉT',
    marketMICCode: 'XBU2',
    inventoryDate: '2019-03-26',
    position: 567,
    decimalPlace: null,
    costBasis: 9378,
    initCcy: 'HUF',
    initValue: 3308,
    initPriceValue: 1875636,
    marketPrice: 1434510,
    marketPriceCcy: 'HUF',
    marketPriceDate: '2023-06-12',
    exchangeRate: 1,
    profitLossPerPostion: -778,
    profitLossPerPostionHuf: -778,
    grossProfitLoss: -441126,
    netProfitLoss: -450504,
    grossProfitLossHuf: -441126,
    netProfitLossHuf: -450504,
  },
  {
    accountNumber: '5387503_1',
    clientId: '5387503',
    accountCategory: 'normal',
    instrumentCategory: 'share',
    instrumentName: 'MOL',
    isin: 'HU0000153937',
    ric: 'MOLB.BU',
    ticker: 'MOL',
    marketName: 'XETRA - BÉT',
    marketMICCode: 'XBU2',
    inventoryDate: '2019-03-26',
    position: 3353,
    decimalPlace: null,
    costBasis: 55458.9127,
    initCcy: 'HUF',
    initValue: 3308,
    initPriceValue: 11091724,
    marketPrice: 8483090,
    marketPriceCcy: 'HUF',
    marketPriceDate: '2023-06-12',
    exchangeRate: 1,
    profitLossPerPostion: -778,
    profitLossPerPostionHuf: -778,
    grossProfitLoss: -2608634,
    netProfitLoss: -2664092.9127,
    grossProfitLossHuf: -2608634,
    netProfitLossHuf: -2664092.9127,
  },
  {
    accountNumber: '5387503_1',
    clientId: '5387503',
    accountCategory: 'normal',
    instrumentCategory: 'share',
    instrumentName: 'MOL',
    isin: 'HU0000153937',
    ric: 'MOLB.BU',
    ticker: 'MOL',
    marketName: 'XETRA - BÉT',
    marketMICCode: 'XBU2',
    inventoryDate: '2019-12-16',
    position: 783,
    decimalPlace: null,
    costBasis: 11275,
    initCcy: 'HUF',
    initValue: 2880,
    initPriceValue: 2255040,
    marketPrice: 1980990,
    marketPriceCcy: 'HUF',
    marketPriceDate: '2023-06-12',
    exchangeRate: 1,
    profitLossPerPostion: -350,
    profitLossPerPostionHuf: -350,
    grossProfitLoss: -274050,
    netProfitLoss: -285325,
    grossProfitLossHuf: -274050,
    netProfitLossHuf: -285325,
  },
  {
    accountNumber: '5387503_1',
    clientId: '5387503',
    accountCategory: 'normal',
    instrumentCategory: 'share',
    instrumentName: 'MOL',
    isin: 'HU0000153937',
    ric: 'MOLB.BU',
    ticker: 'MOL',
    marketName: 'XETRA - BÉT',
    marketMICCode: 'XBU2',
    inventoryDate: '2019-12-16',
    position: 615,
    decimalPlace: null,
    costBasis: 8850,
    initCcy: 'HUF',
    initValue: 2878,
    initPriceValue: 1769970,
    marketPrice: 1555950,
    marketPriceCcy: 'HUF',
    marketPriceDate: '2023-06-12',
    exchangeRate: 1,
    profitLossPerPostion: -348,
    profitLossPerPostionHuf: -348,
    grossProfitLoss: -214020,
    netProfitLoss: -222870,
    grossProfitLossHuf: -214020,
    netProfitLossHuf: -222870,
  },
  {
    accountNumber: '5387503_1',
    clientId: '5387503',
    accountCategory: 'normal',
    instrumentCategory: 'share',
    instrumentName: 'MOL',
    isin: 'HU0000153937',
    ric: 'MOLB.BU',
    ticker: 'MOL',
    marketName: 'XETRA - BÉT',
    marketMICCode: 'XBU2',
    inventoryDate: '2019-12-16',
    position: 309,
    decimalPlace: null,
    costBasis: 4447,
    initCcy: 'HUF',
    initValue: 2878,
    initPriceValue: 889302,
    marketPrice: 781770,
    marketPriceCcy: 'HUF',
    marketPriceDate: '2023-06-12',
    exchangeRate: 1,
    profitLossPerPostion: -348,
    profitLossPerPostionHuf: -348,
    grossProfitLoss: -107532,
    netProfitLoss: -111979,
    grossProfitLossHuf: -107532,
    netProfitLossHuf: -111979,
  },
  {
    accountNumber: '5387503_1',
    clientId: '5387503',
    accountCategory: 'normal',
    instrumentCategory: 'share',
    instrumentName: 'MOL',
    isin: 'HU0000153937',
    ric: 'MOLB.BU',
    ticker: 'MOL',
    marketName: 'XETRA - BÉT',
    marketMICCode: 'XBU2',
    inventoryDate: '2019-12-16',
    position: 951,
    decimalPlace: null,
    costBasis: 13685,
    initCcy: 'HUF',
    initValue: 2878,
    initPriceValue: 2736978,
    marketPrice: 2406030,
    marketPriceCcy: 'HUF',
    marketPriceDate: '2023-06-12',
    exchangeRate: 1,
    profitLossPerPostion: -348,
    profitLossPerPostionHuf: -348,
    grossProfitLoss: -330948,
    netProfitLoss: -344633,
    grossProfitLossHuf: -330948,
    netProfitLossHuf: -344633,
  },
  {
    accountNumber: '5387503_1',
    clientId: '5387503',
    accountCategory: 'normal',
    instrumentCategory: 'share',
    instrumentName: 'MOL',
    isin: 'HU0000153937',
    ric: 'MOLB.BU',
    ticker: 'MOL',
    marketName: 'XETRA - BÉT',
    marketMICCode: 'XBU2',
    inventoryDate: '2019-12-16',
    position: 901,
    decimalPlace: null,
    costBasis: 12902,
    initCcy: 'HUF',
    initValue: 2864,
    initPriceValue: 2580464,
    marketPrice: 2279530,
    marketPriceCcy: 'HUF',
    marketPriceDate: '2023-06-12',
    exchangeRate: 1,
    profitLossPerPostion: -334,
    profitLossPerPostionHuf: -334,
    grossProfitLoss: -300934,
    netProfitLoss: -313836,
    grossProfitLossHuf: -300934,
    netProfitLossHuf: -313836,
  },
  {
    accountNumber: '5387503_1',
    clientId: '5387503',
    accountCategory: 'normal',
    instrumentCategory: 'share',
    instrumentName: 'MOL',
    isin: 'HU0000153937',
    ric: 'MOLB.BU',
    ticker: 'MOL',
    marketName: 'XETRA - BÉT',
    marketMICCode: 'XBU2',
    inventoryDate: '2019-12-16',
    position: 941,
    decimalPlace: null,
    costBasis: 13475,
    initCcy: 'HUF',
    initValue: 2864,
    initPriceValue: 2695024,
    marketPrice: 2380730,
    marketPriceCcy: 'HUF',
    marketPriceDate: '2023-06-12',
    exchangeRate: 1,
    profitLossPerPostion: -334,
    profitLossPerPostionHuf: -334,
    grossProfitLoss: -314294,
    netProfitLoss: -327769,
    grossProfitLossHuf: -314294,
    netProfitLossHuf: -327769,
  },
  {
    accountNumber: '5387503_1',
    clientId: '5387503',
    accountCategory: 'normal',
    instrumentCategory: 'share',
    instrumentName: 'PORSCHE AUTOMOBIL HOLD PRF',
    isin: 'DE000PAH0038',
    ric: 'PSHG_p.DE',
    ticker: 'PAH3',
    marketName: 'XETRA -FRANKFURT',
    marketMICCode: 'XETR',
    inventoryDate: '2018-05-17',
    position: 265,
    decimalPlace: null,
    costBasis: 108.63,
    initCcy: 'EUR',
    initValue: 68.32,
    initPriceValue: 18104.8,
    marketPrice: 14755.2,
    marketPriceCcy: 'EUR',
    marketPriceDate: '2021-01-12',
    exchangeRate: 360.6,
    profitLossPerPostion: -12.64,
    profitLossPerPostionHuf: -4557.984,
    grossProfitLoss: -3349.6,
    netProfitLoss: -3458.23,
    grossProfitLossHuf: -1207865.76,
    netProfitLossHuf: -1247037.738,
  },
  {
    accountNumber: '5387503_1',
    clientId: '5387503',
    accountCategory: 'normal',
    instrumentCategory: 'future',
    instrumentName: 'RCH2001',
    isin: 'HU0007548048',
    ric: null,
    ticker: 'RCH2001',
    marketName: 'XETRA - BÉT',
    marketMICCode: 'XBU2',
    inventoryDate: '2019-12-16',
    position: 10,
    decimalPlace: null,
    costBasis: 7,
    initCcy: 'HUF',
    initValue: 6281,
    initPriceValue: 62810,
    marketPrice: 65270,
    marketPriceCcy: 'HUF',
    marketPriceDate: '2023-02-21',
    exchangeRate: 1,
    profitLossPerPostion: 123000,
    profitLossPerPostionHuf: 123000,
    grossProfitLoss: 1230000,
    netProfitLoss: 1229993,
    grossProfitLossHuf: 1230000,
    netProfitLossHuf: 1229993,
  },
  {
    accountNumber: '5387503_1',
    clientId: '5387503',
    accountCategory: 'normal',
    instrumentCategory: 'share',
    instrumentName: 'RICHTER',
    isin: 'HU0000123096',
    ric: 'GDRB.BU',
    ticker: 'RICHTER',
    marketName: 'XETRA - BÉT',
    marketMICCode: 'XBU2',
    inventoryDate: '2019-07-29',
    position: 1000,
    decimalPlace: null,
    costBasis: 25675,
    initCcy: 'HUF',
    initValue: 5135,
    initPriceValue: 5135000,
    marketPrice: 6945000,
    marketPriceCcy: 'HUF',
    marketPriceDate: '2023-06-12',
    exchangeRate: 1,
    profitLossPerPostion: 1810,
    profitLossPerPostionHuf: 1810,
    grossProfitLoss: 1810000,
    netProfitLoss: 1784325,
    grossProfitLossHuf: 1810000,
    netProfitLossHuf: 1784325,
  },
];
