import { DefaultLayout, LayoutState } from '../types/layoutState';

export const layoutConfig: LayoutState = {
  selectedLayoutId: DefaultLayout.BET,
  layouts: [
    {
      id: DefaultLayout.BET,
      name: 'BÉT',
    },
    {
      id: DefaultLayout.General,
      name: 'Általános',
    },
    {
      id: DefaultLayout.Playground,
      name: 'Homokozó',
    },
  ],
};
