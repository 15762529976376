import { Button, ButtonProps, CircularProgress } from '@mui/material';
import classes from './Button.module.scss';

export interface NbButtonProp extends ButtonProps {
  id?: string;
  className?: string;
  isLoading?: boolean;
}

export const NbButton = ({ className, variant = `contained`, disabled, isLoading, ...props }: NbButtonProp) => {
  return (
    <Button
      className={`${classes.nbButton} ${className ?? ''}`}
      classes={{
        containedPrimary: classes.nbButtonIsContainedPrimary,
        textPrimary: classes.nbButtonIsTextPrimary,
        outlinedPrimary: classes.nbButtonIsOutlinedPrimary,
        containedSecondary: classes.nbButtonIsContainedSecondary,
        textSecondary: classes.nbButtonIsTextSecondary,
        outlinedSecondary: classes.nbButtonIsOutlinedSecondary,
      }}
      variant={variant}
      disabled={isLoading || disabled}
      {...props}
    >
      {isLoading && (
        <CircularProgress
          size={24}
          sx={{ position: 'absolute' }}
        />
      )}
      {props.children}
    </Button>
  );
};
