/* tslint:disable */
/* eslint-disable */
/**
 * NetBroker Account API
 * NetBroker felhasználó számla adatait kérdezi le a háttérrendszerből.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: zsolt.szabo@ersteinvestment.hu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { AxiosInstance, AxiosPromise, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
import type { Configuration } from './configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import type { RequestArgs } from './base';
import { assertParamExists, createRequestFunction, DUMMY_BASE_URL, setSearchParams, toPathString } from './common';
// @ts-ignore
import { BaseAPI, BASE_PATH } from './base';

/**
 *
 * @export
 * @interface Account
 */
export interface Account {
  /**
   *
   * @type {string}
   * @memberof Account
   */
  accountNumber: string;
  /**
   *
   * @type {string}
   * @memberof Account
   */
  clientId: string;
  /**
   *
   * @type {string}
   * @memberof Account
   */
  accountCategory: AccountAccountCategoryEnum;
  /**
   *
   * @type {string}
   * @memberof Account
   */
  accountProxyType: AccountAccountProxyTypeEnum;
  /**
   *
   * @type {string}
   * @memberof Account
   */
  clientName: string;
  /**
   *
   * @type {string}
   * @memberof Account
   */
  accountAlias?: string;
}

export const AccountAccountCategoryEnum = {
  Normal: 'normal',
  Nyesz: 'nyesz',
  InvestmentCard: 'investmentCard',
  Deposit: 'deposit',
  Locked: 'locked',
  Tbsz2010: 'tbsz2010',
  Tbsz2011: 'tbsz2011',
  Tbsz2012: 'tbsz2012',
  Tbsz2013: 'tbsz2013',
  Tbsz2014: 'tbsz2014',
  Tbsz2015: 'tbsz2015',
  Tbsz20152010: 'tbsz2015_2010',
  Tbsz2016: 'tbsz2016',
  Tbsz20162011: 'tbsz2016_2011',
  Tbsz2017: 'tbsz2017',
  Tbsz20172012: 'tbsz2017_2012',
  Tbsz2018: 'tbsz2018',
  Tbsz20182013: 'tbsz2018_2013',
  Tbsz2019: 'tbsz2019',
  Tbsz20192014: 'tbsz2019_2014',
  Tbsz2020: 'tbsz2020',
  Tbsz20202015: 'tbsz2020_2015',
  Tbsz20202010: 'tbsz2020_2010',
  Tbsz2021: 'tbsz2021',
  Tbsz20212016: 'tbsz2021_2016',
  Tbsz20212011: 'tbsz2021_2011',
  Tbsz2022: 'tbsz2022',
  Tbsz20222017: 'tbsz2022_2017',
  Tbsz20222012: 'tbsz2022_2012',
  Tbsz2023: 'tbsz2023',
} as const;

export type AccountAccountCategoryEnum = (typeof AccountAccountCategoryEnum)[keyof typeof AccountAccountCategoryEnum];
export const AccountAccountProxyTypeEnum = {
  Main: 'main',
  Sub: 'sub',
  Trustee: 'trustee',
} as const;

export type AccountAccountProxyTypeEnum =
  (typeof AccountAccountProxyTypeEnum)[keyof typeof AccountAccountProxyTypeEnum];

/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * A microservice jelen szolgáltatása a kérést küldő felhasználó számla adatait kérdezi le a háttérrendszerből, a  háttérrendszertől kapott válaszüzenet tartalmát mappeli a kimenet struktúrának megfelelően.
     * @summary A szolgáltatás a kérést küldő felhasználó számla adatait kérdezi le a háttérrendszerből.
     * @param {string} id All requests should include a unique identifier element. The identifier is used for auditing and control of double-submission for update operations. The Id must be prefixed by the application name (or module, if necessary) to be unique in the whole environment. The application name and the Id must be separated with colon (:). Synchronous response to a request must use the same id in the HTTP Header.
     * @param {string} correlid Flow (or session) identifier. The flow initiator generates the ID; any other party should copy the received ID into all subsequent messages/calls inside the flow. IDs should be unique for each flow.
     * @param {string} source This identifier is unique for the different modules of the source systemapplications. The name must be defined as an application module name in VEKER naming style. This field is used for logging and in the gatekeeper configuration too.
     * @param {string} srcModule This identifier is unique for the different consumer systemapplications. The name has to be defined in VEKER naming style as an application name. This field is used for logging, gathering information on service usage and in the gatekeeper configuration too.
     * @param {string} userid The identity ID describes the identity of the user that has initiated the service request. This may be, for example, the customer, or an internal user, or may identify a special systemapplication user for calls made by automated systemapplications. If the identification of the original user is not possible (e.g. batch jobs) or the original user is not part of the global user directory (e.g. a customer, who is using a frontend systemapplication), then a technical user name should be used. This is different from the application ID. The Identity ID is of the end user of the systemapplication while the application ID is the calling systemapplication.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAccounts: async (
      id: string,
      correlid: string,
      source: string,
      srcModule: string,
      userid: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists('getAccounts', 'id', id);
      // verify required parameter 'correlid' is not null or undefined
      assertParamExists('getAccounts', 'correlid', correlid);
      // verify required parameter 'source' is not null or undefined
      assertParamExists('getAccounts', 'source', source);
      // verify required parameter 'srcModule' is not null or undefined
      assertParamExists('getAccounts', 'srcModule', srcModule);
      // verify required parameter 'userid' is not null or undefined
      assertParamExists('getAccounts', 'userid', userid);
      const localVarPath = `/accounts`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (id != null) {
        localVarHeaderParameter['id'] = String(id);
      }

      if (correlid != null) {
        localVarHeaderParameter['correlid'] = String(correlid);
      }

      if (source != null) {
        localVarHeaderParameter['source'] = String(source);
      }

      if (srcModule != null) {
        localVarHeaderParameter['src-module'] = String(srcModule);
      }

      if (userid != null) {
        localVarHeaderParameter['userid'] = String(userid);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration);
  return {
    /**
     * A microservice jelen szolgáltatása a kérést küldő felhasználó számla adatait kérdezi le a háttérrendszerből, a  háttérrendszertől kapott válaszüzenet tartalmát mappeli a kimenet struktúrának megfelelően.
     * @summary A szolgáltatás a kérést küldő felhasználó számla adatait kérdezi le a háttérrendszerből.
     * @param {string} id All requests should include a unique identifier element. The identifier is used for auditing and control of double-submission for update operations. The Id must be prefixed by the application name (or module, if necessary) to be unique in the whole environment. The application name and the Id must be separated with colon (:). Synchronous response to a request must use the same id in the HTTP Header.
     * @param {string} correlid Flow (or session) identifier. The flow initiator generates the ID; any other party should copy the received ID into all subsequent messages/calls inside the flow. IDs should be unique for each flow.
     * @param {string} source This identifier is unique for the different modules of the source systemapplications. The name must be defined as an application module name in VEKER naming style. This field is used for logging and in the gatekeeper configuration too.
     * @param {string} srcModule This identifier is unique for the different consumer systemapplications. The name has to be defined in VEKER naming style as an application name. This field is used for logging, gathering information on service usage and in the gatekeeper configuration too.
     * @param {string} userid The identity ID describes the identity of the user that has initiated the service request. This may be, for example, the customer, or an internal user, or may identify a special systemapplication user for calls made by automated systemapplications. If the identification of the original user is not possible (e.g. batch jobs) or the original user is not part of the global user directory (e.g. a customer, who is using a frontend systemapplication), then a technical user name should be used. This is different from the application ID. The Identity ID is of the end user of the systemapplication while the application ID is the calling systemapplication.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAccounts(
      id: string,
      correlid: string,
      source: string,
      srcModule: string,
      userid: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Account>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAccounts(
        id,
        correlid,
        source,
        srcModule,
        userid,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = DefaultApiFp(configuration);
  return {
    /**
     * A microservice jelen szolgáltatása a kérést küldő felhasználó számla adatait kérdezi le a háttérrendszerből, a  háttérrendszertől kapott válaszüzenet tartalmát mappeli a kimenet struktúrának megfelelően.
     * @summary A szolgáltatás a kérést küldő felhasználó számla adatait kérdezi le a háttérrendszerből.
     * @param {DefaultApiGetAccountsRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAccounts(
      requestParameters: DefaultApiGetAccountsRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<Array<Account>> {
      return localVarFp
        .getAccounts(
          requestParameters.id,
          requestParameters.correlid,
          requestParameters.source,
          requestParameters.srcModule,
          requestParameters.userid,
          options,
        )
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for getAccounts operation in DefaultApi.
 * @export
 * @interface DefaultApiGetAccountsRequest
 */
export interface DefaultApiGetAccountsRequest {
  /**
   * All requests should include a unique identifier element. The identifier is used for auditing and control of double-submission for update operations. The Id must be prefixed by the application name (or module, if necessary) to be unique in the whole environment. The application name and the Id must be separated with colon (:). Synchronous response to a request must use the same id in the HTTP Header.
   * @type {string}
   * @memberof DefaultApiGetAccounts
   */
  readonly id: string;

  /**
   * Flow (or session) identifier. The flow initiator generates the ID; any other party should copy the received ID into all subsequent messages/calls inside the flow. IDs should be unique for each flow.
   * @type {string}
   * @memberof DefaultApiGetAccounts
   */
  readonly correlid: string;

  /**
   * This identifier is unique for the different modules of the source systemapplications. The name must be defined as an application module name in VEKER naming style. This field is used for logging and in the gatekeeper configuration too.
   * @type {string}
   * @memberof DefaultApiGetAccounts
   */
  readonly source: string;

  /**
   * This identifier is unique for the different consumer systemapplications. The name has to be defined in VEKER naming style as an application name. This field is used for logging, gathering information on service usage and in the gatekeeper configuration too.
   * @type {string}
   * @memberof DefaultApiGetAccounts
   */
  readonly srcModule: string;

  /**
   * The identity ID describes the identity of the user that has initiated the service request. This may be, for example, the customer, or an internal user, or may identify a special systemapplication user for calls made by automated systemapplications. If the identification of the original user is not possible (e.g. batch jobs) or the original user is not part of the global user directory (e.g. a customer, who is using a frontend systemapplication), then a technical user name should be used. This is different from the application ID. The Identity ID is of the end user of the systemapplication while the application ID is the calling systemapplication.
   * @type {string}
   * @memberof DefaultApiGetAccounts
   */
  readonly userid: string;
}

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
  /**
   * A microservice jelen szolgáltatása a kérést küldő felhasználó számla adatait kérdezi le a háttérrendszerből, a  háttérrendszertől kapott válaszüzenet tartalmát mappeli a kimenet struktúrának megfelelően.
   * @summary A szolgáltatás a kérést küldő felhasználó számla adatait kérdezi le a háttérrendszerből.
   * @param {DefaultApiGetAccountsRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public getAccounts(requestParameters: DefaultApiGetAccountsRequest, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .getAccounts(
        requestParameters.id,
        requestParameters.correlid,
        requestParameters.source,
        requestParameters.srcModule,
        requestParameters.userid,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }
}
