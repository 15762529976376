import { ListedInstrument } from '@eih-netbroker/api-instrument';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Collapse } from '@mui/material';
import { useLocale } from '../../../context/NbLocalizationContext';
import { NbButton } from '../../../shared/components/button/Button';
import { NbDatePicker } from '../../../shared/components/date-picker/DatePicker';
import { NbNumericFormat } from '../../../shared/components/numeric-format/numericFormat';
import { NbSelect } from '../../../shared/components/select/Select';
import { NbTextField } from '../../../shared/components/text-field/TextField';
import textFieldClasses from '../../../shared/components/text-field/TextField.module.scss';
import { NbSwitch } from '../../../shared/components/toggle-button/Switch';
import { useOptions } from '../../../shared/services/useOptions';
import { ExpiryType } from '../../../shared/types/expiryType';
import { InventoryManagmentMethod } from '../../../shared/types/inventoryManagmentMethod';
import { OrderType } from '../../../shared/types/orderType';
import { TradeType } from '../../../shared/types/tradeType';
import { TradingSession } from '../../../shared/types/tradingSession';
import classes from './OrderForm.module.scss';
import { WidgetInstrumentSearchAndDisplay } from './WidgetInstrumentSearchAndDisplay';

export interface OrderFormData {
  id?: string;
  instrument?: ListedInstrument | null;
  orderType?: OrderType;
  tradeType?: TradeType;
  quantity?: number;
  stopLimitPrice?: number;
  stopActivationPrice?: number;
  expiryType?: ExpiryType;
  expiry?: Date;
  inventoryManagmentMethod?: InventoryManagmentMethod;
  tradingSession?: TradingSession;
}

interface OrderFormProps {
  order?: OrderFormData;
  widgetGroupId?: string | number;
  onChange?: (order: OrderFormData) => void;
  onInstrumentChange: (instrument: ListedInstrument | null) => void;
}

export const OrderForm = ({ widgetGroupId, order = {}, onChange, onInstrumentChange }: OrderFormProps) => {
  const { translation } = useLocale();
  const { orderTypeOptions, inventoryManagmentMethodOptions, expiryTypeOptions, getTradingSessionOptions } =
    useOptions();

  const isWidget = !!widgetGroupId;

  const handleChange = <T extends keyof OrderFormData>(key: T, value: OrderFormData[T]) => {
    if (key === 'orderType') {
      onChange?.({
        ...order,
        [key]: value,
        stopLimitPrice: value !== OrderType.StopLimit && value !== OrderType.Limit ? undefined : order.stopLimitPrice,
        stopActivationPrice:
          value !== OrderType.StopLimit && value !== OrderType.StopMarket ? undefined : order.stopActivationPrice,
      });
    } else if (key === 'expiryType') {
      onChange?.({
        ...order,
        [key]: value,
        expiry: undefined,
      });
    } else {
      onChange?.({ ...order, [key]: value });
    }
  };

  const stopLimitPriceDisabled = order.orderType !== OrderType.StopLimit && order.orderType !== OrderType.Limit;
  const stopActivationPriceDisabled =
    order.orderType !== OrderType.StopLimit && order.orderType !== OrderType.StopMarket;

  return (
    <div className={`${classes.orderFormContent} ${isWidget ? classes.orderFormContentIsWidget : ''}`}>
      <div className={classes.orderFormRow}>
        <WidgetInstrumentSearchAndDisplay
          source={`order-form-${isWidget ? widgetGroupId : 'modal'}`}
          instrument={order?.instrument ?? null}
          updateInstrument={onInstrumentChange}
        />
      </div>

      <div className={classes.orderFormRow}>
        {!isWidget && <p className={classes.orderFormRowTitle}>{translation.orderModal.transactionType}</p>}

        <div className={`${classes.orderFormRowButtons} ${isWidget ? classes.orderFormRowButtonsIsWidget : ''}`}>
          <NbButton
            onClick={() => handleChange('tradeType', TradeType.Sell)}
            className={classes.orderFormRowSellButton}
            variant={order.tradeType === TradeType.Sell ? 'contained' : 'outlined'}
            fullWidth
          >
            {translation.orderModal.sell}
          </NbButton>
          <NbButton
            onClick={() => handleChange('tradeType', TradeType.Buy)}
            className={classes.orderFormRowBuyButton}
            variant={order.tradeType === TradeType.Buy ? 'contained' : 'outlined'}
            fullWidth
          >
            {translation.orderModal.buy}
          </NbButton>
        </div>
      </div>

      <div className={classes.orderFormRow}>
        <p className={classes.orderFormRowTitle}>{translation.orderModal.order}</p>
        <div className={classes.orderFormRowSelect}>
          <NbSelect
            size={'small'}
            options={orderTypeOptions}
            value={order?.orderType ?? ''}
            onChange={value => handleChange('orderType', value.target.value as OrderType)}
          />
        </div>
      </div>

      <div className={classes.orderFormRow}>
        <p className={classes.orderFormRowTitle}>{translation.orderModal.quantity}</p>
        <div className={classes.orderFormRowInputs}>
          <NbNumericFormat
            value={order.quantity ?? ''}
            decimalScale={0}
            size="small"
            onValueChange={e => handleChange('quantity', e.floatValue)}
            InputProps={{
              className: classes.orderFormRowQuantity,
              endAdornment: (
                <span
                  className={`${textFieldClasses.nbTextFieldAdornment} ${textFieldClasses.nbTextFieldAdornmentIsEnd}`}
                >
                  db
                </span>
              ),
            }}
            customInput={NbTextField}
          />

          <NbButton
            color="emphasize"
            size="small"
            startIcon={<ContentCopyIcon />}
          >
            {translation.orderModal.max}
          </NbButton>
        </div>
      </div>

      <div className={`${classes.orderFormRow} ${stopLimitPriceDisabled ? classes.orderFormRowIsDisabled : ''}`}>
        <p className={classes.orderFormRowTitle}>{translation.orderModal.limit}</p>
        <div className={classes.orderFormRowInput}>
          <NbNumericFormat
            disabled={stopLimitPriceDisabled}
            value={order.stopLimitPrice ?? ''}
            onValueChange={e => handleChange('stopLimitPrice', e.floatValue)}
            size="small"
            customInput={NbTextField}
          />
        </div>
      </div>

      <div className={`${classes.orderFormRow} ${stopActivationPriceDisabled ? classes.orderFormRowIsDisabled : ''}`}>
        <p className={classes.orderFormRowTitle}>{translation.orderModal.stopPrice}</p>
        <div className={classes.orderFormRowInput}>
          <NbNumericFormat
            disabled={stopActivationPriceDisabled}
            size="small"
            onValueChange={e => handleChange('stopActivationPrice', e.floatValue)}
            value={order.stopActivationPrice ?? ''}
            customInput={NbTextField}
          />
        </div>
      </div>

      <div className={classes.orderFormRow}>
        <p className={classes.orderFormRowTitle}>{translation.orderModal.timeInForce}</p>
        <div className={classes.orderFormRowSelect}>
          <NbSelect
            size="small"
            onChange={e => handleChange('expiryType', e.target.value as ExpiryType)}
            value={order.expiryType ?? ''}
            options={expiryTypeOptions}
          />
        </div>
      </div>

      <Collapse in={order.expiryType === ExpiryType.Gtd}>
        <div className={classes.orderFormRow}>
          <p className={classes.orderFormRowTitle}>{''}</p>
          <div className={classes.orderFormRowSelect}>
            <NbDatePicker
              size="small"
              onChange={date => handleChange('expiry', date ?? undefined)}
              value={order.expiry ?? null}
            />
          </div>
        </div>
      </Collapse>

      <div
        className={`${classes.orderFormRow} ${
          order.tradeType !== TradeType.Sell ? classes.orderFormRowIsDisabled : ''
        }`}
      >
        <p className={classes.orderFormRowTitle}>{translation.orderModal.stockValuation}</p>
        <div className={classes.orderFormRowSelect}>
          <NbSelect
            disabled={order.tradeType !== TradeType.Sell}
            size={'small'}
            onChange={e => handleChange('inventoryManagmentMethod', e.target.value as InventoryManagmentMethod)}
            value={order.inventoryManagmentMethod ?? ''}
            options={inventoryManagmentMethodOptions}
          />
        </div>
      </div>

      <div className={`${classes.orderFormRow} ${!order.instrument ? classes.orderFormRowIsDisabled : ''}`}>
        <p className={classes.orderFormRowTitle}>{translation.orderModal.tradingRestrictions}</p>
        <div className={classes.orderFormRowSelect}>
          <NbSelect
            disabled={!order.instrument}
            onChange={e => handleChange('tradingSession', e.target.value as TradingSession)}
            size={'small'}
            options={getTradingSessionOptions(order.instrument)}
            value={order.tradingSession ?? ''}
          />
        </div>
      </div>

      <div className={classes.orderFormRow}>
        <div className={classes.orderFormSwitch}>
          <NbSwitch
            size={'small'}
            color={'secondary'}
          />
          <span className={classes.orderFormSwitchLabel}>{translation.orderModal.exchange}</span>
        </div>
      </div>
    </div>
  );
};
