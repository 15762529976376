import { GridColDef } from '@mui/x-data-grid-pro';
import React, { useEffect, useRef, useState } from 'react';
import { useDialog } from '../../../context/DialogContext';
import { useTheme } from '../../../context/NbThemeContext';
import { useSSEConnection } from '../../../context/SSEConnectionContext';
import { NbDataGrid } from '../../../shared/components/data-grid/DataGrid';
import { InstrumentSubscriptionDataScope } from '../../../shared/types/estream/eStreamSubscription';
import { OrderType } from '../../../shared/types/orderType';
import { TradeType } from '../../../shared/types/tradeType';
import { OrderWidgetElement, SimpleTradeTicketWidgetElement, WidgetElement } from '../../../shared/types/widgetElement';
import { WidgetType } from '../../../shared/types/widgetType';
import { allColumns, OrderBookInstrument, useOrderBookColumns } from '../services/useOrderBookColumns';
import { useWidget } from '../services/useWidget';
import { useWidgetGroup } from '../services/useWidgetGroup';
import { useWidgetInstrument } from '../services/useWidgetInstrument';
import classes from './OrderBookWidget.module.scss';
import { OrderModal } from './OrderModal';
import { WidgetInstrumentSearchAndDisplay } from './WidgetInstrumentSearchAndDisplay';

export const OrderBookWidget = (props: WidgetElement) => {
  const [rowData, setRowData] = useState<OrderBookInstrument[]>([]);
  const [hoveredRow, setHoveredRow] = useState<string | null>(null);

  const { getOrderBookColumn, getSellAction, getBuyAction } = useOrderBookColumns();
  const { updateWidget, searchWidgetsByTypeInGroup } = useWidget();
  const widgetGroup = useWidgetGroup(props.groupId);
  const { instrument, updateInstrument } = useWidgetInstrument(widgetGroup);
  const { openDialog } = useDialog();
  const previousInstrumentChangesListener = useRef<() => void>();
  const { subscribeToInstrumentChanges, hasEStreamConnection } = useSSEConnection();
  const { rem } = useTheme();

  const source = `order-book-${props.groupId}`;

  const onMouseEnterRow: React.MouseEventHandler<HTMLDivElement> = event => {
    const id = event.currentTarget.getAttribute('data-id');
    setHoveredRow(id);
  };

  const onMouseLeaveRow: React.MouseEventHandler<HTMLDivElement> = _ => {
    setHoveredRow(null);
  };

  const onBuyClick = (id: string) => {
    order(id, TradeType.Buy);
  };

  const onSellClick = (id: string) => {
    order(id, TradeType.Sell);
  };

  const orderQuantity = (id: string, tradeType: TradeType) => {
    order(id, tradeType, true);
  };

  const createOrderPayload = (id: string, tradeType: TradeType, calculateQuantity: boolean = false) => {
    const rowIndex = rowData.findIndex(row => row.id === id);
    const order = {
      instrument,
      tradeType: tradeType,
      orderType: OrderType.Limit,
      stopLimitPrice: tradeType === TradeType.Buy ? rowData[rowIndex].purchasePrice : rowData[rowIndex].salePrice,
      quantity: calculateQuantity
        ? rowData
            .slice(0, rowIndex + 1)
            .reduce(
              (acc, row) => (tradeType === TradeType.Buy ? acc + row.purchaseQuantity : acc + row.saleQuantity),
              0,
            )
        : undefined,
    };

    return order;
  };

  const order = (id: string, tradeType: TradeType, calculateQuantity: boolean = false) => {
    const widgets = searchWidgetsByTypeInGroup(props.groupId, [WidgetType.Order, WidgetType.SimpleTradeTicket]);
    const order = createOrderPayload(id, tradeType, calculateQuantity);

    if (widgets.length > 0) {
      widgets.forEach(orderWidget => {
        if (
          orderWidget &&
          (orderWidget.type === WidgetType.Order || orderWidget.type === WidgetType.SimpleTradeTicket)
        ) {
          const newWidget: OrderWidgetElement | SimpleTradeTicketWidgetElement = {
            ...orderWidget,
            payLoad: order,
          };

          updateWidget(newWidget);
        }
      });
    } else {
      openDialog<any>((close: (params?: any) => void) => (
        <OrderModal
          order={order}
          onCancel={() => close()}
        />
      ));
    }
  };

  const columns: Array<GridColDef<OrderBookInstrument>> = [
    getBuyAction(hoveredRow, onBuyClick),
    ...allColumns.map(column =>
      getOrderBookColumn(column.field, hoveredRow, { onPriceClick: order, onQuantityClick: orderQuantity }),
    ),
    getSellAction(hoveredRow, onSellClick),
  ];

  useEffect(() => {
    if (previousInstrumentChangesListener.current) {
      previousInstrumentChangesListener.current();
      previousInstrumentChangesListener.current = undefined;
    }
    if (instrument) {
      previousInstrumentChangesListener.current = subscribeToInstrumentChanges(
        [
          {
            instrument,
            source,
            dataScope: InstrumentSubscriptionDataScope.Book5,
          },
        ],
        items => {
          const changedItem = items.get(instrument);
          if (changedItem) {
            // TODO order book will be displayed in two separate grids next to each other, this is temporary
            setRowData(
              (changedItem.book?.bids || []).map((_, ind) => ({
                id: `bid-${ind}`,
                purchasePrice: _[0],
                purchaseQuantity: _[1],
                salePrice: (changedItem.book?.asks || [])[ind][0],
                saleQuantity: (changedItem.book?.asks || [])[ind][1],
              })),
            );
          }
        },
      );
    }
    return () => {
      if (previousInstrumentChangesListener.current) {
        previousInstrumentChangesListener.current();
        previousInstrumentChangesListener.current = undefined;
      }
    };
  }, [instrument, source, subscribeToInstrumentChanges]);

  return (
    <div className={classes.nbOrderBookGrid}>
      <div className={classes.nbOrderBookGridHeader}>
        <WidgetInstrumentSearchAndDisplay
          source={source}
          instrument={instrument}
          updateInstrument={updateInstrument}
          showPriceChanges={false}
        />
      </div>
      <div className={classes.nbOrderBookGridContainer}>
        {instrument && hasEStreamConnection && (
          <NbDataGrid
            getRowClassName={params =>
              params.indexRelativeToCurrentPage % 2 === 0 ? classes.nbOrderBookGridRowIsEven : ''
            }
            slotProps={{
              row: {
                onMouseEnter: onMouseEnterRow,
                onMouseLeave: onMouseLeaveRow,
              },
            }}
            rows={rowData}
            columns={columns}
            rowHeight={rem(28)}
          />
        )}
      </div>
    </div>
  );
};
