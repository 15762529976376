import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { useEffect, useMemo, useState } from 'react';
import { useLocale } from '../../../context/NbLocalizationContext';
import { useSSEConnection } from '../../../context/SSEConnectionContext';
import { NbButton } from '../../../shared/components/button/Button';
import { useAccounts } from '../../../shared/services/useAccounts';
import { InstrumentSubscriptionDataScope } from '../../../shared/types/estream/eStreamSubscription';
import { InstrumentStoreItem } from '../../../shared/types/estream/instrumentStore';
import { OrderType } from '../../../shared/types/orderType';
import { TradeType } from '../../../shared/types/tradeType';
import { Order, orderRows, useOrderRows } from '../services/useOrderRows';
import classes from './OrderConfirm.module.scss';
import { OrderFormData } from './OrderForm';

interface OrderConfirmProps {
  orderForm?: OrderFormData;
  expectedCost?: number;
}

export const OrderConfirm = ({ orderForm = {}, expectedCost }: OrderConfirmProps) => {
  const { getOrderRows } = useOrderRows();
  const { translation } = useLocale();
  const { subscribeToInstrumentChanges } = useSSEConnection();

  const { accountName } = useAccounts();
  const [instrumentData, setInstrumentData] = useState<InstrumentStoreItem | null>(null);

  useEffect(() => {
    const instrument = orderForm.instrument;
    if (instrument) {
      const listener = subscribeToInstrumentChanges(
        [
          {
            instrument,
            source: 'OrderConfirm',
            dataScope: InstrumentSubscriptionDataScope.Trades,
          },
        ],
        items => {
          const changedItem = items.get(instrument);
          if (changedItem) {
            setInstrumentData(changedItem);
          }
        },
      );

      return () => {
        if (listener) {
          listener();
        }
      };
    }
  }, [subscribeToInstrumentChanges, orderForm.instrument]);

  const order: Order = useMemo(() => {
    const marketPrice =
      orderForm.tradeType === TradeType.Buy ? instrumentData?.book?.asks[0][0] : instrumentData?.book?.bids[0][0];

    const price =
      orderForm.orderType === OrderType.Market || orderForm.orderType === OrderType.StopMarket
        ? marketPrice
          ? marketPrice * orderForm.quantity!
          : undefined
        : orderForm.stopLimitPrice! * orderForm.quantity!;

    return {
      ...orderForm,
      accountName: accountName ?? '-',
      price: price,
      expectedCost: expectedCost,
      transactionSum: price && expectedCost ? price + expectedCost : undefined,
    };
  }, [orderForm, expectedCost, accountName, instrumentData]);

  const rows = orderRows.map(row => getOrderRows(order, row));

  return (
    <div className={classes.orderConfirm}>
      {rows}

      <NbButton
        className={classes.orderConfirmLink}
        variant="text"
        size="small"
        endIcon={<OpenInNewIcon />}
      >
        {translation.orderConfirm.preliminaryExpenses}
      </NbButton>
    </div>
  );
};
