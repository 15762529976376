import { GridColumnResizeParams, GridRowParams } from '@mui/x-data-grid';
import { DataGridProProps, GridColDef, GRID_DETAIL_PANEL_TOGGLE_COL_DEF } from '@mui/x-data-grid-pro';
import { MouseEvent, useCallback, useMemo, useState } from 'react';
import { useTheme } from '../../../context/NbThemeContext';
import { NbDataGrid } from '../../../shared/components/data-grid/DataGrid';
import { Config } from '../../../shared/config/config';
import { allColumns, allSubColumns, useOpenPositionsColumns } from '../services/useOpenPositionsColumns';
import { OpenPositionItem, OpenPositionItemGroup, OpenPositions } from '../states/openPositionsAtom';
import { CustomDetailPanelToggle } from './CustomDetailPanelToggle';
import classes from './OpenPositionsGrid.module.scss';

export type OpenPositionsGridProps = {
  openPositions: OpenPositions;
  onAddNote: (event: MouseEvent<HTMLButtonElement>, row: OpenPositionItemGroup) => void;
  onChangePosition: (event: MouseEvent<HTMLButtonElement>, row: OpenPositionItemGroup) => void;
};

export const OpenPositionsGrid = ({ openPositions, onAddNote, onChangePosition }: OpenPositionsGridProps) => {
  const { rem } = useTheme();
  const [subContainerPadding, setSubContainerPadding] = useState<number>(
    rem(Config.Layout.grid.toggleColumnWidth + 80),
  );
  const { getOpenPositionsColumn, getOpenPositionFlatActions } = useOpenPositionsColumns();

  const columns: Array<GridColDef<OpenPositionItemGroup>> = useMemo(() => {
    const newColumns = [...allColumns]
      .map(column => getOpenPositionsColumn(column.field) as GridColDef<OpenPositionItemGroup>)
      .concat(getOpenPositionFlatActions({ onAddNote, onChangePosition }));

    newColumns.unshift({
      ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
      minWidth: 30,
      renderCell: params => <CustomDetailPanelToggle {...params} />,
    });

    return newColumns;
  }, [getOpenPositionsColumn, getOpenPositionFlatActions, onAddNote, onChangePosition]);

  const subColumns: Array<GridColDef<OpenPositionItem>> = useMemo(() => {
    return [...allSubColumns].map(column => getOpenPositionsColumn(column.field));
  }, [getOpenPositionsColumn]);

  const getDetailPanelHeight = useCallback(
    (rowParams: GridRowParams<OpenPositionItemGroup>) =>
      rowParams.row.subElements.length * rem(Config.Layout.grid.rowHeight) + 3,
    [rem],
  );

  const getDetailPanelContent = useCallback<NonNullable<DataGridProProps['getDetailPanelContent']>>(
    ({ row }: { row: OpenPositionItemGroup }) => (
      <div
        className={classes.subGrid}
        style={{ paddingLeft: `${rem(subContainerPadding)}px` }}
      >
        <NbDataGrid
          getRowId={row => row.id}
          getRowClassName={params => (params.id ? classes.nbOpenPositionsGridRowIsSub : '')}
          columns={subColumns}
          rows={row.subElements}
          columnHeaderHeight={0}
        />
      </div>
    ),
    [subColumns, subContainerPadding, rem],
  );

  const handleColumnResize = (params: GridColumnResizeParams) => {
    if (params.colDef.field === 'instrument') {
      setSubContainerPadding(params.colDef.width! + rem(Config.Layout.grid.toggleColumnWidth));
    }
  };

  return (
    <>
      <NbDataGrid
        getRowId={row => row.instrument.ric}
        getRowClassName={params =>
          params.indexRelativeToCurrentPage % 2 === 0
            ? classes.nbOpenPositionsGridRowIsEven
            : classes.nbOpenPositionsGridRowIsOdd
        }
        rows={openPositions?.positions || []}
        columns={columns}
        initialState={{ pinnedColumns: { right: ['actions'] } }}
        getDetailPanelHeight={getDetailPanelHeight}
        getDetailPanelContent={getDetailPanelContent}
        onColumnResize={handleColumnResize}
        // onColumnWidthChange={handleColumnResize}
      />
    </>
  );
};
