import AddIcon from '@mui/icons-material/Add';
import BookmarkAddIcon from '@mui/icons-material/BookmarkAdd';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import SsidChartIcon from '@mui/icons-material/SsidChart';
import { MouseEventHandler } from 'react';
import { ListedInstrument } from '../../types/listedInstrument';
import { NbIconButton } from '../icon-button/IconButton';
import { InstrumentCategoryLogo } from '../instrument-category-logo/InstrumentCategoryLogo';
import classes from './InstrumentResultRow.module.scss';

export interface InstrumentResultRowProps {
  instrument: ListedInstrument;
  isWithActions?: boolean;
  isSelected?: boolean;
  onClick?: MouseEventHandler<HTMLDivElement>;
  onOrderClick?: (instrument: ListedInstrument) => void;
  onNotificationClick?: (instrument: ListedInstrument) => void;
}

export const InstrumentResultRow = ({
  instrument,
  isWithActions,
  isSelected,
  onOrderClick,
  onNotificationClick,
  ...props
}: InstrumentResultRowProps) => {
  const handleOnOrderClick: MouseEventHandler<HTMLButtonElement> = e => {
    e.stopPropagation();
    onOrderClick?.(instrument);
  };

  const handlePriceAlert: MouseEventHandler<HTMLButtonElement> = e => {
    e.stopPropagation();
    onNotificationClick?.(instrument);
  };

  return (
    <div
      {...props}
      className={`${classes.item} ${isSelected ? classes.itemIsSelected : ''}`}
      data-is-selected={isSelected}
    >
      <div className={classes.itemContent}>
        <InstrumentCategoryLogo
          className={classes.itemLogo}
          instrumentCategory={instrument.instumentCategory}
        />
        <div className={classes.itemBody}>
          <div className={classes.itemName}>{instrument.instrumentName}</div>
          <div className={classes.itemDetails}>
            <span>
              {instrument.marketShortName}:{instrument.ric}
            </span>
            <span>{instrument.isin}</span>
            <span>{instrument.currency}</span>
          </div>
        </div>
      </div>
      {isWithActions && (
        <div className={classes.itemActions}>
          <NbIconButton
            onClick={handleOnOrderClick}
            icon={<AddIcon />}
          />
          <NbIconButton icon={<BookmarkAddIcon />} />
          <NbIconButton icon={<SsidChartIcon />} />
          <NbIconButton
            icon={<NotificationsNoneIcon />}
            onClick={handlePriceAlert}
          />
        </div>
      )}
    </div>
  );
};
