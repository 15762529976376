import { useMemo } from 'react';
import { useLocale } from '../../../context/NbLocalizationContext';
import { InstrumentCategoryLogo } from '../../../shared/components/instrument-category-logo/InstrumentCategoryLogo';
import { NbTooltip } from '../../../shared/components/tooltip/Tooltip';
import { useFormatter } from '../../../shared/services/useFormatter';
import { ProductDetailsBase } from '../../../shared/state/productDetailsStateAtom';
import { ListedInstrument } from '../../../shared/types/listedInstrument';
import { getPercentOf } from '../../../shared/utils/numberUtils';
import classes from './ProductDetailsHeader.module.scss';
import { ProductDetailsListRow } from './ProductDetailsListRow';

interface ProductDetailsHeaderProps {
  className?: string;
  product: ProductDetailsBase;
  instrument: ListedInstrument;
}

export const ProductDetailsHeader = ({ className, product, instrument }: ProductDetailsHeaderProps) => {
  const { translation } = useLocale();
  const { formatPercentage, formatNumberF2 } = useFormatter();
  const lastPricePosition = useMemo(() => {
    const percent = getPercentOf(product.lastPrice - product.low, product.high - product.low);
    return percent + '%';
  }, [product]);

  const averagePricePosition = useMemo(() => {
    const percent = getPercentOf(product.averagePrice - product.low, product.high - product.low);
    return percent + '%';
  }, [product]);

  return (
    <div className={`${classes.header} ${className}`}>
      <div className={classes.headerTitleRow}>
        <InstrumentCategoryLogo
          className={classes.headerTitleRowCategory}
          instrumentCategory={instrument.instumentCategory}
        />
        <span className={classes.headerTitleRowText}>{instrument.ticker}</span>
      </div>
      <div className={classes.headerContent}>
        <div className={classes.headerContentLastPriceCircle} />
        <span className={classes.headerContentLastPrice}>{formatNumberF2(product.lastPrice)}</span>
        <div className={classes.headerContentColumn}>
          <span
            className={`${classes.headerContentChangeText} ${
              product.dailyChange < 0
                ? classes.headerContentChangeTextIsNegative
                : classes.headerContentChangeTextIsPositive
            }`}
          >
            {formatNumberF2(product.dailyChange)}
          </span>
          <span
            className={`${classes.headerContentChangeText} ${
              product.dailyChange < 0
                ? classes.headerContentChangeTextIsNegative
                : classes.headerContentChangeTextIsPositive
            }`}
          >
            {formatPercentage(product.dailyChangePercent)}
          </span>
        </div>
        <div className={classes.headerContentColumn}>
          <ProductDetailsListRow
            label={translation.productDetails.lowShort}
            value={formatNumberF2(product.low)}
            isNumber
          />
          <ProductDetailsListRow
            label={translation.productDetails.averagePrice}
            value={formatNumberF2(product.averagePrice)}
            info={translation.productDetails.averagePriceInfo}
            isNumber
          />
          <ProductDetailsListRow
            label={translation.productDetails.highShort}
            value={formatNumberF2(product.high)}
            isNumber
          />
        </div>
      </div>
      <div className={classes.bar}>
        <div className={`${classes.barInterval} ${classes.barIntervalIsStart}`}>
          <div className={classes.barIntervalLine} />
          <span>{translation.productDetails.min}</span>
        </div>
        <div className={`${classes.barInterval} ${classes.barIntervalIsEnd}`}>
          <div className={classes.barIntervalLine} />
          <span>{translation.productDetails.max}</span>
        </div>
        <NbTooltip
          title={`${translation.productDetails.lastPrice} ${formatNumberF2(product.lastPrice)}`}
          placement="top"
        >
          <div
            className={`${classes.barCircle} ${classes.barCircleIsRed}`}
            style={{ left: lastPricePosition }}
          >
            <span />
            <span />
          </div>
        </NbTooltip>

        <NbTooltip
          title={`${translation.productDetails.averagePrice} ${formatNumberF2(product.averagePrice)}`}
          placement="top"
        >
          <div
            className={`${classes.barCircle} ${classes.barCircleIsBlue}`}
            style={{ left: averagePricePosition }}
          />
        </NbTooltip>
      </div>
    </div>
  );
};
