import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import { GridActionsCellItem, GridColDef, GridRowParams } from '@mui/x-data-grid';
import { MouseEvent } from 'react';
import { useLocale } from '../../../context/NbLocalizationContext';
import { useTheme } from '../../../context/NbThemeContext';
import { useFormatter } from '../../../shared/services/useFormatter';
import { OpenOrder } from '../../../shared/services/useOpenOrders';
import { TradeType } from '../../../shared/types/tradeType';
import { WithoutId } from '../../../shared/types/withoutId';
import gridClasses from '../components/OpenPositionsWidget.module.scss';

export const openOrdersItemFields: Array<keyof WithoutId<OpenOrder>> = [
  'instrument',
  'tradeType',
  'quantity',
  'price',
  'stopPrice',
  'orderType',
  'expiryType',
  'state',
];

export type OpenOrdersColumn = {
  field: keyof WithoutId<OpenOrder>;
};

export interface OpenOrdersGridActions {
  onEdit: (event: MouseEvent<HTMLButtonElement>, row: any) => void;
  onCancelOrder: (event: MouseEvent<HTMLButtonElement>, row: any) => void;
}

export const allColumns: Array<OpenOrdersColumn> = openOrdersItemFields.map(field => ({ field }));

export const useOpenOrdersColumns = () => {
  const { formatNumberF2, formatInteger } = useFormatter();
  const { translation } = useLocale();
  const { rem } = useTheme();

  const getOpenOrdersColumn = (column: keyof WithoutId<OpenOrder>): GridColDef<OpenOrder> => {
    const baseColDef: GridColDef<OpenOrder> = {
      field: column,
      headerName: translation.openOrders.grid[column],
      sortable: false,
      disableColumnMenu: true,
    };

    switch (column) {
      case 'instrument':
        return {
          ...baseColDef,
          width: rem(75),
          align: 'left',
          renderCell: params => {
            return <span>{params.row.instrument?.ticker}</span>;
          },
        };
      case 'tradeType':
        return {
          ...baseColDef,
          width: rem(55),
          align: 'right',
          renderCell: params => {
            return (
              <span
                className={params.row.tradeType === TradeType.Buy ? gridClasses.cellBuyType : gridClasses.cellSellType}
              >
                {translation.tradeTypeOptions[params.row.tradeType]}
              </span>
            );
          },
        };
      case 'quantity':
        return {
          ...baseColDef,
          width: rem(70),
          align: 'right',
          renderCell: params => {
            return <span>{formatInteger(params.row.quantity)}</span>;
          },
        };
      case 'price':
        return {
          ...baseColDef,
          width: rem(60),
          align: 'right',
          renderCell: params => {
            return <span>{formatNumberF2(params.row.price)}</span>;
          },
        };
      case 'stopPrice':
        return {
          ...baseColDef,
          width: rem(70),
          align: 'right',
          renderCell: params => {
            return <span>{formatNumberF2(params.row.stopPrice ?? '')}</span>;
          },
        };
      case 'orderType':
        return {
          ...baseColDef,
          width: rem(75),
          align: 'right',
          renderCell: params => {
            return <span>{translation.orderTypeOptions[params.row.orderType]}</span>;
          },
        };
      case 'expiryType':
        return {
          ...baseColDef,
          width: rem(100),
          align: 'right',
          renderCell: params => {
            return <span>{translation.expiryOptions[params.row.expiryType]}</span>;
          },
        };
      case 'state':
        return {
          ...baseColDef,
          width: rem(75),
          align: 'right',
          renderCell: params => {
            return <span>{translation.orderStateOptions[params.row.state]}</span>;
          },
        };

      default:
        throw new Error(`Invalid column ${column}`);
    }
  };

  const getOpenOrderFlatActions = (actions: OpenOrdersGridActions): GridColDef<OpenOrder> => ({
    field: 'actions',
    type: 'actions',
    getActions: (params: GridRowParams<OpenOrder>) => [
      <GridActionsCellItem
        className={gridClasses.actionsNotes}
        icon={<EditIcon />}
        label={translation.openOrders.notes}
        onClick={e => actions.onEdit(e, params.row)}
      />,
      <GridActionsCellItem
        className={gridClasses.actionsOrder}
        icon={<CloseIcon />}
        label={translation.openOrders.order}
        onClick={e => actions.onCancelOrder(e, params.row)}
      />,
    ],
  });

  return { getOpenOrdersColumn, getOpenOrderFlatActions };
};
