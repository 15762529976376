import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import WarningOutlinedIcon from '@mui/icons-material/WarningOutlined';
import { Collapse } from '@mui/material';
import { useState } from 'react';
import { useFormatter } from '../../../shared/services/useFormatter';
import { Alert } from '../state/alertGroupsAtom';
import { AlertType } from '../types/alertType';
import classes from './AlertCenterListRow.module.scss';

interface AlertCenterListRowProps {
  alert: Alert;
}

const icons: { [key in AlertType]: JSX.Element } = {
  [AlertType.Info]: (
    <InfoRoundedIcon
      fontSize="inherit"
      className={classes.infoIcon}
    />
  ),
  [AlertType.Warning]: (
    <WarningOutlinedIcon
      fontSize="inherit"
      className={classes.warningIcon}
    />
  ),
  [AlertType.Error]: (
    <ErrorIcon
      fontSize="inherit"
      className={classes.errorIcon}
    />
  ),
  [AlertType.Success]: (
    <CheckCircleIcon
      fontSize="inherit"
      className={classes.successIcon}
    />
  ),
};

export const AlertCenterListRow = ({ alert }: AlertCenterListRowProps) => {
  const { formatDateTime } = useFormatter();
  const [isOpened, setIsOpened] = useState<boolean>(false);
  return (
    <li
      className={`${classes.listRow} ${isOpened ? classes.listRowIsOpened : ''}`}
      onClick={() => {
        setIsOpened(!isOpened && !!alert.message);
      }}
    >
      {icons[alert.type]}
      <div className={classes.listRowContent}>
        <span className={`${classes.listRowTitle} ${alert.read ? classes.listRowTitleIsRead : ''}`}>
          {alert.title}
          {!!alert.message && (
            <MoreHorizIcon
              className={classes.listRowTitleMore}
              fontSize="inherit"
            />
          )}
        </span>
        {!!alert.message && (
          <Collapse in={isOpened}>
            <span className={classes.listRowMessage}>{alert.message}</span>
          </Collapse>
        )}
      </div>

      <span className={classes.listRowDate}>{formatDateTime(alert.date)}</span>
      <div className={`${classes.readIndicator} ${alert.read ? classes.readIndicatorIsRead : ''}`} />
    </li>
  );
};
