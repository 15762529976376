import { atom } from 'recoil';
import { ListedInstrument } from '../../../shared/types/listedInstrument';

export type OpenPositionItem = {
  id: string;
  /** Termék - Wont be visible in sub items */
  instrument: ListedInstrument;
  /** Mennyiség */
  quantity: number;
  /** Bekerülési átlagár */
  averagePrice: number;
  /** Bekerülési érték */
  costBasis: number;
  /** Piaci érték */
  marketValue: number;
  /** Napi P&L */
  dailyPnL: number;
  /** Összesített P&L */
  unrealizedPnL: number;
  /** Összesített százalékos P&L */
  unrealizedPnLPerc: number;
};

export type OpenPositionItemGroup = OpenPositionItem & {
  subElements: Array<OpenPositionItem>;
  log?: Array<string>;
};

export type OpenPositions = {
  positions: Array<OpenPositionItemGroup>;
};

export const openPositionsAtom = atom<OpenPositions>({
  key: 'trading/openPositions',
  default: undefined,
});
