import AddIcon from '@mui/icons-material/Add';
import ErrorIcon from '@mui/icons-material/Error';
import SettingsIcon from '@mui/icons-material/Settings';
import { Popover } from '@mui/material';
import { MouseEvent, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { useDialog } from '../../../context/DialogContext';
import { useLocale } from '../../../context/NbLocalizationContext';
import { useSnackbar } from '../../../context/SnackbarContext';
import { NbButton } from '../../../shared/components/button/Button';
import { DraggableList } from '../../../shared/components/draggable-list/DraggableList';
import { NbIconButton } from '../../../shared/components/icon-button/IconButton';
import { InstrumentSearchModal } from '../../../shared/components/instrument-search-modal/InstrumentSearchModal';
import { NbSelect, NbSelectItemOption } from '../../../shared/components/select/Select';
import { NbTabs, TabItem } from '../../../shared/components/tabs/Tabs';
import { WatchListGroupId, watchListGroupIdsSelector } from '../../../shared/state/watchListStateAtom';
import { ListedInstrument } from '../../../shared/types/listedInstrument';
import { useWatchList } from '../services/useWatchList';
import { WatchListDraggableSettingsItem } from './WatchListDraggableSettingsItem';
import classes from './WatchListHeader.module.scss';

export interface WatchListTabsProps {
  headerMode: 'tabs' | 'select';
  selectedGroup: string;
  selectGroup: (group: WatchListGroupId) => void;
  onDeleteWatchList: () => void;
  onGroupEdit: (watchListGroupId?: WatchListGroupId) => void;
  onAddInstrument: (instrument: ListedInstrument) => void;
}

export const WatchListHeader = ({
  headerMode,
  selectedGroup,
  selectGroup,
  onDeleteWatchList,
  onGroupEdit,
  onAddInstrument,
}: WatchListTabsProps) => {
  const { translation } = useLocale();
  const { deleteWatchListGroup, reorderWatchListGroupsByWatchListGroupIds } = useWatchList();
  const watchListGroups = useRecoilValue(watchListGroupIdsSelector);
  const [addPopover, setAddPopover] = useState<HTMLButtonElement | null>(null);
  const [settingsPopover, setSettingsPopover] = useState<HTMLButtonElement | null>(null);
  const { openDialog } = useDialog();
  const { confirm } = useDialog();
  const { showError } = useSnackbar();

  const handleAddClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAddPopover(event.currentTarget);
  };

  const handleAddClose = () => {
    setAddPopover(null);
  };

  const handleSettingsClick = (event: MouseEvent<HTMLButtonElement>) => {
    setSettingsPopover(event.currentTarget);
  };

  const handleSettingsClose = () => {
    setSettingsPopover(null);
  };

  const saveWatchListGroups = async (reorderedWatchListGroups: Array<WatchListGroupId>) => {
    try {
      await reorderWatchListGroupsByWatchListGroupIds(reorderedWatchListGroups);
    } catch (err) {
      showError(translation.watchList.watchListGroupsReorderFailed);
    }
  };

  const deleteWatchListClicked = async (watchListGroupId: string) => {
    handleSettingsClose();

    await confirm({
      title: translation.general.confirmation,
      content: translation.watchList.confirmDelete,
      confirm: translation.general.confirm,
      dismiss: translation.general.cancel,
      onConfirm: async () => {
        await deleteWatchListGroup(watchListGroupId);
        onDeleteWatchList();
      },
    });
  };

  const addNewWatchList = () => {
    setAddPopover(null);
    onGroupEdit();
  };

  const addInstrument = async () => {
    setAddPopover(null);
    const res = await openDialog<ListedInstrument>((close: (params?: ListedInstrument) => void) => (
      <InstrumentSearchModal
        onSelect={instrument => close(instrument)}
        onCancel={() => close()}
      />
    ));

    if (res) {
      onAddInstrument(res);
    }
  };

  const editWatchListClicked = (watchListGroupId: WatchListGroupId) => {
    onGroupEdit(watchListGroupId);
  };

  const tabItems = watchListGroups.map<TabItem>(lg => ({
    id: lg.id,
    label: lg.name,
  }));

  const watchlistGroupOptions = watchListGroups.map<NbSelectItemOption<string>>(group => ({
    id: group.id,
    name: group.name,
  }));

  return (
    <div className={classes.header}>
      {headerMode === 'tabs' && (
        <div className={classes.headerTabs}>
          <NbTabs
            tabs={tabItems}
            selected={watchListGroups.findIndex(group => group.id === selectedGroup)}
            onTabChange={(index: number) => selectGroup(watchListGroups[index])}
          />
        </div>
      )}
      <div className={classes.headerSelect}>
        {headerMode === 'select' && (
          <NbSelect
            options={watchlistGroupOptions}
            value={selectedGroup}
            onChange={e => selectGroup(watchListGroups.find(_ => _.id === e.target.value)!)}
            size={'small'}
          />
        )}
      </div>

      <div className={classes.headerActions}>
        <NbIconButton
          size={'small'}
          icon={<AddIcon />}
          onClick={handleAddClick}
        />
        <Popover
          open={!!addPopover}
          anchorEl={addPopover}
          onClose={handleAddClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          classes={{ paper: classes.popover }}
        >
          <div className={classes.add}>
            <NbButton
              color={'primary'}
              variant={'text'}
              size={'small'}
              onClick={addNewWatchList}
              disabled={watchListGroups.length >= 10}
              classes={{ root: classes.addNewList }}
              className={`${watchListGroups.length >= 10 ? classes.addNewListIsDisabled : ''}`}
            >
              <span className={`${classes.addIcon} ${watchListGroups.length >= 10 ? classes.addIconIsDisabled : ''}`}>
                <AddIcon />
              </span>
              {translation.watchList.addNewList}
            </NbButton>

            {watchListGroups.length >= 10 && (
              <div className={classes.addWarning}>
                <span className={classes.addWarningIcon}>
                  <ErrorIcon />
                </span>
                <span className={classes.addWarningText}>{translation.watchList.watchListGroupWarning}</span>
              </div>
            )}

            <NbButton
              color={'primary'}
              variant={'text'}
              size={'small'}
              onClick={addInstrument}
            >
              <span className={classes.addIcon}>
                <AddIcon />
              </span>
              {translation.watchList.addNewInstrument}
            </NbButton>
          </div>
        </Popover>

        <NbIconButton
          icon={<SettingsIcon />}
          onClick={handleSettingsClick}
        />
        <Popover
          open={!!settingsPopover}
          anchorEl={settingsPopover}
          onClose={handleSettingsClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          classes={{ paper: classes.popover }}
        >
          <div className={classes.settings}>
            <span className={classes.settingsTitle}>{translation.watchList.editLists}</span>
            <DraggableList
              list={watchListGroups}
              onListReordered={saveWatchListGroups}
              renderItem={(group: WatchListGroupId) => (
                <WatchListDraggableSettingsItem
                  watchListGroupId={group}
                  onEditWatchList={editWatchListClicked}
                  onDeleteWatchList={deleteWatchListClicked}
                />
              )}
            />
          </div>
        </Popover>
      </div>
    </div>
  );
};
