import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { MouseEvent, useState } from 'react';
import { ReactComponent as BearishIcon } from '../../../assets/svg/bearish_zoomout.svg';
import { ReactComponent as BullishIcon } from '../../../assets/svg/bullish_zoomout.svg';
import { ReactComponent as NeutralIcon } from '../../../assets/svg/sentiment_minus.svg';
import classes from './SentimentSelector.module.scss';

export const NbSentimentSelector = () => {
  const [selectedSentiment, setSelectedSentiment] = useState('neutral');

  const handleChange = (event: MouseEvent<HTMLElement>, newSentiment: string) => {
    setSelectedSentiment(newSentiment);
  };

  return (
    <div className={classes.selector}>
      <ToggleButtonGroup
        color="primary"
        value={selectedSentiment}
        exclusive
        onChange={handleChange}
        aria-label="sentiment"
      >
        <ToggleButton value="bearish">
          <BearishIcon />
        </ToggleButton>

        <ToggleButton value="neutral">
          <NeutralIcon />
        </ToggleButton>

        <ToggleButton value="bullish">
          <BullishIcon />
        </ToggleButton>
      </ToggleButtonGroup>
    </div>
  );
};
