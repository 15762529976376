import { useEffect, useRef } from 'react';
import { useRecoilState } from 'recoil';
import { useSSEConnection } from '../../../context/SSEConnectionContext';
import { mockedProductDetails } from '../../../shared/mock/mockedProductDetails';
import { productDetailstStateAtom } from '../../../shared/state/productDetailsStateAtom';
import { InstrumentSubscriptionDataScope } from '../../../shared/types/estream/eStreamSubscription';
import { getInstrumentId, ListedInstrument } from '../../../shared/types/listedInstrument';
import { getChangeWithPercent } from '../../../shared/utils/numberUtils';

export const useProductDetails = (instrument: ListedInstrument | null, source: string) => {
  const [product, setProduct] = useRecoilState(productDetailstStateAtom(instrument ? getInstrumentId(instrument) : ''));
  const previousInstrumentChangesListener = useRef<() => void>();
  const { subscribeToInstrumentChanges } = useSSEConnection();

  useEffect(() => {
    if (previousInstrumentChangesListener.current) {
      previousInstrumentChangesListener.current();
      previousInstrumentChangesListener.current = undefined;
    }
    if (instrument) {
      previousInstrumentChangesListener.current = subscribeToInstrumentChanges(
        [
          {
            instrument,
            source,
            dataScope: InstrumentSubscriptionDataScope.Book5,
          },
        ],
        items => {
          const changedItem = items.get(instrument);
          if (
            changedItem &&
            changedItem.lastPrice !== undefined &&
            changedItem.details &&
            changedItem.details.high &&
            changedItem.details.low
          ) {
            setProduct(p => {
              // TODO mocked product details should be removed, once we have real data
              if (changedItem.lastPrice === undefined || changedItem.closePrice === undefined || !changedItem.details) {
                return p;
              }

              const { change: dailyChange, changePercent: dailyChangePercent } = getChangeWithPercent(
                changedItem.lastPrice,
                changedItem.closePrice,
              );
              // TODO update fields from eStream
              return {
                ...(p || mockedProductDetails),
                lastPrice: changedItem.lastPrice,
                high: changedItem.details.high,
                low: changedItem.details.low,
                averagePrice: changedItem.details.averagePrice,
                dailyChange,
                dailyChangePercent,
              };
            });
          }
        },
      );
    }
    return () => {
      if (previousInstrumentChangesListener.current) {
        previousInstrumentChangesListener.current();
        previousInstrumentChangesListener.current = undefined;
      }
    };
  }, [instrument, setProduct, source, subscribeToInstrumentChanges]);

  return {
    product,
  };
};
