import { OpenPositionItem, OpenPositionItemGroup, OpenPositions } from '../../modules/trading/states/openPositionsAtom';
import { ListedInstrument } from '../types/listedInstrument';
import { getRandomInteger, getRandomPercent } from '../utils/mockUtils';
import { sumOfFieldInList } from '../utils/numberUtils';
import { mockedInstruments } from './mockedInstruments';

const mockOpenPosition = (instrument: ListedInstrument) => {
  const subElements: Array<OpenPositionItem> = [];
  const count = Math.ceil(Math.random() * 5);
  for (let i = 0; i <= count; i++) {
    subElements.push({
      id: `${instrument.ric}-${i}`,
      instrument,
      averagePrice: getRandomInteger(),
      costBasis: getRandomInteger(),
      marketValue: getRandomInteger(),
      unrealizedPnL: getRandomInteger(-500, 500),
      unrealizedPnLPerc: getRandomPercent(80, true),
      dailyPnL: getRandomInteger(-500, 500),
      quantity: getRandomInteger(0, 100),
    });
  }

  return {
    instrument,
    averagePrice: sumOfFieldInList(subElements, 'averagePrice'),
    costBasis: sumOfFieldInList(subElements, 'costBasis'),
    marketValue: sumOfFieldInList(subElements, 'marketValue'),
    unrealizedPnL: sumOfFieldInList(subElements, 'unrealizedPnL'),
    unrealizedPnLPerc: sumOfFieldInList(subElements, 'unrealizedPnLPerc'),
    dailyPnL: sumOfFieldInList(subElements, 'dailyPnL'),
    quantity: sumOfFieldInList(subElements, 'dailyPnL'),
    subElements: subElements,
  } as OpenPositionItemGroup;
};

export const mockedOpenPositions: OpenPositions = {
  positions: [
    mockOpenPosition(mockedInstruments[0]),
    mockOpenPosition(mockedInstruments[1]),
    mockOpenPosition(mockedInstruments[2]),
    mockOpenPosition(mockedInstruments[3]),
  ],
};
