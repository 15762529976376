import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { ReactNode, useCallback, useEffect, useState } from 'react';
import { ReactComponent as ErrorIcon } from '../../../assets/svg/error.svg';
import { ReactComponent as SuccessIcon } from '../../../assets/svg/success.svg';
import { ReactComponent as WarningIcon } from '../../../assets/svg/warning.svg';
import { useLocale } from '../../../context/NbLocalizationContext';
import { useSSEConnection } from '../../../context/SSEConnectionContext';
import { NbIconButton } from '../../../shared/components/icon-button/IconButton';
import { NbLoader } from '../../../shared/components/loader/Loader';
import { SubscriptionWithMarketDataInfo, usePartnerSubscriptions } from '../services/usePartnerSubscriptions';
import classes from './ExchangePopoverContent.module.scss';

export interface ExchangePopoverContentProps {
  onHandleExchangeClose: () => void;
}

export const ExchangePopoverContent = ({ onHandleExchangeClose }: ExchangePopoverContentProps) => {
  const { translation, translateWithParams } = useLocale();
  const { hasEStreamConnection } = useSSEConnection();
  const [isLoading, setIsLoading] = useState(false);
  const [subscriptions, setSubscriptions] = useState<Array<SubscriptionWithMarketDataInfo>>();
  const { getMarketIcon, getPartnerAvailableSubscriptions } = usePartnerSubscriptions();

  const getConnectionStateIcon = (realTime: boolean, _marketCode: string): ReactNode => {
    if (!hasEStreamConnection) {
      return <ErrorIcon />;
    }
    return realTime ? <SuccessIcon /> : <WarningIcon />;
    // TODO - https://atlassian.ebh.erste.hu/Confl/pages/viewpage.action?pageId=146447850 - Estream will tell us if a market is down and we need to display the icon according to it
  };

  const loadMarketStatus = useCallback(async () => {
    try {
      setIsLoading(true);
      setSubscriptions(await getPartnerAvailableSubscriptions());
    } catch (err) {
      setSubscriptions(undefined);
    } finally {
      setIsLoading(false);
    }
  }, [getPartnerAvailableSubscriptions]);

  useEffect(() => {
    loadMarketStatus();
  }, [loadMarketStatus]);

  return (
    <div className={classes.exchange}>
      <div className={classes.exchangeHeader}>
        <h4 className={classes.exchangeHeaderTitle}>
          {translation.header.exchange.title}
          <NbIconButton icon={<KeyboardArrowRightIcon />} />
        </h4>
        <NbIconButton
          icon={<CloseIcon />}
          onClick={onHandleExchangeClose}
        />
      </div>
      {isLoading && (
        <NbLoader
          horizontalCenter
          verticalCenter
        />
      )}
      {!isLoading && (
        <div className={classes.exchangeContent}>
          {!subscriptions && translation.header.exchange.error}

          {subscriptions &&
            subscriptions.map(subscriptionItem => (
              <div
                key={subscriptionItem.subscription.marketDataProvider}
                className={classes.exchangeItem}
              >
                <div className={classes.exchangeTitle}>
                  <div
                    className={classes.exchangeFlag}
                    title={subscriptionItem.marketDataProvider?.name || ''}
                  >
                    {getMarketIcon(subscriptionItem)}
                  </div>
                  <p>
                    {subscriptionItem.subscription.realtime
                      ? translation.header.exchange.realTime
                      : translateWithParams(translation.header.exchange.delay, { min: 'UNKNOWN' })}
                  </p>
                </div>
                {getConnectionStateIcon(
                  subscriptionItem.subscription.realtime,
                  subscriptionItem.subscription.marketDataProvider,
                )}
              </div>
            ))}
        </div>
      )}
    </div>
  );
};
