export const wait = (timeout?: undefined | number): Promise<void> => {
  const t = timeout ?? Math.random() * 1000 + 500;
  return new Promise(resolve => {
    setTimeout(resolve, t);
  });
};

export const resolveLater = async <T>(result: T, timeout?: undefined | number): Promise<T> => {
  await wait(timeout);
  return result;
};

export const getRandomInteger = (from = 500, to = 1000): number => {
  const r1 = from < to ? from : to;
  const r2 = from < to ? to : from;
  return parseInt((Math.random() * (r2 - r1) + r1).toFixed(0), 10);
};

export const getRandomPercent = (max: number, canNegative?: boolean): number => {
  return Math.ceil(Math.random() * max) * (canNegative && Math.random() > 0.5 ? -1 : 1);
};

export const getRandomEnum = <T>(anEnum: T): T[keyof T] => {
  // @ts-ignore
  const enumValues = Object.values(anEnum) as unknown as T[keyof T][];
  const randomIndex = Math.floor(Math.random() * enumValues.length);
  return enumValues[randomIndex];
};
