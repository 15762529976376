import { atom } from 'recoil';
import { mockedAlertGroups } from '../../../shared/mock/mockedAlertGroups';
import { AlertCategory } from '../types/alertCategory';
import { AlertType } from '../types/alertType';

export type Alert = {
  id: number;
  title: string;
  message?: string;
  type: AlertType;
  read: boolean;
  date: Date;
};

export type AlertGroup = {
  category: AlertCategory;
  alerts: Array<Alert>;
};

export const alertGroupsAtom = atom<AlertGroup[]>({
  key: 'alert/groups',
  default: mockedAlertGroups,
});
export { AlertCategory };
