export enum DefaultLayout {
  BET = 0,
  General = 1,
  Playground = 2,
}

export interface Layout {
  id: DefaultLayout | number;
  name: string;
}

export interface LayoutState {
  layouts: Layout[];
  selectedLayoutId?: number;
}
