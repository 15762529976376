import CircleIcon from '@mui/icons-material/Circle';
import LinkIcon from '@mui/icons-material/Link';
import { Menu, MenuItem, Paper } from '@mui/material';
import { FC, MouseEvent, useMemo, useState } from 'react';
import { useLocale } from '../../../context/NbLocalizationContext';
import { NbIconButton } from '../../../shared/components/icon-button/IconButton';
import { NbTabs, TabItem } from '../../../shared/components/tabs/Tabs';
import { WidgetContainer } from '../../../shared/types/widgetContainer';
import { WidgetElement } from '../../../shared/types/widgetElement';
import { WidgetGroupColor, widgetGroupIds } from '../../../shared/types/widgetGroup';
import { WidgetType } from '../../../shared/types/widgetType';
import { useWidget } from '../services/useWidget';
import { useWidgetGroup } from '../services/useWidgetGroup';
import { BetFillListWidget } from './BETFillListWidget';
import { ChartWidget } from './ChartWidget';
import { NetPositionsWidget } from './NetPositionsWidget';
import { OpenOrdersWidget } from './OpenOrdersWidget';
import { OpenPositionsWidget } from './OpenPositionsWidget';
import { OrderBookWidget } from './OrderBookWidget';
import { OrderLogWidget } from './OrderLogWidget';
import { OrderWidget } from './OrderWidget';
import { PriceAlertsWidget } from './PriceAlertsWidget';
import { ProductDetailsWidget } from './ProductDetailsWidget';
import { SimpleTradeTicketWidget } from './SimpleTradeTicketWidget';
import { TradeTicketWidget } from './TradeTicketWidget';
import { WatchListWidget } from './WatchListWidget';
import classes from './WidgetWrapper.module.scss';

const widgetMap: { [keys in WidgetType]: FC<WidgetElement> } = {
  [WidgetType.BETFillList]: params => <BetFillListWidget {...params} />,
  [WidgetType.Chart]: params => <ChartWidget {...params} />,
  [WidgetType.NetPositions]: params => <NetPositionsWidget {...params} />,
  [WidgetType.OpenOrders]: params => <OpenOrdersWidget {...params} />,
  [WidgetType.OrderBook]: params => <OrderBookWidget {...params} />,
  [WidgetType.ProductDetails]: params => <ProductDetailsWidget {...params} />,
  [WidgetType.TradeTicket]: params => <TradeTicketWidget {...params} />,
  [WidgetType.SimpleTradeTicket]: params => <SimpleTradeTicketWidget {...params} />,
  [WidgetType.WatchList]: params => <WatchListWidget {...params} />,
  [WidgetType.OpenPositions]: params => <OpenPositionsWidget {...params} />,
  [WidgetType.Order]: params => <OrderWidget {...params} />,
  [WidgetType.PriceAlerts]: params => <PriceAlertsWidget {...params} />,
  [WidgetType.OrderLog]: params => <OrderLogWidget {...params} />,
};

export type WidgetWrapperProps = {
  widgetContainer: WidgetContainer;
};

const widgetGroupClass: { [key in WidgetGroupColor]: string } = {
  [WidgetGroupColor.Default]: classes.widgetLinkIsDefault,
  [WidgetGroupColor.Group1]: classes.widgetLinkIsGroup1,
  [WidgetGroupColor.Group2]: classes.widgetLinkIsGroup2,
  [WidgetGroupColor.Group3]: classes.widgetLinkIsGroup3,
  [WidgetGroupColor.Group4]: classes.widgetLinkIsGroup4,
  [WidgetGroupColor.Group5]: classes.widgetLinkIsGroup5,
  [WidgetGroupColor.Group6]: classes.widgetLinkIsGroup6,
  [WidgetGroupColor.Group7]: classes.widgetLinkIsGroup7,
  [WidgetGroupColor.Group8]: classes.widgetLinkIsGroup8,
  [WidgetGroupColor.Group9]: classes.widgetLinkIsGroup9,
};

export const WidgetWrapper = ({ widgetContainer }: WidgetWrapperProps) => {
  const { updateWidgetGroup, selectWidgetInContainer } = useWidget();
  const [groupMenuAnchorEl, setGroupMenuAnchorEl] = useState<null | HTMLElement>(null);
  const locale = useLocale();

  const openGroupMenu = (event: MouseEvent<HTMLButtonElement>) => setGroupMenuAnchorEl(event.currentTarget);
  const closeGroupMenu = () => setGroupMenuAnchorEl(null);

  const updateWidgetGroupClicked = (group: WidgetGroupColor) => {
    updateWidgetGroup(widgetContainer.id, widgetGroupIds[group]);
    closeGroupMenu();
  };

  const selectWidget = (widget: WidgetElement) => {
    selectWidgetInContainer(widget.id, widgetContainer.id);
  };

  const widgetGroup = useWidgetGroup(widgetContainer.elements[0].groupId);
  const selectedWidgetElementId = widgetContainer.selectedWidgetId ?? widgetContainer.elements[0].id;
  const selectedWidgetElement = widgetContainer.elements.find(_ => _.id === selectedWidgetElementId)!;
  const isGroupMenuOpened = Boolean(groupMenuAnchorEl);

  const tabs = widgetContainer.elements.map<TabItem>(element => ({
    id: element.id.toString(),
    label: locale.translation.widget.header[element.type],
  }));

  const selectedWidgetIndex = useMemo(() => {
    const index = widgetContainer.elements.findIndex(element => element.id === widgetContainer.selectedWidgetId);
    return index === -1 ? 0 : index;
  }, [widgetContainer]);

  return (
    <Paper
      id={`widget-container-${widgetContainer.id}`}
      className={classes.widgetWrapper}
    >
      <div className={classes.header}>
        <NbTabs
          variant="scrollable"
          style={{ width: '100%' }}
          scrollButtons={false}
          allowScrollButtonsMobile={false}
          tabs={tabs}
          onTabChange={index => selectWidget(widgetContainer.elements[index])}
          selected={selectedWidgetIndex}
        />

        <div className={classes.headerActions}>
          <div className={`${widgetGroupClass[widgetGroup.color]}`}>
            <NbIconButton
              size="small"
              color={'secondary'}
              icon={<LinkIcon />}
              onClick={openGroupMenu}
            />

            <Menu
              id="basic-menu"
              anchorEl={groupMenuAnchorEl}
              open={isGroupMenuOpened}
              onClose={closeGroupMenu}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
                dense: true,
              }}
            >
              {Object.keys(WidgetGroupColor).map(group => (
                <MenuItem
                  key={group}
                  onClick={() => updateWidgetGroupClicked(group as WidgetGroupColor)}
                >
                  <span className={`${widgetGroupClass[group as WidgetGroupColor]} ${classes.groupSelectorItem}`}>
                    <CircleIcon />
                    {group}
                  </span>
                </MenuItem>
              ))}
            </Menu>
          </div>
        </div>
      </div>
      <div className={classes.container}>{widgetMap[selectedWidgetElement.type](selectedWidgetElement)}</div>
    </Paper>
  );
};
