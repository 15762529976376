import { Navigate, Outlet, Route, Routes } from 'react-router-dom';
import { ChartPage } from './modules/chart/pages/ChartPage';
import { Frame } from './modules/core/partials/Frame';
import { HomePage } from './modules/home/pages/HomePage';
import { LoginPage } from './modules/login/pages/LoginPage';
import { NotFoundPage } from './modules/not-found/pages/NotFoundPage';
import { PortfolioPage } from './modules/portfolio/pages/PortfolioPage';
import { DataServiceManagementPage } from './modules/settings/pages/DataServiceManagementPage';
import { NotificationSettingsPage } from './modules/settings/pages/NotificationSettingsPage';
import { UserSettingsPage } from './modules/settings/pages/UserSettingsPage';
import { TradingPage } from './modules/trading/pages/TradingPage';
import { WatchlistPage } from './modules/watchlist/pages/WatchlistPage';
import { FullScreenLoader } from './shared/components/fullscreen-loader/FullScreenLoader';
import { routes } from './shared/data/routes';
import { useAuth } from './shared/services/useAuth';
import { AuthState } from './shared/state/authStateAtom';

const AuthenticatedLayout = ({ authStatus }: { authStatus: AuthState }) => {
  // ideally this path is a no-go, AuthContext is stronger...
  if ([AuthState.SignedOff, AuthState.NotAuthenticated].includes(authStatus)) {
    // Navigate to the login page when the user is not logged in
    return (
      <Navigate
        to={`${routes.login}`}
        replace
      />
    );
  }

  return <Frame children={<Outlet />} />;
};

const AppRouting = () => {
  const { appAuth } = useAuth();
  return (
    <>
      {[AuthState.SignedOff, AuthState.NotAuthenticated].includes(appAuth.authStatus) && (
        <Routes>
          <Route
            path={routes.login}
            element={<LoginPage />}
          />
        </Routes>
      )}
      {[AuthState.Loading, AuthState.Unknown].includes(appAuth.authStatus) && <FullScreenLoader />}
      {appAuth.authStatus === AuthState.Authenticated && (
        <Routes>
          <Route
            path={routes.login}
            element={<LoginPage />}
          />

          <Route element={<AuthenticatedLayout authStatus={appAuth.authStatus} />}>
            <Route
              path="/"
              element={<Navigate to={routes.home} />}
            />

            <Route
              path={routes.home}
              element={<HomePage />}
            />

            <Route
              path={routes.chart}
              element={<ChartPage />}
            />

            <Route
              path={routes.userSettings}
              element={<UserSettingsPage />}
            />

            <Route
              path={routes.dataServiceManagement}
              element={<DataServiceManagementPage />}
            />

            <Route
              path={routes.notificationsSettings}
              element={<NotificationSettingsPage />}
            />

            <Route
              path={routes.watchlist}
              element={<WatchlistPage />}
            />

            <Route
              path={routes.portfolio}
              element={<PortfolioPage />}
            />

            <Route
              path={routes.trading}
              element={<TradingPage />}
            />

            <Route
              path="*"
              element={<NotFoundPage />}
            />
          </Route>
        </Routes>
      )}
    </>
  );
};

export default AppRouting;
