import { atom } from 'recoil';
import { User } from '../types/User';

export enum AuthState {
  Unknown = 'Unknown',
  Loading = 'Loading',
  Authenticated = 'Authenticated',
  NotAuthenticated = 'NotAuthenticated',
  SignedOff = 'SignedOff',
}

type UnAuthenticatedAppState = {
  isAuthenticated: false;
  authStatus: AuthState.NotAuthenticated | AuthState.Loading | AuthState.Unknown;
};

type SignedOffAppState = {
  isAuthenticated: false;
  authStatus: AuthState.SignedOff;
  user: User;
};

export type AuthenticatedAppState = {
  isAuthenticated: true;
  authStatus: AuthState.Authenticated | AuthState.Loading;
  user: User;
};

export type AppAuthState = AuthenticatedAppState | UnAuthenticatedAppState | SignedOffAppState;

export const appAuthAtom = atom<AppAuthState>({
  key: 'core/appAuth',
  default: {
    isAuthenticated: false,
    authStatus: AuthState.Unknown,
  },
});
