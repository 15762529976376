import { OrderStateEnum } from '@eih-netbroker/api-order';
import { useRecoilValue } from 'recoil';
import { ordersAtom } from '../../modules/trading/states/ordersAtom';
import { ExpiryType } from '../types/expiryType';
import { ListedInstrument } from '../types/listedInstrument';
import { OrderType } from '../types/orderType';
import { TradeType } from '../types/tradeType';

export type OpenOrder = {
  id: string;
  instrument?: ListedInstrument;
  tradeType: TradeType;
  quantity: number;
  price: number;
  stopPrice?: number;
  orderType: OrderType;
  expiryType: ExpiryType;
  state: OrderStateEnum;
};

export type OpenOrders = {
  openOrders: Array<OpenOrder>;
  isLoading: boolean;
};

export const useOpenOrders = () => {
  const orders = useRecoilValue(ordersAtom);

  const openOrders: OpenOrders = {
    openOrders: orders.orders.map(order => ({
      id: order.id,
      instrument: order.instrument,
      tradeType: order.direction,
      quantity: order.quantity,
      price: order.price,
      stopPrice: order.stopActivationPrice,
      orderType: order.orderType,
      expiryType: order.expiryType,
      state: order.state,
    })),
    isLoading: orders.isLoading,
  };

  return {
    openOrders,
  };
};
