import CloseIcon from '@mui/icons-material/Close';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Paper, PaperProps } from '@mui/material';
import { MouseEvent, useState } from 'react';
import { ConfirmDialogProps } from '../../../context/DialogContext';
import { NbButton } from '../button/Button';
import classes from './ConfirmDialog.module.scss';

export type ConfirmDialogInnerProps = {
  handleClose: (e: unknown, reason: string) => void;
  dialogConfig: ConfirmDialogProps;
};

function PaperComponent(props: PaperProps) {
  return <Paper {...props} />;
}

export const ConfirmDialog = ({ handleClose, dialogConfig }: ConfirmDialogInnerProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const confirmClicked = async (e: MouseEvent) => {
    if (dialogConfig.onConfirm) {
      try {
        setIsLoading(true);
        await dialogConfig.onConfirm();
        handleClose(e, 'confirm');
      } finally {
        setIsLoading(false);
      }
    } else {
      handleClose(e, 'confirm');
    }
  };

  return (
    <>
      <Dialog
        classes={{ paper: classes.modal }}
        onClose={handleClose}
        open={!!dialogConfig}
        PaperComponent={PaperComponent}
      >
        <DialogTitle className={classes.title}>
          <span>{dialogConfig.title}</span>
          <CloseIcon onClick={e => handleClose(e, 'dismiss')} />
        </DialogTitle>
        <DialogContent className={classes.content}>
          <DialogContentText className={classes.text}>{dialogConfig.content}</DialogContentText>
        </DialogContent>
        <DialogActions className={classes.buttons}>
          <NbButton
            color={'secondary'}
            disabled={isLoading}
            onClick={e => handleClose(e, 'dismiss')}
          >
            {dialogConfig.dismiss}
          </NbButton>
          <NbButton
            variant={'contained'}
            onClick={e => confirmClicked(e)}
            isLoading={isLoading}
            autoFocus
            className={dialogConfig.isDanger ? classes.isDanger : ''}
          >
            {dialogConfig.confirm}
          </NbButton>
        </DialogActions>
      </Dialog>
    </>
  );
};
