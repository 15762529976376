import classes from './OrderConfirmRow.module.scss';

export interface OrderConfirmRowProps {
  label: string;
  value?: string;
  isDark?: boolean;
  isDivider?: boolean;
}

export const OrderConfirmRow = ({ label, value, isDark, isDivider }: OrderConfirmRowProps) => {
  return (
    <div
      className={`${classes.orderConfirmRow} ${isDark ? classes.orderConfirmRowIsDark : ''} ${
        isDivider ? classes.orderConfirmRowIsDivider : ''
      }`}
    >
      <span>{label}</span>
      <span>{value}</span>
    </div>
  );
};
