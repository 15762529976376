export const routes = {
  login: `/login`,
  home: `/home`,
  chart: `/chart`,
  watchlist: `/watchlist`,
  trading: `/trading`,
  portfolio: `/portfolio`,
  future: `/future`,
  account: `/account`,
  userSettings: `/settings/user`,
  notificationsSettings: `/settings/notifications`,
  dataServiceManagement: `/settings/data-service-management`,
  notFound: `/not-found`,
};
