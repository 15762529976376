import { useLocale } from '../../../context/NbLocalizationContext';
import { PortfolioComposition } from '../partials/PortfolioComposition';
import classes from './PortfolioPage.module.scss';

export const PortfolioPage = () => {
  const { translation } = useLocale();

  return (
    <div className={classes.portfolio}>
      <h1 className={classes.portfolioHeader}>{translation.portfolio.portfolio}</h1>
      <div className={classes.portfolioContent}>
        <PortfolioComposition />
      </div>
    </div>
  );
};
