import classes from './FullScreenLoader.module.scss';

export const FullScreenLoader = () => {
  return (
    <div className={classes.fullscreenLoader}>
      <span></span>
      <span></span>
    </div>
  );
};
