import { useEffect, useState } from 'react';
import { SSEState } from '../../context/SSEConnectionContext';
import { AppDebug } from '../components/app-debug/AppDebug';
import { Keys } from '../config/keys';
import { SubscribeOptions } from '../types/estream/eStreamSubscription';
import { InstrumentStore } from '../types/estream/instrumentStore';
import { ObjectSet } from '../utils/objectSet';

export const useAppDebugger = ({
  sseState,
  instrumentStore,
  subscriptionQueue,
}: {
  sseState: SSEState;
  instrumentStore: InstrumentStore;
  subscriptionQueue: ObjectSet<SubscribeOptions>;
}) => {
  const [debuggerVisible, setDebuggerVisible] = useState<boolean>(
    window.localStorage.getItem(Keys.DebuggerVisible) === 'true',
  );
  const [debuggerTimer, setDebuggerTimer] = useState(0);

  const initAppDebugger = () => {
    window.addEventListener('keydown', e => {
      if (e.code === 'KeyD' && e.ctrlKey) {
        setDebuggerVisible(true);
        window.localStorage.setItem(Keys.DebuggerVisible, 'true');
      }
    });
  };
  /**
   * Rerender the App debugger if it is visible
   *
   * (This is needed because we are passing parameters stored in a ref to the debugger, and we need to force a rerender
   */
  useEffect(() => {
    if (!debuggerVisible) {
      setDebuggerTimer(0);
      return;
    }

    setTimeout(() => {
      setDebuggerTimer(Date.now());
    }, 500);
  }, [debuggerVisible]);

  /**
   * Enable the debugger if the user presses the CMD+CTRL+"d" key
   */
  useEffect(() => {
    initAppDebugger();
  }, []);

  return (
    <>
      {debuggerVisible && debuggerTimer > 0 && (
        <AppDebug
          visible={true}
          hideDebugger={() => {
            setDebuggerVisible(false);
            window.localStorage.removeItem(Keys.DebuggerVisible);
          }}
          sseState={sseState}
          instrumentStore={instrumentStore}
          subscriptionQueue={subscriptionQueue}
        />
      )}
    </>
  );
};
