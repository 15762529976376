/* tslint:disable */
/* eslint-disable */
/**
 * NB_OrderManagementService API
 * This is a provided API for order management.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: zsolt.szabo@ersteinvestment.hu
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { AxiosInstance, AxiosPromise, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
import type { Configuration } from './configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import type { RequestArgs } from './base';
import {
  assertParamExists,
  createRequestFunction,
  DUMMY_BASE_URL,
  serializeDataIfNeeded,
  setSearchParams,
  toPathString,
} from './common';
// @ts-ignore
import { BaseAPI, BASE_PATH } from './base';

/**
 *
 * @export
 * @interface CostCalculationResponse
 */
export interface CostCalculationResponse {
  /**
   * Currency code for the calculated cost
   * @type {string}
   * @memberof CostCalculationResponse
   */
  currency?: string;
  /**
   * Expected cost of the given order
   * @type {number}
   * @memberof CostCalculationResponse
   */
  expectedCost?: number;
}
/**
 *
 * @export
 * @interface GetOrders400Response
 */
export interface GetOrders400Response {
  /**
   * The error, or validation messages
   * @type {Array<object>}
   * @memberof GetOrders400Response
   */
  errors?: Array<object>;
}
/**
 *
 * @export
 * @interface Order
 */
export interface Order {
  /**
   * Submitted id of the order (only in case of submit), or the temporary idenfier of the order (only in case of pre-submit)
   * @type {string}
   * @memberof Order
   */
  id: string;
  /**
   * Selected account identifier (AccountId-SzamlaAz)
   * @type {string}
   * @memberof Order
   */
  accountId: string;
  /**
   * Price of the order. It is necessary in case of limit order, and must be empty in case of market order.
   * @type {number}
   * @memberof Order
   */
  price: number;
  /**
   * Transaction direction (buy/sell)
   * @type {string}
   * @memberof Order
   */
  direction: OrderDirectionEnum;
  /**
   * State of the order
   * @type {string}
   * @memberof Order
   */
  state: OrderStateEnum;
  /**
   * Order quantity
   * @type {number}
   * @memberof Order
   */
  quantity: number;
  /**
   * Filled quantity if any
   * @type {number}
   * @memberof Order
   */
  filledQty?: number;
  /**
   * Order expiration type
   * @type {string}
   * @memberof Order
   */
  expiryType: OrderExpiryTypeEnum;
  /**
   * Expiry date in case of GTD order in format of (YYYY-MM-DD)
   * @type {string}
   * @memberof Order
   */
  expiry?: string;
  /**
   * ISIN code of the instrument
   * @type {string}
   * @memberof Order
   */
  isin?: string;
  /**
   * Inventory management method in case of position closing
   * @type {string}
   * @memberof Order
   */
  inventoryManagementMethod?: OrderInventoryManagementMethodEnum;
  /**
   * Order type
   * @type {string}
   * @memberof Order
   */
  orderType: OrderOrderTypeEnum;
  /**
   * MIC code of the market
   * @type {string}
   * @memberof Order
   */
  mic: string;
  /**
   * Price level, when the stop order activates
   * @type {number}
   * @memberof Order
   */
  stopActivationPrice?: number;
  /**
   * Limit price of the activated stop order
   * @type {number}
   * @memberof Order
   */
  stopLimitPrice?: number;
  /**
   * Trading session
   * @type {string}
   * @memberof Order
   */
  tradingSession?: OrderTradingSessionEnum;
}

export const OrderDirectionEnum = {
  Buy: 'buy',
  Sell: 'sell',
} as const;

export type OrderDirectionEnum = (typeof OrderDirectionEnum)[keyof typeof OrderDirectionEnum];
export const OrderStateEnum = {
  PreSubmitted: 'pre-submitted',
  Submitted: 'submitted',
  Active: 'active',
  Revoked: 'revoked',
  Rejected: 'rejected',
  Partfilled: 'partfilled',
  Filled: 'filled',
} as const;

export type OrderStateEnum = (typeof OrderStateEnum)[keyof typeof OrderStateEnum];
export const OrderExpiryTypeEnum = {
  Day: 'day',
  Gtd: 'gtd',
  Gtc: 'gtc',
} as const;

export type OrderExpiryTypeEnum = (typeof OrderExpiryTypeEnum)[keyof typeof OrderExpiryTypeEnum];
export const OrderInventoryManagementMethodEnum = {
  Fifo: 'FIFO',
  Lifo: 'LIFO',
  Hifo: 'HIFO',
  Lofo: 'LOFO',
} as const;

export type OrderInventoryManagementMethodEnum =
  (typeof OrderInventoryManagementMethodEnum)[keyof typeof OrderInventoryManagementMethodEnum];
export const OrderOrderTypeEnum = {
  Market: 'market',
  Limit: 'limit',
  StopMarket: 'stopMarket',
  StopLimit: 'stopLimit',
} as const;

export type OrderOrderTypeEnum = (typeof OrderOrderTypeEnum)[keyof typeof OrderOrderTypeEnum];
export const OrderTradingSessionEnum = {
  PreMarket: 'preMarket',
  AfterMarket: 'afterMarket',
  Opening: 'opening',
  Open: 'open',
  Closing: 'closing',
  Auction: 'auction',
} as const;

export type OrderTradingSessionEnum = (typeof OrderTradingSessionEnum)[keyof typeof OrderTradingSessionEnum];

/**
 *
 * @export
 * @interface OrderConfirmationRequest
 */
export interface OrderConfirmationRequest {
  /**
   * Order id
   * @type {string}
   * @memberof OrderConfirmationRequest
   */
  orderId?: string;
  /**
   * Order id
   * @type {string}
   * @memberof OrderConfirmationRequest
   */
  accountId?: string;
  /**
   * Code for confirmation
   * @type {string}
   * @memberof OrderConfirmationRequest
   */
  confirmationCode?: string;
}
/**
 *
 * @export
 * @interface OrderCreatedResponse
 */
export interface OrderCreatedResponse {
  /**
   * Submitted id of the order (only in case of submit), or the temporary idenfier of the order (only in case of pre-submit)
   * @type {string}
   * @memberof OrderCreatedResponse
   */
  id: string;
  /**
   * True if secondary confirmation needed for the submitted order.
   * @type {boolean}
   * @memberof OrderCreatedResponse
   */
  confirmationNeeded?: boolean;
  /**
   * True if the order was succesfully confirmed
   * @type {boolean}
   * @memberof OrderCreatedResponse
   */
  confirmed?: boolean;
}
/**
 * Order descriptor
 * @export
 * @interface OrderRequest
 */
export interface OrderRequest {
  /**
   * Price of the order. It is necessary in case of limit order, and must be empty in case of market order.
   * @type {number}
   * @memberof OrderRequest
   */
  price: number;
  /**
   * Transaction direction (buy/sell)
   * @type {string}
   * @memberof OrderRequest
   */
  direction: OrderRequestDirectionEnum;
  /**
   * State of the order
   * @type {string}
   * @memberof OrderRequest
   */
  state: OrderRequestStateEnum;
  /**
   * Order quantity
   * @type {number}
   * @memberof OrderRequest
   */
  quantity: number;
  /**
   * Filled quantity if any
   * @type {number}
   * @memberof OrderRequest
   */
  filledQty?: number;
  /**
   * Order expiration type
   * @type {string}
   * @memberof OrderRequest
   */
  expiryType: OrderRequestExpiryTypeEnum;
  /**
   * Expiry date in case of GTD order in format of (YYYY-MM-DD)
   * @type {string}
   * @memberof OrderRequest
   */
  expiry?: string;
  /**
   * ISIN code of the instrument
   * @type {string}
   * @memberof OrderRequest
   */
  isin?: string;
  /**
   * Inventory management method in case of position closing
   * @type {string}
   * @memberof OrderRequest
   */
  inventoryManagementMethod?: OrderRequestInventoryManagementMethodEnum;
  /**
   * Order type
   * @type {string}
   * @memberof OrderRequest
   */
  orderType: OrderRequestOrderTypeEnum;
  /**
   * MIC code of the market
   * @type {string}
   * @memberof OrderRequest
   */
  mic: string;
  /**
   * Price level, when the stop order activates
   * @type {number}
   * @memberof OrderRequest
   */
  stopActivationPrice?: number;
  /**
   * Limit price of the activated stop order
   * @type {number}
   * @memberof OrderRequest
   */
  stopLimitPrice?: number;
  /**
   * Trading session
   * @type {string}
   * @memberof OrderRequest
   */
  tradingSession?: OrderRequestTradingSessionEnum;
}

export const OrderRequestDirectionEnum = {
  Buy: 'buy',
  Sell: 'sell',
} as const;

export type OrderRequestDirectionEnum = (typeof OrderRequestDirectionEnum)[keyof typeof OrderRequestDirectionEnum];
export const OrderRequestStateEnum = {
  PreSubmitted: 'pre-submitted',
  Submitted: 'submitted',
  Active: 'active',
  Revoked: 'revoked',
  Rejected: 'rejected',
  Partfilled: 'partfilled',
  Filled: 'filled',
} as const;

export type OrderRequestStateEnum = (typeof OrderRequestStateEnum)[keyof typeof OrderRequestStateEnum];
export const OrderRequestExpiryTypeEnum = {
  Day: 'day',
  Gtd: 'gtd',
  Gtc: 'gtc',
} as const;

export type OrderRequestExpiryTypeEnum = (typeof OrderRequestExpiryTypeEnum)[keyof typeof OrderRequestExpiryTypeEnum];
export const OrderRequestInventoryManagementMethodEnum = {
  Fifo: 'FIFO',
  Lifo: 'LIFO',
  Hifo: 'HIFO',
  Lofo: 'LOFO',
} as const;

export type OrderRequestInventoryManagementMethodEnum =
  (typeof OrderRequestInventoryManagementMethodEnum)[keyof typeof OrderRequestInventoryManagementMethodEnum];
export const OrderRequestOrderTypeEnum = {
  Market: 'market',
  Limit: 'limit',
  StopMarket: 'stopMarket',
  StopLimit: 'stopLimit',
} as const;

export type OrderRequestOrderTypeEnum = (typeof OrderRequestOrderTypeEnum)[keyof typeof OrderRequestOrderTypeEnum];
export const OrderRequestTradingSessionEnum = {
  PreMarket: 'preMarket',
  AfterMarket: 'afterMarket',
  Opening: 'opening',
  Open: 'open',
  Closing: 'closing',
  Auction: 'auction',
} as const;

export type OrderRequestTradingSessionEnum =
  (typeof OrderRequestTradingSessionEnum)[keyof typeof OrderRequestTradingSessionEnum];

/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary Minden nyitott megbízás visszavonása
     * @param {string} source This identifier is unique for the different modules of the source systemapplications. The name must be defined as an application module name in VEKER naming style. This field is used for logging and in the gatekeeper configuration too.
     * @param {string} srcModule This identifier is unique for the different consumer systemapplications. The name has to be defined in VEKER naming style as an application name. This field is used for logging, gathering information on service usage and in the gatekeeper configuration too.
     * @param {string} userid The identity ID describes the identity of the user that has initiated the service request. This may be, for example, the customer, or an internal user, or may identify a special systemapplication user for calls made by automated systemapplications. If the identification of the original user is not possible (e.g. batch jobs) or the original user is not part of the global user directory (e.g. a customer, who is using a frontend systemapplication), then a technical user name should be used. This is different from the application ID. The Identity ID is of the end user of the systemapplication while the application ID is the calling systemapplication.
     * @param {string} accountId
     * @param {string} [id] All requests should include a unique identifier element. The identifier is used for auditing and control of double-submission for update operations. The Id must be prefixed by the application name (or module, if necessary) to be unique in the whole environment. The application name and the Id must be separated with colon (:). Synchronous response to a request must use the same id in the HTTP Header.
     * @param {string} [correlid] Flow (or session) identifier. The flow initiator generates the ID; any other party should copy the received ID into all subsequent messages/calls inside the flow. IDs should be unique for each flow.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cancelAllOrders: async (
      source: string,
      srcModule: string,
      userid: string,
      accountId: string,
      id?: string,
      correlid?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'source' is not null or undefined
      assertParamExists('cancelAllOrders', 'source', source);
      // verify required parameter 'srcModule' is not null or undefined
      assertParamExists('cancelAllOrders', 'srcModule', srcModule);
      // verify required parameter 'userid' is not null or undefined
      assertParamExists('cancelAllOrders', 'userid', userid);
      // verify required parameter 'accountId' is not null or undefined
      assertParamExists('cancelAllOrders', 'accountId', accountId);
      const localVarPath = `/accounts/{accountId}/cancel-all-orders`.replace(
        `{${'accountId'}}`,
        encodeURIComponent(String(accountId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (id != null) {
        localVarHeaderParameter['id'] = String(id);
      }

      if (correlid != null) {
        localVarHeaderParameter['correlid'] = String(correlid);
      }

      if (source != null) {
        localVarHeaderParameter['source'] = String(source);
      }

      if (srcModule != null) {
        localVarHeaderParameter['src-module'] = String(srcModule);
      }

      if (userid != null) {
        localVarHeaderParameter['userid'] = String(userid);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Cancel order
     * @param {string} source This identifier is unique for the different modules of the source systemapplications. The name must be defined as an application module name in VEKER naming style. This field is used for logging and in the gatekeeper configuration too.
     * @param {string} srcModule This identifier is unique for the different consumer systemapplications. The name has to be defined in VEKER naming style as an application name. This field is used for logging, gathering information on service usage and in the gatekeeper configuration too.
     * @param {string} userid The identity ID describes the identity of the user that has initiated the service request. This may be, for example, the customer, or an internal user, or may identify a special systemapplication user for calls made by automated systemapplications. If the identification of the original user is not possible (e.g. batch jobs) or the original user is not part of the global user directory (e.g. a customer, who is using a frontend systemapplication), then a technical user name should be used. This is different from the application ID. The Identity ID is of the end user of the systemapplication while the application ID is the calling systemapplication.
     * @param {string} accountId
     * @param {string} orderId
     * @param {string} [id] All requests should include a unique identifier element. The identifier is used for auditing and control of double-submission for update operations. The Id must be prefixed by the application name (or module, if necessary) to be unique in the whole environment. The application name and the Id must be separated with colon (:). Synchronous response to a request must use the same id in the HTTP Header.
     * @param {string} [correlid] Flow (or session) identifier. The flow initiator generates the ID; any other party should copy the received ID into all subsequent messages/calls inside the flow. IDs should be unique for each flow.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cancelOrder: async (
      source: string,
      srcModule: string,
      userid: string,
      accountId: string,
      orderId: string,
      id?: string,
      correlid?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'source' is not null or undefined
      assertParamExists('cancelOrder', 'source', source);
      // verify required parameter 'srcModule' is not null or undefined
      assertParamExists('cancelOrder', 'srcModule', srcModule);
      // verify required parameter 'userid' is not null or undefined
      assertParamExists('cancelOrder', 'userid', userid);
      // verify required parameter 'accountId' is not null or undefined
      assertParamExists('cancelOrder', 'accountId', accountId);
      // verify required parameter 'orderId' is not null or undefined
      assertParamExists('cancelOrder', 'orderId', orderId);
      const localVarPath = `/accounts/{accountId}/orders/{orderId}`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'orderId'}}`, encodeURIComponent(String(orderId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (id != null) {
        localVarHeaderParameter['id'] = String(id);
      }

      if (correlid != null) {
        localVarHeaderParameter['correlid'] = String(correlid);
      }

      if (source != null) {
        localVarHeaderParameter['source'] = String(source);
      }

      if (srcModule != null) {
        localVarHeaderParameter['src-module'] = String(srcModule);
      }

      if (userid != null) {
        localVarHeaderParameter['userid'] = String(userid);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * The action pre-calculates the expected cost of the order
     * @summary Cost calculation for order
     * @param {string} source This identifier is unique for the different modules of the source systemapplications. The name must be defined as an application module name in VEKER naming style. This field is used for logging and in the gatekeeper configuration too.
     * @param {string} srcModule This identifier is unique for the different consumer systemapplications. The name has to be defined in VEKER naming style as an application name. This field is used for logging, gathering information on service usage and in the gatekeeper configuration too.
     * @param {string} userid The identity ID describes the identity of the user that has initiated the service request. This may be, for example, the customer, or an internal user, or may identify a special systemapplication user for calls made by automated systemapplications. If the identification of the original user is not possible (e.g. batch jobs) or the original user is not part of the global user directory (e.g. a customer, who is using a frontend systemapplication), then a technical user name should be used. This is different from the application ID. The Identity ID is of the end user of the systemapplication while the application ID is the calling systemapplication.
     * @param {string} accountId
     * @param {string} [id] All requests should include a unique identifier element. The identifier is used for auditing and control of double-submission for update operations. The Id must be prefixed by the application name (or module, if necessary) to be unique in the whole environment. The application name and the Id must be separated with colon (:). Synchronous response to a request must use the same id in the HTTP Header.
     * @param {string} [correlid] Flow (or session) identifier. The flow initiator generates the ID; any other party should copy the received ID into all subsequent messages/calls inside the flow. IDs should be unique for each flow.
     * @param {OrderRequest} [orderRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    costCalculation: async (
      source: string,
      srcModule: string,
      userid: string,
      accountId: string,
      id?: string,
      correlid?: string,
      orderRequest?: OrderRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'source' is not null or undefined
      assertParamExists('costCalculation', 'source', source);
      // verify required parameter 'srcModule' is not null or undefined
      assertParamExists('costCalculation', 'srcModule', srcModule);
      // verify required parameter 'userid' is not null or undefined
      assertParamExists('costCalculation', 'userid', userid);
      // verify required parameter 'accountId' is not null or undefined
      assertParamExists('costCalculation', 'accountId', accountId);
      const localVarPath = `/accounts/{accountId}/cost-calculation`.replace(
        `{${'accountId'}}`,
        encodeURIComponent(String(accountId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (id != null) {
        localVarHeaderParameter['id'] = String(id);
      }

      if (correlid != null) {
        localVarHeaderParameter['correlid'] = String(correlid);
      }

      if (source != null) {
        localVarHeaderParameter['source'] = String(source);
      }

      if (srcModule != null) {
        localVarHeaderParameter['src-module'] = String(srcModule);
      }

      if (userid != null) {
        localVarHeaderParameter['userid'] = String(userid);
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(orderRequest, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * The user settings should be checked for the flag auto-confirm. It it\'s not opted-in, the requested order shouldn\'t send to the market immediately but it should be stored temporary under a unique identifier and wait for confirmation request. The temporary stored requests can be purget at the end of the day. If the auto-confirm is enabled, the order should be sent to the market.
     * @summary Creating a new order
     * @param {string} source This identifier is unique for the different modules of the source systemapplications. The name must be defined as an application module name in VEKER naming style. This field is used for logging and in the gatekeeper configuration too.
     * @param {string} srcModule This identifier is unique for the different consumer systemapplications. The name has to be defined in VEKER naming style as an application name. This field is used for logging, gathering information on service usage and in the gatekeeper configuration too.
     * @param {string} userid The identity ID describes the identity of the user that has initiated the service request. This may be, for example, the customer, or an internal user, or may identify a special systemapplication user for calls made by automated systemapplications. If the identification of the original user is not possible (e.g. batch jobs) or the original user is not part of the global user directory (e.g. a customer, who is using a frontend systemapplication), then a technical user name should be used. This is different from the application ID. The Identity ID is of the end user of the systemapplication while the application ID is the calling systemapplication.
     * @param {string} accountId
     * @param {string} [id] All requests should include a unique identifier element. The identifier is used for auditing and control of double-submission for update operations. The Id must be prefixed by the application name (or module, if necessary) to be unique in the whole environment. The application name and the Id must be separated with colon (:). Synchronous response to a request must use the same id in the HTTP Header.
     * @param {string} [correlid] Flow (or session) identifier. The flow initiator generates the ID; any other party should copy the received ID into all subsequent messages/calls inside the flow. IDs should be unique for each flow.
     * @param {OrderRequest} [orderRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createOrder: async (
      source: string,
      srcModule: string,
      userid: string,
      accountId: string,
      id?: string,
      correlid?: string,
      orderRequest?: OrderRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'source' is not null or undefined
      assertParamExists('createOrder', 'source', source);
      // verify required parameter 'srcModule' is not null or undefined
      assertParamExists('createOrder', 'srcModule', srcModule);
      // verify required parameter 'userid' is not null or undefined
      assertParamExists('createOrder', 'userid', userid);
      // verify required parameter 'accountId' is not null or undefined
      assertParamExists('createOrder', 'accountId', accountId);
      const localVarPath = `/accounts/{accountId}/orders`.replace(
        `{${'accountId'}}`,
        encodeURIComponent(String(accountId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (id != null) {
        localVarHeaderParameter['id'] = String(id);
      }

      if (correlid != null) {
        localVarHeaderParameter['correlid'] = String(correlid);
      }

      if (source != null) {
        localVarHeaderParameter['source'] = String(source);
      }

      if (srcModule != null) {
        localVarHeaderParameter['src-module'] = String(srcModule);
      }

      if (userid != null) {
        localVarHeaderParameter['userid'] = String(userid);
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(orderRequest, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Exports the preliminary cost statement PDF file
     * @param {string} source This identifier is unique for the different modules of the source systemapplications. The name must be defined as an application module name in VEKER naming style. This field is used for logging and in the gatekeeper configuration too.
     * @param {string} srcModule This identifier is unique for the different consumer systemapplications. The name has to be defined in VEKER naming style as an application name. This field is used for logging, gathering information on service usage and in the gatekeeper configuration too.
     * @param {string} userid The identity ID describes the identity of the user that has initiated the service request. This may be, for example, the customer, or an internal user, or may identify a special systemapplication user for calls made by automated systemapplications. If the identification of the original user is not possible (e.g. batch jobs) or the original user is not part of the global user directory (e.g. a customer, who is using a frontend systemapplication), then a technical user name should be used. This is different from the application ID. The Identity ID is of the end user of the systemapplication while the application ID is the calling systemapplication.
     * @param {string} accountId
     * @param {string} [id] All requests should include a unique identifier element. The identifier is used for auditing and control of double-submission for update operations. The Id must be prefixed by the application name (or module, if necessary) to be unique in the whole environment. The application name and the Id must be separated with colon (:). Synchronous response to a request must use the same id in the HTTP Header.
     * @param {string} [correlid] Flow (or session) identifier. The flow initiator generates the ID; any other party should copy the received ID into all subsequent messages/calls inside the flow. IDs should be unique for each flow.
     * @param {OrderRequest} [orderRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exportPcsFile: async (
      source: string,
      srcModule: string,
      userid: string,
      accountId: string,
      id?: string,
      correlid?: string,
      orderRequest?: OrderRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'source' is not null or undefined
      assertParamExists('exportPcsFile', 'source', source);
      // verify required parameter 'srcModule' is not null or undefined
      assertParamExists('exportPcsFile', 'srcModule', srcModule);
      // verify required parameter 'userid' is not null or undefined
      assertParamExists('exportPcsFile', 'userid', userid);
      // verify required parameter 'accountId' is not null or undefined
      assertParamExists('exportPcsFile', 'accountId', accountId);
      const localVarPath = `/accounts/{accountId}/exantecost-calculation`.replace(
        `{${'accountId'}}`,
        encodeURIComponent(String(accountId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (id != null) {
        localVarHeaderParameter['id'] = String(id);
      }

      if (correlid != null) {
        localVarHeaderParameter['correlid'] = String(correlid);
      }

      if (source != null) {
        localVarHeaderParameter['source'] = String(source);
      }

      if (srcModule != null) {
        localVarHeaderParameter['src-module'] = String(srcModule);
      }

      if (userid != null) {
        localVarHeaderParameter['userid'] = String(userid);
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(orderRequest, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Get order details
     * @param {string} source This identifier is unique for the different modules of the source systemapplications. The name must be defined as an application module name in VEKER naming style. This field is used for logging and in the gatekeeper configuration too.
     * @param {string} srcModule This identifier is unique for the different consumer systemapplications. The name has to be defined in VEKER naming style as an application name. This field is used for logging, gathering information on service usage and in the gatekeeper configuration too.
     * @param {string} userid The identity ID describes the identity of the user that has initiated the service request. This may be, for example, the customer, or an internal user, or may identify a special systemapplication user for calls made by automated systemapplications. If the identification of the original user is not possible (e.g. batch jobs) or the original user is not part of the global user directory (e.g. a customer, who is using a frontend systemapplication), then a technical user name should be used. This is different from the application ID. The Identity ID is of the end user of the systemapplication while the application ID is the calling systemapplication.
     * @param {string} accountId
     * @param {string} orderId
     * @param {string} [id] All requests should include a unique identifier element. The identifier is used for auditing and control of double-submission for update operations. The Id must be prefixed by the application name (or module, if necessary) to be unique in the whole environment. The application name and the Id must be separated with colon (:). Synchronous response to a request must use the same id in the HTTP Header.
     * @param {string} [correlid] Flow (or session) identifier. The flow initiator generates the ID; any other party should copy the received ID into all subsequent messages/calls inside the flow. IDs should be unique for each flow.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrder: async (
      source: string,
      srcModule: string,
      userid: string,
      accountId: string,
      orderId: string,
      id?: string,
      correlid?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'source' is not null or undefined
      assertParamExists('getOrder', 'source', source);
      // verify required parameter 'srcModule' is not null or undefined
      assertParamExists('getOrder', 'srcModule', srcModule);
      // verify required parameter 'userid' is not null or undefined
      assertParamExists('getOrder', 'userid', userid);
      // verify required parameter 'accountId' is not null or undefined
      assertParamExists('getOrder', 'accountId', accountId);
      // verify required parameter 'orderId' is not null or undefined
      assertParamExists('getOrder', 'orderId', orderId);
      const localVarPath = `/accounts/{accountId}/orders/{orderId}`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'orderId'}}`, encodeURIComponent(String(orderId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (id != null) {
        localVarHeaderParameter['id'] = String(id);
      }

      if (correlid != null) {
        localVarHeaderParameter['correlid'] = String(correlid);
      }

      if (source != null) {
        localVarHeaderParameter['source'] = String(source);
      }

      if (srcModule != null) {
        localVarHeaderParameter['src-module'] = String(srcModule);
      }

      if (userid != null) {
        localVarHeaderParameter['userid'] = String(userid);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * The user settings should be checked for the confirmation needed flag. It it\'s not opted-in, the requested order shouldn\'t send to the market immediately but it should be stored temporary under a unique identifier and wait for confirmation request. The temporary stored requests can be purget at the end of the day. If the auto-confirm is enabled, the order should be sent to the market.
     * @summary Returns the list of orders
     * @param {string} source This identifier is unique for the different modules of the source systemapplications. The name must be defined as an application module name in VEKER naming style. This field is used for logging and in the gatekeeper configuration too.
     * @param {string} srcModule This identifier is unique for the different consumer systemapplications. The name has to be defined in VEKER naming style as an application name. This field is used for logging, gathering information on service usage and in the gatekeeper configuration too.
     * @param {string} userid The identity ID describes the identity of the user that has initiated the service request. This may be, for example, the customer, or an internal user, or may identify a special systemapplication user for calls made by automated systemapplications. If the identification of the original user is not possible (e.g. batch jobs) or the original user is not part of the global user directory (e.g. a customer, who is using a frontend systemapplication), then a technical user name should be used. This is different from the application ID. The Identity ID is of the end user of the systemapplication while the application ID is the calling systemapplication.
     * @param {string} accountId
     * @param {string} [id] All requests should include a unique identifier element. The identifier is used for auditing and control of double-submission for update operations. The Id must be prefixed by the application name (or module, if necessary) to be unique in the whole environment. The application name and the Id must be separated with colon (:). Synchronous response to a request must use the same id in the HTTP Header.
     * @param {string} [correlid] Flow (or session) identifier. The flow initiator generates the ID; any other party should copy the received ID into all subsequent messages/calls inside the flow. IDs should be unique for each flow.
     * @param {'all' | 'submitted' | 'waiting' | 'live' | 'partfilled' | 'filled' | 'wait-to-modify' | 'modified' | 'revoked' | 'rejected' | 'expired' | 'inactive' | 'stop-inactive' | 'stop-activated'} [state]
     * @param {string} [dateFrom] The orders should be queried only from the given date (max 90 days).
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrders: async (
      source: string,
      srcModule: string,
      userid: string,
      accountId: string,
      id?: string,
      correlid?: string,
      state?:
        | 'all'
        | 'submitted'
        | 'waiting'
        | 'live'
        | 'partfilled'
        | 'filled'
        | 'wait-to-modify'
        | 'modified'
        | 'revoked'
        | 'rejected'
        | 'expired'
        | 'inactive'
        | 'stop-inactive'
        | 'stop-activated',
      dateFrom?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'source' is not null or undefined
      assertParamExists('getOrders', 'source', source);
      // verify required parameter 'srcModule' is not null or undefined
      assertParamExists('getOrders', 'srcModule', srcModule);
      // verify required parameter 'userid' is not null or undefined
      assertParamExists('getOrders', 'userid', userid);
      // verify required parameter 'accountId' is not null or undefined
      assertParamExists('getOrders', 'accountId', accountId);
      const localVarPath = `/accounts/{accountId}/orders`.replace(
        `{${'accountId'}}`,
        encodeURIComponent(String(accountId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (state !== undefined) {
        localVarQueryParameter['state'] = state;
      }

      if (dateFrom !== undefined) {
        localVarQueryParameter['dateFrom'] = dateFrom;
      }

      if (id != null) {
        localVarHeaderParameter['id'] = String(id);
      }

      if (correlid != null) {
        localVarHeaderParameter['correlid'] = String(correlid);
      }

      if (source != null) {
        localVarHeaderParameter['source'] = String(source);
      }

      if (srcModule != null) {
        localVarHeaderParameter['src-module'] = String(srcModule);
      }

      if (userid != null) {
        localVarHeaderParameter['userid'] = String(userid);
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Modify existing order
     * @param {string} source This identifier is unique for the different modules of the source systemapplications. The name must be defined as an application module name in VEKER naming style. This field is used for logging and in the gatekeeper configuration too.
     * @param {string} srcModule This identifier is unique for the different consumer systemapplications. The name has to be defined in VEKER naming style as an application name. This field is used for logging, gathering information on service usage and in the gatekeeper configuration too.
     * @param {string} userid The identity ID describes the identity of the user that has initiated the service request. This may be, for example, the customer, or an internal user, or may identify a special systemapplication user for calls made by automated systemapplications. If the identification of the original user is not possible (e.g. batch jobs) or the original user is not part of the global user directory (e.g. a customer, who is using a frontend systemapplication), then a technical user name should be used. This is different from the application ID. The Identity ID is of the end user of the systemapplication while the application ID is the calling systemapplication.
     * @param {string} accountId
     * @param {string} orderId
     * @param {string} [id] All requests should include a unique identifier element. The identifier is used for auditing and control of double-submission for update operations. The Id must be prefixed by the application name (or module, if necessary) to be unique in the whole environment. The application name and the Id must be separated with colon (:). Synchronous response to a request must use the same id in the HTTP Header.
     * @param {string} [correlid] Flow (or session) identifier. The flow initiator generates the ID; any other party should copy the received ID into all subsequent messages/calls inside the flow. IDs should be unique for each flow.
     * @param {OrderRequest} [orderRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    modifyOrder: async (
      source: string,
      srcModule: string,
      userid: string,
      accountId: string,
      orderId: string,
      id?: string,
      correlid?: string,
      orderRequest?: OrderRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'source' is not null or undefined
      assertParamExists('modifyOrder', 'source', source);
      // verify required parameter 'srcModule' is not null or undefined
      assertParamExists('modifyOrder', 'srcModule', srcModule);
      // verify required parameter 'userid' is not null or undefined
      assertParamExists('modifyOrder', 'userid', userid);
      // verify required parameter 'accountId' is not null or undefined
      assertParamExists('modifyOrder', 'accountId', accountId);
      // verify required parameter 'orderId' is not null or undefined
      assertParamExists('modifyOrder', 'orderId', orderId);
      const localVarPath = `/accounts/{accountId}/orders/{orderId}`
        .replace(`{${'accountId'}}`, encodeURIComponent(String(accountId)))
        .replace(`{${'orderId'}}`, encodeURIComponent(String(orderId)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (id != null) {
        localVarHeaderParameter['id'] = String(id);
      }

      if (correlid != null) {
        localVarHeaderParameter['correlid'] = String(correlid);
      }

      if (source != null) {
        localVarHeaderParameter['source'] = String(source);
      }

      if (srcModule != null) {
        localVarHeaderParameter['src-module'] = String(srcModule);
      }

      if (userid != null) {
        localVarHeaderParameter['userid'] = String(userid);
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(orderRequest, localVarRequestOptions, configuration);

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary Confirm pre submitted order
     * @param {string} source This identifier is unique for the different modules of the source systemapplications. The name must be defined as an application module name in VEKER naming style. This field is used for logging and in the gatekeeper configuration too.
     * @param {string} srcModule This identifier is unique for the different consumer systemapplications. The name has to be defined in VEKER naming style as an application name. This field is used for logging, gathering information on service usage and in the gatekeeper configuration too.
     * @param {string} userid The identity ID describes the identity of the user that has initiated the service request. This may be, for example, the customer, or an internal user, or may identify a special systemapplication user for calls made by automated systemapplications. If the identification of the original user is not possible (e.g. batch jobs) or the original user is not part of the global user directory (e.g. a customer, who is using a frontend systemapplication), then a technical user name should be used. This is different from the application ID. The Identity ID is of the end user of the systemapplication while the application ID is the calling systemapplication.
     * @param {string} accountId
     * @param {string} [id] All requests should include a unique identifier element. The identifier is used for auditing and control of double-submission for update operations. The Id must be prefixed by the application name (or module, if necessary) to be unique in the whole environment. The application name and the Id must be separated with colon (:). Synchronous response to a request must use the same id in the HTTP Header.
     * @param {string} [correlid] Flow (or session) identifier. The flow initiator generates the ID; any other party should copy the received ID into all subsequent messages/calls inside the flow. IDs should be unique for each flow.
     * @param {OrderConfirmationRequest} [orderConfirmationRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    orderConfirmation: async (
      source: string,
      srcModule: string,
      userid: string,
      accountId: string,
      id?: string,
      correlid?: string,
      orderConfirmationRequest?: OrderConfirmationRequest,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'source' is not null or undefined
      assertParamExists('orderConfirmation', 'source', source);
      // verify required parameter 'srcModule' is not null or undefined
      assertParamExists('orderConfirmation', 'srcModule', srcModule);
      // verify required parameter 'userid' is not null or undefined
      assertParamExists('orderConfirmation', 'userid', userid);
      // verify required parameter 'accountId' is not null or undefined
      assertParamExists('orderConfirmation', 'accountId', accountId);
      const localVarPath = `/accounts/{accountId}/order-confirmation`.replace(
        `{${'accountId'}}`,
        encodeURIComponent(String(accountId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (id != null) {
        localVarHeaderParameter['id'] = String(id);
      }

      if (correlid != null) {
        localVarHeaderParameter['correlid'] = String(correlid);
      }

      if (source != null) {
        localVarHeaderParameter['source'] = String(source);
      }

      if (srcModule != null) {
        localVarHeaderParameter['src-module'] = String(srcModule);
      }

      if (userid != null) {
        localVarHeaderParameter['userid'] = String(userid);
      }

      localVarHeaderParameter['Content-Type'] = 'application/json';

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers };
      localVarRequestOptions.data = serializeDataIfNeeded(
        orderConfirmationRequest,
        localVarRequestOptions,
        configuration,
      );

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = DefaultApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary Minden nyitott megbízás visszavonása
     * @param {string} source This identifier is unique for the different modules of the source systemapplications. The name must be defined as an application module name in VEKER naming style. This field is used for logging and in the gatekeeper configuration too.
     * @param {string} srcModule This identifier is unique for the different consumer systemapplications. The name has to be defined in VEKER naming style as an application name. This field is used for logging, gathering information on service usage and in the gatekeeper configuration too.
     * @param {string} userid The identity ID describes the identity of the user that has initiated the service request. This may be, for example, the customer, or an internal user, or may identify a special systemapplication user for calls made by automated systemapplications. If the identification of the original user is not possible (e.g. batch jobs) or the original user is not part of the global user directory (e.g. a customer, who is using a frontend systemapplication), then a technical user name should be used. This is different from the application ID. The Identity ID is of the end user of the systemapplication while the application ID is the calling systemapplication.
     * @param {string} accountId
     * @param {string} [id] All requests should include a unique identifier element. The identifier is used for auditing and control of double-submission for update operations. The Id must be prefixed by the application name (or module, if necessary) to be unique in the whole environment. The application name and the Id must be separated with colon (:). Synchronous response to a request must use the same id in the HTTP Header.
     * @param {string} [correlid] Flow (or session) identifier. The flow initiator generates the ID; any other party should copy the received ID into all subsequent messages/calls inside the flow. IDs should be unique for each flow.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async cancelAllOrders(
      source: string,
      srcModule: string,
      userid: string,
      accountId: string,
      id?: string,
      correlid?: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.cancelAllOrders(
        source,
        srcModule,
        userid,
        accountId,
        id,
        correlid,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Cancel order
     * @param {string} source This identifier is unique for the different modules of the source systemapplications. The name must be defined as an application module name in VEKER naming style. This field is used for logging and in the gatekeeper configuration too.
     * @param {string} srcModule This identifier is unique for the different consumer systemapplications. The name has to be defined in VEKER naming style as an application name. This field is used for logging, gathering information on service usage and in the gatekeeper configuration too.
     * @param {string} userid The identity ID describes the identity of the user that has initiated the service request. This may be, for example, the customer, or an internal user, or may identify a special systemapplication user for calls made by automated systemapplications. If the identification of the original user is not possible (e.g. batch jobs) or the original user is not part of the global user directory (e.g. a customer, who is using a frontend systemapplication), then a technical user name should be used. This is different from the application ID. The Identity ID is of the end user of the systemapplication while the application ID is the calling systemapplication.
     * @param {string} accountId
     * @param {string} orderId
     * @param {string} [id] All requests should include a unique identifier element. The identifier is used for auditing and control of double-submission for update operations. The Id must be prefixed by the application name (or module, if necessary) to be unique in the whole environment. The application name and the Id must be separated with colon (:). Synchronous response to a request must use the same id in the HTTP Header.
     * @param {string} [correlid] Flow (or session) identifier. The flow initiator generates the ID; any other party should copy the received ID into all subsequent messages/calls inside the flow. IDs should be unique for each flow.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async cancelOrder(
      source: string,
      srcModule: string,
      userid: string,
      accountId: string,
      orderId: string,
      id?: string,
      correlid?: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.cancelOrder(
        source,
        srcModule,
        userid,
        accountId,
        orderId,
        id,
        correlid,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * The action pre-calculates the expected cost of the order
     * @summary Cost calculation for order
     * @param {string} source This identifier is unique for the different modules of the source systemapplications. The name must be defined as an application module name in VEKER naming style. This field is used for logging and in the gatekeeper configuration too.
     * @param {string} srcModule This identifier is unique for the different consumer systemapplications. The name has to be defined in VEKER naming style as an application name. This field is used for logging, gathering information on service usage and in the gatekeeper configuration too.
     * @param {string} userid The identity ID describes the identity of the user that has initiated the service request. This may be, for example, the customer, or an internal user, or may identify a special systemapplication user for calls made by automated systemapplications. If the identification of the original user is not possible (e.g. batch jobs) or the original user is not part of the global user directory (e.g. a customer, who is using a frontend systemapplication), then a technical user name should be used. This is different from the application ID. The Identity ID is of the end user of the systemapplication while the application ID is the calling systemapplication.
     * @param {string} accountId
     * @param {string} [id] All requests should include a unique identifier element. The identifier is used for auditing and control of double-submission for update operations. The Id must be prefixed by the application name (or module, if necessary) to be unique in the whole environment. The application name and the Id must be separated with colon (:). Synchronous response to a request must use the same id in the HTTP Header.
     * @param {string} [correlid] Flow (or session) identifier. The flow initiator generates the ID; any other party should copy the received ID into all subsequent messages/calls inside the flow. IDs should be unique for each flow.
     * @param {OrderRequest} [orderRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async costCalculation(
      source: string,
      srcModule: string,
      userid: string,
      accountId: string,
      id?: string,
      correlid?: string,
      orderRequest?: OrderRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CostCalculationResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.costCalculation(
        source,
        srcModule,
        userid,
        accountId,
        id,
        correlid,
        orderRequest,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * The user settings should be checked for the flag auto-confirm. It it\'s not opted-in, the requested order shouldn\'t send to the market immediately but it should be stored temporary under a unique identifier and wait for confirmation request. The temporary stored requests can be purget at the end of the day. If the auto-confirm is enabled, the order should be sent to the market.
     * @summary Creating a new order
     * @param {string} source This identifier is unique for the different modules of the source systemapplications. The name must be defined as an application module name in VEKER naming style. This field is used for logging and in the gatekeeper configuration too.
     * @param {string} srcModule This identifier is unique for the different consumer systemapplications. The name has to be defined in VEKER naming style as an application name. This field is used for logging, gathering information on service usage and in the gatekeeper configuration too.
     * @param {string} userid The identity ID describes the identity of the user that has initiated the service request. This may be, for example, the customer, or an internal user, or may identify a special systemapplication user for calls made by automated systemapplications. If the identification of the original user is not possible (e.g. batch jobs) or the original user is not part of the global user directory (e.g. a customer, who is using a frontend systemapplication), then a technical user name should be used. This is different from the application ID. The Identity ID is of the end user of the systemapplication while the application ID is the calling systemapplication.
     * @param {string} accountId
     * @param {string} [id] All requests should include a unique identifier element. The identifier is used for auditing and control of double-submission for update operations. The Id must be prefixed by the application name (or module, if necessary) to be unique in the whole environment. The application name and the Id must be separated with colon (:). Synchronous response to a request must use the same id in the HTTP Header.
     * @param {string} [correlid] Flow (or session) identifier. The flow initiator generates the ID; any other party should copy the received ID into all subsequent messages/calls inside the flow. IDs should be unique for each flow.
     * @param {OrderRequest} [orderRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createOrder(
      source: string,
      srcModule: string,
      userid: string,
      accountId: string,
      id?: string,
      correlid?: string,
      orderRequest?: OrderRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderCreatedResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createOrder(
        source,
        srcModule,
        userid,
        accountId,
        id,
        correlid,
        orderRequest,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Exports the preliminary cost statement PDF file
     * @param {string} source This identifier is unique for the different modules of the source systemapplications. The name must be defined as an application module name in VEKER naming style. This field is used for logging and in the gatekeeper configuration too.
     * @param {string} srcModule This identifier is unique for the different consumer systemapplications. The name has to be defined in VEKER naming style as an application name. This field is used for logging, gathering information on service usage and in the gatekeeper configuration too.
     * @param {string} userid The identity ID describes the identity of the user that has initiated the service request. This may be, for example, the customer, or an internal user, or may identify a special systemapplication user for calls made by automated systemapplications. If the identification of the original user is not possible (e.g. batch jobs) or the original user is not part of the global user directory (e.g. a customer, who is using a frontend systemapplication), then a technical user name should be used. This is different from the application ID. The Identity ID is of the end user of the systemapplication while the application ID is the calling systemapplication.
     * @param {string} accountId
     * @param {string} [id] All requests should include a unique identifier element. The identifier is used for auditing and control of double-submission for update operations. The Id must be prefixed by the application name (or module, if necessary) to be unique in the whole environment. The application name and the Id must be separated with colon (:). Synchronous response to a request must use the same id in the HTTP Header.
     * @param {string} [correlid] Flow (or session) identifier. The flow initiator generates the ID; any other party should copy the received ID into all subsequent messages/calls inside the flow. IDs should be unique for each flow.
     * @param {OrderRequest} [orderRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async exportPcsFile(
      source: string,
      srcModule: string,
      userid: string,
      accountId: string,
      id?: string,
      correlid?: string,
      orderRequest?: OrderRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.exportPcsFile(
        source,
        srcModule,
        userid,
        accountId,
        id,
        correlid,
        orderRequest,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Get order details
     * @param {string} source This identifier is unique for the different modules of the source systemapplications. The name must be defined as an application module name in VEKER naming style. This field is used for logging and in the gatekeeper configuration too.
     * @param {string} srcModule This identifier is unique for the different consumer systemapplications. The name has to be defined in VEKER naming style as an application name. This field is used for logging, gathering information on service usage and in the gatekeeper configuration too.
     * @param {string} userid The identity ID describes the identity of the user that has initiated the service request. This may be, for example, the customer, or an internal user, or may identify a special systemapplication user for calls made by automated systemapplications. If the identification of the original user is not possible (e.g. batch jobs) or the original user is not part of the global user directory (e.g. a customer, who is using a frontend systemapplication), then a technical user name should be used. This is different from the application ID. The Identity ID is of the end user of the systemapplication while the application ID is the calling systemapplication.
     * @param {string} accountId
     * @param {string} orderId
     * @param {string} [id] All requests should include a unique identifier element. The identifier is used for auditing and control of double-submission for update operations. The Id must be prefixed by the application name (or module, if necessary) to be unique in the whole environment. The application name and the Id must be separated with colon (:). Synchronous response to a request must use the same id in the HTTP Header.
     * @param {string} [correlid] Flow (or session) identifier. The flow initiator generates the ID; any other party should copy the received ID into all subsequent messages/calls inside the flow. IDs should be unique for each flow.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getOrder(
      source: string,
      srcModule: string,
      userid: string,
      accountId: string,
      orderId: string,
      id?: string,
      correlid?: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Order>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getOrder(
        source,
        srcModule,
        userid,
        accountId,
        orderId,
        id,
        correlid,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * The user settings should be checked for the confirmation needed flag. It it\'s not opted-in, the requested order shouldn\'t send to the market immediately but it should be stored temporary under a unique identifier and wait for confirmation request. The temporary stored requests can be purget at the end of the day. If the auto-confirm is enabled, the order should be sent to the market.
     * @summary Returns the list of orders
     * @param {string} source This identifier is unique for the different modules of the source systemapplications. The name must be defined as an application module name in VEKER naming style. This field is used for logging and in the gatekeeper configuration too.
     * @param {string} srcModule This identifier is unique for the different consumer systemapplications. The name has to be defined in VEKER naming style as an application name. This field is used for logging, gathering information on service usage and in the gatekeeper configuration too.
     * @param {string} userid The identity ID describes the identity of the user that has initiated the service request. This may be, for example, the customer, or an internal user, or may identify a special systemapplication user for calls made by automated systemapplications. If the identification of the original user is not possible (e.g. batch jobs) or the original user is not part of the global user directory (e.g. a customer, who is using a frontend systemapplication), then a technical user name should be used. This is different from the application ID. The Identity ID is of the end user of the systemapplication while the application ID is the calling systemapplication.
     * @param {string} accountId
     * @param {string} [id] All requests should include a unique identifier element. The identifier is used for auditing and control of double-submission for update operations. The Id must be prefixed by the application name (or module, if necessary) to be unique in the whole environment. The application name and the Id must be separated with colon (:). Synchronous response to a request must use the same id in the HTTP Header.
     * @param {string} [correlid] Flow (or session) identifier. The flow initiator generates the ID; any other party should copy the received ID into all subsequent messages/calls inside the flow. IDs should be unique for each flow.
     * @param {'all' | 'submitted' | 'waiting' | 'live' | 'partfilled' | 'filled' | 'wait-to-modify' | 'modified' | 'revoked' | 'rejected' | 'expired' | 'inactive' | 'stop-inactive' | 'stop-activated'} [state]
     * @param {string} [dateFrom] The orders should be queried only from the given date (max 90 days).
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getOrders(
      source: string,
      srcModule: string,
      userid: string,
      accountId: string,
      id?: string,
      correlid?: string,
      state?:
        | 'all'
        | 'submitted'
        | 'waiting'
        | 'live'
        | 'partfilled'
        | 'filled'
        | 'wait-to-modify'
        | 'modified'
        | 'revoked'
        | 'rejected'
        | 'expired'
        | 'inactive'
        | 'stop-inactive'
        | 'stop-activated',
      dateFrom?: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Order>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getOrders(
        source,
        srcModule,
        userid,
        accountId,
        id,
        correlid,
        state,
        dateFrom,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Modify existing order
     * @param {string} source This identifier is unique for the different modules of the source systemapplications. The name must be defined as an application module name in VEKER naming style. This field is used for logging and in the gatekeeper configuration too.
     * @param {string} srcModule This identifier is unique for the different consumer systemapplications. The name has to be defined in VEKER naming style as an application name. This field is used for logging, gathering information on service usage and in the gatekeeper configuration too.
     * @param {string} userid The identity ID describes the identity of the user that has initiated the service request. This may be, for example, the customer, or an internal user, or may identify a special systemapplication user for calls made by automated systemapplications. If the identification of the original user is not possible (e.g. batch jobs) or the original user is not part of the global user directory (e.g. a customer, who is using a frontend systemapplication), then a technical user name should be used. This is different from the application ID. The Identity ID is of the end user of the systemapplication while the application ID is the calling systemapplication.
     * @param {string} accountId
     * @param {string} orderId
     * @param {string} [id] All requests should include a unique identifier element. The identifier is used for auditing and control of double-submission for update operations. The Id must be prefixed by the application name (or module, if necessary) to be unique in the whole environment. The application name and the Id must be separated with colon (:). Synchronous response to a request must use the same id in the HTTP Header.
     * @param {string} [correlid] Flow (or session) identifier. The flow initiator generates the ID; any other party should copy the received ID into all subsequent messages/calls inside the flow. IDs should be unique for each flow.
     * @param {OrderRequest} [orderRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async modifyOrder(
      source: string,
      srcModule: string,
      userid: string,
      accountId: string,
      orderId: string,
      id?: string,
      correlid?: string,
      orderRequest?: OrderRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Order>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.modifyOrder(
        source,
        srcModule,
        userid,
        accountId,
        orderId,
        id,
        correlid,
        orderRequest,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary Confirm pre submitted order
     * @param {string} source This identifier is unique for the different modules of the source systemapplications. The name must be defined as an application module name in VEKER naming style. This field is used for logging and in the gatekeeper configuration too.
     * @param {string} srcModule This identifier is unique for the different consumer systemapplications. The name has to be defined in VEKER naming style as an application name. This field is used for logging, gathering information on service usage and in the gatekeeper configuration too.
     * @param {string} userid The identity ID describes the identity of the user that has initiated the service request. This may be, for example, the customer, or an internal user, or may identify a special systemapplication user for calls made by automated systemapplications. If the identification of the original user is not possible (e.g. batch jobs) or the original user is not part of the global user directory (e.g. a customer, who is using a frontend systemapplication), then a technical user name should be used. This is different from the application ID. The Identity ID is of the end user of the systemapplication while the application ID is the calling systemapplication.
     * @param {string} accountId
     * @param {string} [id] All requests should include a unique identifier element. The identifier is used for auditing and control of double-submission for update operations. The Id must be prefixed by the application name (or module, if necessary) to be unique in the whole environment. The application name and the Id must be separated with colon (:). Synchronous response to a request must use the same id in the HTTP Header.
     * @param {string} [correlid] Flow (or session) identifier. The flow initiator generates the ID; any other party should copy the received ID into all subsequent messages/calls inside the flow. IDs should be unique for each flow.
     * @param {OrderConfirmationRequest} [orderConfirmationRequest]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async orderConfirmation(
      source: string,
      srcModule: string,
      userid: string,
      accountId: string,
      id?: string,
      correlid?: string,
      orderConfirmationRequest?: OrderConfirmationRequest,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Order>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.orderConfirmation(
        source,
        srcModule,
        userid,
        accountId,
        id,
        correlid,
        orderConfirmationRequest,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = DefaultApiFp(configuration);
  return {
    /**
     *
     * @summary Minden nyitott megbízás visszavonása
     * @param {DefaultApiCancelAllOrdersRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cancelAllOrders(
      requestParameters: DefaultApiCancelAllOrdersRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<void> {
      return localVarFp
        .cancelAllOrders(
          requestParameters.source,
          requestParameters.srcModule,
          requestParameters.userid,
          requestParameters.accountId,
          requestParameters.id,
          requestParameters.correlid,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Cancel order
     * @param {DefaultApiCancelOrderRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cancelOrder(requestParameters: DefaultApiCancelOrderRequest, options?: AxiosRequestConfig): AxiosPromise<void> {
      return localVarFp
        .cancelOrder(
          requestParameters.source,
          requestParameters.srcModule,
          requestParameters.userid,
          requestParameters.accountId,
          requestParameters.orderId,
          requestParameters.id,
          requestParameters.correlid,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     * The action pre-calculates the expected cost of the order
     * @summary Cost calculation for order
     * @param {DefaultApiCostCalculationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    costCalculation(
      requestParameters: DefaultApiCostCalculationRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<CostCalculationResponse> {
      return localVarFp
        .costCalculation(
          requestParameters.source,
          requestParameters.srcModule,
          requestParameters.userid,
          requestParameters.accountId,
          requestParameters.id,
          requestParameters.correlid,
          requestParameters.orderRequest,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     * The user settings should be checked for the flag auto-confirm. It it\'s not opted-in, the requested order shouldn\'t send to the market immediately but it should be stored temporary under a unique identifier and wait for confirmation request. The temporary stored requests can be purget at the end of the day. If the auto-confirm is enabled, the order should be sent to the market.
     * @summary Creating a new order
     * @param {DefaultApiCreateOrderRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createOrder(
      requestParameters: DefaultApiCreateOrderRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<OrderCreatedResponse> {
      return localVarFp
        .createOrder(
          requestParameters.source,
          requestParameters.srcModule,
          requestParameters.userid,
          requestParameters.accountId,
          requestParameters.id,
          requestParameters.correlid,
          requestParameters.orderRequest,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Exports the preliminary cost statement PDF file
     * @param {DefaultApiExportPcsFileRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    exportPcsFile(requestParameters: DefaultApiExportPcsFileRequest, options?: AxiosRequestConfig): AxiosPromise<File> {
      return localVarFp
        .exportPcsFile(
          requestParameters.source,
          requestParameters.srcModule,
          requestParameters.userid,
          requestParameters.accountId,
          requestParameters.id,
          requestParameters.correlid,
          requestParameters.orderRequest,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Get order details
     * @param {DefaultApiGetOrderRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrder(requestParameters: DefaultApiGetOrderRequest, options?: AxiosRequestConfig): AxiosPromise<Order> {
      return localVarFp
        .getOrder(
          requestParameters.source,
          requestParameters.srcModule,
          requestParameters.userid,
          requestParameters.accountId,
          requestParameters.orderId,
          requestParameters.id,
          requestParameters.correlid,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     * The user settings should be checked for the confirmation needed flag. It it\'s not opted-in, the requested order shouldn\'t send to the market immediately but it should be stored temporary under a unique identifier and wait for confirmation request. The temporary stored requests can be purget at the end of the day. If the auto-confirm is enabled, the order should be sent to the market.
     * @summary Returns the list of orders
     * @param {DefaultApiGetOrdersRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getOrders(requestParameters: DefaultApiGetOrdersRequest, options?: AxiosRequestConfig): AxiosPromise<Array<Order>> {
      return localVarFp
        .getOrders(
          requestParameters.source,
          requestParameters.srcModule,
          requestParameters.userid,
          requestParameters.accountId,
          requestParameters.id,
          requestParameters.correlid,
          requestParameters.state,
          requestParameters.dateFrom,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Modify existing order
     * @param {DefaultApiModifyOrderRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    modifyOrder(requestParameters: DefaultApiModifyOrderRequest, options?: AxiosRequestConfig): AxiosPromise<Order> {
      return localVarFp
        .modifyOrder(
          requestParameters.source,
          requestParameters.srcModule,
          requestParameters.userid,
          requestParameters.accountId,
          requestParameters.orderId,
          requestParameters.id,
          requestParameters.correlid,
          requestParameters.orderRequest,
          options,
        )
        .then(request => request(axios, basePath));
    },
    /**
     *
     * @summary Confirm pre submitted order
     * @param {DefaultApiOrderConfirmationRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    orderConfirmation(
      requestParameters: DefaultApiOrderConfirmationRequest,
      options?: AxiosRequestConfig,
    ): AxiosPromise<Order> {
      return localVarFp
        .orderConfirmation(
          requestParameters.source,
          requestParameters.srcModule,
          requestParameters.userid,
          requestParameters.accountId,
          requestParameters.id,
          requestParameters.correlid,
          requestParameters.orderConfirmationRequest,
          options,
        )
        .then(request => request(axios, basePath));
    },
  };
};

/**
 * Request parameters for cancelAllOrders operation in DefaultApi.
 * @export
 * @interface DefaultApiCancelAllOrdersRequest
 */
export interface DefaultApiCancelAllOrdersRequest {
  /**
   * This identifier is unique for the different modules of the source systemapplications. The name must be defined as an application module name in VEKER naming style. This field is used for logging and in the gatekeeper configuration too.
   * @type {string}
   * @memberof DefaultApiCancelAllOrders
   */
  readonly source: string;

  /**
   * This identifier is unique for the different consumer systemapplications. The name has to be defined in VEKER naming style as an application name. This field is used for logging, gathering information on service usage and in the gatekeeper configuration too.
   * @type {string}
   * @memberof DefaultApiCancelAllOrders
   */
  readonly srcModule: string;

  /**
   * The identity ID describes the identity of the user that has initiated the service request. This may be, for example, the customer, or an internal user, or may identify a special systemapplication user for calls made by automated systemapplications. If the identification of the original user is not possible (e.g. batch jobs) or the original user is not part of the global user directory (e.g. a customer, who is using a frontend systemapplication), then a technical user name should be used. This is different from the application ID. The Identity ID is of the end user of the systemapplication while the application ID is the calling systemapplication.
   * @type {string}
   * @memberof DefaultApiCancelAllOrders
   */
  readonly userid: string;

  /**
   *
   * @type {string}
   * @memberof DefaultApiCancelAllOrders
   */
  readonly accountId: string;

  /**
   * All requests should include a unique identifier element. The identifier is used for auditing and control of double-submission for update operations. The Id must be prefixed by the application name (or module, if necessary) to be unique in the whole environment. The application name and the Id must be separated with colon (:). Synchronous response to a request must use the same id in the HTTP Header.
   * @type {string}
   * @memberof DefaultApiCancelAllOrders
   */
  readonly id?: string;

  /**
   * Flow (or session) identifier. The flow initiator generates the ID; any other party should copy the received ID into all subsequent messages/calls inside the flow. IDs should be unique for each flow.
   * @type {string}
   * @memberof DefaultApiCancelAllOrders
   */
  readonly correlid?: string;
}

/**
 * Request parameters for cancelOrder operation in DefaultApi.
 * @export
 * @interface DefaultApiCancelOrderRequest
 */
export interface DefaultApiCancelOrderRequest {
  /**
   * This identifier is unique for the different modules of the source systemapplications. The name must be defined as an application module name in VEKER naming style. This field is used for logging and in the gatekeeper configuration too.
   * @type {string}
   * @memberof DefaultApiCancelOrder
   */
  readonly source: string;

  /**
   * This identifier is unique for the different consumer systemapplications. The name has to be defined in VEKER naming style as an application name. This field is used for logging, gathering information on service usage and in the gatekeeper configuration too.
   * @type {string}
   * @memberof DefaultApiCancelOrder
   */
  readonly srcModule: string;

  /**
   * The identity ID describes the identity of the user that has initiated the service request. This may be, for example, the customer, or an internal user, or may identify a special systemapplication user for calls made by automated systemapplications. If the identification of the original user is not possible (e.g. batch jobs) or the original user is not part of the global user directory (e.g. a customer, who is using a frontend systemapplication), then a technical user name should be used. This is different from the application ID. The Identity ID is of the end user of the systemapplication while the application ID is the calling systemapplication.
   * @type {string}
   * @memberof DefaultApiCancelOrder
   */
  readonly userid: string;

  /**
   *
   * @type {string}
   * @memberof DefaultApiCancelOrder
   */
  readonly accountId: string;

  /**
   *
   * @type {string}
   * @memberof DefaultApiCancelOrder
   */
  readonly orderId: string;

  /**
   * All requests should include a unique identifier element. The identifier is used for auditing and control of double-submission for update operations. The Id must be prefixed by the application name (or module, if necessary) to be unique in the whole environment. The application name and the Id must be separated with colon (:). Synchronous response to a request must use the same id in the HTTP Header.
   * @type {string}
   * @memberof DefaultApiCancelOrder
   */
  readonly id?: string;

  /**
   * Flow (or session) identifier. The flow initiator generates the ID; any other party should copy the received ID into all subsequent messages/calls inside the flow. IDs should be unique for each flow.
   * @type {string}
   * @memberof DefaultApiCancelOrder
   */
  readonly correlid?: string;
}

/**
 * Request parameters for costCalculation operation in DefaultApi.
 * @export
 * @interface DefaultApiCostCalculationRequest
 */
export interface DefaultApiCostCalculationRequest {
  /**
   * This identifier is unique for the different modules of the source systemapplications. The name must be defined as an application module name in VEKER naming style. This field is used for logging and in the gatekeeper configuration too.
   * @type {string}
   * @memberof DefaultApiCostCalculation
   */
  readonly source: string;

  /**
   * This identifier is unique for the different consumer systemapplications. The name has to be defined in VEKER naming style as an application name. This field is used for logging, gathering information on service usage and in the gatekeeper configuration too.
   * @type {string}
   * @memberof DefaultApiCostCalculation
   */
  readonly srcModule: string;

  /**
   * The identity ID describes the identity of the user that has initiated the service request. This may be, for example, the customer, or an internal user, or may identify a special systemapplication user for calls made by automated systemapplications. If the identification of the original user is not possible (e.g. batch jobs) or the original user is not part of the global user directory (e.g. a customer, who is using a frontend systemapplication), then a technical user name should be used. This is different from the application ID. The Identity ID is of the end user of the systemapplication while the application ID is the calling systemapplication.
   * @type {string}
   * @memberof DefaultApiCostCalculation
   */
  readonly userid: string;

  /**
   *
   * @type {string}
   * @memberof DefaultApiCostCalculation
   */
  readonly accountId: string;

  /**
   * All requests should include a unique identifier element. The identifier is used for auditing and control of double-submission for update operations. The Id must be prefixed by the application name (or module, if necessary) to be unique in the whole environment. The application name and the Id must be separated with colon (:). Synchronous response to a request must use the same id in the HTTP Header.
   * @type {string}
   * @memberof DefaultApiCostCalculation
   */
  readonly id?: string;

  /**
   * Flow (or session) identifier. The flow initiator generates the ID; any other party should copy the received ID into all subsequent messages/calls inside the flow. IDs should be unique for each flow.
   * @type {string}
   * @memberof DefaultApiCostCalculation
   */
  readonly correlid?: string;

  /**
   *
   * @type {OrderRequest}
   * @memberof DefaultApiCostCalculation
   */
  readonly orderRequest?: OrderRequest;
}

/**
 * Request parameters for createOrder operation in DefaultApi.
 * @export
 * @interface DefaultApiCreateOrderRequest
 */
export interface DefaultApiCreateOrderRequest {
  /**
   * This identifier is unique for the different modules of the source systemapplications. The name must be defined as an application module name in VEKER naming style. This field is used for logging and in the gatekeeper configuration too.
   * @type {string}
   * @memberof DefaultApiCreateOrder
   */
  readonly source: string;

  /**
   * This identifier is unique for the different consumer systemapplications. The name has to be defined in VEKER naming style as an application name. This field is used for logging, gathering information on service usage and in the gatekeeper configuration too.
   * @type {string}
   * @memberof DefaultApiCreateOrder
   */
  readonly srcModule: string;

  /**
   * The identity ID describes the identity of the user that has initiated the service request. This may be, for example, the customer, or an internal user, or may identify a special systemapplication user for calls made by automated systemapplications. If the identification of the original user is not possible (e.g. batch jobs) or the original user is not part of the global user directory (e.g. a customer, who is using a frontend systemapplication), then a technical user name should be used. This is different from the application ID. The Identity ID is of the end user of the systemapplication while the application ID is the calling systemapplication.
   * @type {string}
   * @memberof DefaultApiCreateOrder
   */
  readonly userid: string;

  /**
   *
   * @type {string}
   * @memberof DefaultApiCreateOrder
   */
  readonly accountId: string;

  /**
   * All requests should include a unique identifier element. The identifier is used for auditing and control of double-submission for update operations. The Id must be prefixed by the application name (or module, if necessary) to be unique in the whole environment. The application name and the Id must be separated with colon (:). Synchronous response to a request must use the same id in the HTTP Header.
   * @type {string}
   * @memberof DefaultApiCreateOrder
   */
  readonly id?: string;

  /**
   * Flow (or session) identifier. The flow initiator generates the ID; any other party should copy the received ID into all subsequent messages/calls inside the flow. IDs should be unique for each flow.
   * @type {string}
   * @memberof DefaultApiCreateOrder
   */
  readonly correlid?: string;

  /**
   *
   * @type {OrderRequest}
   * @memberof DefaultApiCreateOrder
   */
  readonly orderRequest?: OrderRequest;
}

/**
 * Request parameters for exportPcsFile operation in DefaultApi.
 * @export
 * @interface DefaultApiExportPcsFileRequest
 */
export interface DefaultApiExportPcsFileRequest {
  /**
   * This identifier is unique for the different modules of the source systemapplications. The name must be defined as an application module name in VEKER naming style. This field is used for logging and in the gatekeeper configuration too.
   * @type {string}
   * @memberof DefaultApiExportPcsFile
   */
  readonly source: string;

  /**
   * This identifier is unique for the different consumer systemapplications. The name has to be defined in VEKER naming style as an application name. This field is used for logging, gathering information on service usage and in the gatekeeper configuration too.
   * @type {string}
   * @memberof DefaultApiExportPcsFile
   */
  readonly srcModule: string;

  /**
   * The identity ID describes the identity of the user that has initiated the service request. This may be, for example, the customer, or an internal user, or may identify a special systemapplication user for calls made by automated systemapplications. If the identification of the original user is not possible (e.g. batch jobs) or the original user is not part of the global user directory (e.g. a customer, who is using a frontend systemapplication), then a technical user name should be used. This is different from the application ID. The Identity ID is of the end user of the systemapplication while the application ID is the calling systemapplication.
   * @type {string}
   * @memberof DefaultApiExportPcsFile
   */
  readonly userid: string;

  /**
   *
   * @type {string}
   * @memberof DefaultApiExportPcsFile
   */
  readonly accountId: string;

  /**
   * All requests should include a unique identifier element. The identifier is used for auditing and control of double-submission for update operations. The Id must be prefixed by the application name (or module, if necessary) to be unique in the whole environment. The application name and the Id must be separated with colon (:). Synchronous response to a request must use the same id in the HTTP Header.
   * @type {string}
   * @memberof DefaultApiExportPcsFile
   */
  readonly id?: string;

  /**
   * Flow (or session) identifier. The flow initiator generates the ID; any other party should copy the received ID into all subsequent messages/calls inside the flow. IDs should be unique for each flow.
   * @type {string}
   * @memberof DefaultApiExportPcsFile
   */
  readonly correlid?: string;

  /**
   *
   * @type {OrderRequest}
   * @memberof DefaultApiExportPcsFile
   */
  readonly orderRequest?: OrderRequest;
}

/**
 * Request parameters for getOrder operation in DefaultApi.
 * @export
 * @interface DefaultApiGetOrderRequest
 */
export interface DefaultApiGetOrderRequest {
  /**
   * This identifier is unique for the different modules of the source systemapplications. The name must be defined as an application module name in VEKER naming style. This field is used for logging and in the gatekeeper configuration too.
   * @type {string}
   * @memberof DefaultApiGetOrder
   */
  readonly source: string;

  /**
   * This identifier is unique for the different consumer systemapplications. The name has to be defined in VEKER naming style as an application name. This field is used for logging, gathering information on service usage and in the gatekeeper configuration too.
   * @type {string}
   * @memberof DefaultApiGetOrder
   */
  readonly srcModule: string;

  /**
   * The identity ID describes the identity of the user that has initiated the service request. This may be, for example, the customer, or an internal user, or may identify a special systemapplication user for calls made by automated systemapplications. If the identification of the original user is not possible (e.g. batch jobs) or the original user is not part of the global user directory (e.g. a customer, who is using a frontend systemapplication), then a technical user name should be used. This is different from the application ID. The Identity ID is of the end user of the systemapplication while the application ID is the calling systemapplication.
   * @type {string}
   * @memberof DefaultApiGetOrder
   */
  readonly userid: string;

  /**
   *
   * @type {string}
   * @memberof DefaultApiGetOrder
   */
  readonly accountId: string;

  /**
   *
   * @type {string}
   * @memberof DefaultApiGetOrder
   */
  readonly orderId: string;

  /**
   * All requests should include a unique identifier element. The identifier is used for auditing and control of double-submission for update operations. The Id must be prefixed by the application name (or module, if necessary) to be unique in the whole environment. The application name and the Id must be separated with colon (:). Synchronous response to a request must use the same id in the HTTP Header.
   * @type {string}
   * @memberof DefaultApiGetOrder
   */
  readonly id?: string;

  /**
   * Flow (or session) identifier. The flow initiator generates the ID; any other party should copy the received ID into all subsequent messages/calls inside the flow. IDs should be unique for each flow.
   * @type {string}
   * @memberof DefaultApiGetOrder
   */
  readonly correlid?: string;
}

/**
 * Request parameters for getOrders operation in DefaultApi.
 * @export
 * @interface DefaultApiGetOrdersRequest
 */
export interface DefaultApiGetOrdersRequest {
  /**
   * This identifier is unique for the different modules of the source systemapplications. The name must be defined as an application module name in VEKER naming style. This field is used for logging and in the gatekeeper configuration too.
   * @type {string}
   * @memberof DefaultApiGetOrders
   */
  readonly source: string;

  /**
   * This identifier is unique for the different consumer systemapplications. The name has to be defined in VEKER naming style as an application name. This field is used for logging, gathering information on service usage and in the gatekeeper configuration too.
   * @type {string}
   * @memberof DefaultApiGetOrders
   */
  readonly srcModule: string;

  /**
   * The identity ID describes the identity of the user that has initiated the service request. This may be, for example, the customer, or an internal user, or may identify a special systemapplication user for calls made by automated systemapplications. If the identification of the original user is not possible (e.g. batch jobs) or the original user is not part of the global user directory (e.g. a customer, who is using a frontend systemapplication), then a technical user name should be used. This is different from the application ID. The Identity ID is of the end user of the systemapplication while the application ID is the calling systemapplication.
   * @type {string}
   * @memberof DefaultApiGetOrders
   */
  readonly userid: string;

  /**
   *
   * @type {string}
   * @memberof DefaultApiGetOrders
   */
  readonly accountId: string;

  /**
   * All requests should include a unique identifier element. The identifier is used for auditing and control of double-submission for update operations. The Id must be prefixed by the application name (or module, if necessary) to be unique in the whole environment. The application name and the Id must be separated with colon (:). Synchronous response to a request must use the same id in the HTTP Header.
   * @type {string}
   * @memberof DefaultApiGetOrders
   */
  readonly id?: string;

  /**
   * Flow (or session) identifier. The flow initiator generates the ID; any other party should copy the received ID into all subsequent messages/calls inside the flow. IDs should be unique for each flow.
   * @type {string}
   * @memberof DefaultApiGetOrders
   */
  readonly correlid?: string;

  /**
   *
   * @type {'all' | 'submitted' | 'waiting' | 'live' | 'partfilled' | 'filled' | 'wait-to-modify' | 'modified' | 'revoked' | 'rejected' | 'expired' | 'inactive' | 'stop-inactive' | 'stop-activated'}
   * @memberof DefaultApiGetOrders
   */
  readonly state?:
    | 'all'
    | 'submitted'
    | 'waiting'
    | 'live'
    | 'partfilled'
    | 'filled'
    | 'wait-to-modify'
    | 'modified'
    | 'revoked'
    | 'rejected'
    | 'expired'
    | 'inactive'
    | 'stop-inactive'
    | 'stop-activated';

  /**
   * The orders should be queried only from the given date (max 90 days).
   * @type {string}
   * @memberof DefaultApiGetOrders
   */
  readonly dateFrom?: string;
}

/**
 * Request parameters for modifyOrder operation in DefaultApi.
 * @export
 * @interface DefaultApiModifyOrderRequest
 */
export interface DefaultApiModifyOrderRequest {
  /**
   * This identifier is unique for the different modules of the source systemapplications. The name must be defined as an application module name in VEKER naming style. This field is used for logging and in the gatekeeper configuration too.
   * @type {string}
   * @memberof DefaultApiModifyOrder
   */
  readonly source: string;

  /**
   * This identifier is unique for the different consumer systemapplications. The name has to be defined in VEKER naming style as an application name. This field is used for logging, gathering information on service usage and in the gatekeeper configuration too.
   * @type {string}
   * @memberof DefaultApiModifyOrder
   */
  readonly srcModule: string;

  /**
   * The identity ID describes the identity of the user that has initiated the service request. This may be, for example, the customer, or an internal user, or may identify a special systemapplication user for calls made by automated systemapplications. If the identification of the original user is not possible (e.g. batch jobs) or the original user is not part of the global user directory (e.g. a customer, who is using a frontend systemapplication), then a technical user name should be used. This is different from the application ID. The Identity ID is of the end user of the systemapplication while the application ID is the calling systemapplication.
   * @type {string}
   * @memberof DefaultApiModifyOrder
   */
  readonly userid: string;

  /**
   *
   * @type {string}
   * @memberof DefaultApiModifyOrder
   */
  readonly accountId: string;

  /**
   *
   * @type {string}
   * @memberof DefaultApiModifyOrder
   */
  readonly orderId: string;

  /**
   * All requests should include a unique identifier element. The identifier is used for auditing and control of double-submission for update operations. The Id must be prefixed by the application name (or module, if necessary) to be unique in the whole environment. The application name and the Id must be separated with colon (:). Synchronous response to a request must use the same id in the HTTP Header.
   * @type {string}
   * @memberof DefaultApiModifyOrder
   */
  readonly id?: string;

  /**
   * Flow (or session) identifier. The flow initiator generates the ID; any other party should copy the received ID into all subsequent messages/calls inside the flow. IDs should be unique for each flow.
   * @type {string}
   * @memberof DefaultApiModifyOrder
   */
  readonly correlid?: string;

  /**
   *
   * @type {OrderRequest}
   * @memberof DefaultApiModifyOrder
   */
  readonly orderRequest?: OrderRequest;
}

/**
 * Request parameters for orderConfirmation operation in DefaultApi.
 * @export
 * @interface DefaultApiOrderConfirmationRequest
 */
export interface DefaultApiOrderConfirmationRequest {
  /**
   * This identifier is unique for the different modules of the source systemapplications. The name must be defined as an application module name in VEKER naming style. This field is used for logging and in the gatekeeper configuration too.
   * @type {string}
   * @memberof DefaultApiOrderConfirmation
   */
  readonly source: string;

  /**
   * This identifier is unique for the different consumer systemapplications. The name has to be defined in VEKER naming style as an application name. This field is used for logging, gathering information on service usage and in the gatekeeper configuration too.
   * @type {string}
   * @memberof DefaultApiOrderConfirmation
   */
  readonly srcModule: string;

  /**
   * The identity ID describes the identity of the user that has initiated the service request. This may be, for example, the customer, or an internal user, or may identify a special systemapplication user for calls made by automated systemapplications. If the identification of the original user is not possible (e.g. batch jobs) or the original user is not part of the global user directory (e.g. a customer, who is using a frontend systemapplication), then a technical user name should be used. This is different from the application ID. The Identity ID is of the end user of the systemapplication while the application ID is the calling systemapplication.
   * @type {string}
   * @memberof DefaultApiOrderConfirmation
   */
  readonly userid: string;

  /**
   *
   * @type {string}
   * @memberof DefaultApiOrderConfirmation
   */
  readonly accountId: string;

  /**
   * All requests should include a unique identifier element. The identifier is used for auditing and control of double-submission for update operations. The Id must be prefixed by the application name (or module, if necessary) to be unique in the whole environment. The application name and the Id must be separated with colon (:). Synchronous response to a request must use the same id in the HTTP Header.
   * @type {string}
   * @memberof DefaultApiOrderConfirmation
   */
  readonly id?: string;

  /**
   * Flow (or session) identifier. The flow initiator generates the ID; any other party should copy the received ID into all subsequent messages/calls inside the flow. IDs should be unique for each flow.
   * @type {string}
   * @memberof DefaultApiOrderConfirmation
   */
  readonly correlid?: string;

  /**
   *
   * @type {OrderConfirmationRequest}
   * @memberof DefaultApiOrderConfirmation
   */
  readonly orderConfirmationRequest?: OrderConfirmationRequest;
}

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
  /**
   *
   * @summary Minden nyitott megbízás visszavonása
   * @param {DefaultApiCancelAllOrdersRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public cancelAllOrders(requestParameters: DefaultApiCancelAllOrdersRequest, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .cancelAllOrders(
        requestParameters.source,
        requestParameters.srcModule,
        requestParameters.userid,
        requestParameters.accountId,
        requestParameters.id,
        requestParameters.correlid,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Cancel order
   * @param {DefaultApiCancelOrderRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public cancelOrder(requestParameters: DefaultApiCancelOrderRequest, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .cancelOrder(
        requestParameters.source,
        requestParameters.srcModule,
        requestParameters.userid,
        requestParameters.accountId,
        requestParameters.orderId,
        requestParameters.id,
        requestParameters.correlid,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * The action pre-calculates the expected cost of the order
   * @summary Cost calculation for order
   * @param {DefaultApiCostCalculationRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public costCalculation(requestParameters: DefaultApiCostCalculationRequest, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .costCalculation(
        requestParameters.source,
        requestParameters.srcModule,
        requestParameters.userid,
        requestParameters.accountId,
        requestParameters.id,
        requestParameters.correlid,
        requestParameters.orderRequest,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * The user settings should be checked for the flag auto-confirm. It it\'s not opted-in, the requested order shouldn\'t send to the market immediately but it should be stored temporary under a unique identifier and wait for confirmation request. The temporary stored requests can be purget at the end of the day. If the auto-confirm is enabled, the order should be sent to the market.
   * @summary Creating a new order
   * @param {DefaultApiCreateOrderRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public createOrder(requestParameters: DefaultApiCreateOrderRequest, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .createOrder(
        requestParameters.source,
        requestParameters.srcModule,
        requestParameters.userid,
        requestParameters.accountId,
        requestParameters.id,
        requestParameters.correlid,
        requestParameters.orderRequest,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Exports the preliminary cost statement PDF file
   * @param {DefaultApiExportPcsFileRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public exportPcsFile(requestParameters: DefaultApiExportPcsFileRequest, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .exportPcsFile(
        requestParameters.source,
        requestParameters.srcModule,
        requestParameters.userid,
        requestParameters.accountId,
        requestParameters.id,
        requestParameters.correlid,
        requestParameters.orderRequest,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Get order details
   * @param {DefaultApiGetOrderRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public getOrder(requestParameters: DefaultApiGetOrderRequest, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .getOrder(
        requestParameters.source,
        requestParameters.srcModule,
        requestParameters.userid,
        requestParameters.accountId,
        requestParameters.orderId,
        requestParameters.id,
        requestParameters.correlid,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   * The user settings should be checked for the confirmation needed flag. It it\'s not opted-in, the requested order shouldn\'t send to the market immediately but it should be stored temporary under a unique identifier and wait for confirmation request. The temporary stored requests can be purget at the end of the day. If the auto-confirm is enabled, the order should be sent to the market.
   * @summary Returns the list of orders
   * @param {DefaultApiGetOrdersRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public getOrders(requestParameters: DefaultApiGetOrdersRequest, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .getOrders(
        requestParameters.source,
        requestParameters.srcModule,
        requestParameters.userid,
        requestParameters.accountId,
        requestParameters.id,
        requestParameters.correlid,
        requestParameters.state,
        requestParameters.dateFrom,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Modify existing order
   * @param {DefaultApiModifyOrderRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public modifyOrder(requestParameters: DefaultApiModifyOrderRequest, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .modifyOrder(
        requestParameters.source,
        requestParameters.srcModule,
        requestParameters.userid,
        requestParameters.accountId,
        requestParameters.orderId,
        requestParameters.id,
        requestParameters.correlid,
        requestParameters.orderRequest,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary Confirm pre submitted order
   * @param {DefaultApiOrderConfirmationRequest} requestParameters Request parameters.
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DefaultApi
   */
  public orderConfirmation(requestParameters: DefaultApiOrderConfirmationRequest, options?: AxiosRequestConfig) {
    return DefaultApiFp(this.configuration)
      .orderConfirmation(
        requestParameters.source,
        requestParameters.srcModule,
        requestParameters.userid,
        requestParameters.accountId,
        requestParameters.id,
        requestParameters.correlid,
        requestParameters.orderConfirmationRequest,
        options,
      )
      .then(request => request(this.axios, this.basePath));
  }
}
