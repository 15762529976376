import { useLocale } from '../../../context/NbLocalizationContext';
import { useFormatter } from '../../../shared/services/useFormatter';
import { ExpiryType } from '../../../shared/types/expiryType';
import { ListedInstrument } from '../../../shared/types/listedInstrument';
import { OrderFormData } from '../components/OrderForm';
import { OrderConfirmRow } from '../partials/OrderConfirmRow';

export interface Order extends OrderFormData {
  accountName: string;
  exchangeRate?: number;
  expectedCost?: number;
  transactionSum?: number;
  price?: number;
}

export const orderRows: Array<keyof Order | keyof ListedInstrument> = [
  'accountName',
  'instrumentName',
  'isin',
  'currency',
  'orderType',
  'tradeType',
  'quantity',
  'stopLimitPrice',
  'stopActivationPrice',
  'expiryType',
  'tradingSession',
  'price',
  'expectedCost',
  'transactionSum',
];

export const useOrderRows = () => {
  const { translation } = useLocale();
  const { formatNumberF2, formatCurrency, formatDate } = useFormatter();

  const unknownValue = '';

  const getOrderRows = (order: Order, row: keyof Order | keyof ListedInstrument) => {
    switch (row) {
      case 'accountName':
        return (
          <OrderConfirmRow
            label={translation.orderConfirm.rows.accountName}
            value={order.accountName}
          />
        );
      case 'instrumentName':
        return (
          <OrderConfirmRow
            label={translation.orderConfirm.rows.instrumentName}
            value={order.instrument?.instrumentName}
          />
        );
      case 'isin':
        return (
          <OrderConfirmRow
            label={translation.orderConfirm.rows.isin}
            value={order.instrument?.isin}
          />
        );
      case 'currency':
        return (
          <OrderConfirmRow
            label={translation.orderConfirm.rows.currencyCode}
            value={order.instrument?.currency}
            isDivider
          />
        );
      case 'orderType':
        return (
          <OrderConfirmRow
            label={translation.orderConfirm.rows.orderType}
            value={translation.orderTypeOptions[order.orderType!]}
          />
        );
      case 'tradeType':
        return (
          <OrderConfirmRow
            label={translation.orderConfirm.rows.tradeType}
            value={translation.orderConfirm.rows.tradeTypeValues[order.tradeType!]}
          />
        );
      case 'quantity':
        return (
          <OrderConfirmRow
            label={translation.orderConfirm.rows.quantity}
            value={order.quantity + ' db'}
          />
        );
      case 'stopLimitPrice':
        return (
          <OrderConfirmRow
            label={translation.orderConfirm.rows.exchangeRate}
            value={order.stopLimitPrice ? formatNumberF2(order.stopLimitPrice!) : 'Piaci'}
          />
        );
      case 'stopActivationPrice':
        return (
          <OrderConfirmRow
            label={translation.orderConfirm.rows.stopActivationPrice}
            value={formatNumberF2(order.stopActivationPrice ?? unknownValue)}
          />
        );
      case 'expiryType':
        return (
          <OrderConfirmRow
            label={translation.orderConfirm.rows.expiryType}
            value={
              order.expiryType === ExpiryType.Gtd
                ? formatDate(order.expiry!)
                : translation.expiryOptions[order.expiryType!]
            }
          />
        );
      case 'tradingSession':
        return (
          <OrderConfirmRow
            label={translation.orderConfirm.rows.tradingSession}
            value={translation.tradingSessionOptions[order.tradingSession!]}
          />
        );
      case 'price':
        return (
          <OrderConfirmRow
            label={translation.orderConfirm.rows.price}
            value={formatCurrency(order.price ?? unknownValue, order.instrument!)}
            isDark
          />
        );
      case 'expectedCost':
        return (
          <OrderConfirmRow
            label={translation.orderConfirm.rows.dividend}
            value={formatCurrency(order.expectedCost ?? unknownValue, order.instrument!)}
            isDark
          />
        );
      case 'transactionSum':
        return (
          <OrderConfirmRow
            label={translation.orderConfirm.rows.transactionSum}
            value={formatCurrency(order.transactionSum ?? unknownValue, order.instrument!)}
            isDark
          />
        );
      default:
        throw new Error(`Unknow error: ${row}`);
    }
  };

  return { getOrderRows };
};
