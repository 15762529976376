import { Dialog } from '@mui/material';
import React, { createContext, ReactElement, useCallback, useContext, useRef, useState } from 'react';
import { ConfirmDialog } from '../shared/components/confirm-dialog/ConfirmDialog';

export type ConfirmDialogProps = {
  title?: string;
  content: string;
  confirm?: string;
  dismiss?: string;
  onConfirm?: () => Promise<void>;
  isDanger?: boolean;
};

export enum ConfirmDialogResult {
  Confirm = 'Confirm',
  Dismiss = 'Dismiss',
}

export type DialogContextProps =
  | undefined
  | {
      openDialog: <T>(modalFactory: (close: (params?: T) => void) => ReactElement) => Promise<T>;
      confirm: (props: ConfirmDialogProps) => Promise<ConfirmDialogResult>;
    };

const DialogContext = createContext<DialogContextProps>(undefined);

export const DialogProvider = ({ children }: { children: React.ReactNode }) => {
  const modalResolve = useRef<(params?: any) => void>();
  const confirmResolve = useRef<(result: ConfirmDialogResult) => void | undefined>();
  const [modal, setModal] = useState<ReactElement | undefined>();
  const [confirmDialogState, setConfirmDialogState] = useState<ConfirmDialogProps | undefined>(undefined);

  const handleModalClose = useCallback(
    (e: unknown, reason: string, params?: unknown) => {
      if (reason === 'backdropClick') {
        return;
      }
      if (!modalResolve.current) {
        throw new Error('Invalid state! Missing modalResolve');
      }
      modalResolve.current(params);
      setModal(undefined);
      modalResolve.current = undefined;
    },
    [modalResolve],
  );

  const closeModal = useCallback(
    <T,>(params: T) => {
      handleModalClose(null, '', params);
    },
    [handleModalClose],
  );

  const openDialog = useCallback(
    async <T,>(modalFactory: (close: (params?: T) => void) => ReactElement) => {
      setModal(modalFactory(closeModal));
      return new Promise<T>(resolve => {
        modalResolve.current = resolve;
      });
    },
    [closeModal],
  );

  const confirm = useCallback(async (props: ConfirmDialogProps) => {
    setConfirmDialogState(props);
    return new Promise<ConfirmDialogResult>(resolve => {
      confirmResolve.current = resolve;
    });
  }, []);

  const handleConfirmClose = useCallback(
    (args: unknown, reason: string) => {
      if (!confirmResolve.current) {
        throw new Error('Invalid state. There is no confirmResolve configured');
      }
      if (reason === 'confirm') {
        confirmResolve.current(ConfirmDialogResult.Confirm);
      } else {
        confirmResolve.current(ConfirmDialogResult.Dismiss);
      }
      setConfirmDialogState(undefined);
      confirmResolve.current = undefined;
    },
    [confirmResolve],
  );

  return (
    <DialogContext.Provider value={{ confirm, openDialog }}>
      {children}

      {modal && (
        <Dialog
          onClose={handleModalClose}
          open={!!modal}
          className="bv-dialog"
        >
          {modal}
        </Dialog>
      )}
      {confirmDialogState && (
        <ConfirmDialog
          handleClose={handleConfirmClose}
          dialogConfig={confirmDialogState}
        />
      )}
    </DialogContext.Provider>
  );
};

/**
 * Returns
 *  - confirm modal opener
 *  - generic modal opener
 *
 *  Hasznalat:
 *
 *  Confirm modal megnyitasa:
 *  ```
 *    const {confirm} = useDialog();
 *
 *
 *    const res = await confirm({
 *      title: 'Confirm',
 *      content: 'Hello Sir! Are you sure you want to continue?',
 *      confirm: 'Yes',
 *      dismiss: 'No',
 *    });
 *    console.log(res); // ConfirmDialogResult.Confirm / ConfirmDialogResult.Reject
 *  ```
 *
 *  Modal megnyitasa
 *   ```
 *    const {openDialog} = useDialog();
 *
 *    const res = await openDialog<boolean>((close: (params: boolean) => void) => (
 *      <>
 *        <DialogTitle>Hello!</DialogTitle>
 *        <DialogContent>
 *          <form>
 *            ...
 *           </form>
 *        </DialogContent>
 *        <DialogActions>
 *          <Button onClick={() => close(true)}>Most mar bezarhatod</Button>
 *        </DialogActions>
 *      </>
 *    ))
 *  ```
 */
export const useDialog = () => {
  const context = useContext(DialogContext);
  if (context === undefined) {
    throw new Error(`useDialog must be used within a DialogProvider`);
  }
  return context;
};
