import { useEffect, useState } from 'react';
import { ListedInstrument } from '../../../shared/types/listedInstrument';
import { WidgetGroup } from '../../../shared/types/widgetGroup';
import { useWidget } from './useWidget';

export const useWidgetInstrument = (widgetGroup: WidgetGroup) => {
  const [instrument, setInstrument] = useState(widgetGroup.instrument);
  const { updateWidgetGroupState } = useWidget();

  const updateInstrument = (instrument: ListedInstrument | null) => {
    setInstrument(instrument);
    updateWidgetGroupState(widgetGroup.id, { instrument: instrument });
  };

  useEffect(() => {
    if (widgetGroup.instrument !== instrument) {
      setInstrument(widgetGroup.instrument);
    }
  }, [widgetGroup.instrument, instrument]);

  return { updateInstrument, instrument };
};
