import { CIQ } from 'chartiq/js/advanced';
import { useEffect, useLayoutEffect, useRef } from 'react';
import { CqContextElement } from '../../../shared/components/chartIq/ChartIQ';
import { WidgetElement } from '../../../shared/types/widgetElement';
import { useWidgetGroup } from '../services/useWidgetGroup';

// @ts-ignore
import feed from 'chartiq/examples/feeds/optionChainSimulator';
// @ts-ignore
import forecastfeed from 'chartiq/examples/feeds/quoteFeedForecastSimulator.js';
// @ts-ignore
import marker from 'chartiq/examples/markers/markersSample';
// @ts-ignore
import defaultConfiguration from 'chartiq/js/defaultConfiguration';

// @ts-ignore
import EmojiPopover from 'chartiq/js/thirdparty/emoji-popover.es.js';
// @ts-ignore
import PerfectScrollbar from 'chartiq/js/thirdparty/perfect-scrollbar.esm.js';
import { Chart } from '../../../shared/components/chartIq/Chart';
import ChartWidgetTemplate from '../../../shared/components/chartIq/ChartWidgetTemplate';
import { UserSetting, UserSettingsResource } from '../../../shared/resources/userSettings.resource';
import { useResource } from '../../../shared/services/useResource';

export const ChartWidget = (props: WidgetElement) => {
  const widgetGroup = useWidgetGroup(props.groupId);
  const userSettingsResource = useResource(UserSettingsResource);

  const chartContainer = useRef<CqContextElement>();
  const stxRef = useRef<CIQ.ChartEngine>();

  useLayoutEffect(() => {
    if (chartContainer.current && !stxRef.current) {
      const container = chartContainer.current;
      const config = defaultConfiguration({
        markerFeed: marker.MarkerSample,
        scrollStyle: PerfectScrollbar,
        emojiPicker: EmojiPopover,
        quoteFeed: feed,
        forecaseQuoteFeed: forecastfeed,
        nameValueStore: CIQ.NameValueStore,
      });

      config.themes.defaultTheme = 'cig-day';

      config.enabledAddOns.forecasting = true;
      config.enabledAddOns.fullScreen = false;

      config.restore = true;
      config.onEngineReady = (stx: CIQ.ChartEngine) => {
        const chartLayout = userSettingsResource.getChartLayout();
        stx.importLayout(chartLayout);

        stx.addEventListener('layout', () => {
          const layout = stx.exportLayout(true);
          userSettingsResource.updateUserSetting(UserSetting.ChartLayout, layout);
        });

        stx.addEventListener('drawing', () => {
          userSettingsResource.updateChartDrawings(stx.chart.symbol, stx.exportDrawings());
        });

        stx.addEventListener('symbolImport', () => {
          const drawings = userSettingsResource.getChartDrawings(stx?.chart.symbol);
          stx.importDrawings(drawings);
          stx.draw();
        });
      };

      config.rangeMenu = config.rangeMenu.map((range: any) => ({ type: 'item', label: range.label, cmd: range.cmd }));

      stxRef.current = config.createChart(container);
    }
  }, [chartContainer, stxRef, userSettingsResource]);

  useEffect(() => {
    const stx = stxRef.current;
    if (stx && widgetGroup.instrument?.instrumentName) {
      stx.loadChart(widgetGroup.instrument.instrumentName);
    }
  }, [widgetGroup.instrument, stxRef]);

  return (
    <>
      <Chart
        ref={chartContainer}
        template={<ChartWidgetTemplate />}
      />
    </>
  );
};
