import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { routes } from '../shared/data/routes';
import { useAuth } from '../shared/services/useAuth';
import { AuthState } from '../shared/state/authStateAtom';

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const { appAuth, checkIfLoggedIn, initializeApp } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    (async () => {
      if (appAuth.isAuthenticated && appAuth.authStatus === AuthState.Loading) {
        await initializeApp();
        if (location.pathname === routes.login) {
          navigate(routes.home, { replace: true });
        }
      }
    })();
  }, [location, appAuth, navigate, initializeApp]);

  useEffect(() => {
    (async () => {
      const isLoggedIn = await checkIfLoggedIn();
      if (appAuth.authStatus !== AuthState.Loading && !isLoggedIn && location.pathname !== routes.login) {
        navigate(routes.login, { replace: true });
      }
    })();
    // exclude checkIfLoggedIn from dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appAuth.authStatus, location, navigate]);

  return <>{children}</>;
};
