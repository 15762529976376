import InfoIcon from '@mui/icons-material/Info';
import SearchIcon from '@mui/icons-material/Search';
import { Box, LinearProgress, Popper, PopperProps } from '@mui/material';
import { AutocompleteRenderOptionState } from '@mui/material/Autocomplete/Autocomplete';
import { SyntheticEvent, useEffect, useState } from 'react';
import { useLocale } from '../../../context/NbLocalizationContext';
import { useInstrumentSearch } from '../../services/useInstrumentSearch';
import { ListedInstrument } from '../../types/listedInstrument';
import { useDebounce } from '../../utils/useDebounce';
import { NbAutocomplete, NbAutocompleteProps } from '../autocomplete/Autocomplete';
import { InstrumentResultRow } from '../instrument-result-row/InstrumentResultRow';
import classes from './InstrumentSearchAutocomplete.module.scss';

export type InstrumentSearchAutocompleteProps = Omit<NbAutocompleteProps<ListedInstrument>, 'options'> & {
  selection: ListedInstrument | null;
  onSelectInstrument?: (value: ListedInstrument | null) => void;
  isPopperWide?: boolean;
  clearSelectionAfterSelect?: boolean;
};

export const InstrumentSearchAutocomplete = ({
  onSelectInstrument,
  isPopperWide,
  selection,
  clearSelectionAfterSelect,
  ...props
}: InstrumentSearchAutocompleteProps) => {
  const [options, setOptions] = useState<Array<ListedInstrument>>([]);
  const [inputValue, setInputValue] = useState('');
  const [selectedInstrument, setSelectedInstrument] = useState<ListedInstrument | null>(selection || null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const popperOverrides = isPopperWide
    ? {
        PopperComponent: (props: PopperProps) => (
          <Popper
            {...props}
            style={{ width: 350 }}
            placement="bottom-start"
          />
        ),
      }
    : {};

  const { translation } = useLocale();
  const { searchInstrument } = useInstrumentSearch();
  const { debounce, isLoading } = useDebounce(setOptions);

  const openOptionsPopup = () => {
    if (inputValue.length > 0 && options.length > 0) {
      openPopup();
    }
  };

  const changeSelection = (e: SyntheticEvent<Element, Event>, value: ListedInstrument | null) => {
    if (!clearSelectionAfterSelect) {
      setSelectedInstrument(value);
    } else {
      setInputValue('');
      setSelectedInstrument(null);
      setOptions([]);
    }

    if (onSelectInstrument) {
      onSelectInstrument(value);
    }
    closePopup();
  };

  const openPopup = () => {
    setIsPopupOpen(true);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
  };

  useEffect(() => {
    if (!inputValue.trim()) {
      setOptions([]);
      closePopup();
    } else if (selectedInstrument?.ric !== inputValue) {
      debounce(async () => {
        return searchInstrument(inputValue.trim());
      });
    }
  }, [inputValue, debounce, searchInstrument]);

  useEffect(() => {
    if (!inputValue.trim() || selectedInstrument?.ric === inputValue) {
      closePopup();
    } else {
      if (!isLoading && options.length > 0) {
        openPopup();
      }
    }
  }, [options, inputValue]);

  useEffect(() => {
    setSelectedInstrument(selection);
    setOptions([]);
    closePopup();
  }, [selection]);

  return (
    <>
      <NbAutocomplete
        {...props}
        open={isPopupOpen}
        onOpen={openOptionsPopup}
        onClose={() => setIsPopupOpen(false)}
        size={'small'}
        getOptionLabel={option => (typeof option === 'string' ? option : option.ric)}
        isOptionEqualToValue={(option, value) => option.ric === value?.ric}
        filterOptions={options => options}
        options={options}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => setInputValue(newInputValue)}
        value={selectedInstrument}
        onChange={changeSelection}
        renderOption={(renderOptionProps, option: ListedInstrument, state: AutocompleteRenderOptionState) => (
          <Box
            component="li"
            sx={{ padding: '0', margin: '0' }}
            {...renderOptionProps}
          >
            <InstrumentResultRow
              isWithActions={false}
              instrument={option}
              key={option.ric}
              isSelected={state.selected}
            />
          </Box>
        )}
        {...popperOverrides}
        classes={{ paper: classes.searchResultPaper }}
        inputProps={{
          InputProps: { startAdornment: <SearchIcon />, className: classes.searchInput },
          ...props.inputProps,
        }}
        noOptionsText={
          inputValue.length > 0 ? (
            <div className={classes.noOption}>
              <InfoIcon /> {translation.instrument.search.noResult}
            </div>
          ) : (
            <span></span>
          )
        }
      />
      {isLoading && <LinearProgress sx={{ top: '-4px' }} />}
    </>
  );
};
