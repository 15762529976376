import CloseIcon from '@mui/icons-material/Close';
import { LinearProgress } from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useDialog } from '../../../context/DialogContext';
import { useLocale } from '../../../context/NbLocalizationContext';
import { OrderModal } from '../../../modules/trading/components/OrderModal';
import { PriceAlertEditModal } from '../../../modules/trading/components/PriceAlertEditModal';
import { useInstrumentSearch } from '../../services/useInstrumentSearch';
import { ListedInstrument } from '../../types/listedInstrument';
import { useDebounce } from '../../utils/useDebounce';
import { NbIconButton } from '../icon-button/IconButton';
import { InstrumentResultRow } from '../instrument-result-row/InstrumentResultRow';
import { NbTextField } from '../text-field/TextField';
import classes from './InstrumentSearchModal.module.scss';

export interface InstrumentSearchModalProps {
  onSelect: (instrument: ListedInstrument) => void;
  onCancel: () => void;
}

export const InstrumentSearchModal = ({ onSelect, onCancel }: InstrumentSearchModalProps) => {
  const { translation } = useLocale();
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [resultList, setResultList] = useState<Array<ListedInstrument>>();

  const { debounce, isLoading } = useDebounce(setResultList);
  const { searchInstrument } = useInstrumentSearch();
  const { openDialog } = useDialog();
  const onSearch = async (text: string) => {
    setSearchTerm(text);
  };

  const handleClickOnOrder = useCallback(
    async (instrument: ListedInstrument) => {
      await openDialog((close: () => void) => (
        <OrderModal
          order={{
            instrument,
          }}
          onCancel={() => close()}
        />
      ));
    },
    [openDialog],
  );

  const handleClickOnNotification = async (instrument: ListedInstrument) => {
    await openDialog((close: () => void) => (
      <PriceAlertEditModal
        onCancel={() => close()}
        instrument={instrument}
      />
    ));
  };

  useEffect(() => {
    if (!searchTerm.trim()) {
      setResultList([]);
    } else {
      debounce(async () => {
        return searchInstrument(searchTerm);
      });
    }
  }, [searchTerm, debounce, searchInstrument]);

  return (
    <div className={classes.search}>
      <div className={classes.header}>
        <h2>{translation.instrument.search.title}</h2>
        <NbIconButton
          icon={<CloseIcon />}
          onClick={onCancel}
        />
      </div>

      <div className={classes.input}>
        <NbTextField
          size={'small'}
          autoFocus
          value={searchTerm}
          onChange={e => onSearch(e.target.value)}
          placeholder={translation.instrument.search.instruments}
        />
      </div>

      {isLoading && <LinearProgress className={classes.progressBar} />}

      <div className={classes.listWrapper}>
        <ul className={classes.list}>
          {resultList?.map(instrument => (
            <li key={instrument.ric}>
              <InstrumentResultRow
                isWithActions={true}
                instrument={instrument}
                onClick={() => (onSelect ? onSelect(instrument) : {})}
                onOrderClick={handleClickOnOrder}
                onNotificationClick={handleClickOnNotification}
              />
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
