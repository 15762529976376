import { WidgetColumn } from '../../../shared/types/widgetColumn';
import { WidgetConfigState } from '../../../shared/types/widgetConfigState';
import { WidgetWrapper } from '../components/WidgetWrapper';
import classes from './WidgetView.module.scss';

export type WidgetViewProps = {
  widgetConfigState: WidgetConfigState;
};

export const WidgetView = ({ widgetConfigState }: WidgetViewProps) => {
  const renderWidgetContainer = (columns: WidgetColumn[]) => {
    return columns.map((column, colIndex, colArray) => (
      <div
        key={colIndex}
        className={classes.widgetViewColumn}
        style={{
          maxWidth: `calc(${(100 / colArray.reduce((prev, curr) => curr.width + prev, 0)) * column.width}% - ${
            colIndex === colArray.length - 1 ? '0px' : classes.widgetGap
          })`,
        }}
      >
        {column.rows.map((row, rowIndex, rowArray) => (
          <div
            key={rowIndex}
            className={classes.widgetViewRow}
            style={{
              minHeight: `calc(${(100 / rowArray.reduce((prev, curr) => curr.height + prev, 0)) * row.height}% - ${
                rowIndex === rowArray.length - 1 ? '0px' : classes.widgetGap
              })`,
              maxHeight: `calc(${(100 / rowArray.reduce((prev, curr) => curr.height + prev, 0)) * row.height}% - ${
                rowIndex === rowArray.length - 1 ? '0px' : classes.widgetGap
              })`,
            }}
          >
            {Array.isArray(row.columns) ? (
              renderWidgetContainer(row.columns)
            ) : (
              <WidgetWrapper
                widgetContainer={row.columns}
                key={row.columns.id}
              />
            )}
          </div>
        ))}
      </div>
    ));
  };

  return (
    <>
      <div className={classes.widgetView}>{renderWidgetContainer(widgetConfigState.containers)}</div>
    </>
  );
};
