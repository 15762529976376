import {
  Configuration,
  DefaultApi as PartnerDataApi,
  DefaultApiGetPartnerAvaiableSubscriptionsRequest as PartnerDataApiGetPartnerAvaiableSubscriptionsRequest,
  Subscription,
} from '@eih-netbroker/api-partner-data';
import { AxiosInstance, AxiosResponse } from 'axios';
import '../mock/resources/mockedPartnerData.resource';
import { ResourceHeaders } from '../services/useResource';
import { BaseResource } from './base.resource';

export type GetPartnerAvaiableSubscriptionsParams = Omit<
  PartnerDataApiGetPartnerAvaiableSubscriptionsRequest,
  keyof ResourceHeaders
>;

export class PartnerDataResource extends BaseResource {
  // Name has to be overriden, since the build job renames it to 'r' and
  // every resource class will have the same name
  // @ts-ignore
  static readonly name = 'PartnerDataResource';

  private partnerDataApi: PartnerDataApi;

  constructor(axiosInstance: AxiosInstance, basePath: string) {
    super(axiosInstance, basePath);
    this.partnerDataApi = new PartnerDataApi(new Configuration(), basePath, axiosInstance);
  }

  getPartnerAvailableSubscriptions(
    request: GetPartnerAvaiableSubscriptionsParams,
    headers: ResourceHeaders,
  ): Promise<AxiosResponse<Array<Subscription>>> {
    return this.partnerDataApi.getPartnerAvaiableSubscriptions({ ...request, ...headers });
  }
}
