import { Popover } from '@mui/material';
import { GridRowModel } from '@mui/x-data-grid';
import { GridRowOrderChangeParams } from '@mui/x-data-grid-pro';
import { MouseEvent, useCallback, useEffect, useState } from 'react';
import { useLocale } from '../../../context/NbLocalizationContext';
import { NbDataGrid } from '../../../shared/components/data-grid/DataGrid';
import { NbLoader } from '../../../shared/components/loader/Loader';
import { WatchListGroup, WatchListInstrument } from '../../../shared/state/watchListStateAtom';
import { TradeType } from '../../../shared/types/tradeType';
import { useWatchListColumns } from '../services/useWatchListColumns';
import { WatchListContextMenu } from './WatchListContextMenu';
import { default as classes } from './WatchListGrid.module.scss';

export interface WatchListGridProps {
  watchListGroup: WatchListGroup;
  onDeleteWatchListInstrument: (watchListInstrument: WatchListInstrument) => void;
  onOrderWatchListInstrument: (watchListInstrument: WatchListInstrument, tradeType?: TradeType) => void;
  onNotifyWatchListInstrument: (watchListInstrument: WatchListInstrument) => void;
  isEditMode: boolean;
  isWidgetMode: boolean;
}

function updateRowPosition(initialIndex: number, newIndex: number, rows: Array<GridRowModel>): Promise<any> {
  return new Promise(resolve => {
    setTimeout(() => {
      const rowsClone = [...rows];
      const row = rowsClone.splice(initialIndex, 1)[0];
      rowsClone.splice(newIndex, 0, row);
      resolve(rowsClone);
    }, Math.random() * 500 + 100); // simulate network latency
  });
}

export const WatchListGrid = ({
  watchListGroup,
  isEditMode,
  isWidgetMode,
  onDeleteWatchListInstrument,
  onOrderWatchListInstrument,
  onNotifyWatchListInstrument,
}: WatchListGridProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [rows, setRows] = useState(watchListGroup.items);
  const { translation } = useLocale();
  const { getWatchListColumn, getWatchListFlatActions } = useWatchListColumns();
  const [contextMenuPopover, setContextMenuPopover] = useState<HTMLButtonElement | null>(null);
  const [selectedInstrument, setSelectedInstrument] = useState<WatchListInstrument | null>(null);

  const deleteWatchListInstrument = useCallback(
    (_event: MouseEvent<HTMLButtonElement>, row: WatchListInstrument) => {
      onDeleteWatchListInstrument(row);
    },
    [onDeleteWatchListInstrument],
  );

  const orderWatchListInstrument = useCallback(
    (_event: MouseEvent<HTMLButtonElement | HTMLSpanElement>, row: WatchListInstrument, tradeType?: TradeType) => {
      onOrderWatchListInstrument(row, tradeType);
    },
    [onOrderWatchListInstrument],
  );

  const notifyWatchListInstrument = async (_event: MouseEvent<HTMLButtonElement>, row: WatchListInstrument) => {
    await onNotifyWatchListInstrument(row);
  };

  const moreActions = useCallback(
    (event: MouseEvent<HTMLButtonElement>, row: WatchListInstrument) => {
      setContextMenuPopover(event.currentTarget);
      setSelectedInstrument(row);
    },
    [setContextMenuPopover],
  );

  const getColumns = () => {
    if (!watchListGroup.columns) {
      return [];
    }

    return watchListGroup.columns
      .map(column =>
        getWatchListColumn(column.field, {
          onOrder: orderWatchListInstrument,
        }),
      )
      .concat(
        getWatchListFlatActions(translation, isEditMode, isWidgetMode, {
          onDelete: deleteWatchListInstrument,
          onOrder: orderWatchListInstrument,
          onNotify: notifyWatchListInstrument,
          onMore: moreActions,
        }),
      );
  };

  const columns = getColumns();

  const handleRowOrderChange = async (params: GridRowOrderChangeParams) => {
    setIsLoading(true);
    const newRows = await updateRowPosition(params.oldIndex, params.targetIndex, rows);

    setRows(newRows);
    setIsLoading(false);
  };

  useEffect(() => {
    setRows(watchListGroup.items);
  }, [watchListGroup.items]);

  return (
    <div className={classes.nbWatchListGrid}>
      {isLoading && <NbLoader />}
      <NbDataGrid
        getRowId={row => row.instrument.ticker}
        rows={rows}
        columns={columns}
        initialState={{ pinnedColumns: { right: ['actions'] } }}
        rowReordering={isEditMode}
        onRowOrderChange={handleRowOrderChange}
        autoHeight={false}
      />
      <Popover
        open={!!contextMenuPopover}
        anchorEl={contextMenuPopover}
        onClose={() => setContextMenuPopover(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        classes={{ paper: classes.popover }}
      >
        <WatchListContextMenu
          instrument={selectedInstrument}
          onDeleteWatchlistInstrument={onDeleteWatchListInstrument}
          onOrderWatchListInstrument={onOrderWatchListInstrument}
          onNotifyWatchListInstrument={onNotifyWatchListInstrument}
        />
      </Popover>
    </div>
  );
};
