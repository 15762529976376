import CloseIcon from '@mui/icons-material/Close';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import EditIcon from '@mui/icons-material/Edit';
import { GridActionsCellItem, GridRowParams } from '@mui/x-data-grid';
import { GridColDef } from '@mui/x-data-grid-pro';
import { MouseEvent } from 'react';
import { ReactComponent as BearishIcon } from '../../../assets/svg/bearish.svg';
import { ReactComponent as BullishIcon } from '../../../assets/svg/bullish.svg';
import { useLocale } from '../../../context/NbLocalizationContext';
import { useFormatter } from '../../../shared/services/useFormatter';
import { WithoutId } from '../../../shared/types/withoutId';
import gridClasses from '../components/PriceAlertsWidget.module.scss';
import { PriceAlert, SentimentType, TriggerState } from '../states/priceAlertsAtom';

export const priceAlertsItemFields: Array<keyof WithoutId<PriceAlert>> = [
  'instrument',
  'trigger',
  'price',
  'sentiment',
];

export type PriceAlertsColumn = {
  field: keyof WithoutId<PriceAlert>;
};

export interface PriceAlertsGridActions {
  onEditAlert: (event: MouseEvent<HTMLButtonElement>, row: any) => void;
  onDeleteAlert: (event: MouseEvent<HTMLButtonElement>, row: any) => void;
}

export const allColumns: Array<PriceAlertsColumn> = priceAlertsItemFields.map(field => ({ field }));

export const usePriceAlertsColumns = () => {
  const { translation } = useLocale();
  const { formatNumberF2 } = useFormatter();

  const getPriceAlertsColumn = (column: keyof WithoutId<PriceAlert>): GridColDef<PriceAlert> => {
    const baseColDef: GridColDef<PriceAlert> = {
      field: column,
      headerName: translation.priceAlerts.grid[column],
      sortable: false,
      disableColumnMenu: true,
    };

    switch (column) {
      case 'instrument':
        return {
          ...baseColDef,
          width: 85,
          align: 'left',
          renderCell: params => {
            return <span>{params.row.instrument.ticker}</span>;
          },
        };
      case 'trigger':
        return {
          ...baseColDef,
          width: 80,
          align: 'center',
          renderCell: (params: any) => {
            return (
              <span>
                {params.row.trigger === TriggerState.GreaterThanEqual ? '>=' : TriggerState.LessThanEqual ? '<=' : ''}
              </span>
            );
          },
        };
      case 'price':
        return {
          ...baseColDef,
          width: 100,
          align: 'right',
          renderCell: params => {
            return <span>{formatNumberF2(params.row.price)}</span>;
          },
        };
      case 'sentiment':
        return {
          ...baseColDef,
          width: 85,
          align: 'center',
          renderCell: params => {
            return (
              <span>
                {params.row.sentiment === SentimentType.Bearish ? (
                  <BearishIcon />
                ) : SentimentType.Bullish ? (
                  <BullishIcon />
                ) : (
                  ''
                )}
              </span>
            );
          },
        };

      default:
        throw new Error(`Invalid column ${column}`);
    }
  };

  const getPriceAlertsFlatIcons = (actions: PriceAlertsGridActions): GridColDef<PriceAlert> => {
    return {
      field: 'actions',
      type: 'actions',
      getActions: (params: GridRowParams<PriceAlert>) => [
        <GridActionsCellItem
          className={gridClasses.actionsEdit}
          icon={Math.random() > 0.5 ? <EditIcon /> : <DriveFileRenameOutlineIcon />}
          label={translation.priceAlerts.editAlert}
          onClick={e => actions.onEditAlert(e, params.row)}
        />,
        <GridActionsCellItem
          className={gridClasses.actionsDelete}
          icon={<CloseIcon />}
          label={translation.priceAlerts.deleteAlert}
          onClick={e => actions.onDeleteAlert(e, params.row)}
        />,
      ],
    };
  };

  return { getPriceAlertsColumn, getPriceAlertsFlatIcons };
};
