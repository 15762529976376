import { Account } from '@eih-netbroker/api-account';
import { useRecoilState } from 'recoil';
import { SessionResource } from '../resources/session.resource';
import { accountsAtom, Client } from '../state/accountsAtom';
import { consentsAtom } from '../state/consentsAtom';
import { dictionariesAtom } from '../state/dictionariesAtom';
import { useResource, useResourceHeaders } from './useResource';

export const useSession = () => {
  const resourceHeaders = useResourceHeaders();
  const [, setAccounts] = useRecoilState(accountsAtom);
  const [, setDictionaries] = useRecoilState(dictionariesAtom);
  const [, setConsents] = useRecoilState(consentsAtom);

  const sessionResource = useResource(SessionResource);

  const mapAccountToClient = (accounts: Array<Account>) =>
    accounts.reduce<Array<Client>>((acc: Array<Client>, curr: Account) => {
      const clientIndex = acc.findIndex(a => a.clientId === curr.clientId);
      if (clientIndex === -1) {
        acc.push({
          clientId: curr.clientId!,
          clientName: curr.clientName!,
          accounts: [
            {
              accountCategory: curr.accountCategory!,
              accountNumber: curr.accountNumber!,
              accountProxyType: curr.accountProxyType!,
              accountAlias: curr.accountAlias!,
            },
          ],
        });
      } else {
        acc[clientIndex].accounts.push({
          accountCategory: curr.accountCategory!,
          accountNumber: curr.accountNumber!,
          accountProxyType: curr.accountProxyType!,
          accountAlias: curr.accountAlias!,
        });
      }

      return acc;
    }, []);

  const sessionInit = async () => {
    const response = await sessionResource.sessionInit({}, resourceHeaders);

    if (response.data.accounts) {
      const clients: Array<Client> = mapAccountToClient(response.data.accounts);
      setAccounts({
        clients: clients,
      });
    }

    if (response.data.consents) {
      setConsents(response.data.consents);
    }

    if (response.data.dictionaries) {
      setDictionaries(response.data.dictionaries);
    }
  };

  return { sessionInit };
};
