import { InstrumentInstumentCategoryEnum } from '@eih-netbroker/api-instrument';
import { GridRowParams } from '@mui/x-data-grid';
import { DataGridProProps, GridColDef, GRID_DETAIL_PANEL_TOGGLE_COL_DEF } from '@mui/x-data-grid-pro';
import { MouseEvent, useCallback, useMemo } from 'react';
import { useTheme } from '../../../context/NbThemeContext';
import { NbDataGrid } from '../../../shared/components/data-grid/DataGrid';
import { Config } from '../../../shared/config/config';
import { CustomDetailPanelToggle } from '../../trading/components/CustomDetailPanelToggle';
import { allColumns, usePortfolioCompositionColumns } from '../services/usePortfolioCompositionColumns';
import { PortfolioCompositionGridItem } from '../types/portfolioCompositionItem';
import classes from './PortfolioCompositionGrid.module.scss';

export interface PortfolioCompositionGridProps {
  onAction: (event: MouseEvent<HTMLButtonElement>, row: any) => void;
  onPortfolioItemSelected: (item: PortfolioCompositionGridItem) => void;
  rows: Array<PortfolioCompositionGridItem>;
  assetColors: Record<InstrumentInstumentCategoryEnum, string>;
  enableDetailPanel: boolean;
}

export const PortfolioCompositionGrid = ({
  onAction,
  rows,
  onPortfolioItemSelected,
  assetColors,
  enableDetailPanel,
}: PortfolioCompositionGridProps) => {
  const { rem } = useTheme();
  const { getPortfolioCompositionColumn, getPortfolioCompositionFlatIcons } = usePortfolioCompositionColumns();

  const getInstrumentColor = useCallback(
    (item: PortfolioCompositionGridItem): string => {
      return assetColors[item.instrumentCategory as unknown as InstrumentInstumentCategoryEnum] || '#ff0099';
    },
    [assetColors],
  );

  const columns: Array<GridColDef<PortfolioCompositionGridItem>> = useMemo(() => {
    const newColumns = [...allColumns]
      .map(column => getPortfolioCompositionColumn(column.field, enableDetailPanel ? undefined : getInstrumentColor))
      .concat(getPortfolioCompositionFlatIcons({ onAction })) as Array<GridColDef<PortfolioCompositionGridItem>>;

    if (enableDetailPanel) {
      newColumns.unshift({
        ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
        renderCell: params => (
          <span className={classes.groupTickerToggleContainer}>
            <span
              className={classes.groupTickerToggleItem}
              style={{ backgroundColor: getInstrumentColor(params.row) }}
            />
            {params.row.subElements && params.row.subElements.length > 0 && <CustomDetailPanelToggle {...params} />}
          </span>
        ),
      });
    }
    return newColumns;
  }, [
    getPortfolioCompositionColumn,
    getPortfolioCompositionFlatIcons,
    onAction,
    enableDetailPanel,
    getInstrumentColor,
  ]);

  const subColumns: Array<GridColDef<PortfolioCompositionGridItem>> = useMemo(() => {
    return [...allColumns]
      .map(column => getPortfolioCompositionColumn(column.field, undefined, true))
      .concat(getPortfolioCompositionFlatIcons({ onAction })) as Array<GridColDef<PortfolioCompositionGridItem>>;
  }, [getPortfolioCompositionColumn, getPortfolioCompositionFlatIcons, onAction]);

  const getDetailPanelHeight = useCallback(
    (rowParams: GridRowParams<PortfolioCompositionGridItem>) =>
      rowParams.row.subElements ? rowParams.row.subElements.length * rem(Config.Layout.grid.rowHeight) + 3 : 0,
    [rem],
  );

  const getDetailPanelContent = useCallback<NonNullable<DataGridProProps['getDetailPanelContent']>>(
    ({ row }: { row: PortfolioCompositionGridItem }) => (
      <div style={{ paddingLeft: `${rem(Config.Layout.grid.toggleColumnWidth)}px` }}>
        <NbDataGrid
          getRowId={row => row.id}
          columns={subColumns}
          rows={row.subElements || []}
          columnHeaderHeight={0}
          initialState={{ pinnedColumns: { right: ['actions'] } }}
          sx={{ '& .MuiDataGrid-cell': { border: 'none' } }}
          alternateRowColor={false}
        />
      </div>
    ),
    [subColumns, rem],
  );

  return (
    <div className={classes.gridContainer}>
      <NbDataGrid<PortfolioCompositionGridItem>
        classes={{ root: classes.portfolioCompositionGrid }}
        getRowId={row => row.id}
        rows={rows}
        columns={columns}
        initialState={{ pinnedColumns: { right: ['actions'] } }}
        onRowClick={event => onPortfolioItemSelected(event.row)}
        getDetailPanelHeight={enableDetailPanel ? getDetailPanelHeight : undefined}
        getDetailPanelContent={enableDetailPanel ? getDetailPanelContent : undefined}
        autoHeight={false}
        alternateRowColor={false}
      />
    </div>
  );
};
