import { atom } from 'recoil';
import { ListedInstrument } from '../../../shared/types/listedInstrument';
import { OrderType } from '../../../shared/types/orderType';

export enum LogType {
  Create = 'Create',
  Edit = 'Edit',
  Cancel = 'Cancel',
  Fulfillment = 'Fulfillment',
  Rejection = 'Rejection',
}

export type OrderLogSubElements = {
  instrument: ListedInstrument;
  tradeType: string;
  orderType: OrderType;
  price: number;
  quantity: number;
  validity: string;
  stopPrice: number;
};

export type OrderLog = {
  event: LogType;
  tradeType: string;
  instrument: ListedInstrument;
  orderId: string;
  account: string;
  date: Date;
  subElements?: Array<OrderLogSubElements>;
};

export type OrderLogs = {
  orderLogs: Array<OrderLog>;
};

export const orderLogsAtom = atom<OrderLogs>({
  key: 'trading/orderLogs',
  default: undefined,
});
