import { SentimentType, TriggerState } from '../../modules/trading/states/priceAlertsAtom';
import { ListedInstrument } from '../types/listedInstrument';
import { mockedInstruments } from './mockedInstruments';

const mockPriceAlerts = (instrument: ListedInstrument) => {
  return {
    instrument,
    trigger: Math.random() > 0.5 ? TriggerState.GreaterThanEqual : TriggerState.LessThanEqual,
    price: Math.random() * 100,
    sentiment: Math.random() > 0.5 ? SentimentType.Bullish : SentimentType.Bearish,
  };
};

export const mockedPriceAlerts = {
  priceAlerts: [
    mockPriceAlerts(mockedInstruments[0]),
    mockPriceAlerts(mockedInstruments[1]),
    mockPriceAlerts(mockedInstruments[2]),
    mockPriceAlerts(mockedInstruments[3]),
  ],
};
