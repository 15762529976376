import CloseIcon from '@mui/icons-material/Close';
import InfoIcon from '@mui/icons-material/Info';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { nanoid } from 'nanoid';
import { useState } from 'react';
import { useLocale } from '../../../context/NbLocalizationContext';
import { NbButton } from '../../../shared/components/button/Button';
import { NbIconButton } from '../../../shared/components/icon-button/IconButton';
import { NbSelect } from '../../../shared/components/select/Select';
import { NbSentimentSelector } from '../../../shared/components/sentiment-selector/SentimentSelector';
import { NbTextField } from '../../../shared/components/text-field/TextField';
import { Config } from '../../../shared/config/config';
import { useOptions } from '../../../shared/services/useOptions';
import { ListedInstrument } from '../../../shared/types/listedInstrument';
import classes from './PriceAlertEditModal.module.scss';
import { WidgetInstrumentSearchAndDisplay } from './WidgetInstrumentSearchAndDisplay';

export interface PriceAlertEditModalProps {
  onCancel: () => void;
  instrument?: ListedInstrument | null;
}

export const PriceAlertEditModal = ({ onCancel, instrument }: PriceAlertEditModalProps) => {
  const { translation } = useLocale();

  const [selectedInstrument, setSelectedInstrument] = useState<ListedInstrument | null>(instrument || null);
  const [note, setNote] = useState<string>('');
  const { triggerStateOptions } = useOptions();

  const updateNote = (text: string) => {
    if (text.length <= Config.PriceAlertCharacterCount) {
      setNote(text);
    }
  };

  return (
    <div className={classes.edit}>
      <div className={classes.editHeader}>
        <h4>{translation.priceAlerts.modal.title}</h4>
        <NbIconButton
          onClick={onCancel}
          icon={<CloseIcon />}
        />
      </div>

      <div className={classes.editSearch}>
        <WidgetInstrumentSearchAndDisplay
          source={`price-alert-edit-modal-${nanoid()}`}
          instrument={selectedInstrument || null}
          updateInstrument={setSelectedInstrument}
          showPriceChanges={true}
        />
      </div>

      <div className={classes.editContent}>
        <div className={classes.editRow}>
          <p>{translation.priceAlerts.modal.stopPriceCondition}</p>
          <NbSelect
            options={triggerStateOptions}
            size={'small'}
          />
        </div>

        <div className={classes.editRow}>
          <p>{translation.priceAlerts.modal.exchangeRate}</p>
          <NbTextField size={'small'} />
        </div>

        <div className={classes.editRow}>
          <p>{translation.priceAlerts.modal.sentiment}</p>
          <div className={classes.editSentimentWrapper}>
            <NbSentimentSelector />
          </div>
        </div>

        <div className={classes.editRow}>
          <p>{translation.priceAlerts.modal.note}</p>
          <p className={classes.editCharCount}>
            {Config.PriceAlertCharacterCount} {translation.priceAlerts.modal.character} /{' '}
            {Config.PriceAlertCharacterCount - note.length}
          </p>
        </div>

        <NbTextField
          className={classes.editNote}
          placeholder={translation.priceAlerts.modal.note}
          value={note}
          onChange={e => updateNote(e.target.value)}
          multiline
          rows={3}
        />

        <div className={classes.editInfo}>
          <InfoIcon />
          {translation.priceAlerts.modal.info}
        </div>

        <div className={classes.editManagementButton}>
          <NbButton variant={'text'}>
            {translation.priceAlerts.modal.exchangeRateManagement}
            <KeyboardArrowRightIcon />
          </NbButton>
        </div>
      </div>

      <div className={classes.editButtons}>
        <NbButton
          color={'secondary'}
          onClick={onCancel}
        >
          {translation.general.cancel}
        </NbButton>
        <NbButton color={'primary'}>{translation.general.save}</NbButton>
      </div>
    </div>
  );
};
