import { nanoid } from 'nanoid';
import { uniqueArray } from '../../utils/arrayUtils';
import { ObjectSet } from '../../utils/objectSet';
import { getInstrumentId, ListedInstrument } from '../listedInstrument';
import { EStreamTradeDTO } from './eStreamTrade';

/**
 * Internal NetBroker-web store's type for instrument data
 */
export type InstrumentStoreItem = {
  ric: string;
  mic: string;

  ticker?: string;
  closePrice?: number;
  lastPrice?: number;
  lastTradeTime?: Date;
  lastTradeQty?: number;

  book?: {
    asks: Array<[number, number]>;
    bids: Array<[number, number]>;
  };
  details?: {
    low: number;
    high: number;
    averagePrice: number;
    volumeQuantity: number;
    volumeValue: number;
    annualLow: number;
    annualHigh: number;
  };
};

/**
 * NetBroker-web instrument store to access/update instrument data
 */
export class InstrumentStore {
  private store: ObjectSet<InstrumentStoreItem> = new ObjectSet<InstrumentStoreItem>(getInstrumentId);
  private changeListeners: Record<
    string,
    { instruments: Array<string>; cb: (item: ObjectSet<InstrumentStoreItem>) => void }
  > = {};

  get DEBUG_store() {
    return this.store;
  }

  registerAll(
    instrumentList: Array<ListedInstrument | string>,
    cb: (instrumentStoreItems: ObjectSet<InstrumentStoreItem>) => void,
  ): () => void {
    const deregisterFnId = nanoid();
    this.changeListeners[deregisterFnId] = {
      instruments: instrumentList.map(_ => this.getId(_)),
      cb,
    };

    return () => {
      delete this.changeListeners[deregisterFnId];
    };
  }

  get(instrument: string | InstrumentStoreItem): InstrumentStoreItem | undefined {
    const instrumentId = this.getId(instrument);
    return this.store.get(instrumentId);
  }

  setMany(trades: Array<EStreamTradeDTO>) {
    trades.forEach(trade => this.setInstrumentData(trade));

    const listenerKeysNoNotify: Array<string> = [];
    trades.forEach(instrument => {
      Object.keys(this.changeListeners).forEach(listenerKey => {
        const instruments = this.changeListeners[listenerKey].instruments;
        if (instruments.includes(getInstrumentId(instrument))) {
          listenerKeysNoNotify.push(listenerKey);
        }
      });
    });

    if (listenerKeysNoNotify.length > 0) {
      uniqueArray(listenerKeysNoNotify).forEach(listenerKey => {
        const requiredInstruments = this.store.subset(this.changeListeners[listenerKey].instruments);
        this.changeListeners[listenerKey].cb(requiredInstruments);
      });
    }
  }

  private setInstrumentData(item: EStreamTradeDTO) {
    this.store.setX(
      item,
      {
        ric: item.ric,
        mic: item.mic,
        ticker: item.ticker,
      },
      {
        closePrice: item.close_price,
        lastPrice: item.trade_price,
        book: item.book,
        details: item.details,
        lastTradeTime: new Date(item.exchange_time),
        lastTradeQty: item.trade_amount,
      },
    );
  }

  private getId(idOrItem: string | InstrumentStoreItem): string {
    return typeof idOrItem === 'string' ? idOrItem : getInstrumentId(idOrItem);
  }
}
