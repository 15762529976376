import { AxiosInstance } from 'axios';

export class BaseResource {
  protected token: string | undefined;
  constructor(protected readonly axiosInstance: AxiosInstance, protected readonly basePath: string) {}

  setToken(token: string) {
    this.token = token;
  }
}
