import { ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import { DraggableItem } from './DraggableItem';

export type DraggableListProps<T extends { id: string }> = {
  list: Array<T>;
  renderItem: (item: T) => ReactNode;
  onListReordered: (list: Array<T>) => Promise<void>;
};

export const DraggableList = <T extends { id: string }>({
  list,
  renderItem,
  onListReordered,
}: DraggableListProps<T>) => {
  const isDragging = useRef<boolean>(false);
  const [renderedList, setRenderedList] = useState<Array<T>>(list);

  const sortList = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      const newList = [...renderedList];

      [newList[hoverIndex], newList[dragIndex]] = [newList[dragIndex], newList[hoverIndex]];
      setRenderedList(newList);
    },
    [renderedList],
  );

  const moveCard = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      sortList(dragIndex, hoverIndex);
    },
    [sortList],
  );

  const moveCardEnd = useCallback(() => {
    isDragging.current = false;
    onListReordered(renderedList);
  }, [onListReordered, renderedList]);

  useEffect(() => {
    if (!isDragging?.current) {
      // do not overwrite list, when it's changing
      setRenderedList(list);
    }
  }, [list]);

  return (
    <ul>
      {renderedList.map((item, index) => (
        <DraggableItem
          key={item.id}
          id={item.id}
          index={index}
          moveCard={moveCard}
          moveCardEnd={moveCardEnd}
          onDragging={() => (isDragging.current = true)}
        >
          {renderItem(item)}
        </DraggableItem>
      ))}
    </ul>
  );
};
